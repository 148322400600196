import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';



interface SelectNoteCategory {
  handleSelectCategory: (e: any) => void;
  value?: string;
  target?: 'resident' | 'staff';
}

export function SelectNoteCategory({ handleSelectCategory, target = 'resident', value: propValue }: SelectNoteCategory) {
  // Init values
  const { t } = useTranslation();

  const [value, setValue] = useState(propValue || '');

  const categories = useSelector((state: RootState) =>
    state.notes.categories.filter((category) => category.type == target)
  );

  const handleChange = (value: string) => {
    setValue(value);
    handleSelectCategory(value);
  };

  return (
    <Select name="category" onValueChange={handleChange} value={value}>
      <SelectTrigger className="w-[49%]">
        <SelectValue placeholder={t('notes.category')} />
      </SelectTrigger>
      <SelectContent>
        {categories?.map((item: any) => (
          <SelectItem key={item.id} value={item.id}>
            {item.category}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
