import { useMemo } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { t } from 'i18next';

interface MonthAndYearSelectorProps {
  date: Date | undefined;
  onChange: (date: Date) => void;
  baseSelectorYear?: number;
  className?: string;
}

export const MonthAndYearSelector = ({
  date,
  onChange,
  baseSelectorYear,
  className = ''
}: MonthAndYearSelectorProps) => {
  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const startYear = baseSelectorYear ? baseSelectorYear : currentYear;
    const endYear = currentYear + 50;
    const years = [];

    for (let i = endYear; i >= 1900; i--) {
      years.push(i);
    }

    return years;
  }, [baseSelectorYear]);
  const setMonth = (date: Date, month: number) => {
    const newDate = new Date(date);
    newDate.setMonth(month);
    return newDate;
  };

  const setYear = (date: Date, year: number) => {
    const newDate = new Date(date);
    newDate.setFullYear(year);
    return newDate;
  };

  return (
    <div className="w-full flex flex-row md:p-2 gap-2 ">
      <Select
        onValueChange={(value) => onChange?.(setMonth(date ?? new Date(), parseInt(value)))}
        defaultValue={date ? date.getMonth().toString() : ''}
        value={date ? date.getMonth().toString() : ''}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select Month" />
        </SelectTrigger>
        <SelectContent position="popper" className={`${className}`}>
          <SelectItem value="0">{t('calendar.customCalendar.january')}</SelectItem>
          <SelectItem value="1">{t('calendar.customCalendar.february')}</SelectItem>
          <SelectItem value="2">{t('calendar.customCalendar.march')}</SelectItem>
          <SelectItem value="3">{t('calendar.customCalendar.april')}</SelectItem>
          <SelectItem value="4">{t('calendar.customCalendar.may')}</SelectItem>
          <SelectItem value="5">{t('calendar.customCalendar.june')}</SelectItem>
          <SelectItem value="6">{t('calendar.customCalendar.july')}</SelectItem>
          <SelectItem value="7">{t('calendar.customCalendar.august')}</SelectItem>
          <SelectItem value="8">{t('calendar.customCalendar.september')}</SelectItem>
          <SelectItem value="9">{t('calendar.customCalendar.october')}</SelectItem>
          <SelectItem value="10">{t('calendar.customCalendar.november')}</SelectItem>
          <SelectItem value="11">{t('calendar.customCalendar.december')}</SelectItem>
        </SelectContent>
      </Select>
      <Select
        onValueChange={(value) => onChange?.(setYear(date ?? new Date(), parseInt(value)))}
        defaultValue={date ? date.getFullYear().toString() : ''}
        value={date ? date.getFullYear().toString() : ''}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select Year" />
        </SelectTrigger>
        <SelectContent position="popper" className={`overflow-y-auto ${className}`}>
          {years.map((y, i) => (
            <SelectItem key={i} value={y.toString()}>
              {y}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
