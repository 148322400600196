import PaymentMethodsPage from '@/modules/payments/presentation/pages/PaymentMethosdPage';
import SubscriptionPaymentPage from '@/modules/payments/presentation/pages/SubscriptionPaymentPage';
import { Route, Routes } from 'react-router-dom';

const PaymentRouter = () => {
  return (
    <Routes>
      <Route path="subscription/:subscription_id" element={<SubscriptionPaymentPage />}></Route>
      <Route path="manage" element={<PaymentMethodsPage/>}></Route>
    </Routes>
  );
};

export default PaymentRouter;
