import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/registry/new-york/ui/avatar';
import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Badge } from '@/common/presentation/components/ui/badge';
import { cn } from '@/lib/utils';
import { QuillJS } from '../../common/quilljs';
import NoteService from '../../../../infrastructure/services/NoteService';
import { useEffect, useRef, useState } from 'react';
import { NoteI } from '../../../../domain/note.domain';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { format, parseISO } from 'date-fns';
import { Loader } from 'lucide-react';
import { ImgageGrid } from '../../common/imageGrid';
import { useDateHelpers } from '@/utils/helpers/dates.helper';

interface ChatProps {
  note: NoteI;
  displayChat?: boolean;
}

interface FormData {
  answer: string;
  html_answer: string;
  note_id: string;
  images: string[] | null;
}

export function Chat({ note, displayChat = true }: ChatProps) {
  // Initial
  const { formatDate, t } = useDateHelpers();

  // Redux
  const authUserId = useSelector((state: RootState) => state.auth.user?.id);
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const notes = useSelector((state: RootState) => state.notes.notes);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);

  // Hooks
  const [isLoading, setIsLoading] = useState(false);
  const [forceRender, setForceRender] = useState(0);
  const [formData, setFormData] = useState<FormData>({
    answer: '',
    html_answer: '',
    note_id: '',
    images: []
  });

  // Tools
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToBottom();
  }, [note, displayChat]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      const scrollElement = scrollRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollElement) {
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    }
  };

  // Behaviors
  const handleQuillJs = (values: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      answer: values.content,
      html_answer: values.html_content,
      images: values.images
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    setFormData({
      ...formData,
      ['note_id']: noteSelected
    });

    formData['note_id'] = noteSelected;

    await NoteService.createAnswer(formData);

    setFormData({
      ...formData,
      answer: '',
      html_answer: '',
      images: []
    });

    setForceRender(prev => prev + 1);

    setIsLoading(false);
  };

  const staffPhotos = staff.reduce((acc, item) => {
    acc[item.id] = item.profile_url;
    return acc;
  }, {});

  return (
    <div className={cn('flex flex-col pt-2', displayChat ? 'h-[60vh]' : 'hidden')}>
      <div className="flex-grow overflow-auto flex flex-col">
        <ScrollArea ref={scrollRef} type="always" className="flex-grow mt-1">
          <div className="flex flex-col">
            {Object.values(note.answers ?? {})?.map((item: any) =>
              item.created_by != authUserId ? (
                <div className="flex p-2">
                  <div className="mr-1">
                    <Avatar className=" w-8 h-8">
                      <AvatarImage
                        src={staffPhotos[item.created_by] ? staffPhotos[item.created_by] : undefined}
                        className="h-full w-full object-cover"
                      />
                      <AvatarFallback>
                        {item.author
                          .split(' ')
                          .map((chunk: any) => chunk[0])
                          .join('')}
                      </AvatarFallback>
                    </Avatar>
                  </div>
                  <div key={item.id} className="flex items-startpb-3 max-w-[90%]">
                    <div className="flex flex-col">
                      <Badge variant="secondary" className="flex flex-col items-start rounded-lg rounded-tl-none">
                        <div dangerouslySetInnerHTML={{ __html: item.html_answer }} />
                        {item.images && item.images.length > 0 && <ImgageGrid images={item.images} />}
                      </Badge>
                      <span className="text-xs">
                        <span className="mr-1">{item.author}</span>
                        {item.created_at ? formatDate(item.created_at) : ''}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={item.id} className="flex items-end p-2 pb-3 pr-3 max-w-[90%] ml-auto">
                  <div className="flex">
                    <div className="flex  flex-col">
                      <div>
                        <Badge variant="secondary" className="flex flex-col items-start rounded-lg rounded-tl-none">
                          <div dangerouslySetInnerHTML={{ __html: item.html_answer }} />
                          {item.images && item.images.length > 0 && <ImgageGrid images={item.images} />}
                        </Badge>
                      </div>
                      <span className="text-xs self-end">{item.created_at ? formatDate(item.created_at) : ''}</span>
                    </div>
                    <div className="ml-1">
                      <Avatar className=" w-8 h-8">
                        <AvatarImage
                          src={staffPhotos[item.created_by] ? staffPhotos[item.created_by] : undefined}
                          className="h-full w-full object-cover"
                        />
                        <AvatarFallback>
                          {item.author
                            .split(' ')
                            .map((chunk: any) => chunk[0])
                            .join('')}
                        </AvatarFallback>
                      </Avatar>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          {isLoading && (
            <div className="absolute inset-0 flex justify-center items-center">
              <Loader className="h-10 w-10 animate-spin" />
            </div>
          )}
        </ScrollArea>
        <form id="formChat" onSubmit={handleSubmit} className={cn('flex flex-col mt-1')}>
          <div className="grid gap-4">
            <div className="flex items-center space-x-2">
              <QuillJS key={forceRender} onChange={handleQuillJs} value="" submit={true} />
              <Button type="submit" size="sm" className="flex-shrink-0 hidden" disabled={isLoading}/>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
