import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/common/presentation/components/ui/tabs";
import { useEffect, useState } from "react";
import { TrackerCleaningArea } from "./TrackerCleaningArea";
import { TrackerCleaningTask } from "./TrackerCleaningTask";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useTranslation } from "react-i18next";

export function TrackerCleaningAreaTask() {
    const [selectedTab, setTab] = useState("area");
    const configurations = useSelector((state: RootState) => state.configurations);
    const { t } = useTranslation();
    const [accentColor, setAccentColor] = useState("");

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const onSelectedTab = (event: string) => {
        setTab(event);
    }

    return (
        <div>
            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <div className="flex justify-between w-100">
                        {
                            selectedTab ==="area" && <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                {t("cleaning.create.area.title")}
                            </CardTitle>
                        }

                        {
                            selectedTab ==="task" && <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                {t("cleaning.create.task.title")}
                            </CardTitle>
                        }
                        
                        <Tabs defaultValue={selectedTab} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto mb-2">
                            <TabsList>
                                <TabsTrigger value="area" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t("cleaning.create.area.label")}
                                </TabsTrigger>

                                <TabsTrigger value="task" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t("cleaning.create.task.label")}
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                </CardHeader>

                <CardContent>
                    {
                        selectedTab === "area" && <TrackerCleaningArea />
                    }

                    {
                        selectedTab === "task" && <TrackerCleaningTask />
                    }
                </CardContent>
            </Card>
        </div>
    );
}
