import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useDocumentsStore } from '@/modules/residents/domain/stores/documents/documentsStore';

import { useNewCategory } from '@/modules/residents/infrastructure/hooks/documents/useNewCategory';
import { CategoryForm } from '@/modules/residents/presentation/components/Documents/Forms/CategoryForm';

import { categories as defaultCategories } from '@/modules/residents/infrastructure/types/documents';

export const NewCategoryDialog = () => {
  const { isOpen, onClose } = useNewCategory();
  const { error: errorMessage, createCategory, categories: customCategories } = useDocumentsStore();

  const submitForm = async (values: any) => {
    const payload = {
      category_id: values.name.trim().replace(/\s+/g, '_').toLowerCase(),
      name: values.name,
      order: customCategories.length + defaultCategories.length + 1
    };

    try {
      const response = await createCategory(payload);

      if (response) {
        toast({
          description: 'Category created successfully',
          variant: 'default'
        });

        onClose();
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        toast({
          description: error.response.data.message,
          variant: 'destructive'
        });
      } else if (errorMessage === 'Category already exists.') {
        toast({
          description: 'Category already exists.',
          variant: 'destructive'
        });
      } else {
        toast({
          description: 'An error occurred while adding the field',
          variant: 'destructive'
        });
      }
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[500px] max-h-[80vh]">
          <DialogHeader>
            <DialogTitle className="text-xl text-primary">Create a new category</DialogTitle>
          </DialogHeader>
          <CategoryForm
            defaultValues={{
              name: ''
            }}
            onSubmit={submitForm}
            status={'idle'}
            handleLabel={'Create Category'}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
