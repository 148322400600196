import { cn } from '@/lib/utils';

export const Logo = ({ path, clientName }: { path: string; clientName: string }) => {
  return (
    <div className={cn('gap-x-2 flex items-center')}>
      <img src={path} alt="Caring Data Client Logo" width={40} height={40} />
      <p>{clientName}</p>
    </div>
  );
};
