import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { useNewResidentDocument } from '@/modules/residents/infrastructure/hooks/use-new-documents';
import { residentNavigationTabs } from '@/modules/residents/presentation/pages/UpdateResident';
import { RootState } from '@/store/store';
import { ChevronDown } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const buttonStyle = 'hover:bg-primary/80 hover:text-white';

export const ResidentNavigationMenu = () => {
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);
  const { onOpen } = useNewResidentDocument();
  const navigate = useNavigate();

  return (
    <div className="w-full rounded-md flex items-center  gap-2 bg-secondary p-2">


      <Button onClick={() => navigate(`/staff/update?sm=${staffMember?.id}`)} variant={'ghost'} className={buttonStyle}>
      Edit Staff
      </Button>
      {/* <Button variant={'ghost'} className={buttonStyle}>
        Sleeping Hours
      </Button> */}
      {/* <Button variant={'ghost'} className={buttonStyle}>
        Alerts
      </Button> */}
      {/* <Button variant={'ghost'} className={buttonStyle}>
        Bedroom QR
      </Button> */}
      <Button  onClick={() => onOpen()} variant={'ghost'} className={buttonStyle}>
        Documents
      </Button>
      {/* <Button variant={'ghost'} className={buttonStyle}>
        E-signature Log
      </Button>
      <Button variant={'ghost'} className={buttonStyle}>
        DSP
      </Button> */}
      {/* <Button variant={'ghost'} className={buttonStyle}>
        Service Plan
      </Button> */}
    </div>
  );
};
