import { useMemo, useState } from 'react';
import { TimerSelector } from './TimerSelector';
import { UserAccordion } from './UserAccordion';
import { EmarRoutineCard } from './EmarRoutineCard';
import { format } from 'date-fns';

interface Medication {
  id: string;
  resident_id: string;
  time?: string[];
  emar_routine_medications?: any[];
  emar_medication_exceptions?: any[];
}

interface Props {
  medications: any[];
  residentId: string;
  date: Date;
  emar: string;
}

export const EmarRoutineSection = ({ medications, residentId, date, emar }: Props) => {
  function normalizeTimeString(timeString: string): string {
    timeString = timeString.trim();

    const [timePart, period] = timeString.split(' ');

    const normalizedPeriod = period ? period.toUpperCase() : '';

    const [hoursStr, minutesStr] = timePart.split(':');

    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    if (isNaN(hours) || isNaN(minutes) || !normalizedPeriod) {
      return timeString;
    }

    // Eliminar ceros iniciales en las horas
    const hoursWithoutLeadingZero = hours.toString();

    // Asegurarnos de que los minutos tengan dos dígitos
    const minutesPadded = minutes.toString().padStart(2, '0');

    return `${hoursWithoutLeadingZero}:${minutesPadded} ${normalizedPeriod}`;
  }

  const times = useMemo(() => {
    const allTimes = medications.flatMap((medication) => medication.time || []);
    // Filtrar tiempos no válidos y que no contengan comas
    const filteredTimes = allTimes.filter((time) => time && !time.includes(','));

    // Normalizar los tiempos
    const normalizedTimes = filteredTimes.map((time) => normalizeTimeString(time));

    // Remover duplicados usando un Set
    return Array.from(new Set(normalizedTimes));
  }, [medications]);

  const [selectedTime, setSelectedTime] = useState<string>(times[0] || '');

  const completedTimes = useMemo(() => {
    const groupedByDateTime = medications.reduce((acc: Record<string, Medication[]>, medication) => {
      const medicationTimes = (medication.time || []).map(normalizeTimeString);
      medicationTimes.forEach((time: string) => {
        if (!acc[time]) {
          acc[time] = [];
        }
        acc[time].push(medication);
      });
      return acc;
    }, {});

    const completedTimes = times.reduce((acc: string[], time: string) => {
      const medicationsAtTime = groupedByDateTime[time] || [];
      const allMedicationsCompleted = medicationsAtTime.every((medication) => {
        const emarRoutineMedications = medication?.emar_routine_medications || [];
        const emarMedicationExceptions = medication?.emar_medication_exceptions || [];

        const routineMedication = emarRoutineMedications.find((m: any) => {
          return (
            m?.resident_medication_id === medication?.id &&
            m?.time_to_be_witnessed === time &&
            m?.date_to_be_witnessed?.split('T')[0] === format(date, 'yyyy-MM-dd') &&
            m?.witnessed === 1
          );
        });

        const exception = emarMedicationExceptions.find((m: any) => {
          return (
            m?.resident_medication_id === medication?.id &&
            m?.time_to_be_dispensed === time &&
            m?.date_to_be_dispensed?.split('T')[0] === format(date, 'yyyy-MM-dd')
          );
        });

        return routineMedication || exception;
      });

      if (allMedicationsCompleted) {
        acc.push(time.replace(/^0+/, ''));
      }

      return acc;
    }, []);

    return completedTimes;
  }, [medications, times, date]);

  const filteredMedicationByResident = useMemo(() => {
    const filterMedicationsByTime = (time: string) => {
      return medications.reduce((acc: Record<string, Medication[]>, medication) => {
        const medResidentId = medication?.resident_id;

        if (!acc[medResidentId]) {
          acc[medResidentId] = [];
        }

        const medicationTimes = medication?.time || [];
        if (medicationTimes.includes(time)) {
          acc[medResidentId].push(medication);
        }

        return acc;
      }, {});
    };

    return filterMedicationsByTime(selectedTime);
  }, [medications, selectedTime]);

  const filteredMedications = useMemo(() => {
    return medications.filter((medication) => {
      const medicationTimes = medication.time || [];
      return medicationTimes.includes(selectedTime);
    });
  }, [medications, selectedTime]);

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-xl font-semibold">Routine Medications</h2>

      <TimerSelector data={medications} completedTimes={completedTimes} onSelect={setSelectedTime} />

      {residentId === 'all-residents' ? (
        <>
          {Object.keys(filteredMedicationByResident)
            .filter((residentId) => filteredMedicationByResident[residentId].length > 0)
            .map((residentId) => (
              <div key={residentId}>
                <UserAccordion
                  medications={filteredMedicationByResident[residentId]}
                  selectedDate={date}
                  selectedTime={selectedTime}
                  emarType={emar}
                  residentId={residentId}
                />
              </div>
            ))}
        </>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {filteredMedications.map((medication: any) => (
              <EmarRoutineCard
                data={medications.find((med: any) => med.id === medication.id)}
                key={medication.id}
                selectedDate={date ?? new Date()}
                selectedTime={selectedTime ?? ''}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
