import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';

import { Button } from '@/common/presentation/components/ui/button';
import { useDocumentsStore } from '@/modules/residents/domain/stores/documents/documentsStore';
import { useNewCategory } from '@/modules/residents/infrastructure/hooks/documents/useNewCategory';
import { useNewResidentDocument } from '@/modules/residents/infrastructure/hooks/use-new-documents';
import { CircleFadingPlus } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { CategoryCard } from '../CategoryCard';
import { Dialogs } from './Dialogs';

import { categories as defaultCategories } from '@/modules/residents/infrastructure/types/documents';

export type Category = {
  name: string;
  value: string;
  order: number;
};

export const ResidentDocumentsDialog = () => {
  const { isOpen, onClose } = useNewResidentDocument();
  const { onOpen } = useNewCategory();
  // const { categories: customCategories, getCategories } = useDocumentsStore();

  // const [categories, setCategories] = useState<Category[]>([...defaultCategories]);

  // const uniqueCategories = useMemo(() => {
  //   const categories = [...defaultCategories, ...customCategories];

  //   const uniqueCategories = Array.from(new Set(categories.map((category) => category.value))).map((value) => {
  //     const category = categories.find((category) => category.value === value);
  //     return category;
  //   });

  //   return uniqueCategories.sort((a, b) => a.order - b.order);
  // }, [customCategories]);

  // useEffect(() => {
  //   if (customCategories.length === 0) {
  //     getCategories();
  //   }

  //   if (customCategories.length > 0) {
  //     setCategories(uniqueCategories);
  //   }
  // }, [customCategories]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[90vw] max-h-[90vh] h-auto overflow-y-auto">
          <DialogHeader className="flex justify-between">
            <div className="flex justify-between">
              <div className="col-span-2">
                <DialogTitle className="text-xl text-primary">Documents</DialogTitle>
                <DialogDescription>
                  Manage your resident's documentation. Upload, delete and create versions of documents here.
                </DialogDescription>
              </div>
              <div className="p-2">
                <Button className="flex w-full gap-2 bg-primary" onClick={() => onOpen()}>
                  <CircleFadingPlus className="size-4 mr-2" />
                  Add New Category
                </Button>
              </div>
            </div>
          </DialogHeader>
          {/* <NewResidenDocumentForm customCategories={customCategories} />\{categories.length} */}

          {/* {categories.length > 0 && (
            <div className="flex flex-col gap-4">
              {categories.map((category) => (
                <CategoryCard key={category.value} category={category} />
              ))}
            </div>
          )} */}
        </DialogContent>
      </Dialog>

      <Dialogs />
    </>
  );
};
