import { Button } from '@/common/presentation/components/ui/button';
import { Label } from '@/common/presentation/components/ui/label';
import { Input } from '@/common/presentation/components/ui/input';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import React, { useEffect, useRef } from 'react';
import Inputmask from 'inputmask';
import { useDispatch } from 'react-redux';
import { fetchCreatePaymentMethod } from '../slices/paymentsSlice';
import * as PaymentSlice from '../slices/paymentsSlice';
import { Loader2 } from 'lucide-react';
import { toast } from '@/common/presentation/components/ui/use-toast';
import InputCreditCard from './InputCreditCard';
import ProtectedPaymentMessage from './ProtectedPaymentMessage';
import { encryptToLaravel } from '@/utils/helpers/encryption.helper';
import PaymentsAdapter from '../adapters/payments.adapter';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface IProps {
  setDialogOpen: (dialogOpen: boolean) => void;
}

const PaymentMethodForm: React.FC<IProps> = ({ setDialogOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const expiryRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  const formSchema = z.object({
    card_name: z
      .string()
      .min(5, {
        message: t('subscription.cardNameAtLeast')
      })
      .nonempty(t('subscription.cardNameRequired')),
    card_number: z.string().nonempty('Card number is required.'),
    card_expiry: z.string().regex(/^\d{2}\/\d{4}$/, {
      message: t('subscription.expiryFormat')
    }),
    card_cvv: z
      .string()
      .min(3, {
        message: t('subscription.cvvAtLeast')
      })
      .nonempty(t('subscription.cvvRequired'))
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      card_name: '',
      card_number: '',
      card_expiry: '',
      card_cvv: ''
    }
  });

  useEffect(() => {
    if (expiryRef.current) {
      const expiryMask = new Inputmask({
        mask: '99/9999',
        showMaskOnHover: false,
        showMaskOnFocus: false,
        greedy: false
      });

      expiryMask.mask(expiryRef.current);
    }
  }, []);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    setError('');
    const cardInformation = PaymentsAdapter.createPaymentMethod(values);
    const cardEncrypted = encryptToLaravel(cardInformation);
    const response = await dispatch<any>(fetchCreatePaymentMethod(cardEncrypted));
    if (!response.error) {
      await dispatch<any>(PaymentSlice.fetchPaymentMethods());
      setDialogOpen(false);
      toast({
        title: t('subscription.paymentMethodAddedSuccessfully'),
        className: 'bg-green-500 text-white'
      });
    } else {
      const { error } = response;
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="w-full">
      <div className="flex justify-left">
        <div className="w-full">
          <div className="grid grid-cols-1 gap-4">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
                <div className="flex flex-row justify-between gap-5">
                  <div className="items-start w-72">
                    <div className="py-1">
                      <FormField
                        control={form.control}
                        name="card_name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="flex text-base">{t('subscription.nameOnTheCard')}</FormLabel>
                            <FormControl>
                              <Input placeholder={t('signUp.fullName')} className="w-full my-2" autoComplete="off" {...field} />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                  <div className="items-end w-28">
                    <div className="py-1">
                      <FormField
                        control={form.control}
                        name="card_expiry"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="flex text-base">{t('subscription.expiry')}</FormLabel>
                            <FormControl>
                              <Input type="text" placeholder="mm/yyyy" {...field} ref={expiryRef} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-between gap-5 my-2">
                  <div className="items-start w-72">
                    <div className="py-1">
                      <FormField
                        control={form.control}
                        name="card_number"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="flex text-base">{t('subscription.cardNumber')}</FormLabel>
                            <FormControl>
                              <InputCreditCard
                                name="card_number"
                                placeHolder="1234 1234 1234 1234"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                  <div className="items-end w-28">
                    <div className="py-1">
                      <FormField
                        control={form.control}
                        name="card_cvv"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="flex text-base">{t('subscription.cvv')}</FormLabel>
                            <FormControl>
                              <Input type="password" placeholder={t('subscription.cvv')} autoComplete="off" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
                {error.length > 0 && (
                  <motion.div
                  drag
                  dragConstraints={{ left: 100, right: 100,  bottom:100 }}
                  initial={{ scale: 0.98, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.8, opacity: 0 }}
                  transition={{ duration: 0.3, ease: "easeOut" }}
                  >
                    <Label className="text-sm text-red-600">{error}</Label>
                  </motion.div>
                )}
                <div>
                  <ProtectedPaymentMessage />
                </div>
                <div className="col-span-1 py-2">
                  <div className="flex justify-center pt-3">
                    <Button type="submit" className="w-full bg-primary" disabled={loading}>
                      {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                      {t('subscription.addPaymentMethod')}
                    </Button>
                  </div>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodForm;
