import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import useRouteParams from '@/common/hooks/RouteParamsHook';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Pencil, Trash, Ellipsis } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { Textarea } from "@/common/presentation/components/ui/textarea";

import { format } from "date-fns";

import { debounce } from 'lodash';
import { ImageCardTables } from '@/common/presentation/components/Selectors/ImageCardTables';
import { useTranslation } from 'react-i18next';
import TrackerVitalsType from './TrackerVitalsType';
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CircleFadingPlus } from "lucide-react";

function TrackerVitals() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState(null);
    const [dataReport, setDatareport] = useState([]);
    const dispatch = useDispatch();
    const { tracker } = useSelector((state: RootState) => state.tracker);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedHygiene, setSelectedVitals] = useState<string>(params.e || 'form');
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [inputValues, setInputValues] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();

    const onSelectVitals = (e: string) => {
        setParam('e', e);
        setSelectedVitals(e);
    };

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
            setParam('e', 'form');
            setSelectedVitals('form');
        }

        if (!params?.l || params.l.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }

    }, [params]);

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        params.l === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === params.l);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!params.l && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(params.l === 'all-locations' ? locations : locations.filter((l) => l.id === params.l));
        setLocation(params.l);

        if (params.l && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);


    const setpTypes = async () => {
        const json = { location_id: params.l };
        const response = await TrackerService.getTrackerVitalsType(json);
        if (response && response.data) {
            setTypes(JSON.parse(response.data));
        }

    };

    useEffect(() => {
        if (!types) {
            setpTypes();
        }
    }, [types]);



    const newColumns = () => {
        const firstObject = {
            label: t('trackers.Type'),
            value: "type",
            type: "texto"
        };
        return [firstObject, ...residents.map((resident: { name: any; }) => ({
            label: (
                <div>
                    <ImageCardTables
                        item={{
                            id: resident.id,
                            firstName: resident.first_name,
                            lastName: resident.last_name,
                            image: resident.image_url
                        }}
                        aspectRatio={'rounded'}
                    />
                </div>
            ),
            value: resident.first_name,
            type: 'text'
        }))];
    };
    const columns = newColumns();
    const columnsreport = [
        { label: t('trackers.Date/Time'), value: 'date', type: 'date' },
        { label: t('trackers.Resident'), value: 'resident_name' },
        { label: t('trackers.Type'), value: 'type_name' },
        { label: t('trackers.Data'), value: 'value' },
        { label: t('trackers.Detail'), value: 'detail' },
        { label: t('trackers.Staff'), value: 'who_name' }
    ];


    const dataVitalsReport = async () => {
        const json = { location_id: params.l };
        const response = await TrackerService.getTrackerVitals(json);
        if (response && Array.isArray(response) && response.length > 0) {
            let allData = [];
            response.forEach(item => {
                if (item && item.data) {
                    const parsedData = JSON.parse(item.data);
                    allData = allData.concat(parsedData.map(dataItem => ({
                        ...dataItem,
                        created_at: item.created_at,
                        dataReportid: item.id
                    })));
                }
            });
            allData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setDatareport(allData);
        } else {
            setDatareport([])
        }
    };

    useEffect(() => {
        setLoading(true);
        if (!dataReport) {
            dataVitalsReport()
                .then(() => {
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }

    }, [dataReport]);

    const [data, setData] = useState([]);

    const createData = () => {
        const columnField = types?.column_field || [];

        const sortedColumnField = columnField.slice().sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });

        if (!types || !residents) return [];

        const groupedData = {};

        const getValue = (residentId, typeName) => {
            if (!dataReport) {
                return '';
            } else {
                const today = new Date().toISOString().split('T')[0];
                const report = dataReport.find((report) => {
                    const reportDate = new Date(report.date).toISOString().split('T')[0];
                    return report.resident_id === residentId && reportDate === today && report.type_name === typeName;
                });
                return report ? report.value : '';
            }
        };

        residents.forEach((resident) => {
            sortedColumnField.forEach((field) => {
                if (field.status === "inactive") return;

                const type = field.name;
                const typeid = field.id;
                const key = `${typeid}_${resident.first_name}`;
                const initialValue = getValue(resident.id, type);
                const value = inputValues[key] !== undefined ? inputValues[key] : initialValue;

                if (!groupedData[type]) {
                    groupedData[type] = { type: type };
                }

                groupedData[type][resident.first_name] = (
                    <div className="flex justify-center" key={key}>
                        <div className="text-center flex-1">
                            <input
                                type="number"
                                name={key}
                                className="form-input h-8 w-20 border border-gray-300 rounded-md px-2 py-1 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={value}
                                onChange={(e) => handleInputChange(e, typeid, resident.id)}
                                placeholder="Data"
                            />
                        </div>
                    </div>
                );
            });
        });

        return Object.values(groupedData);
    };

    useEffect(() => {
        setData(createData());
    }, [types, dataReport, residents, inputValues]);

    const [pendingSubmit, setPendingSubmit] = useState(null);
    const handleInputChange = (e, typeId, residentId) => {
        const { name, value } = e.target;
        const intValue = parseInt(value, 10);
        setInputValues(prevValues => ({
            ...prevValues,
            [name]: isNaN(intValue) ? '' : intValue,
        }));
        if (pendingSubmit) {
            pendingSubmit.cancel();
        }
        const debouncedSubmit = debounce(() => {
            buttonSumbit({
                typeId: typeId,
                residentId: residentId,
                value: isNaN(intValue) ? '' : intValue
            });
        }, 500);
        setPendingSubmit(() => debouncedSubmit);
        debouncedSubmit();
    }

    const Fields = {
        'Edit': [
            // { fieldName: 'resident_name', label: 'Resident', type: 'hidden' },
            { fieldName: 'field', label: t('trackers.Type'), type: 'select' },
            { fieldName: 'value', label: t('trackers.Value'), type: 'text' },
            { fieldName: 'detail', label: t('trackers.Detail'), type: 'textarea' }
        ]
    };

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[], description: string,) => {
        const filteredFields = fields.filter(field => field.fieldName !== "Tipo" && field.fieldName !== "Type" &&
            field.fieldName !== "Uri");
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(filteredFields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };


    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};
        if (action === 'Edit') {
            json["id"] = formData.dataReportid;
            json["id_data"] = formData.id;
            json["data"] = {
                "type": formData.type,
                "detail": formData.detail,
                "value": formData.value,
                "resident_id": formData.resident_id,
                "date": formData.date
            };
            const response = await TrackerService.updateTrackerVitals(json);
            setShowEditDialog(false);
        } else {
            json["id"] = formData.dataReportid;
            json["id_data"] = formData.id;
            const response = await TrackerService.destroyTrackerVitals(json);
            setShowEditDialog(false);
        }
        dataVitalsReport();
    };

    useEffect(() => {
        setpTypes();
        dataVitalsReport();
    }, [params.l]);


    const [filters, setFilters] = useState({
        resident_name: '',
        type_name: ''
    });

    const buttonSumbit = async ({ typeId, residentId, value }: { typeId: string; residentId: string; value: BigInteger; }) => {
        const today = new Date().toISOString().split('T')[0];
        if (Array.isArray(dataReport)) {
            const foundReport = dataReport.find(report => {
                const reportDate = new Date(report.date).toISOString().split('T')[0];
                return report.type === typeId && report.resident_id === residentId && today === reportDate;
            });

            if (foundReport) {
                const json: { [key: string]: any } = {};
                json["id"] = foundReport.dataReportid;
                json["id_data"] = foundReport.id;
                json["data"] = {
                    "type": typeId,
                    "detail": foundReport.detail,
                    "value": value,
                    "resident_id": residentId,
                    "date": foundReport.date
                };
                const response = await TrackerService.updateTrackerVitals(json);
                dataVitalsReport();
                setData(createData());
            } else {
                const json = [{
                    type: typeId,
                    value: value,
                    resident_id: residentId,
                    detail: " ",
                    location_id: params.l
                }];
                const response = await TrackerService.saveTrackerVitals(json);
                dataVitalsReport();
                setData(createData());
            }
        } else {
            const json = [{
                type: typeId,
                value: value,
                resident_id: residentId,
                detail: " ",
                location_id: params.l
            }];
            const response = await TrackerService.saveTrackerVitals(json);
            dataVitalsReport();
            setData(createData());
        }
    };

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderInputForColumn = (column, index) => {

        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="number"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'textarea':
                return <textarea
                    id={column.fieldName}
                    name={column.fieldName}
                    value={formData[column.fieldName] || ''}
                    onChange={handleChange}
                    style={{ outline: 'none' }}
                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                />;
            case 'select':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {types.column_field.find((option) => option.id === formData.type)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {types.column_field.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            default:
                return null;
        }
    };

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction: direction });
    };

    const sortedData = useMemo(() => {
        if (sortConfig.key) {
            return [...dataReport].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });
        }
        return dataReport;
    }, [dataReport, sortConfig]);


    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                params.l && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackervitals.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackervitals.description')}</div>
                        </div>

                        <Tabs defaultValue={selectedHygiene} onValueChange={(e) => { onSelectVitals(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('seizure.form.label')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                                <TabsTrigger value="type" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Type')}
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }


            {
                selectedHygiene === 'form' && (
                    <div>
                        {
                            params.l && (
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <ResidentSelector
                                            onSelect={onResidentSelect}
                                            locationId={location}
                                            residentId={params.r}
                                            showAllResidents={false}
                                        />
                                    </div>
                                    <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                        <Table className="shadow-lg overflow-hidden">
                                            <TableHeader className="text-center">
                                                <TableRow className="py-2 px-4 text-center bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                    {columns.map((column, index) => (
                                                        <TableHead
                                                            className={`text-${index === 0 ? 'left' : 'center'} text-white`}
                                                            key={column.value}
                                                        >
                                                            {column.label}
                                                        </TableHead>
                                                    ))}
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody className="bg-white dark:bg-background">
                                                {data.map((row, index) => (
                                                    <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800 border-b border-gray-300">
                                                        {columns.map((column, columnIndex) => (
                                                            <TableCell key={columnIndex} className={`py-4 px-4 ${columnIndex !== 0 && 'border-l border-gray-300'}`} >
                                                                {row[column.value as keyof typeof row]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div>
                                        <div className="flex items-center justify-between px-2">
                                            <div className="flex-1 text-sm text-muted-foreground">
                                                {data.length} of {data.length} row(s) selected.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end mb-4">
                                        <button className="p-2 bg-primary text-white rounded" onClick={() => window.location.reload()}>{t('trackers.Save Quick Tracker')}</button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                selectedHygiene === 'report' && (
                    <div>
                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.trackervitalsreport.title')}</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div className="flex space-x-4">
                                    <Input
                                        placeholder="Filter by Name"
                                        value={filters.resident_name}
                                        onChange={(e) => setFilters({ ...filters, resident_name: e.target.value })}
                                        className="w-[200px]"
                                    />
                                    <Input
                                        placeholder="Filter by Type"
                                        value={filters.type_name}
                                        onChange={(e) => setFilters({ ...filters, type_name: e.target.value })}
                                        className="w-[200px]"
                                    />
                                </div>
                                <br />
                                <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                    <Table className="shadow-lg overflow-hidden">
                                        <TableHeader>
                                            <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                {columnsreport.map((column) => (

                                                    <TableHead
                                                        key={column.value}
                                                        className="text-white cursor-pointer items-center"
                                                        onClick={() => handleSort(column.value)}>
                                                        <div className="flex items-center ">
                                                            <span>{column.label}</span>
                                                            <span className="ml-1 flex-col justify-end">
                                                                <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'ascending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                    ▲
                                                                </span>
                                                                <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'descending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                    ▼
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </TableHead>

                                                ))}
                                                <TableHead className="text-white">{t('trackers.Actions')}</TableHead>

                                            </TableRow>
                                        </TableHeader>
                                        <TableBody className="bg-white dark:bg-background">
                                            {loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={columnsreport.length} className="py-4">
                                                        <div className="flex items-center justify-center">
                                                            <Spinner size={'lg'} />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                sortedData && sortedData
                                                    .filter(row =>
                                                        (!filters.resident_name || row.resident_name.toLowerCase().includes(filters.resident_name.toLowerCase())) &&
                                                        (!filters.type_name || row.type_name.toLowerCase().includes(filters.type_name.toLowerCase()))
                                                    )
                                                    .map((row, index) => (
                                                        <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                            {columnsreport.map((column, columnIndex) => (
                                                                <TableCell key={columnIndex} className="py-2 px-4">
                                                                    {column.value === 'date' ? (
                                                                        <span>
                                                                            {format(new Date(row[column.value]), "dd/MM/yyyy hh:mm a")}
                                                                            {column.type_name === 'date' ? ` (${row.value})` : null}
                                                                        </span>
                                                                    ) : column.value === 'type_name' ? (
                                                                        <span>
                                                                            {row.type_name ? capitalizeWords(String(row.type_name)) : 'N/A'}
                                                                        </span>
                                                                    ) : column.value === 'detail' ? (
                                                                        <span>
                                                                            {row.detail ? capitalizeWords(String(row.detail)) : 'N/A'}
                                                                        </span>
                                                                    ) : (
                                                                        capitalizeWords(String(row[column.value as keyof typeof row]))
                                                                    )}
                                                                </TableCell>
                                                            ))}
                                                            <TableCell className="py-2 px-4">
                                                                <DropdownMenu>
                                                                    <DropdownMenuTrigger asChild>
                                                                        <Button variant="outline" size="icon">
                                                                            <Ellipsis className="h-4 w-4" />
                                                                        </Button>
                                                                    </DropdownMenuTrigger>
                                                                    <DropdownMenuContent className="w-56">
                                                                        <DropdownMenuSeparator />
                                                                        <DropdownMenuGroup>
                                                                            <DropdownMenuItem>
                                                                                <Pencil className="mr-2 h-4 w-4" />
                                                                                <span onClick={() => handleButtonOpenDialog(Fields['Edit'], 'Edit', row, '')}>{t('trackers.Edit')}</span>
                                                                            </DropdownMenuItem>
                                                                            <DropdownMenuItem>
                                                                                <Trash className="mr-2 h-4 w-4" />
                                                                                <span onClick={() => handleButtonOpenDialog([], 'Delete', row, "Are you sure you want to delete this item?")}>{t('trackers.Delete')}</span>
                                                                            </DropdownMenuItem>
                                                                        </DropdownMenuGroup>
                                                                    </DropdownMenuContent>
                                                                </DropdownMenu>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                            )}
                                        </TableBody>
                                        <CustomDialog
                                            width="30rem"
                                            newDialog={true}
                                            isOpen={showEditDialog}
                                            onClose={handleCloseEditDialog}
                                            title={dialogTitle}
                                            onSubmit={handleSubmit}
                                            description={dialogDescription}
                                        >
                                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                                <div className="grid gap-4">
                                                    {fields && fields.map((field, index) => (
                                                        <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                                            <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>
                                                            {renderInputForColumn(field, index)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </form>

                                        </CustomDialog>
                                    </Table>
                                    <div>
                                        <div className="flex items-center justify-between px-2">
                                            <div className="flex-1 text-sm text-muted-foreground">
                                                {dataReport && dataReport.length} of {dataReport && dataReport.length} row(s) selected.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="flex justify-end mb-4">
                                    <button className="p-2 bg-primary text-white rounded">{t('trackers.Download')}</button>
                                </div>

                            </CardContent>
                        </Card>
                    </div>
                )
            }

            {
                selectedHygiene === 'type' && (
                    <div>
                        <TrackerVitalsType setpTypesVital={setpTypes} />

                    </div>

                )
            }

        </div >
    )
}

export default TrackerVitals