import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { ITrackerCleaningAreaDeleteDTO, ITrackerCleaningAreaDTO, ITrackerCleaningAreaRetrieveDTO, ITrackerCleaningAreaUpdateDTO, ITrackerCleaningSummaryDayRetrieveDTO, ITrackerCleaningSummaryDeleteDTO, ITrackerCleaningSummaryDTO, ITrackerCleaningSummaryRetrieveDTO, ITrackerCleaningSummaryUpdateDTO, ITrackerCleaningTaskDeleteDTO, ITrackerCleaningTaskDTO, ITrackerCleaningTaskRetrieveDTO, ITrackerCleaningTaskUpdateDTO } from '../../presentation/components/cleaning/TrackerCleaningMockup';

const TrackerCleaningService = {
  getAreas: async (body: ITrackerCleaningAreaRetrieveDTO) => {
    try {
      return await axios.post(`trackerCleaning/areas`, body);
    } catch (error) {
      throw new Error('Error fetching Areas: ' + error);
    }
  },
  createArea: async (body: ITrackerCleaningAreaDTO) => {
    try {
      return await axios.post(`trackerCleaning/area`, body);
    } catch (error) {
      throw new Error('Error fetching Areas: ' + error);
    }
  },
  updateArea: async (body: ITrackerCleaningAreaUpdateDTO) => {
    try {
      return await axios.put(`trackerCleaning/area`, body);
    } catch (error) {
      throw new Error('Error fetching Areas: ' + error);
    }
  },
  deleteArea: async (body: ITrackerCleaningAreaDeleteDTO) => {
    try {
        return await axios({
            method: "delete",
            url: `trackerCleaning/area`,
            data: body
        });
    } catch (error) {
      throw new Error('Error fetching Areas: ' + error);
    }
  },
  getTasks: async (body: ITrackerCleaningTaskRetrieveDTO) => {
    try {
      return await axios.post(`trackerCleaning/tasks`, body);
    } catch (error) {
      throw new Error('Error fetching Task: ' + error);
    }
  },
  createTask: async (body: ITrackerCleaningTaskDTO) => {
    try {
      return await axios.post(`trackerCleaning/task`, body);
    } catch (error) {
      throw new Error('Error fetching Task: ' + error);
    }
  },
  updateTask: async (body: ITrackerCleaningTaskUpdateDTO) => {
    try {
      return await axios.put(`trackerCleaning/task`, body);
    } catch (error) {
      throw new Error('Error fetching Task: ' + error);
    }
  },
  deleteTask: async (body: ITrackerCleaningTaskDeleteDTO) => {
    try {
        return await axios({
            method: "delete",
            url: `trackerCleaning/task`,
            data: body
          });
    } catch (error) {
      throw new Error('Error fetching Task: ' + error);
    }
  },
  getSummaries: async (body: ITrackerCleaningSummaryRetrieveDTO) => {
    try {
      return await axios.post(`trackerCleaning/summaries`, body);
    } catch (error) {
      throw new Error('Error fetching Summary: ' + error);
    }
  },
  getDaySummaries: async (body: ITrackerCleaningSummaryDayRetrieveDTO) => {
    try {
      return await axios.post(`trackerCleaning/todaySummaries`, body);
    } catch (error) {
      throw new Error('Error fetching Summary: ' + error);
    }
  },
  createSummary: async (body: ITrackerCleaningSummaryDTO) => {
    try {
      return await axios.post(`trackerCleaning/summary`, body);
    } catch (error) {
      throw new Error('Error fetching Summary: ' + error);
    }
  },
  updateSummary: async (body: ITrackerCleaningSummaryUpdateDTO) => {
    try {
      return await axios.put(`trackerCleaning/summary`, body);
    } catch (error) {
      throw new Error('Error fetching Summary: ' + error);
    }
  },
  deleteSummary: async (body: ITrackerCleaningSummaryDeleteDTO) => {
    try {
      return await axios({
        method: "delete",
        url: `trackerCleaning/summary`,
        data: body
      });
    } catch (error) {
      throw new Error('Error fetching Summary: ' + error);
    }
  },
};

export default TrackerCleaningService;
