import React from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuSeparator
} from '@/common/presentation/components/ui/dropdown-menu';
import { Pencil, Trash, Ellipsis } from 'lucide-react';

interface RenderActionsColumnProps {
  row: any;
  handleButtonOpenDialog: (fields: any[], title: string, row: any[], description: string) => void;
}

const RenderActionsColumn: React.FC<RenderActionsColumnProps> = ({ row, handleButtonOpenDialog }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon">
          <Ellipsis className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => handleButtonOpenDialog(Fields['Edit'], 'Edit', row, '')}>
            <Pencil className="mr-2 h-4 w-4" />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleButtonOpenDialog('', 'Delete', row, 'Are you sure you want to delete this item?')}>
            <Trash className="mr-2 h-4 w-4" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RenderActionsColumn;