import { create } from 'zustand';

type ExceptionState = {
  isOpen: boolean;
  data: any;
  date: Date | undefined;
  time: string[];
  locationId?: string;
  residentId?: string;
  onOpen: (data: any, date: Date, time: string[], locationId?: string, residentId?: string) => void;
  onClose: () => void;
};

export const useNewExceptionDialog = create<ExceptionState>((set) => ({
  isOpen: false,
  data: null,
  date: undefined,
  time: [],
  onOpen: (data, date, time = [], locationId, residentId) =>
    set({ isOpen: true, data, date, time, locationId, residentId }),
  onClose: () => set({ isOpen: false })
}));
