import { useMemo } from 'react';
import { EmarPrnCard } from './EmarPrnCard';
import { UserAccordion } from './UserAccordion';

interface Props {
  medications: any[];
  residentId: string;
  date: Date;
  emar: string;
}

export const EmarPrnSection = ({ medications, residentId, date, emar }: Props) => {
  const filteredMedicationByResident = useMemo(() => {
    const filterMedicationsByTime = (time: string) => {
      return medications.reduce((acc, medication) => {
        const residentId = medication.resident_id;

        if (!acc[residentId]) {
          acc[residentId] = [];
        }

        if (medication.time.includes(time)) {
          acc[residentId].push(medication);
        }

        return acc;
      }, {});
    };

    return filterMedicationsByTime('As Needed');
  }, [medications, residentId]);

  const filteredMedications = useMemo(() => {
    return filteredMedicationByResident[residentId] || [];
  }, [filteredMedicationByResident, residentId, medications, date, emar]);

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-xl font-semibold">PRN Medications</h2>

      {residentId === 'all-residents' ? (
        <>
          {Object.keys(filteredMedicationByResident).map((residentId) => (
            <div key={residentId}>
              {filteredMedicationByResident[residentId].length > 0 && (
                <UserAccordion
                  medications={filteredMedicationByResident[residentId]}
                  selectedDate={date || new Date()}
                  selectedTime={'As Needed'}
                  emarType={emar}
                  residentId={residentId}
                />
              )}
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {filteredMedications.map((medication: any) => (
              <EmarPrnCard data={medication} key={medication.id} selectedDate={date ?? new Date()} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
