import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SubscriptionService from '../../infraestructure/services/SubscriptionService';
import { SubscriptionSliceI } from '../../domain/makerPlace.domain';

const initialState: SubscriptionSliceI = {
  subscriptions: {},
  subscriptions_loading: true,
  services: [],
};

export const fetchGetClientSubscriptions = createAsyncThunk('subscriptions/client/get', async () => {
  const response = await SubscriptionService.getClientSubscriptions();
  return response;
});

export const fetchGetServices = createAsyncThunk('services/get', async () => {
  const response = await SubscriptionService.getServices();
  return response;
});

export const fetchUpdateSubscription = createAsyncThunk('subscription/update', async (data: any) => {
  const response = await SubscriptionService.updateSubscription(data);
  return response;
});

export const fetchCancelSubscription = createAsyncThunk('subscription/cancel', async (subscriptionId: string) => {
  const response = await SubscriptionService.cancelSubscription(subscriptionId);
  return response;
});

export const fetchGetStripeSubscription = createAsyncThunk(
  'subscription/stripe/get',
  async (subscriptionId: string) => {
    const response = await SubscriptionService.getStripeSubscription(subscriptionId);
    return response;
  }
);

export const fetchSubscribe = createAsyncThunk('subscription/subscribe', async (data: any) => {
  const response = await SubscriptionService.subscribe(data);
  return response;
});

export const fetchGetSubscription = createAsyncThunk('subscription/get', async (subscriptionId: string) => {
  const response = await SubscriptionService.getSubscription(subscriptionId);
  return response;
});

export const fetchRetryPayment = createAsyncThunk('subscription/payment/retry', async (data: any) => {
  const response = await SubscriptionService.retryPayment(data);
  return response;
});

export const SubscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    updateSubscription: (state, action) => {
      const { payload } = action;
      if (payload.status === 'inactive') {
        delete state.subscriptions[payload.service.key];
      } else {
        state.subscriptions[payload.service.key] = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetServices.fulfilled, (state, action) => {
      let { payload } = action.payload;
      state.services = payload;
    });
    builder.addCase(fetchGetClientSubscriptions.fulfilled, (state, action) => {      
      state.subscriptions_loading = false;
      let { payload } = action.payload;
      const subscriptions = payload.reduce((acc: any, subscription: any) => {
        acc[subscription.service.key] = subscription;
        return acc;
      }, {});
      state.subscriptions = subscriptions;
    });
    builder.addCase(fetchGetSubscription.fulfilled, (state, action) => {
      let { subscription_info } = action.payload;
      state.subscriptions[subscription_info.service.key] = subscription_info;
    });
  }
});

export const { updateSubscription } = SubscriptionSlice.actions;
export default SubscriptionSlice.reducer;
