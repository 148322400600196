import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { cn } from '@/lib/utils';
import { useNewResidentTransfer } from '@/modules/residents/infrastructure/hooks/use-new-resident-transfer';
import { GetPicklist } from '@/modules/residents/infrastructure/services/ResidentsService';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, CircleFadingPlus, Loader } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z.object({
  admission_date: z.date({
    required_error: 'Admission date is required'
  }),
  original_move_in_date: z.date({
    required_error: 'Original move in date is required'
  }),
  current_resident_house: z.string({
    required_error: 'Current resident house is required'
  }),
  resident_room: z.string(),
  level_of_care: z.string()
});
type FormValues = z.infer<typeof formSchema>;

const defaultValues: Partial<FormValues> = {
  admission_date: undefined,
  original_move_in_date: undefined,
  current_resident_house: undefined,
  resident_room: '',
  level_of_care: ''
};

export const Newtransfer = () => {
  const { onOpen } = useNewResidentTransfer();
  const [locationOptions, setLocationOptions] = useState([]);
  const [levelOfCareOptions, setLevelOfCareOptions] = useState([]);
  const { pathname } = useLocation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const [locationsResidents, setLocationsResidents]: any = useState({
    ...defaultValues
  });
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
    values: { ...locationsResidents }
  });

  const onSubmit = (data: FormValues) => {
    const formData: any = {};

    Object.entries(data).forEach(([key, value]) => {
      if (value === undefined) {
        formData[key] = null;
      } else {
        formData[key] = value;
      }
    });
  };

  const getPicklist = async () => {
    try {
      const { data }: any = await GetPicklist();
      const { levelOfCarePickList, locations } = data;

      function compareLevels(a: any, b: any) {
        if (a.name === 'Full Care ') return 1;
        if (b.name === 'Full Care ') return -1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      levelOfCarePickList.sort(compareLevels);

      const locationOptions = locations.map((location: any) => ({
        label: location.location.name,
        value: location.location_id
      }));

      const levelOfCareOptions = levelOfCarePickList.map((levelOfCare: any) => ({
        label: levelOfCare.name,
        value: levelOfCare.id
      }));

      setLocationOptions(locationOptions);
      setLevelOfCareOptions(levelOfCareOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPicklist();
  }, []);
  useEffect(() => {
    const path = pathname.split('/').pop();

    setLocationsResidents({});
    form.reset();

    if (path === 'detail' && resident && resident.transfers && resident.transfers.length > 0) {
      const locationResident = resident.transfers[0];
      if (locationOptions.length > 0 && levelOfCareOptions.length > 0) {
        setLocationsResidents({
          admission_date: locationResident?.transfer_date ? new Date(locationResident?.transfer_date) : undefined,
          original_move_in_date: locationResident?.transfer_date
            ? new Date(locationResident?.transfer_date)
            : undefined,
          current_resident_house: locationResident.destination_location_id || undefined,
          resident_room: resident.resident_room || '',
          level_of_care: resident.level_of_care_id || '',
          adress_of_prior_to_admision: resident?.other_information?.adress_of_prior_to_admision || '',
          date_of_left_prior_facility: resident?.other_information?.date_of_left_prior_facility
            ? new Date(resident?.other_information?.date_of_left_prior_facility)
            : undefined,
          reason_for_leaving_prior_facility: resident?.other_information?.reason_for_leaving_prior_facility || ''
        });
      }
    }
  }, [resident, levelOfCareOptions, locationOptions]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-1 md:grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="original_move_in_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Original Move In Date <span className="text-destructive">(*)</span>
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                          >
                            {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date</span>}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <div className="rounded-md border">
                          <CustomCalendar onChange={field.onChange} value={field.value} MonthAndYearPicker />
                        </div>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="current_resident_house"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Current Resident House <span className="text-destructive">(*)</span>
                    </FormLabel>
                    <Select onValueChange={field.onChange} value={field.value} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a resident house" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {locationOptions.map((location: any, index: number) => (
                          <SelectItem value={location.value} key={index}>
                            {location.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="resident_room"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Resident Room</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="Rrom-1">Room-1</SelectItem>
                        <SelectItem value="Rrom-2">Room-2</SelectItem>
                        <SelectItem value="Rrom-3">Room-3</SelectItem>
                        <SelectItem value="Rrom-4">Room-4</SelectItem>
                        <SelectItem value="Rrom-5">Room-5</SelectItem>
                        <SelectItem value="Rrom-6">Room-6</SelectItem>
                        <SelectItem value="Rrom-7">Room-7</SelectItem>
                        <SelectItem value="Rrom-8">Room-8</SelectItem>
                        <SelectItem value="Rrom-9">Room-9</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="level_of_care"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Level of Care</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {levelOfCareOptions.map((levelOfCare: any, index: number) => (
                          <SelectItem value={levelOfCare.value} key={index}>
                            {levelOfCare.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <Button type="submit" className="flex w-auto gap-2 bg-primary">
              <Loader className="size-4 animate-spin mr-2" />
              Save
            </Button>
          </form>
        </Form>
      )}
    </>
  );
};
