import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { TrackerCleaningAreaCreateDialog } from "@/modules/tracker/presentation/components/cleaning/TrackerCleaningAreaCreateDialog";
import { TrackerCleaningAreaDeleteDialog } from "@/modules/tracker/presentation/components/cleaning/TrackerCleaningAreaDeleteDialog";
import { TrackerCleaningAreaEditDialog } from "@/modules/tracker/presentation/components/cleaning/TrackerCleaningAreaEditDialog";
import { IScreeningQuestion } from "@/modules/visitors/infrastructure/interfaces/ScreeningQuestionInterface";
import { RootState } from "@/store/store";
import { Search } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function ScreeningQuestionsForm() {
    const [selectedTab, setTab] = useState("area");
    const configurations = useSelector((state: RootState) => state.configurations);
    const { t } = useTranslation();
    const [accentColor, setAccentColor] = useState("");
    let [dataSource, setDataSource] = useState<IScreeningQuestion[]>([]);

    useEffect(() => {
        setDataSource(
            [
                {
                    id: "73d94627-4d31-4856-91ec-7e6052370765",
                    data: {
                        name: "¿Preguta?",
                        status: "active",
                        who: {
                            id: "1df5b80e-31d9-4217-94b0-e1f6e5510e07",
                            first_name: "Ladie",
                            last_name: "Jejejeje"
                        }
                    },
                    location: {
                        id: "db85723a-e9e3-4178-8a48-9bfb198d0239",
                        name: "Casa 1"
                    },
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                }
            ]
        );
    }, []);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    return (
        <div>
            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <div className="flex justify-between w-100">
                        {
                            <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                Create Questions
                            </CardTitle>
                        }
                    </div>
                </CardHeader>

                <CardContent>
                    <div className="grid grid-cols-1 items-end gap-2 w-100">
                        <div className="justify-self-end">
                            <TrackerCleaningAreaCreateDialog />
                        </div>

                        {
                            dataSource.length ? (
                                <Table>
                                    <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                                        <TableRow className="bg-primary/80 hover:bg-primary/80">
                                            <TableHead className="text-white w-100">Name</TableHead>
                                            <TableHead className="text-white w-5">{t("cleaning.create.area.table.actions")}</TableHead>
                                        </TableRow>
                                    </TableHeader>

                                    <TableBody>
                                        {
                                            dataSource.length && dataSource.map(
                                                element =>
                                                <TableRow>
                                                    <TableCell className="w-100">{element.data.name}</TableCell>
                                                    <TableCell className="flex text-nowrap">
                                                        <TrackerCleaningAreaEditDialog id={element.id} name={element.data.name} status={element.data.status} />
                                                        <TrackerCleaningAreaDeleteDialog id={element.id} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            ) : (
                                <div className="flex flex-col w-100 justify-center align-center">
                                    <div className="flex justify-center items-center">
                                        <Search className="text-center w-5 mb-2" />
                                    </div>

                                    <h1 className="text-center">{t("cleaning.create.area.table.notFound")}</h1>
                                </div>
                            )
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
