import { format, getDaysInMonth } from "date-fns";
import { cn } from "@/lib/utils";

import { CalendarIcon } from "@radix-ui/react-icons"
import { Button } from "@/common/presentation/components/ui/button.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover.tsx";
import { FormField, FormItem, FormLabel, FormMessage, FormControl } from "@/common/presentation/components/ui/form.tsx";

import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar.tsx";
import { DateRangesProp } from "@/modules/calendar/domain/DialogTaskEvent";
import TimePicker from "@/common/presentation/components/TimePicker/TimePicker.tsx";
import { useEffect } from "react";

import { useTranslation } from 'react-i18next';

export const DateRanges = ({
  form,
  isAllDay,
  onChangeTimeStartDateSelected,
  onChangeTimeEndDateSelected,
  selectedTime,
  type,
  selectedDate,
  repeatEveryTypesState,
  onChangeRepeatMonthlyOn
}: DateRangesProp) => {

  const { t } = useTranslation();

  function getListMontlyOn(): object[] {
    if (!selectedDate) return [];

    const [
      day,
      totalDaysOfMonth,
      currentDate,
      dayName,
      weekNumber,
      totalWeeksByMonth
    ]: any = getDateDetailsForSelectedMonth();


    
    const ordinalNumbers: Record<string, string> = {
      1: t('calendar.first'),
      2: t('calendar.second'),
      3: t('calendar.third'),
      4: t('calendar.fourth'),
      5: t('calendar.last')
    };

    /** Obtenemos el numero del ultimo dia del mes seleccionado por el nombre **/
    const lastDayOfMonthByName: number | undefined = getLastDayOfWeekInMonth(
      totalDaysOfMonth,
      format(currentDate, 'yyyy-MM-dd'),
      dayName
    );

    const optionsRepeatOnMonth: object[] = [];

   
    /** Specific day **/
    optionsRepeatOnMonth.push({
      label: `${t('calendar.monthlyOnDay')} ${day}`,
      value: {
        repeatFrequency: 'specificDayOfMonth',
        day: day
      }
    });

    /** Ordinal weekday of month (first, second, thirth, last) **/
    optionsRepeatOnMonth.push({
      label: `${t('calendar.monthlyOnThe')} ${ordinalNumbers[weekNumber]} ${t(`calendar.${dayName.toLowerCase()}`)}`,
      value: {
        repeatFrequency: 'ordinalWeekdayOfMonth',
        week: weekNumber, // Number week in the month
        weekday: dayName,
      }
    });
    
    //{"repeatFrequency": "ordinalWeekdayOfMonth","ordinalWeek": "fourth","weekday": "Tuesday","day": 28}

    /*=====================================================================================
    Agregamos "mensualmente en le ultimo dayName" si el nombre del dia seleccionado es el 
    ultimo del mes y ademas la semana en la que esta ese dia es menor al total de las semanas 
    ya que generalmente la ultima semana es la 5 y tiene 1, 2 o tres dias de mas esto para 
    que no se repita con la linea 253: ( 5: "last" )
    =====================================================================================*/

    if (lastDayOfMonthByName === day && weekNumber < totalWeeksByMonth) {

      /* Last weekday of month */
      optionsRepeatOnMonth.push({
        label: `${t('calendar.monthlyOnThe')} ${t('last')} ${t(`calendar.${dayName.toLowerCase()}`)}`,
        value: {
          repeatFrequency: 'lastWeekdayOfMonth',
          weekday: dayName,
          lastWeek: totalWeeksByMonth, // last week of month
        }
      });

    }

    return optionsRepeatOnMonth;

  }

  function getDateDetailsForSelectedMonth(): [string, number, Date, string, number, number] {

    const currentDate = new Date(selectedDate);

    const totalDaysOfMonth: number = getDaysInMonth(currentDate);
    const dayName: string = format(currentDate, 'EEEE');
    const day: string = format(currentDate, 'dd');
    
    const [weekNumber, totalWeeksByMonth] = getWeekNumberAndTotalWeeksOfMonth(totalDaysOfMonth, day);

    return [
      day,
      totalDaysOfMonth,
      currentDate,
      dayName,
      weekNumber,
      totalWeeksByMonth
    ];

  }

  function getLastDayOfWeekInMonth(totalDays: number, date: string, dayName: string): number | undefined {
    /*
      Obtiene el ultimo dia del mes correspondiente a un dia de la semana especifico
      Por ejemplo, si se selecciona el 1 de junio de 2024 (un sabado) y se busca el ultimo sabado del mes,
      la función devolverá el número del último sábado de ese mes.
    */

    const newDate = date.split('-');

    for (let day = totalDays; day >= 1; day--) {
      /**==================================================================================
            Importante!! dejar el espacio un espacio entre la comilla y el día: -${day} `)
            ya que si no se hace, formatea la fecha mal.
       * ================================================================================***/
      const dayNameOfMonth = format(new Date(`${newDate[0]}-${newDate[1]}-${day} `), 'EEEE');

      if (dayNameOfMonth === dayName) {
        return day;
      }
    }
  }

  function getWeekNumberAndTotalWeeksOfMonth(totalDaysOfMonth: number, day: string): number[] {
    /*
      Obtenemos el numero de la semana en la que se encuentra ese dia
      y tambien el total de las semanas para ese mes 
    */

    //Array total days of the month selected 
    const arrayDays: any = Array.from({ length: totalDaysOfMonth }, (_, i) => i + 1);


    const chunkSize = 7; // Seven days per week
    let totalWeeksWithDays = []

    //Create a multi array, of seven day arrays
    for (let d = 0; d <= arrayDays.length; d += chunkSize) {
      const chunk = arrayDays.slice(d, d + chunkSize);

      totalWeeksWithDays.push(chunk)
    }

    // Find the position of the week, based on the seleted day
    const numberWeek: number = totalWeeksWithDays.findIndex((week: any) => week.find((d: any) => d == day)) + 1; // plus 1 because it starts at 0

    return [numberWeek, totalWeeksWithDays.length]; // Number week of the month seleted  AND total weeks seleted month

  }


  useEffect(() => {
    if (repeatEveryTypesState === 'month') {
      const listrepeatMonthlyOn: any = getListMontlyOn();
      onChangeRepeatMonthlyOn(listrepeatMonthlyOn);
    }
  }, [repeatEveryTypesState, selectedDate]);

  

  return (
    <>
      <div className="flex w-[100%] gap-2 items-end">
        <FormField
          control={form.control}
          name="startDate"
          render={({ field }) => (
            <FormItem className={!isAllDay ? "w-[50%] " : 'w-full'}>
              <FormLabel className="block">
                {t('calendar.startDate')} <span className="text-destructive">(*)</span>
              </FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl >
                    <Button
                      variant={'outline'}
                      className={cn(
                        'w-full font-normal',
                        !field.value && 'text-muted-foreground'
                      )}
                    >
                      {field.value ? (
                        format(field.value, t('dateFormats.dateFormat'))
                      ) : (
                        <span>{t('calendar.selectStartDate')}</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0 z-[100000]" avoidCollisions align="start">
                  <div className="rounded-md border">
                    <CustomCalendar
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      value={field.value}
                      classMonthYearSelect="z-[100000] h-[300px]"
                      MonthAndYearPicker
                    />
                  </div>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />

        {!isAllDay && (
          <FormField
            control={form.control}
            name="startTime"
            render={({ field }) => (
              <FormItem className="w-[50%] items-end">

                <Popover>

                  <PopoverTrigger asChild>
                    <FormControl >
                      <Button
                        variant={'outline'}
                        className="w-full"
                      >
                        {field.value
                          ? field.value
                          : (
                            <span>{t('calendar.time')}</span>
                          )}
                        <CalendarIcon className="ml-auto h-4 w-6 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0 z-[100000]" avoidCollisions align="start">
                    <div className="rounded-md border">
                      <TimePicker
                        onChange={onChangeTimeStartDateSelected}
                        value={selectedTime}
                      />
                    </div>
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
      </div>

      {type === "event" && (
        <div className="flex w-[100%] gap-2 items-end">

          <FormField
            control={form.control}
            name="endDate"
            render={({ field }) => (
              <FormItem className={!isAllDay ? 'w-[50%]' : 'w-full'}>
                <FormLabel className="block">
                  {t('calendar.endDate')}
                </FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl >
                      <Button
                        variant={'outline'}
                        className={cn(
                          'w-full font-normal',
                          !field.value && 'text-muted-foreground'
                        )}
                      >
                        {field.value ? (
                          format(new Date(field.value), t('dateFormats.dateFormat'))
                        ) : (
                          <span>{t('calendar.selectEndDate')}</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0 z-[100000]" avoidCollisions align="start">
                    <div className="rounded-md border">
                      <CustomCalendar
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        value={field.value}
                        classMonthYearSelect="z-[100000] h-[300px]"
                        MonthAndYearPicker
                      />
                    </div>
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />

          {!isAllDay && (
            <FormField
              control={form.control}
              name="endTime"
              render={({ field }) => (
                <FormItem className="w-[50%] items-end">
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl >
                        <Button
                          variant={'outline'}
                          className="w-full"
                        >
                          {field.value
                            ? field.value
                            : (
                              <span>{t('calendar.time')}</span>
                            )}
                          <CalendarIcon className="ml-auto h-4 w-6 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0 z-[100000]" avoidCollisions align="start">
                      <div className="rounded-md border">
                        <TimePicker 
                          onChange={onChangeTimeEndDateSelected} 
                          value={selectedTime} 
                        />
                      </div>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

        </div>
      )}

    </>
  )
}