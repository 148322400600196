import { Skeleton } from '@/common/presentation/components/ui/skeleton';

const SkeletonNotes = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <div className="p-2" key={index}>
          <div className="transition-all hover:scale-[1.01] cursor-pointer rounded-lg border p-4">
            <div className="grid">
              <div className="col-span-10 flex flex-col text-primary font-semibold w-full">
                <Skeleton className="h-4 w-24 mb-2" />
                <Skeleton className="h-4 w-36 mb-2" />
              </div>
              <div className="col-span-10 flex flex-col">
                <Skeleton className="h-4 w-full" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonNotes;
