import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Soup } from 'lucide-react';

interface Props {
  className?: string;
}

export const FoodNutrition = ({ className }: Props) => {
  return (
    <>
      <div className={className}>
        <CustomAccordion
          titleComponent={
            <div className="flex items-center justify-start text-primary">
              <Soup className="size-5 mr-2 " />
              <h1 className="font-bold">Food And Nutrition</h1>
            </div>
          }
          openOption={false}
          defaultOpen
        >
          <div className="w-full grid grid-cols-2 gap-x-4">
            <div className="flex justify-between">
              <h1 className="">Diet:</h1>
              <span>Regular</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Allergies:</h1>
              <span>None</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Food Preferences:</h1>
              <span>None</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Dietary Restrictions:</h1>
              <span>None</span>
            </div>
          </div>

          <div className="w-full grid grid-cols-2 gap-x-4">
            <div className="flex justify-between">
              <h1 className="">Feeding Assistance:</h1>
              <span>Yes</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Feeding Tube:</h1>
              <span>Yes</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Nutritional Supplements:</h1>
              <span>None</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Special Instructions:</h1>
              <span>None</span>
            </div>
          </div>

          <div className="w-full grid grid-cols-2 gap-x-4">
            <div className="flex justify-between">
              <h1 className="">Fluid Consistency:</h1>
              <span>Thin</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Dysphagia:</h1>
              <span>Yes</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Choking Risk:</h1>
              <span>Yes</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Chewing Difficulty:</h1>
              <span>Yes</span>
            </div>
          </div>

          <div className="w-full grid grid-cols-2 gap-x-4">
            <div className="flex justify-between">
              <h1 className="">Swallowing Difficulty:</h1>
              <span>Yes</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Aspiration Risk:</h1>
              <span>Yes</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Oral Care:</h1>
              <span>Yes</span>
            </div>
            <div className="flex justify-between">
              <h1 className="">Denture Care:</h1>
              <span>Yes</span>
            </div>
          </div>
        </CustomAccordion>
      </div>
    </>
  );
};
