import { create } from 'zustand';

type PrnState = {
  isOpen: boolean;
  data: any;
  locationId: string;
  onOpen: (data: any, locationId: string) => void;
  onClose: () => void;
};

export const usePrnDialog = create<PrnState>((set) => ({
  isOpen: false,
  data: null,
  resident: null,
  locationId: 'all-locations',
  onOpen: (data, locationId) => set({ isOpen: true, data, locationId }),
  onClose: () => set({ isOpen: false })
}));
