import { Label } from '@/common/presentation/components/ui/label';
import { InformationItem } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/InformationItem';
import { format } from 'date-fns';

export const ResidentInformation = ({ resident }: any) => {
  const residentAge = resident?.dob ? new Date().getFullYear() - new Date(resident?.dob).getFullYear() : 0;

  return (
    <div className="flex flex-col gap-4">
      <Label className="text-lg font-bold">Resident Information</Label>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <InformationItem label="Client's / Resident's Name" value={`${resident?.first_name} ${resident?.last_name}`} />
        <InformationItem
          label="Date of Birth"
          value={
            resident?.dob
              ? `${format(new Date(resident?.dob), 'yyyy-MM-dd')} (${residentAge} years old)`
              : 'Not Provided'
          }
        />
        <InformationItem
          label="Sex"
          value={resident?.sex ? `${resident.sex[0].toUpperCase()}${resident.sex.slice(1)}` : 'Not Provided'}
        />
        <InformationItem label="Spoken Languages" value={resident?.spoken_languages || 'Not Provided'} />
      </div>
    </div>
  );
};
