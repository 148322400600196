import Step1 from '../components/steps/Step1';
import { useCallback, useEffect, useState } from 'react';
import Step2 from '../components/steps/Step2';
import { useWizardFormData } from '../../infrastructure/providers/FormDataProvider';
import Step3 from '../components/steps/Step3';
import Step5 from '../components/steps/Step5';
import OrderSummary from '../components/OrderSummary';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientToken, fetchGeoLocation, fetchInitialData } from '../slices/SignUpSlice';
import { ICountry } from '../../domain/signup.domain';
import { Progress } from '@/common/presentation/components/ui/progress';
import { useParams } from 'react-router-dom';
import { verifySession } from '@/auth/presentation/slices/authSlice';
import { ClientStatusType } from '@/modules/subscription/domain/makerPlace.domain';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { AppDispatch } from '@/store/store';
import { useTranslation } from 'react-i18next';
import Step6 from '../components/steps/Step6';
import Step4 from '../components/steps/Step4';
import { motion, AnimatePresence } from 'framer-motion';

interface IProcessSteps {
  [step: number]: number;
}

const processSteps: IProcessSteps = {
  1: 33,
  2: 53,
  3: 77,
  4: 90,
  5: 95,
  6: 98
};

const SignUpPage = () => {
  const { formData, updateFormData } = useWizardFormData();
  const [step, setStep] = useState<number>(1);
  const [progress, setProgress] = useState<number>(processSteps[step]);
  const images = ['signup.jpg'];
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const clientStatus = useSelector((state: any) => state.configurations?.configurations?.client_status) ?? '';
  const params = useParams();
  const client_id: string = params.client_id ?? '';
  const user_id: string = params.user_id ?? '';
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {    
    if (isAuthenticated && clientStatus === ClientStatusType.informationPending) {      
      setStep(6);
    } else {
      if (client_id.length > 0 && user_id.length > 0) {
        loginByParams(client_id, user_id);
      }
    }
  }, [isAuthenticated, clientStatus]);

  useEffect(() => {
    setProgress(processSteps[step]);
  }, [step]);

  const loginByParams = async (clientId: string, userId: string) => {
    const response = await dispatch<any>(
      fetchClientToken({
        client_id: clientId,
        user_id: userId
      })
    );

    if (!response.error) {
      const { token } = response.payload;
      localStorage.setItem('access_token', token);
      await dispatch<any>(verifySession());
      return;
    }
  };

  const getInitialData = useCallback(async () => {
    const country = await getClientLocation();
    const response = await dispatch<any>(fetchInitialData(country));

    if (response.error) {
      return;
    }

    const { client_country, countries }: { client_country: string; countries: ICountry[] } = response.payload;
    const countryInfo = countries.find((country: ICountry) => country.code === client_country);

    updateFormData({
      ...formData,
      client_country: countryInfo
    });
  }, []);

  const getClientLocation = async () => {
    try {
      let country = 'us';
      const response = await dispatch<any>(fetchGeoLocation());
      if (!response.error) {
        const { payload } = response;
        country = payload.country.toLocaleLowerCase();
      }
      return country;
    } catch (error) {
      return 'us';
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 handleNextStep={handleNextStep} />;
      case 2:
        return <Step2 handleBackStep={handleBackStep} handleNextStep={handleNextStep} />;
      case 3:
        return <Step3 handleBackStep={handleBackStep} handleNextStep={handleNextStep} />;
      case 4:
        return <Step4 handleBackStep={handleBackStep} handleNextStep={handleNextStep} />;
      case 5:
        return <Step5 handleBackStep={handleBackStep} handleNextStep={handleNextStep} />;
      case 6:
        return <Step6 />;
      default:
        return null;
    }
  };

  const handleNextStep = (step: number) => {
    setProgress(processSteps[step + 1]);
    setStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = (step: number) => {
    setProgress(processSteps[step - 1]);
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <>
    <div className="relative md:h-auto lg:h-full flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-5 lg:px-0">
      <div className="relative hidden h-screen flex-col bg-muted p-10 text-white dark:border-r lg:flex col-span-2">
        <div className="absolute inset-0">
          <Swiper
            spaceBetween={30}
            pagination={{ clickable: true }}
            navigation={true}
            loop={images.length > 1}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false
            }}
            modules={[Pagination, Autoplay]}
            className="h-full w-full"
            style={{
              '--swiper-pagination-bottom': '20px'
            }}
          >
            {images.map((image) => (
              <SwiperSlide key={image}>
                <img
                  src={`/img/${image}`}
                  alt="Image 1"
                  className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <AnimatePresence>
            { [3, 4, 5].includes(step) && (
              <motion.div
                className="absolute inset-0 flex items-center justify-center z-20"
                initial={{ opacity: 0.6, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0.6, scale: 0.9 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                <div className="flex justify-center w-2/3">
                  <OrderSummary products={formData.summary} frecuency="monthly" currency={formData.client_country.currency} />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        <div className="relative z-20 flex items-center text-2xl font-medium text-black">
          <img src="/img/logo_redondo.png" alt="Caring Data Logo" width={60} height={60} className="mr-2" />
          Caring Data
        </div>

        <div className="relative z-20 mt-auto bg-black/50">
          <blockquote className="space-y-2">
            <p className="text-2xl">&ldquo;{t('signUp.propelYourAssistedLiving')}&rdquo;</p>
          </blockquote>
        </div>
      </div>

      <div className="h-screen flex lg:w-full col-span-3">
        <div className="mx-auto grid w-full mt-0 md:mt-16">
          <div className="flex justify-center flex-1 px-3 md:px-0 ">{renderStep()}</div>
        </div>
      </div>

      <Progress value={progress} className="fixed bottom-0 left-0 right-0 rounded-none rounded-t-none z-[9999]" />
    </div>
  </>
  );
};

export default SignUpPage;
