import { create } from 'zustand';

type TimeGivenState = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const useTimeGiven = create<TimeGivenState>((set) => ({
  isOpen: false,
  date: undefined,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false })
}));
