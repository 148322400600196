
import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';


const DepartmentService = {
    getDepartments: async () => {
      let url = '/departments';
  
      try {
        const response = await axios.get(url);
        return response.data;
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data.message || 'Error';
          throw new Error(errorMessage);
        } else if (error.request) {
          throw new Error('No response received from the server');
        } else {
          throw new Error('An error occurred while processing the request');
        }
      }
    },
  };


  export default DepartmentService;