import { CreditCardI } from "../../domain/payments.domain";

const PaymentsAdapter = {
    createPaymentMethod: (creditCard: CreditCardI) => {
        return {
            card_number: creditCard.card_number,
            cvv: creditCard.card_cvv,
            expiry_card: creditCard.card_expiry
        };
    }
};

export default PaymentsAdapter;