import { create } from 'zustand';

type EmarPrnHistoryState = {
  isOpen: boolean;
  data: any;
  onOpen: (data: any) => void;
  onClose: () => void;
};

export const useEmarPrnHistory = create<EmarPrnHistoryState>((set) => ({
  isOpen: false,
  data: null,
  onOpen: (data) => set({ isOpen: true, data }),
  onClose: () => set({ isOpen: false })
}));
