import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ConfigurationServices = {
  updateAccentColor: async (data: any) => {
    const response = await axios.post('/configurations/change-accentcolor', { ...data });
    return response;
  },
  getClientConfiguration: async () => {
    const response = await axios.get('/configurations');
    return response;
  }
};

export default ConfigurationServices;
