import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITrackerCleaningArea, ITrackerCleaningAreaDeleteDTO, ITrackerCleaningAreaDTO, ITrackerCleaningAreaRetrieveDTO, ITrackerCleaningAreaUpdateDTO, ITrackerCleaningSummary, ITrackerCleaningSummaryDayRetrieveDTO, ITrackerCleaningSummaryDeleteDTO, ITrackerCleaningSummaryDTO, ITrackerCleaningSummaryRetrieveDTO, ITrackerCleaningSummaryUpdateDTO, ITrackerCleaningTask, ITrackerCleaningTaskDeleteDTO, ITrackerCleaningTaskDTO, ITrackerCleaningTaskRetrieveDTO, ITrackerCleaningTaskUpdateDTO } from '../components/cleaning/TrackerCleaningMockup';
import TrackerCleaningService from '../../infrastructure/services/TrackerCleaningService';

interface ITrackerCleaningState {
  summaries: ITrackerCleaningSummaryDTO[];
  daySummaries: ITrackerCleaningSummary[];
  activeAreas: ITrackerCleaningArea[];
  areas: ITrackerCleaningArea[];
  activeTasks: ITrackerCleaningTask[];
  tasks: ITrackerCleaningTask[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: ITrackerCleaningState = {
    summaries: [],
    daySummaries: [],
    activeAreas: [],
    areas: [],
    activeTasks: [],
    tasks: [],
    status: 'idle',
    error: null
};

export const getAreas = createAsyncThunk("trackerCleaning/getArea", async (body: ITrackerCleaningAreaRetrieveDTO) => {
  const response = await TrackerCleaningService.getAreas(body);
  return response.data;
});

export const createArea = createAsyncThunk("trackerCleaning/createArea", async (body: ITrackerCleaningAreaDTO) => {
    const response = await TrackerCleaningService.createArea(body);
    return response.data;
});

export const updateArea = createAsyncThunk("trackerCleaning/updateArea", async (body: ITrackerCleaningAreaUpdateDTO) => {
    const response = await TrackerCleaningService.updateArea(body);
    return response.data;
});

export const deleteArea = createAsyncThunk("trackerCleaning/deleteArea", async (body: ITrackerCleaningAreaDeleteDTO) => {
    const response = await TrackerCleaningService.deleteArea(body);
    return response.data;
});

export const getTasks = createAsyncThunk("trackerCleaning/getTask", async (body: ITrackerCleaningTaskRetrieveDTO) => {
    const response = await TrackerCleaningService.getTasks(body);
    return response.data;
});

export const createTask = createAsyncThunk("trackerCleaning/createTask", async (body: ITrackerCleaningTaskDTO) => {
    const response = await TrackerCleaningService.createTask(body);
    return response.data;
});

export const updateTask = createAsyncThunk("trackerCleaning/updateTask", async (body: ITrackerCleaningTaskUpdateDTO) => {
    const response = await TrackerCleaningService.updateTask(body);
    return response.data;
});

export const deleteTask = createAsyncThunk("trackerCleaning/deleteTask", async (body: ITrackerCleaningTaskDeleteDTO) => {
    const response = await TrackerCleaningService.deleteTask(body);
    return response.data;
});

export const getSummaries = createAsyncThunk("trackerCleaning/getSummary", async (body: ITrackerCleaningSummaryRetrieveDTO) => {
    const response = await TrackerCleaningService.getSummaries(body);
    return response.data;
});

export const getDaySummaries = createAsyncThunk("trackerCleaning/todaySummaries", async (body: ITrackerCleaningSummaryDayRetrieveDTO) => {
    const response = await TrackerCleaningService.getDaySummaries(body);
    return response.data;
});

export const createSummary = createAsyncThunk("trackerCleaning/createSummary", async (body: ITrackerCleaningSummaryDTO) => {
    const response = await TrackerCleaningService.createSummary(body);
    return response.data;
});

export const updateSummary = createAsyncThunk("trackerCleaning/updateSummary", async (body: ITrackerCleaningSummaryUpdateDTO) => {
    const response = await TrackerCleaningService.updateSummary(body);
    return response.data;
});

export const deleteSummary = createAsyncThunk("trackerCleaning/deleteSummary", async (body: ITrackerCleaningSummaryDeleteDTO) => {
    const response = await TrackerCleaningService.deleteSummary(body);
    return response.data;
});

const trackerCleaning = createSlice({
  name: 'trackerCleaning',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getAreas.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getAreas.fulfilled, (state, action) => {
      state.status = 'idle';
      state.activeAreas = (action.payload.result as ITrackerCleaningArea[]).filter((value) => value.status === "active");
      state.areas = action.payload.result;
    });

    builder.addCase(getAreas.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getTasks.pending, (state) => {
        state.status = 'loading';
        state.error = null;
    });
    
    builder.addCase(getTasks.fulfilled, (state, action) => {
        state.status = 'idle';
        state.activeTasks = (action.payload.result as ITrackerCleaningTask[]).filter((value) => value.status === "active");
        state.tasks = action.payload.result;
    });

    builder.addCase(getTasks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
    });

    builder.addCase(getSummaries.pending, (state) => {
        state.status = 'loading';
        state.error = null;
    });
    
    builder.addCase(getSummaries.fulfilled, (state, action) => {
        state.status = 'idle';
        state.summaries = action.payload.result;
    });

    builder.addCase(getSummaries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
    });

    builder.addCase(getDaySummaries.pending, (state) => {
        state.status = 'loading';
        state.error = null;
    });
    
    builder.addCase(getDaySummaries.fulfilled, (state, action) => {
        state.status = 'idle';
        state.daySummaries = action.payload.result;
    });

    builder.addCase(getDaySummaries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
    });
  }
});

export default trackerCleaning.reducer;
