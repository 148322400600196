import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { PlanOfCareFormCard } from '@/modules/residents/presentation/components/PlanOfCareForm/PlanOfCareFormCard';
import * as residentConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlanOfCareHistory } from './PlanOfCareHistory';

export const PlanOfCare = () => {
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const { configurations } = useSelector((state: RootState) => state.residents.configurations);
  const dispatch = useDispatch<AppDispatch>();

  const { getFormHistory } = useFormsStore();

  useEffect(() => {
    getFormHistory(resident?.id, 'appraisal_needs_and_services');
  }, [resident]);

  useEffect(() => {
    if (Object.keys(configurations).length === 0) {
      dispatch(residentConfigurationsActions.getResidentsConfigurations());
    }
  }, [configurations, dispatch]);

  return (
    <div className="mt-8 flex flex-col gap-4">
      <PlanOfCareFormCard />
      <PlanOfCareHistory />
    </div>
  );
};
