import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import {
  Select as ShadSelect,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem
} from '@/common/presentation/components/ui/select';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { useEffect, useState } from 'react';
import NoteService from '../../../infrastructure/services/NoteService';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import React from 'react';
import { CrudState, FormData } from '@/modules/notes/domain/note.domain';
import { QuillJS } from '../common/quilljs';
import { Loader } from 'lucide-react';
import { SelectNoteTo } from './elements/SelectNoteTo';
import { UrgentNoteCheckbox } from './elements/urgentNoteCeheckbox';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import { SelectNoteCategory } from '../common/SelectNoteCategory';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import { NoteTranslator } from '../common/noteTranslator';

interface CreateNoteProps {
  crudState: CrudState;
  setCrudState: (crudState: CrudState) => void;
}

export function CreateNote({ crudState, setCrudState }: CreateNoteProps) {
  // Redux
  const clientId = useSelector((state: RootState) => state.configurations.configurations.client_id);
  const drafts = useSelector((state: RootState) => state.notes.drafts.data);
  const quickNotes = useSelector((state: RootState) => state.notes.quickNotes);

  // Init Values
  const { t } = useTranslation();
  const defaultFormData: FormData = {
    content: '',
    html_content: '',
    status: 'active',
    user_id: [],
    category_id: '',
    resident_id: null,
    staff_id: [],
    location_id: [],
    department_id: [],
    reference: 'notePage',
    images: null,
    client_id: clientId,
    draft_id: null,
    urgent: false
  };

  // Hooks
  const [isLoading, setIsLoading] = useState(false);
  const [draftContent, setDraftContent] = useState('');
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const [quickNotesT, setQuickNotesT] = useState(quickNotes);

  useEffect(() => {
    if (crudState.isFinishingDraft) {
      let draft = drafts.filter((item: any) => item.id == crudState.itemId);
      if (draft.length > 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['draft_id']: draft[0].id
        }));

        setDraftContent(draft[0].content);
      }
    }
  }, [crudState]);

  // Tools
  const { params, setParam } = useRouteParams();

  // Behaviors
  const onResidentSelect = (resident: any) => {
    setFormData({
      ...formData,
      ['resident_id']: resident
    });
  };

  const handleSelectNoteTo = (e: any) => {
    const recipients: string[] = [];

    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        recipients.push(e[key].value);
      }
    }

    const staffItems = recipients.filter((item) => item.endsWith('-staff')).map((item) => item.replace('-staff', ''));

    const departmentsItems = recipients
      .filter((item) => item.endsWith('-department'))
      .map((item) => item.replace('-department', ''));

    const locationItems = recipients
      .filter((item) => item.endsWith('-location') && item !== 'all-houses-location')
      .map((item) => item.replace('-location', ''));

    setFormData({
      ...formData,
      ['user_id']: staffItems,
      ['location_id']: locationItems,
      ['department_id']: departmentsItems
    });
  };

  const handleSelectCategory = (value: any) => {
    const filteredQuickNotes = quickNotes.filter((note) => note.category_id === value);

    setQuickNotesT(filteredQuickNotes);

    setFormData({
      ...formData,
      category_id: value
    });
  };

  const handleSelectQuicknote = (value: any) => {
    setDraftContent(value);
  };

  const handleQuillJs = (values: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...values
    }));

    if (values.content.length > 0 && values.content != '\n') {
      if (crudState.isFinishingDraft) {
        setCrudState(NoteHelperService.defineState({ ...crudState, action: updateDraft }));
        return;
      }
      setCrudState(NoteHelperService.defineState({ ...crudState, action: saveDraft }));
      return;
    }
    setCrudState(NoteHelperService.defineState({ ...crudState, action: () => {} }));
  };

  const handleUrgentNoteCheckbox = (value: boolean) => {
    setFormData({
      ...formData,
      ['urgent']: value
    });
  };

  const saveDraft = async (state: CrudState) => {
    if (state.isCreating) {
      return;
    }

    await NoteService.createDraft(formData);

    toast({
      title: 'Draft saved',
      description: ``
    });
  };

  const updateDraft = async (state: CrudState) => {
    if (state.isFinishingDraft) {
      return;
    }

    await NoteService.updateDraft(formData);

    toast({
      title: 'Draft updated',
      description: ``
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setCrudState(NoteHelperService.defineState({ ...crudState, action: () => {} }));

    setIsLoading(true);
  };

  useEffect(() => {
    if (isLoading) {
      const performSecondStateUpdate = async () => {
        await NoteService.createNote(formData);

        setCrudState(NoteHelperService.defineState({}));
        setIsLoading(false);
      };

      performSecondStateUpdate();
    }
  }, [isLoading]);

  return (
    <div className="flex flex-col h-full p-4 pr-1">
      <div className="flex flex-col  h-[95vh]">
        <ResidentSelector locationId={params?.l} onSelect={onResidentSelect} setUrlParams={false} />
        <ScrollArea className="h-[86vh]">
          <Card className="rounded-t-none border-t-0">
            <CardContent>
              <form onSubmit={handleSubmit}>
                <div className="pt-3">
                  <SelectNoteTo handleSelectNoteTo={handleSelectNoteTo} />
                </div>
                <div className="flex justify-between pt-3">
                  <SelectNoteCategory handleSelectCategory={handleSelectCategory} />
                  <ShadSelect name="quickNote" onValueChange={handleSelectQuicknote}>
                    <SelectTrigger className="w-[49%]" name="quickNote">
                      <SelectValue placeholder={t('notes.quickNote')} />
                    </SelectTrigger>
                    <SelectContent>
                      {quickNotesT?.map((item: any) => (
                        <SelectItem key={item.id} value={item.content}>
                          {item.title}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </ShadSelect>
                </div>
                <div className="w-full pt-8">
                  <ScrollArea className="h-[15vh]">
                    <QuillJS onChange={handleQuillJs} value={draftContent} crudState={crudState} translator={true} />
                  </ScrollArea>
                </div>
                <div className="pt-16 flex justify-between">
                  <UrgentNoteCheckbox onCheck={handleUrgentNoteCheckbox} />
                  <Button type="submit" disabled={isLoading} size="sm" className="ml-auto">
                    {isLoading ? <Loader className="mr-2 h-4 w-4 animate-spin" /> : t('notes.send')}
                  </Button>
                </div>
              </form>
              <div className="flex ml-auto">
                <NoteTranslator text={formData.content} setTranslation={setDraftContent} className="hidden" />
              </div>
            </CardContent>
          </Card>
        </ScrollArea>
      </div>
    </div>
  );
}
