import renderActionsColumn from './renderActionsColumn';

const columnsReportMedications: Column[] = [
  {
    key: 'month_name',
    labelTranslationKey: 'reportDate',
    numeric: false,
    visible: true,
    static: true,
    sortable: true,
    render: (data) => data.month_name
  },
  {
    key: 'pdf',
    labelTranslationKey: 'pdf',
    numeric: false,
    visible: true,
    sortable: true,
    editable: true,
    render: renderActionsColumn
  }
];

export default columnsReportMedications;
