import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/common/presentation/components/ui/command';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, CheckIcon, Loader, TimerIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useNewExceptionDialog } from '@/modules/emar/infrastructure/hooks/UseNewException';

interface Props {
  defaultValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  disabled?: boolean;
}

const formSchema = z.object({
  date: z.date({
    required_error: 'Date is required'
  }),
  time: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: 'You have to select at least one time.'
  }),
  reason: z
    .string({
      required_error: 'Reason is required'
    })
    .min(3, 'Reason must be at least 3 characters')
    .max(255, 'Reason must be at most 255 characters'),
  details: z.string(),
  discount_unit: z.boolean().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const ExceptionForm = ({ defaultValues, onSubmit, disabled = false }: Props) => {
  const { data } = useNewExceptionDialog();
  const times = data?.time || [];

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues
  });

  const handleSubmit = async (formData: FormValues) => {
    onSubmit(formData);
  };

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 px-1">
          <Separator className="col-span-full" />

          <div>
            <h3 className="text-lg font-semibold">Medication</h3>
            <p className="text-muted-foreground">Medication: {data?.medication?.name}</p>
          </div>

          <FormField
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormItem className="col-span-9">
                <FormLabel>
                  Date <span className="text-destructive">(*)</span>
                </FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={'outline'}
                        className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                        disabled={disabled}
                      >
                        {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date of exception</span>}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <div className="rounded-md border">
                      <CustomCalendar onChange={field.onChange} value={field.value} MonthAndYearPicker />
                    </div>
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="time"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>
                    Time <span className="text-destructive">(*)</span>
                  </FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={'outline'}
                          className={cn(
                            'w-full flex items-center justify-start font-normal',
                            !field.value && 'text-muted-foreground'
                          )}
                          disabled={disabled}
                        >
                          <TimerIcon className="mr-2 size-4 text-muted-foreground" />
                          <span>Times</span>
                          {field.value?.length > 0 && (
                            <>
                              <Separator orientation="vertical" className="mx-2 h-4" />
                              <Badge variant="outline" className="rounded-sm px-1 font-normal lg:hidden">
                                {field.value.length}
                              </Badge>
                              <div className="hidden space-x-1 lg:flex">
                                {field.value.length > 5 ? (
                                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                                    {field.value.length} selected
                                  </Badge>
                                ) : (
                                  field.value.map((time) => (
                                    <Badge variant="secondary" className="rounded-sm px-1 font-normal" key={time}>
                                      {time}
                                    </Badge>
                                  ))
                                )}
                              </div>
                            </>
                          )}
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="p-1">
                      <Command className="w-full">
                        <CommandInput placeholder="Search time" />
                        <CommandList>
                          <CommandEmpty>No results found.</CommandEmpty>
                          <CommandGroup>
                            {times.map((time: any, index: number) => {
                              const isSelected = field.value.includes(time);

                              return (
                                <CommandItem
                                  value={time}
                                  key={index}
                                  onSelect={() =>
                                    field.onChange(
                                      isSelected ? field.value.filter((t) => t !== time) : [...field.value, time]
                                    )
                                  }
                                >
                                  <div
                                    className={cn(
                                      'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                      isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                                    )}
                                  >
                                    <CheckIcon className={cn('h-4 w-4')} />
                                  </div>
                                  {time}
                                </CommandItem>
                              );
                            })}
                          </CommandGroup>

                          {field.value.length > 0 && (
                            <>
                              <CommandSeparator className="mb-1" />

                              <CommandItem
                                onSelect={() => {
                                  field.onChange([]);
                                }}
                                className="justify-center text-center"
                              >
                                Clear times
                              </CommandItem>
                            </>
                          )}
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="reason"
            render={({ field }) => (
              <FormItem className="col-span-full">
                <FormLabel>
                  Reason <span className="text-destructive">(*)</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} onChange={(e) => field.onChange(e.target.value)} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="details"
            render={({ field }) => (
              <FormItem className="col-span-full">
                <FormLabel>Details</FormLabel>
                <FormControl>
                  <Textarea {...field} onChange={(e) => field.onChange(e.target.value)} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="discount_unit"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox checked={field.value} onCheckedChange={field.onChange} disabled={disabled} />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Discount unit</FormLabel>
                  <FormDescription>
                    Enable this option if you want to discount the medication by one unit, If dispensed do not check
                    otherwise it will be discounted twice.
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />

          <Separator className="col-span-full" />

          <Button type="submit" className="col-span-full" disabled={disabled}>
            {disabled ? <Loader className="size-4 mr-2 animate-spin" /> : null}
            Submit
          </Button>
        </form>
      </Form>
    </div>
  );
};
