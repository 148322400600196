import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { CustomFieldsForm } from './CustomFieldsForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import * as staffConfigurationsActions from '@/modules/staff/presentation/slices/staffConfigurationsSlice';
import { useStaffNewCustomField } from '@/modules/settings/infrastructure/hooks/use-staff-new-custom-field';

export const StaffNewCustomFieldsDialog = () => {
  const { isOpen, onClose } = useStaffNewCustomField();
  const { configurations, status } = useSelector((state: RootState) => state.staff.configurations);

  const dispatch = useDispatch<AppDispatch>();

  const onSubmit = async (values: any) => {
    const newConfigurations = [
      ...configurations.custom_form_fields,
      {
        ...values,
        order: configurations.custom_form_fields.length + 1
      }
    ];

    const response = await dispatch(
        staffConfigurationsActions.postStaffConfigurations({ custom_form_fields: newConfigurations })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      onClose();
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">New Custom Field</DialogTitle>
          </DialogHeader>
          <CustomFieldsForm
            onSubmit={onSubmit}
            disabled={status === 'loading'}
            defaultValues={{
              label: '',
              name: '',
              type: '',
              placeholder: '',
              default_value: '',
              required: false
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
