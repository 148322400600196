import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { ITrackerMenstrutationDeleteDTO, ITrackerMenstrutationDTO, ITrackerMenstrutationRetrieveDTO, ITrackerMenstrutationUpdateDTO } from '../../presentation/components/menstruation/TrackerMenstruationMockup';

const TrackerMenstruationService = {
  getMenstruations: async (body: ITrackerMenstrutationRetrieveDTO) => {
    try {
      return await axios.post(`trackerMenstruation/menstruations`, body);
    } catch (error) {
      throw new Error('Error fetching menstruation: ' + error);
    }
  },
  createMenstruation: async (body: ITrackerMenstrutationDTO) => {
    try {
      return await axios.post(`trackerMenstruation/menstruation`, body);
    } catch (error) {
      throw new Error('Error fetching menstruation: ' + error);
    }
  },
  updateMenstruation: async (body: ITrackerMenstrutationUpdateDTO) => {
    try {
      return await axios.put(`trackerMenstruation/menstruation`, body);
    } catch (error) {
      throw new Error('Error fetching menstruation: ' + error);
    }
  },
  deleteMenstruation: async (body: ITrackerMenstrutationDeleteDTO) => {
    try {
        return await axios({
            method: "delete",
            url: `trackerMenstruation/menstruation`,
            data: body
        });
    } catch (error) {
      throw new Error('Error fetching menstruation: ' + error);
    }
  }
};

export default TrackerMenstruationService;
