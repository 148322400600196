import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { OverviewTableActions } from './OverviewTableActions';
import { OverviewPrnTableActions } from './OverviewPrnTableActions';

export const overviewPrnColumns: ColumnDef<any>[] = [
  {
    id: 'prn_date',
    header: 'Date PRN Medication',
    cell: ({ row }) => {
      console.log({ row: row?.original });

      return format(new Date(row?.original?.emar_medication_prn?.dispensed_at), 'MM/dd/yyyy hh:mm a');
    }
  },
  {
    id: 'medication',
    header: 'Medication',
    cell: ({ row }) => {
      return row?.original?.medication?.name;
    }
  },
  {
    id: 'reason',
    header: 'Reason',
    cell: ({ row }) => {
      return row?.original?.emar_medication_prn?.reason;
    }
  },
  {
    id: 'result',
    header: 'Result',
    cell: ({ row }) => {
      return row?.original?.emar_medication_prn?.result;
    }
  },
  {
    id: 'resident',
    header: 'Resident',
    cell: ({ row }) => {
      return `${row?.original?.resident?.first_name} ${row?.original?.resident?.last_name}`;
    }
  },

  {
    id: 'staff',
    header: 'Staff',
    cell: ({ row }) => {
      return `${row?.original?.emar_medication_prn?.created_by?.first_name} ${row?.original?.emar_medication_prn?.created_by?.last_name}`;
    }
  },
  {
    id: 'actions',
    cell: ({ row }) => <OverviewPrnTableActions data={row.original} />
  }
];
