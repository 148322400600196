import { Button } from '@/common/presentation/components/ui/button';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { Dialog, DialogContent, DialogTitle } from '@/common/presentation/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { useNewMar } from '@/modules/medications/infrastructure/hooks/use-new-mar';
import * as medicationActions from '@/modules/medications/presentation/slices/medicationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { ResidentSelector } from './ResidentSelector';

const FormSchema = z.object({
  resident_id: z
    .string({
      required_error: 'A resident is required.'
    })
    .min(1, 'A resident is required.'),
  date_range: z.date({
    required_error: 'A date is required.'
  }),
  notes: z.boolean().optional(),
  sort_by: z.enum(['medication', 'hour'], {
    required_error: 'A sort by is required.'
  })
});

type FormValues = z.infer<typeof FormSchema>;

const defaultValues: Partial<FormValues> = {
  resident_id: '',
  date_range: undefined,
  notes: false,
  sort_by: 'medication'
};

export const MarDocumentDialog = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { isOpen, onClose, locationId, residentId } = useNewMar();

  const { status } = useSelector((state: RootState) => state.medications);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmit = async (values: FormValues) => {
    const response = await dispatch(
      medicationActions.GenerateMedicationPDF({
        residentId: values.resident_id,
        type: 'emar',
        data: values
      })
    );

    if (response.meta.requestStatus === 'rejected') {
      toast({
        title: 'Failed to generate PDF',
        description: 'An error occurred while generating the PDF',
        variant: 'destructive'
      });
      return;
    }

    const blob = new Blob([response.payload], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url); // Abre el PDF en una nueva pestaña

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogTitle>MAR Details</DialogTitle>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="resident_id"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Resident</FormLabel>
                    <FormControl>
                      <ResidentSelector
                        locationId={locationId}
                        residentId={residentId}
                        onChange={field.onChange}
                        placeholder="Select a resident"
                        disabled={status === 'loading'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="date_range"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Date</FormLabel>
                    <FormControl>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={'outline'}
                            className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                            disabled={status === 'loading'}
                          >
                            {field.value ? format(field.value, 'MM/dd/yyyy') : <span>MAR date</span>}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar mode="single" selected={field.value} onSelect={field.onChange} initialFocus />
                        </PopoverContent>
                      </Popover>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem className="flex items-center gap-2">
                    <FormLabel className="mt-[8px]">Include Notes Page</FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked);
                        }}
                        disabled={status === 'loading'}
                      />
                      {/* <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex flex-col space-y-1"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="none" />
                          </FormControl>
                          <FormLabel className="font-normal">Do not include Medication Notes</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="end" />
                          </FormControl>
                          <FormLabel className="font-normal">Include Medication Notes at the end of the MAR</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="each_page" />
                          </FormControl>
                          <FormLabel className="font-normal">Collate Medication Notes after each MAR page</FormLabel>
                        </FormItem>
                      </RadioGroup> */}
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="sort_by"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Sort By</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex flex-col space-y-1"
                        disabled={status === 'loading'}
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="medication" />
                          </FormControl>
                          <FormLabel className="font-normal">Medication</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="hour" />
                          </FormControl>
                          <FormLabel className="font-normal">Hour</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit" variant={'default'} disabled={status === 'loading'} className="w-full">
                {status === 'loading' && <Loader className="mr-2 h-4 w-4 animate-spin" />}
                Generate
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
