import * as authActions from '@/auth/presentation/slices/authSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as configActions from '@/common/presentation/slices/configurationSlice';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import * as residentsActions from '@/modules/residents/presentation/slices/residentsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { createContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setNavigateFunction } from '../middleware/navigation';

interface AuthContextValues {
  isAuthenticated: boolean;
  isLoading: boolean;
  roles: string[];
  login: (username: string, password: string) => void;
  logout: () => void;
}

const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  isLoading: true,
  roles: [],
  login: () => {},
  logout: () => {}
};

export const AuthContext = createContext<AuthContextValues>(defaultAuthContextValues);

interface AuthContextProviderProps {
  children: JSX.Element;
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const { isLoading, isAuthenticated, roles } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleAuthSuccess = (payload: any) => {
    payload.access_token && localStorage.setItem('access_token', payload.access_token);
    dispatch(configActions.setConfigurations(payload.configuration));
    dispatch(locationsActions.setLocations(payload.user.locations));
    dispatch(residentsActions.setResidents(payload.user.residents));
    dispatch(authActions.setRoles(payload.user.roles));
  };

  const handleAuthFailure = (error?: any) => {
    localStorage.removeItem('access_token');
    dispatch(authActions.unSetAuth());
    dispatch(locationsActions.unSetLocations());
    dispatch(configActions.unSetConfigurations());

    const path = location.pathname;
    const signUpPathRegex = /^\/sign-up(\/.*)?$/;

    if (!signUpPathRegex.test(path)) {
      navigate('/login', { replace: true });
      if (error) {
        toast({
          title: 'Authenticacion Error',
          description: `${error.message}. Please try again.`,
          variant: 'destructive'
        });
      }
    }
  };

  const login = async (username: string, password: string) => {
    try {
      const response: any = await dispatch(authActions.login({ username, password }));
      if (response.meta.requestStatus === 'fulfilled') {
        handleAuthSuccess(response.payload);
      } else {
        handleAuthFailure(response.error);
      }
    } catch (error) {
      handleAuthFailure(error);
    }
  };

  const logout = async () => {
    try {
      const response = await dispatch(authActions.logout());
      if (response.meta.requestStatus === 'fulfilled') {
        handleAuthFailure();
      }
    } catch (error) {
      handleAuthFailure(error);
    }
  };


  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  useEffect(() => {
    const verifySession = async () => {
      const accessToken = localStorage.getItem('access_token');
      const path = location.pathname;
      const signUpPathRegex = /^\/sign-up(\/.*)?$/;

      if (!accessToken && !signUpPathRegex.test(path)) {
        dispatch(authActions.unSetAuth());
        dispatch(locationsActions.unSetLocations());
        dispatch(configActions.unSetConfigurations());
        navigate('/login', { replace: true });
        return;
      }

      try {
        const response: any = await dispatch(authActions.verifySession());
        if (response.meta.requestStatus === 'fulfilled') {
          handleAuthSuccess(response.payload);
        } else {
          handleAuthFailure(response.error);
        }
      } catch (error) {
        handleAuthFailure(error);
      }
    };

    verifySession();
  }, [dispatch]);

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      roles,
      login,
      logout
    }),
    [isAuthenticated, isLoading, roles]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
