import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StaffService from '../../infrastructure/services/StaffService';

interface UserState {
  user: any[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
  dataStaffSelector : any[];
}
const initialState: UserState = {
  user: [],
  status: 'idle',
  error: null,
  dataStaffSelector: [],
};

export const updateStaffPersonalInformationForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffPersonalInformation(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);

export const getStaffSelector = createAsyncThunk('staff/getStaffSelector',
  async (params:any) => {
    try {
      const {locationId, roles}  = params;
      const response = await StaffService.getStaffSelector(locationId, roles);
        return response.data;
    } catch (error: any) {
        const message = error.response.data.message;
        throw new Error(message !== undefined ? message : 'Error get user: ' + error);
    }
  }
);

export const createUserForm = createAsyncThunk('userForm/createUserForm', async (data: any) => {
  try {
    const response = await StaffService.createStaffMember(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error creating user: ' + error);
  }
});



const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user.push(action.payload);
    },
    removeUser: (state, action) => {
      state.user = state.user.filter((user) => user.id !== action.payload);
    }
  
  },
  extraReducers: (builder) => {
    builder.addCase(getStaffSelector.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getStaffSelector.fulfilled, (state, action) => {
      state.status = 'idle';
      state.dataStaffSelector = action.payload;
    });
    
    builder.addCase(getStaffSelector.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});
export const { addUser, removeUser } = usersSlice.actions;
export default usersSlice.reducer;
