import { Pill } from 'lucide-react';
import { AlertCard } from './alert-card';
import { useTranslation } from 'react-i18next';

export const MedicationAlerts = () => {
  const medication_alerts = [
    {
      id: 1,
      date: '2021-09-01',
      name: 'John Doe',
      alert: 'Medication Error',
      status: 'Active'
    },
    {
      id: 2,
      date: '2021-09-01',
      name: 'Jane Doe',
      alert: 'Medication Error',
      status: 'Active'
    },
    {
      id: 3,
      date: '2021-09-01',
      name: 'James Doe',
      alert: 'Medication Error',
      status: 'Active'
    },
    {
      id: 4,
      date: '2021-09-01',
      name: 'Jill Doe',
      alert: 'Medication Error',
      status: 'Active'
    }
  ];
  const { t } = useTranslation();

  return (
    <>
      <AlertCard title={t('dashboard.medicationAlerts')} alerts={medication_alerts} icon={Pill} custom={true}>
        <div className="p-2">
          <div className="rounded-lg border p-4">
            <div className="grid">
              <div className="col-span-10 flex flex-col justify-center text-center w-full">
                <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                  {t('dashboard.noAlerts')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </AlertCard>
    </>
  );
};
