import { createSlice } from '@reduxjs/toolkit';

interface Message {
  title: {label: string, variables: Object};
  message:  {label: string, variables: Object};
  redirect: string;
  type: string;
  buttonText: {label: string, variables: Object};
  excludedRoutes: []
};

interface Object {
    [key: string]: {
      [keyMessage: string]: Message;
  };
};

interface BannerState {
  messages: Object;
}

const initialState: BannerState = {
  messages: {}
};

const BannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    showMessage(state, action) {
      if (!state.messages[action.payload.category]) {
        state.messages[action.payload.category] = {};
      }
      state.messages[action.payload.category][action.payload.keyMessage] = {
        title: action.payload.title,
        message: action.payload.message,
        redirect: action.payload.redirect,
        type: action.payload.type,
        buttonText: action.payload.buttonText,
        excludedRoutes: action.payload?.excludedRoutes
      };
    },
    removeMessage(state, action) {
      const messages = {...state.messages};
      if (messages[action.payload.category] && messages[action.payload.category][action.payload.keyMessage]) {
        const categoryMessages = { ...messages[action.payload.category] };
        delete categoryMessages[action.payload.keyMessage];
        state.messages = {
            ...messages,
            [action.payload.category]: categoryMessages
        };
    }
    }
  }
});

export const { showMessage, removeMessage } = BannerSlice.actions;
export default BannerSlice.reducer;
