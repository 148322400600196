import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { ResidentSelector } from "@/common/presentation/components/Selectors/ResidentSelector";
import { Button } from "@/common/presentation/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { IVisitorForm } from "@/modules/visitors/infrastructure/interfaces/VisitorsFormInterface";
import { RootState } from "@/store/store";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export function VisitorsForm() {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const { t } = useTranslation();
    const { toast } = useToast();
    const [values, setValues] = useState<IVisitorForm>({
        visitor: "",
        temperature: "",
        date: null
    });
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleStartDateChange = (value: any) => {
       setValues({
            ...values,
            date: value
        });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if(params.r) {
            if(values.visitor && values.temperature && values.date) {
                console.log({
                    location_id: params.l,
                    data: {
                        ...values,
                        date: values.date.toISOString(),
                        resident_id: params.r
                    }
                });

                toast(
                    {
                        description: t("mentruation.form.toast.success") 
                    }
                );

                cleanInputs();
            } else {
                toast(
                    {
                        description: t("mentruation.form.toast.required") 
                    }
                );
            }
        } else {
            toast(
                {
                    description: t("mentruation.form.toast.residentRequired") 
                }
            );
        }
    }

    const cleanInputs = () => {
        setValues({
            visitor: "",
            temperature: "",
            date: null
        });
    };

    return (
        <div>
            {
                params.l && (
                    <div className="flex flex-col gap-4">
                        <div>
                            <ResidentSelector
                                locationId={location}
                                residentId={params.r}
                                showAllResidents={false}
                            />
                        </div>
                    </div>
                )
            }

            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        New Visitor
                    </CardTitle>
                </CardHeader>

                <CardContent>
                <form onSubmit={handleSubmit}>
                    <div className="general-outings-form grid grid-rows-1 grid-cols-3 gap-4">
                        <div className="grid grid-cols-1 items-center gap-1">
                            <Label htmlFor={"visitor"} className="text-right justify-self-start">
                                Visitor
                            </Label>

                            <Input
                                id={"visitor"}
                                name={"visitor"}
                                type="text"
                                value={values.visitor}
                                style={{ outline: 'none' }}
                                className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="grid grid-cols-1 items-center gap-1">
                            <Label htmlFor={"temperature"} className="text-right justify-self-start">
                                Temperature
                            </Label>

                            <Input
                                id={"temperature"}
                                name={"temperature"}
                                type="text"
                                value={values.temperature}
                                style={{ outline: 'none' }}
                                className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="grid grid-cols-1 items-center gap-1">
                                <Label htmlFor={"date"} className="text-left">
                                    Date/Time
                                </Label>
                                
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-[280px] justify-start text-left font-normal w-100",
                                            !values.date && "text-muted-foreground"
                                        )}
                                        >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {values.date ? format(values.date, "MM/dd/yyyy - hh:mm aa") : <span>{ t("mentruation.form.form.datePlaceholder") }</span>}
                                        </Button>
                                    </PopoverTrigger>

                                    <PopoverContent className="w-auto p-0">
                                        <CustomCalendar
                                            value={values.date}
                                            timePicker={true}
                                            onChange={handleStartDateChange}
                                        />
                                    </PopoverContent>
                                </Popover>
                        </div>
                    </div>

                    <div className="w-100 mt-4 flex flex-row justify-center">
                        <Button type="submit" className="mr-2">
                            { t("mentruation.form.form.submit") }
                        </Button>
                    </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}
