import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { useEffect, useState } from 'react';
import { AdministrationAlerts } from './components/administration-alerts';
import { CalendarCard } from './components/calendar';
// import { ForyouAlerts } from './components/foryou-alerts';
import { MedicationAlerts } from './components/medication-alerts';
import { NotesCard } from './components/notes/notes-card';
import { ResidentAlerts } from './components/residents/resident-alerts';
import { StaffAlerts } from './components/staff-alerts';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch } from 'react-redux';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice';
import { SystemHealth } from './components/system-health/system-health';
import { ForyouAlerts } from './components/foryou-alerts';

function DashboardPage() {
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const dispatch = useDispatch();
  const { params, setParam } = useRouteParams();

  useEffect(() => {
    dispatch<any>(getStaffByLocation(undefined));
  }, []);

  return (
    <>
      <div className="flex min-h-screen w-full flex-col">
        <div className="flex flex-col gap-4 py-4">
          <main className="flex flex-col gap-4">
            <LocationSelector onSelect={setSelectedLocation} />

            <div className="mt-4">
              <div className="grid gap-6 rounded-lg border p-4">
                <div className="grid grid-cols-1 lg:grid-cols-3   gap-4">
                  <ResidentAlerts location={params.l} />
                  <AdministrationAlerts location={params.l} />
                  <MedicationAlerts />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <StaffAlerts location={params.l} />
                  <ForyouAlerts location={params.l} />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full">
                  <CalendarCard location={params.l} />
                  <NotesCard location={params.l} />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1  gap-4">
                  <SystemHealth  />
                </div>

              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default DashboardPage;
