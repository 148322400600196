import { cn } from '@/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { ReactNode } from 'react';
import { LucideIcon } from 'lucide-react';

export interface ImageProps {
  id: string;
  firstName: string;
  lastName?: string;
  description?: string;
  image?: string;
}

interface ImageCardTablesProps {
  className?: ReactNode;
  icon?: LucideIcon;
  item: ImageProps;
  aspectRatio?: 'portrait' | 'square' | 'rounded';
  isSelected?: boolean;
  onSelect: () => void;
}

export const ImageCardTables = ({
  className,
  icon: Icon,
  item,
  aspectRatio = 'portrait',
  isSelected,
  onSelect,
  ...props
}: ImageCardTablesProps) => {
  const sizes = aspectRatio === 'portrait' ? 'w-[120px] h-[160px]' : 'size-14';

  return (
    <div className={cn('space-y-0 flex flex-col items-center', className)} {...props} style={{ marginBottom: '4px', marginTop: '4px' }}>
      <Avatar
        className={cn(
          'rounded-xl hover:cursor-pointer',
          sizes,
          aspectRatio === 'portrait' ? 'aspect-[3/4]' : aspectRatio === 'square' ? 'aspect-square' : 'rounded-full',
          isSelected ? 'border-4 border-primary/60 border-collapse' : 'border-4 border-transparent'
        )}
        onClick={onSelect}
      >
        <AvatarImage
          src={item.image}
          alt="User Image"
          className="size-full object-cover transition-all hover:scale-105"
        />
        <AvatarFallback
          className={cn(
            'bg-secondary text-sm rounded-md',
            isSelected ? 'bg-primary/20 stroke-primary/60' : 'bg-secondary text-sm'
          )}
        >
          {Icon ? (
            <Icon className={cn('size-8', isSelected && 'stroke-primary/60')} />
          ) : (
            <div className={cn('text-xl font-semibold', isSelected && 'text-primary/60')}>
              {item?.firstName?.charAt(0).toUpperCase()}
              {item?.lastName?.charAt(0).toUpperCase()}
            </div>
          )}
        </AvatarFallback>
      </Avatar>
      <div className={cn('text-center', aspectRatio !== 'portrait' && 'w-[100px]')} >
        <p className={cn('font-semibold', aspectRatio !== 'portrait' && 'truncate')}>{item.firstName}</p>
        <p className={cn(aspectRatio !== 'portrait' && 'truncate')}>{item.lastName}</p>
        <p></p>
      </div>
    </div>
  );
};
