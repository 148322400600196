import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { FormField as formField } from '@/modules/residents/domain/models/FormField';
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface Props {
  defaultFormFields: formField[];
  defaultValues: any;
}

const fieldsToFilter = ['resident_description', 'resident_likes', 'resident_dislikes'];

const FormFieldItem = ({
  fieldKey,
  label,
  control,
  placeholder,
  isRequired
}: {
  fieldKey: string;
  label: string;
  control: any;
  placeholder?: string | undefined;
  isRequired?: boolean;
}) => (
  <div className="border border-t-4 border-t-primary/50 p-2 rounded-lg bg-white dark:bg-[#09090B]">
    <FormField
      control={control}
      name={fieldKey}
      render={({ field }) => (
        <FormItem className="group">
          <FormLabel asChild>
            <div className="flex items-center justify-between gap-2">
              <div>
                <span>{label} </span>
                {isRequired && <span className="text-primary">*</span>}
              </div>
              {/* <Button variant={'ghost'} className="mr-2 opacity-0 group-hover:opacity-100" size={'icon'}>
                <Eye className="size-4" />
              </Button> */}
            </div>
          </FormLabel>
          <FormControl>
            <Textarea
              className="min-h-auto h-[150px] pb-10 focus-visible:ring-primary/25 focus-visible:ring-offset-primary/25"
              value={field.value || ''}
              placeholder={`${placeholder || ''} ${placeholder && placeholder.length > 0 ? '...' : ''}`}
              onChange={(value) => field.onChange(value)}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  </div>
);

export const PlanOfCareForm = forwardRef(({ defaultFormFields, defaultValues }: Props, ref) => {
  const formSchema = useMemo(() => {
    const filteredFields = defaultFormFields.filter((field) => field.isVisible && field.status === 'active');

    const defaultFields = filteredFields.reduce((acc: any, field: formField) => {
      acc[field.value] = field.isRequired
        ? z
            .string({
              required_error: `${field.label} is required`
            })
            .min(1, {
              message: `${field.label} is required`
            })
        : z.string().optional();
      return acc;
    }, {});

    return z.object({
      ...defaultFields
    });
  }, [defaultFormFields]);

  type FormValues = z.infer<typeof formSchema>;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      return new Promise((resolve) => {
        form.handleSubmit((data) => {
          resolve(data);
        })();
      });
    }
  }));

  const sortedFields = useMemo(() => defaultFormFields.sort((a, b) => a.order - b.order), [defaultFormFields]);

  const specialFields = sortedFields.filter(
    (field) => [...fieldsToFilter].includes(field.value) && field.isVisible && field.status === 'active'
  );

  const otherFields = sortedFields.filter(
    (field) => ![...fieldsToFilter].includes(field.value) && field.isVisible && field.status === 'active'
  );

  return (
    <div className="">
      <Form {...form}>
        <form className="flex flex-col gap-4">
          <div className="grid grid-cols-3 gap-4 bg-primary/5 p-3 rounded-lg shadow-sm">
            {specialFields.map((customField: formField) => (
              <FormFieldItem
                key={customField.value}
                fieldKey={customField.value}
                label={customField.label || customField.value.replace(/_/g, ' ')}
                control={form.control}
                placeholder={customField.placeholder || ''}
                isRequired={customField.isRequired}
              />
            ))}
          </div>

          <div className="grid grid-cols-2 gap-4 bg-primary/5 p-3 rounded-lg shadow-sm">
            {otherFields.map((customField: formField) => (
              <FormFieldItem
                key={customField.value}
                fieldKey={customField.value}
                label={customField.label || customField.value.replace(/_/g, ' ')}
                control={form.control}
                placeholder={customField.placeholder || ''}
                isRequired={customField.isRequired}
              />
            ))}
          </div>
        </form>
      </Form>
    </div>
  );
});
