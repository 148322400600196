import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Bell } from 'lucide-react';
import { Button } from '@/common/presentation/components/ui/button';

export const Notifications = () => {
  const notifications = [
    {
      id: 1,
      title: 'Notification 1',
      description: 'This is notification 1'
    },
    {
      id: 2,
      title: 'Notification 2',
      description: 'This is notification 2'
    },
    {
      id: 3,
      title: 'Notification 3',
      description: 'This is notification 3'
    }
  ];
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="icon" className="h-8 w-8 text-white hover:bg-primary/50 hover:text-white">
          <Bell className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <div className="flex flex-col space-y-4 p-2">
          <p className="text-xs font-medium leading-none text-muted-foreground">Notifications</p>

          {notifications.map((notification) => (
            <div key={notification.id} className="flex items-center gap-x-2">
              <div className="rounded-md bg-background p-1">
                <Bell className="h-8 w-8" />
              </div>
              <div className="space-y-1 flex-1">
                <p className="text-sm line-clamp-1">{notification.title}</p>
                <p className="text-xs text-muted-foreground line-clamp-1">{notification.description}</p>
              </div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
