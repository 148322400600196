import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

interface Props {
  defaultValue: number;
  value?: number;
  name: string;
  maxValue: number;
  minValue: number;
  onChange?: (value: number) => void;
  step: number;
}

const Counter: React.FC<Props> = ({ defaultValue, value, name, maxValue, minValue, onChange, step }) => {
  const [internalValue, setInternalValue] = useState(defaultValue);

  useEffect(() => {
    setInternalValue(value !== undefined ? value : 1);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);

    if (e.target.value === '') {
      setInternalValue(1);
      if (onChange) onChange(1);
      return;
    }

    if (isNaN(newValue) || (maxValue !== undefined && newValue > maxValue) || newValue < minValue) {
      return;
    }

    setInternalValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleAdd: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    const newValue = internalValue + step;
    if (maxValue !== undefined && newValue > maxValue) {
      return;
    }
    setInternalValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const newValue = internalValue - step;
    if (newValue < minValue) {
      return;
    }
    setInternalValue(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <div className={`flex flex-row gap-2 items-center`}>
      <Button
        className={`font-extrabold h-10 w-10 text-lg bg-white text-black border hover:text-white`}
        onClick={handleRemove}
        disabled={internalValue === minValue}
      >
        -
      </Button>
      <Input
        className={`w-12 h-12 font-bold`}
        type="number"
        name={name}
        value={internalValue}
        onChange={handleInputChange}
      />

      <motion.div
        className="bg-primary h-10 w-10 rounded flex items-center justify-center hover:cursor-pointer font-bold text-white"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: 'spring', stiffness: 400, damping: 20 }}
        onClick={handleAdd}
      >
        +
      </motion.div>
    </div>
  );
};

export default Counter;
