import { AmbulatoryStatusForm } from './OtherRequiredForm/AmbulatoryStatusForm';
import { BedStatusForm } from './OtherRequiredForm/BedStatusForm';
import { FoodAndNutritionForm } from './OtherRequiredForm/FoodAndNutritionForm';
import { FunctionalCapabilitiesForm } from './OtherRequiredForm/FunctionalCapabilitiesForm';
import { LeaveFacilityUnassisted } from './OtherRequiredForm/LeaveFacilityUnassisted';
import { LocationTrackerIntervalForm } from './OtherRequiredForm/LocationTrackerIntervalForm';
import { PhysicalDisabilitiesForm } from './OtherRequiredForm/PhysicalDisabilitiesForm';
import { ServicesNeeded } from './OtherRequiredForm/ServicesNeeded';
import { SocialFactorsForm } from './OtherRequiredForm/SocialFactorsForm';

export const OtherRquiredInformation = () => {
  return (
    <>
      <div className="grid grid-cols-4 rounded-lg border dark:bg-[#1d1d1d] mt-4 p-4 w-full gap-4">
        {/* <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
          <AmbulatoryStatusForm />
        </div>
        <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
          <BedStatusForm/>
        </div>
        <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
          <PhysicalDisabilitiesForm />
        </div>
        <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
          <FunctionalCapabilitiesForm/>
        </div>
        <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
          <LeaveFacilityUnassisted/>
        </div>
        <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
          <SocialFactorsForm/>
        </div>
        <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
          <ServicesNeeded/>
        </div>
        <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
          <FoodAndNutritionForm/>
        </div>
        <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
          <LocationTrackerIntervalForm/>
        </div> */}

        {/* <div>
       
          <Button type="submit" className="flex justify-center w-64">
            Save and exit
          </Button>
        </div> */}
      </div>
    </>
  );
};
