import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { RootState } from '@/store/store';
import { ChevronDown } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { residentNavigationTabs } from '../pages/UpdateResident';
import { useNewResidentDocument } from '../../infrastructure/hooks/use-new-documents';

const buttonStyle = 'hover:bg-primary/80 hover:text-white';

export const ResidentNavigationMenu = () => {
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const { onOpen } = useNewResidentDocument();
  const navigate = useNavigate();

  return (
    <div className="w-full rounded-md flex items-center justify-between gap-4 bg-secondary p-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className={buttonStyle}>
            Edit resident
            <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-64">
          <DropdownMenuGroup>
            {residentNavigationTabs.map((item, index) => (
              <DropdownMenuItem
                key={index}
                onClick={() => navigate(`/residents/update?r=${resident?.id}&t=${item.value}`)}
              >
                {item.icon}
                <span>{item.title}</span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <Button variant={'ghost'} className={buttonStyle}>
        Medications
      </Button>
      <Button variant={'ghost'} className={buttonStyle}>
        Sleeping Hours
      </Button>
      <Button variant={'ghost'} className={buttonStyle}>
        Alerts
      </Button>
      <Button variant={'ghost'} className={buttonStyle}>
        Bedroom QR
      </Button>
      <Button onClick={() => onOpen()} variant={'ghost'} className={buttonStyle}>
        Documents
      </Button>
      <Button onClick={() => navigate(`/residents/forms?r=${resident?.id}`)} variant={'ghost'} className={buttonStyle}>
        Forms
      </Button>
      <Button variant={'ghost'} className={buttonStyle}>
        E-signature Log
      </Button>
      <Button variant={'ghost'} className={buttonStyle}>
        DSP
      </Button>
      <Button variant={'ghost'} className={buttonStyle}>
        Service Plan
      </Button>
    </div>
  );
};
