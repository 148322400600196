import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { AppDispatch, RootState } from '@/store/store';
import { UsersRound } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../ui/card';
import { Input } from '../ui/input';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';
import { ImageCard } from './ImageCard';
import { useTranslation } from 'react-i18next';
import {  getStaffSelector } from "../../../../modules/staff/presentation/slices/usersSlice.ts";

interface StaffSelectorProps {
  onSelect?: (id: string) => void;
  className?: React.ReactNode;
  locationId?: string;
  showAllStaff?: boolean;
  description?: string | null;
  userId? : string;
  roles:string | null;  // Roles should be separated by "-" when there's more than one ej: Staff-Executive-etc..
}

export const UsersSelector = ({
  onSelect,
  className,
  locationId = '',
  userId = 'all-users',
  showAllStaff = true,
  description = null,
  roles = null
}: StaffSelectorProps) => {

  const dispatch = useDispatch<AppDispatch>();
  const [selectedUser, setSelectedUser] = useState(userId);

  const { t } = useTranslation();

  const {staff, status}: any = useSelector((state: RootState) => ({
    staff: state.users.dataStaffSelector,
    status: state.users.status
  }));

  
  const onSelectedUser = (id: string) => {
    setSelectedUser(id);
    onSelect?.(id);
  };

  useEffect(() => {
    setSelectedUser(staff.length === 1 ? staff[0].id : selectedUser);
    onSelect?.(staff.length === 1 ? staff[0].id : selectedUser);
  }, [staff]);


  useEffect(() => {
    const fetchParams = {locationId, roles: roles}; // Roles should be separated by "-" when there's more than one ej: Staff-Executive-etc..
    dispatch(getStaffSelector(fetchParams));
  }, []);
    

  return (
    <div className={cn(className)}>
      <Card className="border-t-4 border-t-primary/80"> 
        <CardHeader>
          <CardTitle className="flex flex-row items-center text-lg font-bold">{t('usersSelector.staff')}</CardTitle>
          <CardDescription className="flex flex-col md:flex-row items-center">
            {(description && description.length > 0) && (
                <>
                  { description }
                </>
              )}
            {staff.length > 0 && (
              <Input placeholder={t('usersSelector.filterStaff')} className="w-full md:w-[350px] mt-4 md:mt-0 ml-auto" />
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {status === 'loading' ? (
            <div className="flex items-center justify-start">
              <div className="flex flex-col items-center gap-2">
                <Skeleton className="h-20 w-20 rounded-full bg-primary/20" />
                <Skeleton className="h-4 w-16 mt-3 rounded-full bg-primary/20" />
                <Skeleton className="h-4 w-16 rounded-full bg-primary/20" />
              </div>
            </div>
          ) : (
            <ScrollArea>
              <div className="flex gap-4 mb-4">
                {staff.length === 0 && (
                  <div className="w-full flex flex-col items-center justify-center">
                    <p>{t('usersSelector.noStaffFound')}</p>
                    <Link
                      to="/staff/new"
                      role={'button'}
                      className="p-2 bg-primary flex items-center justify-center text-white rounded-md mt-4"
                    >
                      {t('usersSelector.createStaff')}
                    </Link>
                  </div>
                )}

                {staff.length > 0 && (
                  <div className="flex gap-2">
                    {showAllStaff && (
                      <ImageCard
                        item={{
                          id: 'all-users',
                          firstName: t('usersSelector.all'),
                          lastName: t('usersSelector.staff')
                        }}
                        icon={UsersRound}
                        onSelect={() => onSelectedUser('all-users')}
                        aspectRatio={'rounded'}
                        isSelected={selectedUser === 'all-users'}
                      />
                    )}

                    {staff.map((staff:any, index:any) => (
                      <ImageCard
                        key={index}
                        item={{
                          id: staff.id,
                          firstName: staff.first_name,
                          lastName: staff.last_name,
                          image: staff.image_url
                        }}
                        onSelect={() => onSelectedUser(staff.id)}
                        aspectRatio={'rounded'}
                        isSelected={selectedUser === staff.id}
                      />
                    ))}
                  </div>
                )}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
