import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import useRouteParams from '@/common/hooks/RouteParamsHook';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Pencil, Trash, Ellipsis, FileDown } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { Textarea } from "@/common/presentation/components/ui/textarea";

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { format, parse } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import TrackerFoodType from '@/modules/tracker/presentation/pages/TrackerFoodType';

import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ImageCardTables } from '@/common/presentation/components/Selectors/ImageCardTables';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CircleFadingPlus } from "lucide-react";

interface TypeI {
    id: string;
    name: string;
    status: 'active' | 'inactive';
    food: 'true' | 'false';
    fluid: 'true' | 'false';
}

interface TypesI {
    column_field: TypeI[];
}

interface TrackerI {
    id: string;
    location_id: string;
    created_at: string;
    data: TrackerDataI;
}

interface TrackerDataI {
    typeName?: string;
    detail: string | null;
    location_id: string;
    type: string;
    type_val: string;
    value: string;
    id: string;
    resident: {
        id: string;
        first_name: string;
        last_name: string;
    };
    who: {
        id: string;
        first_name: string;
        last_name: string;
    };
    date: string;
}

interface GroupedData {
    resident_name: string;
    data: {
        [yearMonth: string]: TrackerI[];
    };
}

interface FormattedResult {
    resident_id: string;
    resident_name: string;
    yearMonth: string;
    records: TrackerI[];
}

function TrackerFood() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState<TypeI[]>([]);
    const [dataReport, setDatareport] = useState<TrackerI[]>([]);
    const dispatch = useDispatch();
    const { tracker } = useSelector((state: RootState) => state.tracker);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedFood, setSelectedFood] = useState<string>(params.e || 'form');
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [inputValues, setInputValues] = useState({});
    const [select, setSelect] = useState<string[]>([]);
    const [pendingSubmit, setPendingSubmit] = useState(null);
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    const { locationSelectedId } = useLocation();

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
            setParam('e', 'form');
            setSelectedFood('form');
        }

        if (!params?.l || params.l.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }

    }, [params]);

    const { t } = useTranslation();

    const onSelectVitals = (e: string) => {
        setParam('e', e);
        setSelectedFood(e);
    };

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        params.l === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === params.l);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!params.l && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(params.l === 'all-locations' ? locations : locations.filter((l) => l.id === params.l));
        setLocation(params.l);

        if (params.l && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);


    const generateOptionsObject = () => {
        const options = [];
        options.push({ value: 'refused' });

        for (let i = 0; i <= 100; i += 10) {
            options.push({ value: `${i}%` });
        }
        return options;
    };


    useEffect(() => {
        if (!types) {
        }
    }, [types, select]);


    const newColumns = () => {
        const firstObject = {
            label: t('trackers.type'),
            value: "type",
            type: "texto"
        };
        return [firstObject, ...residents.map((resident: { name: any; }) => ({
            label: (
                <div>
                    <ImageCardTables
                        item={{
                            id: resident.id,
                            firstName: resident.first_name,
                            lastName: resident.last_name,
                            image: resident.image_url
                        }}
                        aspectRatio={'rounded'}
                    />
                </div>
            ),
            value: resident.first_name,
            type: 'text'
        }))];
    };
    const columns = newColumns();
    const columnsreport = [
        { label: t('trackers.Date/time'), value: 'yearMonth', type: 'date' },
        { label: t('trackers.Resident'), value: 'resident_name' },
    ];


    const dataFoodReport = async () => {
        const json = { location_id: params.l };
        const response = await TrackerService.getTrackerFood(json);

        if (response) {
            try {
                const { types, trackerFoods }: { types: TypesI, trackerFoods: TrackerI[] } = response;
                const newData: TypeI[] = [];

                types.column_field.forEach(item => {
                    const existingFoodItem = newData.find(obj => obj.id === item.id && obj.food);

                    if (!existingFoodItem && item.food) {
                        newData.push({
                            id: item.id,
                            name: `${item.name} Food (0%-100%)`,
                            status: item.status,
                            food: item.food,
                            fluid: item.fluid
                        });
                    }

                    if (item.fluid) {
                        newData.push({
                            id: item.id,
                            name: `${item.name} Fluid (Oz. Per Meal)`,
                            status: item.status,
                            food: item.food,
                            fluid: item.fluid
                        });
                    }
                });

                setTypes(newData);

                trackerFoods.sort((a, b) => {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return dateB - dateA;
                });

                setDatareport(trackerFoods);
            } catch (error: any) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        if (!dataReport) {
            dataFoodReport();
        }
    }, [dataReport]);

    const [data, setData] = useState([]);

    const cleanTypeName = (typeId: string, types: TypeI[], removeStrings: string[]): string => {
        const typeName = types.find(type => type.id === typeId)?.name || 'Unknown';

        let cleanedTypeName = typeName;
        removeStrings.forEach(str => {
            cleanedTypeName = cleanedTypeName.replace(str, '').trim();
        });

        return cleanedTypeName;
    };

    const createData = () => {
        const columnField = types || [];
        if (!types || !residents) return [];

        const groupedData = {};
        const today = new Date().toISOString().split('T')[0];

        const getValue = (residentId: string, value: string, typeName: string) => {
            if (!dataReport) {
                return '';
            } else {
                const removeStrings = ["Food (0%-100%)", "Fluid (Oz. Per Meal)"];
                removeStrings.forEach(str => {
                    typeName = typeName.replace(str, '').trim();
                });

                const dataWithTypeName = dataReport.map(row => ({
                    ...row,
                    data: {
                        ...row.data,
                        typeName: cleanTypeName(row.data.type, types, removeStrings)
                    }
                }));

                return Array.isArray(dataWithTypeName) && dataWithTypeName.some((report) => {
                    const reportDate = new Date(report.created_at).toISOString().split('T')[0];
                    return report.data.resident.id === residentId &&
                        report.data.value === value &&
                        reportDate === today &&
                        report.data.typeName === typeName
                });
            }

        };

        const getValueinput = (residentId: string, typeName: string) => {
            if (!dataReport) {
                return '';
            } else {
                const removeStrings = ["Food (0%-100%)", "Fluid (Oz. Per Meal)"];
                removeStrings.forEach(str => {
                    typeName = typeName.replace(str, '').trim();
                });

                const dataWithTypeName = dataReport.map(row => ({
                    ...row,
                    data: {
                        ...row.data,
                        typeName: cleanTypeName(row.data.type, types, removeStrings)
                    }
                }));

                const report = dataWithTypeName.find((report) => {
                    const reportDate = new Date(report.created_at).toISOString().split('T')[0];
                    return report.data.resident.id === residentId &&
                        reportDate === today &&
                        report.data.typeName === typeName &&
                        report.data.type_val === 'fluid'
                });
                return report ? report.data.value : '';
            }

        };

        const handleSelectChange = (typeId: string, residentId: string, value: string, type_val: string) => {
            buttonSumbit({
                typeId,
                residentId,
                status: value,
                type_val: type_val
            });
        };

        residents.forEach((resident) => {
            columnField.forEach((field) => {
                if (field.status === "inactive") return;

                const type = field.name;
                const typeid = field.id;
                const options = generateOptionsObject();
                const key = `${typeid}_${resident.first_name}`;

                const currentValue = options.find(option => getValue(resident.id, option.value, type))?.value || '';
                const initialValue = getValueinput(resident.id, type);
                const value = inputValues[key] !== undefined ? inputValues[key] : initialValue;

                if (!groupedData[type]) {
                    groupedData[type] = { type: type };
                }
                groupedData[type][resident.first_name] = (
                    <div className="flex justify-center" key={`${type}-${resident.first_name}`}>
                        {type.toLowerCase().includes("food") ? (
                            <div className="text-center flex-1">
                                <Select
                                    value={currentValue}
                                    onValueChange={(value) => handleSelectChange(typeid, resident.id, value, 'food')}
                                >
                                    <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                                        <SelectValue placeholder="">
                                            {currentValue ? currentValue.charAt(0).toUpperCase() + currentValue.slice(1) : ' '}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        {options.map(option => (
                                            <SelectItem key={option.value} value={option.value}>
                                                {option.value.charAt(0).toUpperCase() + option.value.slice(1)}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        ) : (
                            <input
                                key={resident.id}
                                name={`${typeid}_${resident.first_name}`}
                                type="text"
                                value={value}
                                onChange={(e) => handleInputChange(e, key, typeid, resident.id, 'fluid')}
                                className="col-span-2 px-2 py-3 bg-gray-100 rounded-md focus:outline-none"
                            />
                        )}
                    </div>
                );
            });
        });

        const newData = Object.values(groupedData);
        return newData;
    };


    const handleInputChange = (e, key, typeId, residentId, type_val) => {
        const { name, value } = e.target;
        setInputValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
        if (pendingSubmit) {
            pendingSubmit.cancel();
        }
        const debouncedSubmit = debounce(() => {
            buttonSumbit({
                typeId: typeId,
                residentId: residentId,
                status: value,
                type_val: type_val
            });
        }, 500);
        setPendingSubmit(() => debouncedSubmit);
        debouncedSubmit();
    }

    useEffect(() => {
        setData(createData());
    }, [types, dataReport, residents, inputValues]);



    const Fields = {
        'Edit': [
            { fieldName: 'date', label: 'Date/Time', type: 'date' },
            // { fieldName: 'resident_name', label: 'Resident', type: 'hidden' },
            { fieldName: 'type_name', label: 'Field', type: 'select' },
            { fieldName: 'value', label: 'Value', type: 'selectval' },
            { fieldName: 'detail', label: 'Detail', type: 'textarea' }
        ]
    };

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[], description: string,) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(fields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };


    const handleSubmit = async (p0: { typeId: any; residentId: any; status: string; }) => {
        const json: { [key: string]: any } = {};
        if (action === 'Edit') {
            json["id"] = formData.dataReportid;
            json["id_data"] = formData.id;
            json["data"] = {
                "type": formData.type,
                "detail": formData.detail,
                "value": formData.value,
                "resident_id": formData.resident_id,
                "date": formData.date
            };
            const response = await TrackerService.updateTrackerHygieneNight(json);
        } else {
            json["id"] = formData.dataReportid;
            json["id_data"] = formData.id;
            const response = await TrackerService.destroyTrackerHygieneNight(json);
        }
        dataFoodReport();
    };

    useEffect(() => {
        dataFoodReport();
    }, [params.l]);


    const [filters, setFilters] = useState({
        resident_name: '',
        type_name: ''
    });

    const buttonSumbit = async ({ typeId, residentId, status, type_val }: { typeId: string; residentId: string; status: string; type_val: string; }) => {
        const today = new Date().toISOString().split('T')[0];
        if (Array.isArray(dataReport)) {
            const foundReport = dataReport.find(report => {
                const reportDate = new Date(report.created_at).toISOString().split('T')[0];
                return report.data.type === typeId && report.data.resident.id === residentId && today === reportDate && report.data.type_val === type_val;
            });
            if (foundReport) {
                const json: { [key: string]: any } = {};
                json["id"] = foundReport.id;
                json["id_data"] = foundReport.data.id;
                json["data"] = {
                    "type": typeId,
                    "detail": foundReport.data.detail,
                    "value": status,
                    "resident_id": residentId,
                    "date": foundReport.data.date,
                    "type_val": type_val
                };
                const response = await TrackerService.updateTrackerFood(json);
                dataFoodReport();
                setData(createData());
            } else {
                const json = [{
                    type: typeId,
                    value: status,
                    resident_id: residentId,
                    detail: " ",
                    type_val: type_val,
                    location_id: params.l
                }];
                const response = await TrackerService.saveTrackerFood(json);
                dataFoodReport();
                setData(createData());
            }
        } else {
            const json = [{
                type: typeId,
                value: status,
                resident_id: residentId,
                detail: " ",
                location_id: params.l
            }];
            const response = await TrackerService.saveTrackerFood(json);
            dataFoodReport();
            setData(createData());
        }

    };

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const handleDateChange = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value instanceof Date && !isNaN(value) ? value : null
        }));
    };

    const renderInputForColumn = (column, index) => {

        switch (column.type) {
            case 'text':
                return (
                    <Input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none"
                    />
                );
            case 'date':

                if (formData[column.fieldName] != undefined) {
                    const dateString = formData[column.fieldName];
                    const parsedDate = new Date(dateString);
                    const formattedDate = format(parsedDate, "dd/MM/yyyy hh:mm a");
                    return <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"outline"}
                                className={`w-[280px] justify-start text-left font-normal`}>
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {formattedDate}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                                id={column.fieldName}
                                name={column.fieldName}
                                selectedDate={parsedDate}
                                onChange={date => handleDateChange(column.fieldName, date)}
                                MonthAndYearPicker={true}
                                timePicker={true}
                            />
                        </PopoverContent>
                    </Popover>
                }
            case 'textarea':
                return <Textarea className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none"
                    id={column.fieldName}
                    name={column.fieldName}
                    value={formData[column.fieldName] || ''}
                    onChange={handleChange} />;
            case 'select':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {types.find((option) => option.id === formData.type)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {types.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectval':
                return (
                    <Select value={formData.value} onValueChange={(value) => handleSelectChange('value', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {types.find((option) => option.id === formData.value)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem key="done" value="done">
                                Done
                            </SelectItem>
                            <SelectItem key="refused" value="refused">
                                Refused
                            </SelectItem>
                        </SelectContent>
                    </Select>
                );
            default:
                return null;
        }
    };
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction: direction });
    };



    const groupDataByResidentAndDate = (data: TrackerI[]): FormattedResult[] => {

        const grouped = data.reduce((result: Record<string, GroupedData>, item: TrackerI) => {
            const { resident } = item.data;
            const { created_at } = item;

            let resident_name = `${resident.first_name} ${resident.last_name}`;

            const [year, month] = created_at.split('T')[0].split('-');
            const yearMonth = `${year}-${month}`;

            if (!result[resident.id]) {
                result[resident.id] = { resident_name, data: {} };
            }

            if (!result[resident.id].data[yearMonth]) {
                result[resident.id].data[yearMonth] = [];
            }

            result[resident.id].data[yearMonth].push(item);

            return result;
        }, {} as Record<string, GroupedData>);

        const formattedResult: FormattedResult[] = [];
        for (const resident_id in grouped) {
            const { resident_name, data } = grouped[resident_id];
            for (const yearMonth in data) {
                formattedResult.push({
                    resident_id,
                    resident_name,
                    yearMonth,
                    records: data[yearMonth],
                });
            }
        }

        return formattedResult;
    };

    let groupedData: { resident_id: string; resident_name: any; yearMonth: string; records: any; }[] = [];

    if (dataReport) {
        groupedData = groupDataByResidentAndDate(dataReport);
    }

    const sortedData = useMemo(() => {
        if (sortConfig.key) {
            return [...groupedData].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });
        }

        return groupedData;
    }, [groupedData, sortConfig]);


    const handleButtonExport = async (row: any[]) => {
        const json = {
            location_id: params.l,
            resident_id: row.resident_id,
            date: row.yearMonth
        };
        try {
            const response = await TrackerService.exportReport(json);
            // Crear una URL para el PDF
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            // Abrir el PDF en una nueva pestaña
            window.open(url);

        } catch (error) {
            console.error('Error al generar el reporte:', error);
        }
    };

    const addLineBreaks = (text) => {
        return text.replace(/(Food|Fluid)/g, '$1\n');
    };
    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                params.l && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl mt-3" style={{ color: accentColor }}>{t('trackers.trackerfood.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackerfood.description')}</div>
                        </div>

                        <Tabs defaultValue={selectedFood} onValueChange={(e) => { onSelectVitals(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('seizure.form.label')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                                <TabsTrigger value="type" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.type')}
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }


            {
                selectedFood === 'form' && (
                    <div>
                        {
                            params.l && (
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <ResidentSelector
                                            onSelect={onResidentSelect}
                                            locationId={location}
                                            residentId={params.r}
                                            showAllResidents={false}
                                        />
                                    </div>

                                    <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                        <Table className="shadow-lg overflow-hidden">
                                            <TableHeader className="text-center">
                                                <TableRow className="py-3 px-4 text-center bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                    {columns.map((column, index) => (
                                                        <TableHead
                                                            className={`text-${index === 0 ? 'left' : 'center'} text-white ${index === 0 ? 'first-column' : 'other-columns'}`}
                                                            key={column.value}
                                                        >
                                                            {column.label}
                                                        </TableHead>
                                                    ))}
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody className="bg-white dark:bg-background">
                                                {data.map((row, index) => (
                                                    <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800 border-b border-gray-300">
                                                        {columns.map((column, columnIndex) => (
                                                            <TableCell
                                                                key={columnIndex}
                                                                className={`py-4 px-4 ${columnIndex !== 0 && 'border-l border-gray-300'}`}
                                                                style={{
                                                                    width: columnIndex === 1 ? '50pc' : 'auto', // Ajusta el ancho de la segunda columna aquí
                                                                    whiteSpace: columnIndex === 0 ? 'pre-line' : 'nowrap', // Ajuste para la primera columna
                                                                }}
                                                            >
                                                                {columnIndex === 0 ? addLineBreaks(row[column.value as keyof typeof row]) : row[column.value as keyof typeof row]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>

                                        </Table>
                                    </div>
                                    <div>
                                        <div className="flex items-center justify-between px-2">
                                            <div className="flex-1 text-sm text-muted-foreground">
                                                {data.length} of {data.length} row(s) selected.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end mb-4">
                                        <button className="p-2 bg-primary text-white rounded" onClick={() => window.location.reload()}>{t('trackers.Save Quick Tracker')}</button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                selectedFood === 'report' && (
                    <div>
                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.trackerfoodreport.title')}</CardTitle>

                            </CardHeader>
                            <CardContent>
                                <div className="flex space-x-4">
                                    <Input
                                        placeholder="Filter by Name"
                                        value={filters.resident_name}
                                        onChange={(e) => setFilters({ ...filters, resident_name: e.target.value })}
                                        className="w-[200px]"
                                    />
                                    <Input
                                        placeholder="Filter by Type"
                                        value={filters.type_name}
                                        onChange={(e) => setFilters({ ...filters, type_name: e.target.value })}
                                        className="w-[200px]"
                                    />
                                </div>
                                <br />
                                <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                    <Table className="shadow-lg overflow-hidden">
                                        <TableHeader>
                                            <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                {columnsreport.map((column) => (

                                                    <TableHead
                                                        key={column.value}
                                                        className="text-white cursor-pointer items-center"
                                                        onClick={() => handleSort(column.value)}>
                                                        <div className="flex items-center ">
                                                            <span>{column.label}</span>
                                                            <span className="ml-1 flex-col justify-end">
                                                                <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'ascending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                    ▲
                                                                </span>
                                                                <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'descending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                    ▼
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </TableHead>

                                                ))}
                                                <TableHead className="text-white">{t('trackers.Actions')}</TableHead>

                                            </TableRow>
                                        </TableHeader>
                                        <TableBody className="bg-white dark:bg-background">

                                            {sortedData && sortedData
                                                .filter(row =>
                                                    (!filters.resident_name || row.resident_name.toLowerCase().includes(filters.resident_name.toLowerCase())) &&
                                                    (!filters.type_name || row.type_name.toLowerCase().includes(filters.type_name.toLowerCase()))
                                                )
                                                .map((row, index) => (
                                                    <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                        {columnsreport.map((column, columnIndex) => (
                                                            <TableCell key={columnIndex} className="py-2 px-4">
                                                                {column.value === 'yearMonth' ? (
                                                                    <span>

                                                                        {format(parse(`${row[column.value]}-01`, 'yyyy-MM-dd', new Date()), "MMMM yyyy")}
                                                                        {column.type_name === 'date' ? ` (${row.value})` : null}
                                                                    </span>
                                                                ) : (
                                                                    column.value === 'type_name' ? (
                                                                        <span>
                                                                            {capitalizeWords(String(row[column.value as keyof typeof row]))}
                                                                            {` (${row.value})`}
                                                                        </span>
                                                                    ) : (
                                                                        capitalizeWords(String(row[column.value as keyof typeof row]))
                                                                    )
                                                                )}

                                                            </TableCell>
                                                        ))}
                                                        <TableCell className="py-2 px-4">
                                                            <DropdownMenu>
                                                                <DropdownMenuTrigger asChild>
                                                                    <Button variant="outline" size="icon">
                                                                        <Ellipsis className="h-4 w-4" />
                                                                    </Button>
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent className="w-56">
                                                                    <DropdownMenuSeparator />
                                                                    <DropdownMenuGroup>
                                                                        <DropdownMenuItem>
                                                                            <FileDown className="mr-2 h-4 w-4" />
                                                                            <span onClick={() => handleButtonExport(row)}>Export</span>
                                                                        </DropdownMenuItem>
                                                                    </DropdownMenuGroup>
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                    <div>
                                        <div className="flex items-center justify-between px-2">
                                            <div className="flex-1 text-sm text-muted-foreground">
                                                {dataReport && dataReport.length} of {dataReport && dataReport.length} row(s) selected.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="flex justify-end mb-4">
                                    <button className="p-2 bg-primary text-white rounded">{t('trackers.Download')}</button>
                                </div>

                            </CardContent>
                        </Card>
                    </div>
                )
            }

            {
                selectedFood === 'type' && (
                    <div>
                        {
                            <div>
                                <TrackerFoodType dataFoodReport={dataFoodReport} />
                            </div>
                        }
                    </div>
                )
            }
            <style jsx>{`
    .first-column {
        padding-right: 120px;
    }
`}</style>
        </div >
    )
}

export default TrackerFood