
export interface PriceI {
    id: string;
    status: string;
    price: string;
    country: any;
    min_beds: number | null;
    max_beds: number | null;
};

export interface ModuleI {
    id: string;
    name: string;
    key: string;
    status: string;
    description: string | null;
    includes: string | null;
    prices: PriceI[];
};

export interface ServiceTypeI {
    id: string;
    name: string;
}

export interface ServiceI {
    id: string;
    name: string;
    key: string;
    includes: string | null;
    description: string | null;
    status: string;
    modules: ModuleI[];
    type: ServiceTypeI;
};

export interface ServicesI {
    [key: number] : ServiceI
};


export interface PlanI {
    id: string;
    status: string;
    quantity: number;
    service_module: ModuleI;
};

export interface SubscriptionI {
    id: string;
    client_id: string;
    service_id: string;
    price: string;
    status: string;
    plans: PlanI[];
    service: ServiceI;
    payments_pending: any;
    cancel_at: string | null;
    is_invoice_blocked: boolean | null;
};

export interface SubscriptionsI {
    [key: string]: SubscriptionI
};

export interface SubscriptionSliceI {
    subscriptions: SubscriptionsI;
    subscriptions_loading: boolean;
    services: ServiceI[],
};

export interface FormDataI {
    isDialogOpen: boolean;
    serviceSelectedInfo: ServiceI;
    subscriptionToCancel: Partial<SubscriptionI>;
};

export interface IFacilitiesInfo {
    id: string;
    beds_number: number;
    name: string;
};

export enum ClientStatusType {
    informationPending = 'account_information_pending'
};