import CryptoJS from 'crypto-js';

// This function is used to encrypt data to be send to Laravel
// decrypt the data in Laravel using decryptJS() function
export const encryptToLaravel = (data: any) => {
  const encryptedKey = import.meta.env.VITE_WEB_LARAVEL_ENCRYPTION_KEY;
  const key = CryptoJS.enc.Utf8.parse(encryptedKey);
  const iv = CryptoJS.enc.Utf8.parse(encryptedKey);
  return CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv }).toString();
};

export const encryptToNest = (data: string) => {
  return CryptoJS.AES.encrypt(data, import.meta.env.VITE_WEB_NEST_CARING_DATA_KEY).toString();
};
