import useRouteParams from '@/common/hooks/RouteParamsHook';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Separator } from '@/common/presentation/components/ui/separator';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { RootState } from '@/store/store';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DateSelector } from './DateSelector';
import { EmarPrnSection } from './EmarPrnSection';
import { EmarRoutineSection } from './EmarRoutineSection';

export const EmarMedicationsCard = () => {
  const { params } = useRouteParams();
  const { locationId } = useParams<{ locationId: string }>();
  const [date, setDate] = useState<Date>(new Date());

  const { getMedicationsTimesByResident, medications } = useMedicationStore();

  const { resident } = useSelector((state: RootState) => state.residents.resident, shallowEqual);

  const routineMedications = useMemo(() => {
    return medications.filter((med) => med?.medication_type?.name === 'Routine');
  }, [medications]);

  const prnMedications = useMemo(() => {
    return medications.filter((med) => med?.medication_type?.name === 'PRN');
  }, [medications]);

  const formattedDate = useMemo(() => format(date, 'yyyy-MM-dd'), [date]);
  const residentId = params.r ?? 'all-residents';

  const fetchMedications = useCallback(() => {
    getMedicationsTimesByResident(locationId as string, residentId, formattedDate);
  }, [getMedicationsTimesByResident, locationId, residentId, formattedDate]);

  useEffect(() => {
    fetchMedications();
  }, [fetchMedications]);

  const accordionTitle = useMemo(() => {
    return `Medications for ${residentId === 'all-residents' ? 'All Residents' : resident?.first_name || ''}`;
  }, [residentId, resident]);

  return (
    <>
      <CustomAccordion title={accordionTitle} defaultOpen openOption={false}>
        <div className="flex flex-col gap-4">
          <DateSelector onSelectDate={setDate} defaultDate={date} />
          <p>Filter by date and time to view the medications that need to be administered.</p>
          <Separator />

          {params.e === 'routine' && (
            <EmarRoutineSection medications={routineMedications} residentId={residentId} date={date} emar={params.e} />
          )}

          {params.e === 'prn' && (
            <EmarPrnSection medications={prnMedications} residentId={residentId} date={date} emar={params.e} />
          )}
        </div>
      </CustomAccordion>
    </>
  );
};
