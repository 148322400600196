import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ResidentFormService = {
  createCategory: async (data: any) => {
    const response = await axios.post(`/residents/documents/category/${data.category_id}`, { ...data });
    return response;
  },

  getCategories: async () => {
    const response = await axios.get(`/residents/documents/categories`);
    return response;
  }
};

export default ResidentFormService;
