import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

class CustomError extends Error {
  payload;
  constructor(message: any, payload: any) {
    super(message);
    this.payload = payload;
  }
}

const SubscriptionService = {
  getServices: async () => {
    let url = `/service`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getClientSubscriptions: async () => {
    let url = `/subscription/client`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  updateSubscription: async (data: any) => {
    let url = `/subscription/update`;
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getStripeSubscription: async (subscriptionId: string) => {
    let url = `/subscription/stripe/${subscriptionId}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  cancelSubscription: async (subscriptionId: string) => {
    let url = `/subscription/cancel`;
    try {
      const response = await axios.post(url, { subscription_id: subscriptionId });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  subscribe: async (data: any) => {
    let url = `/subscription/subscribe`;
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getSubscription: async (subscriptionId: string) => {
    let url = `/subscription/${subscriptionId}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  retryPayment: async (data: any) => {
    let url = `/payment/retry`;
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        return {
          error: errorMessage,
          payload: error.response.data
        };
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  }
};

export default SubscriptionService;
