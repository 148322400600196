import { Alert, AlertDescription, AlertTitle } from '@/common/presentation/components/ui/alert';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';
import BannerButton from './components/BannerButton';
import Icon from './components/Icon';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Banner = () => {
  const bannerState = useSelector((state: RootState) => state.banner) || [];
  const [currentPath, setCurrentPath] = useState('');
  let { messages } = bannerState;
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <>
      {Object.keys(messages).length > 0 &&
        Object.entries(messages).map(([__, messageType]) =>
          Object.entries(messageType).map(
            ([key, message]) =>
              (!message.hasOwnProperty('excludedRoutes') ||
                !message.excludedRoutes ||
                message.excludedRoutes.length === 0 ||
                !message.excludedRoutes.some((route) => currentPath.startsWith(route))) && (
                <div className="py-2" key={key}>
                  <Alert
                    className={`border-2 bg-background ${
                      message.type === 'warning' ? 'border-amber-400' : 'border-primary'
                    }`}
                  >
                    <Icon type={message.type} />
                    <div className="grid md:grid-cols-3 sm:grid-row-2">
                      <div className="md:col-span-2">
                        <AlertTitle className="font-bold">{ t(message.title.label, message.title.variables)}</AlertTitle>
                        <AlertDescription className="font-semibold max-w-screen-sm">{ t(message.message.label, message.message.variables)}</AlertDescription>
                      </div>
                      <div className="md:col-span-1 flex justify-center mt-4 md:mt-0 md:justify-end items-center">
                        {message.buttonText?.label.length > 0 && (
                          <BannerButton
                            buttonText={t(message.buttonText.label, message.buttonText.variables)}
                            type={message.type}
                            redirect={message.redirect}
                          />
                        )}
                      </div>
                    </div>
                  </Alert>
                </div>
              )
          )
        )}
    </>
  );
};

export default Banner;
