import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { Avatar } from '@/common/presentation/components/ui/avatar';
import { cn } from '@/lib/utils';
import { AlertsCard } from '@/modules/residents/presentation/components/ProfileInformation/AlertsCard';
import { ContactsCard } from '@/modules/residents/presentation/components/ProfileInformation/ContactsCard';
import { DiagnosisCard } from '@/modules/residents/presentation/components/ProfileInformation/DiagnosisCard';
import { FoodNutrition } from '@/modules/residents/presentation/components/ProfileInformation/FoodNutrition';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberSlice';
import { AppDispatch, RootState } from '@/store/store';
import { AvatarFallback, AvatarImage } from '@radix-ui/react-avatar';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ResidentNavigationMenu } from '../components/ResidentNavigationMenu';


export const DashboardProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();

  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);

  const StafftAge = staffMember?.dob ? new Date().getFullYear() - new Date(staffMember?.dob).getFullYear() : 0;

  useEffect(() => {
    if (searchParams.get('s')) {
      dispatch(staffMemberFormActions.getStaffMemberById(searchParams.get('s')!));
    }
  }, [searchParams.get('s')]);

  if (status === 'loading' && !staffMember) {
    return <SplashScreen />;
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full py-4 flex flex-col gap-6">
          <ResidentNavigationMenu />

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 flex flex-col gap-4">
              <CustomAccordion openOption={false} className="w-full" headerPadding={false} defaultOpen>
                <div className="grid grid-cols-12 gap-4 pb-6">
                  <div className="overflow-hidden w-full flex justify-center col-span-full xl:col-span-4">
                    <Avatar className="w-64 h-64">
                      <AvatarImage
                        src={staffMember?.profile_url}
                        alt={`${staffMember?.first_name} ${staffMember?.last_name}`}
                        className="h-full w-full object-cover"
                      />
                      <AvatarFallback className={cn('bg-secondary text-sm rounded-md', 'bg-secondary text-sm')}>
                        {staffMember?.first_name[0] + staffMember?.last_name[0]}
                      </AvatarFallback>
                    </Avatar>
                  </div>

                  <div className="col-span-full xl:col-span-8">
                    <div className="flex flex-col gap-4">
                      <h1 className="text-2xl font-bold">
                        {staffMember?.first_name} {staffMember?.middle_name} {staffMember?.last_name} {' '}
                        {staffMember?.preferred_name}
                      </h1>
                      <p>
                        <span className="font-bold">Date of Birth:</span>{' '}
                        {format(new Date(staffMember?.dob || new Date()), 'yyyy-MM-dd')} ({StafftAge} years old)
                      </p>
                      <p>
                        <span className="font-bold">Sex: </span>{' '}
                        {staffMember?.sex[0].toUpperCase() + staffMember?.sex.slice(1) || 'Not Specified'}
                      </p>
                      <p>
                        <span className="font-bold">Spoken Languages:</span> {staffMember?.spoken_languages}
                      </p>
                      <p>
                        <span className="font-bold">Weight:</span> {staffMember?.weight} lbs |{' '}
                        <span className="font-bold">Height:</span> {staffMember?.height} ft
                      </p>
                    </div>
                  </div>
                </div>
              </CustomAccordion>

              <div className="grid grid-cols-2 gap-2">
                {/* <DiagnosisCard /> */}
                {/* <FoodNutrition /> */}
                <ContactsCard />
                <AlertsCard />
              </div>
            </div>

            {/* <div className="col-span-3 space-y-4">
              <ContactsCard />
              <AlertsCard />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
