import { create } from 'zustand';

type EditDefaultFormState = {
  defaultFormValues: any;
  isOpen: boolean;
  onOpen: (defaultFormValues: any) => void;
  onClose: () => void;
  changeDefaultFormValues: (defaultFormValues: any) => void;
};

export const useEditDefaultForm = create<EditDefaultFormState>((set) => ({
  defaultFormValues: {
    time_frame: '',
    person_responsible_for_implementation: '',
    method_of_evaluating_progress: ''
  },
  isOpen: false,
  onOpen: (defaultFormValues: any) => set({ isOpen: true, defaultFormValues }),
  onClose: () => set({ isOpen: false }),
  changeDefaultFormValues: (defaultFormValues: any) => set({ defaultFormValues })
}));
