import { create } from 'zustand';
import ResidentFormService from '@/modules/residents/infrastructure/services/DocumentsService';

type DocumentsState = {
  documents: any[];
  categories: any[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  createCategory: (data: any) => Promise<any>;
  getCategories: () => Promise<any>;
};

export const useDocumentsStore = create<DocumentsState>((set) => ({
  documents: [],
  categories: [],
  status: 'idle',
  error: null,

  createCategory: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentFormService.createCategory(data);
      set({ status: 'idle', categories: response.data?.categories });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  getCategories: async () => {
    set({ status: 'loading' });
    try {
      const response = await ResidentFormService.getCategories();
      set({ status: 'idle', categories: response.data?.categories });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
