import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as residentFormActions from '@/modules/residents/presentation/slices/residentsForm';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { Loader } from 'lucide-react';
import { cn } from '@/lib/utils';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';

interface Props {
  className?: string;
}

const formSchema = z.object({
  name: z.string().optional(),
  clergyman_name: z.string().optional(),
  address: z.string().optional(),
  telephone: z.string().optional(),
  notes_religion: z.string().optional()
});
type FormValues = z.infer<typeof formSchema>;

export const ReligiousPreferenceForm = ({ className }: Props) => {
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const { status: residentFormStatus } = useSelector((state: RootState) => state.residents.residentForm);
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});
  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues }
  });
  const onSubmitForm = async (data: FormValues) => {
    const response = dispatch(
      residentFormActions.updateReligiousPreferencesForm({
        resident_id: resident?.id,
        ...data
      })
    );

    if ((await response).payload) {
      toast({
        description: 'Religious preferences updated successfully'
      });
    }
  };

  useEffect(() => {
    setDefaultValues({
      name: resident?.religion_preferences?.name || '',
      clergyman_name: resident?.religion_preferences?.clergyman_name || '',
      address: resident?.religion_preferences?.address || '',
      telephone: resident?.religion_preferences?.telephone || '',
      notes_religion: resident?.religion_preferences?.note_content || ''
    });
  }, [resident]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Religious Preference">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)} className="space-y-8">
                <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
                  <div className="col-span-2 md:col-span-1">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Religious Preference</FormLabel>
                          <FormControl>
                            <Input
                              placeholder=""
                              {...field}
                              value={field.value || ''}
                              disabled={residentFormStatus === 'loading'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormField
                      control={form.control}
                      name="clergyman_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Name Of Clergyman Or Religious Advisor, If Any</FormLabel>
                          <FormControl>
                            <Input
                              placeholder=""
                              {...field}
                              value={field.value || ''}
                              disabled={residentFormStatus === 'loading'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormField
                      control={form.control}
                      name="address"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Address Of Clergyman Or Religious Advisor, If Any</FormLabel>
                          <FormControl>
                            <Input
                              placeholder=""
                              {...field}
                              value={field.value || ''}
                              disabled={residentFormStatus === 'loading'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormField
                      control={form.control}
                      name="telephone"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Telephone</FormLabel>
                          <FormControl>
                            <PhoneInput
                              {...field}
                              value={(field.value as any) || ''}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              disabled={residentFormStatus === 'loading'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-2">
                    <FormField
                      control={form.control}
                      name="notes_religion"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Note</FormLabel>
                          <FormControl>
                            <Textarea
                              className="min-h-[40px] h-[40px]"
                              placeholder=""
                              {...field}
                              value={field.value || ''}
                              disabled={residentFormStatus === 'loading'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                  <>
                    <Separator className="col-span-full" />
                    <Button type="submit" className="w-64" disabled={residentFormStatus === 'loading'}>
                      {residentFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
