import { CircleAlert, Loader2, MessageCircleMore } from 'lucide-react';
import { AlertCard } from './alert-card';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilteredNotes } from '@/modules/notes/presentation/slices/NoteSlice';
import { AppDispatch, RootState } from '@/store/store';
import React, { useEffect, useState } from 'react';
import SkeletonNotes from './notes/skeleton';
import { NoteI } from '@/modules/notes/domain/note.domain';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import NotesModal from './notes/notes-modal';
import { Button } from '@/common/presentation/components/ui/button';
import NoteService from '@/modules/notes/infrastructure/services/NoteService';
import { useTranslation } from 'react-i18next';
import { setMessagesForYou } from '../../slices/DashboardSlice';

interface IForYouAlersProps {
  location?: string;
}

export const ForyouAlerts: React.FC<IForYouAlersProps> = ({ location = 'all-locations' }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<NoteI | null>(null);
  const [isReading, setIsReading] = useState<boolean>(false);
  const [readingNoteId, setReadingNoteId] = useState<string>('');
  const alerts = useSelector((state: RootState) => state.dashboard?.messagesForYou?.payload) ?? [];
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents) ?? [];
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();

  useEffect(() => {
    getMessagesForYou();
  }, [location]);

  const getMessagesForYou = async () => {
    try {
      setIsLoading(true);
      let filters = {
        nextPage: 1,
        user_private: true,
        location: 'all-locations',
        unread: true
      };

      if (location && location.length > 0 && location !== 'all-locations') {
        filters.location = location;
      }

      const { payload } = await dispatch(fetchFilteredNotes(filters));
      await dispatch(setMessagesForYou(payload));
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handlerClick = (note: NoteI) => {
    setSelectedNote(note);
    setIsOpenModal(true);
  };

  const residentNameById = (residentId: string | null): string | null => {
    if (residentId === null) return t('dashboard.everyone');
    return UserHelper.getResidentName(residentId, residents);
  };

  const readNote = async (noteId: string) => {
    try {
      setReadingNoteId(noteId);
      setIsReading(true);
      const { result } = await NoteService.unreadNote({
        note_id: noteId,
        status: 'read'
      });

      if (result !== 'ok') {
        throw Error('Error reading note');
      }

      const unreadNotes = alerts.filter((note: NoteI) => note.id != noteId);
      await dispatch(setMessagesForYou({ payload: unreadNotes }));
    } catch (error) {
      console.log(error);
    }
    setReadingNoteId('');
    setIsReading(false);
  };

  return (
    <>
      <AlertCard
        title={t('dashboard.urgentMessageForYou')}
        alerts={alerts ?? []}
        customScrollHeight="h-[250px]"
        icon={MessageCircleMore}
        custom
      >
        {isLoading ? (
          <SkeletonNotes />
        ) : alerts.length === 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          alerts.map((note) => (
            <div className="p-2" key={note.id}>
              <div
                className="transition-all hover:scale-[1.01] cursor-pointer rounded-lg border p-4"
                onClick={() => handlerClick(note)}
              >
                <div className="grid">
                  <div className="flex sm:flex-row justify-between items-start sm:items-center gap-2">
                    <div className="flex flex-col text-primary font-semibold flex-grow">
                      <span className="flex flex-row gap-2 items-center">
                        {residentNameById(note?.resident_id ?? null)}{' '}
                        {note?.urgent === true && <CircleAlert size={18} />}
                      </span>
                      <span className="text-sm">{formatDate(note?.created_at ?? '')}</span>
                    </div>
                    <div className="self-end sm:self-auto">
                      <Button
                        disabled={isReading}
                        onClick={(e) => {
                          e.stopPropagation();
                          readNote(note.id);
                        }}
                      >
                        {isReading && readingNoteId === note.id ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            {t('dashboard.reading')}
                          </>
                        ) : (
                          <>{t('dashboard.read')}</>
                        )}
                      </Button>
                    </div>
                  </div>
                  <div className="mt-2">
                    <span>{note?.content}</span>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </AlertCard>
      <NotesModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} note={selectedNote} location={location} />
    </>
  );
};
