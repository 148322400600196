import { Note } from '../components/note.tsx';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { setNoteFilters, cleanFilters, resetState } from '../slices/NoteSlice';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider.tsx';

export default function NotesPage() {
  const { params, setParam } = useRouteParams();

  const dispatch = useDispatch<AppDispatch>();

  const [location, setLocation] = useState<string | null>(null);
  const { locationSelectedId } = useLocation();

  useEffect(() => {
    dispatch<any>(resetState());
    dispatch(cleanFilters());
    calculateParams();    
    setLocation(locationSelectedId);
    dispatch(setNoteFilters(['location', params.l]));
  }, [params, locationSelectedId]);
  
  const calculateParams = useCallback(() => {
    if (!params.l) {
      setLocation(locationSelectedId ?? 'all-locations');
      setParam('l', locationSelectedId ?? 'all-locations');
    }
  }, [params]);

  return (
    <>
      <div>
        {params.l && location && (
          <div className="flex-col md:flex">
            <LocationSelector quickView={true} />
            <Note/>
          </div>
        )}
      </div>
    </>
  );
}
