import React from 'react';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import medicationService from '@/modules/medications/infrastructure/services/MedicationService';

const RenderActionsColumn = ({ residentId }) => {
  const handleDownload = async () => {
    try {
      if (!residentId) {
        console.error('No se proporcionó un ID de residente válido.');
        return;
      }

      const response = await medicationService.getMedicationsPdf(residentId, 'report', {
        items: []
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });

      if (blob.size === 0) {
        throw new Error('El archivo PDF está vacío.');
      }

      const url = URL.createObjectURL(blob);
      window.open(url);

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      console.error('Error al obtener el PDF:', error);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="mr-2" size="sm" variant="outline" onClick={handleDownload}>
          <img
            src="/svg/pdf_icon.svg"
            alt="Icono de PDF"
            style={{ marginRight: '8px', width: '24px', height: '24px' }}
          />
        </Button>
      </DropdownMenuTrigger>
    </DropdownMenu>
  );
};

export default RenderActionsColumn;
