// RenderActionsColumn.jsx
import React from 'react';
import { DropdownMenu, DropdownMenuTrigger } from '@/common/presentation/components/ui/dropdown-menu';
import { Row } from "@tanstack/react-table";

interface RenderActionsColumnProps {
  rowData: Row<any>;
  onRowAction: (rowData: Row<any>) => void;
}

const RenderActionsColumn: React.FC<RenderActionsColumnProps> = ({ rowData, onRowAction }) => {
  const handleClick = () => {
    console.log("llego")
    onRowAction(rowData);
  };


  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <a href={'#'} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
          <div className="hover:cursor-pointer hover:opacity-75 transition-opacity duration-300">
            <img src="/svg/pdf_icon.svg" alt="Icono de PDF" style={{ width: '24px', height: '24px' }} />
          </div>
        </a>
      </DropdownMenuTrigger>
    </DropdownMenu>
  );
};

export default RenderActionsColumn;