import { Tabs, TabsList, TabsTrigger } from "@/common/presentation/components/ui/tabs";
import { useEffect, useState } from "react"
import { TrackerCleaningSummary } from "../components/cleaning/TrackerCleaningSummary";
import { TrackerCleaningAreaTask } from "../components/cleaning/TrackerCleaningAreaTask";
import { LocationSelector } from "@/common/presentation/components/Selectors/LocationSelector";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { TrackerCleaningTrackers } from "../components/cleaning/TrackerCleaningTrackers";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useTranslation } from "react-i18next";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { CircleFadingPlus } from "lucide-react";

export function TrackerCleaning() {
    const { params } = useRouteParams();
    const [selectedTab, setTab] = useState("tracker");
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const { locationSelectedId } = useLocation();
    const { t } = useTranslation();
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const onSelectedTab = (event: string) => {
        setTab(event);
    }

    return (
        <div>
            <LocationSelector quickView={true} />

            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{color: accentColor}}>{t("cleaning.title")}</div>
                            <div className="text-1xl">
                                {t("cleaning.description")}
                            </div>
                        </div>

                        <Tabs defaultValue={selectedTab} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto">
                            <TabsList>
                                <TabsTrigger value="tracker" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    { t("cleaning.form.label") }
                                </TabsTrigger>

                                <TabsTrigger value="summary" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    { t("cleaning.report.label") }
                                </TabsTrigger>

                                <TabsTrigger value="areaTask" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    { t("cleaning.create.label") }
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                )
            }

            {
                locationSelectedId && selectedTab === "tracker" && <TrackerCleaningTrackers /> 
            }

            {
                locationSelectedId && selectedTab === "summary" && <TrackerCleaningSummary />
            }

            {
                locationSelectedId && selectedTab === "areaTask" && <TrackerCleaningAreaTask />
            }
        </div>
    )
}
