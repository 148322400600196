import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';

import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Switch } from '@/common/presentation/components/ui/switch';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/common/presentation/components/ui/table';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicalInformationStore } from '@/modules/residents/domain/stores/medical-information/use-medical-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

const dementiaStages = [
  {
    label: 'Confused / Disoriented',
    value: 'confused_disoriented',
    option: null,
    detail: ''
  },
  {
    label: 'Inappropiate Behavior',
    value: 'inappropiate_behavior',
    option: null,
    detail: ''
  },
  {
    label: 'Aggresive Behavior',
    value: 'aggresive_behavior',
    option: null,
    detail: ''
  },
  {
    label: 'Wandering Behavior',
    value: 'wandering_behavior',
    option: null,
    detail: ''
  },
  {
    label: 'Sundowning Behavior',
    value: 'sundowning_behavior',
    option: null,
    detail: ''
  },
  {
    label: 'Able to Follow Instructions',
    value: 'able_to_follow_instructions',
    option: null,
    detail: ''
  },
  {
    label: 'Depressed',
    value: 'depressed',
    option: null,
    detail: ''
  },
  {
    label: 'Suicidal / Self-Abuse',
    value: 'suicidal_self_abuse',
    option: null,
    detail: ''
  },
  {
    label: 'Able to Communicate Needs',
    value: 'able_to_communicate_needs',
    option: null,
    detail: ''
  },
  {
    label: 'At Risk if Allowed Direct Access to Personal Grooming and Hygiene Items',
    value: 'at_risk_if_allowed_direct_access_to_personal_grooming_and_hygiene_items',
    option: null,
    detail: ''
  },
  {
    label: 'Able to Leave Facility Unassisted',
    value: 'able_to_leave_facility_unassisted',
    option: null,
    detail: ''
  }
];

const dementiaStageSchema = dementiaStages.reduce((acc: any, stage: any) => {
  acc[stage.value] = z.object({
    option: z.string().refine((value) => value === 'yes' || value === 'no', {
      message: 'Option must be either yes or no'
    }),
    detail: z.string().nullable().optional()
  });
  return acc;
}, {});

const formSchema = z
  .object({
    general_description: z
      .string({
        required_error: 'General description is required'
      })
      .min(1, {
        message: 'General description is required'
      }),
    has_dementia: z.boolean().optional(),
    dementia_type: z
      .string({
        required_error: 'Dementia type is required'
      })
      .optional(),
    dementia_stage: z.object(dementiaStageSchema)
  })
  .refine(
    (data) => {
      if (data.has_dementia) {
        return data.dementia_type !== '';
      }
      return true;
    },
    {
      message: 'Dementia type is required if the patient has been diagnosed with dementia',
      path: ['dementia_type']
    }
  );

type FormValues = z.infer<typeof formSchema>;

const dementiaOptions = [
  {
    label: 'Alert',
    value: 'alert'
  },
  {
    label: 'Mild Cognitive Impairment',
    value: 'mild_cognitive'
  },
  {
    label: 'Mild Dementia',
    value: 'mild_dementia'
  },
  {
    label: 'Moderate Dementia',
    value: 'moderate_dementia'
  },
  {
    label: 'Advanced Dementia',
    value: 'advanced_dementia'
  }
];

export const MentalConditionForm = () => {
  const {
    resident
  }: {
    resident: Resident | null;
    status: string;
  } = useSelector((state: RootState) => state.residents.resident);

  const defaultDementiaStageValues = dementiaStages.reduce((acc: any, stage: any) => {
    acc[stage.value] = { option: '', detail: '' };
    return acc;
  }, {});

  const [defaultValues, setDefaultValues] = useState(
    resident?.medical_information?.mental_condition || {
      general_description: '',
      has_dementia: false,
      dementia_type: '',
      dementia_stage: defaultDementiaStageValues
    }
  );

  const [createdId, setCreatedId] = useState<string | null>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  const { createOrUpdateMentalCondidionInformation, status: medicalInformationFormStatus } =
    useMedicalInformationStore();

  const handleSubmit = async (values: FormValues) => {
    if (!values.has_dementia) {
      values.dementia_type = '';
    }

    const payload = {
      id: resident?.medical_information?.mental_condition?.id || createdId,
      resident_id: resident?.id,
      ...values
    };

    try {
      const response = await createOrUpdateMentalCondidionInformation(payload);

      form.reset({
        ...values
      });

      setDefaultValues({
        ...values
      });

      setCreatedId(response.mental_condition);

      toast({
        description: 'Mental condition has been updated successfully'
      });
    } catch (error) {
      toast({
        description: 'There was an error updating the mental condition',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <div className="">
        <CustomAccordion
          titleComponent={<h1 className="text-xl font-bold">Mental Condition</h1>}
          openOption={true}
          defaultOpen={true}
        >
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 mt-4">
              <FormField
                control={form.control}
                name="general_description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-muted-foreground">
                      Difficulty with intellectual functioning including inability to make decisions regarding daily
                      living. Specify extent of any symptoms of confusion, forgetfulness: participation in social
                      activities (i.e., active or withdrawn). Sundowning? When?, Where?, How?
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder=""
                        {...field}
                        value={field.value || ''}
                        disabled={medicalInformationFormStatus === 'loading'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Separator className="col-span-4" />

              <h1 className="text-lg font-semibold">Mental Status</h1>

              <FormField
                control={form.control}
                name="has_dementia"
                render={({ field }) => (
                  <FormItem className="flex items-center gap-4">
                    <FormLabel className="mt-1.5">Has the patient been diagnosed with dementia?</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value as boolean}
                        onCheckedChange={field.onChange}
                        disabled={medicalInformationFormStatus === 'loading'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="dementia_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-muted-foreground">
                      If yes, please specify the type of dementia, the stage, and the date of diagnosis. If no, please
                      specify the reason for the diagnosis.
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        value={field.value}
                        className="w-full flex gap-4 justify-between items-center"
                        disabled={medicalInformationFormStatus === 'loading'}
                      >
                        {dementiaOptions.map((option) => (
                          <FormItem key={option.value} className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={option.value} />
                            </FormControl>
                            <FormLabel className="font-normal">{option.label}</FormLabel>
                          </FormItem>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Separator className="col-span-4" />

              <p className="text-muted-foreground">
                Make sure this information coincide with information provided by the physician on the 602 form.
              </p>

              <div>
                <Table>
                  <TableHeader className="text-lg font-bold bg-primary/80">
                    <TableRow>
                      <TableHead className="text-white">Option</TableHead>
                      <TableHead className="flex gap-16 items-center text-white">
                        <div>Yes</div>
                        <div>No</div>
                      </TableHead>
                      <TableHead className="text-white">Detail</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {dementiaStages.map((stage) => (
                      <TableRow>
                        <TableCell className="font-medium">{stage.label}</TableCell>
                        <TableCell>
                          <FormField
                            control={form.control}
                            name={`dementia_stage.${stage.value}.option`}
                            render={({ field }) => (
                              <FormItem>
                                <FormControl>
                                  <RadioGroup
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    value={field.value}
                                    className="w-full flex gap-20 items-center"
                                    disabled={medicalInformationFormStatus === 'loading'}
                                  >
                                    <FormItem className="flex items-center space-x-3 space-y-0">
                                      <FormControl>
                                        <RadioGroupItem value="yes" />
                                      </FormControl>
                                    </FormItem>
                                    <FormItem className="flex items-center space-x-3 space-y-0">
                                      <FormControl>
                                        <RadioGroupItem value="no" />
                                      </FormControl>
                                    </FormItem>
                                  </RadioGroup>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <FormField
                            control={form.control}
                            name={`dementia_stage.${stage.value}.detail`}
                            render={({ field }) => (
                              <FormItem className="col-span-5">
                                <FormControl>
                                  <Textarea
                                    placeholder=""
                                    {...field}
                                    value={field.value || ''}
                                    disabled={medicalInformationFormStatus === 'loading'}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>

              {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                <div className="col-span-2 space-y-4">
                  <Separator />

                  <div className="flex items-end gap-2 col-span-full xl:col-span-1">
                    <Button
                      className="flex gap-2"
                      variant={'default'}
                      type="submit"
                      disabled={medicalInformationFormStatus === 'loading'}
                    >
                      {medicalInformationFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </Form>
        </CustomAccordion>
      </div>
    </>
  );
};
