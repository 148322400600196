import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
 } from '@/common/presentation/components/ui/dialog';
 import { Button } from '@/common/presentation/components/ui/button';
 import { Label } from '@/common/presentation/components/ui/label';
 import { Input } from '@/common/presentation/components/ui/input';
import { Pencil } from 'lucide-react';
import { useDispatch } from 'react-redux';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useEffect, useState } from 'react';
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';

export function GeneralOutingsEditDialog(props: any) {
    const dispatch = useDispatch();
    const { params, setParam } = useRouteParams();
    const [values, setValues] = useState({
        outingActivity: "",
        duration: "",
        staff: "",
        notes: ""
    });

    useEffect(() => {
        const data = props.data;

        setValues({
            outingActivity: data.data.outingActivity,
            duration: data.data.duration,
            staff: data.data.staff,
            notes: data.data.notes
        });
    }, []);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        await dispatch(trackerActions.updateTrackerOutingsGeneral(
            {
                id: props.data.id,
                id_data: props.data.data.id,
                data: values
            }
        ));

        fetchData();
    }

    const fetchData = async () => {
        await dispatch(trackerActions.getTrackerOutingsGeneral(
            {
              "location_id": params.l
            }
          )
        );
      }

    return (
        <div>
            <Dialog>
                <DialogTrigger asChild>
                    <Button variant="outline" size="icon">
                        <Pencil className="h-4 w-4" />
                    </Button>
                </DialogTrigger>

                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Edit</DialogTitle>
                    </DialogHeader>

                <form className="grid gap-4 py-4" onSubmit={handleSubmit}>
                    <div>
                        <Label htmlFor="outing" className="text-right">Outing/Activity</Label>
                        
                        <Input
                            id="outingActivity"
                            name="outingActivity"
                            defaultValue="Pedro Duarte"
                            type="text"
                            className="col-span-3"
                            value={values.outingActivity}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div>
                        <Label htmlFor="duration" className="text-right">Duration</Label>

                        <Input
                            id="duration"
                            name="duration"
                            defaultValue="@peduarte"
                            className="col-span-3"
                            type="text"
                            value={values.duration}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div>
                        <Label htmlFor="staff" className="text-right">Staff Accompanying and Title</Label>

                        <Input
                            id="staff"
                            name="staff"
                            defaultValue="@peduarte"
                            className="col-span-3"
                            type="text"
                            value={values.staff}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div>
                        <Label htmlFor="notes" className="text-right">Notes</Label>

                        <Input
                            id="notes"
                            name="notes"
                            defaultValue="@peduarte"
                            className="col-span-3"
                            type="text"
                            value={values.notes}
                            onChange={handleInputChange}
                        />
                    </div>

                    <DialogClose asChild>
                        <Button type="submit">Save changes</Button>
                    </DialogClose>
                </form>
        </DialogContent>
        </Dialog>
        </div>
    );
};
