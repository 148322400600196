import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { disposeMedication, getAllMedicationsByResidentId } from '../../../slices/medicationsSlice';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { cn } from '@/lib/utils';
import { RootState } from '@/store/store';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { format, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import columnsDestructionMedications from './columnsDestructionMedications';
import { toast } from '@/common/presentation/components/ui/use-toast';

const FormDestruction = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const residentId = searchParams.get('r');
  const { medicationsByResidentId } = useSelector((state: RootState) => state.medications);
  const { t } = useTranslation();
  const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });
  const [sortedData, setSortedData] = useState([]);

  // useEffect(() => {
  //   if (residentId) {
  //     dispatch(getAllMedicationsByResidentId(residentId));
  //   }
  // }, [residentId, dispatch]);

  const [data, setData] = useState<any[]>(
    medicationsByResidentId.map((medication, index) => ({
      ...medication,
      index,
      quantityToDestroy: 0 // Nuevo campo para la cantidad a destruir
    }))
  );
  const [changedData, setChangedData] = useState({});
  const [editedData, setEditedData] = useState({});

  // useEffect(() => {
  //   setData(
  //     medicationsByResidentId.map((medication, index) => ({
  //       ...medication,
  //       index,
  //       quantityToDestroy: 0
  //     }))
  //   );
  // }, [medicationsByResidentId]);

  useEffect(() => {
    setEditedData({});
  }, [residentId]);

  const handleCellChange = (index, columnKey, value, medicationId) => {
    if (columnKey === 'quantity' && value < 0) {
      toast({
        title: 'Error',
        description: 'No puedes ingresar valores negativos.',
        variant: 'destructive'
      });
      return;
    }

    if (columnKey === 'quantity' && value > data[index].current_quantity) {
      toast({
        title: 'Destroy Error',
        description: 'No puedes destruir más medicación de la disponible.',
        variant: 'destructive'
      });
      return;
    }

    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [columnKey]: handleValueByType(columnKey, value)
      };
      return newData;
    });

    setChangedData((prevChangedData) => ({
      ...prevChangedData,
      [index]: {
        ...prevChangedData[index],
        [columnKey]: value
      }
    }));

    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [index]: {
        ...prevEditedData[index],
        id: medicationId,
        [columnKey]: handleValueByType(columnKey, value),
        medicationId
      }
    }));
  };

  const handleSort = (columnKey, direction) => {
    setSorting({ key: columnKey, direction });

    const sortedDataCopy = [...sortedData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setSortedData(sortedDataCopy);
  };

  const handleValueByType = (columnKey, value) => {
    const column = columnsDestructionMedications.find((col) => col.key === columnKey);

    if (!column) {
      return value;
    }

    switch (column.type) {
      case 'calendar':
        return isValid(new Date(value)) ? new Date(value) : null;
      case 'number':
        return value;
      case 'text':
        return value;
      default:
        return value;
    }
  };

  const renderCellContent = (index, column, data) => {
    return (
      <td key={column.key} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
        {column.type === 'medication' ? (
          data[index][column.key]
        ) : column.editable ? (
          column.type === 'calendar' ? (
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className={cn(
                    'w-full justify-start text-left font-normal',
                    !data[index][column.value] && 'text-muted-foreground'
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {data[index][column.value] ? (
                    format(new Date(data[index][column.value]), t('dateFormats.dateTimeFormat'))
                  ) : (
                    <span>Pick a date and time</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <CustomCalendar
                  onChange={(selectedDate) => handleCellChange(index, column.value, selectedDate, data[index].id)}
                  value={data[index][column.value] ? new Date(data[index][column.value]) : new Date()}
                  name={`${column.value}-${index}`}
                  MonthAndYearPicker
                  timePicker
                />
              </PopoverContent>
            </Popover>
          ) : (
            <Input
              type={column.type === 'number' ? 'number' : 'text'}
              value={data[index][column.key] || ''}
              onChange={(e) => handleCellChange(index, column.key, e.target.value, data[index].id)}
              style={{ width: '100px' }}
              min={0}
            />
          )
        ) : (
          data[index][column.key] || ''
        )}
      </td>
    );
  };

  const handleSave = () => {
    const editedEntries = Object.values(editedData).map((entry) => ({
      ...entry,
      date_filled: entry.date_filled ? formatDateForMySQL(new Date(entry.date_filled)) : null,
      disposal_date: entry.disposal_date ? formatDateForMySQL(new Date(entry.disposal_date)) : null
    }));

    dispatch(disposeMedication(editedEntries));
  };

  const formatDateForMySQL = (date) => {
    return date.toISOString().slice(0, 19).replace('T', ' ');
  };

  const additionalButtons = (
    <>
      <Button onClick={handleSave}>Save</Button>
    </>
  );

  return (
    <div className="flex flex-col gap-4 my-4">
      <TableCustomVirtoso
        data={data}
        columns={columnsDestructionMedications.map((column) => {
          if (column.key === 'quantityToDestroy') {
            return {
              ...column,
              validate: (value) =>
                value > data[column.index].current_quantity
                  ? 'No puedes destruir más medicación de la disponible'
                  : null
            };
          }
          return column;
        })}
        renderCellContent={renderCellContent}
        additionalButtons={additionalButtons}
        onSort={handleSort}
      />
    </div>
  );
};

export default FormDestruction;
