import physicianService from '@/common/infrastructure/services/PhysicianService';
import { RootState } from '@/store/store';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export interface Physician {
  id: string;
  name: string;
  npi: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  email: string;
  physician_type_id: string;
  client_id: string;
  status: string;
}

interface PhysicianState {
  physicians: Physician[];
  physicianTypes: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: PhysicianState = {
  physicians: [],
  physicianTypes: [],
  status: 'idle',
  error: null
};

export const fetchPhysicians = createAsyncThunk('physicians/fetchPhysicians', async () => {
  const response = await physicianService.getAllPhysicians();
  return response;
});

export const fetchPhysicianTypes = createAsyncThunk('physicians/fetchPhysicianTypes', async () => {
  const response = await physicianService.getAllPhysicianTypes();
  return response;
});

export const createPhysician = createAsyncThunk('physicians/createPhysician', async (physician: Physician) => {
  const response = await physicianService.createPhysician(physician);
  return response;
});

export const updatePhysician = createAsyncThunk(
  'physicians/updatePhysician',
  async ({ id, physician }: { id: string; physician: Physician }) => {
    const response = await physicianService.updatePhysician(id, physician);
    return response;
  }
);

const physicianSlice = createSlice({
  name: 'physicians',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhysicians.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPhysicians.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.physicians = action.payload;
      })
      .addCase(fetchPhysicians.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch physicians';
      })
      .addCase(fetchPhysicianTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPhysicianTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.physicianTypes = action.payload;
      })
      .addCase(fetchPhysicianTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch physician types';
      })
      .addCase(createPhysician.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPhysician.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.physicians.push(action.payload);
      })
      .addCase(createPhysician.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create physician';
      })
      .addCase(updatePhysician.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePhysician.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.physicians.findIndex((physician) => physician.id === action.payload.id);
        if (index !== -1) {
          state.physicians[index] = action.payload;
        }
      })
      .addCase(updatePhysician.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update physician';
      });
  }
});

export default physicianSlice.reducer;

export const selectAllPhysicians = (state: RootState) => state.physicians.physicians;
export const selectAllPhysicianTypes = (state: RootState) => state.physicians.physicianTypes;
export const getPhysiciansStatus = (state: RootState) => state.physicians.status;
export const getPhysiciansError = (state: RootState) => state.physicians.error;
