import ResidentService from '@/modules/residents/infrastructure/services/ResidentsService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface ResidentsState {
  residents: any[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: ResidentsState = {
  residents: [],
  status: 'idle',
  error: null
};

export const getResidentsByLocation = createAsyncThunk(
  'residents/getResidents',
  async (locationId: string | undefined) => {
    try {
      const response = await ResidentService.getResidents(locationId);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching residents: ' + error);
    }
  }
);

const residentsSlice = createSlice({
  name: 'residents',
  initialState,
  reducers: {
    setResidents: (state, action) => {
      state.residents = action.payload;
    },
    addResident: (state, action) => {
      state.residents.push(action.payload);
    },
    removeResident: (state, action) => {
      state.residents = state.residents.filter((resident) => resident.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getResidentsByLocation.pending, (state) => {
      state.residents = [];
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(getResidentsByLocation.fulfilled, (state, action) => {
      const residents = action.payload.map((resident: any) => {
        const newResident = {
          ...resident.resident,
          location: resident.location
        };

        return newResident;
      });
      state.residents = residents;
      state.status = 'idle';
      state.error = null;
    });

    builder.addCase(getResidentsByLocation.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.status = 'failed';
      state.residents = [];
    });
  }
});

export const { setResidents, addResident, removeResident } = residentsSlice.actions;
export default residentsSlice.reducer;
