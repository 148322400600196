import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { FormField, FormFieldPayload } from '@/modules/residents/domain/models/FormField';
import { usePlanOfCareStore } from '@/modules/residents/domain/stores/plan-of-care/planOfCareStore';
import { useAddFormFieldsManager } from '@/modules/residents/infrastructure/hooks/plan-of-care/useAddFormFieldsManager';
import { useEditFormFieldsManager } from '@/modules/residents/infrastructure/hooks/plan-of-care/useEditFormFieldsManager';
import { useFormFieldsManager } from '@/modules/residents/infrastructure/hooks/plan-of-care/useFormFieldsManager';
import { Eye, EyeOff, Pencil, Trash } from 'lucide-react';
import { useMemo } from 'react';

export const FormFieldsManagerDialog = () => {
  const { isOpen, onClose, formFields } = useFormFieldsManager();
  const { onOpen: openFormFieldDialog } = useAddFormFieldsManager();
  const { onOpen: openEditFieldDialog } = useEditFormFieldsManager();
  const { status, error: errorMessage, addNewFieldForm, getFormFields } = usePlanOfCareStore();

  const fields = useMemo(() => formFields, [formFields]);

  const handleHideOrShowField = async (field: FormField) => {
    const payload: FormFieldPayload = {
      form_id: 'plan_of_care',
      ...field,
      isVisible: !field.isVisible
    };

    try {
      await addNewFieldForm(payload);

      toast({
        description: 'Field added successfully',
        variant: 'default'
      });

      await getFormFields('plan_of_care');
    } catch (error: any) {
      console.error('Error updating field:', error);
    }
  };

  const handleDeleteField = async (field: any) => {
    const payload: FormFieldPayload = {
      form_id: 'plan_of_care',
      ...field,
      status: 'inactive'
    };

    try {
      await addNewFieldForm(payload);

      toast({
        description: 'Field added successfully',
        variant: 'default'
      });

      await getFormFields('plan_of_care');
    } catch (error: any) {
      console.error('Error updating field:', error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[90vw] md:max-w-[30vw] max-h-[80vh] h-auto overflow-y-auto">
        <DialogHeader>
          <div className="flex items-center justify-between gap-4">
            <DialogTitle className="text-xl text-primary font-bold">Manage Fields</DialogTitle>
            <Button
              onClick={openFormFieldDialog}
              variant={'outline'}
              className="hover:bg-primary hover:text-white mr-4"
              size={'sm'}
            >
              Add new field
            </Button>
          </div>
          <Separator className="bg-primary" />
        </DialogHeader>

        {fields.map((field, index) => {
          return (
            <Card key={index} className="flex hover:cursor-pointer">
              <CardHeader className="flex-1 py-4 w-[250px]">
                <CardTitle className="text-lg font-normal flex items-center">
                  <span className="overflow-hidden whitespace-nowrap text-ellipsis">{field.label}</span>
                  {field.isDefault && (
                    <Badge className="ml-2 border border-foreground bg-transparent hover:bg-transparent text-foreground">
                      Default
                    </Badge>
                  )}
                </CardTitle>
              </CardHeader>
              <CardContent className="flex items-center gap-2 p-0 mx-4">
                {!field.isDefault && (
                  <Button
                    onClick={() => openEditFieldDialog(field)}
                    size={'icon'}
                    variant={'outline'}
                    className="hover:bg-primary hover:text-white"
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                )}
                <Button
                  onClick={() => handleHideOrShowField(field)}
                  size={'icon'}
                  variant={'outline'}
                  className="hover:bg-primary hover:text-white"
                >
                  {field.isVisible ? <Eye className="h-4 w-4" /> : <EyeOff className="h-4 w-4" />}
                </Button>
                {!field.isDefault && (
                  <Button
                    onClick={() => handleDeleteField(field)}
                    size={'icon'}
                    variant={'outline'}
                    className="hover:bg-primary hover:text-white"
                  >
                    <Trash className="h-4 w-4" />
                  </Button>
                )}
              </CardContent>
            </Card>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};
