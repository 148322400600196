import RenderActionsColumn from './renderActionsColumn';

const columnsReportMedications = (residentId) => [
  {
    id: 'month',
    key: 'month',
    labelTranslationKey: 'medications.reportCentralizedMedications.reportDate',
    type: 'medication',
    value: 'month',
    visible: true,
    static: true,
    sortable: true
  },
  {
    key: 'pdf',
    labelTranslationKey: 'Pdf',
    visible: true,
    sortable: true,
    editable: true,
    type: 'action',
    render: () => <RenderActionsColumn residentId={residentId} />
  }
];

export default columnsReportMedications;
