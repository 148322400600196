import { Button } from '@/common/presentation/components/ui/button';
import { cn } from '@/lib/utils';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

interface TimePickerProps {
  onChange: (time: string) => void;
  value: string;
}

const TimePicker = ({ onChange, value }: TimePickerProps) => {
  
  const [hour, minute]:any = value.split(':').map( Number => parseInt(Number) ) || [0, 0];
  const [period, setPeriod] = useState<AmPmType>(hour < 12 ? 'AM' : 'PM');
  const [time, setTime] = useState(value);
  const [minutes, setMinutes] = useState(minute);

  const handleTimeChange = (hour: number, period: AmPmType) => {
    const newHour = convert12to24(hour, period);
    setTime(`${newHour}:${minutes}`);
    setPeriod(period);
  };

  useEffect(() => {
    const minuteValue = minutes.toString().padStart(2, '0');

    onChange?.(`${convert24to12( parseInt(time.split(':')[0]) )[0]}:${minuteValue} ${period}`);

  }, [onChange, time, minutes, period]);

  return (
    <>
      <div className="rounded-md py-4 w-full flex flex-row justify-center items-center gap-2">
        <TimeInput
          onChange={(value) => handleTimeChange(value, period)}
          max={12}
          min={1}
          value={convert24to12(hour)[0]}
        />
        <span className="text-2xl">:</span>
        <TimeInput onChange={(value) => setMinutes(value)} value={minutes} />
        <Period onChange={(value) => handleTimeChange(hour, value)} period={period} />
      </div>
    </>
  );
};

interface TimeInputProps {
  max?: number;
  min?: number;
  onChange: (value: number) => void;
  value: number;
}

const TimeInput = ({ max = 59, min = 0, onChange, value }: TimeInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [time, setTime] = useState<number>(value);

  const formatTime = (value: number) => {
    return value < 10 ? `0${value}` : value.toString();
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (inputRef.current?.value.length === 3 && inputRef.current?.value.startsWith('0')) {
      inputRef.current.value = inputRef.current.value.slice(1);
    }

    setTime(value);
  };

  const incrementTime = () => {
    setTime((prev) => {
      const newValue = prev + 1;
      return newValue <= max ? newValue : min;
    });
  };

  const decrementTime = () => {
    setTime((prev) => {
      const newValue = prev - 1;
      return newValue >= min ? newValue : max;
    });
  };

  useEffect(() => {
    onChange?.(time);
  }, [time]);

  return (
    <>
      <div className="bg-muted h-[90px] rounded-md flex flex-col items-center justify-center hover:bg-primary/10">
        <ChevronUp className="p-1 hover:cursor-pointer" onClick={incrementTime} role="button" />
        <input
          ref={inputRef}
          type="number"
          inputMode="numeric"
          min={min}
          max={max}
          className="w-12 bg-transparent text-center text-xl focus-visible:outline-0"
          value={formatTime(time)}
          onChange={handleTimeChange}
        />
        <ChevronDown className="p-1 hover:cursor-pointer" onClick={decrementTime} role="button" />
      </div>
    </>
  );
};

interface PeriodProps {
  period?: AmPmType;
  onChange: (period: AmPmType) => void;
}

const Period = ({ period = 'AM', onChange }: PeriodProps) => {
  const periods = ['AM', 'PM'];
  const buttonCommonClass = 'w-16 bg-muted/50 text-black text-xs rounded-md hover:bg-primary/40 hover:text-white';

  const getButtonClass = (p: string) => {
    return cn(buttonCommonClass, period === p && 'bg-primary text-white');
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        {periods.map((p) => (
          <Button key={p} className={getButtonClass(p)} onClick={() => onChange(p as AmPmType)}>
            {p.toUpperCase()}
          </Button>
        ))}
      </div>
    </>
  );
};

export type AmPmType = 'AM' | 'PM';

const convert12to24 = (hour12: number, amPm: AmPmType): number => {
  let hour24 = hour12;

  if (amPm === 'AM' && hour24 === 12) {
    hour24 = 0;
  } else if (amPm === 'PM' && hour24 < 12) {
    hour24 += 12;
  }

  return hour24;
};

const convert24to12 = (hour24: number): [number, AmPmType] => {
  const hour12 = hour24 % 12 || 12;
  return [hour12, hour24 < 12 ? 'AM' : 'PM'];
};

export default TimePicker;
