import  NotesPage  from '@/modules/notes/presentation/pages/NotesPage';
import { Route, Routes } from 'react-router-dom';

const NotesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<NotesPage />}></Route>
    </Routes>
  );
};

export default NotesRouter;
