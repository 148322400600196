import { TriangleAlert, InfoIcon } from 'lucide-react';
import React from 'react';

interface props {
    type: string;
};

const Icon: React.FC<props> = ({ type }) => {
    const iconClasses = `h-4 w-4 ${type === 'warning' ? 'stroke-amber-400' : 'stroke-primary'} `;
    return (
        <>
            {type == 'warning' ? <TriangleAlert className={iconClasses} /> : <InfoIcon className={iconClasses} />}
        </>
    );
}

export default Icon;