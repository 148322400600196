import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { PrnTableActions } from './PrnTableActions';

export const prnColumns: ColumnDef<any>[] = [
  {
    header: 'Date',
    accessorKey: 'dispensed_at',
    cell: ({ row }) => {
      return format(new Date(row.original.dispensed_at), 'MM/dd/yyyy hh:mm a');
    }
  },
  {
    header: 'Reason',
    accessorKey: 'reason'
  },
  {
    header: 'Result',
    accessorKey: 'result'
  },
  {
    header: 'Dispensed By',
    accessorKey: 'created_by',
    cell: ({ row }) => {
      return `${row.original.created_by.first_name} ${row.original.created_by.last_name}`;
    }
  },
  {
    id: 'actions',
    cell: ({ row }) => <PrnTableActions data={row.original} />
  }
];
