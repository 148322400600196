import AuthService from '@/auth/infrastructure/services/authService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  user: any | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  roles: string[];
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  isLoading: true,
  roles: [],
  error: null
};

export const login = createAsyncThunk(
  'auth/login',
  async (data: { username: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ message: error.response?.data?.message || 'Login failed' });
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    const response = await AuthService.logout();
    return response.data;
  } catch (error: any) {
    return rejectWithValue({ message: error.response?.data?.message || 'Logout failed' });
  }
});

export const verifySession = createAsyncThunk('auth/verifySession', async (_, { rejectWithValue }) => {
  try {
    const response = await AuthService.verifySession();
    return response.data;
  } catch (error: any) {
    return rejectWithValue({ message: error.response?.data?.message || 'Session verification failed' });
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    unSetAuth: (state) => {
      state.user = null;
      state.roles = [];
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = null;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isAuthenticated = false;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.roles = action.payload.user.roles;
        state.isAuthenticated = true;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.user = null;
        state.roles = [];
        state.isAuthenticated = false;
        state.isLoading = false;
        state.error = (action.payload as { message: string })?.message || action.error.message || 'An error occurred';
      });

    builder
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.roles = [];
        state.isAuthenticated = false;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.user = null;
        state.roles = [];
        state.isAuthenticated = false;
        state.isLoading = false;
        state.error = (action.payload as { message: string })?.message || action.error.message || 'An error occurred';
      });

    builder
      .addCase(verifySession.pending, (state) => {
        state.isLoading = true;
        state.isAuthenticated = false;
        state.error = null;
      })
      .addCase(verifySession.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.roles = action.payload.user.roles;
        state.isAuthenticated = true;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(verifySession.rejected, (state, action) => {
        state.user = null;
        state.roles = [];
        state.isAuthenticated = false;
        state.isLoading = false;
        state.error = (action.payload as { message: string })?.message || action.error.message || 'An error occurred';
      });
  }
});

export const { unSetAuth, setRoles } = authSlice.actions;
export default authSlice.reducer;
