import { AllergiesForm } from './forms/AllergiesForm';
import { DiagnosisForm } from './forms/DiagnosisForm';
import { ExamAndProcedure } from './forms/ExamAndProcedure';
import { HealthForm } from './forms/HealthForm';
import { MedicalActions } from './forms/MedicalActions';
import { MentalConditionForm } from './forms/MentalConditionForm';
import { TuberculosisInformationForm } from './forms/TuberculosisInformation';

export const MedicalInformation = () => {
  return (
    <>
      <div className="grid grid-cols-4 rounded-lg border dark:bg-[#1d1d1d] mt-4 p-4 w-full gap-4">
        <div className="col-span-4 xl:col-span-4 flex flex-col gap-4">
          <DiagnosisForm />
        </div>
        <div className="col-span-4 xl:col-span-4 flex flex-col gap-4">
          <AllergiesForm />
        </div>
        <div className="col-span-4 xl:col-span-4 flex flex-col gap-4">
          <HealthForm />
        </div>
        <div className="col-span-4 xl:col-span-4 flex flex-col gap-4">
          <ExamAndProcedure />
        </div>
        <div className="col-span-4 xl:col-span-4 flex flex-col gap-4">
          <MentalConditionForm />
        </div>
        <div className="col-span-4 xl:col-span-4 flex flex-col gap-4">
          <TuberculosisInformationForm />
        </div>
        <div className="col-span-4 xl:col-span-4 flex flex-col gap-4">
          <MedicalActions />
        </div>
      </div>
    </>
  );
};
