import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';
import { useNewResident } from '../../infrastructure/hooks/use-new-resident';
import { NewResidentForm } from './new-resident-form';

export const NewResidentDialog = () => {
  const { isOpen, onClose } = useNewResident();

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[1280px] max-h-[80vh] h-full">
          <DialogHeader>
            <DialogTitle className="text-xl">New Resident Form</DialogTitle>
            <DialogDescription>This is a form to add a new resident to the system.</DialogDescription>
          </DialogHeader>
          <NewResidentForm />
        </DialogContent>
      </Dialog>
    </>
  );
};
