import LocationService from '@/modules/locations/infrastructure/service/LocationService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setLocation } from './locationSlice';

interface LocationsState {
  locations: any[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: LocationsState = {
  locations: [],
  status: 'idle',
  error: null
};

export const getLocations = createAsyncThunk('location/getLocations', async () => {
  const response = await LocationService.getAllLocations();
  const data = response.data.map((location: any) => {
    return location.location;
  });
  return data;
});

export const getUsersByLocation = createAsyncThunk('location/getUsersByLocation', async (locationId: string) => {
  const response = await LocationService.getUsersByLocation(locationId);
  return response.data;
});

const locationsSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    addLocation: (state, action) => {
      state.locations.push(action.payload);
    },
    removeLocation: (state, action) => {
      state.locations = state.locations.filter((location) => location.id !== action.payload);
    },
    unSetLocations: (state) => {
      state.locations = [];
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.status = 'idle';
      state.locations = action.payload;
      state.error = null;
    });

    builder.addCase(getLocations.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});

export const { setLocations, addLocation, removeLocation, unSetLocations } = locationsSlice.actions;
export default locationsSlice.reducer;
