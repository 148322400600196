import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StaffService from '../../infrastructure/services/StaffService';

interface StaffMemberFormState {
  status: 'idle' | 'loading' | 'failed';
  isValid: boolean;
  error: string | null;
}

const initialState: StaffMemberFormState = {
  status: 'idle',
  isValid: false,
  error: null
};

export const updateStaffPersonalInformationForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffPersonalInformation(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);

export const updateCustomFieldsForm = createAsyncThunk('staffForm/updateCustomFieldsForm', async (data: any) => {
  try {
    const response = await StaffService.updateCustomFieldsForm(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
  }
});

export const deletestaffProfileImage = createAsyncThunk('residentsForm/deleteProfileImage', async (residentId: string) => {
  try {
    const response = await StaffService.deleteProfileImage(residentId);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error deleting profile Image: ' + error);
  }
});
export const updateStaffMemberTerminationForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffMemberTerminationForm(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating Termination: ' + error);
    }
  }
);
export const updateStaffcompanyForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffcompanyForm(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);
export const updateStaffTrainingForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffTrainingForm(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);
export const updateStaffnotesForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffnotesForm(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);
export const updateStaffloginForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffloginForm(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);
export const uploadStaffProfileImage = createAsyncThunk('residentsForm/uploadProfileImage', async (data: any) => {
  try {
    const response = await StaffService.uploadStaffProfileImage(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error uploading profile Image: ' + error);
  }
});
const staffMemberFormSlice = createSlice({
  name: 'staffMemberForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateStaffPersonalInformationForm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateStaffPersonalInformationForm.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(updateStaffPersonalInformationForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  }
});

export default staffMemberFormSlice.reducer;
