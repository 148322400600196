import { Skeleton } from '@/common/presentation/components/ui/skeleton';

const AlertsSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 pt-1">
      {Array.from({ length: 6 }).map((_, index) => (
        <Skeleton key={index} className="h-5 w-11/12  bg-black/10 dark:bg-gray-500/20" />
      ))}
    </div>
  );
};

export default AlertsSkeleton;
