import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Contact, Mail, MapPinned, Smartphone } from 'lucide-react';

interface Props {
  className?: string;
}

const contacs = [
  {
    responsiblePartyName: 'Robert Adams',
    address: '555 Main St , Seal Beach , CA , 90740',
    email: 'main@main.com',
    phone: '(321) 911-3264'
  },
  {
    primaryPhysicianName: 'Doctor Feelgood',
    address: '123 Main ST, Downey , Ca, 90876',
    email: 'test@mail.com',
    phone: '(255) 555-5554'
  }
];

export const ContactsCard = ({ className }: Props) => {
  return (
    <>
      <div className={className}>
        <CustomAccordion
          titleComponent={
            <div className="flex items-center justify-start text-primary">
              <Contact className="size-5 mr-2" />
              <h1 className="font-bold">Contacts</h1>
            </div>
          }
          openOption={false}
          defaultOpen
        >
          <div className="flex flex-col p-2 gap-4">
            <div className="w-full grid grid-cols-2 gap-x-4">
              <div className="flex justify-between">
                <h1 className="">DNR:</h1>
                <span>Yes</span>
              </div>
              <div className="flex justify-between">
                <h1 className="">POLST:</h1>
                <span>Yes</span>
              </div>
              <div className="flex justify-between">
                <h1 className="">Hospice:</h1>
                <span>Yes</span>
              </div>
              <div className="flex justify-between">
                <h1 className="">Conserved:</h1>
                <span>Yes</span>
              </div>
            </div>

            <Separator />

            <ScrollArea className="h-[175px] w-full">
              <div className="flex flex-col gap-4 px-2">
                {contacs.map((contact, index) => (
                  <div key={index} className="space-y-4 border p-1 rounded-md">
                    <div className="flex flex-row gap-4">
                      <h1 className=" ">Primary Physician Name:</h1>
                      <p>{contact.primaryPhysicianName}</p>
                    </div>
                    <div className="">
                      <div className="flex flex-row gap-4">
                        <MapPinned className="text-primary" />
                        <p>{contact.address}</p>
                      </div>
                      <div className="flex flex-row gap-4">
                        <Mail className="text-primary" />
                        <p>{contact.email}</p>
                      </div>
                      <div className="flex flex-row gap-4">
                        <Smartphone className="text-primary" />
                        <p>{contact.phone}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </div>
        </CustomAccordion>
      </div>
    </>
  );
};
