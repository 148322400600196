import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PharmacyService from "../../infrastructure/services/PharmacyService";

export const fetchPharmacies = createAsyncThunk(
  "pharmacy/fetchPharmacies",
  async (locationId) => {
    try {
      const response = await PharmacyService.getPharmacy(locationId);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching pharmacies: ' + error);
    }
  }
);

export const createNewPharmacy = createAsyncThunk(
  "pharmacy/createNewPharmacy",
  async (formData) => {
    const response = await PharmacyService.createPharmacy(formData);
    return response.data;
  }
);

const initialState = {
  pharmacies: [],
  status: "idle",
  error: null,
};

const pharmacySlice = createSlice({
  name: "pharmacy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPharmacies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPharmacies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pharmacies = action.payload;
      })
      .addCase(fetchPharmacies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Error fetching pharmacies";
      })
      .addCase(createNewPharmacy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createNewPharmacy.fulfilled, (state) => {
      })
      .addCase(createNewPharmacy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Error creating pharmacy";
      });
  },
});

export default pharmacySlice.reducer;

export const selectPharmacies = (state) => state.pharmacy.pharmacies;
export const selectPharmacyStatus = (state) => state.pharmacy.status;
export const selectPharmacyError = (state) => state.pharmacy.error;
