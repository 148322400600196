import React from 'react';
import { Controller } from 'react-hook-form';
import { Label } from '@/common/presentation/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import TimeSelector from '../TimeSelector';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import TypeOptions from '../TypeOptions';
import { Input } from '@/components/ui/input';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';

interface MedicationFormSelectProps {
  control: any;
  setValue: (name: string, value: any) => void;
  handleSelect: (option: any) => void;
  types: any[];
  residentMedicationTime: any[];
  selectedTimes: string[];
  setSelectedTimes: (times: string[]) => void;
  handleSelectedTimesChange: (selectedTimes: string[]) => void;
  handleSelectedTypeChange: (type: any) => void;
  selectedListForm: any[];
  typeName: string;
  strength: string;
  medication: string;
}

const MedicationFormSelect: React.FC<MedicationFormSelectProps> = ({
  control,
  setValue,
  handleSelect,
  types,
  selectedTimes,
  setSelectedTimes,
  handleSelectedTimesChange,
  handleSelectedTypeChange,
  strength,
  typeName,
  medication,
  selectedListForm
}) => {
  const { t } = useTranslation();

  const handleTypeChange = (value: string) => {
    const selectedType = types.find((type) => type.id === value);
    if (selectedType) {
      handleSelectedTypeChange(selectedType);
    }
  };

  const handleSpecialDayChange = (updatedData: { selectedDays: string[]; selectedDate: Date[]; text: string }) => {
    setValue('special_days', updatedData.selectedDays);
  };

  return (
    <div className="p-2">
      <div className="grid grid-cols-1 gap-4 mb-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="md:col-span-1">
            <Label htmlFor="medications">
              Medications <span className="text-primary">*</span>
            </Label>
            <Controller
              name="medication"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  {...field}
                  value={medication}
                  onChange={(e) => {
                    const newMedication = e.target.value;
                    field.onChange(newMedication);
                    handleSelect(newMedication);
                  }}
                  disabled={!!field.value}
                  className="p-2 w-full border border-gray-300 rounded-md focus:border-primary focus:ring-primary disabled:text-gray-500 disabled:opacity-100"
                />
              )}
            />
          </div>
          <div className="md:col-span-1">
            <Label htmlFor="form">
              Form <span className="text-primary">*</span>
            </Label>
            <Controller
              name="form"
              control={control}
              render={({ field }) => (
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}
                  disabled={selectedListForm.length === 0}
                  className="w-full"
                >
                  <SelectTrigger className="w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                    <SelectValue placeholder="Select Form" />
                  </SelectTrigger>
                  <SelectContent>
                    {selectedListForm.map((form, index) => (
                      <SelectItem key={index} value={form.name || form}>
                        {form.name || form}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>
          {strength ? (
            <div className="md:col-span-1">
              <Label htmlFor="strength">
                Strength <span className="text-primary">*</span>
              </Label>
              <Controller
                name="strength"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                    placeholder={t('medications.medicationForm.searchPlaceholder')}
                    {...field}
                  />
                )}
              />
            </div>
          ) : (
            <div className="md:col-span-1">
              <Label htmlFor="type">
                Type <span className="text-primary">*</span>
              </Label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    onValueChange={(value) => {
                      handleTypeChange(value);
                      field.onChange(value);
                    }}
                    value={field.value}
                    disabled={types.length === 0}
                    className="w-full"
                  >
                    <SelectTrigger className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                      <SelectValue placeholder="Select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      {types.map((type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
          {strength && (
            <div className="col-span-1">
              <Label htmlFor="type">
                Type <span className="text-primary">*</span>
              </Label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    onValueChange={(value) => {
                      handleTypeChange(value);
                      field.onChange(value);
                    }}
                    value={field.value}
                    disabled={types.length === 0}
                    className="w-full"
                  >
                    <SelectTrigger className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                      <SelectValue placeholder="Select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      {types.map((type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          )}
          <div className="col-span-2">
            <Label htmlFor="time">
              Times <span className="text-primary">*</span>
            </Label>
            <Controller
              name="time"
              control={control}
              render={({ field }) => (
                <TimeSelector
                  onChange={(selectedTimes) => {
                    handleSelectedTimesChange(selectedTimes);
                    field.onChange(selectedTimes);
                  }}
                  medicationTimes={selectedTimes}
                  selectedTimes={field.value}
                  value={setSelectedTimes}
                  typeName={typeName}
                />
              )}
            />
          </div>
          <div className="col-span-4">
            {typeName === 'Routine' && (
              <TypeOptions
                type={typeName}
                // onDaysChange={handleDaysChange}
                // onUpdateFormData={handleSpecialDayChange}
                // special_days={specialDaysValue}
              />
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="col-span-1">
            <Label htmlFor="instruction" className="text-sm block mb-1">
              Instruction <span className="text-primary">*</span>
            </Label>
            <Controller
              name="instruction"
              control={control}
              render={({ field }) => (
                <Textarea
                  cols={2}
                  rows={2}
                  className="resize-none text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0 placeholder:text-gray-300"
                  style={{ height: 'auto', minHeight: 'unset', maxHeight: 'unset' }}
                  placeholder={t('medications.medicationForm.enterInstruction')}
                  {...field}
                />
              )}
            />
          </div>

          <div className="col-span-1">
            <Label htmlFor="special" className="text-sm block mb-1">
              Special Instructions <span className="text-primary">*</span>
            </Label>
            <Controller
              name="special"
              control={control}
              render={({ field }) => (
                <Textarea
                  cols={2}
                  rows={2}
                  id="special"
                  className="resize-none text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0 placeholder:text-gray-300"
                  style={{ height: 'auto', minHeight: 'unset', maxHeight: 'unset' }}
                  {...field}
                  placeholder={t('medications.medicationForm.enterInstruction')}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicationFormSelect;
