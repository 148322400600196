export enum relatedToTypes {
  residents = 'residents',
  users = 'users'
};

export type TypeRelatedTo = 'residents' | 'users';

export interface Calendar {
  tasksEvents: ITaskEvent[];
  categories: ICategory[];
  confirmations: {
    deleting: {};
  };
  taskEventInfo: object;
  taskEventsList: ITaskEventList[];
  updated: boolean;
  status: 'idle' | 'loading' | 'failed'; // idle Estado de reposo o inactividad
  error: string | null;
  staff: any;
  usersToNotify: string[];
}

export interface ITaskEvent {
  id?: string;
  title: string;
  start: string;
  end: string;
  allDay: boolean;
  eventId: string;
  options: string;
  color: string;
  categoryName: string;
  residentName: string;
  detail: string;
  category: ICategory[];
  byUser: string;
}

export interface ITaskEventList {
  id: string;
  title: string;
  detail: string;
  resident_id: string;
  resident: IResident[];
  related_to: TypeRelatedTo;
  category: ICategory;
  start_date_time: string;
  user: IUser;
  task_event_has_users: ITaskEventHasUser[]
}

export interface ITaskEventHasUser {
  id: string;
  user_id: string;
  users: IUser;
};

export interface IUser {
  id: string;
  first_name: string;
  last_name: string;
}

export interface IResident {
  id: string;
  first_name: string;
  last_name: string;
}

export interface ICategory {
  id: string;
  name: string;
  color: string;
}

export interface IDataReport {
  resident_id: string;
  location_id: string;
  categories: {
    value: string;
    label: string;
  }[];
  date_range: {
    from: string;
    to: string | null;
  };
}

export interface ICalendarResumeData {
  resident_id: string;
  user_id: string;
  location_id: string;
  page: number;
  per_page: number;
  related_to: TypeRelatedTo;
  title?: string;
  categories?: { value: string; label: string }[];
  date_range?: {
    from: string;
    to: string | null;
  } | null;
}

export interface ICalendarTableItem {
  id: string;
  title: string;
  detail: string;
  related_to: string;
  resident_id: string;
  user_id: string;
  date_time: string;
  category: string;
  user: string;
  created_by: string;
}

export interface IDeleteRecurringEvent {
  eventId: string | Date;
  startDate: string | Date;
  type: string;
}

export interface IdataEvent {
  extendedProps: {
    eventId: string;
    options: string;
    residentName: string;
    categoryName: string;
    byUser: string;
    startTime: string;
    startDate: string;
    endTime: string;
  };
  startDate: string | Date;
  endDate: string;
  allDay: boolean;
  title: string;
  date: string | Date;
  color: string;
}

export interface FetchParamsData {
  start?: string | Date;
  end?: string | Date;
  locationId: string;
  type?: object;
  category?: string;
  selected_date?: Date | string;
}

export interface InfoEventCardProp {
  open: boolean;
  onClose: () => void;
  eventTaskId: string;
  startEndDate?: { start: string | Date, end: string | Date };
  fetchParamsData?: FetchParamsData;
  onSelectedDate?: (date: any) => void;
  locationId?: string;
  residentId?: string;
  fromCalendar?: boolean;
  onUpdated?: () => void;
  allowEdit?: boolean;
}

export interface IAlertDeleteEventDoesNotRepeatProp {
  open: boolean;
  openChange: (val: boolean) => void;
  onDelete: () => void;
}

export interface IDialogDeleteRecurringEventProp {
  open: boolean;
  openChange: (val: boolean) => void;
  dispatchDeleteData: () => Promise<void>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  allEvents?: boolean;
}

export interface ICategoriesColorFilter {
  open: boolean;
  openChange: (val: boolean) => void;
  categories: ICategory[];
  value: string;
  onChange: (event: string) => void;
}
