import { FormDataProvider } from '@/modules/wizard/infrastructure/providers/FormDataProvider';
import SignUpPage from '@/modules/wizard/presentation/pages/SignUpPage';
import { Route, Routes } from 'react-router-dom';

const SignUpRouter = () => {
  return (
    <FormDataProvider>
      <Routes>
        <Route path="/" element={<SignUpPage />} />
        <Route path="/:client_id/:user_id" element={<SignUpPage />} />
      </Routes>
    </FormDataProvider>
  );
};

export default SignUpRouter;
