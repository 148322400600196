import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { ITrackerCleaningArea } from "./TrackerCleaningMockup";
import { TrackerCleaningAreaEditDialog } from "./TrackerCleaningAreaEditDialog";
import { TrackerCleaningAreaCreateDialog } from "./TrackerCleaningAreaCreateDialog";
import { Input } from "@/common/presentation/components/ui/input";
import { useEffect, useState } from "react";
import { Label } from "@/common/presentation/components/ui/label";
import { TrackerCleaningAreaDeleteDialog } from "./TrackerCleaningAreaDeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { getAreas } from "../../slices/TrackerCleaningSlice";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { Search } from "lucide-react";
import { useTranslation } from "react-i18next";

export function TrackerCleaningArea() {
    const dispatch = useDispatch();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const { params } = useRouteParams();
    const { t } = useTranslation();
    const [filter, setFilter] = useState("");
    let [dataSource, setDataSource] = useState<ITrackerCleaningArea[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setDataSource(trackerCleaning.areas);
    }, [trackerCleaning]);

    const handleFilterChange = (event: any) => {
        setFilter(event.target.value);
    }

    const fetchData = async () => {
        await dispatch(getAreas(
            {
                location_id: params.l
            }
        ));
    }

    return (
        <div className="grid grid-cols-1 items-end gap-2 w-100">
            <div className="justify-self-end">
                <TrackerCleaningAreaCreateDialog />
            </div>

            {
                dataSource.length ? (
                    <Table>
                        <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                            <TableRow className="bg-primary/80 hover:bg-primary/80">
                                <TableHead className="text-white w-100">{t("cleaning.create.area.table.area")}</TableHead>
                                <TableHead className="text-white w-5">{t("cleaning.create.area.table.actions")}</TableHead>
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            {
                                dataSource.length && dataSource.map(
                                    element =>
                                    <TableRow>
                                        <TableCell className="w-100">{element.name}</TableCell>
                                        <TableCell className="flex text-nowrap">
                                            <TrackerCleaningAreaEditDialog id={element.id} name={element.name} status={element.status} />
                                            <TrackerCleaningAreaDeleteDialog id={element.id} />
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                ) : (
                    <div className="flex flex-col w-100 justify-center align-center">
                        <div className="flex justify-center items-center">
                            <Search className="text-center w-5 mb-2" />
                        </div>

                        <h1 className="text-center">{t("cleaning.create.area.table.notFound")}</h1>
                    </div>
                )
            }
        </div>
    );
}
