import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';

import { useNewStaff } from '../../infrastructure/hooks/use-new-staff';
import { StaffForm } from './StaffForm';
import * as staffActions from '@/modules/staff/presentation/slices/staffSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { toast } from '@/common/presentation/components/ui/use-toast';
import useRouteParams from '@/common/hooks/RouteParamsHook';

export const NewstaffDialog = () => {
  const { isOpen, onClose } = useNewStaff();

  const { status } = useSelector((state: RootState) => state.staff.allStaff);

  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();

  const onSubmit = async (values: any) => {
    const response: any = await dispatch(staffActions.createStaffMember(values));

    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(staffActions.getStaffByLocation(params.l === 'all-locations' ? undefined : params.l));
      onClose();
      toast({
        title: 'Staff member created'
      });
    }

    if (response.meta.requestStatus === 'rejected') {
      const errorMessage = response?.error?.message || 'An error occurred';
      toast({
        description: errorMessage,
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[1290px] max-h-[80vh] h-full border-t-4 border-t-primary/80">
          <DialogHeader>
            <DialogTitle className="text-xl">New User </DialogTitle>
            <DialogDescription>This is a form to add a new user to the system.</DialogDescription>
          </DialogHeader>
          <StaffForm
            onSubmit={onSubmit}
            disabled={status === 'loading'}
            defaultValues={{
              first_name: '',
              middle_name: '',
              last_name: '',
              dob: undefined,
              sex: '',
              email: '',
              user_level: '',
              username: '',
              password: '',
              confirmpassword: '',
              locations: [],
              position_title: ''
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
