import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { HistoryTableColumns } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/HistoryTableColumns';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const FormsHistory = () => {
  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const { getFormHistory, forms } = useFormsStore();

  const getFormHistoryReq = async (residentId: string, key: string) => {
    await getFormHistory(residentId, key);
  };

  useEffect(() => {
    getFormHistoryReq(resident?.id, 'appraisal_needs_and_services');
  }, [resident]);

  return (
    <div>
      <SingleTable data={forms} columns={HistoryTableColumns} onDelete={() => {}} disable={false} filter={false} />
    </div>
  );
};
