import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Button } from '@/common/presentation/components/ui/button';
import { useNewMar } from '../../infrastructure/hooks/use-new-mar';
export const NavigationItems = () => {
  const { params } = useRouteParams();
  const { onOpen } = useNewMar();

  return (
    <>
      <div className="flex flex-row items-center gap-1">
        <Button
          className="flex flex-row items-center gap-2"
          onClick={() => onOpen(params.l || undefined, params.r || undefined)}
          size="sm"
        >
          <img src="/svg/pdf_icon.svg" alt="Icono de PDF" className="size-5" />
          <span>MAR</span>
        </Button>
      </div>
    </>
  );
};
