import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { ResidentSelector } from "@/common/presentation/components/Selectors/ResidentSelector";
import { Button } from "@/common/presentation/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { RootState } from "@/store/store";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ITrackerSeizureForm } from "./TrackerSeizureMockup";
import { format } from "date-fns";
import { createSeizure } from "../../slices/TrackerSeizureSlice";
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";

export function TrackerSeizureForm() {
    const dispatch = useDispatch();
    const { params, setParam } = useRouteParams();
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const [location, setLocation] = useState<string | null>(null);
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const { t } = useTranslation();
    const { toast } = useToast();
    const { locationSelectedId } = useLocation();
    const [values, setValues] = useState<ITrackerSeizureForm>({
        date: null,
        duration: "",
        detail: ""
    });

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        params.l === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === params.l);
                }
            };
            fetchData();
        }

        if (!params.l && locations.length === 1) {
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setLocation(params.l);

        if (params.l && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleDateChange = (value: any) => {
       setValues({
            ...values,
            date: value
        });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if(params.r) {
            if(values.date && values.detail && values.duration) {
                dispatch(createSeizure(
                    {
                        location_id: locationSelectedId,
                        data: {
                            ...values,
                            date: values.date.toISOString(),
                            resident: params.r
                        }
                    }
                ));

                toast(
                    {
                        description: t("seizure.form.toast.success"),
                        className: 'bg-green-500 text-white'
                    }
                );

                cleanInputs();
            } else {
                toast(
                    {
                        description: t("seizure.form.toast.required") 
                    }
                );
            }
        } else {
            toast(
                {
                    description: t("seizure.form.toast.residentRequired") 
                }
            );
        }
    }

    const cleanInputs = () => {
        setValues({
            date: new Date(),
            duration: "",
            detail: ""
        });
    };

    return (
        <div>
            {
                locationSelectedId && (
                    <div className="flex flex-col gap-4">
                        <div>
                            <ResidentSelector
                                onSelect={onResidentSelect}
                                locationId={location}
                                residentId={params.r}
                                showAllResidents={false}
                            />
                        </div>
                    </div>
                )
            }

            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("seizure.form.form.title")}
                    </CardTitle>
                </CardHeader>

                <CardContent>
                <form onSubmit={handleSubmit}>
                    <div className="general-outings-form grid grid-cols-3 gap-4 mt-5">
                        <div className="grid grid-cols-1 items-center gap-4">
                                <Label htmlFor={"date"} className="text-left">
                                    { t("seizure.form.form.date") }
                                </Label>
                                
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-[280px] justify-start text-left font-normal w-100",
                                            !values.date && "text-muted-foreground"
                                        )}
                                        >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {values.date ? format(values.date, "MM/dd/yyyy - hh:mm aa") : <span>mm/dd/yyyy - hh:mm aa</span>}
                                        </Button>
                                    </PopoverTrigger>

                                    <PopoverContent className="w-auto p-0">
                                        <CustomCalendar
                                            value={values.date}
                                            timePicker={true}
                                            onChange={handleDateChange}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>

                            <div className="grid grid-cols-1 items-center gap-4">
                                <Label htmlFor={"duration"} className="text-right justify-self-start">
                                    { t("seizure.form.form.duration") }
                                </Label>

                                <Input
                                    id={"duration"}
                                    name={"duration"}
                                    type="text"
                                    value={values.duration}
                                    style={{ outline: 'none' }}
                                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="grid grid-cols-1 items-center gap-4">
                                <Label htmlFor={"detail"} className="text-right justify-self-start">
                                    { t("seizure.form.form.detail") }
                                </Label>

                                <Input
                                    id={"detail"}
                                    name={"detail"}
                                    type="text"
                                    value={values.detail}
                                    style={{ outline: 'none' }}
                                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="w-100 mt-4 flex flex-row justify-end">
                            <Button type="submit" className="mr-2">
                                { t("seizure.form.form.submit") }
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}
