import { Button } from '@/common/presentation/components/ui/button';
import { Eye } from 'lucide-react';
import { usePrnDialog } from '@/modules/emar/infrastructure/hooks/UsePrnDialog';
import { useParams } from 'react-router-dom';

interface Props {
  data: any;
}

export const PrnTableActions = ({ data }: Props) => {
  const { onOpen } = usePrnDialog();
  const { locationId } = useParams<any>();

  console.log({ data });

  return (
    <Button
      size={'icon'}
      variant={'outline'}
      onClick={() =>
        onOpen(
          {
            time_given: data.dispensed_at,
            follow_up: data.follow_up,
            reason: data.reason,
            result: data.result,
            comments: data.comments,
            level_of_pain: data.level_of_pain,
            resident_id: data.resident_id,
            id: data.resident_medication_id,
            dispense_id: data.id
          },
          locationId!
        )
      }
    >
      <Eye className="size-4" />
    </Button>
  );
};
