import { DashboardProfile } from '@/modules/staff/presentation/pages/DashboardProfile';
import { StaffPage } from '@/modules/staff/presentation/pages/StaffPage';
import { UpdateStaffMemberPage } from '@/modules/staff/presentation/pages/UpdateStaffMemberPage';
import { Route, Routes } from 'react-router-dom';

const StaffRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<StaffPage />} />
      <Route path="/update" element={<UpdateStaffMemberPage />} />
      <Route path="/profile" element={<DashboardProfile />} />
    </Routes>
  );
};

export default StaffRouter;
