import { Route, Routes } from 'react-router-dom';

import AdministrationPage from '@/modules/tracker/presentation/pages/AdministrationPage';
import TrackerPage from '@/modules/tracker/presentation/pages/TrackerPage';
import CustomColumn from '@/modules/tracker/presentation/pages/CustomColumn';
import TrackerHygiene from '@/modules/tracker/presentation/pages/TrackerHygiene';
import TrackerHygieneType from '@/modules/tracker/presentation/pages/TrackerHygieneType';
import TrackerVitals from '@/modules/tracker/presentation/pages/TrackerVitals';
import TrackerVitalsType from '@/modules/tracker/presentation/pages/TrackerVitalsType';
import TrackerHygieneNight from '@/modules/tracker/presentation/pages/TrackerHygieneNight';
import TrackerHygieneNightType from '@/modules/tracker/presentation/pages/TrackerHygieneNightType';
import TrackerDirect from '@/modules/tracker/presentation/pages/TrackerDirect';
import TrackerDirectType from '@/modules/tracker/presentation/pages/TrackerDirectType';
import TrackerInconsistence from '@/modules/tracker/presentation/pages/TrackerInconsistence';
import TrackerOutingsPage from '@/modules/tracker/presentation/pages/TrackerOutingsPage';
import TrackerSkinCheck from '@/modules/tracker/presentation/pages/TrackerSkinCheck';
import { TrackerCleaning } from '@/modules/tracker/presentation/pages/TrackerCleaning';
import { ElopementPage } from '@/modules/tracker/presentation/pages/ElopementPage';
import { SeizurePage } from '@/modules/tracker/presentation/pages/SeizurePage';
import TrackerFood from '@/modules/tracker/presentation/pages/TrackerFood';
import TrackerFoodType from '@/modules/tracker/presentation/pages/TrackerFoodType';
import TrackerCashResource from '@/modules/tracker/presentation/pages/TrackerCashResource';
import { TrackerMenstruationPage } from '@/modules/tracker/presentation/pages/TrackerMenstruationPage';

const TrackerRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<TrackerPage />} />
      <Route path="/administration/:locationId?" element={<AdministrationPage />} />
      <Route path="/tableCustom" element={<CustomColumn />} />
      <Route path="/hygiene/:locationId?" element={<TrackerHygiene />} />
      <Route path="/hygieneType/:locationId?" element={<TrackerHygieneType />} />
      <Route path="/vitals/:locationId?" element={<TrackerVitals />} />
      <Route path="/vitalsType/:locationId?" element={<TrackerVitalsType />} />
      <Route path="/hygienenight/:locationId?" element={<TrackerHygieneNight />} />
      <Route path="/hygienenightType/:locationId?" element={<TrackerHygieneNightType />} />
      <Route path="/direct/:locationId?" element={<TrackerDirect />} />
      <Route path="/directType/:locationId?" element={<TrackerDirectType />} />
      <Route path="/incontinence/:locationId?" element={<TrackerInconsistence />} />
      <Route path="/skincheck/:locationId?" element={<TrackerSkinCheck />} />
      <Route path="/food/:locationId?" element={<TrackerFood />} />
      <Route path="/foodtype/:locationId?" element={<TrackerFoodType />} />
      <Route path="/cashresource/:locationId?" element={<TrackerCashResource />} />
      <Route path="/:locationId?" element={<AdministrationPage />} />
      <Route path="/outingsTracker" element={<TrackerOutingsPage />} />
      <Route path="/cleaning" element={<TrackerCleaning />} />
      <Route path="/elopement" element={<ElopementPage />} />
      <Route path="/seizure" element={<SeizurePage />} />
      <Route path="/menstruation" element={<TrackerMenstruationPage />} />
    </Routes>
  );
};

export default TrackerRouter;