import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerService from '../../infrastructure/services/TrackerService';

interface TrackerState {
  customizationTable: any[];
  reportBlob: Blob | null;  // Para almacenar el blob del reporte
  temperatureType: any[];
  temperature: any[];
  datatemperature: any[];
  status: 'idle' | 'loading' | 'failed';
  generalReport: any[];
  hospitalizationReport: any[];
  error: string | null;
}

const initialState: TrackerState = {
  customizationTable: [],
  reportBlob: null,
  temperatureType: [],
  temperature: [],
  datatemperature: [],
  generalReport: [],
  hospitalizationReport: [],
  status: 'idle',
  error: null
};

export const getCustomizations = createAsyncThunk('tracker/getCustomizations', async () => {
  const response = await TrackerService.getCustomizations();
  return response.data;
});

export const getTemperatureType = createAsyncThunk('tracker/getTemperatureType', async () => {
  const response = await TrackerService.getTemperatureType();
  return response.data;
});

export const getTemperature = createAsyncThunk('tracker/getTemperature', async () => {
  const response = await TrackerService.getTemperature();
  return response.data;
});

export const saveTemperature = createAsyncThunk(
  'tracker/saveTemperature',
  async ({ formData, locationId }: { formData: any; locationId?: string }, thunkAPI) => {
    try {
      const response = await TrackerService.saveTemperature(formData, locationId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); // Manejo de errores
    }
  }
);

export const getTrackerHygieneType = createAsyncThunk('trackerHygiene/getTrackerHygieneType', async (formData) => {
  const response = await TrackerService.getTrackerHygieneType(formData);
  return response.data;
});

export const getTrackerHygiene = createAsyncThunk('trackerHygiene/getTrackerHygiene', async (formData) => {
  const response = await TrackerService.getTrackerHygiene(formData);
  return response.data;
});

export const updateTrackerHygiene = createAsyncThunk('trackerHygiene/updateTrackerHygiene', async (formData) => {
  const response = await TrackerService.updateTrackerHygiene(formData);
  return response.data;
});

export const destroyTrackerHygiene = createAsyncThunk('trackerHygiene/destroyTrackerHygiene', async (formData) => {
  const response = await TrackerService.destroyTrackerHygiene(formData);
  return response.data;
});

export const updateTrackerHygieneType = createAsyncThunk('trackerHygiene/updateTrackerHygieneType', async (formData) => {
  const response = await TrackerService.updateTrackerHygieneType(formData);
  return response.data;
});

export const saveTrackerHygieneType = createAsyncThunk('trackerHygiene/saveTrackerHygieneType', async (formData) => {
  const response = await TrackerService.saveTrackerHygieneType(formData);
  return response.data;
});

export const saveTrackerHygiene = createAsyncThunk('trackerHygiene/saveTrackerHygiene', async (formData) => {
  const response = await TrackerService.saveTrackerHygiene(formData);
  return response.data;
});

///Vitals TYPE
export const getTrackerVitalsType = createAsyncThunk('trackerVitals/getTrackerVitalsType', async (formData) => {
  const response = await TrackerService.getTrackerVitalsType(formData);
  return response.data;
});

export const updateTrackerVitalsType = createAsyncThunk('trackerVitals/updateTrackerVitalsType', async (formData) => {
  const response = await TrackerService.updateTrackerVitalsType(formData);
  return response.data;
});

export const saveTrackerVitalsType = createAsyncThunk('trackerVitals/saveTrackerVitalsType', async (formData) => {
  const response = await TrackerService.saveTrackerVitalsType(formData);
  return response.data;
});

///Vitals

export const getTrackerVitals = createAsyncThunk('trackerVitals/getTrackerVitals', async (formData) => {
  const response = await TrackerService.getTrackerVitals(formData);
  return response.data;
});

export const updateTrackerVitals = createAsyncThunk('trackerVitals/updateTrackerVitals', async (formData) => {
  const response = await TrackerService.updateTrackerVitals(formData);
  return response.data;
});

export const saveTrackerVitals = createAsyncThunk('trackerVitals/saveTrackerVitals', async (formData) => {
  const response = await TrackerService.saveTrackerVitals(formData);
  return response.data;
});

export const destroyTrackerVitals = createAsyncThunk('trackerVitals/destroyTrackerVitals', async (formData) => {
  const response = await TrackerService.destroyTrackerVitals(formData);
  return response.data;
});

///24/7
export const getTrackerHygieneNight = createAsyncThunk('trackerHygieneNigth/getTrackerHygieneNight', async (formData) => {
  const response = await TrackerService.getTrackerHygieneNight(formData);
  return response.data;
});

export const saveTrackerHygieneNight = createAsyncThunk('trackerHygieneNigth/saveTrackerHygieneNight', async (formData) => {
  const response = await TrackerService.saveTrackerHygieneNight(formData);
  return response.data;
});

export const updateTrackerHygieneNight = createAsyncThunk('trackerHygieneNigth/updateTrackerHygieneNight', async (formData) => {
  const response = await TrackerService.updateTrackerHygieneNight(formData);
  return response.data;
});

export const destroyTrackerHygieneNight = createAsyncThunk('trackerHygieneNigth/destroyTrackerHygieneNight', async (formData) => {
  const response = await TrackerService.destroyTrackerHygieneNight(formData);
  return response.data;
});

///24/7 Type
export const getTrackerHygieneNightType = createAsyncThunk('trackerHygieneNigth/getTrackerHygieneNightType', async (formData) => {
  const response = await TrackerService.getTrackerHygieneNightType(formData);
  return response.data;
});

export const saveTrackerHygieneNightType = createAsyncThunk('trackerHygieneNigth/saveTrackerHygieneNightType', async (formData) => {
  const response = await TrackerService.saveTrackerHygieneNightType(formData);
  return response.data;
});

export const updateTrackerHygieneNightType = createAsyncThunk('trackerHygieneNigth/updateTrackerHygieneNightType', async (formData) => {
  const response = await TrackerService.updateTrackerHygieneNightType(formData);
  return response.data;
});


export const saveTrackerHygieneNightTypeSelect = createAsyncThunk('trackerHygieneNigth/saveTrackerHygieneNightTypeSelect', async (formData) => {
  const response = await TrackerService.saveTrackerHygieneNightTypeSelect(formData);
  return response.data;
});
export const updateTrackerHygieneNightTypeSelect = createAsyncThunk('trackerHygieneNigth/updateTrackerHygieneNightTypeSelect', async (formData) => {
  const response = await TrackerService.updateTrackerHygieneNightTypeSelect(formData);
  return response.data;
});

export const saveTrackerHygieneTypeSelect = createAsyncThunk('trackerHygiene/saveTrackerHygieneTypeSelect', async (formData) => {
  const response = await TrackerService.saveTrackerHygieneTypeSelect(formData);
  return response.data;
});
export const updateTrackerHygieneTypeSelect = createAsyncThunk('trackerHygiene/updateTrackerHygieneTypeSelect', async (formData) => {
  const response = await TrackerService.updateTrackerHygieneTypeSelect(formData);
  return response.data;
});


///Vitals TYPE
export const getTrackerDirectType = createAsyncThunk('trackerVitals/getTrackerDirectType', async (formData) => {
  const response = await TrackerService.getTrackerDirectType(formData);
  return response.data;
});

export const updateTrackerDirectType = createAsyncThunk('trackerVitals/updateTrackerDirectType', async (formData) => {
  const response = await TrackerService.updateTrackerDirectType(formData);
  return response.data;
});

export const saveTrackerDirectType = createAsyncThunk('trackerVitals/saveTrackerDirectType', async (formData) => {
  const response = await TrackerService.saveTrackerDirectType(formData);
  return response.data;
});

///Vitals

export const getTrackerDirect = createAsyncThunk('trackerVitals/getTrackerDirect', async (formData) => {
  const response = await TrackerService.getTrackerDirect(formData);
  return response.data;
});

export const updateTrackerDirect = createAsyncThunk('trackerVitals/updateTrackerDirect', async (formData) => {
  const response = await TrackerService.updateTrackerDirect(formData);
  return response.data;
});

export const saveTrackerDirect = createAsyncThunk('trackerVitals/saveTrackerDirect', async (formData) => {
  const response = await TrackerService.saveTrackerDirect(formData);
  return response.data;
});

export const destroyTrackerDirect = createAsyncThunk('trackerVitals/destroyTrackerDirect', async (formData) => {
  const response = await TrackerService.destroyTrackerDirect(formData);
  return response.data;
});

//Bowel
export const getTrackerBowelType = createAsyncThunk('trackerIncontinence/getTrackerBowelType', async (formData) => {
  const response = await TrackerService.getTrackerBowelType(formData);
  return response.data;
});
export const saveTrackerBowelType = createAsyncThunk('trackerIncontinence/saveTrackerBowelType', async (formData) => {
  const response = await TrackerService.saveTrackerBowelType(formData);
  return response.data;
});
export const getTrackerBowel = createAsyncThunk('trackerIncontinence/getTrackerBowel', async (formData) => {
  const response = await TrackerService.getTrackerBowel(formData);
  return response.data;
});
export const saveTrackerBowel = createAsyncThunk('trackerIncontinence/saveTrackerBowel', async (formData) => {
  const response = await TrackerService.saveTrackerBowel(formData);
  return response.data;
});
export const updateTrackerBowel = createAsyncThunk('trackerIncontinence/updateTrackerBowel', async (formData) => {
  const response = await TrackerService.updateTrackerBowel(formData);
  return response.data;
});
export const destroyTrackerBowel = createAsyncThunk('trackerIncontinence/destroyTrackerBowel', async (formData) => {
  const response = await TrackerService.destroyTrackerBowel(formData);
  return response.data;
});

//Urine
export const getTrackerUrineType = createAsyncThunk('trackerIncontinence/getTrackerUrineType', async (formData) => {
  const response = await TrackerService.getTrackerUrineType(formData);
  return response.data;
});
export const saveTrackerUrineType = createAsyncThunk('trackerIncontinence/saveTrackerUrineType', async (formData) => {
  const response = await TrackerService.saveTrackerUrineType(formData);
  return response.data;
});
export const getTrackerUrine = createAsyncThunk('trackerIncontinence/getTrackerUrine', async (formData) => {
  const response = await TrackerService.getTrackerUrine(formData);
  return response.data;
});
export const saveTrackerUrine = createAsyncThunk('trackerIncontinence/saveTrackerUrine', async (formData) => {
  const response = await TrackerService.saveTrackerUrine(formData);
  return response.data;
});
export const updateTrackerUrine = createAsyncThunk('trackerIncontinence/updateTrackerUrine', async (formData) => {
  const response = await TrackerService.updateTrackerUrine(formData);
  return response.data;
});
export const destroyTrackerUrine = createAsyncThunk('trackerIncontinence/destroyTrackerUrine', async (formData) => {
  const response = await TrackerService.destroyTrackerUrine(formData);
  return response.data;
});

//brief
export const getTrackerBriefType = createAsyncThunk('trackerIncontinence/getTrackerBriefType', async (formData) => {
  const response = await TrackerService.getTrackerBriefType(formData);
  return response.data;
});
export const saveTrackerBriefType = createAsyncThunk('trackerIncontinence/saveTrackerBriefType', async (formData) => {
  const response = await TrackerService.saveTrackerBriefType(formData);
  return response.data;
});
export const getTrackerBrief = createAsyncThunk('trackerIncontinence/getTrackerBrief', async (formData) => {
  const response = await TrackerService.getTrackerBrief(formData);
  return response.data;
});
export const saveTrackerBrief = createAsyncThunk('trackerIncontinence/saveTrackerBrief', async (formData) => {
  const response = await TrackerService.saveTrackerBrief(formData);
  return response.data;
});
export const updateTrackerBrief = createAsyncThunk('trackerIncontinence/updateTrackerBrief', async (formData) => {
  const response = await TrackerService.updateTrackerBrief(formData);
  return response.data;
});
export const destroyTrackerBrief = createAsyncThunk('trackerIncontinence/destroyTrackerBrief', async (formData) => {
  const response = await TrackerService.destroyTrackerBrief(formData);
  return response.data;
});

// General Outings
export const getTrackerOutingsGeneral = createAsyncThunk("trackerOutings/getTrackerOutingsGeneralReport", async (body: any) => {
  const response = await TrackerService.getTrackerOutingsGeneral(body);
  return response.data;
});

export const saveTrackerOutingsGeneral = createAsyncThunk("trackerOutings/saveTrackerOutingsGeneralReport", async (formData) => {
  const response = await TrackerService.saveTrackerOutingsGeneral(formData);
  return response.data;
});

export const updateTrackerOutingsGeneral = createAsyncThunk("trackerOutings/updateTrackerOutingsGeneralReport", async (formData) => {
  const response = await TrackerService.updateTrackerOutingsGeneral(formData);
  return response.data;
});

export const destroyTrackerOutingsGeneral = createAsyncThunk("trackerOutings/destroyTrackerOutingsGeneralReport", async (formData) => {
  const response = await TrackerService.destroyTrackerOutingsGeneral(formData);
  return response.data;
});

// Hospitalization Outings
export const getTrackerOutingsHospitalization = createAsyncThunk("trackerOutings/getTrackerOutingsHospitalizationReport", async (body: any) => {
  const response = await TrackerService.getTrackerOutingsHospitalization(body);
  return response.data;
});

export const saveTrackerOutingsHospitalization = createAsyncThunk("trackerOutings/saveTrackerOutingsHospitalizationReport", async (formData) => {
  const response = await TrackerService.saveTrackerOutingsHospitalization(formData);
  return response.data;
});

export const updateTrackerOutingsHospitalization = createAsyncThunk("trackerOutings/updateTrackerOutingsHospitalizationReport", async (formData) => {
  const response = await TrackerService.updateTrackerOutingsHospitalization(formData);
  return response.data;
});

export const destroyTrackerOutingsHospitalization = createAsyncThunk("trackerOutings/destroyTrackerOutingsHospitalizationReport", async (formData) => {
  const response = await TrackerService.destroyTrackerOutingsHospitalization(formData);
  return response.data;
});

//Skin Check
export const getTrackerSkinCheck = createAsyncThunk('trackerSkinCheck/getTrackerSkinCheck', async (formData) => {
  const response = await TrackerService.getTrackerSkinCheck(formData);
  return response.data;
});
export const saveTrackerSkinCheck = createAsyncThunk('trackerSkinCheck/saveTrackerSkinCheck', async (formData) => {
  const response = await TrackerService.saveTrackerSkinCheck(formData);
  return response.data;
});
export const updateTrackerSkinCheck = createAsyncThunk('trackerSkinCheck/updateTrackerSkinCheck', async (formData) => {
  const response = await TrackerService.updateTrackerSkinCheck(formData);
  return response.data;
});
export const destroyTrackerSkinCheck = createAsyncThunk('trackerSkinCheck/destroyTrackerSkinCheck', async (formData) => {
  const response = await TrackerService.destroyTrackerSkinCheck(formData);
  return response.data;
});
export const getTrackerFood = createAsyncThunk('trackerFood/getTrackerFood', async (formData) => {
  const response = await TrackerService.getTrackerFood(formData);
  return response.data;
});
export const saveTrackerFood = createAsyncThunk('trackerFood/saveTrackerFood', async (formData) => {
  const response = await TrackerService.saveTrackerFood(formData);
  return response.data;
});
export const getTrackerFoodType = createAsyncThunk('trackerFood/getTrackerFoodType', async (formData) => {
  const response = await TrackerService.getTrackerFoodType(formData);
  return response.data;
});
export const saveTrackerFoodType = createAsyncThunk('trackerFood/saveTrackerFoodType', async (formData) => {
  const response = await TrackerService.saveTrackerFoodType(formData);
  return response.data;
});

export const updateTrackerFoodType = createAsyncThunk('trackerFood/updateTrackerFoodType', async (formData) => {
  const response = await TrackerService.updateTrackerFoodType(formData);
  return response.data;
});

export const updateTrackerFood = createAsyncThunk('trackerFood/updateTrackerFood', async (formData) => {
  const response = await TrackerService.updateTrackerFood(formData);
  return response.data;
});

export const exportReport = createAsyncThunk('trackerFood/exportReport', async (formData) => {
  const response = await TrackerService.exportReport(formData);
  return response.data;
});

//CASH resource 
export const getTrackerCashResource = createAsyncThunk('trackerSkinCheck/getTrackerCashResource', async (formData) => {
  const response = await TrackerService.getTrackerCashResource(formData);
  return response.data;
});

export const saveTrackerCashResource = createAsyncThunk('trackerSkinCheck/saveTrackerCashResource', async (formData) => {
  const response = await TrackerService.saveTrackerCashResource(formData);
  return response.data;
});

export const updateTrackerCashResource = createAsyncThunk('trackerSkinCheck/updateTrackerCashResource', async (formData) => {
  const response = await TrackerService.updateTrackerCashResource(formData);
  return response.data;
});

export const destroyTrackerCashResource = createAsyncThunk('trackerSkinCheck/destroyTrackerCashResource', async (formData) => {
  const response = await TrackerService.destroyTrackerCashResource(formData);
  return response.data;
});

export const balanceResident = createAsyncThunk('trackerSkinCheck/balanceResident', async (formData) => {
  const response = await TrackerService.balanceResident(formData);
  return response.data;
});

export const exportReportDirect = createAsyncThunk( 'trackerDirect/exportReport', async ({ formData, token }: { formData: any; token: string }) => {
    const response = await TrackerService.exportReportDirect(formData, token);
    return response.data;
  }
);

const trackerSlice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getTrackerOutingsGeneral.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getTrackerOutingsGeneral.fulfilled, (state, action) => {
      state.status = 'idle';
      state.generalReport = action.payload;
    });

    builder.addCase(getTrackerOutingsGeneral.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getTrackerOutingsHospitalization.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getTrackerOutingsHospitalization.fulfilled, (state, action) => {
      state.status = 'idle';
      state.hospitalizationReport = action.payload;
    });

    builder.addCase(getTrackerOutingsHospitalization.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getCustomizations.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    builder.addCase(getCustomizations.fulfilled, (state, action) => {
      state.status = 'idle';
      state.customizationTable = action.payload;
    });
    builder.addCase(getCustomizations.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getTemperatureType.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    builder.addCase(getTemperatureType.fulfilled, (state, action) => {
      state.status = 'idle';
      state.temperatureType = action.payload;
    });
    builder.addCase(getTemperatureType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    builder.addCase(saveTemperature.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTemperature.fulfilled, (state, action) => {
      state.status = 'idle';
      state.datatemperature = action.payload;
    });
    builder.addCase(getTemperature.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    builder.addCase(getTrackerHygieneType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerHygieneType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    builder.addCase(getTrackerHygiene.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerHygiene.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    builder.addCase(updateTrackerHygiene.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerHygiene.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    builder.addCase(destroyTrackerHygiene.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(destroyTrackerHygiene.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    builder.addCase(updateTrackerHygieneType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerHygieneType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(saveTrackerHygieneType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerHygieneType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(saveTrackerHygiene.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerHygiene.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    //TRACKER TYPE
    builder.addCase(getTrackerVitalsType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerVitalsType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerVitalsType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerVitalsType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerVitalsType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerVitalsType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    ///Vitals
    builder.addCase(getTrackerVitals.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerVitals.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerVitals.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerVitals.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerVitals.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerVitals.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Destroy
    builder.addCase(destroyTrackerVitals.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(destroyTrackerVitals.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    ///24/7
    builder.addCase(getTrackerHygieneNight.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerHygieneNight.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerHygieneNight.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerHygieneNight.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerHygieneNight.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerHygieneNight.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Destroy
    builder.addCase(destroyTrackerHygieneNight.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(destroyTrackerHygieneNight.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    ///24/7 TYPE
    builder.addCase(getTrackerHygieneNightType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerHygieneNightType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerHygieneNightType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerHygieneNightType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerHygieneNightType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerHygieneNightType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    //Save
    builder.addCase(saveTrackerHygieneNightTypeSelect.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerHygieneNightTypeSelect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerHygieneNightTypeSelect.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerHygieneNightTypeSelect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerHygieneTypeSelect.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerHygieneTypeSelect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerHygieneTypeSelect.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerHygieneTypeSelect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    ///Direct
    builder.addCase(getTrackerDirect.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerDirect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    ///FOOD
    builder.addCase(getTrackerFood.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerFood.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerDirect.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerDirect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerDirect.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerDirect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Destroy
    builder.addCase(destroyTrackerDirect.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(destroyTrackerDirect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    //TYPE
    builder.addCase(getTrackerDirectType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerDirectType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //SAVE
    builder.addCase(saveTrackerDirectType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerDirectType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //UPDAE
    builder.addCase(updateTrackerDirectType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerDirectType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    ///Bowel
    builder.addCase(getTrackerBowel.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });

    builder.addCase(saveTrackerFood.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    //Update
    builder.addCase(updateTrackerFood.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerFood.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    builder.addCase(saveTrackerFood.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //FOOD TYPE
    builder.addCase(getTrackerFoodType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerFoodType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerBowel.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerBowel.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerBowel.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerBowel.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Destroy
    builder.addCase(destroyTrackerBowel.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(destroyTrackerBowel.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    //TYPE
    builder.addCase(getTrackerBowelType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerBowelType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //SAVE
    builder.addCase(saveTrackerBowelType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerBowelType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    ///Urine
    builder.addCase(getTrackerUrine.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerUrine.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerUrine.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerUrine.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerUrine.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerUrine.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Destroy
    builder.addCase(destroyTrackerUrine.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(destroyTrackerUrine.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    //TYPE
    builder.addCase(getTrackerUrineType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerUrineType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //SAVE
    builder.addCase(saveTrackerUrineType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerUrineType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });


    ///Brief
    builder.addCase(getTrackerBrief.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerBrief.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerBrief.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerBrief.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerBrief.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerBrief.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Destroy
    builder.addCase(destroyTrackerBrief.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(destroyTrackerBrief.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    //TYPE
    builder.addCase(getTrackerBriefType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerBriefType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //SAVE
    builder.addCase(saveTrackerBriefType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerBriefType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    ///Skin check
    builder.addCase(getTrackerSkinCheck.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerSkinCheck.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Save
    builder.addCase(saveTrackerSkinCheck.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerSkinCheck.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Update
    builder.addCase(updateTrackerSkinCheck.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerSkinCheck.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Destroy
    builder.addCase(destroyTrackerSkinCheck.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerFoodType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerFoodType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    //Update
    builder.addCase(updateTrackerFoodType.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerFoodType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Report
    builder.addCase(exportReport.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(exportReport.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    ///Cash Resource
    builder.addCase(getTrackerCashResource.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(getTrackerCashResource.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //save
    builder.addCase(saveTrackerCashResource.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(saveTrackerCashResource.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //update
    builder.addCase(updateTrackerCashResource.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(updateTrackerCashResource.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //destroy
    builder.addCase(destroyTrackerCashResource.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(destroyTrackerCashResource.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    //balance
    builder.addCase(balanceResident.fulfilled, (state, action) => {
      state.status = "idle";
      state.temperature = action.payload;
    });
    builder.addCase(balanceResident.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
    //Direct
    builder.addCase(exportReportDirect.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    builder.addCase(exportReportDirect.fulfilled, (state, action) => {
      state.status = 'idle';
      state.reportBlob = action.payload;  // Almacena el blob
    });
    builder.addCase(exportReportDirect.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});
export default trackerSlice.reducer;
