import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';

export const SwitchStatus = () => {
  return (
    <>
      <div className="grid grid-cols-2 gap-2 p-4">
        <div className="flex items-center space-x-2">
          <Switch id="active_status" />
          <Label htmlFor="airplane-mode">Active Status</Label>
        </div>
        <div className="flex items-center space-x-2">
          <Switch id="alert_medicine" />
          <Label htmlFor="airplane-mode">Enable Medicine Alert</Label>
        </div>
        <div className="flex items-center space-x-2 col-span-2">
          <Switch id="enable_bowel" />
          <Label htmlFor="airplane-mode">Enable Bowel Movement Tracker</Label>
        </div>
      </div>
    </>
  );
};
