import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { ResidentSelector } from "@/common/presentation/components/Selectors/ResidentSelector";
import { Button } from "@/common/presentation/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { RootState } from "@/store/store";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ITrackerMenstrutationForm } from "./TrackerMenstruationMockup";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { createMenstruation } from "../../slices/TrackerMenstruationSlice";
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";

export function TrackerMenstruationForm() {
    const dispatch = useDispatch();
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const [location, setLocation] = useState<string | null>(null);
    const { params, setParam } = useRouteParams();
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { locationSelectedId } = useLocation();
    const { t } = useTranslation();
    const { toast } = useToast();
    const [values, setValues] = useState<ITrackerMenstrutationForm>({
        startDate: null,
        endDate: null,
        comments: ""
    });

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        params.l === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === params.l);
                }
            };
            fetchData();
        }

        if (!params.l && locations.length === 1) {
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setLocation(params.l);

        if (params.l && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleStartDateChange = (value: any) => {
       setValues({
            ...values,
            startDate: value
        });
    };

    const handleEndDateChange = (value: any) => {
        setValues({
             ...values,
             endDate: value
         });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if(params.r) {
            if(values.startDate && values.endDate && values.comments) {
                dispatch(createMenstruation(
                    {
                        location_id: locationSelectedId,
                        data: {
                            ...values,
                            startDate: values.startDate.toISOString(),
                            endDate: values.endDate.toISOString(),
                            comments: values.comments,
                            resident: params.r
                        }
                    }
                ));

                toast(
                    {
                        description: t("mentruation.form.toast.success") ,
                        className: 'bg-green-500 text-white'
                    }
                );

                cleanInputs();
            } else {
                toast(
                    {
                        description: t("mentruation.form.toast.required") 
                    }
                );
            }
        } else {
            toast(
                {
                    description: t("mentruation.form.toast.residentRequired") 
                }
            );
        }
    }

    const cleanInputs = () => {
        setValues({
            startDate: new Date(),
            endDate: new Date(),
            comments: ""
        });
    };

    return (
        <div>
            {
                <div className="flex flex-col gap-4">
                    <div>
                        <ResidentSelector
                            onSelect={onResidentSelect}
                            locationId={location}
                            residentId={params.r}
                            showAllResidents={false}
                            filterByGender="female"
                            showKeepInEye={true}
                        />
                    </div>
                </div>
            }

            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("mentruation.form.form.title")}
                    </CardTitle>
                </CardHeader>

                <CardContent>
                <form onSubmit={handleSubmit}>
                    <div className="general-outings-form grid grid-cols-2 gap-4 mt-5">
                        <div className="grid grid-cols-1 items-center gap-4">
                                <Label htmlFor={"startDate"} className="text-left">
                                    { t("mentruation.form.form.startDate") }
                                </Label>
                                
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-[280px] justify-start text-left font-normal w-100",
                                            !values.startDate && "text-muted-foreground"
                                        )}
                                        >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {values.startDate ? format(values.startDate, "MM/dd/yyyy") : <span>mm/dd/yyyy</span>}
                                        </Button>
                                    </PopoverTrigger>

                                    <PopoverContent className="w-auto p-0">
                                        <CustomCalendar
                                            value={values.startDate}
                                            timePicker={false}
                                            onChange={handleStartDateChange}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>

                            <div className="grid grid-cols-1 items-center gap-4">
                                <Label htmlFor={"endDate"} className="text-left">
                                    { t("mentruation.form.form.endDate") }
                                </Label>
                                
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-[280px] justify-start text-left font-normal w-100",
                                            !values.endDate && "text-muted-foreground"
                                        )}
                                        >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {values.endDate ? format(values.endDate, "MM/dd/yyyy") : <span>mm/dd/yyyy</span>}
                                        </Button>
                                    </PopoverTrigger>

                                    <PopoverContent className="w-auto p-0">
                                        <CustomCalendar
                                            value={values.endDate}
                                            timePicker={false}
                                            onChange={handleEndDateChange}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>

                            <div className="grid grid-cols-1 items-center gap-4" style={{ gridColumn: "1 / span 2" }}>
                                <Label htmlFor={"comments"} className="text-right justify-self-start">
                                    { t("mentruation.form.form.comments") }
                                </Label>

                                <Input
                                    id={"comments"}
                                    name={"comments"}
                                    type="text"
                                    value={values.comments}
                                    style={{ outline: 'none' }}
                                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                                    onChange={handleInputChange}
                                />
                            </div>
                    </div>

                        <div className="w-100 mt-4 flex flex-row justify-end">
                            <Button type="submit" className="mr-2">
                                { t("mentruation.form.form.submit") }
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}
