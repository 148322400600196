import { tagalog } from "@/locales/extra-locales";
import { useTranslation } from 'react-i18next';
import { useMemo } from "react";
import { es, enUS } from "date-fns/locale";

export const localization = {
    formatPrice: (price: number, currency: string) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency
          });
          return formatter.format(parseFloat(price.toString()));
    }
};

export const useLocale = () => {
    const { t, i18n } = useTranslation();

    const getLocale = () => {
        switch (i18n.language) {
          case 'es':
            return es;
          case 'en':
            return enUS;
          case 'tl':
            return tagalog;
          default:
            return undefined;
        }
      };
      
      const locale = getLocale();

      return {locale};
};