import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { useEffect, useState } from 'react';
import { getTemperature, getTemperatureType } from '../../slices/TrackerSlice';
import SpeedDial from '@/common/presentation/components/CustomSpeedDial/SpeedDial';
import { BookCopy, Ellipsis, Pencil, Trash2 } from 'lucide-react';
import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { cn } from '@/lib/utils';
import { Input } from '@/common/presentation/components/ui/input';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Label } from '@/common/presentation/components/ui/label';  

function ReportTemperature() {
  const dispatch = useDispatch<AppDispatch>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [temperatureValue, setTemperatureValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [hourValue, setHourValue] = useState(''); // Añadido para manejar el valor de 'hour'

  const { datatemperature, temperatureType, status } = useSelector((state: RootState) => state.tracker);

  const renderActionsColumn = (rowData: any) => {
    return (
      <div className="flex justify-center items-center">
        <SpeedDial
          mainIcon={<Ellipsis size={16} />}
          buttons={[
            {
              icon: <Pencil size={16} />,
              onClick: () => handleEditClick(rowData)
            },
            {
              icon: <Trash2 size={16} />
              // onClick: () => handleDelete(rowData.id)
            },
            {
              icon: <BookCopy size={16} />
              // onClick: () => handleDelete(rowData.id)
            }
          ]}
        />
      </div>
    );
  };

  const [columns, setColumns] = useState<any[]>([
    {
      key: 'hour',
      label: 'Hour',
      type: 'select' // Cambiado a 'select'
    },
    {
      key: 'column_1',
      label: 'Nevera1',
      type: 'text'
    },
    {
      key: 'column_2',
      label: 'Nevera2',
      type: 'number'
    },
    {
      key: 'column_3',
      label: 'Congelador1',
      type: 'text'
    },
    {
      key: 'column_4',
      label: 'Congelador2',
      type: 'number'
    },
    {
      key: 'actions',
      label: 'Actions',
      render: renderActionsColumn
    }
  ]);

  const isEditable = false;

  useEffect(() => {
    dispatch(getTemperatureType());
    dispatch(getTemperature());
  }, [dispatch]);

  const handleEditClick = (rowData) => {
    setTemperatureValue(rowData.column_1);
    setDescriptionValue(rowData.column_2);
    setHourValue(rowData.hour);
  
    setShowEditDialog(true);
    setSelectedRow(rowData);
  };

  const handleSaveEdit = () => {
    if (selectedRow) {
      const editedRow = { ...selectedRow }; // Copia la fila seleccionada para evitar mutar el estado directamente
      editedRow.column_1 = temperatureValue;
      editedRow.column_2 = descriptionValue;
      editedRow.hour = hourValue;
  
      setSelectedRow(editedRow);
      setShowEditDialog(false);
    }
  };
  

  const handleCellChange = () => {};

  const renderCellContent = (index, column, data, temperatureType) => {
    if (column.key === 'hour') {
      const selectedTypeTemperature = temperatureType.find((type) => type.id === data[index][column.key]);
      return (
        <td key={column.dataKey} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
          {selectedTypeTemperature ? selectedTypeTemperature.name : ''}
        </td>
      );
    } else if (column.key === 'actions') {
      return (
        <td key={column.dataKey} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
          {renderActionsColumn(data[index])}
        </td>
      );
    } else {
      return (
        <td key={column.dataKey} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
          {isEditable ? (
            <Input
              type={column.type === 'number' ? 'number' : 'text'}
              onChange={(e) => handleCellChange(index, column.key, e.target.value)}
            />
          ) : column.render ? (
            column.render(data[index])
          ) : (
            data[index][column.key]
          )}
        </td>
      );
    }
  };

  const handleCloseEditDialog = () => {
    setShowEditDialog(false);
    setSelectedRow(null);
  };

  const transformData = (data, columns) => {
    return data.flatMap((item) => {
      const parsedData = JSON.parse(item.data);

      return parsedData
        .map((dataItem) => {
          const transformedItem = {};

          columns.forEach((column) => {
            const value = dataItem[column.key];
            if (value) {
              transformedItem[column.key] = value;
            }
          });

          // Retornar el objeto transformado si tiene alguna propiedad no vacía
          return Object.keys(transformedItem).length > 0 ? transformedItem : null;
        })
        .filter((item) => item !== null); // Filtrar los objetos nulos (vacíos)
    });
  };

  const getInitialValue = (columnKey) => {
    if (selectedRow) {
      const columnValue = selectedRow[columnKey];

      const column = columns.find((col) => col.key === columnKey);
      if (column && column.type === 'select') {
        return columnValue; // Return the ID directly
      }

      return columnValue;
    }
    return '';
  };

  const handleColumnChange = (columnKey, value) => {
    switch (columnKey) {
      case 'hour':
        setHourValue(value);
        break;
      case 'column_1':
        setTemperatureValue(value);
        break;
      case 'column_2':
        setDescriptionValue(value);
        break;
      default:
        break;
    }
  };

  const editColumns = columns
  .filter((column) => column.type === 'text' || column.type === 'number' || column.type === 'select')
  .map((column) => ({
    key: column.key,
    label: column.label,
    type: column.type,
    value: getInitialValue(column.key), // Asegúrate de que getInitialValue devuelva valores adecuados
    onChange: (value) => handleColumnChange(column.key, value),
    options:
      column.type === 'select' ? temperatureType.map((type) => ({ value: type.id, label: type.name })) : undefined
  }));

const renderInputForColumn = (column, index) => {
  switch (column.type) {
    case 'text':
      return (
        <Input
          type="text"
          value={column.value} // Asegúrate de que el valor se actualice correctamente
          onChange={(e) => column.onChange(e.target.value)}
          className="border rounded-md p-2"
        />
      );
    case 'number':
      return (
        <Input
          type="number"
          value={column.value} // Asegúrate de que el valor se actualice correctamente
          onChange={(e) => column.onChange(e.target.value)}
          className="border rounded-md p-2"
        />
      );
    case 'date':
      return <CustomCalendar />;
    case 'select':
      return (
        <Select value={column.value} onValueChange={(value) => column.onChange(value)}>
          <SelectTrigger>
            <SelectValue placeholder={column.label}>
              {temperatureType.find((option) => option.id === column.value)?.name}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {column.options.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    default:
      return null;
  }
};

  return (
    <div>
      <TableCustomVirtoso
        data={transformData(datatemperature, columns)}
        columns={columns}
        renderCellContent={(index, column, data) => renderCellContent(index, column, data, temperatureType)}
      />
      <CustomDialog
        isOpen={showEditDialog}
        onClose={handleCloseEditDialog}
        title="Edit Temperature"
        onSubmit={handleSaveEdit}
      >
        {editColumns.map((column, index) => (
          <div key={column.key} className="mb-4">
            <Label htmlFor={column.key} className="block text-gray-700 font-bold mb-2">
              {column.label}
            </Label>
            {renderInputForColumn(column, index)}
          </div>
        ))}
      </CustomDialog>
    </div>
  );
}

export default ReportTemperature;
