import { RootState } from '@/store/store';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const hexToHSL = (hex: string): string => {
  // Extraer los valores RGB del color hexadecimal
  const r = parseInt(hex.substring(1, 3), 16) / 255;
  const g = parseInt(hex.substring(3, 5), 16) / 255;
  const b = parseInt(hex.substring(5, 7), 16) / 255;

  // Encontrar el valor mínimo y máximo de los componentes RGB
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calcular la luminosidad
  let l = (max + min) / 2;

  let h, s;

  if (max === min) {
    // Escala de grises
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h! /= 6;
  }

  // Convertir a grados y porcentaje
  h = Math.round(h! * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return `${h} ${s}% ${l}%`;
};

interface ConfigurationsProviderProps {
  children: ReactNode;
}

type ConfigurationsProviderState = {
  configurations: any;
  menus: any[];
  clientInfo: any;
  accentColor: string;
};

const initialState: ConfigurationsProviderState = {
  configurations: {},
  menus: [],
  clientInfo: {},
  accentColor: ''
};

const configurationsContext = createContext<ConfigurationsProviderState>(initialState);

export function ConfigurationsProvider({ children, ...props }: ConfigurationsProviderProps) {
  const configurations = useSelector((state: RootState) => state.configurations.configurations);
  const [menus, setMenus] = useState<any[]>([]);
  const [clientInfo, setClientInfo] = useState<any>({});
  const [accentColor, setAccentColor] = useState<string>('#2563eb');

  useEffect(() => {
    if (configurations) {
      setMenus(configurations.menus);
      setClientInfo({
        logo: configurations.logo,
        name: configurations.name,
        status: configurations.client_status,
        email: configurations.email,
        phone: configurations.phone,
        country: configurations.client_country,
        id: configurations.client_id
      });

      setAccentColor(configurations.accent_color);
    }
  }, [configurations]);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary', hexToHSL(accentColor));
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', accentColor);
    }
  }, [accentColor]);

  return (
    <configurationsContext.Provider value={{ configurations, menus, clientInfo, accentColor }} {...props}>
      {children}
    </configurationsContext.Provider>
  );
}

export const useConfigurationsProvider = () => {
  const context = useContext(configurationsContext);

  if (!context) {
   wi('useResidentForm must be used within a ResidentProvider');
  }

  return context;
};
