import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { XCircleIcon, TimerIcon } from 'lucide-react';
import { TimePicker } from '@/common/presentation/components/CustomCalendar/TimePicker';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Button } from '@/common/presentation/components/ui/button';
import { Badge } from '@/common/presentation/components/ui/badge';
import { useTranslation } from 'react-i18next';

interface TimeSelectorProps {
  onChange: (selectedTimes: string[]) => void;
  medicationTimes: string[];
  selectedTimes: string[];
  typeName?: string;
}

const TimeSelector: React.FC<TimeSelectorProps> = ({ onChange, medicationTimes, selectedTimes, typeName }) => {
  console.log(medicationTimes)
  const { t } = useTranslation();

  const getInitialTime = useCallback(() => {
    const now = new Date();
    return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
  }, []);

  const [selectedTime, setSelectedTime] = useState<string>(getInitialTime());
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>(selectedTimes);

  useEffect(() => {
    setSelectedItems(selectedTimes);
  }, [selectedTimes]);

  const handleTimeSelected = useCallback((time: string) => {
    setSelectedTime(time);
  }, []);

  const convertTo12HourFormat = (time: string): string => {
    const [hourStr, minuteStr] = time.split(':');
    let hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);
    let period = 'AM';

    if (hour === 0) hour = 12;
    else if (hour === 12) period = 'PM';
    else if (hour > 12) {
      period = 'PM';
      hour -= 12;
    }

    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
  };

  const handleAddTime = useCallback(() => {
    if (!selectedTime.trim()) return;

    const formattedTime = convertTo12HourFormat(selectedTime);

    if (!selectedItems.includes(formattedTime)) {
      const updatedItems = [...selectedItems, formattedTime];
      setSelectedItems(updatedItems);
      onChange(updatedItems);
    }
  }, [selectedTime, selectedItems, onChange]);

  const handleRemoveTime = useCallback(
    (time: string) => {
      const updatedItems = selectedItems.filter((item) => item !== time);
      setSelectedItems(updatedItems);
      onChange(updatedItems);
    },
    [selectedItems, onChange]
  );

  const handleSelectTime = useCallback(
    (time: string) => {
      if (selectedItems.includes(time)) {
        handleRemoveTime(time);
      } else {
        const updatedItems = [...selectedItems, time];
        setSelectedItems(updatedItems);
        onChange(updatedItems);
      }
    },
    [selectedItems, onChange, handleRemoveTime]
  );

  const sortTimes = useCallback((times: string[]): string[] => {
    return [...times].sort((a, b) => {
      const [aTime, aPeriod] = a.split(' ');
      const [bTime, bPeriod] = b.split(' ');
      const [aHour, aMinute] = aTime.split(':').map(Number);
      const [bHour, bMinute] = bTime.split(':').map(Number);

      if (aPeriod !== bPeriod) {
        return aPeriod === 'AM' ? -1 : 1;
      }

      if (aHour !== bHour) {
        return aHour - bHour;
      }

      return aMinute - bMinute;
    });
  }, []);

  const organizedTimes = useMemo(() => {
    const predefinedTimes = sortTimes([...medicationTimes]);
    const additionalTimes = sortTimes(selectedItems.filter((time) => !medicationTimes.includes(time)));
    return [...predefinedTimes, ...additionalTimes];
  }, [medicationTimes, selectedItems, sortTimes]);

  return (
    <div className="relative">
      <Popover open={isPickerOpen} onOpenChange={setIsPickerOpen}>
        <PopoverTrigger asChild>
          <Button variant={'outline'} className="w-full justify-start text-left font-normal">
            {!typeName && selectedItems.length < 3 && <TimerIcon className="mr-2 h-4 w-4" />}
            {typeName !== 'PRN' ? (
              selectedItems.length > 0 ? (
                selectedItems.length <= 3 ? (
                  selectedItems.map((time, index) => (
                    <Badge key={index} variant="secondary" className="mr-2">
                      {time}
                    </Badge>
                  ))
                ) : (
                  <span>
                    {selectedItems.length}{' '}
                    {selectedItems.length === 1
                      ? t('medications.medicationForm.timePicker.hour')
                      : t('medications.medicationForm.timePicker.hours')}{' '}
                    {t('medications.medicationForm.timePicker.selected')}
                  </span>
                )
              ) : (
                <span>{t('medications.medicationForm.timePicker.selectOrEnterTime')}</span>
              )
            ) : (
              <Badge variant="secondary" className="mr-2">
                {t('medications.medicationForm.timePicker.asNeeded')}
              </Badge>
            )}
          </Button>
        </PopoverTrigger>
        {typeName !== 'PRN' && (
          <PopoverContent>
            <div>
              <div className="px-2 py-1 grid grid-cols-3 gap-2">
                {organizedTimes.map((time) => (
                  <div
                    key={time}
                    className={`flex items-center justify-center rounded px-2 py-1 cursor-pointer ${
                      selectedItems.includes(time) ? 'bg-primary text-white' : 'bg-gray-200'
                    } ${medicationTimes.includes(time) ? 'border-2 border-blue-500' : ''}`}
                    onClick={() => handleSelectTime(time)}
                  >
                    <span className="text-sm">{time}</span>
                    {!medicationTimes.includes(time) && selectedItems.includes(time) && (
                      <XCircleIcon
                        className="h-4 w-4 ml-1 text-red-500 hover:text-red-700"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveTime(time);
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div className="p-2 flex flex-col items-center">
                <div className="w-full max-w-xs">
                  <TimePicker onChange={handleTimeSelected} value={selectedTime} />
                </div>
                <button
                  className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-3 rounded mt-2 text-sm w-full"
                  onClick={handleAddTime}
                >
                  {t('medications.medicationForm.timePicker.add')}
                </button>
              </div>
            </div>
          </PopoverContent>
        )}
      </Popover>
    </div>
  );
};

export default TimeSelector;
