import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { ListFilter } from 'lucide-react';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { Label } from '@/common/presentation/components/ui/label';
import { useDispatch } from 'react-redux';
import { setNoteFilters, fetchGetNotes } from '../../slices/NoteSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';

interface NoteFiltersProps {
  crudState: CrudState;
}

export function NoteFilters({ crudState }: NoteFiltersProps) {
  // Initial 
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const categories = useSelector((state: RootState) => state.notes.categories);

  // Behavoir
  const updateNoteFilters = (key: string, value: string) => {
    dispatch(setNoteFilters([key, value]));
    dispatch<any>(fetchGetNotes(1));
  };

  const handleResidentFilter = async (residentId: string) => {
    updateNoteFilters('resident', residentId != 'all' ? residentId : '');
  };

  const handleStaffFilter = async (staffId: string) => {
    updateNoteFilters('staff', staffId != 'all' ? staffId : '');
  };

  const handleCategoryFilter = async (categoryId: string) => {
    updateNoteFilters('category', categoryId != 'all' ? categoryId : '');
  };

  return (
    <Popover>
      <PopoverTrigger>
        <ListFilter className="mr-2 h-5 w-5"></ListFilter>
      </PopoverTrigger>
      <PopoverContent>
        <div className="grid gap-4">
          <div className="grid gap-2">
            {!crudState.showingStaffNotes && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label htmlFor="resident">{t('notes.resident')}</Label>
                <Select onValueChange={handleResidentFilter}>
                  <SelectTrigger className="col-span-2 h-8">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent id="resident">
                    <SelectItem value="all">{t('notes.all')}</SelectItem>
                    {residents?.map((resident) => (
                      <SelectItem key={resident.id} value={resident.id}>
                        {resident.first_name + ' ' + resident.last_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="staff">{t('notes.staff')}</Label>
              <Select onValueChange={handleStaffFilter}>
                <SelectTrigger className="col-span-2 h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent id="staff">
                  <SelectItem value="all">{t('notes.all')}</SelectItem>
                  {staff?.map((staff: any) => (
                    <SelectItem key={staff.id} value={staff.id}>
                      {staff.first_name + ' ' + staff.last_name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="category">{t('notes.category')}</Label>
              <Select onValueChange={handleCategoryFilter}>
                <SelectTrigger className="col-span-2 h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent id="category">
                  <SelectItem value="all">{t('notes.all')}</SelectItem>
                  {categories?.map((category: any) => (
                    <SelectItem key={category.id} value={category.id}>
                      {category.category}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
