import { Button } from '@/common/presentation/components/ui/button';
import { Label } from '@/common/presentation/components/ui/label';
import { useEditDefaultForm } from '@/modules/residents/infrastructure/hooks/forms/useEditDefaultForm';
import { useMemo } from 'react';

const items = [
  { label: 'TIME FRAME', value: 'time_frame' },
  { label: 'PERSON(S) RESPONSIBLE FOR IMPLEMENTATION', value: 'person_responsible_for_implementation' },
  { label: 'METHOD OF EVALUATING PROGRESS', value: 'method_of_evaluating_progress' }
];

interface Props {
  defaultValues: any;
}

export const DefaultFormValues = ({ defaultValues }: Props) => {
  const { onOpen, defaultFormValues } = useEditDefaultForm();

  const data = useMemo(() => {
    const keys = items.map(({ value }: any) => value);

    return keys.map((key: any) => {
      return {
        key,
        value: defaultFormValues?.[key]?.trim() || ''
      };
    });
  }, [defaultValues, defaultFormValues]);

  const formatValue = (value: string) => {
    if (value === '' || value === undefined || value === null) {
      return 'Not Provided';
    }

    return value.split('\n').map((item, index) => <p key={index}>{item}</p>);
  };

  return (
    <div className="group/form relative flex flex-col gap-4">
      {items.map((item, index) => (
        <div key={index}>
          <Label className="font-bold">{item.label}:</Label>
          <p>{formatValue(data[index].value) || 'Not Provided'}</p>
        </div>
      ))}

      <Button
        className="absolute top-0 right-0 opacity-100 group-hover/form:opacity-100 transition"
        variant={'outline'}
        onClick={() =>
          onOpen({
            time_frame: data[0].value,
            person_responsible_for_implementation: data[1].value,
            method_of_evaluating_progress: data[2].value
          })
        }
      >
        Edit
      </Button>
    </div>
  );
};
