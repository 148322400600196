import { cn } from '@/lib/utils';
import '@blocknote/core/fonts/inter.css';
import '@blocknote/mantine/style.css';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

interface NotesEditorProps {
  className?: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image']
  ]
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'clean'
];

export const NotesEditor = ({ className, value, onChange, disabled }: NotesEditorProps) => {
  return (
    <>
      <ReactQuill
        className={cn(className)}
        theme={'snow'}
        value={value}
        onChange={onChange}
        formats={formats}
        modules={modules}
        readOnly={disabled}
      />
    </>
  );
};
