import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { usePlanOfCareStore } from '@/modules/residents/domain/stores/plan-of-care/planOfCareStore';
// import { useAddNewFieldForm } from '@/modules/residents/infrastructure/hooks/planofcareform/useAddNewFieldForm';
import * as residentConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomFieldsForm } from './CustomFieldsForm';
// import { NewFieldResponse } from '@/modules/residents/infrastructure/types/planofcare/newFormField';

interface NewFieldPayload {
  form_id: string;
  label: string;
  key: string;
  placeholder?: string;
  required: boolean;
  type: string;
}

export const AddNewFieldFormDialog = () => {
  // const { isOpen, onClose } = useAddNewFieldForm();
  // const { status, error: errorMessage, addNewFieldForm } = usePlanOfCareStore();
  // const { configurations } = useSelector((state: RootState) => state.residents.configurations || {});
  // const dispatch = useDispatch<AppDispatch>();

  // const onSubmit = useCallback(
  //   async (values: Partial<NewFieldPayload>) => {
  //     const payload: NewFieldPayload = {
  //       form_id: 'plan_of_care',
  //       label: values.label || '',
  //       key: values.key || '',
  //       placeholder: values.placeholder || '',
  //       required: values.required || false,
  //       type: 'text'
  //     };
  //     try {
  //       const response: NewFieldResponse = await addNewFieldForm(payload);

  //       if (response) {
  //         const updatedConfigurations = {
  //           ...configurations,
  //           forms_preferences: response.data
  //         };

  //         toast({
  //           description: 'Field added successfully',
  //           variant: 'default'
  //         });

  //         dispatch(residentConfigurationsActions.setConfigurations(updatedConfigurations));

  //         onClose();
  //       }
  //     } catch (error: any) {
  //       if (error.response?.data?.message) {
  //         toast({
  //           description: error.response.data.message,
  //           variant: 'destructive'
  //         });
  //       } else if (errorMessage === 'The key already exists in this form.') {
  //         toast({
  //           description: 'The key already exists in this form.',
  //           variant: 'destructive'
  //         });
  //       } else {
  //         toast({
  //           description: 'An error occurred while adding the field',
  //           variant: 'destructive'
  //         });
  //       }

  //       console.error('Error adding field:', error);
  //     }
  //   },
  //   [addNewFieldForm, configurations, dispatch, onClose, errorMessage]
  // );

  return (
    <>
      {/* <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-auto max-h-[80vh] h-auto overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl">Add New Field</DialogTitle>
          </DialogHeader>
          <CustomFieldsForm
            onSubmit={onSubmit}
            status={status}
            defaultValues={{
              label: '',
              placeholder: '',
              required: false
            }}
          />
        </DialogContent>
      </Dialog> */}
    </>
  );
};
