import React from 'react';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../ui/dropdown-menu';
import { ListIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface Column {
  key: string;
  labelTranslationKey: string;
  type?: string;
  visible?: boolean;
  static?: boolean;
}

interface TableViewOptionsProps {
  columns: Column[];
  columnVisibility: { [key: string]: boolean };
  onColumnVisibilityChange: (columnKey: string, isVisible: boolean) => void;
}

export function TableViewOptions({ columns, columnVisibility, onColumnVisibilityChange }: TableViewOptionsProps) {
  const { t } = useTranslation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm">
          <ListIcon className="mr-2 h-4 w-4" />
          {t('tableViewOptions.view')}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{t('tableViewOptions.toggleColumns')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {columns.map(
          (column) =>
            !column.static && (
              <DropdownMenuCheckboxItem
                key={column.key}
                checked={columnVisibility[column.key]}
                onCheckedChange={(checked) => onColumnVisibilityChange(column.key, checked)}
              >
                {t(column.labelTranslationKey)}
              </DropdownMenuCheckboxItem>
            )
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
