import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  time_frame: z.string().optional(),
  person_responsible_for_implementation: z.string().optional(),
  method_of_evaluating_progress: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

interface Props {
  defaultValues: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
}

export const DefaultFormValuesForm = ({ defaultValues, onSubmit }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="time_frame"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Time Frame</FormLabel>
                <FormControl>
                  <Textarea {...field} placeholder="Enter time frame" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="person_responsible_for_implementation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Person Responsible For Implementation</FormLabel>
                <FormControl>
                  <Textarea {...field} placeholder="Enter person responsible for implementation" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="method_of_evaluating_progress"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Method Of Evaluating Progress</FormLabel>
                <FormControl>
                  <Textarea {...field} placeholder="Enter method of evaluating progress" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" className="col-span-full" disabled={false}>
            {false && <Loader className="size-4 mr-2 animate-spin" />}
            Save
          </Button>
        </form>
      </Form>
    </>
  );
};
