import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { StaffSelector } from '@/modules/notes/presentation/components/staffNotes/StaffSelector';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { useEffect, useState } from 'react';
import NoteService from '../../../infrastructure/services/NoteService';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch } from 'react-redux';
import { Loader, Target } from 'lucide-react';
import React from 'react';
import { CrudState, FormData, NoteI } from '@/modules/notes/domain/note.domain';
import { QuillJS } from '../common/quilljs';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import { SelectNoteCategory } from '../common/SelectNoteCategory';
import { useTranslation } from 'react-i18next';


interface CreateNoteProps {
  crudState: CrudState;
  setCrudState: (crudState: CrudState) => void;
  note: NoteI | null;
}

export function CreateStaffNote({ crudState, setCrudState, note }: CreateNoteProps) {
  const { t } = useTranslation();
  const { params, setParam } = useRouteParams();
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const clientId = useSelector((state: RootState) => state.configurations.configurations.client_id);
  const dispatch = useDispatch();

  const defaultFormData: FormData = {
    content: '',
    html_content: '',
    status: 'active',
    user_id: [],
    category_id: '',
    resident_id: null,
    staff_id: crudState.isUpdatingStaffNote ? note?.staff_id ?? [] : [],
    location_id: crudState.isUpdatingStaffNote ? note?.scope_location_ids ?? [] : [],
    reference: 'notePage',
    images: null,
    client_id: clientId,
    draft_id: null,
    urgent: false,
    department_id: []
  };

  // Hooks
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (crudState.isUpdatingStaffNote && note) {
      if (note.category_id) {
        handleSelectCategory(note.category_id);
      }
    }
  }, [crudState]);

  // Behaviors
  const handleQuillJs = (values: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...values
    }));
  };

  const getContent = () => {
    if (crudState.isUpdatingStaffNote && note) {
      return note.html_content;
    }

    return '';
  };

  const handleSelectCategory = (value: any) => {
    setFormData({
      ...formData,
      ['category_id']: value
    });
  };

  const onStaffSelect = (staffId: any) => {
    const selectedStaff = staff.find((element) => element.id === staffId);

    if (!selectedStaff) return;

    const updatedLocations = [...formData.location_id];

    selectedStaff.locations.forEach((location: any) => {
      const index = updatedLocations.indexOf(location.id);
      if (index !== -1) {
        updatedLocations.splice(index, 1);
      } else {
        updatedLocations.push(location.id);
      }
    });

    if (formData.staff_id) {
      const updatedStaff = [...formData.staff_id];

      const index = updatedStaff.indexOf(staffId);
      if (index !== -1) {
        updatedStaff.splice(index, 1);
      } else {
        updatedStaff.push(staffId);
      }

      setFormData({
        ...formData,
        ['location_id']: updatedLocations,
        ['staff_id']: updatedStaff
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    if (!crudState.isUpdatingStaffNote) {
      await NoteService.createNote(formData);
    } else {
      if (!crudState.itemId) {
        console.log('Error updating note');
        setIsLoading(false);
        return;
      }

      let data = {
        ...formData,
        id: crudState.itemId
      };
      await NoteService.updateNote(data);
    }

    setIsLoading(false);

    setCrudState(NoteHelperService.defineState({ showingStaffNotes: true }));
  };

  return (
    <div className="flex h-full flex-col">
      <Separator />
      <div className="flex flex-1 flex-col">
        <Separator />
        <div className="p-4">
          <StaffSelector locationId={params?.l} onSelect={onStaffSelect} staffId={note?.staff_id} />
          <Card className="border-t-4 border-t-primary/80 mt-3 h-[50vh]">
            <CardContent>
              <ScrollArea className="h-[48vh]">
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="flex justify-between pt-3">
                    <SelectNoteCategory
                      handleSelectCategory={handleSelectCategory}
                      value={crudState.isUpdatingStaffNote && note?.category_id ? note.category_id : ''}
                      target="staff"
                    />
                  </div>
                  <div className="w-full  h-[22vh] pt-12">
                    <ScrollArea className="h-[15vh]">
                      <QuillJS onChange={handleQuillJs} value={getContent()} />
                    </ScrollArea>
                  </div>
                  <div className=" pt-16 flex justify-end">
                    <Button type="submit" size="sm" className="ml-auto">
                      {isLoading ? (
                        <Loader className="mr-2 h-4 w-4 animate-spin" />
                      ) : crudState.isUpdatingStaffNote ? (
                        t('notes.update')
                      ) : (
                        t('notes.send')
                      )}
                    </Button>
                  </div>
                </form>
              </ScrollArea>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
