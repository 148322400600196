import { useEffect, useState } from "react";
import { ITrackerCleaningSummary } from "./TrackerCleaningMockup";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { format } from "date-fns";
import { TrackerCleaningSummaryEditDialog } from "./TrackerCleaningSummaryEditDialog";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { deleteSummary, getSummaries } from "../../slices/TrackerCleaningSlice";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { Pencil, Search, Trash } from "lucide-react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";

export function TrackerCleaningSummary() {
    const dispatch = useDispatch();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const configurations = useSelector((state: RootState) => state.configurations);
    const { params } = useRouteParams();
    const { t } = useTranslation();
    let [dataSource, setDataSource] = useState<ITrackerCleaningSummary[]>([]);
    const [accentColor, setAccentColor] = useState("");
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState<ITrackerCleaningSummary>();
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteData, setDeleteData] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        setDataSource(trackerCleaning.summaries);
    }, [trackerCleaning]);

    /* EDIT DIALOG */
    const openEditDialog = (element: ITrackerCleaningSummary) => {
        setOpenEdit(true);
        setEditData(element);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleEdit = (element: ITrackerCleaningSummary) => {
        dispatch(deleteSummary(
            {
                id
            }
        ));

        fetchData();

        return true;
    }

    /* DELETE DIALOG */
    const openDeleteDialog = (id: string) => {
        setOpenDelete(true);
        setDeleteData(id);
    }

    const closeDeleteDialog = () => {
        setOpenDelete(false);
    }

    const handleDelete = (id: string) => {
        dispatch(deleteSummary(
            {
                id
            }
        ));

        fetchData();

        return true;
    }

    const fetchData = async () => {
        await dispatch(getSummaries(
            {
                location_id: params.l
            }
        ));
    }
    
    return (
            <div>
                {
                    deleteData && <CustomDialog 
                        title={t("cleaning.report.summary.delete.title")} 
                        description={t("cleaning.report.summary.delete.description")} 
                        width="30rem"
                        newDialog={true}
                        isOpen={openDelete}
                        onSubmit={() => handleDelete(deleteData)}
                        onClose={closeDeleteDialog}
                    ></CustomDialog>
                }

                {
                    editData && <CustomDialog 
                        title={t("cleaning.report.summary.edit.title")}
                        width="30rem"
                        newDialog={true}
                        isOpen={openEdit}
                        onSubmit={() => handleEdit(editData)}
                        onClose={closeEditDialog}
                    >
                        <TrackerCleaningSummaryEditDialog summary={editData} />
                    </CustomDialog>
                }

            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("cleaning.report.summary.title")}
                    </CardTitle>
                </CardHeader>
                    
                <CardContent>
                    <div className="grid grid-cols-1 grid-row-2 w-100">
                        {
                            dataSource.length ? (
                                <Table>
                                    <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                                        <TableRow className="bg-primary/80 hover:bg-primary/80">
                                            <TableHead className="text-white">{t("cleaning.report.summary.table.date")}</TableHead>
                                            <TableHead className="text-white">{t("cleaning.report.summary.table.task")}</TableHead>
                                            <TableHead className="text-white">{t("cleaning.report.summary.table.detail")}</TableHead>
                                            <TableHead className="text-white">{t("cleaning.report.summary.table.area")}</TableHead>
                                            <TableHead className="text-white">{t("cleaning.report.summary.table.staff")}</TableHead>
                                            <TableHead className="text-white">{t("cleaning.report.summary.table.actions")}</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    
                                    <TableBody>
                                        {
                                            dataSource.length && dataSource.map(
                                                element =>
                                                <TableRow key={element.id}>
                                                    <TableCell>{format(new Date(element.data.date), "dd/MM/yyyy hh:mm a")}</TableCell>
                                                    <TableCell>{element.data.task.name}</TableCell>
                                                    <TableCell>{element.data.detail}</TableCell>
                                                    <TableCell>{element.data.area.name}</TableCell>
                                                    <TableCell>{element.data.staff.first_name + ' ' + element.data.staff.last_name}</TableCell>
                                                    <TableCell className="flex">      
                                                        <Button onClick={() => openEditDialog(element)} variant="outline" size="icon" className='mr-2'>
                                                            <Pencil className="h-4 w-4" />
                                                        </Button>
                                                        
                                                        <Button onClick={() => openDeleteDialog(element.id)} variant="outline" size="icon" className='mr-2'>
                                                            <Trash className="h-4 w-4" />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            ) : (
                                <div className="flex flex-col w-100 justify-center align-center">
                                    <div className="flex justify-center items-center">
                                        <Search className="text-center w-5 mb-2" />
                                    </div>

                                    <h1 className="text-center">
                                        {t("cleaning.report.summary.table.notFound")}
                                    </h1>
                                </div>
                            )
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
