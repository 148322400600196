import { Locale } from 'date-fns';

export const tagalog: Locale = {
  code: 'tl',
  formatLong: {
    date: () => 'MM/dd/yyyy',
    time: () => 'HH:mm',
    dateTime: () => 'MM/dd/yyyy HH:mm',
  },
  formatRelative: () => '',
  localize: {
    ordinalNumber: () => '',
    era: () => [],
    quarter: () => [],
    month: (month: number) => {
      const months = [
        'Enero', 'Pebrero', 'Marso', 'Abril', 'Mayo', 'Hunyo',
        'Hulyo', 'Agosto', 'Setyembre', 'Oktubre', 'Nobyembre', 'Disyembre'
      ];
      return months[month];
    },
    day: (day: number) => {
      const days = ['Lun', 'Mar', 'Miy', 'Huw', 'Biy', 'Sab', 'Lin'];
      return days[day];
    },
    dayPeriod: () => '',
  },
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1,
  },
  match: {
    ordinalNumber: () => ({ value: 0, rest: '' }),
    era: () => ({ value: 0, rest: '' }),
    quarter: () => ({ value: 0, rest: '' }),
    month: () => ({ value: 0, rest: '' }),
    day: () => ({ value: 0, rest: '' }),
    dayPeriod: () => ({ value: 0, rest: '' }),
  },
};