import React, { createContext, useContext, useState } from 'react';
import { FormDataI } from '../../domain/makerPlace.domain';

interface FormDataContextType {
  formData: FormDataI;
  updateFormData: (newData: Partial<FormDataI>) => void;
}

export const FormDataContext = createContext<FormDataContextType | undefined>(undefined);

export const useMarketPlaceFormData = (): FormDataContextType => {
  const context = useContext(FormDataContext);

  if (!context) {
    throw new Error('useWizardFormData must be used within a FormDataProvider');
  }

  return context;
};

interface IProps {
  children: React.ReactNode;
}

export const FormDataProvider: React.FC<IProps> = ({ children }) => {
  const initialFormData: FormDataI = {
    serviceSelectedInfo: {
      id: '',
      name: '',
      key: '',
      includes: null,
      description: null,
      status: '',
      modules: [],
      type: {
        id: '',
        name: ''
      }
    },
    isDialogOpen: false,
    subscriptionToCancel: {}
  };

  const [formData, setFormData] = useState<FormDataI>(initialFormData);

  const updateFormData = (newData: Partial<FormDataI>) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const contextValue: FormDataContextType = {
    formData,
    updateFormData
  };

  return <FormDataContext.Provider value={contextValue}>{children}</FormDataContext.Provider>;
};
