import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { Languages, SendHorizonal } from 'lucide-react';

interface QuillToolbarProps {
  submit?: boolean;
  translator?: boolean;
}

function undoChange() {
  const button = document.querySelector('#formChat button[type="submit"]') as HTMLButtonElement;
  if (button) {
    button.click();
  }
}

function redoChange() {
  const translate = document.querySelector('#btn-translate') as HTMLButtonElement;
  if (translate) {
    translate.click();
  }
}

const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block'
];

export const QuillToolbar = ({ submit = false, translator = false }: QuillToolbarProps) => (
  <div id="toolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-image" />
    </span>
    {translator && (
      <span className="ql-formats">
        <button className="ql-redo">
          <Languages />
        </button>
      </span>
    )}
    {submit && (
      <span className="ql-formats" style={{ float: 'inline-end' }}>
        <button className="ql-undo">
          <SendHorizonal />
        </button>
      </span>
    )}
  </div>
);

export default QuillToolbar;
