import LocationService from '@/modules/locations/infrastructure/service/LocationService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface LocationSlice {
  location: any;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: LocationSlice = {
  location: null,
  status: 'idle',
  error: null
};

export const getLocation = createAsyncThunk('location/getLocation', async (locationId: string) => {
  const response = await LocationService.getLocation(locationId);
  const data = response.data.location;
  return data;
});

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
      state.status = 'idle';
      state.error = null;
    },
    removeLocation: (state) => {
      state.location = null;
      state.status = 'idle';
      state.error = null;
    },
    clearLocation: (state) => {
      state.location = null;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLocation.pending, (state) => {
      state.status = 'loading';
      state.location = null;
      state.error = null;
    });

    builder.addCase(getLocation.fulfilled, (state, action) => {
      state.status = 'idle';
      state.location = action.payload;
      state.error = null;
    });

    builder.addCase(getLocation.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
      state.location = null;
    });
  }
});

export const { setLocation, removeLocation, clearLocation } = locationSlice.actions;
export default locationSlice.reducer;
