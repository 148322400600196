import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { RootState } from '@/store/store';
import { ArrowLeft } from 'lucide-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AuthContext } from '@/auth/infrastructure/providers/AuthContextProvider';
import { Button } from '@/common/presentation/components/ui/button';

export function AssessmentNavbar() {
    const { clientInfo } = useConfigurationsProvider();
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const { logout } = useContext(AuthContext);
    const { t } = useTranslation();
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const handleLogout = () => {
        logout();
    };

    return (
        <div className='flex justify-center items-center w-100 p-2' style={{ backgroundColor: accentColor }}>
            <Button onClick={handleLogout}>
                <ArrowLeft style={{ color: "#ffffff", marginLeft: "1rem", cursor: "pointer", position: "absolute", top: "1.6rem", left: 0 }} />
            </Button>

            <img style={{ width: "4rem" }} src={clientInfo?.logo} />
        </div>
    );
}
