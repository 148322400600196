import { Input } from '@/common/presentation/components/ui/input';
import React, { useState, useEffect } from 'react';

const NumberInput = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value === '' ? '' : parseInt(e.target.value);
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleIncrement = () => {
    const newValue = inputValue === '' ? 1 : inputValue + 1;
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleDecrement = () => {
    if (inputValue === '' || inputValue > 0) {
      const newValue = inputValue === '' ? 0 : inputValue - 1;
      setInputValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <div className="flex items-center border border-gray-300 rounded-md">
      <button
        onClick={handleDecrement}
        className="px-3 py-1 focus:outline-none"
      >
        -
      </button>
      <Input
        type="number"
        value={inputValue === '' ? '' : inputValue}
        onChange={handleChange}
        className=" px-3 py-1 text-center focus:outline-none border-none"
      />
      <button
        onClick={handleIncrement}
        className="px-3 py-1 focus:outline-none"
      >
        +
      </button>
    </div>
  );
};

export default NumberInput;
