import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';

const CustomCard = ({ title, description, children }) => {
  return (
    <Card className="col-span-3 border-t-4 border-t-primary/80">
      <CardHeader>
        <CardTitle className="flex flex-row items-center text-lg font-bold">{title}</CardTitle>
        <div className="flex w-full items-center justify-between">
          <div>{description}</div>
        </div>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CustomCard;
