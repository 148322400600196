import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSpeedTest, fetchSystemTest } from '../../../slices/DashboardSlice';
import { AppDispatch } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Button } from '@/common/presentation/components/ui/button';
import { cn } from '@/lib/utils';
import { HeartPulse, RefreshCw } from 'lucide-react';
import ItemDiagnosis from './item-diagnosis';
import { HealthStatus } from '@/modules/dashboard/domain/dashboard.domain';
import { useTranslation } from 'react-i18next';


export const SystemHealth = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [speed, setSpeed] = useState<number | null>(null);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [cpuUsage, setCpuUsage] = useState<number | null>(null);
  const [memoryUsage, setMemoryUsage] = useState<number | null>(null);
  const [systemStatus, setSystemStatus] = useState<HealthStatus>(HealthStatus.good);
  const [networkStatus, setNetworkStatus] = useState<HealthStatus>(HealthStatus.good);
  const { t } = useTranslation();

  const FILE_SIZE_IN_BYTES = 10 * 1024 * 1024; // 10MB
  const NETWORK_SPEED_THRESHOLDS = { good: 13, poor: 5 };
  const CPU_SPEED_THRESHOLDS = { good: 78, poor: 90 };
  const MEMORY_SPEED_THRESHOLDS = { good: 78, poor: 90 };

  useEffect(() => {
    getSpeedNetwork();
    getSystemTest();
  }, []);


  useEffect(() => {
    if (speed === null) return;
    setNetworkStatus(determineNetworkQuality(speed));
  }, [speed]);

  useEffect(() => {
    if (memoryUsage === null || cpuUsage === null) return;
    setSystemStatus(getSystemStatus(cpuUsage, memoryUsage));
  }, [memoryUsage, cpuUsage])


  const getSystemStatus = (cpuUsage: number, memoryUsage: number) => {
    let cpuStatus: HealthStatus = HealthStatus.good;
    let memoryStatus: HealthStatus = HealthStatus.good;
    let overallStatus: HealthStatus = HealthStatus.good;

    if (cpuUsage <= CPU_SPEED_THRESHOLDS.good) {
      cpuStatus = HealthStatus.good;
    } else if (cpuUsage <= CPU_SPEED_THRESHOLDS.poor) {
      cpuStatus = HealthStatus.poor;
    } else {
      cpuStatus = HealthStatus.fair;
    }

    if (memoryUsage <= MEMORY_SPEED_THRESHOLDS.good) {
      memoryStatus = HealthStatus.good;
    } else if (memoryUsage <= MEMORY_SPEED_THRESHOLDS.poor) {
      memoryStatus = HealthStatus.poor;
    } else {
      memoryStatus = HealthStatus.fair;
    }

    if (cpuStatus === HealthStatus.poor || memoryStatus === HealthStatus.poor) {
      overallStatus = HealthStatus.poor;
    } else if (cpuStatus === HealthStatus.fair || memoryStatus === HealthStatus.fair) {
      overallStatus = HealthStatus.fair;
    }

    return overallStatus;
  };

  const getSystemTest = async () => {
    try {
      const startTime = new Date().getTime();
      const { payload } = await dispatch(fetchSystemTest(startTime));      
      const cpu = payload.cpuUsage == 0 ||  payload?.cpuUsage.length == 0 ? 1 : parseFloat(payload.cpuUsage);
      const memory = parseFloat(parseFloat(payload.memoryUsage)?.toFixed(2) ?? 0);
      setMemoryUsage(memory);
      setCpuUsage(cpu);
      setSystemStatus(getSystemStatus(cpu, memory));
    } catch (error) {
      console.log(error);
    }
  };

  const getSpeedNetwork = useCallback(async (refresh: boolean = false) => {
    try {

      if (refresh == false) {
        await dispatch(fetchSpeedTest(new Date()));
      }
      
      const startTime = new Date().getTime();
      await dispatch(fetchSpeedTest(new Date()));

      const endTime = new Date().getTime();
      const durationInSeconds = (endTime - startTime) / 1000;

      const fileSizeInBits = FILE_SIZE_IN_BYTES * 8;
      const speedMbps = fileSizeInBits / durationInSeconds / (1024 * 1024); // Convert to Mbps

      setSpeed(parseFloat(speedMbps.toFixed(2)));
    } catch (error) {
      console.error('Error testing network:', error);
    }
  }, [dispatch]);

  const determineNetworkQuality = (speedMbps: number): HealthStatus => {
    if (speedMbps >= NETWORK_SPEED_THRESHOLDS.good) return HealthStatus.good;
    if (speedMbps >= NETWORK_SPEED_THRESHOLDS.poor) return HealthStatus.poor;
    return HealthStatus.fair;
  };

  const getHealthStatusMessage = () => {
    switch (systemStatus) {
      case HealthStatus.good:
        return t('dashboard.runningSmoothly', {cpuUsage: cpuUsage, memoryUsage: memoryUsage}) + '.';
      case HealthStatus.poor:
        return t('dashboard.runningOk', {cpuUsage: cpuUsage, memoryUsage: memoryUsage}) + '.';
      case HealthStatus.fair:
        return t('dashboard.runningPoor', {cpuUsage: cpuUsage, memoryUsage: memoryUsage}) + '.';
      default:
        return `System status is unknown. CPU and memory usage could not be determined.`;
    }
  };

  const getNetworkQualityMessage = () => {
    switch (networkStatus) {
      case HealthStatus.good:
        return t('dashboard.networkExcellent', { speed: speed }) + '.';
      case HealthStatus.poor:
        return t('dashboard.networkAcceptable', { speed: speed }) + '.';
      case HealthStatus.fair:
        return t('dashboard.networkPoor', { speed: speed }) + '.';
      default:
        return '';
    }
  };

  const refresh = async () => {
    setRefreshing(true);
    setSpeed(null);
    setCpuUsage(null);
    setMemoryUsage(null);
    await getSpeedNetwork(true);
    await getSystemTest();
    setRefreshing(false);
  };

  return (
    <Card className="border-t-4 border-t-primary/80 h-full flex flex-col">
      <CardHeader>
        <div className="flex flex-row items-center mb-2">
          <HeartPulse className={cn('shrink-0 h-6 w-6 mr-2 text-muted-foreground', refreshing ? 'text-primary' : '')} />
          <CardTitle className="flex-1 font-bold text-muted-foreground text-lg">{ t('dashboard.systemHealth') }</CardTitle>
          <div className="flex gap-4">
            <Button disabled={refreshing} variant="outline" className="h-8 hover:bg-primary/5 gap-2" onClick={refresh}>
              <RefreshCw className={cn('h-4 w-4', refreshing ? 'animate-spin text-primary' : '')} />
              {refreshing ? t('dashboard.refreshing') : t('dashboard.refresh')}
            </Button>
          </div>
        </div>
        <hr />
      </CardHeader>
      <CardContent className="flex-grow flex flex-col">
        <div className="flex flex-col gap-4">
            <ItemDiagnosis
              title={t('dashboard.localNetwork')}
              message={getNetworkQualityMessage()}
              status={networkStatus}
              loading={(refreshing || speed == null)}
            />
            <ItemDiagnosis
              title={t('dashboard.systemStatus')}
              message={getHealthStatusMessage()}
              status={systemStatus}
              loading={(refreshing || memoryUsage == null)}
            />
        </div>
      </CardContent>
    </Card>
  );
};
