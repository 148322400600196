import { useState } from 'react';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { useTranslation } from 'react-i18next';


interface UrgentNoteProps {
  onCheck: (checked: boolean) => void;
  checked?: boolean;
}

export function UrgentNoteCheckbox({ onCheck, checked = false }: UrgentNoteProps) {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(checked);

  const handleChecked = (e: boolean) => {
    setIsChecked(e);
    onCheck(e);
  };

  return (
    <div className="flex items-center space-x-2">
      <Checkbox id="urgent" checked={isChecked} onCheckedChange={handleChecked} />
      <label
        htmlFor="urgent"
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {t('notes.important/Urgent')}
      </label>
    </div>
  );
}
