import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
 } from '@/common/presentation/components/ui/dialog';
 import { Button } from '@/common/presentation/components/ui/button';
 import { Label } from '@/common/presentation/components/ui/label';
 import { Input } from '@/common/presentation/components/ui/input';
import { Trash } from 'lucide-react';
import { useDispatch } from 'react-redux';
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';

export function DeleteOutingsTrackerReportDialog(props: any) {
  const { params, setParams } = useRouteParams();
  const dispatch = useDispatch();
    
    const deleteElement = async () => {
        await dispatch(trackerActions.destroyTrackerOutingsGeneral(
            {
                id: props.id,
                id_data: props.id_data
            }
        ));

        fetchData();
    };

    const fetchData = async () => {
        await dispatch(trackerActions.getTrackerOutingsGeneral(
            {
              "location_id": params.l
            }
          )
        );
      }

    return (
        <div>
            <Dialog>
                <DialogTrigger asChild>
                    <Button variant="outline" size="icon" className='mr-2'>
                        <Trash className="h-4 w-4" />
                    </Button>
                </DialogTrigger>

                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Eliminate</DialogTitle>
                    </DialogHeader>

                <div className="grid gap-4 py-4">
                    Are you sure to delete the registry?
                </div>

                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="submit" onClick={deleteElement}>Accept</Button>
                    </DialogClose>
                    
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">Cancel</Button>
                    </DialogClose>
                </DialogFooter>
        </DialogContent>
        </Dialog>
        </div>
    );
};
