const dateFormats = {
  es: {
    dateFormat: 'dd/MM/yyyy',
    dateTimeFormat: 'dd/MM/yyyy hh:mm a',
  },
  en: {
    dateFormat: 'MM/dd/yyyy',
    dateTimeFormat: 'MM/dd/yyyy hh:mm a',
  },
  tl: {
    dateFormat: 'MM/dd/yyyy',
    dateTimeFormat: 'MM/dd/yyyy hh:mm a',
  },
};

export default dateFormats;
