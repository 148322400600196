import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem
} from '@/common/presentation/components/ui/dropdown-menu';
import {  PenBox, FilePlus2, Plus } from 'lucide-react';
import { RootState } from '@/store/store';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface FloatButtonProps {
  setCrudState: (crudState: CrudState) => void;
}

export function FloatButton({ setCrudState }: FloatButtonProps) {
  // Init values
  const { t } = useTranslation();

  // Redux
  const auth = useSelector((state: RootState) => state.auth.user);

  return (
    <div className="bottom-4 ml-1 cursor-pointer">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Plus className="text-primary"  />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onSelect={() => setCrudState(NoteHelperService.defineState({ isCreating: true }))}>
            <PenBox />
            <span className="ml-2">{t('notes.newNote')}</span>
          </DropdownMenuItem>
          {auth?.roles[0] && ['Super Administrator', 'Executive', 'Client Manager'].includes(auth?.roles[0].name) && (
            <DropdownMenuItem
              onSelect={() =>
                setCrudState(NoteHelperService.defineState({ showingStaffNotes: true, isCreatingStaffNote: true }))
              }
            >
              <FilePlus2 />
              <span className="ml-2">{t('notes.newStaffNote')}</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
