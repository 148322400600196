import { Form, FormControl, FormField, FormItem, FormLabel } from '@/common/presentation/components/ui/form';
import { Switch } from '@/common/presentation/components/ui/switch';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  defaultValues: Partial<FormValues>;
}

const FormSchema = z.object({
  status: z.boolean()
});

type FormValues = z.infer<typeof FormSchema>;

export const SwitchStaffStatus = ({ defaultValues }: Props) => {
  const { staffMember, status: staffStatus } = useSelector((state: RootState) => state.staff.staffMember);
  const { status } = useSelector((state: RootState) => state.staff.staffMemberForm);
  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmit = async (data: FormValues) => {
    const response = await dispatch(
      staffMemberFormActions.updateStaffPersonalInformationForm({
        staff_member_id: staffMember?.id,
        ...staffMember,
        status: data.status === true ? 'active' : 'inactive'
      })
    );

    if (response?.meta.requestStatus === 'rejected') {
      toast({
        description: 'Failed to update staff member status',
        variant: 'destructive'
      });
    }

    if (response?.meta.requestStatus === 'fulfilled') {
      toast({
        description: 'Staff member status updated'
      });
    }
  };

  return (
    <>
      <div>
        {staffStatus === 'idle' && (
          <Form {...form}>
            <form onChange={form.handleSubmit(onSubmit)} className="grid grid-cols-2 gap-2 p-4">
              <FormField
                control={form.control}
                name="status"
                render={({ field }) => {
                  return (
                    // items-center justify-between rounded-lg border p-4
                    <FormItem className="flex flex-col ">
                      <FormLabel className="text-base">Active Status</FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value as boolean}
                          onCheckedChange={field.onChange}
                          disabled={status === 'loading'}
                          aria-readonly
                        />
                      </FormControl>
                    </FormItem>
                  );
                }}
              />
            </form>
          </Form>
        )}
      </div>
    </>
  );
};
