import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const AuthService = {
  login: async (data: any) => {
    const response = await axios.post('/auth/login', data);
    return response;
  },

  logout: async () => {
    const response = await axios.get('/auth/logout');
    return response;
  },

  verifySession: async () => {
    const response = await axios.get('/auth/verify');
    return response;
  }
};

export default AuthService;
