import { FormField } from '@/modules/residents/domain/models/FormField';
import { create } from 'zustand';

type EditFormFieldsManagerState = {
  planOfCare: FormField | null;
  isOpen: boolean;
  onOpen: (planOfCare: FormField) => void;
  onClose: () => void;
};

export const useEditFormFieldsManager = create<EditFormFieldsManagerState>((set) => ({
  planOfCare: null,
  isOpen: false,
  onOpen: (planOfCare: FormField) => set({ isOpen: true, planOfCare }),
  onClose: () => set({ isOpen: false })
}));
