import { Label } from "@/components/ui/label";
import { useTranslation } from "react-i18next";

const PlanCard = ({ price }: { price: string }) => {
    const { t } = useTranslation();
  return (
<div className="relative p-[2px] overflow-hidden shadow-sm rounded-lg bg-gradient-to-r from-primary/10 via-blue-800/5 to-primary/65 animate-gradient-x">
  <div className="relative bg-white dark:bg-gray-800 rounded-lg p-4 h-auto flex flex-col sm:flex-row sm:items-center">
    <div className="flex flex-col sm:flex-row justify-between w-full gap-4">
      <div className="flex flex-col justify-start sm:w-1/2">
        <Label className="font-extrabold text-xl">{t('signUp.essentials')}</Label>
        <Label className="text-gray-400 text-lg">{t('signUp.standardSuscription')}</Label>
      </div>
      
      <div className="flex flex-col justify-end sm:w-1/2 sm:items-end">
        <Label className="font-extrabold text-xl">{price}</Label>
        <Label className="text-gray-400 text-lg">{t('signUp.perFacilitysUpTo', { beds: 10 })}</Label>
      </div>
    </div>
  </div>
</div>

  );
};

export default PlanCard;
