import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { AxiosError } from 'axios';

const LocationService = {
  getAllLocations: async () => {
    try {
      return await axios.get('locations');
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error fetching locations';
      throw new Error(message);
    }
  },

  getLocation: async (locationId: string) => {
    try {
      return await axios.get(`locations/${locationId}`);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error fetching location';
      throw new Error(message);
    }
  },

  getUsersByLocation: async (locationId: string) => {
    try {
      return await axios.get(`user-locations/${locationId}`);
    } catch (error) {
      throw new Error('Error fetching userLocations: ' + error);
    }
  }
};

export default LocationService;
