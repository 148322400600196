import React from 'react';
import ServiceSelectionForm from './forms/ServiceSelectionForm';
import { ServiceI } from '../../domain/makerPlace.domain';
import ServiceEssentialForm from './forms/ServiceEssentialForm';

interface Props {
  serviceInfo: ServiceI;
}

const SubscriptionForm: React.FC<Props> = ({ serviceInfo }) => {
  const renderForm = () => {    
    switch (serviceInfo.type.name) {
      case 'quantity':
        return (
          <ServiceEssentialForm
            serviceInfo={serviceInfo}
          />
        );
      case 'selection':
        return (
          <ServiceSelectionForm
            serviceInfo={serviceInfo}
          />
        );
      default:
        return null;
    }
  };
  return <>{renderForm()}</>;
};

export default SubscriptionForm;
