import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { cn } from '@/lib/utils';
import { GetPicklist } from '@/modules/residents/infrastructure/services/ResidentsService';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';

interface Props {
  className?: string;
}

const formSchema = z.object({
  admission_date: z.date({
    required_error: 'Admission date is required'
  }),
  original_move_in_date: z.date({
    required_error: 'Original move in date is required'
  }),
  current_resident_house: z.string({
    required_error: 'Current resident house is required'
  }),
  resident_room: z.string(),
  level_of_care: z.string(),
  adress_of_prior_to_admision: z.string().optional(),
  date_of_left_prior_facility: z.date().optional(),
  reason_for_leaving_prior_facility: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

const defaultValues: Partial<FormValues> = {
  admission_date: undefined,
  original_move_in_date: undefined,
  current_resident_house: undefined,
  resident_room: '',
  level_of_care: '',
  adress_of_prior_to_admision: '',
  date_of_left_prior_facility: undefined,
  reason_for_leaving_prior_facility: ''
};

export const LevelOfCareForm = ({ className }: Props) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [levelOfCareOptions, setLevelOfCareOptions] = useState([]);
  const { pathname } = useLocation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const [locationsResidents, setLocationsResidents]: any = useState({
    ...defaultValues
  });
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
    values: { ...locationsResidents }
  });

  const onSubmit = (data: FormValues) => {
    const formData: any = {};

    Object.entries(data).forEach(([key, value]) => {
      if (value === undefined) {
        formData[key] = null;
      } else {
        formData[key] = value;
      }
    });
  };

  const getPicklist = async () => {
    try {
      const { data }: any = await GetPicklist();
      const { levelOfCarePickList, locations } = data;

      function compareLevels(a: any, b: any) {
        if (a.name === 'Full Care ') return 1;
        if (b.name === 'Full Care ') return -1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      levelOfCarePickList.sort(compareLevels);

      const locationOptions = locations.map((location: any) => ({
        label: location.location.name,
        value: location.location_id
      }));

      const levelOfCareOptions = levelOfCarePickList.map((levelOfCare: any) => ({
        label: levelOfCare.name,
        value: levelOfCare.id
      }));

      setLocationOptions(locationOptions);
      setLevelOfCareOptions(levelOfCareOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPicklist();
  }, []);
  useEffect(() => {
    const path = pathname.split('/').pop();

    setLocationsResidents({});
    form.reset();

    if (path === 'detail' && resident && resident.transfers && resident.transfers.length > 0) {
      const locationResident = resident.transfers[0];
      if (locationOptions.length > 0 && levelOfCareOptions.length > 0) {
        setLocationsResidents({
          admission_date: locationResident?.transfer_date ? new Date(locationResident?.transfer_date) : undefined,
          original_move_in_date: locationResident?.transfer_date
            ? new Date(locationResident?.transfer_date)
            : undefined,
          current_resident_house: locationResident.destination_location_id || undefined,
          resident_room: resident.resident_room || '',
          level_of_care: resident.level_of_care_id || '',
          adress_of_prior_to_admision: resident?.other_information?.adress_of_prior_to_admision || '',
          date_of_left_prior_facility: resident?.other_information?.date_of_left_prior_facility
            ? new Date(resident?.other_information?.date_of_left_prior_facility)
            : undefined,
          reason_for_leaving_prior_facility: resident?.other_information?.reason_for_leaving_prior_facility || ''
        });
      }
    }
  }, [resident, levelOfCareOptions, locationOptions]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Residence & Level of Care" defaultOpen>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div>
                    <FormField
                      control={form.control}
                      name="admission_date"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Admission Date / Current House <span className="text-destructive">(*)</span>
                          </FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={'outline'}
                                  className={cn(
                                    'w-full pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                >
                                  {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date</span>}
                                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <div className="rounded-md border">
                                <CustomCalendar onChange={field.onChange} value={field.value} MonthAndYearPicker />
                              </div>
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div>
                    <FormField
                      control={form.control}
                      name="adress_of_prior_to_admision"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Address Prior To Admission</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="date_of_left_prior_facility"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Date Left Prior Facility</FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={'outline'}
                                  className={cn(
                                    'w-full pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                >
                                  {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date</span>}
                                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <div className="rounded-md border">
                                <CustomCalendar onChange={field.onChange} value={field.value} MonthAndYearPicker />
                              </div>
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="reason_for_leaving_prior_facility"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Reasons For Leaving Prior Facility</FormLabel>
                          <FormControl>
                            <Textarea className="min-h-[40px] h-[40px]" placeholder="" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                {/* <div className='flex justify-end'>
          <Button type="submit">Save</Button>
          </div> */}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
