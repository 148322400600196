import { ScreeningQuestionsPage } from '@/modules/visitors/presentation/pages/ScreeningQuestionsPage';
import { VisitorsPage } from '@/modules/visitors/presentation/pages/VisitorsPage';
import { Route, Routes } from 'react-router-dom';

const VisitorsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<VisitorsPage />} />
      <Route path="/questions" element={<ScreeningQuestionsPage />} />
    </Routes>
  );
};

export default VisitorsRouter;