import { Pencil, UserPlus, ReplyAllIcon, BookOpenCheck, MessageCircle } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/registry/new-york/ui/avatar';
import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { NoteI, NoteCategoryI } from '../../../domain/note.domain';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useState, useEffect } from 'react';
import NoteService from '../../../infrastructure/services/NoteService';
import { setNoteId } from '../../slices/NoteSlice';
import { useDispatch } from 'react-redux';
import { toast } from '@/common/presentation/components/registry/new-york/ui/use-toast';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/common/presentation/components/ui/dialog';
import { cn } from '@/lib/utils';
import Select from 'react-select';
import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { Chat } from '../notes/elements/chat';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import { ImgageGrid } from './imageGrid';
import { useTranslation } from 'react-i18next';


interface NoteDisplayProps {
  note: NoteI | null;
  setCrudState: (crudState: CrudState) => void;
  crudState: CrudState;
}

interface formDataAddUser {
  note_id: string;
  user_id: string[];
}

interface formDataSharePrivateNote {
  note_id: string;
  user_id: string[];
}

interface Option {
  value: string;
  label: string;
}

interface CategoryAccumulator {
  [key: string]: string;
}

export function NoteDisplay({ note, setCrudState, crudState }: NoteDisplayProps) {
  // Initial
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const authUserId = useSelector((state: RootState) => state.auth.user?.id);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const categories = useSelector((state: RootState) => state.notes.categories);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const noteToOptions: Option[] = [];

  // Hooks
  const [openDialogSharePrivateNote, setopenDialogSharePrivateNote] = useState(false);
  const [openDialogAddUser, setopenDialogAddUser] = useState(false);
  const [displayChat, setdisplayChat] = useState<boolean>(false);



  const [formDataAddUser, setFormDataAddUser] = useState<formDataAddUser>({
    note_id: noteSelected,
    user_id: []
  });

  const [formDataSharePrivateNote, setformDataSharePrivateNote] = useState<formDataSharePrivateNote>({
    note_id: noteSelected,
    user_id: []
  });

  staff.forEach((element: any) => {
    if (
      userId != element.id &&
      Array.isArray(note?.private_user_ids) &&
      note.private_user_ids.includes(element.id) &&
      !note?.created_by != element.id
    ) {
      let item = {
        value: element.id,
        label: element.first_name + ' ' + element.last_name
      };
      noteToOptions.push(item);
    }
  });

  const residentNames = residents.reduce((acc, item) => {
    acc[item.id] = item.first_name + ' ' + item.last_name;
    return acc;
  }, {});

  const staffNames = staff.reduce((acc, item) => {
    acc[item.id] = item.first_name + ' ' + item.last_name;
    return acc;
  }, {});

  const staffPhotos = staff.reduce((acc, item) => {
    acc[item.id] = item.profile_url;
    return acc;
  }, {});

  const categoriesNames = categories.reduce((acc: CategoryAccumulator, item: NoteCategoryI) => {
    acc[item.id] = item.category;
    return acc;
  }, {} as CategoryAccumulator);

  const customClassNames = {
    control: () =>
      cn(
        '!bg-background border-1 !border-gray-300 dark:!border-gray-700 focus:!border-red-400 !rounded h-1 focus:!outline-none'
      ),
    menu: () => cn('!bg-background !border-2 !border-gray focus:!border-red-400 !z-[9999]'),
    option: ({ isSelected }: any) =>
      cn(
        'dark:text-white dark:hover:!bg-gray-400 hover:!bg-primary/10 before:!bg-primary/10',
        isSelected ? '!bg-primary' : ''
      ),
    singleValue: () => cn('text-dark dark:text-white'),
    multiValue: () => cn('!bg-primary !text-white'),
    multiValueLabel: () => cn('!text-white')
  };

  const handleSelectAddUser = (e: any) => {
    const recipients: string[] = [];

    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        recipients.push(e[key].value);
      }
    }

    setFormDataAddUser({
      ...formDataAddUser,
      ['user_id']: recipients
    });
  };

  const handleSubmitAddUser = async (e: React.FormEvent) => {
    e.preventDefault();
    let data = formDataAddUser;
    data['note_id'] = noteSelected;
    await NoteService.addUsertoNote(data);
    setopenDialogAddUser(false);

    toast({
      title: 'User added',
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">User added</code>
        </pre>
      )
    });
  };

  const handleSelectSharePrivateNote = (e: any) => {
    const recipients: string[] = [];

    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        recipients.push(e[key].value);
      }
    }

    setformDataSharePrivateNote({
      ...formDataSharePrivateNote,
      ['user_id']: recipients
    });
  };

  const handleSubmitSharePrivateNote = async (e: React.FormEvent) => {
    e.preventDefault();
    let data = formDataSharePrivateNote;
    data['note_id'] = noteSelected;
    const response = await NoteService.sharePrivateNote(data);
    setopenDialogSharePrivateNote(false);
    setNote(response.id);

    toast({
      title: 'User added',
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">User added</code>
        </pre>
      )
    });
  };

  const setNote = (noteId: string) => {
    dispatch(setNoteId(noteId));
  };

  return (
    <div className="flex h-full flex-col">
      <Separator />
      {note ? (
        <div className="flex flex-1 flex-col mt-2">
          <div className="flex items-start p-4">
            <div className="flex items-start gap-4 text-sm">
              <Avatar>
                <AvatarImage
                  src={staffPhotos[note.created_by] ? staffPhotos[note.created_by] :  undefined}
                  className="h-full w-full object-cover"
                />
                <AvatarFallback>
                  {note.author
                    .split(' ')
                    .map((chunk) => chunk[0])
                    .join('')}
                </AvatarFallback>
              </Avatar>
              <div className="flex-col">
                <div className="self-center font-semibold">{note.author}</div>
                {note.resident_id && (
                  <div className="self-center text-xs font-medium">
                    {!crudState.showingStaffNotes
                      ? t('notes.resident') + ': ' + residentNames[note.resident_id]
                      : t('notes.staff') + ': ' + note.staff_id.map((id, index) => <div key={index}>{staffNames[id] || ''}</div>)}
                  </div>
                )}
                {note.category_id && (
                  <div className="self-center text-xs  font-thin">
                    { t('notes.category') + ': '} {categoriesNames[note.category_id]}
                  </div>
                )}
              </div>
            </div>
            <div className="ml-auto flex items-center gap-2">
              <Separator orientation="vertical" className="mx-1 h-6" />
              {note.created_by == authUserId && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      disabled={!note}
                      onClick={() =>
                        setCrudState(
                          NoteHelperService.defineState({
                            isUpdating: crudState.showingStaffNotes ? false : true,
                            showingStaffNotes: crudState.showingStaffNotes,
                            isUpdatingStaffNote: crudState.showingStaffNotes ? true : false,
                            itemId: note.id
                          })
                        )
                      }
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('notes.edit')}</TooltipContent>
                </Tooltip>
              )}
              {!crudState.showingStaffNotes && (
                <Popover>
                  <PopoverTrigger>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button variant="ghost" size="icon" disabled={!note}>
                          <BookOpenCheck className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>{t('notes.whoRead')}</TooltipContent>
                    </Tooltip>
                  </PopoverTrigger>
                  <PopoverContent>
                    <div className="mt-4">
                      <ScrollArea className="h-[150px]">
                        <ul>
                          {note?.readers.map((item: any) => (
                            <li key={item.id} className="mb-1">
                              {staffNames[item.user_id]}
                            </li>
                          ))}
                        </ul>
                      </ScrollArea>
                    </div>
                  </PopoverContent>
                </Popover>
              )}
              {note.visibility == 'private' && (
                <Dialog
                  open={openDialogAddUser}
                  onOpenChange={() => setopenDialogAddUser(openDialogAddUser ? false : true)}
                >
                  <DialogTrigger>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button variant="ghost" size="icon" disabled={!note}>
                          <UserPlus className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>{t('notes.addUser')}</TooltipContent>
                    </Tooltip>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>{t('notes.addUserToThisNote')}</DialogTitle>
                    </DialogHeader>
                    <form onSubmit={handleSubmitAddUser}>
                      <div className="grid gap-4 py-4">
                        <Select
                          isMulti
                          name="colors"
                          className="basic-multi-select"
                          options={noteToOptions}
                          classNamePrefix="select"
                          placeholder="Users"
                          classNames={customClassNames}
                          onChange={handleSelectAddUser}
                        />
                      </div>
                      <DialogFooter>
                        <Button type="submit">{t('notes.Add')}</Button>
                      </DialogFooter>
                    </form>
                  </DialogContent>
                </Dialog>
              )}
              {note.visibility == 'private' && (
                <Dialog
                  open={openDialogSharePrivateNote}
                  onOpenChange={() => setopenDialogSharePrivateNote(openDialogSharePrivateNote ? false : true)}
                >
                  <DialogTrigger>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button variant="ghost" size="icon" disabled={!note}>
                          <ReplyAllIcon className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>{t('notes.answerAsAPrivateNote')}</TooltipContent>
                    </Tooltip>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>{t('notes.answerAsAPrivateNote')}</DialogTitle>
                    </DialogHeader>
                    <form onSubmit={handleSubmitSharePrivateNote}>
                      <div className="grid gap-4 py-4">
                        <Select
                          isMulti
                          name="colors"
                          className="basic-multi-select"
                          options={noteToOptions}
                          classNamePrefix="select"
                          placeholder="Answer to"
                          classNames={customClassNames}
                          onChange={handleSelectSharePrivateNote}
                        />
                      </div>
                      <DialogFooter>
                        <Button type="submit">{t('notes.share')}</Button>
                      </DialogFooter>
                    </form>
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </div>
          <Separator />
          <div className="flex flex-col h-full p-4 pr-0 pb-0 text-sm">
            <div className="flex flex-col h-full">
              <Card
                className={cn('flex col-span-3 border-t-4 border-t-primary ', displayChat ? ' h-[15vh]' : ' h-[75vh]')}
              >
                <CardContent className="flex-grow overflow-auto flex flex-col">
                  <ScrollArea type="always" className="flex-grow">
                    <article className="max-w-[90vh] mt-2" dangerouslySetInnerHTML={{ __html: note.html_content }} />
                    {note.images && note.images.length > 0 && <ImgageGrid images={note.images} />}
                  </ScrollArea>
                </CardContent>
                <div className="flex flex-col">
                  {!crudState.showingStaffNotes && (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <MessageCircle
                          className="mr-2 h-5 w-5 mt-3 cursor-pointer"
                          onClick={() => {
                            setdisplayChat(!displayChat);
                          }}
                        />
                      </TooltipTrigger>
                      <TooltipContent>{!displayChat ? t('notes.displayChat')  : t('notes.hideChat')}</TooltipContent>
                    </Tooltip>
                  )}
                </div>
              </Card>
              {!crudState.showingStaffNotes && <Chat note={note} displayChat={displayChat} />}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="p-8 text-center text-muted-foreground">{t('notes.noNoteSelected')}</div>
        </div>
      )}
    </div>
  );
}
