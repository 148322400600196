import { createOpenAI } from '@ai-sdk/openai';
import { generateText } from 'ai';

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

const openai = createOpenAI({
  apiKey,
  compatibility: 'strict'
});

export const generateTextAction = async (prompt: string) => {
  const result = await generateText({
    model: openai('gpt-4o-mini'),
    messages: [
      {
        role: 'system',
        content:
          'You are an attendant in a nursing home, and you are in charge of conducting the intake interview for new residents. Your objective is to suggest relevant and detailed information in order to complete the profile of the new resident. Do not answer the questions in the prompt. Only provide the answers in the response.'
      },
      { role: 'user', content: prompt }
    ]
  });

  return result.text;
};
