import { axios, nestApi } from '@/common/infrastructure/providers/AxiosContextProvider';
import { IAccount } from '../../domain/signup.domain';

const SignUpService = {
  getInitialData: async (country: string) => {
    let url = '/sign-up/data';
    try {
      const response = await axios.post(url, { country_code: country });
      return response.data;
    } catch (error) {
      throw new Error(`Error getting the information ${error}`);
    }
  },
  getGeoLocationClient: async () => {
    let url = '/localization/ip-info';
    try {
      const response = await nestApi.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  },
  createClient: async (formData: any) => {
    let url = '/client/sign-up';
    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error creating the client';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getClientToken: async (data: { client_id: string; user_id: string }) => {
    let url = '/sign-up/get-client-token';
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error getting the client';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  userNameExists: async (userName: string) => {
    let url = `/user/user-name-exists/${userName}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  updateLogin: async (data: IAccount) => {
    let url = `/user/update-login`;
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getPrice: async (formData: any) => {
    let url = '/subscription/getEssentialPlanPrice';
    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error) {
      throw new Error(`Error getting the price ${error}`);
    }
  },
  subscribeClient: async (formData: any) => {
    let url = `/subscription/getStarted`;

    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  },
  clientUpdate: async (formData: any) => {
    let url = `/client/update/wizard`;
    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
};

export default SignUpService;
