import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogTitle,
    DialogTrigger
} from '@/common/presentation/components/ui/dialog.tsx';
import { Button } from '@/common/presentation/components/ui/button';

import { IAlertDeleteEventDoesNotRepeatProp } from '../../domain/calendar.domain';
import { useTranslation } from 'react-i18next';

import styles from "../pages/styles/index.module.css";


export function AlertDeleteEventDoesNotRepeat({ 
 open, 
 openChange, 
 onDelete 
}: IAlertDeleteEventDoesNotRepeatProp) {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onOpenChange={openChange}>
            <DialogContent className={`${styles.dialogContent} md:max-w-[500px]`}>
                <div className="flex flex-col space-y-4 py-4">
                    <DialogTitle>{t('calendar.alertDeleteEvent.dialogTitle')}</DialogTitle>
                    <DialogDescription>
                        {t('calendar.alertDeleteEvent.dialogDescription')}
                    </DialogDescription>
                </div>
                <DialogFooter>
                    <div className="w-full flex justify-end">
                        <DialogTrigger asChild>
                            <Button type="submit" className="mr-2" onClick={onDelete}>
                                {t('calendar.ok')}
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger asChild>
                            <Button type="button" className='bg-background text-primary dark:text-white hover:text-white'>
                                {t('calendar.cancel')}
                            </Button>

                        </DialogTrigger>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}