import { NewCategoryDialog } from './NewCategoryDialog';
import { UploadDocumentDialog } from './UploadDocumentDialog';

export const Dialogs = () => {
  return (
    <>
      <NewCategoryDialog />
      <UploadDocumentDialog />
    </>
  );
};
