import { Form, FormControl, FormField, FormItem, FormLabel } from '@/common/presentation/components/ui/form';
import { Switch } from '@/common/presentation/components/ui/switch';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as staffConfigurationsActions from '@/modules/staff/presentation/slices/staffConfigurationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  defaultValues: Partial<FormValues>;
}

const formSchema = z.object({

  company: z.boolean().optional(),
  training: z.boolean().optional(),
  other: z.boolean().optional(),
  custom_fields: z.boolean().optional(),
  termination: z.boolean().optional(),
  login_information: z.boolean().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const FormCards = ({ defaultValues }: Props) => {
  const { status } = useSelector((state: RootState) => state.staff.configurations);
  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmit = async (data: FormValues) => {
    const response = await dispatch(
      staffConfigurationsActions.postStaffConfigurations({
       staff_form_cards: data
      })
    );

    if (response.payload !== undefined) {
      toast({
        title: 'Form cards updated',
        variant: 'default'
      });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div>
          <h2 className="text-xl font-bold">Staff form cards</h2>
          <p>Here you can configure the staff form cards that will be displayed in the staff form page.</p>
        </div>

        <Form {...form}>
          <form
            onChange={form.handleSubmit(onSubmit)}
            className="flex flex-col w-full items-start justify-center gap-4"
          >
            <div className="flex flex-col gap-4 w-full">
              <FormField
                control={form.control}
                name="company"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Company</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="training"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Training</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="other"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base"> Other</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="custom_fields"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Custom Fields</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="termination"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Termination</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="login_information"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Login Information</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
