import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedications } from '../../slices/medicationsSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { RootState } from '@/store/store';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Controller, useForm } from 'react-hook-form';
import debounce from 'lodash.debounce';

const SelectWithVirtuoso = ({ onSelect, value, onCreateNew, editValue, isDisabled = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { medications } = useSelector((state: RootState) => state.medications || { medications: [] });
  const { params } = useRouteParams();
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const { control, setValue, watch, setError, clearErrors } = useForm({
    defaultValues: {
      medicationName: editValue || '',
      selectedMedication: value || null
    }
  });

  const medicationName = watch('medicationName');
  const selectedOption = watch('selectedMedication');
  const shouldFetch = medicationName.length >= 3 && !isDisabled;

  const debouncedFetchFilteredMedications = useCallback(
    debounce(async (filter) => {
      if (filter.length >= 3 && !isDisabled) {
        try {
          await dispatch(fetchMedications({ locationId: params.l, filter })).unwrap();
        } catch (error) {
          console.error('Error fetching medications:', error);
        }
      }
    }, 300),
    [dispatch, params.l, isDisabled]
  );

  useEffect(() => {
    if (shouldFetch) {
      debouncedFetchFilteredMedications(medicationName);
    } else {
      setIsOpen(false);
    }
  }, [medicationName, debouncedFetchFilteredMedications, shouldFetch]);

  const handleSearch = (e) => {
    if (isDisabled) return;
    const value = e.target.value;
    setValue('medicationName', value);
    if (value.length < 3) {
      clearErrors('medicationName');
      setIsOpen(false);
    } else {
      setError('medicationName', { type: 'manual', message: t('medications.medicationForm.searchPlaceholder') });
      setIsOpen(true);
    }
  };

  const handleSelectOption = useCallback(
    (option) => {
      if (isDisabled) return;
      setValue('selectedMedication', option.id);
      setValue('medicationName', option.name);
      onSelect?.(option);
      setIsOpen(false);
      inputRef.current?.blur();
    },
    [onSelect, setValue, isDisabled]
  );

  const handleClearSelection = (e) => {
    if (isDisabled) return;
    e.stopPropagation();
    setValue('selectedMedication', null);
    setValue('medicationName', '');
    onSelect?.(null);
    setIsOpen(false);
    inputRef.current?.focus();
    onCreateNew(false);
  };

  const handleCreateNew = () => {
    if (isDisabled) return;
    console.log(medicationName);
    onCreateNew(medicationName);
    setIsOpen(false);
  };

  const getContainerHeight = () => {
    return medications.length > 3 ? 'h-48' : `h-${Math.max(medications.length * 8, 12)}`;
  };

  return (
    <div className="relative">
      <div className="flex items-center w-full border border-input rounded-md focus:border-primary">
        <Controller
          name="medicationName"
          control={control}
          render={({ field }) => (
            <Input
              ref={inputRef}
              type="text"
              placeholder={t('medications.medicationForm.searchPlaceholder')}
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e);
                handleSearch(e);
              }}
              onFocus={() => {
                if (medicationName.length >= 3 && !selectedOption && !isDisabled) {
                  setIsOpen(true);
                }
              }}
              className="flex h-10 w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
              disabled={isDisabled}
            />
          )}
        />
        {medicationName && !isDisabled && (
          <Button
            onClick={handleClearSelection}
            className="absolute right-0 mr-2 p-1 bg-transparent hover:bg-transparent border-none shadow-none text-gray-500"
            variant="outline"
          >
            <X className="w-4 h-4" />
          </Button>
        )}
      </div>
      {isOpen && !isDisabled && (
        <div className="absolute z-[999999] top-full left-0 w-full bg-white border-gray-300 border-t-0 rounded-b-md shadow-md">
          <div className={`options-container ${getContainerHeight()} overflow-y-auto`} style={{ zIndex: 1000 }}>
            {medications.length > 0 ? (
              <Virtuoso
                totalCount={medications.length}
                itemContent={(index) => (
                  <div
                    key={medications[index].id}
                    className="px-2 z-[999999] py-1 cursor-pointer transition-colors hover:bg-gray-200"
                    onMouseDown={() => handleSelectOption(medications[index])}
                  >
                    {medications[index].name}
                  </div>
                )}
              />
            ) : (
              <div
                className="px-2 z-[999999] py-1 cursor-pointer transition-colors hover:bg-gray-200"
                onMouseDown={handleCreateNew}
              >
                {t('medications.medicationForm.createMedication', { medicationName })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(SelectWithVirtuoso);
