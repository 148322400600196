import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { PlanOfCareHistoryActions } from './PlanOfCareHistoryActions';

export const HistoryTableHistoryTableColumns: ColumnDef<any>[] = [
  {
    header: 'Date',
    cell: ({ row }) => format(new Date(row.original.created_at), 'MM/dd/yyyy hh:mm a')
  },
  {
    header: 'Generated By',
    cell: ({ row }) => {
      return (
        <div>
          {row.original.created_by?.first_name} {row.original.created_by?.last_name}
        </div>
      );
    }
  },
  {
    accessorKey: 'actions',
    header: () => {
      return <div className="w-12">Actions</div>;
    },
    cell: ({ row }) => <PlanOfCareHistoryActions id={row.original.id} />
  }
];
