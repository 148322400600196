import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Separator } from '@/common/presentation/components/ui/separator';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { FileClock } from 'lucide-react';
import { RefObject, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useEmarPrnHistory } from '../../infrastructure/hooks/UseEmarPrnHistory';
import { usePrnDialog } from '../../infrastructure/hooks/UsePrnDialog';

interface MedicationCardProps {
  className?: string;
  data: any;
  children?: React.ReactNode;
  setRef?: (ref: RefObject<HTMLDivElement>) => void;
  selectedDate: Date;
  // selectedTime: string;
  // locationId?: string;
  // residentId?: string;
}

const responsibleOfAdministration = (name: string) => {
  let key;

  switch (name) {
    case 'Controlled medication':
      key = {
        bg: 'bg-blue-400/50',
        text: 'text-blue-950'
      };
      break;
    case 'Hospice Care kit':
      key = {
        bg: 'bg-teal-500/50',
        text: 'text-teal-950'
      };
      break;
    case 'Administered by nurse':
      key = {
        bg: 'bg-pink-500/50',
        text: 'text-pink-950'
      };
      break;

    default:
      key = {
        bg: 'bg-gray-400/50',
        text: 'text-gray-950'
      };
      break;
  }
  return key;
};

export const EmarPrnCard = ({ className, data, setRef }: MedicationCardProps) => {
  console.log({ data });

  const cardRef = useRef<HTMLDivElement>(null);

  const { onOpen } = usePrnDialog();
  const { onOpen: onOpenEmarPrnHistory } = useEmarPrnHistory();
  const { locationId } = useParams();

  useEffect(() => {
    if (cardRef.current) {
      setRef?.(cardRef);
    }
  }, [cardRef]);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onOpenEmarPrnHistory(data);
  };

  return (
    <Card
      className={cn('flex flex-col hover:cursor-pointer', className)}
      ref={cardRef}
      onClick={() => onOpen(data, locationId!)}
    >
      <CardContent className="space-y-2">
        <CardHeader className="px-0">
          <div className="flex justify-between gap-2">
            <Badge variant="outline" className="text-md font-bold border-blue-500 text-blue-500">
              As Needed
            </Badge>

            {data?.emar_medication_prns?.length > 0 && (
              <Button variant="ghost" size={'icon'} className="size-8" onClick={handleOpen}>
                <FileClock className="size-6" />
              </Button>
            )}
          </div>
          <CardTitle className="text-xl font-bold">{data?.medication?.name}</CardTitle>
        </CardHeader>
        <Separator />
        <div className="text-md">
          <p>
            RX #: <span className="font-bold">{data?.rx || 'Not provided'}</span>
          </p>
          <p>
            Start Date:{' '}
            <span className="font-bold">
              {data?.cycle_start_date ? format(new Date(data?.cycle_start_date), 'MM/dd/yyyy') : 'Not provided'}
            </span>
          </p>
          <p>
            Purpose of Medication: <span className="font-bold">{data?.physician || 'Not provided'}</span>
          </p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Instructions</h3>
          <p className="text-md">{data?.instruction || 'No instructions provided'}</p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Special Instructions</h3>
          <p className="text-md">{data?.special_instructions || 'No special instructions provided'}</p>
        </div>
      </CardContent>
      <CardFooter className="mt-auto w-full flex flex-col gap-2">
        {data?.responsible_of_administration && (
          <>
            <Separator />
            <p
              className={cn(
                'w-full p-2 text-md font-bold text-center rounded-md',
                responsibleOfAdministration(data?.responsible_of_administration?.name).bg,
                responsibleOfAdministration(data?.responsible_of_administration?.name).text
              )}
            >
              {data?.responsible_of_administration?.name || 'No responsible of administration provided'}
            </p>
          </>
        )}
      </CardFooter>
    </Card>
  );
};
