import { Label } from '@/common/presentation/components/ui/label';
import ServiceCard from '../components/ServiceCard';
import ServiceModal from '../components/ServiceModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetServices } from '../slices/SubscriptionSlice';
import { FormDataProvider } from '../../infraestructure/providers/MarketPlaceProvider';
import { getLocations } from '@/modules/locations/presentation/slices/locationsSlice';

const MarketPlacePage = () => {
  const subscription = useSelector((state: any) => state.subscription);
  const { services, subscriptions } = subscription;
  const dispatch = useDispatch();

  useEffect(() => {
    getServices();
    dispatch<any>(getLocations());
  }, []);

  const getServices = async () => {
    await dispatch<any>(fetchGetServices());
  };

  const isSubscribed = (serviceKey: string) => {
    return Object.keys(subscriptions).includes(serviceKey);
  };

  return (
    <>
      <FormDataProvider>
        <div className="mt-2">
          <div className="flex flex-col gap-1">
            <Label className="font-bold text-2xl">Market Place</Label>
            <Label className="text-sm font-normal">
              Explore unlimited possibilities: Elevate your experience with our exclusive subscription services
            </Label>
          </div>
          <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 rounded-lg border p-4 mt-5">
            {Object.values(services).map((service: any) => (
              <ServiceCard
                key={service.id}
                serviceInfo={service}
                isSubscribed={isSubscribed(service.key)}
              />
            ))}
          </div>
          <ServiceModal/>
        </div>
      </FormDataProvider>
    </>
  );
};

export default MarketPlacePage;
