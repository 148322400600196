import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { NotesEditor } from '@/common/presentation/components/Notes/NotesEditor';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  className?: string;
}

const formSchema = z.object({
  content: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const OtherForm = ({ className }: Props) => {
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMember);
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});

  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues } as FormValues
  });

  const onSubmitForm = async (data: FormValues) => {
    const response = dispatch(
      staffMemberFormActions.updateStaffnotesForm({
        staff_member_id: staffMember?.id,
        ...data
      })
    );

    if ((await response).payload) {
      toast({
        description: 'Notes member staff updated successfully'
      });
    }
  };

  useEffect(() => {
    if (staffMember) {
      setDefaultValues({
        content: staffMember.user_notes?.content || ''
      });
    }
  }, [staffMember]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Notes User">
            <div className="col-span-4">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmitForm)} className="space-y-8">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                      <FormField
                        control={form.control}
                        name="content"
                        render={({ field }) => {
                          return (
                            <FormItem>
                              <FormLabel></FormLabel>
                              <FormControl>
                                <NotesEditor
                                  className="min-h-auto h-[200px] pb-10"
                                  value={field.value}
                                  onChange={(value) => {
                                    field.onChange(value);
                                  }}
                                  disabled={staffFormStatus === 'loading'}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                  </div>
                  {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                    <>
                      <Separator className="col-span-full" />
                      <Button type="submit" className="w-64" disabled={staffFormStatus === 'loading'}>
                        {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                        Update
                      </Button>
                    </>
                  )}
                </form>
              </Form>
            </div>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
