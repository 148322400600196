import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import paymentsService from '../../infraestructure/services/PaymentsService';
import { PaymentMethodI } from '../../domain/payments.domain';


interface PaymentState {
  paymentMethods: PaymentMethodI[];
  errorMessage: string;
  loading: boolean;
}

const initialState: PaymentState = {
  paymentMethods: [],
  errorMessage: '',
  loading: false
};

export const fetchPaymentMethods = createAsyncThunk('payment/fetchPaymentMethods', async () => {
  const response = await paymentsService.getPaymentMethods();
  return response.data;
});

export const fetchSetPaymentMethodAsDefault = createAsyncThunk('payment/fetchSetPaymentMethodAsDefault', async (paymentMethodId: string) => {
  const response = await paymentsService.setPaymentAsDefault(paymentMethodId);
  return response.data;
});

export const fetchCreatePaymentMethod = createAsyncThunk('payment/fetchCreatePaymentMethod', async (cardEncryptedInfo: string) => {
  const response = await paymentsService.createPaymentMethod(cardEncryptedInfo);
  return response.data;
});

const PaymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentMethods(state, action) {
      state.paymentMethods = action.payload;
    },
    addPaymentMethod(state, action) {
      state.paymentMethods.push(action.payload);
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethods = Object.values(action.payload.payment_methods);
    });
    
    builder
    .addCase(fetchSetPaymentMethodAsDefault.fulfilled, (state, action) => {
      const { payment_method_id } = action.payload;
      state.paymentMethods = state.paymentMethods.map((paymentMethod) => {
        if (paymentMethod.id === payment_method_id) {
          return { ...paymentMethod, is_default: true };
        } else {
          return { ...paymentMethod, is_default: false };
        }
      });
    })
    .addCase(fetchCreatePaymentMethod.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
    })
    .addCase(fetchCreatePaymentMethod.fulfilled, (state, action) => {
        state.errorMessage = '';
        state.loading = false;
    })
    .addCase(fetchCreatePaymentMethod.rejected, (state, action) => {
      state.errorMessage = action.error.message ?? '';
      state.loading = false;
    })
  }
});

export const { setPaymentMethods, addPaymentMethod, setErrorMessage } = PaymentSlice.actions;
export default PaymentSlice.reducer;
