import { Button } from '@/common/presentation/components/ui/button';
import { Label } from '@/common/presentation/components/ui/label';
import { Separator } from '@/common/presentation/components/ui/separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from '@/common/presentation/components/ui/sheet';
import { generateTextAction } from '@/modules/residents/infrastructure/services/AiService';
import { Loader2, Sparkles } from 'lucide-react';
import { useMemo, useState } from 'react';

interface SuggestionSheetProps {
  label: string;
  defaultValue?: string[];
  addCompletitionToTextarea: (value: string) => void;
}

export const SuggestionSheet = ({ label, defaultValue = [], addCompletitionToTextarea }: SuggestionSheetProps) => {
  const items = useMemo(() => defaultValue, [defaultValue]);
  const [aiItems, setAiItems] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const prompt = useMemo(() => {
    return `
   Generate suggestions to complete the text field in the form for evaluating a potential new resident of a senior care center.

    I will provide you with the following information:

        •	Categories:
    ${label}
        •	Current items:
    ${items.join('\n')}
        •	Previous AI suggestions:
    ${aiItems.join('\n')}

    Instructions:

        1.	Generate a list of 5 new suggestions to complete the ${label} section of the form.
        2.	The suggestions should be separated by commas.
        3.	Ensure that the suggestions are different from the previous AI suggestions.
        4.  Add a . at the end of each suggestion.
    `;
  }, [items]);

  const generateText = async () => {
    setIsLoading(true);
    const result = await generateTextAction(prompt);
    setAiItems(result.split(',').map((item) => item.trim()));
    setIsLoading(false);
  };

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant={'outline'} className="rounded-full h-8">
          <Sparkles className="size-4 mr-2" />
          <span>Suggestions</span>
        </Button>
      </SheetTrigger>
      <SheetContent className="overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{label} Suggestions</SheetTitle>
          <SheetDescription>
            There are some suggestions for you to complete this form. Please check the suggestions and add them to the
            form.
          </SheetDescription>
        </SheetHeader>

        <Separator className="my-4" />

        <div className="flex flex-col gap-4 mt-4">
          {items.map((value, index) => (
            <div
              key={index}
              className="flex flex-row gap-4 cursor-pointer"
              onClick={() => addCompletitionToTextarea(value)}
            >
              <div className="w-full border-2 border-gray-300 rounded-md p-2">
                <p className="text-sm font-bold">{value}</p>
              </div>
            </div>
          ))}
        </div>

        {aiItems.length > 0 && (
          <div>
            <Separator className="my-4" />
            <div className="flex flex-col gap-4 mt-4">
              <Label className="text-lg font-bold">AI Suggestions</Label>
              {aiItems.map((value, index) => (
                <div
                  key={index}
                  className="flex flex-row gap-4 cursor-pointer"
                  onClick={() => addCompletitionToTextarea(value)}
                >
                  <div className="w-full rounded-lg p-[3px]  bg-gradient-to-r from-[#6EE7B7] via-[#3B82F6] to-[#9333EA]">
                    <div className="rounded-md bg-white">
                      <p className="text-sm font-bold p-2">{value}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <Button
          variant={'outline'}
          className="mt-4"
          onClick={() => {
            generateText();
          }}
        >
          {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <Sparkles className="size-4 mr-2" />}
          Generate More Suggestions With AI
        </Button>
      </SheetContent>
    </Sheet>
  );
};
