import React, { useState, useRef } from 'react';
import { Ellipsis } from 'lucide-react'; // Importa el ícono de Elipsis de Lucide

const SpeedDial = ({ mainIcon, buttons }) => {
  const [isOpen, setIsOpen] = useState(false);
  const speedDialRef = useRef(null);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const mainIconStyle = {
    width: '32px', // Ajusta el tamaño del icono principal según sea necesario
    height: '32px',
    borderRadius: '50%', // Asegura que el icono principal sea redondeado
    backgroundColor: 'transparent', // Quita el fondo del icono principal
    color: 'var(--color-primary)', // Utiliza el color primario para el icono
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  };

  return (
    <div
      ref={speedDialRef}
      className="relative inline-block group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={mainIconStyle} onClick={() => setIsOpen(!isOpen)}>
        {mainIcon}
      </div>
      {isOpen && (
        <div className="absolute right-8 top-0 flex flex-row space-x-2 transform -translate-y-full scale-0 group-hover:translate-y-0 group-hover:scale-100 transition-transform duration-300">
          {buttons.map((button, index) => (
            <button
              key={index}
              className="flex items-center justify-center w-8 h-8 bg-primary text-white rounded-full hover:bg-primary-focus hover:shadow-md transition-colors duration-300 cursor-pointer"
              onClick={button.onClick}
            >
              {button.icon}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SpeedDial;
