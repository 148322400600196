import { create } from 'zustand';

type Data = {
  category: string;
  parent_document_id?: string | null;
};

type UploadDocumentState = {
  isOpen: boolean;
  data: Data | null;
  onOpen: (data: Data) => void;
  onClose: () => void;
};

export const useUploadDocument = create<UploadDocumentState>((set) => ({
  isOpen: false,
  data: null,
  onOpen: (data: Data) => set({ isOpen: true, data }),
  onClose: () => set({ isOpen: false, data: null })
}));
