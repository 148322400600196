import { ModuleI } from "@/modules/subscription/domain/makerPlace.domain";


export const calculatePrice = (
    serviceModule: ModuleI, 
    clientBedsNumber: number | null = null,
    countryCode: string | null = null
  ) => {
    
    const filteredPrices = serviceModule.prices
      .filter((price) => price.country?.code == null || price.country?.code === countryCode)
      .filter(
        (price) =>
          ( clientBedsNumber === null) || 
          (
            (price.min_beds == null || clientBedsNumber >= price.min_beds) &&
            (price.max_beds == null || clientBedsNumber <= price.max_beds)
          )
      );

    const initialAccumulator = { price: 0, ranges: { max_beds: 0, min_beds: 0 } };
  
    const priceModule = filteredPrices.reduce((acc, price) => {
      const priceValue = parseFloat(price.price);
      acc.price += priceValue;
      acc.ranges.min_beds = price.min_beds ?? acc.ranges.min_beds;
      acc.ranges.max_beds = price.max_beds ?? acc.ranges.max_beds;
      return acc;
    }, initialAccumulator);
  
    return priceModule;
  };
  