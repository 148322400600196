import { AssessmentNavbar } from "../components/assessment/AssessmentNavbar";
import { useEffect, useState } from "react";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { AssessmentQuestion } from "../components/assessment/AssessmentQuestions";
import { AssessmentFacilities } from "../components/assessment/AssessmentFacilities";

export function AssessmentPage() {
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const [step, setStep] = useState("questions");

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    return(
        <div style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "auto 1fr" }}>
            <AssessmentNavbar />
            
            {
                step === 'facilities' && <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                    <AssessmentFacilities />
                </div>
            }

            {
                step === 'questions' && <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                    <AssessmentQuestion />
                </div>
            }

            {
                step === 'temperature' && <div>Temperature</div>
            }

        </div>
    );
}
