import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PropsI {
  label: string;
  name?: string;
  type?: 'text' | 'password' | 'number' | 'email';
  value?: string;
  disabled?: boolean;
  errors?: string | false;
  placeHolder?: string;
  onChange?: (fieldName: string, value: string) => void;
  children?: React.ReactNode;
  className?: string;
  reference?: any;
  requiredLabel?: boolean;
  autoComplete?: string;
}

const InputField: React.FC<PropsI> = ({
  label,
  name = '',
  type,
  value,
  placeHolder,
  onChange,
  disabled = false,
  errors = false,
  className = '',
  autoComplete = 'off',
  children,
  requiredLabel = false,
  reference
}) => {
  const { t } = useTranslation();
  return (
    <div className={cn('space-y-2', className)}>
      <Label htmlFor={name} className={cn('text-sm font-medium', errors && 'text-red-600')}>
        {label}
        {requiredLabel && (
          <>
            {' '}
            <span className={cn(errors ? 'text-red-600' : 'text-gray-400')}>({t('signUp.required')})</span>
          </>
        )}
      </Label>
      {children
        ? children
        : onChange !== undefined && (
            <Input
              name={name}
              type={type}
              ref={reference ? reference : null}
              placeholder={placeHolder}
              disabled={disabled}
              value={value}
              autoComplete={autoComplete}
              onChange={(e) => onChange(name, e.target.value)}
              className={cn(errors && 'border-red-600')}
            />
          )}
    </div>
  );
};

export default InputField;
