import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITrackerMenstrutation, ITrackerMenstrutationDeleteDTO, ITrackerMenstrutationDTO, ITrackerMenstrutationRetrieveDTO, ITrackerMenstrutationUpdateDTO } from '../components/menstruation/TrackerMenstruationMockup';
import TrackerMenstruationService from '../../infrastructure/services/TrackerMenstruationService';

interface ITrackerCleaningState {
    menstruations: ITrackerMenstrutation[];
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
}

const initialState: ITrackerCleaningState = {
    menstruations: [],
    status: 'idle',
    error: null
};

export const getMenstruations = createAsyncThunk("trackerMenstruation/getMenstruations", async (body: ITrackerMenstrutationRetrieveDTO) => {
  const response = await TrackerMenstruationService.getMenstruations(body);
  return response.data;
});

export const createMenstruation = createAsyncThunk("trackerMenstruation/createMenstruation", async (body: ITrackerMenstrutationDTO) => {
    const response = await TrackerMenstruationService.createMenstruation(body);
    return response.data;
});

export const updateMenstruation = createAsyncThunk("trackerMenstruation/updateMenstruation", async (body: ITrackerMenstrutationUpdateDTO) => {
    const response = await TrackerMenstruationService.updateMenstruation(body);
    return response.data;
});

export const deleteMenstruation = createAsyncThunk("trackerMenstruation/deleteMenstruation", async (body: ITrackerMenstrutationDeleteDTO) => {
    const response = await TrackerMenstruationService.deleteMenstruation(body);
    return response.data;
});

const trackerMenstruation = createSlice({
  name: 'trackerMenstruation',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getMenstruations.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getMenstruations.fulfilled, (state, action) => {
      state.status = 'idle';
      state.menstruations = action.payload.result;
    });

    builder.addCase(getMenstruations.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});

export default trackerMenstruation.reducer;
