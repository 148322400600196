import { Button } from '@/common/presentation/components/ui/button';
import { cn } from '@/lib/utils';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

interface TimePickerProps {
  onChange: (time: string) => void;
  value: string;
}

export const TimePicker = ({ onChange, value }: TimePickerProps) => {
  const [time, setTime] = useState(value);
  const [isReady, setIsReady] = useState(false);

  const [hour, minute] = time.split(':');
  const [period, setPeriod] = useState<AmPmType>(convert24to12(hour)[1] as AmPmType);

  const handleTimeChange = (value: number, type: 'hour' | 'minute') => {
    if (isReady) {
      if (type === 'hour') {
        setTime(`${convert12to24(value, period)}:${minute}`);
      } else {
        setTime(`${hour}:${value}`);
      }
    }
  };

  const handlePeriodChange = (value: AmPmType) => {
    if (isReady) {
      setTime(`${convert12to24(hour, value)}:${minute}`);
      setPeriod(value);
    }
  };

  useEffect(() => {
    !isReady && setIsReady(true);

    if (isReady) {
      onChange?.(time);
    }
  }, [onChange, time]);

  return (
    <>
      <div className="rounded-md py-4 w-full flex flex-row justify-center items-center gap-2">
        <TimeInput onChange={(value) => handleTimeChange(value, 'hour')} max={12} min={1} value={convert24to12(hour)} />
        <span className="text-2xl">:</span>
        <TimeInput onChange={(value) => handleTimeChange(value, 'minute')} value={+minute} />
        <Period onChange={(value) => handlePeriodChange(value)} period={period} />
      </div>
    </>
  );
};

interface TimeInputProps {
  max?: number;
  min?: number;
  onChange: (value: number) => void;
  value: number | [number, AmPmType];
}

const TimeInput = ({ max = 59, min = 0, onChange, value = 0 }: TimeInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [time, setTime] = useState<number>(Array.isArray(value) ? value[0] : value);

  const formatTime = (value: number) => {
    return value < 10 ? `0${value}` : value;
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (inputRef.current?.value.length === 3 && inputRef.current?.value.startsWith('0')) {
      inputRef.current.value = inputRef.current.value.slice(1);
    }

    setTime(value);
  };

  const incrementTime = () => {
    setTime((prev) => {
      const newValue = prev + 1;
      return newValue <= max ? newValue : min;
    });
  };

  const decrementTime = () => {
    setTime((prev) => {
      const newValue = prev - 1;
      return newValue >= min ? newValue : max;
    });
  };

  useEffect(() => {
    onChange?.(time);
  }, [time]);

  return (
    <>
      <div className="bg-muted h-[90px] rounded-md flex flex-col items-center justify-center hover:bg-primary/10">
        <ChevronUp className="p-1 hover:cursor-pointer" onClick={incrementTime} role="button" />
        <input
          ref={inputRef}
          type="number"
          inputMode="numeric"
          min={min}
          max={max}
          className="w-12 bg-transparent text-center text-xl focus-visible:outline-0"
          value={formatTime(time)}
          onChange={handleTimeChange}
        />
        <ChevronDown className="p-1 hover:cursor-pointer" onClick={decrementTime} role="button" />
      </div>
    </>
  );
};

interface PeriodProps {
  period?: AmPmType;
  onChange: (period: AmPmType) => void;
}

const Period = ({ period = 'am', onChange }: PeriodProps) => {
  const periods = ['am', 'pm'];
  const buttonCommonClass = 'w-16 bg-muted/50 text-black text-xs rounded-md hover:bg-primary/40 hover:text-white';

  const getButtonClass = (p: string) => {
    return cn(buttonCommonClass, period === p && 'bg-primary text-white');
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        {periods.map((p) => (
          <Button key={p} className={getButtonClass(p)} onClick={() => onChange(p as AmPmType)}>
            {p.toUpperCase()}
          </Button>
        ))}
      </div>
    </>
  );
};

export type AmPmType = 'am' | 'pm';

const convert12to24 = (hour12: string | number, amPm: AmPmType): number => {
  let hour24 = Number(hour12);

  if (amPm === 'am' && hour24 === 12) {
    hour24 = 0;
  } else if (amPm === 'pm' && hour24 < 12) {
    hour24 += 12;
  } else if (amPm === 'am' && hour24 > 12) {
    hour24 -= 12;
  }

  return hour24;
};

const convert24to12 = (hour24: string | number): [number, AmPmType] => {
  const hour12 = Number(hour24) % 12 || 12;
  return [hour12, Number(hour24) < 12 ? 'am' : 'pm'];
};
