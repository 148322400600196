import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { InitialStateI } from '../../domain/dashboard.domain';
import DashboardService from '../../infraestructure/services/DashboardService';
import { NotesStructureI } from '@/modules/notes/domain/note.domain';

const initialNotes: NotesStructureI = {
  result: '',
  payload: [],
  meta: []
};

const initialState: InitialStateI = {
  urgentNotes: initialNotes,
  messagesForYou: initialNotes,
  residentAlerts: {
    status: 'loading',
    data: []
  },
  administrationAlerts: {
    status: 'loading',
    data: []
  }
};

export const fetchResidentsAlert = createAsyncThunk('alerts/residents', async (location: string) => {
  try {
    const response = await DashboardService.getResidentsAlert(location);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchAdministrationAlert = createAsyncThunk('alerts/administration', async (location: string) => {
  try {
    const response = await DashboardService.getAdministrationAlert(location);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchSpeedTest = createAsyncThunk('alerts/speedTest', async (date: any) => {
  try {
    const response = await DashboardService.getSpeedTest(date);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchSystemTest = createAsyncThunk('alerts/systemTest', async (date: any) => {
  try {
    const response = await DashboardService.getSystemTest(date);
    return response;
  } catch (error) {
    throw error;
  }
});

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setUrgentNotes: (state, action) => {
        state.urgentNotes = action.payload;
    },
    setMessagesForYou(state, action) {
      state.messagesForYou = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResidentsAlert.pending, (state, action) => {
      state.residentAlerts = {
        status: 'loading',
        data: []
      };      
    });
    builder.addCase(fetchResidentsAlert.fulfilled, (state, action) => {
      state.residentAlerts = {
        status: 'idle',
        data: action.payload
      };      
    });
    builder.addCase(fetchAdministrationAlert.pending, (state, action) => {
      state.administrationAlerts = {
        status: 'loading',
        data: []
      };      
    });
    builder.addCase(fetchAdministrationAlert.fulfilled, (state, action) => {
      state.administrationAlerts = {
        status: 'idle',
        data: action.payload
      };      
    });
  }
});

export const { setUrgentNotes, setMessagesForYou } = DashboardSlice.actions;
export default DashboardSlice.reducer;
