import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { AppDispatch, RootState } from '@/store/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getResidentsByLocation } from '@/modules/residents/presentation/slices/residentsSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import Icon from '@/common/presentation/components/layout/Banner/components/Icon';
import { UsersRound } from 'lucide-react';

interface Props {
  residentId: string | undefined;
  locationId: string | undefined;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const ResidentSelector = ({ residentId, locationId, onChange, placeholder, disabled }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);

  const getResidents = async () => {
    const response: any = await dispatch(
      getResidentsByLocation(locationId === 'all-locations' ? undefined : locationId)
    );
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
  };

  const onSelectResident = (id: string) => {
    onChange(id);
  };

  useEffect(() => {
    getResidents();
  }, [dispatch]);

  return (
    <>
      <Select onValueChange={onSelectResident} defaultValue={residentId}>
        <SelectTrigger className="w-full py-2 h-12" disabled={disabled}>
          {status === 'loading' ? (
            <div className="w-full py-2 flex items-center gap-2">
              <Skeleton className="size-6 rounded-full bg-primary/20" />
              <Skeleton className="h-6 w-1/2 rounded-full bg-primary/20" />
            </div>
          ) : (
            <SelectValue placeholder={placeholder} />
          )}
        </SelectTrigger>
        <SelectContent>
          {status === 'loading' ? (
            <div className="w-full py-2 flex items-center gap-2">
              <Skeleton className="size-6 rounded-full bg-primary/20" />
              <Skeleton className="h-6 w-1/2 rounded-full bg-primary/20" />
            </div>
          ) : (
            <>
              {residents.map((resident) => (
                <SelectItem key={resident.id} value={resident.id}>
                  <div className="w-full flex items-center gap-2">
                    <Avatar className="size-8">
                      <AvatarImage src={resident.image_url} alt="User Image" className="size-full object-cover" />
                      <AvatarFallback className="bg-secondary text-sm rounded-md">
                        <UsersRound className="size-8" />
                      </AvatarFallback>
                    </Avatar>
                    {resident.first_name} {resident.last_name}
                  </div>
                </SelectItem>
              ))}
            </>
          )}
        </SelectContent>
      </Select>
    </>
  );
};
