import { Button } from "@/common/presentation/components/ui/button";
import { Loader } from "lucide-react";
import { useRef, useState } from "react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from "@/common/presentation/components/ui/use-toast.ts";

interface IProps {
  generateReportFunction: () => Promise<boolean|string>;
}
const DirectReportButton: React.FC<IProps> = ({ generateReportFunction }) => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [reportUrl, setReportUrl] = useState<string>('');
  const openPdf = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const handleOpenReport = () => {
    setIsGenerating(false);
    window.open(reportUrl, '_blank');
  };

  const generateReport = async () => {
    try {
      setIsGenerating(true);
      const response = await generateReportFunction();

      console.log("Response: ", response);
      
      if (typeof response === 'string' && response !== '') {
        // Si la respuesta es una URL, permitir que el botón de apertura se muestre
        openPdf.current?.classList.remove('hidden');
        setReportUrl(response); // No es necesario el `toString()`, ya que es un string
        toast({
          description: t('form.disablePopUpBlocker'),
          className: 'bg-green-500 text-white'
        });
      } else if (response === true) {
        console.log("Reporte generado exitosamente");
      } else if (response === false) {
        console.error("Error al generar el reporte");
      } else {
        console.error("Respuesta inesperada: ", response);
      }
    } catch (error) {
      console.error('Error generating report:', error);
    } finally {
      setIsGenerating(false);
    }
};

  return (
    <>
      <div className="w-full lg:w-auto mt-1 lg:mt-0">
        <Button className="dark:text-white w-full" type="submit" disabled={isGenerating} onClick={generateReport}>
          {isGenerating && <Loader className="mr-2 h-4 w-4 animate-spin" />}
          {isGenerating ? t('form.generatingReport') : t('form.generateReport')}
        </Button>
      </div>
      <div>
        <Button variant="link" className="pl-0 hidden" ref={openPdf} onClick={handleOpenReport}>
          { t('form.didNotOpenReport') }
        </Button>
      </div>
    </>
  );
};

export default DirectReportButton;
