import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerSeizureService from '../../infrastructure/services/TrackerSeizureService';
import { ITrackerSeizure, ITrackerSeizureDeleteDTO, ITrackerSeizureDTO, ITrackerSeizureRetrieveDTO, ITrackerSeizureUpdateDTO } from '../components/seizure/TrackerSeizureMockup';

interface ITrackerCleaningState {
    seizures: ITrackerSeizure[];
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
}

const initialState: ITrackerCleaningState = {
    seizures: [],
    status: 'idle',
    error: null
};

export const getSeizures = createAsyncThunk("trackerSeizure/seizures", async (body: ITrackerSeizureRetrieveDTO) => {
  const response = await TrackerSeizureService.getSeizures(body);
  return response.data;
});

export const createSeizure = createAsyncThunk("trackerSeizure/createSeizure", async (body: ITrackerSeizureDTO) => {
    const response = await TrackerSeizureService.createSeizure(body);
    return response.data;
});

export const updateSeizure = createAsyncThunk("trackerSeizure/updateSeizure", async (body: ITrackerSeizureUpdateDTO) => {
    const response = await TrackerSeizureService.updateSeizure(body);
    return response.data;
});

export const deleteSeizure = createAsyncThunk("trackerSeizure/deleteSeizure", async (body: ITrackerSeizureDeleteDTO) => {
    const response = await TrackerSeizureService.deleteSeizure(body);
    return response.data;
});

const trackerSeizure = createSlice({
  name: 'trackerSeizure',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getSeizures.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getSeizures.fulfilled, (state, action) => {
      state.status = 'idle';
      state.seizures = action.payload.result;
    });

    builder.addCase(getSeizures.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});

export default trackerSeizure.reducer;
