import { cn } from '@/lib/utils';
import React from 'react';
import Select, { MultiValue } from 'react-select';

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  name: string;
  options: IOption[];
  onChange: (value: any) => void;
  selectedOptions?: IOption[] ;
  customMessage?: string | null;
  placeHolder?: string | null;
  className?: string;
}

const MultiSelect: React.FC<IProps> = ({ 
    name, 
    options, 
    onChange, 
    selectedOptions, 
    customMessage = null,
    placeHolder = null,
    className
}) => {

  const defaultClasses = {
    control: () =>
      cn(
        '!bg-background !border !border-gray-300 dark:!border-gray-700 !rounded-md focus:!outline-none !shadow-none !overflow-hidden !min-h-10'
      ),
    menu: () => cn('!bg-background !border !border-gray !z-[999999]'),
    option: ({ isSelected , isFocused}: any) =>
      cn(
        'dark:!text-white dark:hover:!bg-primary/60 hover:!bg-primary/60',
        isSelected ? '!bg-primary text-white' : isFocused ? '!bg-primary/10' : ''
      ),
    singleValue: () => cn('text-dark dark:text-white'),
    multiValue: () => cn('!bg-primary !text-white'),
    multiValueLabel: () => cn('!text-white')
  };

  const customNoOptionsMessage = () => {
    return customMessage ?? 'No options';
  };

  const onChangeDefault = (selected: MultiValue<IOption>) => {
    onChange(Array.from(selected));
  };

  return (
    <div className={cn('w-full', className)}>
      <Select
        isMulti
        classNames={defaultClasses}
        placeholder={placeHolder}
        isSearchable
        name={name}
        options={options}
        onChange={onChangeDefault}
        isClearable={true}
        closeMenuOnSelect={false}
        value={selectedOptions}
        noOptionsMessage={customNoOptionsMessage}
      />
    </div>
  );
};

export default MultiSelect;
