import { Alert } from '@/common/presentation/components/ui/alert';
import NavBar from '@/modules/wizard/presentation/components/Navbar';

const EssentialSuspendedPage = () => {
  return (
    <>
      <div className="pt-3 px-2 md:px-6 lg:px-12">
        <Alert className="border-2 bg-background border-amber-400 ">
          <div className="flex justify-center font-bold">
          The subscription for your account has been suspended. Please notify the facility manager immediately. Additionally, the facility manager can retry the payment through his account.
          </div>
        </Alert>
      </div>
    </>
  );
};

export default EssentialSuspendedPage;
