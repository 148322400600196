const columnsDestructionMedications: Column[] = [
  {
    key: 'medication_id',
    labelTranslationKey: 'medication_name', // Asumiendo que tienes una clave de traducción para el nombre del medicamento
    numeric: false,
    visible: true,
    static: true,
    sortable: true,
    render: (data) => data.medication_name // Puedes ajustar esto según cómo quieras renderizar los datos
  },
  {
    key: 'quantity',
    labelTranslationKey: 'quantity',
    numeric: true,
    visible: true,
    sortable: true,
    editable: true
  },
  {
    key: 'filledDate', // Corregido el nombre de 'filleDate' a 'filledDate'
    labelTranslationKey: 'filledDate',
    numeric: false,
    visible: true,
    sortable: true,
    editable: true,
    render: (data) => new Date(data.filledDate).toLocaleDateString() // Ajusta el formato de la fecha según tus necesidades
  },
  {
    key: 'rx',
    labelTranslationKey: 'prescriptionNumber',
    numeric: true,
    visible: true,
    sortable: true,
    editable: true
  },
  {
    key: 'disposal_date',
    labelTranslationKey: 'disposalDate',
    numeric: false,
    visible: true,
    sortable: true,
    editable: true,
    render: (data) => new Date(data.disposal_date).toLocaleDateString() // Ajusta el formato de la fecha según tus necesidades
  },
  {
    key: 'name_pharmacy',
    labelTranslationKey: 'nameOfPharmacy',
    numeric: false,
    visible: true,
    sortable: true,
    editable: true
  }
];

export default columnsDestructionMedications;
