import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogFooter,
  DialogClose,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';
import { ExternalLink } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ITrainingInfo {
  description: string;
  includes: string[];
  price: number;
  ranges: { min_beds: number; max_beds: number };
}
interface IProps {
  trainingServiceInfo: ITrainingInfo;
}

const TrainingDialog: React.FC<IProps> = ({ trainingServiceInfo }) => {
  const { t } = useTranslation();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="w-fit p-0" variant="link" type="button">
          {t('signUp.learnMore')} <ExternalLink className="ml-1" size={16} />
        </Button>
      </DialogTrigger>
      <DialogContent className="h-[80vh]">
        <DialogHeader>
          <DialogTitle className="font-bold text-2xl">{t('signUp.caregiverTrainingCAOnly')}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col">
          <div>
            <ScrollArea className="max-h-[52vh] overflow-y-auto" type="always">
              <p>{t('signUp.caregiverTrainingInfo')}</p>

              {trainingServiceInfo?.includes && (
                <>
                  <p className="my-3">Benefits of online training courses include:</p>
                  <ul>
                    {trainingServiceInfo?.includes.map((include: string) => {
                      return <li key={include}>- {include}</li>;
                    })}
                  </ul>
                </>
              )}
            </ScrollArea>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button className="bg-primary" type="button">
                {t('signUp.gotIt')}
              </Button>
            </DialogClose>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TrainingDialog;
