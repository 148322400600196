import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import useRouteParams from '@/common/hooks/RouteParamsHook';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { FileDown, Trash, Ellipsis } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { Textarea } from "@/common/presentation/components/ui/textarea";

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { ImageCardTables } from '@/common/presentation/components/Selectors/ImageCardTables';
import { SingleImageDropzone } from '@/common/presentation/components/ImageDropZone/single-image-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CircleFadingPlus } from "lucide-react";

function TrackerSkinCheck() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState([{ id: 1, name: 'Skin Check', status: 'active' }]);
    const [dataReport, setDatareport] = useState([]);
    const [dataReportquick, setDatareportQuick] = useState(null);

    const dispatch = useDispatch();
    const { tracker } = useSelector((state: RootState) => state.tracker);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedSkin, setSelectedSkin] = useState<string>(params.e || 'form');
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [select, setSelect] = useState([{ id: 1, name: 'Clear', status: 'active' }, { id: 2, name: 'Not Clear', status: 'active' }]);
    const [data, setData] = useState([]);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [errorMessage, setErrorMessage] = useState('');
    const canvasRef = useRef(null);
    const [points, setPoints] = useState([]);
    const [clicks, setClicks] = useState(0);
    const timeout = 300;
    const pointSize = 3;
    const [imageData, setImageData] = useState('');
    const [sexResident, setsexResident] = useState<string>(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [detail, setDetail] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [file, setFile] = useState<File>();
    const formattedDate = selectedDate ? selectedDate.toLocaleString() : '';
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedMoisture, setSelectedMoisture] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedTemperature, setSelectedTemperature] = useState('');
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const onSelectHygiene = (e: string) => {
        setParam('e', e);
        setSelectedSkin(e);
    };

    const onResidentSelect = (residentId: any) => {
        setParam('r', residentId);
        const selectedResident = residents.find(resident => resident.id === residentId);
        setsexResident(selectedResident.sex);
    };
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
            setParam('e', 'form');
            setSelectedSkin('form');
        }

        if (!params?.l || params.l.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }

    }, [params]);

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        params.l === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === params.l);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!params.l && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(params.l === 'all-locations' ? locations : locations.filter((l) => l.id === params.l));
        setLocation(params.l);

        if (params.l && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    const newColumns = () => {
        const firstObject = {
            label: t('trackers.Type'),
            value: "type",
            type: "texto"
        };
        return [firstObject, ...residents.map((resident: { name: any; }) => ({
            label: (
                <div>
                    <ImageCardTables
                        item={{
                            id: resident.id,
                            firstName: resident.first_name,
                            lastName: resident.last_name,
                            image: resident.image_url
                        }}
                        aspectRatio={'rounded'}
                    />
                </div>
            ),
            value: resident.first_name,
            type: 'text'
        }))];
    };
    const columns = newColumns();
    const columnsreport = [
        { label: t('trackers.Date/Time'), value: 'date', type: 'date' },
        { label: t('trackers.Resident'), value: 'resident_name' },
        { label: t('trackers.Detail'), value: 'detail' },
        { label: t('trackers.Staff'), value: 'who_name' }
    ];


    const dataSkinReport = async () => {
        const json = { location_id: params.l };
        const response = await TrackerService.getTrackerSkinCheck(json);
        if (response && Array.isArray(response) && response.length > 0) {
            let allData = [];

            response.forEach(item => {
                if (item && item.data) {
                    let parsedData;

                    try {
                        if (typeof item.data === "string") {
                            parsedData = JSON.parse(item.data);
                        } else {
                            parsedData = item.data;
                        }

                        allData.push({
                            ...parsedData,
                            created_at: item.created_at,
                            dataReportid: item.id
                        });

                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                }
            });

            allData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setDatareport(allData);
        } else {
            setDatareport([]);
        }
    };


    const dataSkinQuickReport = async () => {
        const json = { location_id: params.l };
        const response = await TrackerService.getTrackerHygiene(json);
        if (response && Array.isArray(response) && response.length > 0) {
            let allData = [];
            response.forEach(item => {
                if (item && item.data) {
                    const parsedData = JSON.parse(item.data);
                    allData = allData.concat(parsedData.map(dataItem => ({
                        ...dataItem,
                        created_at: item.created_at,
                        dataReportid: item.id
                    })));
                }
            });
            allData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setDatareportQuick(allData);
        }
    };

    useEffect(() => {
        if (!dataReport) {
            dataSkinReport();
        }

        if (!dataReportquick) {
            dataSkinQuickReport();
        }
    }, [dataReport, dataReportquick]);

    const createData = () => {
        const columnField = types || [];

        if (!types) return [];
        const groupedData = {};

        const today = new Date().toISOString().split('T')[0];

        const isReportChecked = (residentId, value, typeName) => {
            if (!dataReportquick) {
                return false;
            } else {
                return Array.isArray(dataReportquick) && dataReportquick.some((report) => {
                    const reportDate = new Date(report.date).toISOString().split('T')[0];
                    return report.resident_id === residentId && report.value === value && reportDate === today && report.type_name === typeName;
                });
            }

        };

        const handleSelectChange = (typeId, residentId, value) => {
            buttonSumbit({
                typeId,
                residentId,
                status: value,
                save: 'quick'
            });
        };

        residents.forEach((resident) => {
            columnField.forEach((field) => {
                if (field.status === "inactive") {
                    return;
                }
                const type = field.name;
                const typeid = field.id;

                const currentValue = select
                    .filter(option => option.status === 'active')
                    .find(option => isReportChecked(resident.id, option.name, type))?.name || '';

                if (!groupedData[type]) {
                    groupedData[type] = { type: type };
                }

                groupedData[type][resident.first_name] = (
                    <div className="flex justify-center" key={`${type}-${resident.first_name}`}>
                        <div className="text-center flex-1">
                            <Select
                                value={currentValue}
                                onValueChange={(value) => handleSelectChange(typeid, resident.id, value)}
                            >
                                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                                    <SelectValue placeholder="">
                                        {currentValue ? currentValue.charAt(0).toUpperCase() + currentValue.slice(1) : ' '}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    {select
                                        .filter(option => option.status === 'active')
                                        .map(option => (
                                            <SelectItem key={option.id} value={option.name}>
                                                {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
                                            </SelectItem>
                                        ))
                                    }
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                );
            });
        });

        const newData = Object.values(groupedData);
        return newData;
    };


    useEffect(() => {
        setData(createData());
    }, [types, dataReport, dataReportquick]);

    const Fields = {
        'Edit': [
            { fieldName: t('trackers.Date/Time'), label: 'Date/Time', type: 'date' },
            { fieldName: t('trackers.Type'), label: 'Type', type: 'select' },
            { fieldName: t('trackers.Value'), label: 'Value', type: 'selectval' },
            { fieldName: t('trackers.Detail'), label: 'Detail', type: 'textarea' }
        ]
    };

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[], description: string,) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(fields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
    };



    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};
        if (action === 'Delete') {
            json["id"] = formData.dataReportid;
            json["id_data"] = formData.id;
            const response = await TrackerService.destroyTrackerSkinCheck(json);
        }
        dataSkinReport();
    };

    useEffect(() => {
        dataSkinReport();
    }, [params.l]);


    const [filters, setFilters] = useState({
        resident_name: '',
        type_name: ''
    });

    const buttonSumbit = async ({ typeId, residentId, status, save }: { typeId: string; residentId: string; status: string; save: string; }) => {
        const today = new Date().toISOString().split('T')[0];

        const findSkinCheckId = (columnField) => {
            const skinCheckField = columnField.find(field => field.name.toLowerCase() === 'skin check');
            return skinCheckField ? skinCheckField.id : null;
        };
        if (save == 'quick') {
            const json = { location_id: params.l };
            const response = await TrackerService.getTrackerHygieneType(json);
            if (response && response.data) {
                const jsonData = JSON.parse(response.data);
                const skinCheckId = findSkinCheckId(jsonData.column_field);
                if (Array.isArray(dataReportquick)) {
                    const foundReport = dataReportquick.find(report => {
                        const reportDate = new Date(report.date).toISOString().split('T')[0];
                        return report.type === skinCheckId && report.resident_id === residentId && today === reportDate;
                    });

                    if (foundReport) {
                        const json: { [key: string]: any } = {};
                        json["id"] = foundReport.dataReportid;
                        json["id_data"] = foundReport.id;
                        json["data"] = {
                            "type": skinCheckId,
                            "detail": foundReport.detail,
                            "value": status,
                            "resident_id": residentId,
                            "date": foundReport.date
                        };
                        const response = await TrackerService.updateTrackerHygiene(json);
                        dataSkinQuickReport();
                        setData(createData());
                    } else {
                        const json = [{
                            type: skinCheckId,
                            value: status,
                            resident_id: residentId,
                            detail: " ",
                            location_id: params.l
                        }];
                        const response = await TrackerService.saveTrackerHygiene(json);
                        dataSkinQuickReport();
                        setData(createData());
                    }
                } else {
                    const json = [{
                        type: skinCheckId,
                        value: status,
                        resident_id: residentId,
                        detail: " ",
                        location_id: params.l
                    }];
                    const response = await TrackerService.saveTrackerHygiene(json);
                    dataSkinReport();
                    setData(createData());;
                }
            }
        } else {

        }
    };

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };



    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction: direction });
    };

    const sortedData = useMemo(() => {
        if (sortConfig.key) {
            return [...dataReport].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });
        }
        return dataReport;
    }, [dataReport, sortConfig]);


    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            changeSizeCanvasResponsive(ctx, canvas);

            const handleResize = () => {
                changeSizeCanvasResponsive(ctx, canvas);
            };

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    const changeSizeCanvasResponsive = (ctx, canvas) => {
        let cw = (canvas.width = 500);
        let ch = (canvas.height = 500);
        ctx.clearRect(0, 0, cw, ch);

        if (window.innerWidth <= 650) {
            cw = (canvas.width = 250);
            ch = (canvas.height = 250);
            canvas.setAttribute('width', 250);
            canvas.setAttribute('height', 250);
            ctx.clearRect(0, 0, cw, ch);
        } else {
            cw = (canvas.width = 500);
            ch = (canvas.height = 500);
            canvas.setAttribute('width', 500);
            canvas.setAttribute('height', 500);
            ctx.clearRect(0, 0, cw, ch);
        }

        return [cw, ch];
    };

    const drawCoordinates = (point, r, ctx) => {
        ctx.fillStyle = "#ff2626";
        ctx.beginPath();
        ctx.arc(point.x, point.y, r, 0, Math.PI * 2, true);
        ctx.fill();
    };

    const getPosition = (event, canvas) => {
        var rect = canvas.getBoundingClientRect();
        var x = event.clientX - rect.left;
        var y = event.clientY - rect.top;
        return { x, y };
    };

    const handleCanvasClick = (e) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        const m = getPosition(e, canvas);
        drawCoordinates(m, pointSize, ctx);

        setClicks((prevClicks) => {
            const newClicks = prevClicks + 1;
            setTimeout(() => {
                if (newClicks === 1) {
                    setPoints((prevPoints) => [...prevPoints, m]);
                } else {
                    let updatedPoints = [...points];
                    for (let i = 0; i < updatedPoints.length; i++) {
                        ctx.beginPath();
                        ctx.arc(updatedPoints[i].x, updatedPoints[i].y, pointSize, 0, Math.PI * 2, true);
                        if (ctx.isPointInPath(m.x, m.y)) {
                            updatedPoints.splice(i, 1);
                            break;
                        }
                    }
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    updatedPoints.forEach(p => drawCoordinates(p, pointSize, ctx));
                    setPoints(updatedPoints);
                }
                setClicks(0);
            }, timeout);
            return newClicks;
        });
    };

    const deleteCoordinates = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        setPoints([]);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };


    const onChange = async (file?: File) => {
        if (file) {
            setFile(file.target.files[0]);
        }
    };

    const handleSelectChangeSkin = (value) => {
        setFormData((prevData) => ({ ...prevData, value }));
    };

    const handleChangeDetail = (e) => {
        setDetail(e.target.value);
    };

    const handleDateChangeSkin = (date) => {
        setSelectedDate(date);
    };
    const codeEnOptions = [
        t('trackers.None'), t('trackers.Abrasion'), t('trackers.Bruise'),
        t('trackers.Burn'), t('trackers.Rash'), t('trackers.HX of Pressure Sore'),
        t('trackers.Incision'), t('trackers.Laceration'), t('trackers.Lesion'),
        t('trackers.Mottled'), t('trackers.Pressure Sore'), t('trackers.Reddened Area'),
        t('trackers.Scar'), t('trackers.Skin Tear'), t('trackers.Skin Discoloration'), t('trackers.Statis Ulcer')

    ];


    const handleStatusClick = (value) => {
        setSelectedStatus(value);
    };

    const handleMoistureClick = (value, id) => {
        setSelectedMoisture(value);
    };
    const drawBorderTemperature = (value, id) => {
        setSelectedTemperature(value);
    };

    const drawBorderColor = (value, id) => {
        setSelectedColor(value);
    };

    const handleSubmitSkin = async (event) => {
        event.preventDefault();

        // manejar el envío del formulario
        if (!params.r) {
            alert('Por favor seleccione un residente');
            return;
        }

        if (points.length === 0) {
            alert("Please mark at least one point on the canvas.");
            return;
        }

        if (!selectedStatus) {
            alert("Please select an option Status.");
            return;
        }

        if (!selectedMoisture) {
            alert("Please select an option Moisture.");
            return;
        }

        if (!selectedColor) {
            alert("Please select an option Color.");
            return;
        }

        if (!selectedTemperature) {
            alert("Please select an option Temperature.");
            return;
        }

        const canvas = canvasRef.current;
        if (canvas) {
            const urlImage = canvas.toDataURL();
            const isoDate = new Date(selectedDate).toISOString();
            const json = new FormData();
            json.append('resident_id', params.r);
            json.append('date', isoDate);
            json.append('detail', detail);
            json.append('status', selectedStatus);
            json.append('moisure', selectedMoisture);
            json.append('temperature', selectedTemperature);
            json.append('color', selectedColor);
            json.append('type', formData.value);
            json.append('image', file);
            json.append('url', urlImage);
            json.append('location_id', params.l);
            try {
                const response = await TrackerService.saveTrackerSkinCheck(json);
                setDetail('');
                setSelectedStatus('');
                setSelectedMoisture('');
                setSelectedColor('');
                setSelectedTemperature('');
                setFormData({});
                deleteCoordinates();
                dataSkinReport();
                setImageFile(null);
                toast({
                    title: 'SkinCheck',
                    description: 'SkinCheck saved successfully',
                    className: 'bg-green-500 text-white'
                });
            } catch (error) {
                console.error('Error al guardar:', error);
                toast({
                    description: 'There was an error saving the SkinCheck',
                    variant: 'destructive'
                });
            }
        }

    };

    const backgroundImageUrl = sexResident === null || sexResident === 'male'
        ? 'https://caringdata.com/corte/hombre.png'
        : 'https://caringdata.com/corte/mujer.png';

    const handleResidentClick = (resident) => {
        setFilters({
            resident_name: `${resident}`,
            who_name: ''
        });
    };


    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                params.l && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackerskin.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackerskin.description')}</div>
                        </div>

                        <Tabs defaultValue={selectedSkin} onValueChange={(e) => { onSelectHygiene(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('seizure.form.label')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }


            {
                selectedSkin === 'form' && (
                    <div>
                        {
                            params.l && (
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <ResidentSelector
                                            onSelect={onResidentSelect}
                                            locationId={location}
                                            residentId={params.r}
                                            showAllResidents={false}
                                        />
                                    </div>

                                    <div>
                                        <Card className="border-t-4 border-t-primary/80">
                                            <CardHeader>
                                                <CardTitle className="flex flex-row items-center text-lg font-bold">{t('trackers.bodypart')}</CardTitle>
                                            </CardHeader>
                                            <form onSubmit={handleSubmitSkin} encType="multipart/form-data">
                                                <CardContent>
                                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                                    <div className="flex flex-wrap lg:flex-nowrap">
                                                        <div className="flex flex-col lg:w-1/2">
                                                            <canvas
                                                                className="d-none border canvas-image"
                                                                id="canvas"
                                                                width="500"
                                                                height="500"
                                                                ref={canvasRef}
                                                                onClick={handleCanvasClick}
                                                                style={{
                                                                    backgroundSize: 'contain',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    cursor: 'crosshair',
                                                                    backgroundImage: `url(${backgroundImageUrl})`
                                                                }}
                                                            ></canvas>
                                                            <div className="w-100 mt-4">
                                                                <div className="flex justify-center"> {/* Asegúrate de usar las clases correctas para flex y centrado */}
                                                                    <Button onClick={deleteCoordinates} type="button" className="bg-primary text-white px-4 py-2 rounded">
                                                                        {t('trackers.Reset red dots')}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col w-full p-2">
                                                            <div className="flex flex-col w-full p-2">
                                                                <Label htmlFor="tracker_image" className="control-label mb-3">
                                                                    {t('trackers.Upload Image')}
                                                                </Label>
                                                                <Input
                                                                    type="file"
                                                                    id="tracker_image"
                                                                    name="tracker_image"
                                                                    className="w-full"
                                                                    onChange={onChange}
                                                                    multiple
                                                                />
                                                            </div>

                                                            <div className="flex flex-col w-full p-2">
                                                                <div className="flex flex-col w-full p-2">
                                                                    <Label htmlFor="tracker_brief_date" className="control-label mb-3">
                                                                        {t('trackers.Date/Time')}
                                                                    </Label>
                                                                    <div className="input-group">
                                                                        <Popover>
                                                                            <PopoverTrigger asChild>
                                                                                <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                                                                    {formattedDate}
                                                                                </Button>
                                                                            </PopoverTrigger>
                                                                            <PopoverContent className="w-auto p-0">
                                                                                <CustomCalendar
                                                                                    id="tracker_brief"
                                                                                    name="tracker_brief"
                                                                                    selectedDate={selectedDate}
                                                                                    onChange={handleDateChangeSkin}
                                                                                    MonthAndYearPicker={true}
                                                                                    timePicker={true}
                                                                                />
                                                                            </PopoverContent>
                                                                        </Popover>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="flex flex-col w-full p-2">
                                                                <Label htmlFor="tracker_detail" className="control-label mb-3">
                                                                    {t('trackers.Detail')}
                                                                </Label>
                                                                <Textarea
                                                                    id="tracker_detail"
                                                                    name="tracker_detail"
                                                                    className="w-full"
                                                                    value={detail}
                                                                    onChange={handleChangeDetail}
                                                                />
                                                            </div>

                                                            <div className="flex flex-col w-full p-2">
                                                                <Label htmlFor="tracker_condition" className="control-label mb-3">
                                                                    {t('trackers.Condition')}
                                                                </Label>
                                                                <Select value={formData.value} onValueChange={handleSelectChangeSkin} required>
                                                                    <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                                                                        <SelectValue placeholder="Select an option">
                                                                            {formData.value || 'Select an option'}
                                                                        </SelectValue>
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        {codeEnOptions.map((option) => (
                                                                            <SelectItem key={option} value={option}>
                                                                                {option}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectContent>
                                                                </Select>
                                                            </div>

                                                            <div className="flex flex-col p-2">
                                                                <Label htmlFor="tracker_condition" className="control-label mb-2">
                                                                    {t('trackers.Status')}
                                                                </Label>
                                                                <div className="flex gap-2">
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => handleStatusClick('Improved')}
                                                                        className={`flex-1 pt-0 pb-0 mb-2 p-2 ${selectedStatus === 'Improved' ? 'text-white bg-green-700 border-green-700 hover:bg-green-700' : 'text-green-700 border-green-500 hover:bg-green-100 bg-white-100 hover:border-green-700 hover:text-green-700'}`}
                                                                    >
                                                                        {t('trackers.Improved')}
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => handleStatusClick('The same')}
                                                                        className={`flex-1 pt-0 pb-0 mb-2 p-2 ${selectedStatus === 'The same' ? 'text-white bg-gray-700 border-gray-700 hover:bg-gray-700' : 'text-gray-700 border-gray-500 hover:bg-gray-100 bg-white-100 hover:border-gray-700 hover:text-gray-700'}`}
                                                                    >
                                                                        {t('trackers.Same')}
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => handleStatusClick('Worsen')}
                                                                        className={`flex-1 pt-0 pb-0 p-2 ${selectedStatus === 'Worsen' ? 'text-white bg-red-700 border-red-700 hover:bg-red-700' : 'text-red-700 border-red-500 hover:bg-red-100 bg-white-100 hover:border-red-700 hover:text-red-700'}`}
                                                                    >
                                                                        {t('trackers.Worsen')}
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col p-2">
                                                                <Label htmlFor="tracker_condition" className="control-label mb-2">
                                                                    {t('trackers.Moisture')}
                                                                </Label>
                                                                <div className="flex gap-2">
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => handleMoistureClick('Dry Flaking')}
                                                                        className={`flex-1 pt-0 pb-0 mb-2 p-2 ${selectedMoisture === 'Dry Flaking' ? 'text-white bg-green-700 border-green-700 hover:bg-green-700' : 'text-green-700 border-green-500 hover:bg-green-100 bg-white-100 hover:border-green-700 hover:text-green-700'}`}
                                                                    >
                                                                        {t('trackers.Dry Flaking')}
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => handleMoistureClick('Oily')}
                                                                        className={`flex-1 pt-0 pb-0 mb-2 p-2 ${selectedMoisture === 'Oily' ? 'text-white bg-green-700 border-green-700 hover:bg-green-700' : 'text-green-700 border-green-500 hover:bg-green-100 bg-white-100 hover:border-green-700 hover:text-green-700'}`}
                                                                    >
                                                                        {t('trackers.Oily')}
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => handleMoistureClick('Clammy')}
                                                                        className={`flex-1 pt-0 pb-0 p-2 ${selectedMoisture === 'Clammy' ? 'text-white bg-green-700 border-green-700 hover:bg-green-700' : 'text-green-700 border-green-500 hover:bg-green-100 bg-white-100 hover:border-green-700 hover:text-green-700'}`}
                                                                    >
                                                                        {t('trackers.Clammy')}
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col gap-4 p-2">
                                                                <Label htmlFor="tracker_condition" className="control-label mb-2">
                                                                    {t('trackers.Temperature')}
                                                                </Label>
                                                                <div className="flex gap-4">
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => drawBorderTemperature('Cool')}
                                                                        className={`flex-1 pt-0 pb-0 ${selectedTemperature === 'Cool' ? 'text-white bg-blue-700 border-blue-700 hover:bg-blue-700' : 'text-blue-700 border-blue-500 hover:bg-blue-100 bg-white-100 hover:border-blue-700 hover:text-blue-700'}`}
                                                                    >
                                                                        {t('trackers.Cool')}
                                                                    </Button>

                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => drawBorderTemperature('Warm')}
                                                                        className={`flex-1 pt-0 pb-0 ${selectedTemperature === 'Warm' ? 'text-white bg-red-700 border-red-700 hover:bg-red-700' : 'text-red-700 border-red-500 hover:bg-red-100 bg-white-100 hover:border-red-700 hover:text-red-700'}`}
                                                                    >
                                                                        {t('trackers.Warm')}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col gap-4 p-2">
                                                                <Label htmlFor="tracker_condition" className="control-label mb-2">
                                                                    {t('trackers.Color')}
                                                                </Label>
                                                                <div className="flex gap-4">
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => drawBorderColor('Pink')}
                                                                        className={`flex-1 pt-0 pb-0 ${selectedColor === 'Pink' ? 'text-white bg-pink-700 border-pink-700 hover:bg-pink-700' : 'text-pink-700 border-pink-500 hover:bg-pink-100 bg-pink-100 hover:border-pink-700 hover:text-pink-700'}`}
                                                                    >
                                                                        {t('trackers.Pink')}
                                                                    </Button>

                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => drawBorderColor('Pale')}
                                                                        className={`flex-1 pt-0 pb-0 ${selectedColor === 'Pale'
                                                                            ? 'text-black bg-[#F7D8CD] border-[#F7D8CD] hover:bg-[#F7D8CD]'
                                                                            : 'text-black border-[#F7D8CD] hover:bg-[#F7D8CD] bg-[#F7D8CD] hover:border-[#F7D8CD] hover:text-[#F7D8CD]'
                                                                            }`}
                                                                    >
                                                                        {t('trackers.Pale')}
                                                                    </Button>

                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => drawBorderColor('Flushed')}
                                                                        className={`flex-1 pt-0 pb-0 ${selectedColor === 'Flushed'
                                                                            ? 'text-white bg-[#b75858] border-[#b75858] hover:bg-[#b75858]'
                                                                            : 'text-white border-[#b75858] hover:bg-[#b75858] bg-[#ef8989e4] hover:border-[#b75858] hover:text-white'
                                                                            }`}
                                                                    >
                                                                        {t('trackers.Flushed')}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <input type="hidden" name="tracker_skincheckurl" value={imageData} />

                                                    <div className="flex flex-column justify-end mt-4">
                                                        <Button type="submit" className="bg-primary text-white px-4 py-2 rounded">
                                                            {t('trackers.Save')}
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </form>

                                        </Card>
                                    </div>

                                    <br />
                                    <br />
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                selectedSkin === 'report' && (
                    <div>
                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <CardTitle className="flex flex-row items-center text-lg font-bold">{t('trackers.trackerskinreport.title')}</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div className="flex space-x-4">
                                    <Input
                                        placeholder="Filter by Name"
                                        value={filters.resident_name}
                                        onChange={(e) => setFilters({ ...filters, resident_name: e.target.value })}
                                        className="w-[200px]"
                                    />
                                    <Input
                                        placeholder="Filter by Type"
                                        value={filters.type_name}
                                        onChange={(e) => setFilters({ ...filters, type_name: e.target.value })}
                                        className="w-[200px]"
                                    />
                                </div>
                                <br />
                                <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                    <Table className="shadow-lg overflow-hidden">
                                        <TableHeader>
                                            <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                {columnsreport.map((column) => (

                                                    <TableHead
                                                        key={column.value}
                                                        className="text-white cursor-pointer items-center"
                                                        onClick={() => handleSort(column.value)}>
                                                        <div className="flex items-center ">
                                                            <span>{column.label}</span>
                                                            <span className="ml-1 flex-col justify-end">
                                                                <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'ascending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                    ▲
                                                                </span>
                                                                <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'descending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                    ▼
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </TableHead>

                                                ))}
                                                <TableHead className="text-white">{t('trackers.Actions')}</TableHead>

                                            </TableRow>
                                        </TableHeader>
                                        <TableBody className="bg-white dark:bg-background">

                                            {sortedData && sortedData
                                                .filter(row =>
                                                    (!filters.resident_name || row.resident.first_name.toLowerCase().includes(filters.resident_name.toLowerCase())) &&
                                                    (!filters.type_name || row.type.toLowerCase().includes(filters.type_name.toLowerCase()))
                                                )
                                                .map((row, index) => (
                                                    <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                        {columnsreport.map((column, columnIndex) => (
                                                            <TableCell key={columnIndex} className="py-2 px-4">
                                                                {column.value === 'date' ? (
                                                                    <span>
                                                                        {format(new Date(row[column.value]), "dd/MM/yyyy hh:mm a")}
                                                                        {column.type_name === 'date' ? ` (${row.value})` : null}
                                                                    </span>
                                                                ) : (
                                                                    column.value === 'resident_name' ? (
                                                                        <a
                                                                            href="#"
                                                                            onClick={() => handleResidentClick(row[column.value])}
                                                                            className="text-blue-500 hover:underline"
                                                                        >
                                                                            {`${row.resident.first_name}`} {`${row.resident.last_name}`}
                                                                        </a>
                                                                    ) : column.value === 'who_name' ? (
                                                                        <span>
                                                                            {capitalizeWords(String(row.who.first_name))} {capitalizeWords(String(row.who.last_name))}
                                                                        </span>
                                                                    ) : (
                                                                        capitalizeWords(String(row[column.value as keyof typeof row]))
                                                                    )
                                                                )}

                                                            </TableCell>
                                                        ))}
                                                        <TableCell className="py-2 px-4">
                                                            <DropdownMenu>
                                                                <DropdownMenuTrigger asChild>
                                                                    <Button variant="outline" size="icon">
                                                                        <Ellipsis className="h-4 w-4" />
                                                                    </Button>
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent className="w-56">
                                                                    <DropdownMenuSeparator />
                                                                    <DropdownMenuGroup>
                                                                        <DropdownMenuItem>
                                                                            <FileDown className="mr-2 h-4 w-4" />
                                                                            <span >PDF</span>
                                                                        </DropdownMenuItem>
                                                                        <DropdownMenuItem>
                                                                            <Trash className="mr-2 h-4 w-4" />
                                                                            <span onClick={() => handleButtonOpenDialog('', 'Delete', row, "Are you sure you want to delete this item?")}>{t('trackers.Delete')}</span>
                                                                        </DropdownMenuItem>

                                                                    </DropdownMenuGroup>
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                        <CustomDialog
                                            isOpen={showEditDialog}
                                            onClose={handleCloseEditDialog}
                                            title={dialogTitle}
                                            onSubmit={handleSubmit}
                                            description={dialogDescription}
                                        >
                                        </CustomDialog>
                                    </Table>
                                    <div>
                                        <div className="flex items-center justify-between px-2">
                                            <div className="flex-1 text-sm text-muted-foreground">
                                                {dataReport && dataReport.length} of {dataReport && dataReport.length} row(s) selected.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="flex justify-end mb-4">
                                    <button className="p-2 bg-primary text-white rounded">{t('trackers.Download')}</button>
                                </div>

                            </CardContent>
                        </Card>
                    </div>
                )
            }

        </div >
    )
}

export default TrackerSkinCheck