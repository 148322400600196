import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Label } from '@/common/presentation/components/ui/label';
import { cn } from '@/lib/utils';
import { useWizardFormData } from '@/modules/wizard/infrastructure/providers/FormDataProvider';
import { ChevronLeftIcon } from 'lucide-react';
import React, { useCallback, useEffect } from 'react';
import Counter from '../../../../../common/presentation/components/Counter/Counter';
import SetUpFacilities from '../SetUpFacilities';
import OrderSummary from '../OrderSummary';
import { IFacilitiesInfo, FormData as IFormData, IService, Products } from '@/modules/wizard/domain/signup.domain';
import { useSelector } from 'react-redux';
import { localization } from '@/utils/helpers/localization.helper';
import { calculatePrice } from '@/utils/helpers/subscriptions.helper';
import { useTranslation } from 'react-i18next';
import { Separator } from '@/common/presentation/components/ui/separator';

interface IProps {
  handleBackStep: (step: number) => void;
  handleNextStep: (step: number) => void;
}

const Step3: React.FC<IProps> = ({ handleBackStep, handleNextStep }) => {
  const { formData, updateFormData } = useWizardFormData();
  const services = useSelector((state: any) => state.signUp.services);
  const { t } = useTranslation();

  useEffect(() => {
    const summary = formattSummary(formData);
    updateFormData({
      ...formData,
      summary
    });
  }, []);

  const formattSummary = useCallback(
    (providerData: IFormData) => {
      const { essential_plan }: IService = services;

      const prices = essential_plan.modules.reduce((acc: any, module) => {
        const price = calculatePrice(module, null, formData.client_country.code).price;
        acc[module.key] = price;
        return acc;
      }, {});

      let summary: Products = {
        facilities: {
          label: 'Facilities',
          quantity: providerData.facilities_number,
          price: prices['facility']
        },
        beds: {
          label: 'Extra beds',
          quantity: 0,
          price: prices['bed']
        }
      };

      let totalBeds = 0;
      providerData.facilities_list.forEach((facility) => {
        summary = {
          ...summary,
          beds: {
            ...summary.beds,
            quantity: summary.beds.quantity + (facility.beds_number > 10 ? facility.beds_number - 10 : 0)
          }
        };
        totalBeds += facility.beds_number;
      });

      return summary;
    },
    [services]
  );

  const facilitiesHandler = (value: number) => {
    let newData: IFormData;
    let newFacilitiesList: Array<IFacilitiesInfo> = [];
    if (value > formData.facilities_list.length) {
      newFacilitiesList = [
        ...formData.facilities_list,
        ...Array.from({ length: value - formData.facilities_list.length }, () => ({ beds_number: 10 }))
      ];
    } else if (value < formData.facilities_list.length) {
      newFacilitiesList = formData.facilities_list.slice(0, value);
    }
    newData = {
      ...formData,
      facilities_number: value,
      facilities_list: newFacilitiesList
    };
    const summary = formattSummary(newData);
    updateFormData({
      ...newData,
      summary
    });
  };

  const saveFacilitiesSetUp = (facilitiesList: IFacilitiesInfo[]) => {
    const data = {
      ...formData,
      facilities_list: facilitiesList
    };

    const summary = formattSummary(data);
    updateFormData({
      ...data,
      summary
    });
  };

  return (
    <>
    <div className="w-11/12 lg:w-9/12 flex md:block items-center justify-center">
    <div className="col-span-3 w-full">
        <div className="justify-start">
          <Button className="p-0 text-base" variant="link" onClick={() => handleBackStep(2)}>
            <ChevronLeftIcon className="w-4 h-4 mr-1 text-base" /> { t('signUp.back') }
          </Button>
        </div>
        <Card className={`border-0 shadow-none flex flex-col`}>
          <CardHeader>
            <CardTitle className="flex flex-row items-center text-2xl font-bold">
              {t('signUp.facilityDetails')}
            </CardTitle>
          </CardHeader>
          <CardContent className="flex-grow overflow-auto flex flex-col gap-10">
            <form className="flex flex-col h-full">
              <div className="grid grid-cols-1 gap-2 flex-grow overflow-auto">
                <div>
                  <div className="flex flex-row justify-between items-center pr-2">
                    <div className="py-1">
                      <Label htmlFor="facilities_number" className="text-right text-base">
                        {t('signUp.numberFacilities')}{' '}
                        <span className={cn('text-gray-400')}>({t('signUp.required')})</span>
                      </Label>
                    </div>
                    <div className="py-1">
                      <Counter
                        defaultValue={1}
                        minValue={1}
                        maxValue={10}
                        step={1}
                        name={'facilities_number'}
                        onChange={facilitiesHandler}
                        value={formData.facilities_number}
                      />
                    </div>
                  </div>
                  <Separator className="bg-primary mt-4 h-1 rounded" />

                  <div className="col-span-1">
                    <SetUpFacilities
                      onSave={(facilities) => saveFacilitiesSetUp(facilities)}
                      priceExtraBed={localization.formatPrice(
                        formData.summary.beds.price,
                        formData.client_country.currency
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-col items-center pt-5 mt-auto">
                  <Button
                    className="w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNextStep(3);
                    }}
                  >
                    { t('signUp.continue') }
                  </Button>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>

    </>
  );
};

export default Step3;
