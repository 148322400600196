import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  fetchResidentMedicationById,
  fetchMedications,
  fetchTypes,
  getResidentMedicationTime,
  editResidentMedicationById,
  fetchForms
} from '../../slices/medicationsSlice';
import { RootState } from '@/store/store';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CustomImages } from '../CustomImages';
import MedicationsOptional from './MedicationOptional';
import BottomButtons from '../BottomButtons';
import MedicationFormSelect from './MedicationFormSelect';
import ChartRX from './ChartRX';
import { useTranslation } from 'react-i18next';
import { toast } from '@/common/presentation/components/ui/use-toast';

const MedicationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { medicationId } = useParams();
  const [listSelectForm, setlistSelectForm] = useState<string[]>([]);

  const { types, residentMedicationTime, medicationsResident, medications, forms } = useSelector(
    (state: RootState) => state.medications
  );

  const { control, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: useMemo(
      () => ({
        medication: '',
        form: [],
        strength: [],
        purpose: '',
        not_exceed: '',
        frequency: '',
        instruction: '',
        special: '',
        date_prescribed: '',
        current_quantity: 0,
        minimum_quantity: 0,
        units_per_dose: 0,
        type: '',
        location_type_id: '',
        responsible_administrations_id: '',
        routes_medication_id: '',
        resident_id: '',
        status: '',
        typeName: '',
        selectedMedication: {} as [],
        special_days: [],
        location_id: '',
        chart: [],
        physician_id: '',
        prescriber_npi: '',
        physician_typeName: '',
        physician_type_id: '',
        content_notes: '',
        pharmacy_id: '',
        time: []
      }),
      []
    )
  });

  const { fields } = useFieldArray({
    control,
    name: 'chart'
  });

  const [images, setImages] = useState<File[]>([]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [typeName, setTypeName] = useState('');

  const handleImageChange = useCallback((file: File) => {
    setImages((prevImages) => [...prevImages, file]);
  }, []);

  const handleSelectedTimesChange = useCallback(
    (selectedTimes: string[]) => {
      setSelectedTimes(selectedTimes);
      setValue('time', selectedTimes);
    },
    [setValue]
  );

  const handleCycleStartDateChange = useCallback(
    (selectedDate: Date | null) => {
      setValue('chart', [{ cycle_start_date: selectedDate ? selectedDate.toISOString() : null }]);
    },
    [setValue]
  );

  const handleSelect = useCallback(
    (option: any) => {
      setValue('medication_id', option.id);
      setValue('form', option.forms || []);
      setValue('strength', option.strengths || []);
    },
    [setValue]
  );

  const handleBackClick = useCallback(() => navigate(-1), [navigate]);

  useEffect(() => {
    if (medicationId) {
      dispatch(fetchResidentMedicationById(medicationId));
      dispatch(fetchTypes({ locationId: medicationsResident.location_id }));
      dispatch(getResidentMedicationTime(medicationsResident.resident_id));
      dispatch(fetchForms());
      dispatch(
        fetchMedications({
          locationId: medicationsResident.location_id,
          medicationId: medicationsResident.medicationId
        })
      );
    }
  }, [medicationId, dispatch]);
  useEffect(() => {
    if (medicationsResident) {
      const datamedications = medications.find((med) => med.id === medicationsResident.medication_id);

      if (datamedications) {
        const listForm = forms.find((form) => form.name === datamedications.form);

        if (listForm) {
          setlistSelectForm(forms);
        } else {
          const formValue = datamedications.form.split(', ').map((item) => item.trim());
          setlistSelectForm(formValue);
        }

        reset({
          ...medicationsResident,
          medication: datamedications?.name || '',
          type: medicationsResident.type_medication_id,
          typeName: medicationsResident?.location_type_medication?.type_medication?.name,
          location_type_id: medicationsResident.location_type_id || '',
          responsible_administrations_id: medicationsResident.responsible_administrations_id || '',
          routes_medication_id: medicationsResident.routes_medication_id || '',
          form: medicationsResident.form || [],
          special_days: medicationsResident.special_days || [],
          location_id: medicationsResident.location_id,
          chart: medicationsResident.chart,
          physician_id: medicationsResident.physician?.id || '',
          prescriber_npi: medicationsResident.physician?.npi || '',
          physician_typeName: medicationsResident.physician?.physician_type?.name || '',
          physician_type_id: medicationsResident.physician_type?.id || '',
          content_notes: medicationsResident.notes?.content || '',
          pharmacy_id: medicationsResident.pharmacy?.id || '',
          time: medicationsResident.time || [],
          date_prescribed: medicationsResident?.date_prescribed || '',
          purpose: medicationsResident?.purpose || ''
        });
      }
    }
  }, [medicationsResident, medications, forms, reset]);

  const onSubmit = async (data: any) => {
    try {
      const response = await dispatch(editResidentMedicationById({ id: medicationId, data }));
      if (!response.error) {
        toast({ description: 'Actualización realizada con éxito!', variant: 'success' });
      }
    } catch (error) {
      console.error('Error al actualizar los datos:', error);
      toast({ description: error.message, variant: 'destructive' });
    }
  };

  return (
    <div className="gap-4 my-4">
      <div>
        <div className="flex flex-col gap-4 my-4">
          <div className="flex items-center mb-4">
            <Button variant="ghost" onClick={handleBackClick}>
              <ArrowLeft className="mr-2 h-4 w-4" />
              {t('medications.back')}
            </Button>
            <div className="text-xl text-primary font-semibold ml-4">{t('medications.detailsTitle')}</div>
          </div>

          <CustomAccordion title="Medications" description="Information about the prescribing physician and pharmacy.">
            <MedicationFormSelect
              control={control}
              handleSelect={handleSelect}
              types={types}
              selectedTimes={watch('time')}
              handleSelectedTimesChange={handleSelectedTimesChange}
              handleSelectedTypeChange={setTypeName}
              typeName={watch('typeName')}
              strength={watch('strength')}
              selectedListForm={listSelectForm}
              medication={watch('medication')}
            />
            <div className="flex justify-start">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion>
          <CustomAccordion
            title="Optional information"
            description="Additional details about the prescribing physician and pharmacy."
            defaultOpen
          >
            {medicationsResident && (
              <MedicationsOptional
                residentId={medicationsResident.resident_id}
                locationId={medicationsResident.location_id}
                control={control} // Pasa control
                handleSubmit={handleSubmit} // Pasa handleSubmit
                setValue={setValue} // Pasa setValue
                watch={watch}
              />
            )}
            <div className="flex justify-start">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion>
          <CustomAccordion title="RX Chart" description="Additional details about the medication.">
            <ChartRX control={control} fields={fields} />
          </CustomAccordion>

          {/*

          <CustomAccordion title="RX Chart" description="Additional details about the medication.">
            <ChartRX control={control} fields={fields} />
          </CustomAccordion>

          <CustomAccordion title="Images" description="Additional notes for the medication.">
            <div className="mb-4">
              <CustomImages onChange={handleImageChange} />
            </div>
          </CustomAccordion> */}
        </div>
      </div>
    </div>
  );
};

export default MedicationDetails;
