import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { Button } from '@/common/presentation/components/ui/button';
import * as staffSliceActions from '@/modules/staff/presentation/slices/staffSlice';
import { AppDispatch, RootState } from '@/store/store';
import { CircleFadingPlus } from 'lucide-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNewStaff } from '../../infrastructure/hooks/use-new-staff';
import { columns } from '../components/columns';

export const StaffPage = () => {
  const { onOpen } = useNewStaff();
  const { params, setParam } = useRouteParams();
  const dispatch = useDispatch<AppDispatch>();
  const { staff, status } = useSelector((state: RootState) => state.staff.allStaff);

  useEffect(() => {
    if (params.l) {
      dispatch(staffSliceActions.getStaffByLocation(params.l === 'all-locations' ? undefined : params.l));
    } else {
      setParam('l', 'all-locations');
    }
  }, [params]);

  return (
    <>
      <LocationSelector locationId={useParams().l} />

      <div>
        <div className="Table">
          {status === 'loading' ? (
            <div>Loading...</div>
          ) : (
            <SingleTable
              data={staff}
              columns={columns}
              onDelete={() => {}}
              disable={false}
              filterKey={'first_name'}
              aditionalActions={
                <Button onClick={onOpen} className="flex ml-auto w-auto gap-2 bg-primary">
                  <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                  Create User
                </Button>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};
