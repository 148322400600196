import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { ITrackerSeizureDeleteDTO, ITrackerSeizureDTO, ITrackerSeizureRetrieveDTO, ITrackerSeizureUpdateDTO } from '../../presentation/components/seizure/TrackerSeizureMockup';

const TrackerSeizureService = {
  getSeizures: async (body: ITrackerSeizureRetrieveDTO) => {
    try {
      return await axios.post(`trackerSeizure/seizures`, body);
    } catch (error) {
      throw new Error('Error fetching Seizure: ' + error);
    }
  },
  createSeizure: async (body: ITrackerSeizureDTO) => {
    try {
      return await axios.post(`trackerSeizure/seizure`, body);
    } catch (error) {
      throw new Error('Error fetching Seizure: ' + error);
    }
  },
  updateSeizure: async (body: ITrackerSeizureUpdateDTO) => {
    try {
      return await axios.put(`trackerSeizure/seizure`, body);
    } catch (error) {
      throw new Error('Error fetching Seizure: ' + error);
    }
  },
  deleteSeizure: async (body: ITrackerSeizureDeleteDTO) => {
    try {
        return await axios({
            method: "delete",
            url: `trackerSeizure/seizure`,
            data: body
        });
    } catch (error) {
      throw new Error('Error fetching Seizure: ' + error);
    }
  }
};

export default TrackerSeizureService;
