import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Eye, Pencil } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { residentNavigationTabs } from '../pages/UpdateResident';

interface Props {
  id: string;
}

export const TableActions = ({ id }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex gap-2">
        <Button size={'icon'} variant={'outline'} asChild>
          <Link to={`/residents/profile?r=${id}`}>
            <Eye className="size-4" />
          </Link>
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <Pencil className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {residentNavigationTabs.map((item, index) => (
              <DropdownMenuItem key={index} onClick={() => navigate(`/residents/update?r=${id}&t=${item.value}`)}>
                {item.icon}
                <span>{item.title}</span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
};
