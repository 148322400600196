import { Form, FormControl, FormField, FormItem, FormMessage } from '@/common/presentation/components/ui/form';
import { UseFormReturn } from 'react-hook-form';
import { Button } from '@/common/presentation/components/ui/button';
import { DatePickerWithRange } from '@/common/presentation/components/DatePickerWithPresets/DatePickerWithRange';
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';
import { RefreshCwIcon } from 'lucide-react';
import { Input } from '@/common/presentation/components/ui/input';
import { z } from 'zod';
import { FormSchema } from './schema';
import CalendarReportButton from './CalendarReportButton';
import { useTranslation } from 'react-i18next';

interface FilterFormProps {
  categoriesFormatted: { value: string; label: string }[];
  onFilter: (data: z.infer<typeof FormSchema>) => void;
  onCleanFilter: () => void;
  form: UseFormReturn<z.infer<typeof FormSchema>>;
  generateReport: () => Promise<string | boolean>;
}

const FilterForm: React.FC<FilterFormProps> = ({ categoriesFormatted, onFilter, onCleanFilter, form, generateReport }) => {
  const { t } = useTranslation();

  const getSelectedRange = (field: any) => {
    const value = field.value;
    if (value && value.from) {
      return { from: value.from, to: value.to ?? undefined };
    }
    return null;
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFilter)}>
        <div className="flex flex-wrap gap-3">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem className="flex-grow min-w-[200px]">
                <FormControl>
                  <Input
                    placeholder={t('calendar.title')}
                    name={field.name}
                    onChange={(e) => field.onChange(e)}
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="categories_filter"
            render={({ field }) => (
              <FormItem className="flex-grow min-w-[200px]">
                <FormControl>
                  <MultiSelect
                    placeHolder={t('calendar.selectFilterCategoties')}
                    options={categoriesFormatted}
                    customMessage={t('calendar.noCategoties')}
                    selectedOptions={field.value}
                    name={field.name}
                    onChange={(e) => field.onChange(e)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="date_range"
            render={({ field }) => (
              <FormItem className="flex-grow min-w-[200px]">
                <FormControl>
                  <DatePickerWithRange
                    className="w-full"
                    onUpdate={(selectedRange) => field.onChange(selectedRange)}
                    selected={getSelectedRange(field)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button className="w-full md:w-auto  dark:text-white" type="submit">
            {t('form.filter')}
          </Button>
          <Button className="w-full md:w-auto dark:text-white" type="button" onClick={onCleanFilter}>
            <RefreshCwIcon size={16} className="mr-2" />
            {t('form.cleanFilter')}
          </Button>
          <CalendarReportButton generateReportFunction={generateReport} />
        </div>
      </form>
    </Form>
  );
};

export default FilterForm;
