import {
  Dialog,
  DialogContent,
  DialogHeader
} from '@/common/presentation/components/ui/dialog';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import PaymentMethodForm from './PaymentMethodForm';
import { useState } from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import { useTranslation } from 'react-i18next';

const PaymentMethodAdding = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button 
        onClick={() => setDialogOpen(true)} 
        className="bg-primary text-white button rounded-md p-2 w-full lg:w-auto px-2">
          {t('subscription.addNewPaymentMethod')}
      </Button>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <Card className="mt-3 border-none">
              <CardHeader>
                <CardTitle className="text-xl text-start">{t('subscription.paymentMethodInformation')}</CardTitle>
                <CardDescription className="text-base text-start">{t('subscription.paymentWillBeDefault')}</CardDescription>
              </CardHeader>
              <CardContent>
                <PaymentMethodForm setDialogOpen={setDialogOpen} />
              </CardContent>
            </Card>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentMethodAdding;
