import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger
} from '@/common/presentation/components/ui/dialog.tsx';
import { Button } from '@/common/presentation/components/ui/button';
import { IDialogDeleteRecurringEventProp } from '../../domain/calendar.domain';
import { useTranslation } from 'react-i18next';
import styles from "../pages/styles/index.module.css";


export function DialogDeleteRecurringEvent(
  {
    open,
    openChange,
    dispatchDeleteData,
    onChange,
    allEvents = false
  }: IDialogDeleteRecurringEventProp) {

  const { t } = useTranslation();

  return (

    <Dialog open={open} onOpenChange={openChange}>
      <DialogContent className={`${styles.dialogContent} md:max-w-[300px]`} >
        <DialogHeader>
          <DialogTitle>{t('calendar.dialogDeleteRecurringEvent.title')}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col space-y-4 py-4">
          {
            allEvents ? (
              <p className="text-sm text-gray-500 dark:text-gray-300">
                {t('calendar.dialogDeleteRecurringEvent.confirmDeleteEvent')}
              </p>
            ) : (
              <>
                <div className="flex items-center space-x-4">
                  <input
                    type="radio"
                    id="thisEvent"
                    name='radio'
                    className='accent-primary'
                    value="thisEvent"
                    onChange={onChange}
                    defaultChecked
                  />
                  <label htmlFor="thisEvent">{t('calendar.dialogDeleteRecurringEvent.thisEvet')}</label>
                </div>
                <div className="flex items-center space-x-4">
                  <input
                    type="radio"
                    name='radio'
                    id="thisAndfollowingEvents"
                    className="accent-primary"
                    onChange={onChange}
                    value="thisAndfollowingEvents"
                  />
                  <label htmlFor="followingEvents">{t('calendar.dialogDeleteRecurringEvent.followingEvents')}</label>
                </div>
                <div className="flex items-center space-x-4">
                  <input
                    type="radio"
                    name='radio'
                    id="allEvents"
                    className="accent-primary"
                    onChange={onChange}
                    value="allEvents"
                  />
                  <label htmlFor="allEvents">{t('calendar.dialogDeleteRecurringEvent.allEvents')}</label>
                </div>
              </>
            )
          }
        </div>
        <DialogFooter>
          <div className="w-full flex justify-end">
            <DialogTrigger asChild>
              <Button type="submit" className="mr-2" onClick={dispatchDeleteData}>
                {t('calendar.dialogDeleteRecurringEvent.ok')} 
              </Button>

            </DialogTrigger>
            <DialogTrigger asChild>
              <Button type="button" className='bg-background text-primary dark:text-white hover:text-white' onClick={() => openChange(false)}>
                {t('calendar.cancel')}
              </Button>
            </DialogTrigger>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}