import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/common/presentation/components/ui/accordion';
import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import { cn } from '@/lib/utils';
import { RootState } from '@/store/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EmarPrnCard } from './EmarPrnCard';
import { EmarRoutineCard } from './EmarRoutineCard';

interface Props {
  medications: any[];
  selectedDate: Date;
  selectedTime: string;
  emarType: string;
  residentId: string;
}

export const UserAccordion = ({ residentId, medications, selectedDate, selectedTime, emarType }: Props) => {
  const { residents } = useSelector((state: RootState) => state.residents.allResidents);

  const { locationId } = useParams();

  const residentInfo = useMemo(() => {
    return residents.find((resident) => resident.id === residentId);
  }, [residents]);

  return (
    <Accordion type="single" defaultValue="open" collapsible>
      <AccordionItem value="open" data-sate="open">
        <AccordionTrigger className="hover:no-underline">
          <Avatar className={cn('rounded-full hover:cursor-pointer')}>
            <AvatarImage
              src={residentInfo?.image_url}
              alt="User Image"
              className="size-full object-cover transition-all hover:scale-105"
            />
            <AvatarFallback className="bg-secondary text-sm rounded-md">
              {residentInfo?.first_name?.charAt(0).toUpperCase()}
              {residentInfo?.last_name?.charAt(0).toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <span className="ml-4 mr-auto text-lg font-semibold">{`${residentInfo?.first_name} ${residentInfo?.last_name}`}</span>
        </AccordionTrigger>

        <AccordionContent className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {medications.map((medication) => (
              <div key={medication.id}>
                {emarType === 'routine' ? (
                  <EmarRoutineCard
                    data={medications.find((med: any) => med.id === medication.id)}
                    selectedDate={selectedDate ?? new Date()}
                    selectedTime={selectedTime ?? ''}
                    locationId={locationId}
                    residentId={residentInfo?.id}
                  />
                ) : (
                  <EmarPrnCard key={medication.id} data={medication} selectedDate={selectedDate ?? new Date()} />
                )}
              </div>
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
