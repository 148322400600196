import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/common/presentation/components/ui/hover-card';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { cn } from '@/lib/utils';
import { BadgeCheck, BadgeX } from 'lucide-react';
import { useMedicationStore } from '../../domain/stores/useMedicationStore';
import { useMemo } from 'react';

interface Medication {
  medication_type?: {
    name?: string;
  };
  time?: string[];
}

interface TimerSelectorProps {
  data?: any;
  onSelect?: (time: string) => void;
  children?: React.ReactNode;
  completedTimes?: string[];
}

export const TimerSelector = ({ onSelect, completedTimes = [], children }: TimerSelectorProps) => {
  const { medications } = useMedicationStore();

  // Filtrar medicaciones de rutina
  const routineMedications: Medication[] = useMemo(() => {
    return (medications || []).filter((medication: Medication) => medication.medication_type?.name === 'Routine');
  }, [medications]);

  // Función para parsear el tiempo
  const parseTimeString = (timeString: string): string => {
    if (!timeString || typeof timeString !== 'string') return '00:00';
    const parts = timeString.trim().split(' ');
    let hours: number;
    let minutes: number;

    if (parts.length === 2) {
      // Formato esperado: "12:00 PM"
      const [time, period] = parts;
      const [hoursStr, minutesStr] = time.split(':');
      hours = parseInt(hoursStr, 10);
      minutes = parseInt(minutesStr, 10);
      if (isNaN(hours) || isNaN(minutes)) return '00:00';
      if (period === 'PM' && hours < 12) hours += 12;
      if (period === 'AM' && hours === 12) hours = 0;
    } else if (parts.length === 1) {
      // Formato esperado: "12:00"
      const [hoursStr, minutesStr] = parts[0].split(':');
      hours = parseInt(hoursStr, 10);
      minutes = parseInt(minutesStr, 10);
      if (isNaN(hours) || isNaN(minutes)) return '00:00';
    } else {
      return '00:00';
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  // Crear objeto Date desde una cadena de tiempo
  const createTimeDate = (time: string): Date | null => {
    const parsedTime = parseTimeString(time);
    if (parsedTime === '00:00' && time !== '00:00') {
      return null;
    }
    const date = new Date(`1970-01-01T${parsedTime}Z`);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date;
  };

  // Generar lista de tiempos únicos ordenados
  const sortedTimes: string[] = useMemo(() => {
    const times: Date[] = routineMedications.flatMap((medication) => {
      return (medication.time || []).map((time) => createTimeDate(time)).filter((date): date is Date => date !== null);
    });

    const formatTime = (date: Date): string => {
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';
      const adjustedHours = hours % 12 || 12;
      return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
    };

    const uniqueTimes = Array.from(new Set(times.map(formatTime)));
    return uniqueTimes.sort((a, b) => {
      const dateA = createTimeDate(a);
      const dateB = createTimeDate(b);
      if (dateA && dateB) {
        return dateA.getTime() - dateB.getTime();
      }
      return 0;
    });
  }, [routineMedications]);

  // Verificar si el tiempo ha sido completado
  const isTimeCompleted = (time: string): boolean => completedTimes.includes(time);

  return (
    <Tabs className="w-full">
      <TabsList className="w-full h-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 bg-transparent gap-2 p-0">
        {sortedTimes.map((time, index) => (
          <TabsTrigger
            key={index}
            value={time}
            className="text-center py-2 rounded-md border-2 data-[state=active]:border-primary relative"
            onClick={() => onSelect?.(time)}
          >
            {time}
            <HoverCard>
              <HoverCardTrigger asChild>
                <div
                  className={cn(
                    'size-4 rounded-full absolute right-3',
                    isTimeCompleted(time) ? 'bg-green-500' : 'bg-red-500'
                  )}
                />
              </HoverCardTrigger>
              <HoverCardContent className="w-auto">
                <div className="flex justify-center">
                  {isTimeCompleted(time) ? (
                    <div className="flex items-center gap-2">
                      <BadgeCheck className="h-6 w-6 text-green-500" />
                      <p>Todas las medicaciones administradas</p>
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      <BadgeX className="h-6 w-6 text-red-500" />
                      <p>Algunas medicaciones no administradas</p>
                    </div>
                  )}
                </div>
              </HoverCardContent>
            </HoverCard>
          </TabsTrigger>
        ))}
      </TabsList>
      {children}
    </Tabs>
  );
};
