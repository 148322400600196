import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { RootState } from '@/store/store';
import { Eye, Trash } from 'lucide-react';
import { useSelector } from 'react-redux';

export const HistoryTableActions = ({ id }: any) => {
  const { deleteForm, getFormHistory, generateHistoryPdf } = useFormsStore();
  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const handleDelete = async () => {
    try {
      const response = await deleteForm(id);

      toast({
        description: 'Form deleted successfully!',
        variant: 'default'
      });

      if (response) {
        await getFormHistory(resident?.id, 'appraisal_needs_and_services');
      }
    } catch (error) {
      toast({
        description: 'Error deleting form',
        variant: 'destructive'
      });
    }
  };

  const handleView = async () => {
    try {
      const response = await generateHistoryPdf(id);

      const blob = new Blob([response], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url);

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      toast({
        description: 'Error generating PDF',
        variant: 'destructive'
      });
    }
  };

  return (
    <div className="flex gap-2">
      <Button size={'icon'} variant={'outline'} onClick={handleView}>
        <Eye className="h-4 w-4" />
      </Button>
      <Button size={'icon'} variant={'outline'} onClick={handleDelete}>
        <Trash className="h-4 w-4" />
      </Button>
    </div>
  );
};
