import { createOpenAI } from '@ai-sdk/openai';
import { generateText } from 'ai';

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

const openai = createOpenAI({
  apiKey,
  compatibility: 'strict'
});

export const translateToEnglish = async (prompt: string) => {
  const result = await generateText({
    model: openai('gpt-4o-mini'),
    messages: [
      {
        role: 'system',
        content:
          "Your role is to act as a translator. No matter the language in which the user provides a message, your task is to translate it accurately and completely into English. Do not interpret or respond to the content; simply return the message translated into English. Provide translations only in response to the user's input."
      },
      { role: 'user', content: prompt }
    ]
  });

  return result.text;
};
