import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Label } from '@/common/presentation/components/ui/label';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { useWizardFormData } from '@/modules/wizard/infrastructure/providers/FormDataProvider';
import { Brain, ChevronLeftIcon, ExternalLink } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import OrderSummary from '../OrderSummary';
import { FormData as IFormData, IService, Products } from '@/modules/wizard/domain/signup.domain';
import { useSelector } from 'react-redux';
import { localization } from '@/utils/helpers/localization.helper';
import { calculatePrice } from '@/utils/helpers/subscriptions.helper';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Switch } from '@/common/presentation/components/ui/switch';
import TrainingDialog from './components/TrainingDialog';

interface IProps {
  handleBackStep: (step: number) => void;
  handleNextStep: (step: number) => void;
}

interface ITrainingInfo {
  description: string;
  includes: string[];
  price: number;
  ranges: { min_beds: number; max_beds: number };
}

const Step4: React.FC<IProps> = ({ handleBackStep, handleNextStep }) => {
  const { formData, updateFormData } = useWizardFormData();
  const services = useSelector((state: any) => state.signUp.services);
  const { t } = useTranslation();
  const [trainingServiceInfo, setTrainingServiceInfo] = useState<ITrainingInfo>({
    description: '',
    includes: [],
    price: 0,
    ranges: {
      min_beds: 0,
      max_beds: 0
    }
  });

  useEffect(() => {
    const summary = formattSummary(formData);
    updateFormData({
      ...formData,
      summary
    });
  }, []);

  const formattSummary = useCallback(
    (providerData: IFormData) => {
      const { training_plan, essential_plan }: IService = services;

      const prices = essential_plan.modules.reduce((acc: any, module) => {
        const price = calculatePrice(module, null, formData.client_country.code).price;
        acc[module.key] = price;
        return acc;
      }, {});

      let summary: Products = {
        facilities: {
          label: 'Facilities',
          quantity: providerData.facilities_number,
          price: prices['facility']
        },
        beds: {
          label: 'Extra beds',
          quantity: 0,
          price: prices['bed']
        }
      };

      const totalBeds = providerData.facilities_list.reduce((sum, facility) => sum + facility.beds_number, 0);
      const trainingInfo = calculatePrice(training_plan.modules[0], totalBeds, formData.client_country.code);

      setTrainingServiceInfo({
        description: services.training_plan.modules[0].description,
        includes: JSON.parse(services.training_plan.modules[0].includes),
        price: trainingInfo.price,
        ranges: trainingInfo.ranges
      });

      if (providerData.training === 'yes') {
        if (trainingInfo) {
          summary = {
            ...summary,
            training: {
              label: 'Training',
              quantity: 1,
              price: trainingInfo.price
            }
          };
        }
      }

      return summary;
    },
    [services]
  );

  const inputHandler = (name: string, value: string) => {
    const summary = formattSummary({
      ...formData,
      [name]: value
    });

    updateFormData({
      ...formData,
      [name]: value,
      summary
    });
  };

  const getTrainingLabelPrice = () => {
    const { ranges, price } = trainingServiceInfo;
    const formattedPrice = localization.formatPrice(price, formData.client_country.currency);

    if (ranges.max_beds) {
      return t('signUp.trainingLabel', {
        price: formattedPrice,
        min_beds: ranges.min_beds,
        max_beds: ranges.max_beds
      });
    } else {
      return t('signUp.trainingLabelUnlimitedBeds', {
        price: formattedPrice,
        min_beds: ranges.min_beds
      });
    }
  };

  return (
    <>
      <div className="w-11/12 lg:w-9/12 flex md:block items-center justify-center">
        <div className="col-span-3 w-full">
          <div className="justify-start">
            <Button className="p-0 text-base" variant="link" onClick={() => handleBackStep(2)}>
              <ChevronLeftIcon className="w-4 h-4 mr-1 text-base" /> {t('signUp.back')}
            </Button>
          </div>
          <Card className={`border-0 shadow-none flex flex-col`}>
            <CardHeader>
              <CardTitle className="flex flex-row items-center text-2xl font-bold">
                {t('signUp.additionalService')}
              </CardTitle>
            </CardHeader>
            <CardContent className="flex-grow overflow-auto flex flex-col gap-10">
              <div className="flex flex-col gap-2">
                <div className="py-1">
                  <Label htmlFor="facilities_number" className="text-right text-base">
                    {t('signUp.doYouWantTraining')}{' '}
                  </Label>
                </div>
                <div className="p-3 border rounded-md flex flex-row  justify-between">
                  <div className="flex flex-col">
                    <Label className="font-semibold text-base">{t('signUp.caregiverTrainingCAOnly')}</Label>
                  </div>
                  <div>
                    <Switch
                      checked={formData.training === 'yes'}
                      onCheckedChange={(e) => {
                        inputHandler('training', e ? 'yes' : 'no');
                      }}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="bg-secondaryBackground rounded-lg border-dotted border-2 mx-1">
                    <div className="flex flex-row gap-1 m-2">
                      <div>
                        <Brain className="text-primary ml-3 mr-3 mt-3" size={35} />
                      </div>
                      <div className="flex flex-col mt-3 mr-3 justify-start">
                        <p className="text-xs font-medium">{t('signUp.careerSmart')}</p>
                        <TrainingDialog trainingServiceInfo={trainingServiceInfo} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <Label className="text-primary">{getTrainingLabelPrice()}</Label>
                </div>
                <div className="mt-4 flex h-full flex-col lg:hidden">
                  <OrderSummary
                    products={formData.summary}
                    frecuency="monthly"
                    currency={formData.client_country.currency}
                  />
                </div>
                <div className="mt-2">
                  <Button
                    className="w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNextStep(3);
                    }}
                  >
                    {t('signUp.continueToCheckout')}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Step4;
