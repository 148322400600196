const tagaLocale = {
    code: 'tl',
    week: {
      dow: 1, // Lunes es el primer día de la semana
      doy: 4  // La semana que contiene Jan 4th es la primera semana del año
    },
    buttonText: {
      prev: 'Nakaraan',
      next: 'Susunod',
      today: 'Ngayon',
      month: 'Buwan',
      week: 'Linggo',
      day: 'Araw',
      list: 'Listahan'
    },
    weekText: 'Wk',
    allDayText: 'Buong araw',
    moreLinkText: 'higit pa',
    noEventsText: 'Walang mga kaganapan na ipapakita',
    // Agrega más traducciones según sea necesario
    monthNames: [
      'Enero', 'Pebrero', 'Marso', 'Abril', 'Mayo', 'Hunyo',
      'Hulyo', 'Agosto', 'Setyembre', 'Oktubre', 'Nobyembre', 'Disyembre'
    ],
    
    monthNamesShort: [
      'Ene', 'Peb', 'Mar', 'Abr', 'May', 'Hun',
      'Hul', 'Ago', 'Set', 'Okt', 'Nob', 'Dis'
    ],
    dayNames: [
      'Linggo', 'Lunes', 'Martes', 'Miyerkules', 'Huwebes', 'Biyernes', 'Sabado'
    ],
    dayNamesShort: ['Lin', 'Lun', 'Mar', 'Miy', 'Huw', 'Biy', 'Sab']
  };

  export default tagaLocale;