import { ConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AuthContextProvider from './auth/infrastructure/providers/AuthContextProvider';
import { ThemeProvider } from './common/infrastructure/providers/ThemeProvider';
import { Toaster } from './common/presentation/components/ui/toaster';
import './i18n';
import './index.css';
import { SubscriptionProvider } from './modules/subscription/infraestructure/providers/SubscriptionProvider';
import { AppRouter } from './routes/AppRouter';
import store from './store/store';
import { LocationProvider } from './modules/locations/infrastructure/providers/LocationContextProvider';

const Root = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AuthContextProvider>
          <ConfigurationsProvider>
            <LocationProvider>
              <SubscriptionProvider>
                <ThemeProvider defaultTheme="light" storageKey="ballancify-ui-theme">
                  <AppRouter />
                  <Toaster />
                </ThemeProvider>
              </SubscriptionProvider>
            </LocationProvider>
          </ConfigurationsProvider>
        </AuthContextProvider>
      </Provider>
    </BrowserRouter>
  );
};

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<Root />);
} else {
  throw new Error("No se encontró el elemento raíz 'root'");
}
