import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ResidentMedicalInformationService = {
  updateHealthInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/health/${data.resident_id}`, { ...data });
    return response;
  },
  createOrUpdateDiagnosisInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/diagnosis/${data.resident_id}`, { ...data });
    return response;
  },
  deleteDiagnosisInformation: async (data: any) => {
    const response = await axios.delete(
      `/residents/medical-information/diagnosis/${data.id}/resident/${data.resident_id}`
    );
    return response;
  },
  createOrUpdateAllergyInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/allergy/${data.resident_id}`, { ...data });
    return response;
  },
  deleteAllergyInformation: async (data: any) => {
    const response = await axios.delete(
      `/residents/medical-information/allergy/${data.id}/resident/${data.resident_id}`
    );
    return response;
  },
  createOrUpdateExamOrProcedureInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/exam-or-procedure/${data.resident_id}`, {
      ...data
    });
    return response;
  },
  deleteExamOrProcedureInformation: async (data: any) => {
    const response = await axios.delete(
      `/residents/medical-information/exam-or-procedure/${data.id}/resident/${data.resident_id}`
    );
    return response;
  },
  createOrUpdateMentalCondidionInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/mental-condition/${data.resident_id}`, {
      ...data
    });
    return response;
  },
  createOrUpdateTubercolosisInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/tuberculosis/${data.resident_id}`, { ...data });
    return response;
  },
  createOrUpdateActionsInformation: async (data: any) => {
    const response = await axios.post(`/residents/medical-information/actions/${data.resident_id}`, { ...data });
    return response;
  },

  searchCode: async (code: string) => {
    const response = await axios.get(`/residents/medical-information/search-code/${code}`);
    return response;
  },

  createDocumentCategory: async (data: any) => {
    const response = await axios.post(`/residents/documents/category/${data.category_id}`, { ...data });
    return response;
  }
};

export default ResidentMedicalInformationService;
