import { Button } from '@/common/presentation/components/ui/button';
import { usePrnDialog } from '@/modules/emar/infrastructure/hooks/UsePrnDialog';
import { Pencil } from 'lucide-react';

export const OverviewPrnTableActions = ({ data }: any) => {
  const { onOpen } = usePrnDialog();

  return (
    <Button
      size={'icon'}
      variant={'outline'}
      onClick={() =>
        onOpen(
          {
            time_given: data?.emar_medication_prn?.dispensed_at,
            follow_up: data?.emar_medication_prn?.follow_up,
            reason: data?.emar_medication_prn?.reason,
            result: data?.emar_medication_prn?.result,
            comments: data?.emar_medication_prn?.comments,
            level_of_pain: data?.emar_medication_prn?.level_of_pain,
            resident_id: data?.emar_medication_prn?.resident_id,
            id: data?.emar_medication_prn?.resident_medication_id,
            dispense_id: data?.emar_medication_prn?.id
          },
          'all-locations'
        )
      }
    >
      <Pencil className="size-4" />
    </Button>
  );
};
