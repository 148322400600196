import { LucideIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Item } from './item';

import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import * as lucide from 'lucide-react';

export interface ItemModel {
  id: string;
  name: string;
  order: number;
  path: string;
  icon: string;
  permission_id?: null;
  created_at: Date;
  updated_at: Date;
  submenus: ItemModel[];
  menu_id?: string;
}

export const NavigationList = ({ isMobile, mobileCollapse }: { isMobile: boolean; mobileCollapse: () => void }) => {
  const { menus } = useConfigurationsProvider();

  const menuItems = menus || [];

  const [items, setItems] = useState<ItemModel[]>([]);
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
  const level = 0;

  const navigate = useNavigate();

  const onExpand = (id: string) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  useEffect(() => {
    const userMenus = JSON.parse(JSON.stringify(menuItems));

    userMenus.forEach((menu: any) => {
      if (menu.icon === null || menu.icon === undefined) {
        menu.icon = 'LayoutDashboard';
      }
    });

    if (userMenus.length > 0) {
      userMenus.sort((a: any, b: any) => a.order - b.order);
      setItems(userMenus);
    }
  }, [menuItems]);

  const getIcon = (icon: string) => {
    const Accessibility = icon as keyof typeof lucide;
    return lucide[Accessibility] as LucideIcon;
  };

  const onItemClick = (url: string) => {
    navigate(url);

    if (isMobile) {
      mobileCollapse?.();
    }
  };

  return (
    <>
      {items.length === 0 && <p className="pl-3">No navigation items</p>}

      {items.map((item) => {
        return (
          <div key={item.id}>
            <Item
              id={item.id}
              label={item.name}
              icon={getIcon(item.icon)}
              active={false}
              level={level}
              hasSubmenu={item.submenus.length > 0}
              expanded={!!expandedItems[item.id]}
              onExpand={() => onExpand(item.id)}
              onClick={() => onItemClick(item.path)}
            />
            {expandedItems[item.id] &&
              item.submenus &&
              item.submenus.sort((a, b) => a.order - b.order).map((submenu) => (
                <div key={submenu.id}>
                  <Item
                    id={submenu.id}
                    label={submenu.name}
                    icon={getIcon(item.icon)}
                    active={false}
                    level={level + 1}
                    expanded={!!expandedItems[submenu.id]}
                    onClick={() => onItemClick(submenu.path)}
                  />
                </div>
              ))}
          </div>
        );
      })}
    </>
  );
};
