import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { Label } from '@/common/presentation/components/ui/label';
import { CardHeader, CardTitle } from '@/components/ui/card';
import { ModuleI } from '@/modules/subscription/domain/makerPlace.domain';
import { IService } from '@/modules/wizard/domain/signup.domain';
import { useWizardFormData } from '@/modules/wizard/infrastructure/providers/FormDataProvider';
import { RootState } from '@/store/store';
import { Check, ChevronLeftIcon } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PlanCard from './components/PlanCard';

interface IProps {
  handleBackStep: (step: number) => void;
  handleNextStep: (step: number) => void;
}

interface IServiceInfo {
  price: string;
  includes: Array<string>;
}

const Step2: React.FC<IProps> = ({ handleBackStep, handleNextStep }) => {
  const services: IService = useSelector((state: RootState) => state.signUp.services);
  const { formData } = useWizardFormData();
  const { t } = useTranslation();
  const [serviceInfo, setServiceInfo] = useState<IServiceInfo>({
    price: '',
    includes: []
  });

  useEffect(() => {
    const { service, modules } = services['essential_plan'];
    const facilityModule: ModuleI | undefined = modules.find((value) => value.key === 'facility');

    if (!facilityModule) return;

    const price = getPriceByCountry(facilityModule, formData.client_country.code);

    setServiceInfo({
      price: formatPrice(price, formData.client_country.currency),
      includes: JSON.parse(service.includes)
    });
  }, []);

  const formatPrice = useCallback(
    (price: string, currency: string) => {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency
      });
      return formatter.format(parseFloat(price));
    },
    [formData]
  );

  const getPriceByCountry = useCallback(
    (facilityModule: ModuleI, countryCode: string): string => {
      return facilityModule.prices.find((price) => price.country.code === countryCode)?.price ?? '';
    },
    [formData]
  );

  return (
    <>
      <div className="w-11/12 lg:w-9/12 flex md:block items-center justify-center">
        <div className="col-span-3 w-full">
          <div className="justify-start">
            <Button className="p-0 text-base" variant="link" onClick={() => handleBackStep(2)}>
              <ChevronLeftIcon className="w-4 h-4 mr-1 text-base" /> {t('signUp.back')}
            </Button>
          </div>
          <Card className={`border-0 shadow-none flex flex-col`}>
            <CardHeader>
              <CardTitle className="flex flex-row items-center text-2xl font-bold">
                {t('signUp.planInformation')}
              </CardTitle>
              <div className="flex w-full items-center justify-between text-base">
                <div>{t('signUp.ChoosePlanWorks')}</div>
              </div>
            </CardHeader>
            <CardContent className="flex-grow overflow-auto flex flex-col gap-10">
              <PlanCard price={serviceInfo.price} />
              <div className="flex flex-col gap-2">
                <Label className="font-bold text-lg">{t('signUp.includes')}</Label>
                <div className="max-h-80 overflow-y-auto">
                  <ul className="grid grid-cols-1 md:grid-cols-2 gap-1">
                    {serviceInfo.includes.map((value, index) => (
                      <li key={index} className="flex flex-row gap-1 py-1">
                        <div className="bg-primary rounded-full size-4 flex justify-center items-center">
                          <Check color="white" size={12} strokeWidth={4} />
                        </div>
                        <Label className="text-sm">{t(`signUp.${value}`)}</Label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex gap-3 flex-col">
                <Button className="w-full" onClick={() => handleNextStep(2)}>
                  {t('signUp.continue')}
                </Button>
                <Button variant="link" className="w-full">
                  {t('signUp.bookDemo')}
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Step2;
