import React, { createContext, useContext, useEffect, useState } from 'react';
import { FormData } from '../../domain/signup.domain';


interface FormDataContextType {
  formData: FormData;
  updateFormData: (newData: Partial<FormData>) => void;
  isWideScreen: boolean;
}

export const FormDataContext = createContext<FormDataContextType | undefined>(undefined);

export const useWizardFormData = (): FormDataContextType => {
  const context = useContext(FormDataContext);

  if (!context) {
    throw new Error('useWizardFormData must be used within a FormDataProvider');
  }

  return context;
};

interface IProps {
  children: React.ReactNode;
}

export const FormDataProvider: React.FC<IProps> = ({ children }) => {
  const initialFormData: FormData = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    client_name: '',
    facilities_number: 1,
    summary: {
      training: {
        label: '',
        quantity: 0,
        price: 0
      },
      facility: {
        label: '',
        quantity: 0,
        price: 0
      },
      beds: {
        label: '',
        quantity: 0,
        price: 0
      },
    },
    training: 'yes',
    facilities_list: [{ beds_number: 10 }],
    card_number: '',
    expiry_card: '',
    city: '',
    country: '',
    client_country: {
      id: '',
      name: '',
      code: '',
      currency: ''
    },
    account: {
      user_name: '',
      password: '',
      confirm_password: ''
    },
    postal_code: '',
    cvv: '',
    address_1: '',
    address_2: '',
    state: '',
    name_on_card: '',
    errors: {}
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isWideScreen, setIsWideScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1400);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const updateFormData = (newData: Partial<FormData>) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const contextValue: FormDataContextType = {
    formData,
    updateFormData,
    isWideScreen
  };

  return <FormDataContext.Provider value={contextValue}>{children}</FormDataContext.Provider>;
};
