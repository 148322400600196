import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { usePlanOfCareStore } from '@/modules/residents/domain/stores/plan-of-care/planOfCareStore';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HistoryTableHistoryTableColumns } from './PlanOfCareHistoryTableColumns';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';

export const PlanOfCareHistory = () => {
  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const { getPlanOfCareHistory, planOfCareHistory } = usePlanOfCareStore();

  const getPlanOfCareHistoryReq = async (residentId: string) => {
    await getPlanOfCareHistory(residentId);
  };

  useEffect(() => {
    getPlanOfCareHistoryReq(resident?.id);
  }, [resident]);

  return (
    <div>
      <CustomAccordion
        title={`Plan of Care History for ${resident?.first_name} ${resident?.last_name}`}
        defaultOpen={true}
        openOption={false}
        separator
      >
        <SingleTable
          data={planOfCareHistory}
          columns={HistoryTableHistoryTableColumns}
          onDelete={() => {}}
          disable={false}
          filter={false}
        />
      </CustomAccordion>
    </div>
  );
};
