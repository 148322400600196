import { create } from 'zustand';
import ResidentMedicalInformationService from '../../../infrastructure/services/MedicalInformationService';

type MedicalInformationState = {
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  updateHealthInformation: (data: any) => Promise<any>;
  createOrUpdateDiagnosisInformation: (data: any) => Promise<any>;
  deleteDiagnosisInformation: (data: any) => Promise<any>;
  createOrUpdateAllergyInformation: (data: any) => Promise<any>;
  deleteAllergyInformation: (data: any) => Promise<any>;
  createExamOrProcedureInformation: (data: any) => Promise<any>;
  deleteExamOrProcedureInformation: (data: any) => Promise<any>;
  createOrUpdateMentalCondidionInformation: (data: any) => Promise<any>;
  createOrUpdateTubercolosisInformation: (data: any) => Promise<any>;
  createOrUpdateActionsInformation: (data: any) => Promise<any>;
  searchCode: (code: string) => Promise<any>;
};

export const useMedicalInformationStore = create<MedicalInformationState>((set) => ({
  status: 'idle',
  error: null,

  updateHealthInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.updateHealthInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  createOrUpdateDiagnosisInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.createOrUpdateDiagnosisInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deleteDiagnosisInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.deleteDiagnosisInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  createOrUpdateAllergyInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.createOrUpdateAllergyInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deleteAllergyInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.deleteAllergyInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  createExamOrProcedureInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.createOrUpdateExamOrProcedureInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deleteExamOrProcedureInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.deleteExamOrProcedureInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  createOrUpdateMentalCondidionInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.createOrUpdateMentalCondidionInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  createOrUpdateTubercolosisInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.createOrUpdateTubercolosisInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  createOrUpdateActionsInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.createOrUpdateActionsInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  searchCode: async (code: string) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentMedicalInformationService.searchCode(code);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
