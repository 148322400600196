import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { generateReport, getResume, setUpdated } from '../../slices/CalendarSlice';
import { AppDispatch, RootState } from '@/store/store';
import {
  ICalendarResumeData,
  ICalendarTableItem,
  ITaskEventList,
  relatedToTypes,
  TypeRelatedTo
} from '../../../domain/calendar.domain';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import FilterForm from './FilterForm';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import ResumeTable from './ResumeTable';
import { Button } from '@/common/presentation/components/ui/button';
import { Eye, Pencil } from 'lucide-react';
import { FormSchema } from './schema';
import { InfoEventCard } from '../InfoEventCard';
import { TaskEventDialog } from '../DialogTaskEvent/DialogTaskEvent';

interface IProps {
  residentSelectedId?: string;
  userSelectedId?: string;
  locationSelectedId: string;
  selectedRelatedTo: TypeRelatedTo;
}

const CalendarList: React.FC<IProps> = ({
  selectedRelatedTo,
  userSelectedId = 'all-users',
  residentSelectedId = 'all-residents',
  locationSelectedId
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const categories = useSelector((state: RootState) => state?.calendar.categories);
  const didUpdated = useSelector((state: RootState) => state.calendar.updated);
  const { formatDate } = useDateHelpers();
  const { setParam } = useRouteParams();

  const [taskEvents, setTaskEvents] = useState<ICalendarTableItem[]>([]);
  const [categoriesFormatted, setCategoriesFormatted] = useState<{ value: string; label: string }[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [isOpenInfo, setIsOpenInfo] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [eventTaskSelected, setEventTaskSelected] = useState<string>('');
  const [totalRows, setTotalRows] = useState<number>(0);
  const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema)
  });

  const lastFilters: ICalendarResumeData = {
    categories: [],
    date_range: { from: '', to: '' },
    resident_id: residentSelectedId,
    user_id: userSelectedId,
    location_id: locationSelectedId,
    related_to: selectedRelatedTo,
    per_page: rowsPerPage,
    page: currentPage,
    title: undefined
  };

  useEffect(() => {
    if (categories.length > 0) {
      formatCategories();
    }
  }, [categories]);

  useEffect(() => {
    if (didUpdated) {
      dispatch(setUpdated(false));
      fetchData(lastFilters, currentPage, rowsPerPage);
    }
  }, [didUpdated]);

  useEffect(() => {
    if (locationSelectedId && residentSelectedId && userSelectedId) {
      const updatedFilters = {
        ...lastFilters,
        related_to: selectedRelatedTo,
        resident_id: residentSelectedId,
        user_id: userSelectedId,
        location_id: locationSelectedId
      };
      fetchData(updatedFilters, currentPage, rowsPerPage);
    }
  }, [rowsPerPage, residentSelectedId, userSelectedId, locationSelectedId, selectedRelatedTo]);

  const formatCategories = () => {
    const formatted = categories
      .map(({ id, name }) => ({ value: id, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setCategoriesFormatted(formatted);
  };

  const fetchData = async (filterData: ICalendarResumeData, page: number, perPage: number) => {
    setIsLoading(true);
    const response = await dispatch(getResume({ ...filterData, page, per_page: perPage }));
    if (!response.payload) {
      setIsLoading(false);
      return;
    }

    const { data, total } = response.payload.data;
    const events = data.map((item: ITaskEventList) => ({
      id: item.id,
      title: item.title,
      detail: item.detail ? `${item.detail.substring(0, 30)}...` : '',
      date_time: formatDate(item.start_date_time),
      related_to: item.related_to,
      resident_id: item.resident.length > 0 ? (item.resident.length > 1 ? 'all-residents' : item.resident[0].id) : '',
      user_id:
        item.task_event_has_users.length > 0
          ? item.task_event_has_users.length > 1
            ? 'all-users'
            : item.task_event_has_users[0].users.id
          : '',
      user: item.related_to === relatedToTypes.residents ? getNamesResident(item) : getNamesUsers(item),
      category: item.category.name,
      created_by: `${item.user.first_name} ${item.user.last_name}`
    }));

    let eventsArray = events;
    if (page !== currentPage) {
      eventsArray = [...taskEvents, ...events];
    }

    setTaskEvents(eventsArray);

    if (total > eventsArray.length) {
      setHasMoreRows(true);
    } else {
      setHasMoreRows(false);
    }

    setTotalRows(total);
    setIsLoading(false);
  };

  const getNamesResident = (eventItem: ITaskEventList): string => {
    if (eventItem.resident.length > 0) {
      return eventItem.resident.length > 1
        ? 'Everyone'
        : `${eventItem.resident[0].first_name} ${eventItem.resident[0].last_name}`;
    }
    return '';
  };

  const getNamesUsers = (eventItem: ITaskEventList): string => {
    if (eventItem.task_event_has_users.length > 0) {
      return eventItem.task_event_has_users.length > 1
        ? 'Everyone'
        : `${eventItem.task_event_has_users[0].users.first_name} ${eventItem.task_event_has_users[0].users.last_name}`;
    }
    return '';
  };

  const filter = async (data: z.infer<typeof FormSchema>) => {
    try {
      const { categories_filter, date_range, title } = data;
      const filterData: ICalendarResumeData = {
        ...lastFilters,
        categories: categories_filter ?? [],
        date_range: {
          from: date_range?.from?.toISOString().split('T')[0] ?? '',
          to: date_range?.to?.toISOString().split('T')[0] ?? ''
        },
        title: title
      };
      fetchData(filterData, 1, rowsPerPage);
    } catch (error) {
      console.error('Error filtering', error);
    }
  };

  const cleanFilter = () => {
    form.reset({ title: '', categories_filter: [], date_range: null });
    fetchData(
      {
        ...lastFilters,
        title: '',
        date_range: null
      },
      1,
      rowsPerPage
    );
    setParam('r', 'all-residents');
  };

  const generateReportPdf = async (): Promise<string | boolean> => {
    try {
      const response = await dispatch(generateReport(lastFilters)).unwrap();
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = await URL.createObjectURL(blob);
      const popupTest = window.open(url, '_blank');
      if (!popupTest || popupTest.closed || typeof popupTest.closed == 'undefined') {
        return url;
      }
      return true;
    } catch (error) {
      console.error('Error generating report:', error);
      return false;
    }
  };

  const renderActionsColumn = (row: ICalendarTableItem) => (
    <div className="flex gap-2 justify-end">
      <Button variant="outline" size="icon" onClick={() => showTaskEventInfo(row)}>
        <Eye className="h-4 w-4" />
      </Button>
      <Button variant="outline" size="icon" onClick={() => editTaskEvent(row)}>
        <Pencil className="h-4 w-4" />
      </Button>
    </div>
  );

  const columnsCalendarResume = [
    {
      key: 'date_time',
      id: 'date_time',
      labelTranslationKey: 'calendar.dateTime',
      value: 'date_time',
      link: true,
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'user',
      id: 'user',
      labelTranslationKey: selectedRelatedTo === relatedToTypes.residents ? 'calendar.resident' : 'calendar.user',
      render: (row: ICalendarTableItem) => (
        <span onClick={() => relatedToChangeHandler(row)} className="cursor-pointer text-primary hover:underline">
          {row.user}
        </span>
      ),
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'title',
      id: 'title',
      labelTranslationKey: 'calendar.title',
      value: 'title',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'detail',
      id: 'detail',
      labelTranslationKey: 'calendar.detail',
      value: 'detail',
      classes: '',
      visible: false,
      sortable: true
    },
    {
      key: 'category',
      id: 'category',
      labelTranslationKey: 'calendar.category',
      value: 'category',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'created_by',
      id: 'created_by',
      labelTranslationKey: 'calendar.createdBy',
      value: 'created_by',
      visible: false,
      sortable: true
    },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: 'calendar.actions',
      render: renderActionsColumn,
      width: 'w-64',
      static: true
    }
  ];

  const relatedToChangeHandler = (row: ICalendarTableItem) => {
    if (row.related_to === relatedToTypes.residents) {
      setParam('r', row.resident_id);
    } else {
      setParam('u', row.user_id);
    }
  };

  const showTaskEventInfo = (row: ICalendarTableItem) => {
    setEventTaskSelected(row.id);
    setIsOpenInfo(true);
  };

  const editTaskEvent = (row: ICalendarTableItem) => {
    setEventTaskSelected(row.id);
    setIsOpenEdit(true);
  };

  const tableSorting = useCallback((columnKey: string, direction: string) => {
    const sortedData = [...taskEvents].sort((a: any, b: any) => {
      if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setTaskEvents(sortedData);
  }, []);

  const loadMore = useCallback(() => {
    if (!hasMoreRows || isLoading) return;
    setCurrentPage(currentPage + 1);
    fetchData(lastFilters, currentPage + 1, rowsPerPage);
  }, [hasMoreRows, currentPage, fetchData]);

  return (
    <CustomCard title={`Resume`} description="">
      <div className="flex flex-col gap-8">
        <ResumeTable
          filters={
            <FilterForm
              form={form}
              categoriesFormatted={categoriesFormatted}
              onFilter={filter}
              onCleanFilter={cleanFilter}
              generateReport={generateReportPdf}
            />
          }
          taskEvents={taskEvents}
          loadMore={loadMore}
          hasMore={hasMoreRows}
          columnsCalendarResume={columnsCalendarResume}
          handleSort={tableSorting}
          isLoading={isLoading}
        />
        <InfoEventCard
          open={isOpenInfo}
          onClose={() => setIsOpenInfo(false)}
          eventTaskId={eventTaskSelected}
          fromCalendar={false}
        />
        {isOpenEdit && eventTaskSelected.length > 0 && (
          <TaskEventDialog
            open={isOpenEdit}
            onClose={() => setIsOpenEdit(false)}
            isUpdate={true}
            idUpdate={eventTaskSelected}
            fromCalendar={false}
          />
        )}
      </div>
    </CustomCard>
  );
};

export default CalendarList;
