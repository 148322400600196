import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
 } from '@/common/presentation/components/ui/dialog';
 import { Button } from '@/common/presentation/components/ui/button';
 import { Label } from '@/common/presentation/components/ui/label';
 import { Input } from '@/common/presentation/components/ui/input';
import { useEffect, useState } from 'react';
import { Pencil } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { getTasks, updateTask } from '../../slices/TrackerCleaningSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { Checkbox } from '@/components/ui/checkbox';

export function TrackerCleaningTaskEditDialog(props: { id: string, name: string, status: string }) {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { t } = useTranslation();
    const { toast } = useToast();
    const [values, setValues] = useState({
        name: "",
        status: false
    });

    useEffect(() => {
        setValues({
            name: props.name,
            status: props.status === "active" ? true : false
        });
    }, []);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleCheckboxChange = (event: any) => {
        setValues({
            ...values,
            status: event
        });
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(values.name) {
            await dispatch(updateTask(
                {
                    id: props.id,
                    ...values,
                    status: values.status ? 'active' : 'inactive'
                }
            ));
    
            fetchData();
            toast(
                {
                    description: t("cleaning.create.task.edit.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.create.task.edit.toast.required")
                }
            );
        }
    }

    const fetchData = async () => {
        await dispatch(getTasks(
            {
                location_id: params.l
            }
        ));
    };

    return (
        <div>
            <Dialog>
                <DialogTrigger asChild>
                    <Button variant="outline" size="icon" className='mr-2'>
                        <Pencil className="h-4 w-4" />
                    </Button>
                </DialogTrigger>

                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>
                            {t("cleaning.create.task.edit.title")}
                        </DialogTitle>
                    </DialogHeader>

                <form className="grid gap-4 py-4" onSubmit={handleSubmit}>
                    <div>
                        <Label htmlFor="name" className="text-right">
                            {t("cleaning.create.task.edit.form.name")}
                        </Label>

                        <Input
                            id="name"
                            name="name"
                            defaultValue="@peduarte"
                            className="col-span-3"
                            value={values.name}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="flex items-center space-x-2">
                        <Checkbox id="terms" onCheckedChange={handleCheckboxChange} checked={values.status} />

                        <label
                            htmlFor="terms"
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                            Active
                        </label>
                    </div>
                    
                    <DialogClose asChild>
                        <Button type="submit">
                            {t("cleaning.create.task.edit.form.submit")}
                        </Button>
                    </DialogClose>
                </form>
        </DialogContent>
        </Dialog>
        </div>
    );
};
