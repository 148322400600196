import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useTranslation } from 'react-i18next';

const CustomCombobox = ({ options = [], onSelect, onCreate, placeholder, value, onChange, entityType, fields }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const onCreateSubmit = (data) => {
    onCreate(data);
    setIsCreating(false);
    reset();
    setSearchTerm('');
  };

  return (
    <div className="relative w-full max-w-xs">
      <Select
        open={open}
        onOpenChange={setOpen}
        value={value}
        onValueChange={(selectedValue) => {
          const selected = options.find((option) => option.id === selectedValue);
          onSelect(selected);
          onChange(selectedValue);
          setOpen(false);
          setSearchTerm('');
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="w-full">
          <div className="p-2">
            <Input
              type="text"
              placeholder={t('medications.customCombobox.searchPlaceholder')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-2"
            />
            {isCreating ? (
              <form onSubmit={handleSubmit(onCreateSubmit)} className="space-y-2">
                {fields.map((field) => (
                  <Input
                    key={field.name}
                    {...register(field.name)}
                    className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0 placeholder:text-gray-300"
                    placeholder={t(field.placeholder)}
                  />
                ))}
                <div className="flex justify-between">
                  <Button type="button" variant="outline" size="sm" onClick={() => setIsCreating(false)}>
                    {t('medications.customCombobox.cancel')}
                  </Button>
                  <Button type="submit" size="sm">
                    {t('medications.customCombobox.create')}
                  </Button>
                </div>
              </form>
            ) : (
              <Button variant="outline" className="w-full mb-2" onClick={() => setIsCreating(true)}>
                <Plus className="mr-2 h-4 w-4" /> {t('medications.customCombobox.createNew', { entityType })}{' '}
              </Button>
            )}
            {!isCreating &&
              filteredOptions.map((option) => (
                <SelectItem key={option.id} value={option.id}>
                  <div className="flex items-center">{option.name}</div>
                </SelectItem>
              ))}
          </div>
        </SelectContent>
      </Select>
    </div>
  );
};

export default CustomCombobox;
