import useRouteParams from '@/common/hooks/RouteParamsHook';
import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { EmarTabs } from '../EmarTabs';
import { overviewPrnColumns } from './OverviewPrnColumns';
import { overviewRoutineColumns } from './OverviewRoutineColumns';

export const OverViewTable = () => {
  const { params } = useRouteParams();

  const { medications, getMedicationsTimesByResident } = useMedicationStore();

  const getResidentMedications = async () => {
    try {
      getMedicationsTimesByResident('all-locations', 'all-residents', format(new Date(), 'yyyy-MM-dd'));
    } catch (error) {
      console.log({ error });
    }
  };

  const exceptionMedications = useMemo(() => {
    if (params.e === 'routine') {
      return medications.reduce((acc, medication) => {
        const filterExceptionMedications = medication.emar_medication_exceptions.map((med: any) => {
          const data = {
            ...medication,
            emar_medication_exception: med
          };
          //   delete data.emar_medication_exceptions;
          return data;
        });

        return [...acc, ...filterExceptionMedications];
      }, []);
    }

    if (params.e === 'prn') {
      return medications.reduce((acc, medication) => {
        const filterExceptionMedications = medication.emar_medication_prns.map((med: any) => {
          const data = {
            ...medication,
            emar_medication_prn: med
          };
          //   delete data.emar_prn_exceptions;
          return data;
        });

        return [...acc, ...filterExceptionMedications];
      }, []);
    }

    return [];
  }, [medications, params.e]);

  useEffect(() => {
    if (!medications.length) {
      getResidentMedications();
    }
  }, [medications]);

  return (
    <SingleTable
      data={exceptionMedications}
      columns={params.e === 'routine' ? overviewRoutineColumns : overviewPrnColumns}
      onDelete={() => {}}
      aditionalActions={<EmarTabs />}
      filter={false}
      filterKey="name"
    />
  );
};
