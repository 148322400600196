import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/components/ui/input';
import { Loader } from 'lucide-react';

const formSchema = z.object({
  name: z.string().min(3, {
    message: 'The name of the category must be at least 3 characters.'
  })
});

type FormValues = z.infer<typeof formSchema>;

interface CategoryFormProps {
  status: 'loading' | 'idle' | 'success' | 'error';
  defaultValues: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  handleLabel?: string;
}

export const CategoryForm = ({ defaultValues, onSubmit, status, handleLabel }: CategoryFormProps) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange'
  });

  const handleSubmit = (data: z.infer<typeof formSchema>) => {
    onSubmit({ ...data });
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} disabled={status === 'loading'} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" className="col-span-full w-full" disabled={status === 'loading'}>
            {status === 'loading' && <Loader className="mr-2 h-4 w-4 animate-spin" />}
            {handleLabel || 'Create Category'}
          </Button>
        </form>
      </Form>
    </>
  );
};
