import { z } from 'zod';

export const DateRangeSchema = z.object({
  from: z.union([z.date(), z.undefined()]),
  to: z.union([z.date(), z.undefined()])
});

export const CategoriesFilterSchema = z.object({
  value: z.string().nonempty({ message: 'Category value cannot be empty' }),
  label: z.string().nonempty({ message: 'Category label cannot be empty' })
});

export const FormSchema = z.object({
  categories_filter: z.array(CategoriesFilterSchema).optional(),
  date_range: DateRangeSchema.nullable().optional(),
  resident: z.string().optional()
});

export interface ICalendarResumeData {
  resident_id: string;
  location_id: string;
  page: number;
  per_page: number;
  title?: string;
  resident?: string;
  categories?: { value: string; label: string }[];
  date_range?: { from: string; to: string };
}
