import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice';
import { AppDispatch, RootState } from '@/store/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../../../../common/presentation/components/ui/card';
import { Input } from '../../../../../common/presentation/components/ui/input';
import { ScrollArea, ScrollBar } from '../../../../../common/presentation/components/ui/scroll-area';
import { toast } from '../../../../../common/presentation/components/ui/use-toast';
import { ImageCard } from '../../../../../common/presentation/components/Selectors/ImageCard';

interface StaffSelectorProps {
  onSelect?: (id: string) => void;
  className?: React.ReactNode;
  locationId?: string;
  staffId?: string[];
}

export const StaffSelector = ({
  onSelect,
  className,
  locationId = 'all-locations',
  staffId = [],
}: StaffSelectorProps) => {
  const { staff, status } = useSelector((state: RootState) => state.staff.allStaff);
  const [selectedUser, setSelectedUser] = useState(staffId || []);

  const dispatch = useDispatch<AppDispatch>();

  const getStaff = async () => {
    const response: any = await dispatch(
      getStaffByLocation(locationId === 'all-locations' ? undefined : locationId)
    );
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
  };

  const onSelectedUser = (id: string) => {
    const staff = [...selectedUser];
    const index = staff.indexOf(id);
    if (index !== -1) {
        staff.splice(index, 1);
    } else {
        staff.push(id);
    }

    setSelectedUser(staff);
    onSelect?.(id);
  };

  useEffect(() => {
    getStaff();
  }, [dispatch]);

  useEffect(() => {

  }, [selectedUser]);

  return (
    <div className={cn(className)}>
      <Card className="border-t-4 border-t-primary/80">
        <CardHeader>
          <CardTitle className="flex flex-row items-center text-lg font-bold">Staff</CardTitle>
          <CardDescription className="flex flex-col md:flex-row items-center">
            {staff.length > 0 && (
              <Input placeholder="Filter Staff" className="w-full md:w-[350px] mt-4 md:mt-0 ml-auto" />
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {status === 'loading' ? (
            <div className="flex items-center justify-start">
              <div className="flex flex-col items-center gap-2">
                <Skeleton className="h-20 w-20 rounded-full bg-primary/20" />
                <Skeleton className="h-4 w-16 mt-3 rounded-full bg-primary/20" />
                <Skeleton className="h-4 w-16 rounded-full bg-primary/20" />
              </div>
            </div>
          ) : (
            <ScrollArea>
              <div className="flex gap-4 mb-4">
                {staff.length === 0 && (
                  <div className="w-full flex flex-col gap-2 items-center justify-center">
                    <p>No staff found</p>
                  </div>
                )}

                {staff.length > 0 && (
                  <div className="flex gap-2">
                    {staff.map((staff, index) => (
                      <ImageCard
                        key={index}
                        item={{
                          id: staff.id,
                          firstName: staff.first_name,
                          lastName: staff.last_name,
                          image: staff.profile_url
                        }}
                        onSelect={() => onSelectedUser(staff.id)}
                        aspectRatio={'rounded'}
                        isSelected={selectedUser?.includes(staff.id)}
                      />
                    ))}
                  </div>
                )}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
