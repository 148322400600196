export const getCardType = (cardNumber: string) => {
  type cardType = {
    [brand: string]: RegExp;
  };

  const cardTypes: cardType = {
    visa: /^4/,
    mastercard: /^5[1-5]/,
    amex: /^3[47]/,
    discover: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
    diners: /^3(0[0-5]|[68])/,
    jcb: /^35(2[89]|[3-8][0-9])/,
    unionpay: /^62/,
    maestro: /^(5018|5020|5038|6304|6759|676[1-3])/,
    mir: /^220[0-4]/
  };

  return Object.keys(cardTypes).find((type) => cardTypes[type].test(cardNumber)) || 'unknown';
};

export const getCardPatternMask = (cardType: string) => {
  let cardNumberMaskPattern = '9999 9999 9999 9999';

  switch (cardType) {
    case 'visa':
      cardNumberMaskPattern = '9999 9999 9999 9999';
      break;
    case 'mastercard':
      cardNumberMaskPattern = '9999 9999 9999 9999';
      break;
    case 'amex':
      cardNumberMaskPattern = '9999 999999 99999';
      break;
    case 'discover':
      cardNumberMaskPattern = '9999 9999 9999 9999';
      break;
    case 'diners':
      cardNumberMaskPattern = '9999 999999 9999';
      break;
    case 'jcb':
      cardNumberMaskPattern = '9999 9999 9999 9999';
      break;
    case 'unionpay':
      cardNumberMaskPattern = '9999 9999 9999 9999';
      break;
    case 'maestro':
      cardNumberMaskPattern = '9999 9999 9999 9999';
      break;
    case 'mir':
      cardNumberMaskPattern = '9999 9999 9999 9999';
      break;
    default:
  }
  return cardNumberMaskPattern;
};

export const getCardImageType = (cardType: string) => {
    let cardImages: {
      [brand: string]: string;
    } = {
      visa: 'visa.svg',
      mastercard: 'mastercard.svg',
      amex: 'american-express.svg',
      discover: 'discover.svg',
      diners: 'discover.svg',
      jcb: 'jcb.svg',
      unionpay: 'discover.svg',
      maestro: 'maestro.svg',
      mir: 'discover.svg'
    };

    return cardImages[cardType] ?? 'unknown.svg';
};