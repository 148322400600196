import React, { useEffect, useState } from 'react';
import { useWizardFormData } from '../../infrastructure/providers/FormDataProvider';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Label } from '@/common/presentation/components/ui/label';
import Counter from '../../../../common/presentation/components/Counter/Counter';
import { IFacilitiesInfo } from '../../domain/signup.domain';
import { useTranslation } from 'react-i18next';

interface Props {
  onSave: (facilitiesData: IFacilitiesInfo[]) => void;
  priceExtraBed: string;
}

interface IFacilitiesData {
  beds_number: number;
}

const SetUpFacilities: React.FC<Props> = ({ onSave, priceExtraBed }) => {
  const { formData } = useWizardFormData();
  const [facilitiesData, setFacilitiesData] = useState<IFacilitiesData[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setFacilitiesData(formData.facilities_list || []);
  }, [formData.facilities_list]);

  const counterHandler = (index: number, value: number) => {
    const newFacilitiesList = [...facilitiesData];
    newFacilitiesList[index] = { ...newFacilitiesList[index], beds_number: value };
    setFacilitiesData(newFacilitiesList);
  };

  useEffect(() => {
    onSave(facilitiesData);
  }, [facilitiesData]);

  return (
    <div className="py-3 text-muted-foreground">
      <div className="w-full p-2 mb-2">
        <Label className="text-xs">
          { t('signUp.bedPrice', {price: priceExtraBed}) }
        </Label>
      </div>
      <ScrollArea className="h-[350px]">
        <div className="grid gap-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 px-2 pb-4">
            {formData.facilities_list.map((facility, index) => (
              <div key={index} className="px-5 py-3 bg-white rounded-lg shadow-md">
                <div className="py-2">
                  <Label htmlFor="facilities_number" className="text-right text-base font-semibold">
                    { t('signUp.numberBeds', {number: index + 1})}
                  </Label>
                </div>
                <div className="py-2">
                  <Counter
                    name="beds_number"
                    defaultValue={10}
                    value={facility.beds_number}
                    onChange={(value) => counterHandler(index, value)}
                    minValue={1}
                    maxValue={30}
                    step={1}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

export default SetUpFacilities;
