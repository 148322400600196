import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Label } from '@/common/presentation/components/ui/label';
import { cn } from '@/lib/utils';
import { BillingData } from '@/modules/wizard/domain/signup.domain';
import { ChevronLeftIcon, Loader2 } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import Inputmask from 'inputmask';
import InputCreditCard from '@/modules/payments/presentation/components/InputCreditCard';
import { useWizardFormData } from '@/modules/wizard/infrastructure/providers/FormDataProvider';
import OrderSummary from '../OrderSummary';
import { z } from 'zod';
import SignUpAdapter from '../../adapters/SignUpAdapter';
import { useDispatch } from 'react-redux';
import { createClient } from '../../slices/SignUpSlice';
import { encryptToLaravel } from '@/utils/helpers/encryption.helper';
import { toast } from '@/common/presentation/components/ui/use-toast';
import CountrySelector from '../CountrySelector/CountrySelector';
import ProtectedPaymentMessage from '@/modules/payments/presentation/components/ProtectedPaymentMessage';
import InputField from './components/InputField';
import { useTranslation } from 'react-i18next';

interface IProps {
  handleBackStep: (step: number) => void;
  handleNextStep: (step: number) => void;
}

const Step5: React.FC<IProps> = ({ handleBackStep, handleNextStep }) => {
  const [errors, setErrors] = useState<Partial<BillingData>>({});
  const [isLoading, setIsLoading] = useState(false);
  const expiryRef = useRef<HTMLInputElement>(null);
  const nameOnCardRef = useRef<HTMLInputElement>(null);
  const cardNumberRef = useRef<HTMLInputElement>(null);
  const cvvRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const postalCodeRef = useRef<HTMLInputElement>(null);
  const address1Ref = useRef<HTMLInputElement>(null);
  const address2Ref = useRef<HTMLInputElement>(null);
  const { formData, updateFormData } = useWizardFormData();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (expiryRef.current) {
      const expiryMask = new Inputmask({
        mask: '99/9999',
        showMaskOnHover: false,
        showMaskOnFocus: false,
        greedy: false
      });

      expiryMask.mask(expiryRef.current);
    }
  }, []);

  const inputHandler = (name: string, value: string) => {
    updateFormData({
      ...formData,
      [name]: value
    });

    setErrors({
      ...errors,
      [name]: undefined
    });
  };

  const register = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    const data = SignUpAdapter.signUp({
      ...formData,
      card_information: getPaymentMethodEncrypted()
    });

    try {
      const response = await dispatch<any>(createClient(data));

      if (response.error) {
        throw new Error(response.error.message);
      }

      const { token } = response.payload;

      localStorage.setItem('access_token', token);
      handleNextStep(4);
      toast({
        title: t('signUp.paymentSuccessfully'),
        description: t('signUp.paymentSuccessNextStep'),
        className: 'bg-green-500 text-white'
      });
      return;
    } catch (error: any) {
      toast({
        title: t('signUp.paymentError'),
        description: `Error: ${error.message}`,
        variant: 'destructive'
      });
    }

    setIsLoading(false);
  };

  const getPaymentMethodEncrypted = () => {
    const paymentObject = {
      card_number: formData.card_number,
      cvv: formData.cvv,
      expiry_card: formData.expiry_card,
      name_on_card: formData.name_on_card
    };

    return encryptToLaravel(paymentObject);
  };

  const validateForm = () => {
    setErrors({});
    const schema = z.object({
      name_on_card: z
        .string()
        .regex(/^[a-zA-Z\s]*$/, { message: 'First name must contain only letters' })
        .nonempty('Name on card required'),
      expiry_card: z.string().regex(/^\d{2}\/\d{4}$/, {
        message: 'Expiry date must be in the format MM/YYYY'
      }),
      cvv: z.string().regex(/^\d+$/, { message: 'CVV must contain only numbers' }),
      country: z.string().nonempty('Country is required'),
      state: z.string().nonempty('State is required'),
      city: z.string().nonempty('City is required'),
      card_number: z.string().nonempty('Card number is required'),
      postal_code: z.string().nonempty('Zip is required'),
      address_1: z.string().nonempty('Address is required')
    });

    try {
      schema.parse(formData);
      return true;
    } catch (error: any) {
      const errors = error.errors.reduce((acc: any, curr: any) => {
        return { ...acc, [curr.path[0]]: curr.message };
      }, {});

      setErrors(errors);
      scrollToFieldError(Object.keys(errors)[0]);
      return false;
    }
  };

  const scrollToFieldError = (fieldError: string) => {
    switch (fieldError) {
      case 'name_on_card':
        nameOnCardRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'expiry_card':
        nameOnCardRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'card_number':
        cardNumberRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'cvv':
        cvvRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'country':
        countryRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'state':
        stateRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'city':
        cityRef.current?.scrollIntoView({ behavior: 'smooth' });

        break;
      case 'postal_code':
        postalCodeRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'address_1':
        address1Ref.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'address_2':
        address2Ref.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="w-11/12 lg:w-9/12 flex md:block items-center justify-center">
        <div className="col-span-3 w-full">
          <div className="justify-start">
            <Button className="p-0 text-base" variant="link" onClick={() => handleBackStep(2)}>
              <ChevronLeftIcon className="w-4 h-4 mr-1 text-base" /> {t('signUp.back')}
            </Button>
          </div>
          <Card className={`border-0 shadow-none flex flex-col`}>
            <CardHeader>
              <CardTitle className="flex flex-row items-center text-xl font-bold">
                {t('signUp.paymentInformation')}
              </CardTitle>
              <div className="flex w-full items-center justify-between">
                <div className="text-base">{t('signUp.addPaymentMethodToPay')}</div>
              </div>
            </CardHeader>
            <CardContent className="flex-grow overflow-auto flex flex-col gap-10">
              <form className="w-full">
                <div className={cn('space-y-3', isLoading && 'opacity-50')}>
                  <InputField
                    name="name_on_card"
                    label={t('signUp.nameOnTheCard')}
                    type="text"
                    placeHolder={t('signUp.fullName')}
                    disabled={isLoading}
                    value={formData.name_on_card}
                    onChange={inputHandler}
                    errors={errors.name_on_card}
                  />
                  <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
                    <InputField label={t('signUp.cardNumber')} className="flex-grow">
                      <InputCreditCard
                        name="card_number"
                        placeHolder="1234 1234 1234 1234"
                        value={formData.card_number}
                        disabled={isLoading}
                        onChange={(value) => {
                          inputHandler('card_number', value);
                        }}
                      />
                    </InputField>

                    <InputField
                      label={t('signUp.expiry')}
                      name="expiry_card"
                      type="text"
                      className="w-full sm:w-32 space-y-2"
                      placeHolder="mm/yyyy"
                      reference={expiryRef}
                      disabled={isLoading}
                      value={formData.expiry_card}
                      errors={errors.expiry_card}
                      onChange={inputHandler}
                    />

                    <InputField
                      label={t('signUp.cvv')}
                      name="cvv"
                      type="password"
                      placeHolder="CVV"
                      autoComplete="off"
                      disabled={isLoading}
                      value={formData.cvv}
                      onChange={inputHandler}
                      errors={errors.cvv}
                      className={'w-full sm:w-24 space-y-2'}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <div className="flex flex-col my-3">
                    <Label className="text-xl font-bold">{t('signUp.billingInformation')}</Label>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
                      <InputField
                        label={t('signUp.streetAddress1')}
                        name="address_1"
                        className="flex-grow"
                        type="text"
                        disabled={isLoading}
                        value={formData.address_1}
                        onChange={inputHandler}
                        errors={errors.address_1}
                      />
                      <InputField
                        label={t('signUp.streetAddress2')}
                        name="address_2"
                        type="text"
                        disabled={isLoading}
                        value={formData.address_2}
                        onChange={inputHandler}
                        errors={errors.address_2}
                      />

                      <InputField label={t('signUp.country')}>
                        <CountrySelector
                          name="country"
                          defaultCountry={formData.client_country.code}
                          country={formData.country}
                          disabled={isLoading}
                          onChange={(e) => {
                            updateFormData({
                              ...formData,
                              country: e.value.toLowerCase()
                            });
                          }}
                        />
                      </InputField>
                    </div>
                    <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-4">
                      <InputField
                        label={t('signUp.city')}
                        name="city"
                        type="text"
                        disabled={isLoading}
                        value={formData.city}
                        onChange={inputHandler}
                        errors={errors.city}
                        className="flex-grow"
                      />

                      <InputField
                        label={t('signUp.state/province')}
                        name="state"
                        type="text"
                        disabled={isLoading}
                        value={formData.state}
                        onChange={inputHandler}
                        errors={errors.state}
                      />

                      <InputField
                        label={t('signUp.zip/postalCode')}
                        name="postal_code"
                        type="text"
                        disabled={isLoading}
                        placeHolder="Zip/Postal Code"
                        value={formData.postal_code}
                        onChange={inputHandler}
                        errors={errors.postal_code}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col lg:hidden mt-5">
                  <div className="max-w-[530px]">
                    <div className="flex flex-row">
                      <div className="w-full ">
                        <OrderSummary
                          products={formData.summary}
                          frecuency="monthly"
                          currency={formData.client_country.currency}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <ProtectedPaymentMessage />
                <div className="flex flex-row mt-1">
                  <Button className="w-full" onClick={register} disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        {t('signUp.attemptingPayment')}
                      </>
                    ) : (
                      <> {t('signUp.payAndCreateAccount')}</>
                    )}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Step5;
