import { Separator } from '@/common/presentation/components/ui/separator';

export const FormInformation = ({ resident }: any) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold">
        Licensing regulations require that an appraisal of needs or needs and services plan be completed for
        clients/residents to identify individual needs or to develop a service plan for meeting client/resident needs.
        For Residential Care Facilities for the Chronically III, licensing regulations require that a Resident
        Individual Services Plan be completed to document the needs and services of individual residents.
      </p>
      <p>
        <span className="font-bold">NOTE:</span> For Residential Care Facilities for the Elderly, this form may may be
        completed to assist in developing a plan of action to meet the services needs of individual residents not
        presently being addressed as specified in California Code of Regulations, Title 22, Section 87457(c)(2).
      </p>

      <Separator />

      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 px-4">
        <p className="font-bold col-span-3">BACKGROUND INFORMATION:</p>
        <p className="col-span-9">
          Brief description of client's/resident's medical history/ emotional, behavioral, and physical problems;
          functional limitations; physical and mental; functional capabilities; ability to handle personal cash
          resources and perform simple homemaking tasks; client's/resident's likes and dislikes.
        </p>
      </div>
    </div>
  );
};
