import { useSelector } from 'react-redux';
import PaymentManage from '../components/PaymentsManage';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const PaymentMethodsPage = () => {
    const userInfo = useSelector((state: any) => state.auth.user);
    const isExecutive = userInfo.roles.find((role : {name: string}) => ['Executive', 'Super Administrator'].includes(role.name));
    const navigate = useNavigate();

    useEffect(() => {
        if (!isExecutive) {
            navigate('/dashboard', {replace: true});
        }
    }, []);

    
    return (
        <div className='pt-3'>
            <PaymentManage/>
        </div>
    );
}

export default PaymentMethodsPage;