import renderActionsColumn from './renderActionsTrackerDirectColumn';
import { useTranslation } from 'react-i18next';

const useTrackerDirectColumns = () => {
  const { t } = useTranslation();
  
  const TrackerDirectColumns = [
    {
      key: 'date',
      id: 'date',
      labelTranslationKey: 'trackers.Date/Time',
      value: 'date',
      visible: true,
      sortable: true,
      width: '14.2%',
    },
    {
      key: 'resident_name',
      id: 'resident_name',
      labelTranslationKey: 'trackers.Resident',
      value: 'resident_name',
      visible: true,
      sortable: true,
      width: '14.2%',
    },
    {
      key: 'type_name',
      id: 'type_name',
      labelTranslationKey: 'trackers.Field',
      value: 'type_name',
      visible: true,
      sortable: true,
      width: '14.2%',
    },
    {
      key: 'value',
      id: 'value',
      labelTranslationKey: 'trackers.Data',
      value: 'value',
      visible: true,
      sortable: true,
      width: '14.2%',
    },
    {
      key: 'detail',
      id: 'detail',
      labelTranslationKey: 'trackers.Detail',
      value: 'detail',
      visible: true,
      sortable: true,
      width: '14.2%',
    },
    {
      key: 'who_name',
      id: 'who_name',
      labelTranslationKey: 'trackers.Staff',
      value: 'who_name',
      visible: true,
      sortable: true,
      width: '14.2%',
    },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: 'trackers.actions',
      render: renderActionsColumn,
      visible: true,
      static: true,
      width: '14.2%',
    },
  ];

  const translateColumnLabel = (key) =>
    t(TrackerDirectColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = TrackerDirectColumns.map((column) => ({
    ...column,
    label: translateColumnLabel(column.key),  // Esto traduce las etiquetas si es necesario
  }));

  return columnsWithTranslation;
};

export default useTrackerDirectColumns;
