import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ResidentService from '../../infrastructure/services/ResidentsService';

interface ResidentConfigurationState {
  configurations: any;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: ResidentConfigurationState = {
  configurations: {},
  status: 'idle',
  error: null
};

export const getResidentsConfigurations = createAsyncThunk(
  'residentsConfigurations/getResidentsConfigurations',
  async () => {
    try {
      const response = await ResidentService.getResidentsConfigurations();
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error getting residents configurations: ' + error);
    }
  }
);

export const postResidentsConfigurations = createAsyncThunk(
  'residentsConfigurations/postResidentsConfigurations',
  async (data: any) => {
    try {
      const response = await ResidentService.postResidentsConfigurations(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error posting residents configurations: ' + error);
    }
  }
);

const residentConfigurationsSlice = createSlice({
  name: 'residentsConfigurations',
  initialState,
  reducers: {
    setConfigurations: (state, action) => {
      state.configurations = action.payload;
    },
    resetConfigurations: (state) => {
      state.configurations = {};
      state.error = null;
      state.status = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResidentsConfigurations.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.configurations = {};
      })
      .addCase(getResidentsConfigurations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.configurations = action.payload;
        state.error = null;
      })
      .addCase(getResidentsConfigurations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
        state.configurations = {};
      });

    builder
      .addCase(postResidentsConfigurations.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postResidentsConfigurations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.configurations = action.payload;
        state.error = null;
      })
      .addCase(postResidentsConfigurations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  }
});

export const { setConfigurations, resetConfigurations } = residentConfigurationsSlice.actions;
export default residentConfigurationsSlice.reducer;
