const seggestions = {
  likes: [
    'Resident likes watching movies and TV.',
    'Resident likes sports.',
    'Resident likes being outside.',
    'Resident likes music.',
    'Resident likes doing arts and crafts.',
    'Resident likes interacting with people.',
    'Resident likes to do puzzles.',
    'Resident likes doing word searches.',
    'Resident likes coloring.',
    'Resident likes bingo.',
    'Resident likes animals.',
    'Resident likes dancing.',
    'Resident likes being loved.'
  ],
  dislikes: [
    'Resident dislikes loud noices.',
    'Resident dislikes large gatherings.',
    'Resident dislikes being told what to do.',
    'Resident dislikes being spoken to as a child.',
    'Resident dislikes being arround people.',
    'Resident dislikes technology.',
    'Resident dislikes being told they are old.',
    'Resident dislikes snakes.',
    'Resident dislikes bugs.',
    'Resident dislikes animals.',
    'Resident dislikes the cold weather.',
    'Resident dislikes the hot weather.',
    'Resident dislikes change.'
  ]
};

export default seggestions;
