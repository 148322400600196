import { Label } from '@/common/presentation/components/ui/label';
import React from 'react';
import { Frequency, Product, Products } from '../../domain/signup.domain';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { localization } from '@/utils/helpers/localization.helper';
import { useTranslation } from 'react-i18next';

type IProps = {
  products: Products;
  frecuency: Frequency;
  children?: React.ReactNode;
  currency: string;
};

const OrderSummary: React.FC<IProps> = ({ products, frecuency = 'one-time', children, currency }) => {
  const { t } = useTranslation();

  const calculatePrice = (product: Product) => {
    return product.price * product.quantity;
  };

  const calculateTotalPrice = () => {
    return Object.values(products).reduce((acc, product) => acc + calculatePrice(product), 0);
  };

  const formatPrice = (price: number) => {
    return localization.formatPrice(price, currency);
  };

  return (
    <div className="bg-secondaryBackground rounded-lg border-dotted border-2 flex flex-col p-4 w-full">
      <div className="flex flex-row gap-2">
        <img src="/img/secure_icon.svg" />
        <Label className="font-bold text-lg text-black">{t('signUp.orderSummary')}</Label>
      </div>
      {/* <ScrollArea className="h-40 pr-3" type="always"> */}
      {Object.values(products).map(
        (value: Product) =>
          value.quantity > 0 && (
            <div className="flex flex-col gap-2 py-2 text-black" key={value.label}>
              <Label className="text-sm font-bold">{value.label}</Label>
              <div className="flex flex-row justify-between">
                <Label className="justify-start font-normal text-base">{t('signUp.numberOf')} {value.label}</Label>
                <Label className="justify-end items-end font-normal text-base">{value.quantity}</Label>
              </div>
              <div className="flex flex-row justify-between">
                <Label className="justify-start font-normal text-base">{t('signUp.pricePer')} {value.label}</Label>
                <Label className="justify-end items-end text-base font-bold ml-2 text-end">
                  {' '}
                  {formatPrice(value.price)}
                </Label>
              </div>
              <div className="flex flex-row justify-between text-black">
                <Label className="justify-start font-normal text-base">{t('signUp.totalPriceFor')} {value.label}</Label>
                <Label className="justify-end items-end text-base font-bold ml-2 text-end">
                  {' '}
                  {formatPrice((value.price * value.quantity))}
                </Label>
              </div>
            </div>
          )
      )}
      {/* </ScrollArea> */}

      <div className="py-2 text-black">
        <div className="flex flex-row justify-between">
          <Label className="justify-start font-normal text-base">{t('signUp.monthlyTotalPrice')}</Label>
          <Label className="justify-end items-end font-bold text-base ml-3">
            {formatPrice(calculateTotalPrice())}
          </Label>
        </div>
      </div>
      {children && <div className="pt-4">{children}</div>}
    </div>
  );
};

export default OrderSummary;
