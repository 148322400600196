import { useEffect, useState } from "react";
import { Input } from "@/common/presentation/components/ui/input.tsx";
import { ChevronDown, ChevronUp } from 'lucide-react';

interface CustomInput {
    onChange?: (value: string) => void;
    value?: number;
    type?: string;
    field?: any;
    defaultValue?: number;
    disabled?: boolean;
    required?: boolean;
}

export
const CustomInput = ({type = 'text', onChange=()=>{}, value = 1, disabled = false, required = false, field}: CustomInput) => {
    
    const [isValue, setValue] = useState<number>(value);
    
    const handleChangeValue = (e: any) =>{
        setValue(e.target.value)
        onChange(e.target.value)
    }

    const handleIncrementDecrement = (type: string) => {
        let total: number = isValue;
        
        if (type === 'increment') {
            total += 1;
        } 

        if (type === 'decrement') {
            if (total > 0) {
                total -= 1;
            }
        }
    
        setValue(total);
        onChange(`${total}`);
    }
    

    useEffect(()=>{
        if(disabled  == true){
            setValue(1)
            onChange('1')
        }
    },[disabled])

    useEffect(()=>{
        if(value>1){
            setValue(value);
        }
    },[value])

   return( 
    <>
    {  type === 'number' && 
        <div className="flex">
            
            <Input
                type="number"
                inputMode="numeric"
                placeholder="number"
                defaultValue={1}
                {...field}
                value={isValue}
                onChange={handleChangeValue}
                disabled={disabled}
            />
            <div>
                {disabled == false 
                    && (
                    <> 
                        <ChevronUp 
                            className="p-1 h-[21px] hover:cursor-pointer text-gray-500" 
                            onClick={()=>handleIncrementDecrement('increment')} 
                            role="button" 
                            
                            />
                        <ChevronDown 
                            className="p-1 h-[21px] hover:cursor-pointer text-gray-500" 
                            onClick={()=>handleIncrementDecrement('decrement')} 
                            role="button" 
                            />
                    </>
                )}
            </div>
        </div>
    }


    {type === 'text' &&
        <Input
            placeholder="Title"
            {...field}
            defaultValue={1}
            autoComplete="false"
            disabled={disabled}
        />
    }

    </>
    )
}