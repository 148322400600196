
import  ContactsPage  from '@/modules/contacts/presentation/pages/ContactsPage';
import { ContactsDetail } from '@/modules/contacts/presentation/pages/ContactsDetail';

import { ContactsCreateDetail } from '@/modules/contacts/presentation/pages/ContactsCreateDetail';
import { ContactsEditDetail } from '@/modules/contacts/presentation/pages/ContactsEditDetail';

import { Route, Routes } from 'react-router-dom';

const ContactsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContactsPage />}></Route>
      <Route path="/new/:r" element={<ContactsCreateDetail />} />
      <Route path="/edit/:contactId" element={<ContactsEditDetail />} />
      <Route path="/location/:locationId" element={<ContactsPage />} />
    </Routes>
  );
};

export default ContactsRouter;