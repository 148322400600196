import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { HealthStatus, HealthStatusI } from '@/modules/dashboard/domain/dashboard.domain';
import React, { useEffect, useState } from 'react';

interface ItemDiagnosisProps {
  key?: string;
  title: string;
  message: string;
  status: HealthStatusI;
  loading: boolean;
}

const colorClasses = {
  default: 'bg-gray-500',
  loading: 'bg-gray-500',
  [HealthStatus.good]: 'bg-green-500',
  [HealthStatus.poor]: 'bg-amber-500',
  [HealthStatus.fair]: 'bg-red-500',
};

const ItemDiagnosis: React.FC<ItemDiagnosisProps> = ({ key, title, message, status, loading }) => {
  const [classColor, setClassColor] = useState('gray-600');

  useEffect(() => {
    const color = getStatusColorClass();
    setClassColor(color);
  }, [status, loading]);

  const getStatusColorClass = () => {
    if (loading) return 'gray-500';
    switch (status) {
      case HealthStatus.good:
        return 'green-500';
      case HealthStatus.poor:
        return 'amber-500';
      case HealthStatus.fair:
        return 'red-500';
      default:
        return 'gray-500';
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2" key={key}>
        <div
          className={cn('flex flex-row border text-black dark:text-white rounded-lg h-auto items-center gap-4 pl-3')}
        >
          <div>
            <span className="relative flex h-3 w-3">
              <span
                className={cn(
                  `bg-${classColor}`,
                  'animate-ping absolute inline-flex h-full w-full rounded-full opacity-75',
                )}
              ></span>
              <span className={cn('relative inline-flex rounded-full h-3 w-3', `bg-${classColor}`)}></span>
            </span>
          </div>
          <div className="flex flex-col py-2 w-full gap-1">
            <div className="font-bold">{title}</div>
            {loading ? (
              <Skeleton className="h-4 bg-black/15 w-4/5" />
            ) : (
              <>
                <div className="text-sm">{message}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemDiagnosis;
