import { Button } from '@/common/presentation/components/ui/button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface props {
  buttonText: string;
  type: string;
  redirect: string;
}

const BannerButton: React.FC<props> = ({buttonText, type, redirect}) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(redirect)}
      className={`border-2 w-full md:w-7/12 ${
        type === 'warning'
          ? 'bg-amber-400 hover:text-amber-400 hover:border-amber-500'
          : 'border-primary/80 hover:text-primary hover:border-primary'
      } hover:bg-background text-white rounded-md flex items-center`}
    >
      <span className="mx-auto">{buttonText}</span>
    </Button>
  );
};

export default BannerButton;
