export const trackOutingsGeneralTableData = [
    {
        date: new Date().toISOString(),
        resident: "Lorem ipsum dolor",
        activity: "Lorem ipsum dolor",
        duration: "dolor",
        staff: "Lorem",
        note: "Lorem ipsum dolor sit amet"
    },
    {
        date: new Date().toISOString(),
        resident: "Lorem ipsum dolor",
        activity: "Lorem ipsum dolor",
        duration: "dolor",
        staff: "Lorem",
        note: "Lorem ipsum dolor sit amet"
    },
    {
        date: new Date().toISOString(),
        resident: "Lorem ipsum dolor",
        activity: "Lorem ipsum dolor",
        duration: "dolor",
        staff: "Lorem",
        note: "Lorem ipsum dolor sit amet"
    },
    {
        date: new Date().toISOString(),
        resident: "Lorem ipsum dolor",
        activity: "Lorem ipsum dolor",
        duration: "dolor",
        staff: "Lorem",
        note: "Lorem ipsum dolor sit amet"
    },
];

export const trackOutingsMedicalTableData = [
    {
        date: new Date().toISOString(),
        note: "Lorem ipsum dolor sit amet",
        resident: "Lorem ipsum dolor",
        specialty: "Lorem",
        doctorName: "Lorem ipsum"
    },
    {
        date: new Date().toISOString(),
        note: "Lorem ipsum dolor sit amet",
        resident: "Lorem ipsum dolor",
        specialty: "Lorem",
        doctorName: "Lorem ipsum"
    },
    {
        date: new Date().toISOString(),
        note: "Lorem ipsum dolor sit amet",
        resident: "Lorem ipsum dolor",
        specialty: "Lorem",
        doctorName: "Lorem ipsum"
    },
    {
        date: new Date().toISOString(),
        note: "Lorem ipsum dolor sit amet",
        resident: "Lorem ipsum dolor",
        specialty: "Lorem",
        doctorName: "Lorem ipsum"
    },
];

export const trackOutingsHospitalizationTableData = [
    {
        date: new Date().toISOString(),
        hospitalizationReason: "Lorem ipsum dolor sit amet",
        hospitalName: "Lorem",
        resident: "ipsum",
        dateBack: "dictumst"
    },
    {
        date: new Date().toISOString(),
        hospitalizationReason: "Lorem ipsum dolor sit amet",
        hospitalName: "Lorem",
        resident: "ipsum",
        dateBack: "dictumst"
    },
    {
        date: new Date().toISOString(),
        hospitalizationReason: "Lorem ipsum dolor sit amet",
        hospitalName: "Lorem",
        resident: "ipsum",
        dateBack: "dictumst"
    },
    {
        date: new Date().toISOString(),
        hospitalizationReason: "Lorem ipsum dolor sit amet",
        hospitalName: "Lorem",
        resident: "ipsum",
        dateBack: "dictumst"
    },
    {
        date: new Date().toISOString(),
        hospitalizationReason: "Lorem ipsum dolor sit amet",
        hospitalName: "Lorem",
        resident: "ipsum",
        dateBack: "dictumst"
    }
];
