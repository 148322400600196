import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/common/presentation/components/ui/command';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Building, CheckIcon, Loader } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
// Esquema de validación del formulario

interface Props {
  className?: string;
}

const formSchema = z.object({
  company: z.string().optional(),
  locations: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: 'You have to select at least one location'
  }),
  preferred_location: z.string().optional(),
  position_title: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const CompanyForm = ({ className }: Props) => {
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  const { status: residentFormStatus } = useSelector((state: RootState) => state.residents.residentForm);
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});

  const locationOptions =
    locations.map((location: any) => ({
      value: location.id,
      label: location.name
    })) || [];

  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => state.auth);

  const canEdit = useMemo(() => {
    if (user) {
      return user.roles.find((role) => role.name === 'Super Administrator');
    }

    return false;
  }, [user]);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues } as FormValues
  });

  const onSubmitForm = async (data: FormValues) => {
    const response = dispatch(
      staffMemberFormActions.updateStaffcompanyForm({
        staff_member_id: staffMember?.id,
        ...data
      })
    );

    if ((await response).payload) {
      toast({
        description: 'company staff member updated successfully'
      });
    }
  };

  useEffect(() => {
    if (locations.length === 0) {
      dispatch(locationsActions.getLocations());
    }
  }, [locations]);

  useEffect(() => {
    if (staffMember) {
      setDefaultValues({
        company: staffMember?.company || '',
        preferred_location: staffMember?.preferred_location || '',
        position_title: staffMember?.position_title || '',
        locations: staffMember?.locations.map((location: any) => location?.id) || []
      });
    }
  }, [staffMember]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Company">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)}>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div className="col-span-2 md:col-span-1">
                    <FormField
                      control={form.control}
                      name="company"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Company</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="locations"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Location <span className="text-destructive">(*)</span>
                          </FormLabel>

                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={'outline'}
                                  className={cn(
                                    'w-full flex items-center justify-start font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                  disabled={residentFormStatus === 'loading'}
                                >
                                  <Building className="mr-2 size-4 text-muted-foreground" />
                                  <span>Locations</span>

                                  {field.value?.length > 0 && (
                                    <>
                                      <Separator orientation="vertical" className="mx-2 h-4" />
                                      <Badge variant="outline" className="rounded-sm px-1 font-normal lg:hidden">
                                        {field.value.length}
                                      </Badge>
                                      <div className="hidden space-x-1 lg:flex">
                                        {field.value.length > 2 ? (
                                          <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                                            {field.value.length} selected
                                          </Badge>
                                        ) : (
                                          field.value.map((location) => (
                                            <Badge
                                              variant="secondary"
                                              className="rounded-sm px-1 font-normal"
                                              key={location}
                                            >
                                              {locationOptions.find((t) => t.value === location)?.label}
                                            </Badge>
                                          ))
                                        )}
                                      </div>
                                    </>
                                  )}
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className={cn('p-1', !canEdit && 'pointer-events-none')}>
                              <Command className="w-full">
                                <CommandInput placeholder="Search time" />
                                <CommandList>
                                  <CommandEmpty>No results found.</CommandEmpty>
                                  <CommandGroup>
                                    {locationOptions.map((location: any, index: number) => {
                                      const isSelected = field.value?.includes(location.value);
                                      return (
                                        <CommandItem
                                          value={location.value}
                                          key={index}
                                          onSelect={() =>
                                            field.onChange(
                                              isSelected
                                                ? field.value.filter((t) => t !== location.value)
                                                : [...field.value, location.value]
                                            )
                                          }
                                        >
                                          <div
                                            className={cn(
                                              'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                              isSelected
                                                ? 'bg-primary text-primary-foreground'
                                                : 'opacity-50 [&_svg]:invisible'
                                            )}
                                          >
                                            <CheckIcon className={cn('h-4 w-4')} />
                                          </div>
                                          {location.label}
                                        </CommandItem>
                                      );
                                    })}
                                  </CommandGroup>

                                  {field.value?.length > 0 && (
                                    <>
                                      <CommandSeparator className="mb-1" />

                                      <CommandItem
                                        onSelect={() => {
                                          field.onChange([]);
                                        }}
                                        className="justify-center text-center"
                                      >
                                        Clear times
                                      </CommandItem>
                                    </>
                                  )}
                                </CommandList>
                              </Command>
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="preferred_location"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Preferred Location for Dashboard</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value || ''}
                            defaultValue={field.value || ''}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select a resident house" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {locationOptions.map((location: any, index: number) => (
                                <SelectItem value={location.value} key={index}>
                                  {location.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name="position_title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Position Title</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} disabled={residentFormStatus === 'loading'} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {JSON.stringify(form.watch()) !== JSON.stringify(defaultValues) && (
                    <>
                      <Separator className="col-span-full" />
                      <Button type="submit" className="w-64" disabled={residentFormStatus === 'loading'}>
                        {residentFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                        Update
                      </Button>
                    </>
                  )}
                </div>
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
