import { cn } from '@/lib/utils';
import { Share2 } from 'lucide-react';
import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { MultiValue } from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getContacts } from '@/modules/contacts/presentation/slices/contactsSlice';
import { resetSelectedNote } from '@/modules/notes/presentation/slices/NoteSlice';
import NoteService from '../../../infrastructure/services/NoteService';
import { Loader } from 'lucide-react';
import { toast } from '@/common/presentation/components/ui/use-toast';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription
} from '@/common/presentation/components/ui/dialog';


interface Option {
  value: string;
  label: string;
}

interface formDataShareNotes {
  note_id: string[];
  email: string[];
}

const customClassNames = {
  control: () =>
    cn(
      '!bg-background border-1 !border-gray-300 dark:!border-gray-700 focus:!border-red-400 !rounded h-1 focus:!outline-none'
    ),
  menu: () => cn('!bg-background !border-2 !border-gray focus:!border-red-400 !z-[9999]'),
  option: ({ isSelected }: any) =>
    cn(
      'dark:text-white dark:hover:!bg-gray-400 hover:!bg-primary/10 before:!bg-primary/10',
      isSelected ? '!bg-primary' : ''
    ),
  singleValue: () => cn('text-dark dark:text-white'),
  multiValue: () => cn('!bg-primary !text-white'),
  multiValueLabel: () => cn('!text-white')
};

export function ShareNote() {
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const selectedNotes = useSelector((state: RootState) => state.notes.selectedNotes);
  const contacts = useSelector((state: RootState) => state.contacts.contacts) || [];

  // Hooks
  const [openDialog, setopenDialogAddUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formDataShareNotes, setFormDataShareNotes] = useState<formDataShareNotes>({
    note_id: selectedNotes,
    email: []
  });

  const [noteToOptions, setNoteToOptions] = useState<Option[]>([]);

  // Fetch contacts on dialog open
  const gContacts = () => {
    dispatch<any>(getContacts());
  };

  // Filter contacts to only include those with valid emails
  useEffect(() => {
    const filteredContacts = contacts
      .filter(contact => contact.email && contact.email.trim() !== '')
      .map(contact => ({ value: contact.email, label: contact.email })); 

    setNoteToOptions(filteredContacts);
  }, [contacts]);

  // Behavior
  const handleSubmitShareNotes = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    let data =  {
      ...formDataShareNotes,
      note_id: selectedNotes
    }

    NoteService.shareNote(data);

    setTimeout(() => {
      toast({
        title: t('notes.yourNoteHasBeenShared'),
        description: ``
      });
      setIsLoading(false);
      setopenDialogAddUser(false);
      dispatch<any>(resetSelectedNote());
    }, 1000);
  };

  const handleSelectShareNote = (selectedOptions: MultiValue<Option>) => {
    const recipients = selectedOptions.map(option => option.value);
    setFormDataShareNotes({
      ...formDataShareNotes,
      email: recipients
    });
  };

  return (
    <div className="flex items-center px-4 py-2">
      <h1 className="text-xl font-bold">{t('notes.notes')}</h1>
      <div className="ml-auto flex items-center space-x-2">
        <span className="text-xs">{t('notes.share')}</span>
        <Dialog
          open={openDialog}
          onOpenChange={() => setopenDialogAddUser(openDialog ? false : true)}>
          <DialogTrigger>
            <Button variant="ghost" size="icon" onClick={() => gContacts()}>
              <Share2 className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent className="w-full max-w-lg">
            <DialogHeader>
              <DialogTitle>{t('notes.shareNotes')}</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              {t('notes.youAreSharing').replace("#", selectedNotes.length.toString())}
            </DialogDescription>
            <form onSubmit={handleSubmitShareNotes}>
              <div className="grid gap-4 py-4">
                <CreatableSelect
                  isMulti
                  name="shareWith"
                  classNames={customClassNames}
                  options={noteToOptions}
                  classNamePrefix="select"
                  placeholder={t('notes.shareWith')}
                  onChange={handleSelectShareNote}
                  className="w-full"
                />
              </div>
              <DialogFooter>
                <Button type="submit">
                  {isLoading ? <Loader className="mr-2 h-4 w-4 animate-spin" /> : t('notes.share')}
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
