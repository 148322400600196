import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '@/store/store';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getContactsCategoriesPersonal, getContactsCategoriesMedical, getContactById } from '../slices/contactsSlice';
import { ContactsCategoriesEdit } from '../components/PersonalContacts/ContactsCategoriesEdit';
import { useNavigate } from 'react-router-dom';
import { NavigationBar } from '@/common/presentation/components/navigations/NavigationBar';
import { Button } from '@/common/presentation/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface ContactInfo {
    [key: string]: {
        category: string;
        company: string;
        name: string;
        last_name: string;
        relationship: string;
        email: string;
        address: string;
        zipcode: string;
        country: string;
        city: string;
        linkto: string;
    };
}

export const ContactsEditDetail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { contactId } = useParams<{ contactId: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const [contactInfo, setContactInfo] = useState<ContactInfo>({});
    const [currentCategoryType, setCurrentCategoryType] = useState<number | null>(null);

    const categories = useSelector((state: RootState) => state.contacts.categories);
    const handleBackClick = () => {
        navigate('/contacts');
    };

    useEffect(() => {
        if (contactId) {
            dispatch(getContactById(contactId))
                .unwrap()
                .then((contact) => {
                    //console.log('Fetched contact:', contact); // Verifica que el contacto se está obteniendo correctamente
                    setContactInfo({
                        [contact.category]: {
                            category: contact.category,
                            company: contact.company,
                            name: contact.name,
                            last_name: contact.last_name,
                            relationship: contact.relationship,
                            email: contact.email,
                            address: contact.address,
                            zipcode: contact.zipcode,
                            country: contact.country,
                            city: contact.city,
                            state: contact.state,
                            same_as: contact.same_as,
                            linkto: contact.linkto,
                        }
                    });

                    const category = categories.find(cat => cat.id === contact.category);
                    if (category) {
                        //console.log('Category found:', category); // Verifica que la categoría se está encontrando
                        setCurrentCategoryType(category.type);
                    }
                })
                .catch((error) => {
                    //console.error("Failed to fetch contact:", error);
                });
        }
    }, [dispatch, contactId, categories]);

    useEffect(() => {
        dispatch(getContactsCategoriesPersonal());
        dispatch(getContactsCategoriesMedical());
    }, [dispatch]);

    const renderTabs = () => {
        if (contactId && currentCategoryType !== null) {
            if (currentCategoryType === 1) {
                return (
                    <>
                    <NavigationBar>
                        <Button variant="ghost" onClick={handleBackClick}>
                        <>
                            <ArrowLeft className="mr-2 h-4 w-4" />
                            {t('contacts.back')}
                        </>
                        </Button>
                    </NavigationBar>
                    <Tabs defaultValue="primary" className="w-full">
                        <TabsList className="w-full h-full grid grid-cols-1 md:grid-cols-1 gap-2">
                            <TabsTrigger value="primary" className="w-full data-[state=active]:bg-primary data-[state=active]:text-white">
                                {t('contacts.personal_legal_contacts')}
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent value="primary" className="h-full">
                            <ContactsCategoriesEdit type={1} contactInfo={contactInfo} setContactInfo={setContactInfo} currentCategory={Object.keys(contactInfo)[0]} />
                        </TabsContent>
                    </Tabs>
                    </>
                );
            } else if (currentCategoryType === 2) {
                return (
                    <>
                    <NavigationBar>
                        <Button variant="ghost" onClick={handleBackClick}>
                        <>
                            <ArrowLeft className="mr-2 h-4 w-4" />
                            {t('contacts.back')}
                        </>
                        </Button>
                    </NavigationBar>
                    <Tabs defaultValue="other" className="w-full">
                        <TabsList className="w-full h-full grid grid-cols-1 md:grid-cols-1 gap-2">
                            <TabsTrigger value="other" className="w-full data-[state=active]:bg-primary data-[state=active]:text-white">
                                {t('contacts.medical_dental_contacts')}
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent value="other" className="h-full">
                            <ContactsCategoriesEdit type={2} contactInfo={contactInfo} setContactInfo={setContactInfo} currentCategory={Object.keys(contactInfo)[0]} />
                        </TabsContent>
                    </Tabs>
                    </>
                );
            } else if (currentCategoryType === 3) {
                return (
                    <>
                    <NavigationBar>
                        <Button variant="ghost" onClick={handleBackClick}>
                        <>
                            <ArrowLeft className="mr-2 h-4 w-4" />
                            {t('contacts.back')}
                        </>
                        </Button>
                    </NavigationBar>
                    <Tabs defaultValue="medical" className="w-full">
                        <TabsList className="w-full h-full grid grid-cols-1 md:grid-cols-1 gap-2">
                            <TabsTrigger value="medical" className="w-full data-[state=active]:bg-primary data-[state=active]:text-white">
                                {t('contacts.program_employer_social')}
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent value="medical" className="h-full">
                            <ContactsCategoriesEdit type={3} contactInfo={contactInfo} setContactInfo={setContactInfo} currentCategory={Object.keys(contactInfo)[0]} />
                        </TabsContent>
                    </Tabs>
                    </>
                );
            } else {
                return <p>{t('contacts.no_category_selected')}</p>;
            }
        } else {
            return <p>{t('contacts.loading')}...</p>;
        }
    };

    return (
        <div className="py-4 flex flex-col gap-6">
            <div>
                <h1 className="text-2xl font-semibold">{contactId ? t('contacts.edit_contact') : t('contacts.new_contact')}</h1>
            </div>
            <div>
                {currentCategoryType !== null || !contactId ? renderTabs() : <p>{t('contacts.loading')}...</p>}
            </div>
        </div>
    );
};
