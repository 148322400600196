import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ResidentCarriersFinancialInformationService = {
  createOrUpdateCarrierInformation: async (data: any) => {
    const response = await axios.post(`/residents/carriers-financial-information/carrier/${data.resident_id}`, {
      ...data
    });
    return response;
  },

  removeCarrierInformation: async (data: any) => {
    const response = await axios.delete(
      `/residents/carriers-financial-information/carrier/${data.id}/resident/${data.resident_id}`
    );
    return response;
  },

  createOrUpdateFinancialInformation: async (data: any) => {
    const response = await axios.post(`/residents/carriers-financial-information/financial/${data.resident_id}`, {
      ...data
    });
    return response;
  },

  removeFinancialInformation: async (data: any) => {
    const response = await axios.delete(
      `/residents/carriers-financial-information/financial/${data.id}/resident/${data.resident_id}`
    );
    return response;
  }
};

export default ResidentCarriersFinancialInformationService;
