import Select from 'react-select';
import { cn } from '@/lib/utils';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useEffect, useState } from 'react';
import { NoteI } from '@/modules/notes/domain/note.domain';
import { useTranslation } from 'react-i18next';

interface SelectNoteToProps {
  handleSelectNoteTo: (e: any) => void;
  note?: NoteI | null;
}

interface Option {
  value: string;
  label: string;
}

export function SelectNoteTo({ handleSelectNoteTo, note }: SelectNoteToProps) {
  // Init values
  const { t } = useTranslation();

  // Tools
  const { params } = useRouteParams();

  // Redux
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const departments = useSelector((state: RootState) => state.departments.departments);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const user = useSelector((state: RootState) => state.auth.user);

  // Hooks
  const [noteToOptions, setNoteToOptions] = useState<Option[]>([]);
  const [defaultNoteToOptions, setDefaultNoteToOptions] = useState<Option[]>([]);

  // Recipients options
  useEffect(() => {
    const options: Option[] = [];
    const defaultOptionValues = defaultNoteToOptions.map((opt) => opt.value);

    const hasLocation = defaultOptionValues.some((val) => val.includes('-location'));
    const hasStaffOrDepartment = defaultOptionValues.some(
      (val) => val.includes('-staff') || val.includes('-department')
    );

    // Build options
    if (hasLocation) {
      if (params.l !== 'all-locations') {
        options.push({
          value: params.l + '-location',
          label: locations.filter((loc) => loc.id === params.l)[0].name
        });
      } else {
        locations.forEach((element) => {
          const item = {
            value: element.id + '-location',
            label: element.name
          };
          options.push(item);
        });
      }
    } else if (hasStaffOrDepartment) {
      staff.forEach((element) => {
        if (userId != element.id) {
          let item = {
            value: element.id + '-staff',
            label: element.first_name + ' ' + element.last_name
          };
          options.push(item);
        }
      });

      departments.forEach((element) => {
        let item = {
          value: element.id + '-department',
          label: element.department
        };
        options.push(item);
      });
    } else {
      // If no specific filters, include all options
      if (params.l !== 'all-locations') {
        options.push({
          value: params.l + '-location',
          label: locations.find((loc) => loc.id === params.l)?.name || ''
        });
      } else {
        locations.forEach((element) => {
          const item = {
            value: element.id + '-location',
            label: element.name
          };
          options.push(item);
        });

        // All houses option
        let showAllHouses = false;
        user.roles.forEach((role: any) => {
          if (role.name == 'Executive' || role.name == 'Super Administrator') {
            showAllHouses = true;
          }
        });

        if (showAllHouses) {
          const item = {
            value: 'all-houses' + '-location',
            label: t('notes.allHouses')
          };
          options.push(item);
        }
      }

      staff.forEach((element) => {
        if (userId !== element.id) {
          const item = {
            value: element.id + '-staff',
            label: element.first_name + ' ' + element.last_name
          };
          options.push(item);
        }
      });

      departments.forEach((element) => {
        const item = {
          value: element.id + '-department',
          label: element.department
        };
        options.push(item);
      });
    }

    if (JSON.stringify(noteToOptions) !== JSON.stringify(options)) {
      options.sort((a, b) => {
        const firstItem = t('notes.allHouses');
        
        if (a.label === firstItem) return -1;
        if (b.label === firstItem) return 1;
        return a.label.localeCompare(b.label);
      });
      setNoteToOptions(options);
    }
  }, [params.l, locations, defaultNoteToOptions, noteToOptions]);

  useEffect(() => { 
    const defaultOptions: Option[] = [];
    // Build default options
    if (note) {
      noteToOptions.forEach((option: Option) => {
        let value = option.value.replace('-staff', '').replace('-location', '').replace('-department', '');

        if (note.visibility == 'public') {
          if (note.scope_location_ids.includes(value)) {
            defaultOptions.push({ value: value + '-location', label: option.label });
          } else if (note.scope_location_ids.length <= 0) {
            const allHousesExists = defaultOptions.some((option) => option.value === 'all-houses-location');

            if (!allHousesExists) {
              defaultOptions.push({ value: 'all-houses-location', label: t('notes.allHouses') });
            }
          }
        }

        if (note.visibility == 'private' || note.visibility == 'department') {
          if (note.private_user_ids.includes(value)) {
            defaultOptions.push({ value: value + '-staff', label: option.label });
          }

          if (note.department_ids.includes(value)) {
            defaultOptions.push({ value: value + '-department', label: option.label });
          }
        }
      });
    }

    if (defaultOptions.length > 0 && JSON.stringify(defaultNoteToOptions) !== JSON.stringify(defaultOptions)) {
      setDefaultNoteToOptions(defaultOptions);
    }
  }, [noteToOptions]);

  const customClassNames = {
    control: () =>
      cn(
        '!bg-background border-1 !border-gray-300 dark:!border-gray-700 focus:!border-red-400 !rounded h-1 focus:!outline-none'
      ),
    menu: () => cn('!bg-background !border-2 !border-gray focus:!border-red-400 !z-[9999]'),
    option: ({ isSelected }: any) =>
      cn(
        'dark:text-white dark:hover:!bg-gray-400 hover:!bg-primary/10 before:!bg-primary/10',
        isSelected ? '!bg-primary' : ''
      ),
    singleValue: () => cn('text-dark dark:text-white'),
    multiValue: () => cn('!bg-primary !text-white'),
    multiValueLabel: () => cn('!text-white')
  };

  const onchange = (selectedOptions: any) => {
    const mutableOptions = [...selectedOptions] as Option[];
    setDefaultNoteToOptions(mutableOptions);
    handleSelectNoteTo(selectedOptions);
  };

  return (
    <Select
      isMulti
      name="colors"
      className="basic-multi-select"
      options={noteToOptions}
      classNamePrefix="select"
      placeholder={t('notes.noteTo')}
      classNames={customClassNames}
      value={defaultNoteToOptions}
      onChange={(selectedOptions) => {
        onchange(selectedOptions);
      }}
    />
  );
}
