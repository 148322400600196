import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Link } from 'react-router-dom';

interface Form {
  name: string;
  key: string;
}

interface Props {
  forms: Form[];
}

export const FormsSelector = ({ forms }: Props) => {
  const { params } = useRouteParams();

  return (
    <div>
      {forms.map((form: Form) => {
        return (
          <div className="flex flex-col gap-4" key={form.key}>
            <Link
              to={`/residents/forms/${form.key}?r=${params.r}`}
              className="w-full border border-t-4 border-t-primary/80 p-2 rounded-lg hover:text-primary/80 hover:underline"
            >
              {form.name}
            </Link>
          </div>
        );
      })}
    </div>
  );
};
