import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import * as residentsConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';
import { useNewCustomField } from '@/modules/settings/infrastructure/hooks/use-new-custom-field';
import { useUpdateCustomField } from '@/modules/settings/infrastructure/hooks/use-update-custom-field';
import { AppDispatch, RootState } from '@/store/store';
import { Pencil, Plus, Trash } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';

export const CustomFields = () => {
  const { configurations } = useSelector((state: RootState) => state.residents.configurations);

  const dispatch = useDispatch<AppDispatch>();

  const { onOpen: onOpenNewCustomField } = useNewCustomField();
  const { onOpen: onOpenEditCustomField } = useUpdateCustomField();

  const handleDelete = async (id: string) => {
    const newConfigurations = [...configurations?.custom_form_fields?.filter((field: any) => field?.name !== id)];

    dispatch(
      residentsConfigurationsActions.postResidentsConfigurations({
        custom_form_fields: newConfigurations
      })
    );
  };

  return (
    <>
      <div className="mb-4 flex items-center">
        <div className="flex-1">
          <h1 className="text-xl font-bold">Custom Fields</h1>
          <p>Here you can manage the custom fields for the resident form.</p>
        </div>
        <Button onClick={onOpenNewCustomField} variant={'outline'}>
          <Plus className="size-4 mr-2" />
          Add field
        </Button>
      </div>

      <div className="flex flex-col gap-2">
        {configurations?.custom_form_fields?.map((field: any, index: number) => {
          return (
            <Card key={index} className="flex hover:cursor-pointer">
              <CardHeader className="flex-1 py-4">
                <CardTitle className="text-lg font-normal">
                  {field?.label}
                  {field?.required && (
                    <Badge className="ml-2 border border-foreground bg-transparent hover:bg-transparent text-foreground">
                      Required
                    </Badge>
                  )}
                </CardTitle>
                <CardDescription>Type: {field?.type}</CardDescription>
              </CardHeader>
              <CardContent className="flex items-center gap-2 p-0 mx-4">
                <Button
                  onClick={() => onOpenEditCustomField(field?.name)}
                  size={'icon'}
                  variant={'outline'}
                  className="cursor-default"
                >
                  <Pencil className="size-4" />
                </Button>
                <Button
                  onClick={() => handleDelete(field?.name)}
                  size={'icon'}
                  variant={'outline'}
                  className="cursor-default"
                >
                  <Trash className="size-4" />
                </Button>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </>
  );
};
