import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Button } from '@/common/presentation/components/ui/button';
import { Globe } from 'lucide-react';

const languages = [
  {
    code: 'es',
    label: 'Español'
  },
  {
    code: 'en',
    label: 'English'
  },
  {
    code: 'tl',
    label: 'Tagalog'
  }
];

const languageCodesArray = languages.map((lang) => lang.code) as string[];
type LanguageCodes = (typeof languageCodesArray)[number];

export const LanguageMode = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState<LanguageCodes>(
    () => (localStorage.getItem('i18nextLng') as LanguageCodes) || 'es'
  );

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const handleChangeLanguage = (lang: LanguageCodes) => {
    localStorage.setItem('i18nextLng', lang);
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="h-8 w-8  text-white hover:bg-primary/50 hover:text-white">
            <Globe className="size-4" />
            <span className="sr-only">Change Language</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuRadioGroup value={language} onValueChange={handleChangeLanguage}>
            {languages.map(({ code, label }) => (
              <DropdownMenuRadioItem key={code} value={code}>
                {label}
              </DropdownMenuRadioItem>
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
