import { AppraisalNeedsAndServicesPlanPage } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/AppraisalNeedsAndServicesPlanPage';
import { useParams } from 'react-router-dom';

const formsRoutes = [
  {
    path: 'appraisal_needs_and_services',
    component: <AppraisalNeedsAndServicesPlanPage />
  }
];

export const ResidentFormPage = () => {
  const { formKey } = useParams();

  const formRoute = formsRoutes.find((form) => form.path === formKey);

  if (!formRoute) {
    return <div>Form not found</div>;
  }

  return <div>{formRoute.component}</div>;
};
