import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Alert, AlertDescription, AlertTitle } from '@/common/presentation/components/ui/alert';
import { RotateCcw, Terminal, TriangleAlert, X } from 'lucide-react';
import { useState } from 'react';
import { EmptyIcon } from '../EmptyIcon';

interface Props {
  className?: string;
}

const alerts = [
  {
    title: 'Hola',
    description: 'esta es una alerta'
  },
  {
    title: 'Hola',
    description: 'esta es una alerta'
  },
  {
    title: 'Hola',
    description: 'esta es una alerta'
  },
  {
    title: 'Hola',
    description: 'esta es una alerta'
  }
];

export const AlertsCard = ({ className }: Props) => {
  const [alert, setAlert] = useState(alerts);

  const removeAlert = (index: number) => {
    const newAlerts = alert.filter((_, i) => i !== index);
    setAlert(newAlerts);
  };

  return (
    <>
      <div className={className}>
        <CustomAccordion
          titleComponent={
            <>
              <div className="flex flex-row items-center gap-4 text-primary">
                <TriangleAlert className="size-5 " />
                <h1 className="font-bold">Alerts</h1>
                <span className="font-bold">{alert.length}</span>
              </div>

              <RotateCcw className="ml-auto h-4 w-4 hover:cursor-pointer" onClick={() => setAlert(alerts)} />
            </>
          }
          openOption={false}
          className="col-span-3"
          defaultOpen
        >
          {alert.length === 0 && (
            <div className="w-full flex flex-col items-center py-12 gap-4">
              <EmptyIcon className="h-auto w-[8vw]" />
              <p>There are no alerts for this resident.</p>
            </div>
          )}

          {alert.length > 0 && (
            <div className="flex flex-col gap-2">
              {alert.map((alert, index) => (
                <Alert key={index}>
                  <Terminal className="h-4 w-4" />
                  <AlertTitle className="flex">
                    {alert.title}
                    <X className="ml-auto h-4 w-4 hover:cursor-pointer" onClick={() => removeAlert(index)} />
                  </AlertTitle>
                  <AlertDescription>{alert.description}</AlertDescription>
                </Alert>
              ))}
            </div>
          )}
        </CustomAccordion>
      </div>
    </>
  );
};
