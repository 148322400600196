import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useCarriersAndFinancialInformationStore } from '@/modules/residents/domain/stores/carriers-financial-information/use-carrier-financial-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

const formSchema = z.object({
  financial_source: z.string().optional(),
  source_payeer: z.string().optional(),
  uci_number: z.string().optional(),
  social_worker: z.string().optional(),
  source_payee_phone: z.string().optional()
});
type FormValues = z.infer<typeof formSchema>;

export const FinancialInformationForm = () => {
  const {
    resident
  }: {
    resident: Resident | null;
    status: string;
  } = useSelector((state: RootState) => state.residents.resident);

  const [defaultValues, setDefaultValues] = useState<FormValues>(
    resident?.carriers_and_financial_information?.financial || {
      financial_source: '',
      source_payeer: '',
      uci_number: '',
      social_worker: '',
      source_payee_phone: ''
    }
  );

  const [createdId, setCreatedId] = useState<string | null>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  const { createOrUpdateFinancialInformation, status: carriersAndFinancialInformationFormStatus } =
    useCarriersAndFinancialInformationStore();

  const handleSubmit = async (values: FormValues) => {
    const payload = {
      id: resident?.carriers_and_financial_information?.financial?.id || createdId,
      resident_id: resident?.id,
      ...values
    };

    try {
      const response = await createOrUpdateFinancialInformation(payload);

      form.reset({
        ...values
      });

      setDefaultValues({
        ...values
      });

      setCreatedId(response.financial);

      toast({
        description: 'Financial Information saved successfully'
      });
    } catch (error) {
      toast({
        description: 'Failed to save Financial Information',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <div>
        <CustomAccordion
          titleComponent={<h1 className="text-xl font-bold">Financial Information</h1>}
          openOption={true}
          defaultOpen={true}
        >
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
                <div className="col-span-2 md:col-span-1">
                  <FormField
                    control={form.control}
                    name="financial_source"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Financial Source</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            value={field.value || ''}
                            disabled={carriersAndFinancialInformationFormStatus === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <FormField
                    control={form.control}
                    name="source_payeer"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Source Payee</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            value={field.value || ''}
                            disabled={carriersAndFinancialInformationFormStatus === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <FormField
                    control={form.control}
                    name="uci_number"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>UCI#</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            value={field.value || ''}
                            disabled={carriersAndFinancialInformationFormStatus === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <FormField
                    control={form.control}
                    name="social_worker"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Social Worker</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            value={field.value || ''}
                            disabled={carriersAndFinancialInformationFormStatus === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="col-span-2 md:col-span-2">
                  <FormField
                    control={form.control}
                    name="source_payee_phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Source payee phone</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            value={field.value || ''}
                            disabled={carriersAndFinancialInformationFormStatus === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                <div className="col-span-2 space-y-4">
                  <Separator />

                  <div className="flex items-end gap-2 col-span-full xl:col-span-1">
                    <Button
                      className="flex gap-2"
                      variant={'default'}
                      type="submit"
                      disabled={carriersAndFinancialInformationFormStatus === 'loading'}
                    >
                      {carriersAndFinancialInformationFormStatus === 'loading' && (
                        <Loader className="size-4 mr-2 animate-spin" />
                      )}
                      Update
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </Form>
        </CustomAccordion>
      </div>
    </>
  );
};
