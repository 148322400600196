import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { useSettings } from '../../infrastructure/hooks/use-settings';
import { EmarContent, EmarTab } from './menus/emar/EmarSettings';
import {
  ResidentContent,
  ResidentTab
} from '@/modules/settings/presentation/components/menus/residents/ResidentsSettings';
import { StaffContent, StaffTab } from '@/modules/settings/presentation/components/menus/Staff/StaffSettings';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useState } from 'react';
import { UserRoundCog, Users } from 'lucide-react';
import { ScrollArea } from '@/components/ui/scroll-area';

const tabs = [
  { key: 'residents', label: 'Residents', component: <ResidentContent />, tab: <ResidentTab />, icon: <Users /> },
  { key: 'staff', label: 'Staff', component: <StaffContent />, tab: <StaffTab />, icon: <UserRoundCog /> },
  { key: 'emar', label: 'Emar', component: <EmarContent />, tab: <EmarTab />, icon: null }
];

export const SettingsDialog = () => {
  const { isOpen, onClose } = useSettings(); // custom hook
  const [selectedTab, setSelectedTab] = useState('residents');

  const handleSelectChange = (value: string) => {
    setSelectedTab(value);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[92%] h-auto min-h-[90vh] flex flex-col gap-6 md:max-w-[80%] rounded-lg">
          <DialogHeader>
            <DialogTitle>Settings</DialogTitle>
          </DialogHeader>
          <div className="h-full">
            {/* Dropdown para móvil */}
            <div className="md:hidden py-2">
              <Select value={selectedTab} onValueChange={handleSelectChange}>
                <SelectTrigger id="month" aria-label="Month" className="z-[9999999]">
                  <SelectValue placeholder="Select a tab" />
                </SelectTrigger>
                <SelectContent className="z-[9999999]">
                  {tabs.map((tab) => (
                    <SelectItem key={tab.key} value={tab.key}>
                      <div className="flex items-center gap-2">
                        {tab.icon}
                        {tab.label}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            {/* Tabs for desktop */}
            <Tabs
              defaultValue="residents"
              value={selectedTab}
              onValueChange={handleSelectChange}
              className="w-full flex flex-col md:flex-row gap-4"
            >
              <TabsList className="hidden md:flex md:flex-col md:h-full">
                {tabs.map((tab) => (
                  <TabsTrigger className="w-full" key={tab.key} value={tab.key}>
                    {tab.tab}
                  </TabsTrigger>
                ))}
              </TabsList>
              <div className="w-full">
                <ScrollArea className="flex-grow overflow-auto max-h-[80vh] w-full pr-2" type="always">
                  {tabs.map((tab) => (
                    <TabsContent key={tab.key} value={tab.key} hidden={selectedTab !== tab.key}>
                      {tab.component}
                    </TabsContent>
                  ))}
                </ScrollArea>
              </div>
            </Tabs>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};