import React, { useState, useEffect } from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';

interface ColumnProps {
  label: string;
  type: 'input' | 'number' | 'calendar' | 'checkbox';
  editable?: boolean;
}

interface CustomColumnProps {
  initialColumns: ColumnProps[];
  onColumnsChange: (columns: ColumnProps[]) => void;
}

const CustomColumn: React.FC<CustomColumnProps> = () => {
  const [columns, setColumns] = useState<ColumnProps[]>([]);

 
  const addDefaultRow = () => {
    const newColumn: ColumnProps = { label: '', type: 'input', editable: true };
    setColumns([...columns, newColumn]);
  };

  useEffect(() => {
    addDefaultRow();
  }, []);

  const handleColumnChange = (index: number, key: keyof ColumnProps, value: string) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = { ...updatedColumns[index], [key]: value };
    setColumns(updatedColumns);
  };

  const addColumn = () => {
    const newColumn: ColumnProps = { label: '', type: 'input', editable: true };
    setColumns([...columns, newColumn]);
  };

  const removeColumn = (index: number) => {
    const updatedColumns = [...columns];
    updatedColumns.splice(index, 1);
    setColumns(updatedColumns);
  };

  const handleSave = () => {
    // Llamar a la función de retorno para pasar los cambios al componente padre
    onColumnsChange(columns);
  };

  return (
    <CustomCard>
      <h3 className="text-xl font-semibold mb-6">Column Editor</h3>
      <div className="mb-4">
        <Button onClick={addColumn} className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600">
          Add Column
        </Button>
      </div>
      {columns.map((column, index) => (
        <div key={index} className="flex items-center space-x-4 mb-4">
          <Input
            placeholder="Label"
            value={column.label}
            onChange={(e) => handleColumnChange(index, 'label', e.target.value)}
            className="w-32 border rounded-lg py-2 px-4 focus:outline-none focus:ring focus:border-blue-500"
          />
          <select
            value={column.type}
            onChange={(e) => handleColumnChange(index, 'type', e.target.value)}
            className="w-32 border rounded-lg py-2 px-4 focus:outline-none focus:ring focus:border-blue-500"
          >
            <option value="input">Input</option>
            <option value="number">Number</option>
            <option value="calendar">Calendar</option>
            <option value="checkbox">Checkbox</option>
          </select>
          <Button onClick={() => removeColumn(index)} className="bg-red-500 text-white rounded-lg px-4 py-2 hover:bg-red-600">
            Remove
          </Button>
        </div>
      ))}
      <div>
        <Button onClick={handleSave} className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600">
          Save
        </Button>
      </div>
    </CustomCard>
  );
};

export default CustomColumn;