import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { useNewExceptionDialog } from '@/modules/emar/infrastructure/hooks/UseNewException';
import { ExceptionForm } from '@/modules/emar/presentation/components/forms/NewExceptionForm';
import { RootState } from '@/store/store';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

export const NewExceptionDialog = () => {
  const { isOpen, onClose, data, date, time, locationId } = useNewExceptionDialog();

  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const { medicationException, status: medicationCardStatus } = useMedicationStore();

  const onSubmit = async (values: any) => {
    try {
      await medicationException(
        locationId!,
        resident?.id ?? data?.resident_id,
        data?.id,
        format(
          new Date(new Date(date as Date).getTime() + new Date(date as Date).getTimezoneOffset() * 60000) ?? new Date(),
          'yyyy-MM-dd'
        ),
        values.time,
        values.reason,
        values.details,
        values.discount_unit,
        data?.emar_medication_exception?.id
      );

      onClose();
      toast({
        description: 'Exception created successfully'
      });
    } catch (error: any) {
      toast({
        description: 'Failed to create exception',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-xl">
              Exception for {resident?.first_name || data?.resident?.first_name}{' '}
              {resident?.last_name || data?.resident?.last_name}
            </DialogTitle>
          </DialogHeader>
          <ExceptionForm
            onSubmit={onSubmit}
            defaultValues={{
              date: new Date(new Date(date as Date).getTime() + new Date(date as Date).getTimezoneOffset() * 60000),
              time,
              reason: data?.emar_medication_exception?.reason || '',
              details: data?.emar_medication_exception?.details || '',
              discount_unit: data?.emar_medication_exception?.discount_unit || false
            }}
            disabled={medicationCardStatus === 'loading'}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
