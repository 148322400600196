import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import suggestions from '@/modules/residents/domain/mocks/AppraisalLikesAndDislikes';
import { SuggestionSheet } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/SuggestionSheet';

const formSchema = z.object({
  resident_description: z
    .string({
      required_error: 'Resident description is required'
    })
    .min(10, {
      message: 'Resident description must be at least 10 characters'
    })
    .optional(),
  resident_likes: z.string().optional(),
  resident_dislikes: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

interface Props {
  defaultValues: Partial<FormValues>;
}

export const BasicInformationForm = forwardRef(({ defaultValues }: Props, ref) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      return new Promise((resolve) => {
        form.handleSubmit((data) => {
          resolve(data);
        })();
      });
    }
  }));

  const addCompletitionToTextarea = (value: string, tree: any) => {
    const currentValue = form.getValues(tree) || '';
    const newValue = `${currentValue}\n -${value}`;
    const path = tree.split('.');
    const last = path.pop();
    const parent = path.reduce((acc: any, key: any) => acc[key], form.getValues());
    parent[last] = newValue;
    form.setValue(tree, parent[last]);
  };

  return (
    <>
      <Form {...form}>
        <form className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="border border-t-4 border-t-primary/80 p-2 rounded-lg">
            <FormField
              control={form.control}
              name="resident_description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Resident's Description (Update under Resident Profile)</FormLabel>
                  <FormControl>
                    <Textarea
                      className="h-[200px] border-none focus-visible:ring-0"
                      placeholder=""
                      {...field}
                      disabled={status === 'loading'}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="border border-t-4 border-t-primary/80 p-2 rounded-lg">
            <FormField
              control={form.control}
              name="resident_likes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Resident's Likes (Update under Resident Profile)</FormLabel>
                  <FormControl>
                    <div className="relative cursor-pointer">
                      <div className="absolute bottom-0 pb-3 left-0 pl-3 flex items-center">
                        <SuggestionSheet
                          label={'Resident Likes'}
                          defaultValue={suggestions?.likes}
                          addCompletitionToTextarea={(value: string) =>
                            addCompletitionToTextarea(value, 'resident_likes')
                          }
                        />
                      </div>
                      <Textarea
                        className="h-[200px] border-none focus-visible:ring-0 pb-14"
                        {...field}
                        onChange={(e) => {
                          form.setValue('resident_likes', e.target.value);
                        }}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="border border-t-4 border-t-primary/80 p-2 rounded-lg">
            <FormField
              control={form.control}
              name="resident_dislikes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Resident's Dislikes (Update under Resident Profile)</FormLabel>
                  <FormControl>
                    <div className="relative cursor-pointer">
                      <div className="absolute bottom-0 pb-3 left-0 pl-3 flex items-center">
                        <SuggestionSheet
                          label={'Resident Dislikes'}
                          defaultValue={suggestions?.dislikes}
                          addCompletitionToTextarea={(value: string) =>
                            addCompletitionToTextarea(value, 'resident_dislikes')
                          }
                        />
                      </div>
                      <Textarea
                        className="h-[200px] border-none focus-visible:ring-0 pb-14"
                        {...field}
                        onChange={(e) => {
                          form.setValue('resident_dislikes', e.target.value);
                        }}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>
    </>
  );
});
