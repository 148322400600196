import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { Alert } from '@/common/presentation/components/ui/alert';
import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { Label } from '@/common/presentation/components/ui/label';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { fetchGetSubscription, fetchRetryPayment } from '@/modules/subscription/presentation/slices/SubscriptionSlice';
import { RootState } from '@/store/store';
import { localization } from '@/utils/helpers/localization.helper';
import { format } from 'date-fns';
import { Loader2 } from 'lucide-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface props {
  subscriptionId: string;
}

const PaymentRetry: React.FC<props> = ({ subscriptionId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptions = useSelector((state: RootState) => state.subscription.subscriptions);
  const { clientInfo } = useConfigurationsProvider();
  const [disabledRetry, setDisabledRetry] = useState<boolean>(true);
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
  const [intervalIds, setIntervalIds] = useState<NodeJS.Timeout[]>([]);
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>({});
  const { t } = useTranslation();
  const [waitingRetry, setWaitingRetry] = useState({
    waiting: false,
    minutes_missing: ''
  });

  useEffect(() => {
    if (Object.keys(subscriptions).length == 0) return;
    getSubscription();
    return () => {
      cleanRemainingTime();
    };
  }, [subscriptions]);

  useEffect(() => {
    fetchSubscription();
  }, []);

  const fetchSubscription = useCallback(async () => {
    try {
      const response = await dispatch<any>(fetchGetSubscription(subscriptionId));

      if (response.error) {
        throw new Error(response.error.message);
      }

      const { subscription_payment } = response.payload;
      const { last_payment_try, current_time } = subscription_payment;
      if (last_payment_try) {
        const currentServerTime = current_time;
        if (last_payment_try && last_payment_try.try_number === 2) {
          setDisabledRetry(true);
          timeRemainingRetry(currentServerTime, last_payment_try.try_date_time);
        }
        return;
      }
      setDisabledRetry(false);
    } catch (error) {
      navigate('/dashboard', { replace: true });
      return;
    }
  }, []);

  const subscriptionIsPaymentPending = useCallback(
    (subscription: any) => {
      return ['past_due', 'payment_pending'].includes(subscription.status);
    },
    [subscriptions]
  );

  const getSubscription = async () => {
    const [subscriptionKey, subscription] =
      Object.entries(subscriptions).find(([key, value]) => {
        return value?.id === subscriptionId;
      }) || [];

    if (!subscriptionKey || !subscriptionIsPaymentPending(subscription)) {
      navigate('/dashboard', { replace: true });
      return;
    }

    setSubscriptionInfo(subscription);
  };

  const timeRemainingRetry = useCallback((currentTime: string, finishingTime: string) => {
    const calculateRemaningTime = (currentTimeDate: Date, finishingTimeDate: Date) => {
      const difference = finishingTimeDate.getTime() - currentTimeDate.getTime();
      if (difference <= 0) {
        return { waiting: false, minutes_missing: '00:00' };
      } else {
        const remainingMinutes = Math.floor((difference / (1000 * 60)) % 60);
        const remainingSeconds = Math.floor((difference / 1000) % 60);
        return {
          waiting: true,
          minutes_missing: `${remainingMinutes}:${remainingSeconds.toString().padStart(2, '0')}`
        };
      }
    };

    cleanRemainingTime();

    let currentTimeDate = new Date(currentTime);
    const finishingTimeDate = new Date(finishingTime);

    const intervalId: NodeJS.Timeout = setInterval(() => {
      try {
        const { waiting, minutes_missing } = calculateRemaningTime(currentTimeDate, finishingTimeDate);
        setWaitingRetry({ waiting, minutes_missing });
        if (!waiting) {
          clearInterval(intervalId);
          setDisabledRetry(false);
        }
        currentTimeDate = new Date(currentTimeDate.getTime() + 1000);
      } catch (error) {
        console.log(`Error in timer`);
      }
    }, 1000);
    setIntervalIds([...intervalIds, intervalId]);
  }, []);

  const cleanRemainingTime = () => {
    intervalIds.forEach((intervalId) => {
      clearInterval(intervalId);
    });
    setIntervalIds([]);
  };

  const initialsNames = (serviceName: string) => {
    const names = serviceName.split(' ');
    return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
  };

  const retryPayment = useCallback(async () => {
    setLoadingPayment(true);
    const response = await dispatch<any>(fetchRetryPayment({ subscription_id: subscriptionId }));
    cleanRemainingTime();
    if (response.payload.error) {
      const { current_time, last_payment_try } = response.payload.payload;

      if (last_payment_try) {
        const currentServerTime = current_time;
        if (last_payment_try && last_payment_try.try_number === 2) {
          setDisabledRetry(true);
          timeRemainingRetry(currentServerTime, last_payment_try.try_date_time);
        } else {
          setDisabledRetry(false);
        }
      }
      toast({
        title: `${response.payload.error}`,
        description: t('subscription.tryChangeDefaultPayment'),
        variant: 'destructive'
      });
      setLoadingPayment(false);
      return;
    }

    toast({
      title: t('subscription.subscriptionPaidSuccessfully'),
      className: 'bg-green-500 text-white'
    });
    navigate('/dashboard', { replace: true });
    setLoadingPayment(false);
  }, [subscriptionId]);

  return (
    <div>
      {subscriptions[subscriptionInfo.service_key]?.is_invoice_blocked !== undefined &&
        subscriptions[subscriptionInfo.service_key]?.is_invoice_blocked === true && (
          <Alert className="border-2 bg-background border-red-400 mb-4 ">
            <div className="flex justify-center">
              {t('subscription.yourInvoiceHasBeenBlocked')}
            </div>
          </Alert>
        )}
      {Object.keys(subscriptionInfo).length > 0 && (
        <Card>
          <CardContent className="pb-0">
            <div className="flex justify-between py-2 flex-col md:flex-row">
              <div className="flex items-center hidden md:block">
                <div className="w-14 h-14 border border-1 flex rounded-md justify-center items-center bg-primary">
                  <Label className="font-bold text-lg select-none text-white">
                    {initialsNames(subscriptionInfo.service.name)}
                  </Label>
                </div>
              </div>
              <div className="flex flex-col flex-grow gap-1 md:pl-4">
                <div className="flex justify-start">
                  <Label className="font-bold text-base">{t(`subscription.${subscriptionInfo.service.name}`)}</Label>
                </div>
                <div className="flex justify-start">
                  <Label className="font-light">
                    {t('subscription.thePaymentForYourSubscriptionOf')}{' '}
                    <span className="font-bold">
                      {localization.formatPrice(subscriptionInfo.price, clientInfo.country.currency)}
                    </span>{' '}
                    {t('subscription.failedOn')} {format(new Date(subscriptionInfo.payments_pending[0].created_at), 'yyyy-MM-dd')}.
                  </Label>
                </div>
              </div>
              <div className="flex md:flex-col justify-center">
                <Button
                  onClick={() => retryPayment()}
                  disabled={disabledRetry || loadingPayment || waitingRetry.waiting}
                  className="w-full lg:w-auto mt-3 md:mt-0"
                >
                  {loadingPayment && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  {waitingRetry.waiting
                    ? t('subscription.waitMinutes', {minutesMissing: waitingRetry.minutes_missing})
                    : loadingPayment
                    ? t('subscription.retryingPayment')
                    : t('subscription.retryPayment')}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default PaymentRetry;
