import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/common/presentation/components/ui/button';
import { Form, FormField, FormItem, FormLabel } from '@/common/presentation/components/ui/form';
import { Label } from '@/common/presentation/components/ui/label';
import { BadgeCheck, Loader2, TriangleAlert } from 'lucide-react';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { IFacilitiesInfo, ServiceI, SubscriptionI } from '@/modules/subscription/domain/makerPlace.domain';
import { RootState } from '@/store/store';
import Counter from '@/common/presentation/components/Counter/Counter';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { calculatePrice } from '@/utils/helpers/subscriptions.helper';
import { localization } from '@/utils/helpers/localization.helper';
import BedSetting from '../BedSetting';
import { getLocations } from '@/modules/locations/presentation/slices/locationsSlice';
import { useMarketPlaceFormData } from '@/modules/subscription/infraestructure/providers/MarketPlaceProvider';
import { Alert, AlertDescription, AlertTitle } from '@/common/presentation/components/ui/alert';
import { fetchGetClientSubscriptions, fetchUpdateSubscription } from '../../slices/SubscriptionSlice';

interface Props {
  serviceInfo: ServiceI;
}

interface LoadingI {
  [key: string]: boolean;
}

interface PriceI {
  price: number;
  ranges: {
    max_beds: number;
    min_beds: number;
  };
}

const getTrainingLabelPrice = (trainingPrice: PriceI, currency: string) => {
  const { ranges, price } = trainingPrice;
  const formattedPrice = localization.formatPrice(price, currency);
  let label = `${formattedPrice} per month for ${ranges.min_beds} beds`;
  label += ranges.max_beds ? ` to ${ranges.max_beds}` : ' or more';
  label += ' beds';
  return label;
};

const ServiceEssentialForm: React.FC<Props> = ({ serviceInfo }) => {
  const subscriptions = useSelector((state: RootState) => state.subscription.subscriptions);
  const locations = useSelector((state: RootState) => state.locations.allLocations.locations);
  const services = useSelector((state: RootState) => state.subscription.services);
  const { clientInfo, configurations } = useConfigurationsProvider();
  const { updateFormData } = useMarketPlaceFormData();
  const dispatch = useDispatch();
  const form = useForm({});
  const [state, setState] = useState({
    subscriptionInfo: {} as Partial<SubscriptionI>,
    facilities: [] as IFacilitiesInfo[],
    isSettingUpBeds: false,
    price: 0,
    priceExtraBed: '',
    trainingAlert: null as string | null,
    currentTrainingPrice: 0,
    isLoading: {} as Partial<LoadingI>,
    clientFacilitiesNumber: 0
  });

  useEffect(() => {
    if (Object.keys(subscriptions).length > 0) {
      initializeForm();
    }
  }, [subscriptions]);

  useEffect(() => {
    if (state.facilities.length > 0) {
      calculatePrices();
    }
  }, [state.facilities]);

  const initializeForm = () => {
    const clientLocations = locations.map((location) => ({
      name: location.name,
      beds_number: location.max_beds,
      id: location.id
    }));
    setState((prevState) => ({
      ...prevState,
      facilities: clientLocations,
      clientFacilitiesNumber: clientLocations.length
    }));
    form.setValue('facilities_number', clientLocations.length);
    getSubscriptionInfo(serviceInfo.key);
  };

  const getSubscriptionInfo = (serviceKey: string) => {
    const subscription = subscriptions[serviceKey] || {};
    setState((prevState) => ({
      ...prevState,
      subscriptionInfo: subscription
    }));
  };

  const calculatePrices = () => {
    getPrice();
    getTrainingPrice();
  };

  const onSubmit = async (formData: Object) => {
    const data = {
      facilities: state.facilities,
      subscription_id: state.subscriptionInfo.id
    };
    setState((prevState) => ({
      ...prevState,
      isLoading: { ...prevState.isLoading, [serviceInfo.key]: true }
    }));
    try {
      const response = await dispatch<any>(fetchUpdateSubscription(data));
      if (!response.error) {
        await dispatch<any>(getLocations());
        await dispatch<any>(fetchGetClientSubscriptions());
        updateFormData({
          ...formData,
          isDialogOpen: false
        });
        toast({
          title: 'Subscription',
          description: 'Your subscription was updated successfully',
          className: 'bg-green-500 text-white'
        });
      } else {
        throw new Error(response.error.message);
      }
    } catch (error: any) {
      toast({
        title: 'Subscription',
        description: error.message,
        variant: 'destructive'
      });
    } finally {
      setState((prevState) => ({
        ...prevState,
        isLoading: { ...prevState.isLoading, [serviceInfo.key]: false }
      }));
    }
  };

  const getPrice = () => {
    const facilityModule = serviceInfo.modules.find((module) => module.key === 'facility');
    const bedModule = serviceInfo.modules.find((module) => module.key === 'bed');
    if (!facilityModule || !bedModule) return;

    const facilityPrice = calculatePrice(facilityModule);
    const bedPrice = calculatePrice(bedModule);

    setState((prevState) => ({
      ...prevState,
      priceExtraBed: localization.formatPrice(bedPrice.price, clientInfo.country.currency)
    }));

    const extraBeds = state.facilities.reduce((sum, facility) => {
      return sum + (facility.beds_number > 10 ? facility.beds_number - 10 : 0);
    }, 0);

    const totalPrice = facilityPrice.price * state.facilities.length + bedPrice.price * extraBeds;
    setState((prevState) => ({
      ...prevState,
      price: totalPrice
    }));
  };

  const inputFacilitiesHandler = (value: number) => {
    const newFacilities = [...state.facilities];
    if (value > newFacilities.length) {
      const additionalFacilities = Array.from({ length: value - newFacilities.length }, () => ({
        id: '',
        beds_number: 10,
        name: 'New Facility'
      }));
      setState((prevState) => ({
        ...prevState,
        facilities: [...newFacilities, ...additionalFacilities]
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        facilities: newFacilities.slice(0, value)
      }));
    }
  };

  const saveBedsChanges = (facilitiesList: IFacilitiesInfo[]) => {
    setState((prevState) => ({
      ...prevState,
      facilities: facilitiesList,
      isSettingUpBeds: false
    }));
  };

  const getTrainingPrice = () => {
    const totalBeds = state.facilities.reduce((sum, facility) => sum + facility.beds_number, 0);
    const trainingPlan = subscriptions['training_plan'];

    if (trainingPlan) {
      const activePlan = trainingPlan.plans.find((plan) => plan.status === 'active');
      if (activePlan) {
        const trainingKeyModule = activePlan.service_module.key;
        const trainingModule = services
          .find((service) => service.key === 'training_plan')
          ?.modules.find((module) => module.key === trainingKeyModule);
        if (trainingModule) {
          const trainingPrice: PriceI = calculatePrice(trainingModule, totalBeds, clientInfo.country.code);
          if (state.currentTrainingPrice === 0) {
            setState((prevState) => ({
              ...prevState,
              currentTrainingPrice: trainingPrice.price
            }));
          }
          if (state.currentTrainingPrice !== 0 && state.currentTrainingPrice !== trainingPrice.price) {
            setState((prevState) => ({
              ...prevState,
              trainingAlert: getTrainingLabelPrice(trainingPrice, clientInfo.country.currency)
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              trainingAlert: ''
            }));
          }
        }
      }
    }
  };

  return (
    <>
      {!state.isSettingUpBeds ? (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="my-3" key={JSON.stringify(state.facilities)}>
              <FormField
                control={form.control}
                name="facilities_number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Number of Facilities</FormLabel>
                    <Counter
                      value={state.facilities.length}
                      onChange={(value) => {
                        field.onChange(value);
                        inputFacilitiesHandler(value);
                      }}
                      defaultValue={state.facilities.length}
                      name={field.name}
                      maxValue={30}
                      minValue={state.clientFacilitiesNumber}
                      step={1}
                    />
                  </FormItem>
                )}
              />
            </div>
            <div className="col-span-1">
              <div className="flex flex-row items-center gap-1">
                <Button
                  className="p-0"
                  onClick={(e) => {
                    e.preventDefault();
                    setState((prevState) => ({
                      ...prevState,
                      isSettingUpBeds: true
                    }));
                    //setIsSetUpModalOpen(true);
                  }}
                  variant="link"
                >
                  Set up facility's beds
                </Button>
              </div>
            </div>
            {configurations.free_access ? (
              <div className="col-span-2 mt-1">
                <Alert className="border-primary bg-primary/5">
                  <BadgeCheck className="h-4 w-4 stroke-primary" />
                  <AlertTitle>Free Access</AlertTitle>
                  <AlertDescription>Your account does not require payments for this service</AlertDescription>
                </Alert>
              </div>
            ) : (
              <>
                {state.trainingAlert && (
                  <div className="col-span-2">
                    <Alert className="border-amber-400">
                      <TriangleAlert className="h-4 w-4 stroke-amber-400" />
                      <AlertTitle>Training Plan</AlertTitle>
                      <AlertDescription>
                        Your new subscription price for the Training Plan is {state.trainingAlert}
                      </AlertDescription>
                    </Alert>
                  </div>
                )}
                <div className="mt-3">
                  <Label className="font-bold text-3xl">
                    {localization.formatPrice(state.price, clientInfo.country.currency)}
                  </Label>{' '}
                  / Monthly
                </div>
              </>
            )}
            <div className="mt-9">
              <div className="mb-2">
                <Label className="text-xs font-light line-height-5">
                  {Object.values(state.subscriptionInfo).length > 0
                    ? `
            By modifying your subscription, you agree to update your subscription details. Any changes made will be 
            reflected in your next billing cycle.
          `
                    : `
            By subscribing, you agree to immediate billing using your registered payment method. Charges occur upon
            subscription activation. You can cancel anytime from your account.
          `}
                </Label>
              </div>
              <Button type="submit" className="w-full" disabled={state.isLoading[serviceInfo.key]}>
                {state.isLoading[serviceInfo.key] && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                {Object.values(state.subscriptionInfo).length > 0
                  ? state.isLoading[serviceInfo.key]
                    ? 'Updating Subscription'
                    : 'Update Subscription'
                  : state.isLoading[serviceInfo.key]
                  ? 'Subscribing'
                  : 'Subscribe'}
              </Button>
            </div>
          </form>
        </Form>
      ) : (
        <BedSetting
          facilities={state.facilities}
          name="beds_number"
          onSave={(facilitiesList) => saveBedsChanges(facilitiesList)}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              isSettingUpBeds: false
            }));
          }}
          extraBedPrice={state.priceExtraBed}
        />
      )}
    </>
  );
};

export default ServiceEssentialForm;
