import { create } from 'zustand';
import EmarService from '@/modules/emar/infrastructure/services/EmarService';

type MedicationState = {
  medications: any[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  getMedicationsTimesByResident: (locationId: string, residentId: string, date: string) => void;
  dispenseMedication: (
    locationId: string,
    residentId: string,
    residentMedicationId: string,
    timeToBeDispensed: string,
    dateToBeDispensed: string
  ) => Promise<any>;
  witnessMedication: (
    locationId: string,
    residentId: string,
    emarMedicationId: string,
    timeToBeDispensed: string,
    dateToBeDispensed: string
  ) => Promise<any>;
  medicationException: (
    locationId: string,
    residentId: string,
    residentMedicationId: string,
    dateToBeDispensed: string,
    times: string[],
    reason: string,
    details: string,
    discountUnit: boolean,
    exceptionId?: string
  ) => Promise<any>;
  dispensePrnMedication: (
    timeGiven: string,
    followUp: string,
    reason: string,
    levelOfPain: string,
    result: string,
    comments: string,
    residentId: string,
    locationId: string,
    residentMedicationId: string,
    dispenseId: string
  ) => Promise<any>;
};

export const useMedicationStore = create<MedicationState>((set) => ({
  medications: [],
  status: 'idle',
  error: null,

  getMedicationsTimesByResident: async (locationId, residentId, date) => {
    set({ status: 'loading' });
    try {
      const response = await EmarService.getResidentMedicationsTimesByResident(locationId, residentId, date);
      set({ medications: response?.medications, status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  dispenseMedication: async (locationId, residentId, residentMedicationId, timeToBeDispensed, dateToBeDispensed) => {
    set({ status: 'loading' });
    try {
      const response = await EmarService.handleDispenseMedication({
        location_id: locationId,
        resident_id: residentId,
        resident_medication_id: residentMedicationId,
        time_to_be_dispensed: timeToBeDispensed,
        date_to_be_dispensed: dateToBeDispensed
      });
      set({ medications: response?.medications, status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  witnessMedication: async (locationId, residentId, emarMedicationId, timeToBeDispensed, dateToBeDispensed) => {
    set({ status: 'loading' });
    try {
      const response = await EmarService.handleWitnessMedication({
        location_id: locationId,
        resident_id: residentId,
        emar_medication_id: emarMedicationId,
        time_to_be_witnessed: timeToBeDispensed,
        date_to_be_witnessed: dateToBeDispensed
      });
      set({ medications: response?.medications, status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  medicationException: async (
    locationId,
    residentId,
    residentMedicationId,
    dateToBeDispensed,
    times,
    reason,
    details,
    discountUnit,
    exceptionId
  ) => {
    set({ status: 'loading' });
    try {
      const response = await EmarService.handleExceptionMedication({
        location_id: locationId,
        resident_id: residentId,
        resident_medication_id: residentMedicationId,
        date_to_be_dispensed: dateToBeDispensed,
        times,
        reason,
        details,
        discount_unit: discountUnit,
        exception_id: exceptionId
      });
      set({ medications: response?.medications, status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  dispensePrnMedication: async (
    timeGiven,
    followUp,
    reason,
    levelOfPain,
    result,
    comments,
    residentId,
    locationId,
    residentMedicationId,
    dispenseId
  ) => {
    set({ status: 'loading' });
    try {
      const response = await EmarService.handleDispensePrnMedication({
        time_given: timeGiven,
        follow_up: followUp,
        reason,
        level_of_pain: levelOfPain,
        result,
        comments,
        resident_id: residentId,
        location_id: locationId,
        resident_medication_id: residentMedicationId,
        dispense_id: dispenseId
      });
      set({ medications: response?.medications, status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
