import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { FormFieldPayload, FormFieldToRequest } from '@/modules/residents/domain/models/FormField';
import { usePlanOfCareStore } from '@/modules/residents/domain/stores/plan-of-care/planOfCareStore';
import { useEditFormFieldsManager } from '@/modules/residents/infrastructure/hooks/plan-of-care/useEditFormFieldsManager';
import { useCallback } from 'react';
import { CustomFieldsForm } from '../Forms/FieldsFormManagerForm';

export const EditFieldFormDialog = () => {
  const { isOpen, onClose, planOfCare } = useEditFormFieldsManager();
  const { status, error: errorMessage, addNewFieldForm, getFormFields } = usePlanOfCareStore();

  const onSubmit = useCallback(async (values: FormFieldToRequest) => {
    const payload: FormFieldPayload = {
      form_id: 'plan_of_care',
      label: values.label,
      value: values.value,
      placeholder: values.placeholder,
      isVisible: true,
      isRequired: values.isRequired,
      isDefault: false,
      order: planOfCare?.order || 0
    };

    try {
      await addNewFieldForm(payload);

      toast({
        description: 'Field added successfully',
        variant: 'default'
      });

      await getFormFields('plan_of_care');

      onClose();
    } catch (error: any) {
      if (error.response?.data?.message) {
        toast({
          description: error.response.data.message,
          variant: 'destructive'
        });
      } else if (errorMessage === 'The key already exists in this form.') {
        toast({
          description: 'The key already exists in this form.',
          variant: 'destructive'
        });
      } else {
        toast({
          description: 'An error occurred while adding the field',
          variant: 'destructive'
        });
      }

      console.error('Error adding field:', error);
    }
  }, []);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-auto max-h-[80vh] h-auto overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold text-primary">Edit Form Field</DialogTitle>
            <Separator className="bg-primary" />
          </DialogHeader>
          <CustomFieldsForm
            onSubmit={onSubmit}
            status={status}
            defaultValues={{
              label: planOfCare?.label,
              placeholder: planOfCare?.placeholder || '',
              isRequired: planOfCare?.isRequired,
              value: planOfCare?.value
            }}
            isEdit
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
