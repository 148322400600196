import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const FormsService = {
  getFormHistory: async (residentId: string, key: string) => {
    const response = await axios.get(`/residents/forms/${residentId}/${key}`);
    return response;
  },

  saveForm: async (data: any, pdf: boolean) => {
    const response = await axios.post('/residents/forms', data, pdf ? { responseType: 'blob' } : {});
    return response;
  },

  deleteForm: async (id: string) => {
    const response = await axios.delete(`/residents/forms/${id}`);
    return response;
  },

  generateHistoryPdf: async (form_id: string) => {
    const response = await axios.get(`/residents/forms/generate-history-pdf/${form_id}`, { responseType: 'blob' });
    return response;
  },

  getFormResources: async (form_id: string) => {
    const response = await axios.get(`/residents/forms-resources/${form_id}`);
    return response;
  }
};

export default FormsService;
