import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Save, Trash } from 'lucide-react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export interface DefaultValues {
  id?: string | null;
  exam_or_procedure: string;
  description: string;
  date: Date | undefined;
}

interface Props {
  defaultValues: DefaultValues;
  disabled?: boolean;
  onSubmit: (values: any) => void;
  onRemove: (id: string | null) => void;
}

const formSchema = z.object({
  exam_or_procedure: z
    .string({ required_error: 'Exman name or procedure is required' })
    .min(3, { message: 'Exam name or procedure must be at least 3 characters' }),
  description: z.string().optional(),
  date: z.date().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const ExamOrProcedure = ({ defaultValues, onSubmit, onRemove, disabled = false }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit({ id: defaultValues?.id, ...values });
    },
    [onSubmit, defaultValues?.id]
  );

  const handleRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onRemove((defaultValues?.id as string) || null);
    },
    [onRemove, defaultValues?.id]
  );

  return (
    <>
      <div className="bg-white drop-shadow-md border rounded-md p-4 w-full">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="grid grid-cols-3 gap-4">
            <FormField
              control={form.control}
              name="exam_or_procedure"
              render={({ field }) => (
                <FormItem className="col-span-full xl:col-span-1">
                  <FormLabel>
                    Exam / Procedure
                    <span className="text-destructive ml-1">(*)</span>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="col-span-full xl:col-span-1">
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-end gap-2 col-span-full xl:col-span-1">
              <FormField
                control={form.control}
                name="date"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Date of the exam / procedure</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                            disabled={disabled}
                          >
                            {field.value ? (
                              format(field.value, 'MM/dd/yyyy')
                            ) : (
                              <div>Select a date of the exam / procedure</div>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <div className="rounded-md border">
                          <CustomCalendar
                            onChange={field.onChange}
                            value={field.value || undefined}
                            MonthAndYearPicker
                          />
                        </div>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                <Button
                  className="flex gap-2 border-green-500 hover:bg-green-500/10"
                  variant={'outline'}
                  size={'icon'}
                  type="submit"
                  disabled={disabled}
                >
                  <Save className="h-4 w-4 flex-1 text-green-500" />
                </Button>
              )}

              <Button
                className="flex gap-2 border-red-500 hover:bg-red-500/10"
                variant={'outline'}
                size={'icon'}
                onClick={(e) => handleRemove(e)}
              >
                <Trash className="h-4 w-4 flex-1 text-red-500" />
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
