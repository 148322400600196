import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { TrackerCleaningQuickTracker } from "./TrackerCleaningQuickTracker";
import { TrackerCleaningForm } from "./TrackerCleaningForm";
import { Tabs, TabsList, TabsTrigger } from "@/common/presentation/components/ui/tabs";
import { useEffect, useState } from "react";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export function TrackerCleaningTrackers() {
    const [selectedTracker, setTracker] = useState("quick");
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const { t } = useTranslation();
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const onSelectedTracker = (event: string) => {
        setTracker(event);
    }

    return (
        <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
            <CardHeader>
                <div className="flex justify-between w-100">
                    {
                        selectedTracker === "quick" && <CardTitle className="flex flex-row items-center text-lg font-bold" style={{color: accentColor}}>
                             { t("cleaning.form.quick.title") }
                        </CardTitle>
                    }

                    {
                        selectedTracker === "detailed" && <CardTitle className="flex flex-row items-center text-lg font-bold" style={{color: accentColor}}>
                            { t("cleaning.form.detailed.title") }
                        </CardTitle>
                    }

                    <Tabs defaultValue={selectedTracker} onValueChange={(event) => { onSelectedTracker(event); }} className="w-auto mb-3">
                        <TabsList>
                            <TabsTrigger value="quick" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                            { t("cleaning.form.quick.label") }
                            </TabsTrigger>

                            <TabsTrigger value="detailed" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                            { t("cleaning.form.detailed.label") }
                            </TabsTrigger>
                        </TabsList>
                    </Tabs>
                </div>
            </CardHeader>
            
            <CardContent>
                {
                    selectedTracker === "quick" && <TrackerCleaningQuickTracker />
                }

                {
                    selectedTracker === "detailed" && <TrackerCleaningForm />
                }
            </CardContent>
        </Card>
    );
}
