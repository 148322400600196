import { Button } from '@/common/presentation/components/ui/button';
import { Eye, Pencil } from 'lucide-react';
import { Link } from 'react-router-dom';

interface Props {
  id: string;
}

export const TableActions = ({ id }: Props) => {
  return (
    <>
      <div className="flex gap-2">
      <Button size={'icon'} variant={'outline'} asChild>
          <Link to={`/staff/profile?sm=${id}`}>
            <Eye className="size-4" />
          </Link>
        </Button>
        <Button size={'icon'} variant={'outline'} asChild>
          <Link to={`/staff/update?sm=${id}`}>
            <Pencil className="size-4" />
          </Link>
        </Button>
      </div>
    </>
  );
};
