// columnsContacts.tsx
import renderActionsColumn from './renderActionsColumn';
import { useTranslation } from 'react-i18next';

const useContactsColumns = () => {
  const { t } = useTranslation();
  const ContactsColumns = [
    {
      key: 'category',
      id: 'category',
      labelTranslationKey: 'contacts.category',
      value: 'category',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'linkto',
      id: 'linkto',
      labelTranslationKey: 'contacts.resident',
      value: 'linkto',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'full_name',
      id: 'full_name',
      labelTranslationKey: 'contacts.contact',
      value: 'full_name',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'phone',
      id: 'phone',
      labelTranslationKey: 'contacts.phone',
      value: 'phone',
      visible: true,
      sortable: true,
      static: true,
      width: '20%'
    },
    {
      key: 'actions',
      labelTranslationKey: 'contacts.actions',
      render: renderActionsColumn,
      visible: true,
      static: true,
      width: '20%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(ContactsColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = ContactsColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default useContactsColumns;
