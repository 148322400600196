import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { AppDispatch } from '@/store/store';
import { EllipsisVertical, File } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as residentActions from '../../slices/residentSlice';
import { Input } from '@/common/presentation/components/ui/input';

interface Props {
  id: string;
  isParent?: boolean;
  documentUrl: string;
  status: 'active' | 'inactive';
  documentInfo?: any;
  onDelete: (id: string) => void;
}

const documentNavigationTabs = [
  {
    title: 'Edit Document',
    value: 'edit_document'
  },
  {
    title: 'Delete Document',
    value: 'delete_document'
  }
];

export const DocumentsHistoryTableActions = ({
  id,
  isParent = true,
  documentUrl,
  status,
  onDelete,
  documentInfo
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [documentStatus, setDocumentStatus] = useState(status);

  const [isEditing, setIsEditing] = useState(false);
  const [editComment, setEditComment] = useState(documentInfo?.comment || '');

  useEffect(() => {
    if (documentStatus === 'inactive') {
      setFile(undefined);
    }
  }, [documentStatus]);

  const handleViewDocument = () => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, '_blank');
    } else if (documentUrl && documentStatus === 'active') {
      window.open(documentUrl, '_blank');
    }
  };

  const handleEditDocument = async () => {
    try {
      await dispatch(
        residentActions.updateResidentDocument({
          documentId: id,
          data: {
            comment: editComment
          }
        })
      );
      alert('Document updated successfully.');
      setIsEditing(false);
    } catch (error) {
      alert('Failed to update document. Please try again.');
    }
  };

  const handleDeleteDocument = async () => {
    if (window.confirm('Are you sure you want to delete this document (se elimina por completo)?')) {
      try {
        const response = await dispatch(residentActions.deleteResidentDocument(id));
        console.log({ response });
        setDocumentStatus('inactive');
        onDelete(id); // Llamar a la función onDelete
        alert('Document deleted successfully.');
      } catch (error) {
        alert('Failed to delete document. Please try again.');
      }
    }
  };

  const handleFileChange = (file?: File) => {
    setFile(file);
    if (file) {
      console.log('File selected:', file);
    }
  };

  return (
    <div className="flex gap-10">
      <Button variant={'outline'} onClick={handleViewDocument} disabled={documentStatus === 'inactive'}>
        <File className="size-4" />
      </Button>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="icon">
            <EllipsisVertical className="size-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {documentNavigationTabs.map((item, index) => (
            <DropdownMenuItem
              key={index}
              onClick={() => {
                if (item.value === 'edit_document') {
                  if (window.confirm('Are you sure you want to edit the document data?')) {
                    setIsEditing(!isEditing);
                  }
                } else if (item.value === 'delete_document') {
                  handleDeleteDocument();
                }
              }}
            >
              <span className={item.value === 'delete_document' ? 'text-red-500' : ''}>{item.title}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      {isEditing && (
        <div className="flex flex-col gap-2   rounded-md bg-white">
          <Input placeholder="Comment" value={editComment} onChange={(e) => setEditComment(e.target.value)} />
          <Button variant="default" onClick={handleEditDocument}>
            Save Changes
          </Button>
        </div>
      )}
    </div>
  );
};
