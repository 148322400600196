import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';
import { useHistoryDocuments } from '@/modules/residents/infrastructure/hooks/use-history-documents';
import { DocumentsHistorycolumns } from './DocumentsHistoryColumns';

export const HistoryDocumentsDialog = () => {
  const { isOpen, onClose, documents } = useHistoryDocuments();

  const columnsFiltered = DocumentsHistorycolumns.filter((column) => column.header !== 'Version History');

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[55vw]  max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl">Documents History</DialogTitle>
          </DialogHeader>

          <SingleTable data={documents} columns={columnsFiltered} onDelete={() => {}} disable={false} filter={true} />
        </DialogContent>
      </Dialog>
    </>
  );
};
