import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { cn } from '@/lib/utils';
import { ICountry } from '@/modules/wizard/domain/signup.domain';
import { useWizardFormData } from '@/modules/wizard/infrastructure/providers/FormDataProvider';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { CountryCode, E164Number } from 'libphonenumber-js/core';
import { Loader2 } from 'lucide-react';
import CountrySelector from '../CountrySelector/CountrySelector';
import { useTranslation } from 'react-i18next';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';
import InputField from './components/InputField';
interface IProps {
  handleNextStep: (step: number) => void;
}

interface IErrors {
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  email: string | null;
  client_name: string | null;
  country: string;
}

const Step1: React.FC<IProps> = ({ handleNextStep }) => {
  const navigate = useNavigate();
  const { updateFormData, formData } = useWizardFormData();
  const [errors, setErrors] = useState<Partial<IErrors>>({});
  const { countries } = useSelector((state: any) => state.signUp);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const clientNameRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const countriesCode: CountryCode[] = useMemo(() => {
    return countries.map((country: ICountry) => country.code.toUpperCase());
  }, [countries]);

  const isReady: boolean = useMemo(() => {
    if (countries.length > 0 && formData.client_country.code) {
      return true;
    }
    return false;
  }, [countries, formData]);

  const handleInputChange = (name: string, value: string) => {
    updateFormData({
      ...formData,
      errors: {
        ...formData.errors,
        [name]: null
      }
    });
    setErrors({
      ...errors,
      [name]: null
    });
    updateFormData({ [name]: value });
  };

  const handlePhoneChange = (value: string) => {
    updateFormData({ phone: value });
  };

  const handleContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (validateForm()) {
      handleNextStep(1);
    }
  };

  const handleCountryChange = (value: string) => {
    const countryInfo = countries.find((country: ICountry) => country.code === value.toLocaleLowerCase());
    updateFormData({ client_country: countryInfo });
  };

  const validateForm = () => {
    setErrors({});
    const schema = z.object({
      first_name: z
        .string()
        .regex(/^[a-zA-Z\s]*$/, { message: 'First name must contain only letters' })
        .nonempty('First name required'),
      email: z.string().email('Email not valid').nonempty('Email required'),
      phone: z.string().nonempty('Phone number required'),
      last_name: z.string().nonempty('Last name required'),
      client_name: z.string().nonempty('Company name required')
    });
    try {
      schema.parse(formData);
      return true;
    } catch (error: any) {
      const errors = error.errors.reduce((acc: any, curr: any) => {
        return { ...acc, [curr.path[0]]: curr.message };
      }, {});
      setErrors(errors);
      updateFormData({ ...formData, [errors]: errors });
      scrollToFieldError(Object.keys(errors)[0]);
      return false;
    }
  };

  const scrollToFieldError = (fieldError: string) => {
    switch (fieldError) {
      case 'first_name':
        firstNameRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'last_name':
        lastNameRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'phone':
        phoneRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'email':
        emailRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'client_name':
        clientNameRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  };

  const backToLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate('/login');
  };

  return (
    <>
      {!isReady ? (
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
      ) : (
        <div className="col-span-3 w-11/12 lg:w-9/12 flex md:block items-center justify-center">
          <Card className={`border-0 shadow-none flex flex-col md:mt-20`}>
            <CardHeader>
              <CardTitle className="flex flex-row items-center text-2xl font-bold">
                {t('signUp.personalInformation')}
              </CardTitle>
              <div className="flex w-full items-center justify-between text-lg">
                <div>{t('signUp.fillPersonalInformation')}</div>
              </div>
            </CardHeader>
            <CardContent>
              <form className="flex flex-col h-full">
                <div className="grid grid-cols-1 gap-2">
                  <div className="w-full flex flex-col gap-2">
                    <div className="grid grid-rows-1 md:grid-cols-2 md:gap-4">
                      <InputField
                        label={t('signUp.firstName')}
                        type="text"
                        name="first_name"
                        placeHolder={t('signUp.firstName')}
                        value={formData.first_name}
                        onChange={handleInputChange}
                        requiredLabel={true}
                        errors={errors.first_name ?? ''}
                        className="w-full"
                        autoComplete="on"
                      />
                      <div className="col-span-1" ref={lastNameRef}>
                        <InputField
                          label={t('signUp.lastName')}
                          type="text"
                          name="last_name"
                          placeHolder={t('signUp.lastName')}
                          value={formData.last_name}
                          onChange={handleInputChange}
                          className="w-full"
                          requiredLabel={true}
                          autoComplete="on"
                          errors={errors.last_name ?? ''}
                        />
                      </div>
                    </div>

                    <div className="grid grid-rows-1 md:grid-cols-2 md:gap-4">
                      <InputField label={t('signUp.country')} requiredLabel={true}>
                        <CountrySelector
                          defaultCountry={formData?.client_country?.code.toUpperCase()}
                          country={formData?.client_country?.code.toUpperCase()}
                          name="country"
                          onChange={(e) => {
                            handleCountryChange(e.value.toLowerCase());
                          }}
                          countries={countriesCode}
                        />
                      </InputField>

                      <InputField label={t('signUp.phoneNumber')} requiredLabel={true}>
                        <PhoneInput
                          name="phone"
                          key={JSON.stringify(countriesCode)}
                          countries={countriesCode}
                          defaultCountry={formData?.client_country?.code.toUpperCase() as CountryCode}
                          value={formData.phone as E164Number}
                          onChange={(e) => handlePhoneChange(e)}
                        />
                      </InputField>
                    </div>

                    <div className="col-span-1" ref={emailRef}>
                      <InputField
                        label={t('signUp.email')}
                        type="email"
                        name="email"
                        placeHolder={t('signUp.email')}
                        value={formData.email}
                        onChange={handleInputChange}
                        className="w-full"
                        errors={errors.email ?? ''}
                        requiredLabel={true}
                        autoComplete="on"
                      />
                    </div>

                    <div className="col-span-1" ref={clientNameRef}>
                      <InputField
                        label={t('signUp.companyName')}
                        type="text"
                        placeHolder={t('signUp.companyName')}
                        requiredLabel={true}
                        name="client_name"
                        value={formData.client_name}
                        onChange={handleInputChange}
                        errors={errors.client_name ?? ''}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center pt-5 mt-4">
                  <Button className="w-full" onClick={(e) => handleContinue(e)}>
                    {t('signUp.continue')}
                  </Button>
                  <Button className="pt-0 mt-2" onClick={(e) => backToLogin(e)} variant="link">
                    {t('signUp.alreadyHaveAnAccount')}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default Step1;
