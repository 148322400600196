import { Lock } from "lucide-react";
import { useTranslation } from "react-i18next";

const ProtectedPaymentMessage = () => {
  const { t } = useTranslation();
    return(
        <div className="flex flex-row my-6">
        <div className="w-7">
          <div className="bg-primary rounded-full size-5 flex justify-center items-center">
            <Lock className="font-bold" color="white" size={10} strokeWidth="3" />
          </div>
        </div>
        <div>
          <p className="font-normal text-xs text-start">
            {t('signUp.weProtectYourPayment')}
          </p>
        </div>
      </div>
    )
}

export default ProtectedPaymentMessage;