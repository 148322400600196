import React from 'react';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { useTranslation } from 'react-i18next';

interface DialogAuditProps {
  isOpen: boolean;
  onClose: () => void;
  locationId: string;
  audits: any[];
  columns: any[];
}

const DialogAudit: React.FC<DialogAuditProps> = ({ isOpen, onClose, audits, columns }) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t('medications.audit.auditLogTitle')}
      description={t('medications.audit.auditLogDescription')}
      buttonLabel={t('medications.audit.confirmButtonLabel')}
      className="md:max-w-[725px]"
    >
      <TableCustomVirtuoso
        data={audits}
        columns={columns}
        additionalButtons={null}
        filters={null}
        renderCellContent={(index, column, data) => (
          <React.Fragment key={column.key}>
            {column.render ? column.render(data[index]) : data[index][column.key]}
          </React.Fragment>
        )}
        onSort={(columnKey, direction) => {}}
        rowClickPath={() => ''}
        showSearchInput={false}
      />
    </CustomDialog>
  );
};

export default DialogAudit;
