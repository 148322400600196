import { CircleFadingPlus } from 'lucide-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { Button } from '@/common/presentation/components/ui/button';
import { AppDispatch, RootState } from '@/store/store';

import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import * as residentsActions from '@/modules/residents/presentation/slices/residentsSlice';

import { useNewResident } from '@/modules/residents/infrastructure/hooks/use-new-resident';
import { columns } from '@/modules/residents/presentation/components/columns';

export const ResidentsPage = () => {
  const { onOpen } = useNewResident();
  const dispatch = useDispatch<AppDispatch>();
  const { params, setParam } = useRouteParams();

  const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);

  useEffect(() => {
    dispatch(residentActions.resetResident());

    const location = params.l === 'all-locations' ? undefined : params.l;
    if (location || !params.l) {
      dispatch(residentsActions.getResidentsByLocation(location));
    }

    if (!params.l) {
      setParam('l', 'all-locations');
    }
  }, [dispatch, params.l]);

  return (
    <>
      <LocationSelector locationId={params.l} />
      <div>
        <div className="Table">
          {status === 'loading' && <div>Loading...</div>}
          {status === 'failed' && <div>Failed to load residents</div>}
          {status === 'idle' && (
            <>
              <SingleTable
                data={residents}
                columns={columns}
                onDelete={() => {}}
                disable={false}
                filterKey={'first_name'}
                aditionalActions={
                  <Button onClick={onOpen} className="flex ml-auto w-auto gap-2 bg-primary">
                    <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                    Create Resident
                  </Button>
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
