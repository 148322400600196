import { Label } from '@/common/presentation/components/ui/label';

export const InformationItem = ({ label, value }: { label: string; value: any }) => {
  return (
    <div className="col-span-1 md:col-span-1">
      <Label htmlFor="name" className="text-sm font-semibold mb-2">
        {label}:<span className="font-normal ml-1">{value}</span>
      </Label>
    </div>
  );
};
