import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

export const useDateHelpers = () => {
    const { t } = useTranslation();

    const formatDate = (dateTime: string, hours: boolean = true): string => {
    const formatString = hours ? t('dateFormats.dateTimeFormat') : t('dateFormats.dateFormat');    
    return format(new Date(dateTime), formatString);    };

    return { formatDate, t };
};
