import { Button } from "@/common/presentation/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function AssessmentFacilities() {
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const [locations, setLocations] = useState<{ id: string, name: string}[]>([]);

    useEffect(() => {
        setLocations(
            [
                {
                    id: "3d23ea1e-7196-4cfe-b724-56837c37a41b",
                    name: "Demo - Caring Homes RCFE"
                },
                {
                    id: "8e89d0a3-90f2-4cba-a4fb-4ccf3b577ecc",
                    name: "Demo - Caring Homes - ARF"
                },
                {
                    id: "b78754d4-e278-4f1d-bc11-dc2d2108468e",
                    name: "Gardens - Caring Homes AZ"
                }
            ]
        );
    }, []);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const selectFacility = (facility: { id: string, name: string}) => {
        console.log(facility);
    }

    return (
        <div>
            <Card className="m-5 border-t-4 border-t-primary/80" style={{ width: "60rem", justifySelf: "center", marginTop: "3rem" }}>
                <CardHeader className="mb-5">
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        Please Select a Facility
                    </CardTitle>
                </CardHeader>

                <CardContent>
                        <div className="flex justify-center">
                            {
                                locations.map((item) => 
                                    <Button className="mr-2" key={item.id} onClick={() => selectFacility(item)}>{item.name}</Button>
                                )
                            }
                        </div>
                </CardContent>
            </Card>
        </div>
    );
}
