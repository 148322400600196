import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { NavigationBar } from '@/common/presentation/components/navigations/NavigationBar';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { ArrowLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { cn } from '@/lib/utils';
import FormCentralizedMedicationRecord from './FormCentralizedMedicationRecord/FormCentralizedMedicationRecord';
import ReportCentralizedMedicationRecord from './ReportCentralizedMedicationRecord/ReportCentralizedMedicationRecord';
import { useTranslation } from 'react-i18next';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import * as locationActions from '@/modules/locations/presentation/slices/locationSlice';
import { Button } from '@/common/presentation/components/ui/button';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';

const CentralizedMedicationRecordPage = () => {
  const navigate = useNavigate();
  const { params, setParam } = useRouteParams();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTab, setSelectedTab] = useState<string>('form');
  const { location, status: locationStatus } = useSelector((state: RootState) => state.locations.location);
  const locationPage = useLocation();
  const { t } = useTranslation();

  const handleBackClick = () => {
    navigate('/medications');
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (params.l && params.l !== 'all-locations') {
        try {
          dispatch(locationActions.getLocation(params.l)).unwrap();
        } catch (error) {
          return;
        }
      }

      dispatch(residentActions.resetResident());
      dispatch(locationActions.removeLocation());
    };

    fetchInitialData();
  }, [params.l, params.r, dispatch, navigate]);

  const onSelectDestruction = (e: string) => {
    setParam('t', e);
    setSelectedTab(e);
  };

  const handleGoBack = () => {
    if (locationPage.key === 'default') {
      navigate('/medications');
    } else {
      navigate(-1);
    }
  };

  return locationStatus === 'loading' ? (
    <SplashScreen />
  ) : (
    <div className="gap-4  my-5">
      <div className="flex items-center justify-between space-x-4 mb-4 my-5">
        <div className="flex items-center space-x-4">
          <Button variant="ghost" onClick={handleGoBack}>
            <ArrowLeft className="mr-2 h-4 w-4" />
            {t('medications.back')}
          </Button>
          <div className="font-semibold text-2xl">
            {'Centralized Medication Record'}{' '}
            <span className="font-bold">{params.l === 'all-locations' ? 'All Locations' : location?.name}</span>
          </div>
        </div>
        <Tabs
          defaultValue={selectedTab}
          onValueChange={(e) => {
            onSelectDestruction(e);
          }}
          className="ml-auto"
        >
          <TabsList>
            <TabsTrigger value="form" className="data-[state=active]:bg-primary data-[state=active]:text-white">
              Form
            </TabsTrigger>
            <TabsTrigger value="report" className="data-[state=active]:bg-primary data-[state=active]:text-white">
              Report
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="flex flex-col gap-4 my-4">
        <ResidentSelector
          onSelect={(residentId) => residentId === 'all-residents' && dispatch(residentActions.resetResident())}
          locationId={'all-locations'}
          residentId={params.r}
        />
        {selectedTab === 'form' ? <FormCentralizedMedicationRecord /> : <ReportCentralizedMedicationRecord />}
      </div>
    </div>
  );
};

export default CentralizedMedicationRecordPage;
