import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMedicationsByResidentId, updateMassiveResidentMedications } from '../../../slices/medicationsSlice';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { cn } from '@/lib/utils';
import { RootState } from '@/store/store';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { format, isValid, parseISO } from 'date-fns';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import useColumnsCentralizedMedications from './columnsCentralizedMedications';
import { toast } from '@/components/ui/use-toast';

const FormCentralizedMedicationRecord = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultResidentId = searchParams.get('r');
  const [residentId, setResidentId] = useState(defaultResidentId || '');
  const { medicationsByResidentId } = useSelector((state: RootState) => state.medications);
  const [pageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const { params } = useRouteParams();
  const [filters, setFilters] = useState<{ filter: string }>({ filter: '' });
  const columns = useColumnsCentralizedMedications();
  const [data, setData] = useState([]);
  const [changedData, setChangedData] = useState({});
  const [editedData, setEditedData] = useState({});

  // Crear una fila vacía para la tabla
  const createEmptyRow = () => {
    return columns.reduce(
      (acc, column) => {
        acc[column.key] = '';
        return acc;
      },
      { id: `new-${Date.now()}` }
    );
  };

  useEffect(() => {
    dispatch(
      getAllMedicationsByResidentId({
        residentId: params.r || 'all-residents',
        filter: filters.filter,
        page: currentPage,
        limit: pageSize
      })
    )
      .unwrap()
      .then((action) => {
        if (action.data) {
          const transformedData = action.data.map((medication) => {
            const transformedChart = medication.chart.map((chartItem) => ({
              rx: chartItem.rx,
              status: chartItem.status,
              refills: chartItem.refills,
              fillDate: chartItem.fillDate,
              qtyReceived: chartItem.qtyReceived,
              cycleEndDate: chartItem.cycle_end_date,
              expirationDate: chartItem.expiration_date,
              cycleStartDate: chartItem.cycle_start_date
            }));

            return {
              ...medication,
              chart: transformedChart
            };
          });

          setData([createEmptyRow(), ...transformedData]);
        }
      })
      .catch((error) => {
        console.error('Error al obtener medicaciones:', error);
      });
  }, [residentId, filters.filter, currentPage, dispatch, params.r]);

  useEffect(() => {
    setEditedData({});
  }, [residentId]);

  const handleCellChange = useCallback(
    (index, columnKey, value, medicationName, medicationId) => {
      const formattedValue =
        columnKey === 'calendar' && isValid(new Date(value)) ? new Date(value).toISOString() : value;

      setData((prevData) => {
        const newData = [...prevData];
        // Asegurarse de que solo la fila correspondiente se actualice
        newData[index] = {
          ...newData[index],
          [columnKey]: formattedValue
        };
        return newData;
      });

      setChangedData((prevChangedData) => ({
        ...prevChangedData,
        [index]: {
          ...prevChangedData[index],
          [columnKey]: formattedValue
        }
      }));

      setEditedData((prevEditedData) => ({
        ...prevEditedData,
        [index]: {
          id: medicationId,
          [columnKey]: formattedValue,
          medicationName,
          medicationId
        }
      }));
    },
    [data]
  );

  // Función para manejar el valor basado en el tipo de columna
  const handleValueByType = (columnKey, value) => {
    const column = columns.find((col) => col.key === columnKey);

    if (!column) {
      return value;
    }

    switch (column.type) {
      case 'calendar':
        return isValid(new Date(value)) ? new Date(value) : null;
      case 'number':
        return value;
      case 'text':
        return value;
      default:
        return value;
    }
  };

  const getColumnData = useCallback(
    (index, columnKey) => {
      const value = data[index] ? data[index][columnKey] : '';
      return columnKey === 'calendar' && value && isValid(parseISO(value)) ? parseISO(value) : value || '';
    },
    [data]
  );

  const handleRowClick = (index) => {
    if (index && Object.keys(index).length > 0 && data && data.length > 0) {
      const firstRowData = { ...data[0] };

      delete firstRowData.id;

      const filteredDataToCopy = Object.keys(firstRowData).reduce((acc, key) => {
        if (firstRowData[key] && key !== 'calendar') {
          acc[key] = firstRowData[key];
        }
        return acc;
      }, {});

      setData((prevData) => {
        const newData = prevData.map((row, i) => {
          if (row.id === index.id && i !== 0) {
            // No afecta la primera fila, solo las otras
            return {
              ...row,
              ...filteredDataToCopy, // Copia solo una vez los datos de la primera fila
              // Asegura que la fecha pueda ser editada después
              cycleStartDate: row.cycleStartDate // Mantén los valores únicos como la fecha
            };
          }
          return row;
        });
        return newData;
      });
    }
  };

  const submitChart = () => {
    const formattedData = data
      .filter((item) => !item.id.startsWith('new-'))
      .map((item) => {
        return {
          id: item.id,
          medication_name: item.medication_name || '',
          rx: item.rx || '',
          filleDate: item.filleDate,
          qtyReceived: item.qtyReceived || '',
          cycle_start_date: item.cycle_start_date,
          cycle_end_date: item.cycle_end_date,
          expiration_date: item.expiration_date,
          refills: item.refills || 0
        };
      });

    dispatch(updateMassiveResidentMedications(formattedData))
      .unwrap()
      .then((response) => {
        toast({
          description: 'Medicamentos actualizados correctamente.'
        });
      })
      .catch((error) => {
        console.error('Failed:', error);
        toast({
          description: 'Error al actualizar los medicamentos',
          variant: 'destructive'
        });
      });
  };

  const additionalButtons = (
    <>
      <Button onClick={submitChart} size="sm">
        <span className="text-xs">{'save'}</span>
      </Button>
    </>
  );

  return (
    <div className="flex flex-col gap-4 my-4">
      <TableCustomVirtoso
        data={data}
        columns={columns}
        additionalButtons={additionalButtons}
        disableAllFilters={true}
        renderCellContent={(index, column) => (
          <td key={column.key}>
            {column.editable ? (
              column.type === 'calendar' ? (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={'outline'}
                      className={cn(!getColumnData(index, column.key) && 'text-muted-foreground')}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {getColumnData(index, column.key) ? (
                        format(new Date(getColumnData(index, column.key)), 'MM/dd/yyyy')
                      ) : (
                        <span>Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <CustomCalendar
                      onChange={(selectedDate) => handleCellChange(index, column.key, selectedDate, '', data[index].id)}
                      value={getColumnData(index, column.key) ? new Date(getColumnData(index, column.key)) : new Date()}
                      name={`${column.key}-${index}`}
                      MonthAndYearPicker
                    />
                  </PopoverContent>
                </Popover>
              ) : (
                <Input
                  type={column.type === 'number' ? 'number' : 'text'}
                  value={getColumnData(index, column.key)}
                  onChange={(e) => handleCellChange(index, column.key, e.target.value, '', data[index].id)}
                  style={{ width: '100px' }}
                />
              )
            ) : (
              getColumnData(index, column.key)
            )}
          </td>
        )}
        rowClickPath={handleRowClick}
      />
    </div>
  );
};

export default FormCentralizedMedicationRecord;
