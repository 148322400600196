import i18n from 'i18next';
import i18nBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import dateFormats from './utils/dateFormats';

import esTranslations from './locales/es.json';
import enTranslations from './locales/en.json';
import tlTranslations from './locales/tl.json';


i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'es', 'tl'],
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    },
    resources: {
      es: {
        translation: {
          ...esTranslations,
          dateFormats: dateFormats.es
        }
      },
      en: {
        translation: {
          ...enTranslations,
          dateFormats: dateFormats.en
        }
      },
      tl: {
        translation: {
          ...tlTranslations,
          dateFormats: dateFormats.tl
        }
      }
    }
  });

export default i18n;
