import { Button } from '@/common/presentation/components/ui/button';
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"
import { cn } from "@/lib/utils";
import {
    Command,
    CommandEmpty,
    CommandList,
    CommandInput,
    CommandItem,
} from "@/common/presentation/components/ui/command.tsx";
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover.tsx';
import { ICategoriesColorFilter, ICategory } from "../../domain/calendar.domain.ts";
import { useTranslation } from 'react-i18next';


export const CategoriesColorFilter = ({
    open,
    openChange,
    categories,
    value,
    onChange
}: ICategoriesColorFilter) => {
    const { t, i18n  } = useTranslation();

    const newCategories = [...categories];

    if (newCategories.length > 0) {
        newCategories.unshift({
            id: "all-colors",
            name: t('calendar.all'),
            color: "#fefefe"
        });
    }

    return (
        <Popover open={open} onOpenChange={openChange}>
            <PopoverTrigger asChild>
                <div className='w-full md:w-auto'>
                    <Button
                        variant="outline"
                        role="combobox"
                        style={{
                            backgroundColor: (newCategories.find((c: ICategory) => c.id === value))?.color,
                            color: (value === '' ||
                                (newCategories.find((c: ICategory) => c.id === value))
                                    ?.id === 'all-colors') ? 'black' : 'white'
                        }}
                        className={`mb-5 w-full md:w-1/2 ${cn("md:w-[260px] justify-between", "text-muted-foreground")}`}
                    >
                        {
                            value
                                ? (newCategories.find((c: ICategory) => c.id === value))?.name
                                : t('calendar.filter')
                        }
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0 mt-[-22px]">

                <Command>
                    <CommandInput placeholder="Select category color" />
                    <CommandEmpty>{t('calendar.noOptionFound')}.</CommandEmpty>

                    <CommandList>
                        {newCategories.map((category: ICategory) => (
                            <CommandItem
                                key={category.id}
                                value={category.id}
                                onSelect={(id: string) => {
                                    onChange(id);
                                    openChange(false);
                                }}
                            >
                                    {
                                    (i18n.exists('calendar.listCategories.' + category.name.toLocaleLowerCase().replace(/\s+/g, ''))) 
                                      ? t('calendar.listCategories.' + category.name.toLocaleLowerCase().replace(/\s+/g, '')) 
                                      : category.name
                                      }
                                <CheckIcon
                                    style={{
                                        color: value == category.id ? 'white' : category.color,
                                        backgroundColor: category.color
                                    }}
                                    className={cn(
                                        "ml-auto h-4 w-4",
                                        value == category.id ? "opacity-100" : "opacity-100"
                                    )}
                                />
                            </CommandItem>
                        ))}
                    </CommandList>

                </Command>
            </PopoverContent>
        </Popover >
    );
}