import { useNavigate, useParams } from 'react-router-dom';
import PaymentRetry from '../components/PaymentRetry';
import PaymentManage from '../components/PaymentsManage';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SubscriptionPaymentPage = () => {
  const params = useParams();
  const subscription_id = params.subscription_id ? params.subscription_id : '';
  const userInfo = useSelector((state: any) => state.auth.user);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const isExecutive = userInfo.roles.find((role: {name: string, is: string}) => ['Executive', 'Super Administrator'].includes(role.name));
    if (!isExecutive) {
      navigate('/dashboard', { replace: true });
    }
  }, []);

  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex flex-col gap-4 py-4">
        <main className="flex flex-col gap-4">
          <div>
            <h2 className="mb-2 font-bold text-3xl">{t('subscription.subscriptionPayment')}</h2>
          </div>
          <div className="flex flex-col gap-3">
            <PaymentRetry subscriptionId={subscription_id} />
            <div>
              <PaymentManage />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SubscriptionPaymentPage;
