import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';

const CalendarSkeleton = () => {
  return (
    <>
      <div className="col-span-6 2lg:col-span-3 flex justify-end">
        <ScrollArea className="flex-grow overflow-auto w-full 2lg:w-11/12">
          {Array.from({ length: 2 }).map((_, index) => (
            <div key={index} className="m-2 w-full">
              <div className="flex flex-col bg-primary/10 p-3 rounded-lg border-l-8 border-primary hover:bg-primary/20 cursor-pointer transition-all">
                <span className="text-primary font-bold ml-1">
                  <Skeleton className="h-4 w-3/5 bg-black/10" />
                </span>
                <span className="ml-1 font-semibold pt-2">
                  <Skeleton className="h-4 w-24 mb-2 bg-black/10" />
                </span>
              </div>
            </div>
          ))}
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </div>
    </>
  );
};

export default CalendarSkeleton;
