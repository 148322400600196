import { CircleAlert, NotebookPen } from 'lucide-react';
import { AlertCard } from '../alert-card';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetNotes, setNoteFilters } from '@/modules/notes/presentation/slices/NoteSlice';
import { AppDispatch, RootState } from '@/store/store';
import { NoteI } from '@/modules/notes/domain/note.domain';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import NotesModal from './notes-modal';
import SkeletonNotes from './skeleton';
import { UserHelper } from '@/utils/helpers/user.helper';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

interface ResidentI {
  id: string;
  first_name: string;
  last_name: string;
}

const NoteLayout = ({
  children,
  handlerNoteSelection,
  note
}: {
  children: React.ReactNode;
  handlerNoteSelection?: (note: NoteI) => void | null;
  note: NoteI | undefined;
}) => (
  <div className="p-2">
    <div
      className="transition-all hover:scale-[1.01] cursor-pointer rounded-lg border p-4"
      onClick={() => {
        if (handlerNoteSelection && note) {
          handlerNoteSelection(note);
        }
      }}
    >
      <div className="grid">{children}</div>
    </div>
  </div>
);

interface NoteIProps {
  isLoading: boolean;
  note?: NoteI;
  residents: ResidentI[];
  handlerNoteSelection?: (note: NoteI | undefined) => void | null;
}

const Note: React.FC<NoteIProps> = ({ isLoading, note, residents, handlerNoteSelection }) => {
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();
  const residentNameById = (residentId: string | null): string | null => {
    if (residentId === null) return t('dashboard.everyone');
    return UserHelper.getResidentName(residentId, residents);
  };

  return (
    <NoteLayout note={note} handlerNoteSelection={handlerNoteSelection}>
      <div className="col-span-10 flex flex-col text-primary font-semibold w-full">
        {isLoading ? (
          <>
            <Skeleton className="h-4 w-24 mb-2" />
            <Skeleton className="h-4 w-36 mb-2" />
          </>
        ) : (
          <>
            <span className="w-full flex flex-row gap-2 items-center">
              {residentNameById(note?.resident_id ?? null)} {note?.urgent == true && <CircleAlert size={18} />}
            </span>
            <span className="text-sm w-full">{formatDate(note?.created_at ?? '')}</span>
          </>
        )}
      </div>
      <div className="col-span-10 flex flex-col">
        {isLoading ? <Skeleton className="h-4 w-full" /> : <span>{note?.content}</span>}
      </div>
    </NoteLayout>
  );
};

interface INoteCardProps {
  location?: string | null;
}

export const NotesCard: React.FC<INoteCardProps> = ({ location }) => {
  const dispatch = useDispatch<AppDispatch>();
  const notes = useSelector((state: RootState) => state.notes.notes.payload) ?? [];
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents) ?? [];
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [noteSelected, setNoteSelected] = useState<NoteI | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    getNotes();
  }, [location]);

  useEffect(() => {
    if (notes.length > 0 && residents.length > 0) {
      setIsLoading(false);
    }
  }, [notes, residents]);

  const getNotes = async () => {
    setIsLoading(true);
    let locationFilter = 'all-locations';
    if (location !== undefined && location && location?.length > 0) {
      locationFilter = location;
    }
    await dispatch(setNoteFilters(['location', locationFilter]));
    await dispatch(setNoteFilters(['urgent', false]));
    await dispatch(fetchGetNotes(1));
    setIsLoading(false);
  };

  const handlerNoteSelection = (note: NoteI | undefined) => {
    if (note) {
      setNoteSelected(note);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <AlertCard title={t('dashboard.notes')} alerts={notes ?? []} icon={NotebookPen} custom>
        {isLoading ? (
          <SkeletonNotes />
        ) : notes.length == 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          notes.map((note: NoteI) => (
            <Note
              key={note.id}
              isLoading={false}
              note={note}
              residents={residents}
              handlerNoteSelection={handlerNoteSelection}
            />
          ))
        )}
      </AlertCard>
      <NotesModal isOpen={isModalOpen} note={noteSelected} setIsOpen={setIsModalOpen} />
    </>
  );
};
