import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Separator } from '@/common/presentation/components/ui/separator';
import { cn } from '@/lib/utils';
import * as residentFormActions from '@/modules/residents/presentation/slices/residentsForm';
import * as residentsActions from '@/modules/residents/presentation/slices/residentsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

import { toast } from '@/common/presentation/components/ui/use-toast';
import { useNewResident } from '../../infrastructure/hooks/use-new-resident';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';

const formSchema = z.object({
  first_name: z
    .string({
      required_error: 'First name is required.'
    })
    .min(3, {
      message: 'First name must be at least 3 characters.'
    })
    .max(30, {
      message: 'First name must not be longer than 30 characters.'
    }),
  middle_name: z.string().optional(),
  last_name: z
    .string({
      required_error: 'Last name is required.'
    })
    .min(3, {
      message: 'Last name must be at least 3 characters.'
    })
    .max(30, {
      message: 'Last name must not be longer than 30 characters.'
    }),
  dob: z.date({
    required_error: 'Date of birth is required.'
  }),
  sex: z.string({
    required_error: 'Sex is required.'
  }),
  spoken_languages: z.string().optional(),
  responsible_for_signing: z.enum(['responsible_party', 'resident'], {
    required_error: 'You need to select a responsible for signing.'
  }),
  phone: z.string().optional(),
  email: z.string().email().optional().or(z.literal('')),
  admission_date: z.date({
    required_error: 'Admission date is required'
  }),
  original_move_in_date: z.date({
    required_error: 'Original move in date is required'
  }),
  current_resident_house: z.string({
    required_error: 'Current resident house is required'
  })
});

type FormValues = z.infer<typeof formSchema>;

const defaultValues: Partial<FormValues> = {
  first_name: '',
  middle_name: '',
  last_name: '',
  dob: undefined,
  sex: undefined,
  spoken_languages: '',
  responsible_for_signing: 'resident',
  phone: '',
  email: '',
  admission_date: undefined,
  original_move_in_date: undefined,
  current_resident_house: ''
};

export const NewResidentForm = () => {
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  const { status } = useSelector((state: RootState) => state.residents.residentForm);

  const dispatch = useDispatch<AppDispatch>();
  const { onClose } = useNewResident();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues
  });

  const [age, setAge] = useState(0);

  const calculateAge = (dob: Date) => {
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const handleSubmit = async (values: FormValues) => {
    const response = await dispatch(residentFormActions.createResidentForm(values));

    if (response.payload) {
      dispatch(residentsActions.getResidentsByLocation());
      form.reset(defaultValues);
      onClose();
      toast({
        title: 'Resident Created'
      });
    }
  };

  useEffect(() => {
    if (form.watch('dob')) {
      setAge(calculateAge(form.watch('dob')));
    }
  }, [form.watch('dob')]);

  return (
    <>
      <ScrollArea className="h-full w-full m-0 p-0">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 px-1">
            <Separator className="col-span-full" />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <Label className="col-span-full text-lg">Personal Information</Label>
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      First Name <span className="text-destructive">(*)</span>
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="middle_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Middle Name</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Last Name <span className="text-destructive">(*)</span>
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="grid grid-cols-12 gap-2 items-end">
                <FormField
                  control={form.control}
                  name="dob"
                  render={({ field }) => (
                    <FormItem className="col-span-9">
                      <FormLabel>
                        Date of Birth <span className="text-destructive">(*)</span>
                      </FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              className={cn(
                                'w-full pl-3 text-left font-normal',
                                !field.value && 'text-muted-foreground'
                              )}
                              disabled={status === 'loading'}
                            >
                              {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date of birth</span>}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <div className="rounded-md border">
                            <CustomCalendar
                              onChange={(date) => {
                                field.onChange(date);
                                setAge(calculateAge(date!));
                              }}
                              value={field.value}
                              MonthAndYearPicker
                            />
                          </div>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="col-span-3 flex flex-col gap-3">
                  <Label>Age</Label>
                  <Button variant={'outline'} className="w-full" disabled>
                    {age}
                  </Button>
                </div>
              </div>

              <FormField
                control={form.control}
                name="sex"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Sex <span className="text-destructive">(*)</span>
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}
                      defaultValue={field.value}
                      disabled={status === 'loading'}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a sex" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="male">Male</SelectItem>
                        <SelectItem value="female">Female</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="spoken_languages"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Language</FormLabel>
                    <FormControl>
                      <Input {...field} autoComplete="off" disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <Separator className="col-span-full" />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <Label className="col-span-full text-lg">Resident Location</Label>
              <FormField
                control={form.control}
                name="admission_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Admission Date / Current House <span className="text-destructive">(*)</span>
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                            disabled={status === 'loading'}
                          >
                            {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date</span>}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <div className="rounded-md border">
                          <CustomCalendar onChange={field.onChange} value={field.value} MonthAndYearPicker />
                        </div>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="original_move_in_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Original Move In Date <span className="text-destructive">(*)</span>
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                            disabled={status === 'loading'}
                          >
                            {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date</span>}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <div className="rounded-md border">
                          <CustomCalendar onChange={field.onChange} value={field.value} MonthAndYearPicker />
                        </div>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="current_resident_house"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Current Resident House <span className="text-destructive">(*)</span>
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}
                      defaultValue={field.value}
                      disabled={status === 'loading'}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a resident house" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {locations.map((location: any) => (
                          <SelectItem value={location.id} key={location.id}>
                            {location.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <Separator className="col-span-full" />

            <div className="w-full col-span-1 lg:col-span-3">
              <Label className="col-span-full text-lg">Who is responsible for signing legal documents</Label>

              <div className="grid grid-cols-3 gap-4">
                <FormField
                  control={form.control}
                  name="responsible_for_signing"
                  render={({ field }) => (
                    <FormItem className="my-4 col-span-3 lg:col-span-1">
                      <FormControl>
                        <RadioGroup
                          disabled={status === 'loading'}
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          value={field.value}
                          className="flex flex-col space-y-1"
                        >
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="responsible_party" />
                            </FormControl>
                            <FormLabel className="font-normal">Responsible Party</FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="resident" />
                            </FormControl>
                            <FormLabel className="font-normal">Resident</FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="col-span-3 lg:col-span-1">
                      <FormLabel>Phone</FormLabel>
                      <FormControl>
                        <PhoneInput
                          {...field}
                          value={field.value as any}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          disabled={status === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="col-span-3 lg:col-span-1">
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} type="email" disabled={status === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <Button type="submit" className="col-span-full w-64" disabled={status === 'loading'}>
              {status === 'loading' && <Loader className="size-4 animate-spin mr-2" />}
              Save Resident
            </Button>
          </form>
        </Form>
      </ScrollArea>
    </>
  );
};
