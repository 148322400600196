import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DepartmentsStructureI, InitialStateI } from '../../domain/department.domin';
import DepartmentService from '../../infrastructure/services/DepartmentService';

const initialDepartment: DepartmentsStructureI = {
  id: '',
  department: '',
  client_id: '',
  created_at: null,
  updated_at: null,
  department_role: [],
  department_user: []
};

const initialState: InitialStateI = {
  departments: [initialDepartment]
};

export const fetchGetDepartments = createAsyncThunk(
    'departments/get',
    async () => {
      const response = await DepartmentService.getDepartments();
      return response;
    }
  );

const DepartmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetDepartments.fulfilled, (state, action) => {
        state.departments = action.payload.payload;
      });
  }
});

export const {} = DepartmentSlice.actions;
export default DepartmentSlice.reducer;

