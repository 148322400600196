import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
 } from '@/common/presentation/components/ui/dialog';
 import { Button } from '@/common/presentation/components/ui/button';
import { Trash } from 'lucide-react';
import { useDispatch } from 'react-redux';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { deleteTask, getTasks } from '../../slices/TrackerCleaningSlice';
import { useTranslation } from 'react-i18next';

export function TrackerCleaningTaskDeleteDialog(props: { id: string }) {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { t } = useTranslation();

    const deleteElement = async () => {
        await dispatch(deleteTask(
            {
                id: props.id
            }
        ));

        fetchData();
    };

    const fetchData = async () => {
        await dispatch(getTasks(
            {
                location_id: params.l
            }
        ));
    }

    return (
        <div>
            <Dialog>
                <DialogTrigger asChild>
                    <Button variant="outline" size="icon" className='mr-2'>
                        <Trash className="h-4 w-4" />
                    </Button>
                </DialogTrigger>

                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>
                        {t("cleaning.create.task.delete.title")}
                        </DialogTitle>
                    </DialogHeader>

                <div className="grid gap-4 py-4">
                    {t("cleaning.create.task.delete.description")}
                </div>

                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="submit" onClick={deleteElement}>
                            {t("cleaning.create.task.delete.form.submit")}
                        </Button>
                    </DialogClose>
                    
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            {t("cleaning.create.task.delete.form.cancel")}
                        </Button>
                    </DialogClose>
                </DialogFooter>
        </DialogContent>
        </Dialog>
        </div>
    );
};
