import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { cn } from '@/lib/utils';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { format, parse, isValid } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Badge } from '@/common/presentation/components/ui/badge';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

const columns = [
  {
    key: 'status',
    labelTranslationKey: 'medications.columnsChartMedications.status',
    value: 'status',
    type: 'checkbox',
    sortable: false,
    visible: true,
    static: true,
    width: '10%'
  },
  {
    key: 'rx',
    labelTranslationKey: 'medications.columnsChartMedications.rx',
    value: 'rx',
    type: 'text',
    visible: true,
    sortable: true,
    width: '15%'
  },
  {
    key: 'filleDate',
    labelTranslationKey: 'medications.columnsChartMedications.filleDate',
    value: 'filleDate',
    type: 'calendar',
    sortable: true,
    visible: false,
    width: '5%'
  },
  {
    key: 'qtyReceived',
    labelTranslationKey: 'medications.columnsChartMedications.qtyReceived',
    value: 'qtyReceived',
    type: 'number',
    visible: true,
    sortable: true,
    width: '5%'
  },
  {
    key: 'cycle_start_date',
    labelTranslationKey: 'medications.columnsChartMedications.cycle_start_date',
    value: 'cycle_start_date',
    type: 'calendar',
    visible: true,
    sortable: true,
    width: '10%'
  },
  {
    key: 'cycle_end_date',
    labelTranslationKey: 'medications.columnsChartMedications.cycle_end_date',
    value: 'cycle_end_date',
    type: 'calendar',
    visible: true,
    sortable: true,
    width: '10%'
  },
  {
    key: 'expiration_date',
    labelTranslationKey: 'medications.columnsChartMedications.expiration_date',
    value: 'expiration_date',
    type: 'calendar',
    sortable: true,
    visible: false,
    width: '10%'
  },
  {
    key: 'refills',
    labelTranslationKey: 'medications.columnsChartMedications.refills',
    value: 'refills',
    type: 'number',
    visible: true,
    sortable: true,
    width: '5%'
  }
];

const getDefaultRow = () => {
  const defaultRow = {};
  columns.forEach((column) => {
    switch (column.type) {
      case 'checkbox':
        defaultRow[column.value] = false;
        break;
      case 'calendar':
        defaultRow[column.value] = '';
        break;
      case 'number':
        defaultRow[column.value] = 0;
        break;
      default:
        defaultRow[column.value] = '';
    }
  });
  return defaultRow;
};

const ChartRX = ({ control, fields, append }) => {
  const { setValue, getValues } = useForm();
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    return isValid(parsedDate) ? format(parsedDate, 'dd/MM/yyyy') : '';
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
    return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : null;
  };

  useEffect(() => {
    if (fields && fields.length > 0) {
      const processedData = fields.map((item) => ({
        ...item,
        filleDate: formatDate(item.filleDate),
        cycle_start_date: formatDate(item.cycle_start_date),
        cycle_end_date: formatDate(item.cycle_end_date),
        expiration_date: formatDate(item.expiration_date)
      }));
      setTableData(processedData);
      setValue('chart', processedData);
    } else {
      const defaultData = [getDefaultRow()];
      setTableData(defaultData);
      setValue('chart', defaultData);
    }
  }, [fields, setValue]);

  const addRow = useCallback(() => {
    const newRow = getDefaultRow();
    setTableData((prevTableData) => [newRow, ...prevTableData]);
    setValue('chart', (prevChart) => [newRow, ...prevChart]);
  }, [setValue]);

  const handleCellChange = useCallback(
    (index, columnKey, value) => {
      setTableData((prevData) => {
        const updatedData = [...prevData];
        if (['filleDate', 'cycle_start_date', 'cycle_end_date', 'expiration_date'].includes(columnKey)) {
          updatedData[index] = { ...updatedData[index], [columnKey]: parseDate(value) };
        } else {
          updatedData[index] = { ...updatedData[index], [columnKey]: value };
        }
        return updatedData;
      });

      setValue(`chart.${index}.${columnKey}`, value);
    },
    [setValue]
  );
  const renderCellContent = (index, column, data) => {
    return (
      <td key={column.value} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
        <Controller
          name={`chart.${index}.${column.value}`}
          control={control}
          render={({ field }) => {
            const value = field.value;
            if (column.type === 'checkbox') {
              return (
                <Badge
                  variant="outline"
                  className={cn(
                    'text-md font-bold',
                    value ? 'border-green-500 text-green-500' : 'border-gray-500 text-gray-500'
                  )}
                >
                  {value ? 'Active' : 'Inactive'}
                </Badge>
              );
            } else if (column.type === 'calendar') {
              const formattedDate = formatDate(value);
              return (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={'outline'}
                      className={cn(
                        'w-full justify-start text-left font-normal',
                        !formattedDate && 'text-muted-foreground'
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {formattedDate || <span> MM/dd/yyyy</span>}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <CustomCalendar
                      onChange={(selectedDate) => {
                        const formattedDate = format(selectedDate, 'dd/MM/yyyy');
                        field.onChange(parseDate(formattedDate));
                        handleCellChange(index, column.value, formattedDate);
                      }}
                      value={value ? parse(value, 'yyyy-MM-dd', new Date()) : new Date()}
                      name={`${column.value}-${index}`}
                      MonthAndYearPicker
                    />
                  </PopoverContent>
                </Popover>
              );
            } else {
              return (
                <Input
                  type={column.type === 'number' ? 'number' : 'text'}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleCellChange(index, column.value, e.target.value);
                  }}
                  style={{ width: '100px' }}
                />
              );
            }
          }}
        />
      </td>
    );
  };

  const handleSort = (columnKey) => {
    const sortedData = [...tableData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) return -1;
      if (a[columnKey] > b[columnKey]) return 1;
      return 0;
    });
    setTableData(sortedData);
  };

  return (
    <TableCustomVirtoso
      columns={columns}
      data={tableData}
      renderCellContent={renderCellContent}
      onSort={handleSort}
      className="w-full"
      showSearchInput={false}
      additionalButtons={
        <Button className="bg-primary" onClick={addRow}>
          Add Row
        </Button>
      }
    >
      <div className="flex justify-between items-center p-4">
        <Button onClick={addRow} className="w-1/4">
          {t('buttons.addRow')}
        </Button>
      </div>
    </TableCustomVirtoso>
  );
};

export default ChartRX;
