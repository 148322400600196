import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StaffService from '../../infrastructure/services/StaffService';

interface StaffState {
  staff: any[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: StaffState = {
  staff: [],
  status: 'idle',
  error: null
};

export const getStaffByLocation = createAsyncThunk('staff/getStaff', async (locationId: string | undefined) => {
  try {
    const response = await StaffService.getStaff(locationId);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching staff: ' + error);
  }
});

export const createStaffMember = createAsyncThunk('staff/createStaffMember', async (data: any) => {
  try {
    const response: any = await StaffService.createStaffMember(data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    throw new Error(error?.response?.data?.message || error.error?.message || 'An error occurred');
  }
});

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    addStaff: (state, action) => {
      state.staff.push(action.payload);
    },
    removeStaff: (state, action) => {
      state.staff = state.staff.filter((staff) => staff.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffByLocation.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getStaffByLocation.fulfilled, (state, action) => {
        state.staff = action.payload;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(getStaffByLocation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(createStaffMember.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(createStaffMember.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createStaffMember.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  }
});

export const { addStaff, removeStaff } = staffSlice.actions;
export default staffSlice.reducer;
