import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StaffService from '../../infrastructure/services/StaffService';

interface StaffMemberState {
  staffMember: any;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: StaffMemberState = {
  staffMember: null,
  status: 'idle',
  error: null
};

export const getStaffMemberById = createAsyncThunk('staff/getStaffMember', async (staffMemberId: string) => {
  try {
    const response = await StaffService.getStaffMemberById(staffMemberId);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching staff member: ' + error);
  }
});

const staffMemberSlice = createSlice({
  name: 'staffMember',
  initialState,
  reducers: {
    setStaffMember: (state, action) => {
      state.staffMember = action.payload;
    },
    resetStaffMember: (state) => {
      state.staffMember = null;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffMemberById.pending, (state) => {
        state.status = 'loading';
        state.staffMember = null;
        state.error = null;
      })
      .addCase(getStaffMemberById.fulfilled, (state, action) => {
        state.staffMember = action.payload;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(getStaffMemberById.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.staffMember = null;
      });
  }
});

export const { setStaffMember, resetStaffMember } = staffMemberSlice.actions;
export default staffMemberSlice.reducer;
