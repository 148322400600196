import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';

import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Eye, EyeOff, Loader } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  className?: string;
}

const formSchema = z.object({
  first_name: z
    .string({
      required_error: 'First name is required.'
    })
    .min(3, {
      message: 'First name must be at least 3 characters.'
    })
    .max(30, {
      message: 'First name must not be longer than 30 characters.'
    }),
  middle_name: z.string().optional(),
  last_name: z
    .string({
      required_error: 'Last name is required.'
    })
    .min(3, {
      message: 'Last name must be at least 3 characters.'
    })
    .max(30, {
      message: 'Last name must not be longer than 30 characters.'
    }),

  aka: z.string().optional(),
  dob: z.date({
    required_error: 'Date of birth is required.'
  }),

  social_security: z.string().optional(),
  alien_number: z.string({}).optional(),

  sex: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip_code: z.string().optional(),
  place_of_birth: z.string().optional(),
  telephone: z.string().optional(),
  email: z.string().optional(),
  driver_license: z.string().optional()
  // livescan_id: z.number({}).optional(),
  // hiring_date: z.date({}).optional(),
  // first_aid_due: z.date({}).optional(),
  // cpr_due: z.date({}).optional(),
});

type FormValues = z.infer<typeof formSchema>;

const formatSocialNumber = (value: string | undefined) => {
  if (value !== undefined && value !== null && value.length > 0) {
    const valueStr = value.replace(/\D/g, '');

    let formatNumber = '';

    if (valueStr.length > 5) {
      formatNumber = valueStr.slice(0, 3) + '-' + valueStr.slice(3, 5) + '-' + valueStr.slice(5, 9);
    } else if (valueStr.length > 3) {
      formatNumber = valueStr.slice(0, 3) + '-' + valueStr.slice(3, 5);
    } else if (valueStr.length > 0) {
      formatNumber = valueStr;
    }

    return formatNumber;
  }

  return '';
};

export const StaffPrincipalInformationForm = ({ className }: Props) => {
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMemberForm);
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});

  const { user } = useSelector((state: RootState) => state.auth);
  const canView = useMemo(() => {
    if (user) {
      return user.roles.find((role) => role.name === 'Super Administrator') || user?.id === staffMember?.id;
    }

    return false;
  }, [user]);

  const [socialSecurityVisible, setSocialSecurityVisible] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const [age, setAge] = useState(0);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues } as FormValues
  });

  const calculateAge = (dob: Date) => {
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const onSubmitForm = async (data: FormValues) => {
    const response = dispatch(
      staffMemberFormActions.updateStaffPersonalInformationForm({
        staff_member_id: staffMember?.id,
        ...data,
        social_security: data?.social_security!.split('-').join('')
      })
    );
    if ((await response).payload) {
      toast({
        description: 'Staff information updated successfully'
      });
    }
  };

  useEffect(() => {
    if (form.watch('dob')) {
      setAge(calculateAge(form.watch('dob')));
    }
  }, [form.watch('dob')]);

  useEffect(() => {
    if (staffMember) {
      setDefaultValues({
        first_name: staffMember?.first_name || '',
        middle_name: staffMember?.middle_name || '',
        last_name: staffMember?.last_name || '',
        aka: staffMember?.aka || '',
        dob: new Date(staffMember.dob) || undefined,
        social_security: formatSocialNumber(`${staffMember.social_security}`) || '',
        alien_number: staffMember.alien_number || '',
        sex: staffMember?.sex || '',
        address: staffMember?.address || '',
        city: staffMember?.city || '',
        state: staffMember?.state || '',
        zip_code: staffMember?.zip_code || '',
        place_of_birth: staffMember?.place_of_birth || '',
        telephone: staffMember?.telephone || '',
        email: staffMember?.email || '',
        driver_license: staffMember?.driver_license || ''
      });
    }
  }, [staffMember]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load resident</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Personal Information" openOption={false} defaultOpen>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)} className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <FormField
                  control={form.control}
                  name="first_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        First Name <span className="text-destructive">(*)</span>
                      </FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="middle_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Middle Name</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="last_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Last Name <span className="text-destructive">(*)</span>
                      </FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="aka"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>AKA's</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="grid grid-cols-12 gap-2 items-end ">
                  <div className="col-span-9">
                    <FormField
                      control={form.control}
                      name="dob"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Date of Birth <span className="text-destructive">(*)</span>
                          </FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={'outline'}
                                  className={cn(
                                    'w-full pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                  disabled={staffFormStatus === 'loading'}
                                >
                                  {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date </span>}
                                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <div className="rounded-md border">
                                <CustomCalendar
                                  onChange={(date) => {
                                    field.onChange(date);
                                    setAge(calculateAge(date!));
                                  }}
                                  value={field.value}
                                  // baseSelectorYear={new Date().getFullYear() - 18}
                                  MonthAndYearPicker
                                />
                              </div>
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-3 flex flex-col gap-3">
                    <Label>Age</Label>
                    <Button variant={'outline'} className="w-full" disabled>
                      {age}
                    </Button>
                  </div>
                </div>

                <FormField
                  control={form.control}
                  name="sex"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Sex</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                        defaultValue={field.value}
                        disabled={staffFormStatus === 'loading'}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a sex" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="male">Male</SelectItem>
                          <SelectItem value="female">Female</SelectItem>
                          <SelectItem value="other">Other</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="telephone"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Telephone</FormLabel>
                      <FormControl>
                        <PhoneInput
                          {...field}
                          value={field.value as any}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          disabled={staffFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} type="email" disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="address"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Address</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="city"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>City</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="state"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>State</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="zip_code"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Zip_code</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="place_of_birth"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Place_of_birth</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="social_security"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Social Security (Optional)</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input
                            {...field}
                            type={socialSecurityVisible ? 'text' : 'password'}
                            value={field.value || ''}
                            onChange={(e) => {
                              field.onChange(formatSocialNumber(e.target.value));
                            }}
                            disabled={staffFormStatus === 'loading' || !canView}
                            placeholder=""
                            autoCorrect="off"
                            autoComplete="off"
                            className="w-full rounded-lg bg-background"
                          />

                          {!!canView && (
                            <>
                              <Eye
                                className={cn(
                                  'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                  socialSecurityVisible && 'hidden'
                                )}
                                onClick={() => setSocialSecurityVisible(true)}
                              />
                              <EyeOff
                                className={cn(
                                  'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                  !socialSecurityVisible && 'hidden'
                                )}
                                onClick={() => setSocialSecurityVisible(false)}
                              />
                            </>
                          )}
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="driver_license"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel> Id number</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                  <>
                    <Separator className="col-span-full" />
                    <Button type="submit" className="w-64" disabled={staffFormStatus === 'loading'}>
                      {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
