import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { Save, Trash } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export interface DefaultValues {
  id?: string | null;
  allergy: string;
  description: string;
  is_contagious: string;
  created_at?: Date;
}

interface Props {
  defaultValues: DefaultValues;
  disabled?: boolean;
  onSubmit: (values: any) => void;
  onRemove?: (id: string | null) => void;
}

const formSchema = z.object({
  allergy: z.string(),
  description: z.string(),
  is_contagious: z.string().optional()
});

export type FormValues = z.infer<typeof formSchema>;

export const AllergiesCard = ({ defaultValues, disabled, onSubmit, onRemove }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues
  });

  const handleSubmit = (values: FormValues) => {
    onSubmit({
      ...values,
      id: defaultValues.id
    });
  };

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onRemove?.(defaultValues.id ?? null);
  };

  return (
    <>
      <div className="bg-white drop-shadow-md border rounded-md p-4 w-full">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="allergy"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Allergy</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea className="min-h-[40px] h-[40px]" {...field} value={field.value || ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="is_contagious"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Is Contagious / Infectious Disease</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                      className="flex flex-col space-y-1"
                      disabled={disabled}
                    >
                      <FormItem className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="yes" />
                        </FormControl>
                        <FormLabel className="font-normal">Yes</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="no" />
                        </FormControl>
                        <FormLabel className="font-normal">No</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="col-span-2 flex flex-col gap-4">
              <Separator />
              <div className="flex items-center justify-between gap-2">
                {defaultValues?.created_at && (
                  <p>
                    <span className="font-bold">Created at:</span>{' '}
                    {format(new Date(defaultValues?.created_at ?? ''), 'yyyy-MM-dd hh:mm a')}
                  </p>
                )}

                <div className="ml-auto flex items-center gap-2">
                  {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                    <Button
                      className="flex gap-2 border-green-500 hover:bg-green-500/10"
                      variant={'outline'}
                      size={'icon'}
                      type="submit"
                      disabled={disabled}
                    >
                      <Save className="h-4 w-4 flex-1 text-green-500" />
                    </Button>
                  )}

                  <Button
                    className="flex gap-2 border-red-500 hover:bg-red-500/10"
                    variant={'outline'}
                    size={'icon'}
                    onClick={(e) => handleRemove(e)}
                    disabled={disabled}
                  >
                    <Trash className="h-4 w-4 flex-1 text-red-500" />
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
