import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const options = [
  { value: 'routine', label: 'Emar Routine' },
  { value: 'prn', label: 'Emar PRN' }
];

export const EmarTabs = () => {
  const naigate = useNavigate();
  const { params, setParam, resetParams } = useRouteParams();
  const [selectedEmar, setSelectedEmar] = useState<string>(params.e || 'routine');

  const onSelectEmar = (e: string) => {
    setParam('e', e);
  };

  useEffect(() => {
    if (selectedEmar !== options.find((option) => option.value === selectedEmar)?.value) {
      toast({
        description: 'Invalid Emar type',
        variant: 'destructive'
      });
      resetParams();
      naigate('/');

      return;
    }

    setParam('e', selectedEmar);
  }, [selectedEmar]);

  return (
    <Tabs
      defaultValue={selectedEmar}
      onValueChange={(e) => {
        onSelectEmar(e);
      }}
      className="ml-auto"
    >
      <TabsList>
        {options.map((option) => (
          <TabsTrigger
            key={option.value}
            value={option.value}
            onClick={() => setSelectedEmar(option.value)}
            className="data-[state=active]:bg-primary data-[state=active]:text-white"
          >
            {option.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};
