import { Button } from '@/common/presentation/components/ui/button';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Separator } from '@/common/presentation/components/ui/separator';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface Props {
  name?: string;
  defaultValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  disabled?: boolean;
}

const formSchema = z.object({
  label: z
    .string({
      required_error: 'Label is required'
    })
    .min(1, {
      message: 'Label must be at least 1 characters long'
    })
    .max(255, {
      message: 'Label must be at most 255 characters long'
    }),
  name: z
    .string({
      required_error: 'Key is required'
    })
    .min(1, {
      message: 'Key must be at least 1 characters long'
    })
    .max(255, {
      message: 'Key must be at most 255 characters long'
    }),
  type: z
    .string({
      required_error: 'Type is required'
    })
    .min(1, {
      message: 'Type must be at least 1 characters long'
    })
    .max(255, {
      message: 'Type must be at most 255 characters long'
    }),
  placeholder: z.string().optional(),
  default_value: z.string().optional(),
  required: z.boolean().optional()
});

type FormValues = z.infer<typeof formSchema>;

const intputTypes = [
  'text',
  'textarea'
  // 'number',
  // 'date',
  // 'password',
  // 'tel',
  // 'url',
  // 'color',
  // 'file',
  // 'time',
  // 'month',
  // 'week',
  // 'datetime-local'
];

export const CustomFieldsForm = ({ name, defaultValues, onSubmit, disabled = false }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues
  });

  const handleSubmit = async (values: FormValues) => {
    onSubmit(values);
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 px-1">
          <Separator className="col-span-full" />

          <FormField
            control={form.control}
            name="label"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Input Label <span className="text-destructive">(*)</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="Ex. Field 1" {...field} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Input key <span className="text-destructive">(*)</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="Ex. field_1" {...field} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Input Type <span className="text-destructive">(*)</span>
                </FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value} disabled={disabled}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a type" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {intputTypes.map((type) => (
                      <SelectItem key={type} value={type}>
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="placeholder"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Input placeholder</FormLabel>
                <FormControl>
                  <Input placeholder="Ex. Enter a value for Field 1" {...field} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="default_value"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Input default value</FormLabel>
                <FormControl>
                  <Input placeholder="field 1" {...field} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="required"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormLabel>Input required</FormLabel>
                <FormControl>
                  <Checkbox checked={field.value} onCheckedChange={field.onChange} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Separator className="col-span-full" />

          <Button type="submit" className="col-span-full" disabled={disabled}>
            {disabled ? <Loader className="size-4 mr-2 animate-spin" /> : null}
            {name ? 'Update' : 'Create'}
          </Button>
        </form>
      </Form>
    </>
  );
};
