import { Switch } from '@/common/presentation/components/ui/switch';
import { updateResidentDocumentHardCopy } from '@/modules/residents/presentation/slices/residentSlice';
import { AppDispatch } from '@/store/store';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DocumentsHistoryTableActions } from './DocumentsHistoryTableActions';

export const DocumentsHistorycolumns: ColumnDef<any>[] = [
  {
    header: 'Comment',
    accessorKey: 'comment'
  },
  {
    header: 'Upload Date',
    cell: ({ row }) => {
      const createdAt = row.original.created_at;

      if (!createdAt) {
        return <div className="flex items-center space-x-2"><p>N/A</p></div>;
      }

      const dateObject = new Date(createdAt);

      if (isNaN(dateObject.getTime())) {
        return <div className="flex items-center space-x-2"><p>Invalid Date</p></div>;
      }

      const formattedDate = format(dateObject, 'MM/dd/yyyy hh:mm a'); 

      return (
        <div className="flex items-center space-x-2">
          <p>{formattedDate}</p>
        </div>
      );
    }
  },
  {
    header: 'Hard Copy',
    accessorKey: 'hard_copy',
    cell: ({ row }) => {
      const otherInformation = typeof row.original.other_information === 'string'
        ? JSON.parse(row.original.other_information)
        : row.original.other_information || {};
  

  
      const initialChecked = otherInformation.hard_copy ?? false;
      console.log('Initial Checked:', initialChecked);
  
      const [isChecked, setIsChecked] = useState(initialChecked);
      const dispatch = useDispatch<AppDispatch>();
  
      const handleChange = useCallback(async (checked: boolean) => {
        setIsChecked(checked);
        try {
          await dispatch(updateResidentDocumentHardCopy({
            documentId: row.original.id,
            hard_copy: checked
          }));
        } catch (error) {
          console.error('Failed to update hard copy status', error);
        }
      }, [dispatch, row.original.id]);
  
      return (
        <div className="flex items-center space-x-2">
          <Switch
            id="hard_copy"
            checked={isChecked}
            onCheckedChange={handleChange}
          />
        </div>
      );
    }
  },
 

  {
    header: 'Document Version',
    id: 'actions',
    cell: ({ row }) => (
      <DocumentsHistoryTableActions
        id={row.original.id}
        isParent={row.original.parent_document_id !== null}
        documentUrl={row.original.url}
        status={row.original.status}
        onDelete={row.original.id}
      />
    )
  },

  

];