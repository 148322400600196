import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { format } from 'date-fns';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { CalendarIcon, Loader } from 'lucide-react';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { z } from 'zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { Input } from '@/common/presentation/components/ui/input';
import { Separator } from '@/common/presentation/components/ui/separator';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';

interface Props {
  className?: string;
}

const formSchema = z.object({
  termination_date: z.date({}).optional(),
  reason_termination: z.string({}).optional()
});
type FormValues = z.infer<typeof formSchema>;
export const TerminationForm = ({ className }: Props) => {
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMemberForm);

  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});
  const dispatch = useDispatch<AppDispatch>();
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues } as FormValues
  });
  const onSubmitForm = async (data: FormValues) => {
    const response = dispatch(
      staffMemberFormActions.updateStaffMemberTerminationForm({
        staff_member_id: staffMember?.id,
        ...data
      })
    );

    if ((await response).payload) {
      toast({
        description: 'termination information updated successfully'
      });
    }
  };
  useEffect(() => {
    if (staffMember) {
      setDefaultValues({
        termination_date: new Date(staffMember.termination_date) || undefined,
        reason_termination: staffMember.reason_termination || ''
      });
    }
  }, [staffMember]);
  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
           <CustomAccordion title="Termination">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmitForm)} className="space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="col-span-2 md:col-span-1">
                  <FormField
                    control={form.control}
                    name="termination_date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Termination date</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={'outline'}
                                className={cn(
                                  'w-full pl-3 text-left font-normal',
                                  !field.value && 'text-muted-foreground'
                                )}
                              >
                                {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date </span>}
                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <div className="rounded-md border">
                              <CustomCalendar
                                onChange={(date) => {
                                  field.onChange(date);
                                }}
                                value={field.value}
                                // baseSelectorYear={new Date().getFullYear() - 18}
                                MonthAndYearPicker
                              />
                            </div>
                          </PopoverContent>
                        </Popover>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <FormField
                    control={form.control}
                    name="reason_termination"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Reason of termination</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                  <>
                    <Separator className="col-span-full" />
                    <Button type="submit" className="w-64" disabled={staffFormStatus === 'loading'}>
                      {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </>
                )}
              </div>
            </form>
          </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
