import { create } from 'zustand';

type CustomFieldState = {
  name?: string;
  isOpen: boolean;
  onOpen: (id: string) => void;
  onClose: () => void;
};

export const useUpdateCustomField = create<CustomFieldState>((set) => ({
  isOpen: false,
  onOpen: (name: string) => set({ isOpen: true, name }),
  onClose: () => set({ isOpen: false })
}));
