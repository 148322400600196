import { AppDispatch, RootState } from '@/store/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdministrativeInformation } from './PrimaryInformationForm/AdministrativeInformation';
import { CustomFields } from './PrimaryInformationForm/CustomFields';
import { DischargeResidents } from './PrimaryInformationForm/Discharge';
import { LevelOfCareForm } from './PrimaryInformationForm/LevelOfCare';
import { NoNameResident } from './PrimaryInformationForm/NoNameResident';
import { NotesResidents } from './PrimaryInformationForm/Notes';
import { PersonalInformationForm } from './PrimaryInformationForm/PersonalInformation';
import { ProfilePicture } from './PrimaryInformationForm/ProfilePicture';
import { ReligiousPreferenceForm } from './PrimaryInformationForm/ReligiousPreference';
import { SwitchStatus } from './PrimaryInformationForm/SwitchStatus';
import * as residentConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';

export const PrimaryInformation = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [isAdministrator, setIsAdministrator] = useState<boolean>(false);
  const { configurations } = useSelector((state: RootState) => state.residents.configurations);
  const [formsToShow, setFormsToShow] = useState<Record<string, boolean> | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const isAdmin = user?.roles.some((role) => role.name === 'Super Administrator');
    setIsAdministrator(isAdmin || false);
  }, [user]);

  useEffect(() => {
    if (configurations) {
      setFormsToShow(configurations.resident_form_cards);
    }
  }, [configurations]);

  useEffect(() => {
    if (Object.keys(configurations).length === 0) {
      dispatch(residentConfigurationsActions.getResidentsConfigurations());
    }
  }, [dispatch]);


  return (
    <>
      <div className="grid grid-cols-4 rounded-lg border dark:bg-[#1d1d1d] mt-4 p-4 w-full gap-4">
        <div className="col-span-4 xl:col-span-1 flex flex-col gap-4">
          <ProfilePicture />
          <SwitchStatus />
        </div>
        <PersonalInformationForm className="col-span-4 xl:col-span-3" />
        <LevelOfCareForm className="col-span-4" />
        {formsToShow?.xxxx && <NoNameResident className="col-span-4" />}
        {formsToShow?.religious_preferences && <ReligiousPreferenceForm className="col-span-4" />}
        {isAdministrator && formsToShow?.administrator_can_see && <AdministrativeInformation className="col-span-4" />}
        {formsToShow?.custom_fields && <CustomFields className="col-span-4" />}
        {formsToShow?.notes && <NotesResidents className="col-span-4" />}
        {formsToShow?.discharge && <DischargeResidents className="col-span-4" />}
      </div>
    </>
  );
};
