import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  className?: string;
}

const formSchema = z.object({
  additional_training: z.string().optional(),
  training_group: z.string().optional()
});
type FormValues = z.infer<typeof formSchema>;

const defaultValues: Partial<FormValues> = {
  additional_training: '',
  
  training_group: ''
};
export const TrainingForm =({ className }: Props) => {
  // const [locationOptions, setLocationOptions] = useState([]);
  const [locationsResidents, setLocationsResidents]: any = useState({
    ...defaultValues
  });
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
    
  });
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMemberForm);
  const dispatch = useDispatch<AppDispatch>();

  const onSubmitForm = async (data: FormValues) => {
    const response = dispatch(
      staffMemberFormActions.updateStaffTrainingForm({
        staff_member_id: staffMember?.id,
        ...data
      })
    );
    if ((await response).payload) {
      toast({
        description: 'Staff information updated successfully'
      });
    }
  };

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load resident</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Traning Form">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmitForm)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <FormField
                  control={form.control}
                  name="training_group"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Training Group</FormLabel>
                      <Select onValueChange={field.onChange} value={field.value} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a resident house" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="arf">ARF</SelectItem>
                          <SelectItem value="rcfe">RCFE</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <FormField
                  control={form.control}
                  name="additional_training"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Additional Training Content</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field}  disabled={staffFormStatus === 'loading'}/>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                <>
                  <Separator className="col-span-full" />
                  <Button type="submit" className="w-64" disabled={staffFormStatus === 'loading'}>
                    {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                    Update
                  </Button>
                </>
              )}
            </div>
          </form>
        </Form>
        </CustomAccordion>
        </div>
      )}
    </>
  );
};
