import useRouteParams from "@/common/hooks/RouteParamsHook";
import { LocationSelector } from "@/common/presentation/components/Selectors/LocationSelector";
import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ScreeningQuestionsForm } from "../components/sceeningQuestions/ScreeningQuestionsForm";

export function ScreeningQuestionsPage() {
    const { params } = useRouteParams();
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const { t } = useTranslation();
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    return (
        <div>
        { !params.l && <LocationSelector selections={false} />}
        
        {
            params.l && <ScreeningQuestionsForm />
        }
    </div>
    );
}