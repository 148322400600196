import { TabsContent, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { UserRoundCog } from 'lucide-react';
import { FormCards } from './FormCards';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import * as staffConfigurationsActions from '@/modules/staff/presentation/slices/staffConfigurationsSlice';
import { StaffCustomFields } from './CustomFields';
import { Separator } from '@/common/presentation/components/ui/separator';
export const StaffTab = () => {
  return (
    <TabsTrigger value="staff" className="w-full flex items-center justify-start gap-2">
      <UserRoundCog />
      Staff
    </TabsTrigger>
  );
};

export const StaffContent = () => {
  const { configurations } = useSelector((state: RootState) => state.staff.configurations);

  const dispatch = useDispatch<AppDispatch>();

  const defaultValues = configurations?.staff_form_cards
    ? configurations.staff_form_cards
    : {
        company: false,
        training: false,
        other: false,
        custom_fields: false,
        termination: false,
        login_information: false
      };

  useEffect(() => {
    if (Object.keys(configurations).length === 0) {
      dispatch(staffConfigurationsActions.getStaffConfigurations());
    }
  }, [dispatch]);

  return (
    <TabsContent value="staff" className="w-full">
      <FormCards defaultValues={defaultValues} />
      <Separator className="my-4" />
      <StaffCustomFields />
    </TabsContent>
  );
};
