import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const physicianService = {
  getAllPhysicians: async () => {
    try {
      const response = await axios.get('physicians/listPhysicians');
      return response.data;
    } catch (error: any) {
      throw new Error('Error fetching physicians: ' + error.message);
    }
  },

  getPhysicianById: async (id: string) => {
    try {
      const response = await axios.get(`physicians/${id}`);
      return response.data;
    } catch (error: any) {
      throw new Error('Error fetching physician by ID: ' + error.message);
    }
  },

  createPhysician: async (physician: any) => {
    try {
      const response = await axios.post('physicians/createPhysician', physician);
      return response.data;
    } catch (error: any) {
      throw new Error('Error creating physician: ' + error.message);
    }
  },

  updatePhysician: async (id: string, physician: any) => {
    try {
      const response = await axios.put(`physicians/${id}`, physician);
      return response.data;
    } catch (error: any) {
      throw new Error('Error updating physician: ' + error.message);
    }
  },

  deletePhysician: async (id: string) => {
    try {
      await axios.delete(`physicians/${id}`);
    } catch (error: any) {
      throw new Error('Error deleting physician: ' + error.message);
    }
  },

  getAllPhysicianTypes: async () => {
    try {
      const response = await axios.get('physicians/physician-types');
      return response.data;
    } catch (error: any) {
      throw new Error('Error fetching physician types: ' + error.message);
    }
  }
};

export default physicianService;
