import { Badge } from '@/common/presentation/components/ui/badge';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { Label } from '@/common/presentation/components/ui/label';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaymentMethods, fetchSetPaymentMethodAsDefault } from '../slices/paymentsSlice';
import { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';
import { getCardImageType } from '@/utils/helpers/paymentMethod.helper';
import { useTranslation } from 'react-i18next';

const PaymentMethodList = () => {
  const dispatch = useDispatch();
  const paymentMethods = useSelector((state: any) => state.payment.paymentMethods) || [];
  const { t } = useTranslation();
  const [defaultLoading, setDefaultLoading] = useState({
    payment_id: '',
    loading: false
  });

  useEffect(() => {
    const getPaymentList = async () => {
      dispatch<any>(fetchPaymentMethods());
    };
    getPaymentList();
  }, []);

  const setPaymentAsDefault = async (paymentId: string) => {
    if (defaultLoading.loading) return;

    setDefaultLoading({
        payment_id: paymentId,
        loading: true
    });

    await dispatch<any>(fetchSetPaymentMethodAsDefault(paymentId));
    
    setDefaultLoading({
        payment_id: '',
        loading: false
    });
  };

  const capitalize = (string: string) => {
    string = string === 'american_express' ? 'american express' : string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="flex-row mt-4 gap-1">
      {paymentMethods.map((payment: any) => (
        <div key={payment.id}>
          <Card className="my-5">
            <CardContent className="pb-0 pl-3">
              <div className="flex py-2 justify-between items-center">
                <div className="flex items-center">
                  <img className="h-auto w-14 border border-1 rounded-md" src={`/img/credit-cards/${getCardImageType(payment.brand)}`} />
                </div>
                <div className="flex flex-col justify-center flex-grow pl-2 w-1/2">
                  <Label className="font-medium text-sm pl-2">
                    {capitalize(payment.brand)} {t('subscription.endingIn')} {payment.last_digits}
                  </Label>
                </div>
                <div className="flex flex-col justify-center items-end">
                  {payment.is_default ? (
                    <Badge className="bg-black text-white cursor-default hover:bg-black hover:text-white">
                      {t('subscription.default')}
                    </Badge>
                  ) : defaultLoading.loading && defaultLoading.payment_id === payment.id ? (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    <Label
                      onClick={() => setPaymentAsDefault(payment.id)}
                      className={`font-medium text-center ${!defaultLoading.loading ? 'hover:underline cursor-pointer text-primary' : 'text-slate-500' }`}
                    >
                      {t('subscription.setAsDefault')}
                    </Label>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default PaymentMethodList;
