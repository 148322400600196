import Counter from '@/common/presentation/components/Counter/Counter';
import { Label } from '@/common/presentation/components/ui/label';
import React, { useEffect, useState } from 'react';
import { IFacilitiesInfo } from '../../domain/makerPlace.domain';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { Button } from '@/common/presentation/components/ui/button';

interface PropsI {
  facilities: IFacilitiesInfo[];
  name: string;
  onSave: (facilitiesList: IFacilitiesInfo[]) => void;
  onCancel: () => void;
  extraBedPrice: string;
}

const BedSetting: React.FC<PropsI> = ({ facilities, name, onSave, onCancel, extraBedPrice }) => {
  const [facilitiesAux, setFacilitiesAux] = useState<IFacilitiesInfo[]>([]);

  useEffect(() => {
    setFacilitiesAux(facilities);
  }, [facilities]);
 
  const inputHandler = (value: number, index: number) => {
    setFacilitiesAux(prevFacilitiesAux => {
        const updatedFacilities = [...prevFacilitiesAux];
        updatedFacilities[index] = {
          ...updatedFacilities[index],
          beds_number: value
        };
        return updatedFacilities;
      });
  }

  return (
    <>
      <div>
        <div className="my-3">
          <Label className="font-bold text-xl">Set up facility's beds</Label>
        </div>
        <div className="mt-1 flex">
          <Label className="font-normal text-sm text-start justify-start">
            Each facility includes up to 10 beds at no extra cost. For any additional beds beyond the initial 10, a fee
            of {extraBedPrice} per bed will be charged.
          </Label>
        </div>
        <ScrollArea type="always" className={cn('border-2 border-dotted rounded-md p-2 mt-3', 'h-64')}>
          <div className="grid grid-cols-1 gap-2">
            {facilities.map((facility, index) => (
              <div key={index}>
                <div className="py-1">
                  <Label htmlFor="facilities_number" className="text-right">
                    Number of Beds for {facility.id.length > 0 ? facility.name : `Facility # ${index + 1}`}
                  </Label>
                </div>
                <div className="py-1">
                  <Counter
                    name={name}
                    defaultValue={facility.beds_number}
                    value={facility.beds_number}
                    onChange={(value) => inputHandler(value, index)}
                    minValue={1}
                    maxValue={30}
                    step={1}
                  />
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
        <div className="flex justify-end mt-3">
          <Button
            className="mr-2 bg-secondary text-primary hover:text-secondary border-1"
            onClick={() => onCancel()}
          >
            Back
          </Button>
          <Button onClick={() => onSave(facilitiesAux)}>Save changes</Button>
        </div>
      </div>
    </>
  );
};

export default BedSetting;
