import Title from "@/common/presentation/components/Title/Title";


const TrainingPage = () => {

    return (
        <div>
            <Title>Training</Title>
        </div>
    )
}

export default TrainingPage;