import { cn } from '@/lib/utils';
import React from 'react';
import { Button } from '../ui/button';

interface IProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  type?: 'title' | 'sub_title';
}

const Title: React.FC<IProps> = ({ children, className, onClick, type = 'title' }) => {
  const baseClassNames = 'font-bold text-muted-foreground dark:text-white';
  const sizeClassNames = type === 'title' ? 'text-2xl' : 'text-xl';
  const combinedClassNames = cn(baseClassNames, sizeClassNames, className);

  return onClick ? (
    <Button className={combinedClassNames} variant="link" onClick={onClick}>
      {children}
    </Button>
  ) : (
    <span className={combinedClassNames}>
      {children}
    </span>
  );
};

export default Title;
