import React, { FC, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomizations, getTemperature, getTemperatureType, saveTemperature } from '../slices/TrackerSlice';
import { AppDispatch, RootState } from '@/store/store';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { NavigationBar } from '@/common/presentation/components/navigations/NavigationBar';
import { TabsTrigger } from '@radix-ui/react-tabs';
import { Tabs, TabsList } from '@/common/presentation/components/ui/tabs';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { cn } from '@/lib/utils';
import ReportTemperature from '../components/Administrations/ReportTemperature';
import FormTemperature from '../components/Administrations/FormTemperature';
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';

const AdministrationPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { locationId } = useParams<{ l: string }>();
  const { params, setParam } = useRouteParams();
  const [location, setLocation] = useState<string | null>(null);
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('report');
  const [filter, setFilter] = useState<string>('');
  const formRef = useRef<any>();
  
  useEffect(() => {
    if (params.l && locations.length === 0) {
      const fetchData = async () => {
        const response = await dispatch(locationsActions.getLocations());
        if (response.payload) {
          const selectedLocations =
            params.l === 'all-locations'
              ? response.payload
              : response.payload.filter((l: any) => l.location.id === params.l);
          setSelectedLocations(selectedLocations.map((l: any) => l.location));
        }
      };
      fetchData();
    }

    if (!params.l && locations.length === 1) {
      setSelectedLocations(locations);
      setLocation(locations[0].id);
      setParam('l', locations[0].id);
    }

    setSelectedLocations(params.l === 'all-locations' ? locations : locations.filter((l) => l.id === params.l));
    setLocation(params.l);
  }, [params]);

  const onSelectTemperature = (e: string) => {
    setParam('t', e);
    setSelectedTab(e);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  return (
    <div>
      <div className="flex flex-col gap-4 my-4">
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <div className="font-semibold text-2xl">
              {selectedLocations.length > 0 ? (
                <>Temperature {selectedLocations.length > 1 ? 'All Locations' : `- ${selectedLocations[0]?.name}`}</>
              ) : (
                'Temperature'
              )}
            </div>
          </div>
          <Tabs
            defaultValue={selectedTab}
            onValueChange={(e) => {
              onSelectTemperature(e);
            }}
            className="flex gap-2"
          >
            <TabsList className="flex">
              <TabsTrigger
                value="form"
                className={cn(
                  'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all disabled:pointer-events-none disabled:opacity-50 bg-primary text-white',
                  selectedTab === 'form' ? 'bg-primary' : 'bg-gray-200 text-gray-800'
                )}
              >
                Form
              </TabsTrigger>
              <TabsTrigger
                value="report"
                className={cn(
                  'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all disabled:pointer-events-none disabled:opacity-50 bg-primary text-white',
                  selectedTab === 'report' ? 'bg-primary' : 'bg-gray-200 text-gray-800'
                )}
              >
                Report
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      <LocationSelector locationId={params.l} quickView={true} />
      <div className="flex items-center justify-between mb-4 mt-5">
        {selectedTab === 'report' && (
          <Input
            type="text"
            value={filter}
            onChange={handleFilterChange}
            placeholder="Filter..."
            className="max-w-xs"
          />
        )}
      </div>
      {selectedTab === 'form' ? <FormTemperature ref={formRef} /> : <ReportTemperature />}
    </div>
  )
};

export default AdministrationPage;
