import { Button } from '@/common/presentation/components/ui/button';
import { useNewExceptionDialog } from '@/modules/emar/infrastructure/hooks/UseNewException';
import { Pencil } from 'lucide-react';

export const OverviewTableActions = ({ data }: any) => {
  const { onOpen } = useNewExceptionDialog();

  const payload = {
    data,
    date: data?.emar_medication_exception.date_to_be_dispensed,
    time: [data?.emar_medication_exception.time_to_be_dispensed]
  };

  return (
    <Button
      size={'icon'}
      variant={'outline'}
      onClick={() => onOpen(payload.data, payload.date, payload.time, 'all-locations')}
    >
      <Pencil className="size-4" />
    </Button>
  );
};
