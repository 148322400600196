import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import useRouteParams from '@/common/hooks/RouteParamsHook';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Pencil, Trash, Ellipsis } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { RadioGroup } from '@/common/presentation/components/ui/radio-group';
import { useTranslation } from 'react-i18next';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CircleFadingPlus } from "lucide-react";


interface TypeI {
    id: string;
    tracker_name: string;
    type_name: string;
    image_url: string;
    description: string;
    status: 'active' | 'inactive';
}


interface TrackerI {
    id: string;
    location_id: string;
    created_at: string;
    data: TrackerDataI;
}

interface TrackerDataI {
    type_name?: string;
    image?: string;
    date: string;
    detail: string | null;
    location_id: string;
    resident: {
        id: string;
        first_name: string;
        last_name: string;
    };
    type: string;
    id: string;

    who: {
        id: string;
        first_name: string;
        last_name: string;
    };

}

interface TrackerUrineI extends TrackerI {
    data: TrackerDataI & {
        output: string;
        smell: string;
        other: string;
        type_description?: string;
    };
}


function TrackerInconsistence() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [typesBowel, setTypesBowel] = useState<TypeI[]>([]);
    const [typesUrine, setTypesUrine] = useState<TypeI[]>([]);
    const [typesBrief, setTypesBrief] = useState<TypeI[]>([]);
    const [dataReportBowel, setDatareportBowel] = useState<TrackerI[]>([]);
    const [dataReportUrine, setDatareportUrine] = useState<TrackerUrineI[]>([]);
    const [dataReportBrief, setDatareportBrief] = useState<TrackerI[]>([]);
    const dispatch = useDispatch();
    const { tracker } = useSelector((state: RootState) => state.tracker);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [trackerSlect, settrackerSlect] = useState('');
    const [selectedIncosistence, setSelectedInconsistence] = useState<string>(params.e || 'form');
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [inputValues, setInputValues] = useState({});
    const { t } = useTranslation();
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    const { locationSelectedId } = useLocation();

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
            setParam('e', 'form');
            setSelectedInconsistence('form');
        }

        if (!params?.l || params.l.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }

    }, [params]);

    const [selectedTracker, setSelectedTracker] = useState<string>('bowel');

    const onSelectTracker = (e: string) => {
        setSelectedTracker(e);
    };

    const onSelectVitals = (e: string) => {
        setParam('e', e);
        setSelectedInconsistence(e);
    };

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        params.l === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === params.l);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!params.l && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(params.l === 'all-locations' ? locations : locations.filter((l) => l.id === params.l));
        setLocation(params.l);

        if (params.l && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);


    const residentSelected = useMemo(() => {
        return UserHelper.getResidentInformation(params.r, residents);
    }, [params.r, residents]);


    const newColumns = () => {
        const firstObject = {
            label: t('trackers.Type'),
            value: "type",
            type: "texto"
        };
        return [firstObject, ...residents.map((resident: { name: any; }) => ({
            label: `${resident.first_name} ${resident.last_name}`,
            value: resident.first_name,
            type: 'text'
        }))];
    };
    const columns = newColumns();
    const columnsreport = [
        { label: t('trackers.Date/time'), value: 'date', type: 'date' },
        { label: t('trackers.Resident'), value: 'resident_name' },
        { label: t('trackers.Type'), value: 'type_name' },
        { label: t('trackers.Detail'), value: 'detail' },
        { label: t('trackers.Staff'), value: 'who_name' }
    ];
    const columnsreportUrine = [
        { label: t('trackers.Date/time'), value: 'date', type: 'date' },
        { label: t('trackers.Resident'), value: 'resident_name' },
        { label: t('trackers.Type'), value: 'type_name' },
        { label: t('trackers.Output'), value: 'output' },
        { label: t('trackers.Smell'), value: 'smell' },
        { label: t('trackers.Other'), value: 'other' },
        { label: t('trackers.Detail'), value: 'detail' },
        { label: t('trackers.Staff'), value: 'who_name' }
    ];

    const dataBowelReport = async () => {
        const json = { location_id: params.l };
        const response = await TrackerService.getTrackerBowel(json);
        if (response) {
            try {
                const { types, trackerBowels }: { types: TypeI[], trackerBowels: TrackerI[] } = response;
                setTypesBowel(types);
                trackerBowels.sort((a, b) => {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return dateB - dateA;
                });

                setDatareportBowel(trackerBowels);
            } catch (error: any) {
                console.log(error);
            }
        }
    };


    const dataUrineReport = async () => {
        const json = { location_id: params.l };
        const response = await TrackerService.getTrackerUrine(json);
        if (response) {
            try {
                const { types, trackerUrines }: { types: TypeI[], trackerUrines: TrackerUrineI[] } = response;
                setTypesUrine(types);
                trackerUrines.sort((a, b) => {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return dateB - dateA;
                });

                setDatareportUrine(trackerUrines);
            } catch (error: any) {
                console.log(error);
            }
        }
    }


    const dataBriefReport = async () => {
        const json = { location_id: params.l };
        const response = await TrackerService.getTrackerBrief(json);
        if (response) {
            try {
                const { types, trackerBriefs }: { types: TypeI[], trackerBriefs: TrackerI[] } = response;
                setTypesBrief(types);
                trackerBriefs.sort((a, b) => {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return dateB - dateA;
                });

                setDatareportBrief(trackerBriefs);
            } catch (error: any) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        if (!dataReportBowel) {
            dataBowelReport();
        }

        if (!dataReportUrine) {
            dataUrineReport();
        }
        if (!dataReportBrief) {
            dataBriefReport();
        }
    }, [dataReportBowel, dataReportUrine, dataReportBrief]);

    const handleDateChange = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value instanceof Date && !isNaN(value) ? value : null
        }));
    };

    const Fields = {
        'EditBowel': [
            { fieldName: 'date', label: t('trackers.Date/Time'), type: 'date' },
            { fieldName: 'type', label: t('trackers.Type'), type: 'select' },
            { fieldName: 'detail', label: t('trackers.Detail'), type: 'textarea' }
        ],
        'EditUrine': [
            { fieldName: 'date', label: t('trackers.Date/Time'), type: 'date' },
            { fieldName: 'type', label: t('trackers.Type'), type: 'selecturine' },
            { fieldName: 'output', label: t('trackers.Output'), type: 'selectoutput' },
            { fieldName: 'smell', label: t('trackers.Smell'), type: 'selectsmell' },
            { fieldName: 'other', label: t('trackers.Other'), type: 'selectother' },
            { fieldName: 'detail', label: t('trackers.Detail'), type: 'textarea' }

        ],
        'EditBrief': [
            { fieldName: 'date', label: t('trackers.Date/Time'), type: 'date' },
            { fieldName: 'type', label: t('trackers.Type'), type: 'selectbrief' },
            { fieldName: 'detail', label: t('trackers.Detail'), type: 'textarea' }
        ],

    };

    const [fields, setDialogField] = useState(false);
    const handleButtonOpenDialog = (fields: any[], title: string, idTracker: string, data: TrackerDataI, description: string, tracker: string) => {
        const combinedData = { ...data, idTracker };
        setShowEditDialog(true);
        setFormValue(combinedData);
        setDialogField(fields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
        settrackerSlect(tracker);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };


    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};
        if (trackerSlect === 'Bowel') {
            if (action === 'Edit') {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                json["data"] = {
                    "type": formData.type,
                    "detail": formData.detail,
                    "resident_id": formData.resident_id,
                    "date": formData.date
                };
                const response = await TrackerService.updateTrackerBowel(json);
                setShowEditDialog(false);
            } else {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                const response = await TrackerService.destroyTrackerBowel(json);
                setShowEditDialog(false);
            }
            dataBowelReport();
        } else if (trackerSlect === 'Urine') {
            if (action === 'Edit') {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                json["data"] = {
                    "type": formData.type,
                    "resident_id": formData.resident_id,
                    "date": formData.date,
                    "output": formData.output,
                    "smell": formData.smell,
                    "other": formData.other,
                    "detail": formData.detail
                }
                const response = await TrackerService.updateTrackerUrine(json);
                setShowEditDialog(false);
            } else {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                const response = await TrackerService.destroyTrackerUrine(json);
                setShowEditDialog(false);
            }
            dataUrineReport();
        } else if (trackerSlect === 'Brief') {
            if (action === 'Edit') {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                json["data"] = {
                    "type": formData.type,
                    "detail": formData.detail,
                    "resident_id": formData.resident_id,
                    "date": formData.date
                }
                const response = await TrackerService.updateTrackerBrief(json);
                setShowEditDialog(false);
            } else {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                const response = await TrackerService.destroyTrackerBrief(json);
                setShowEditDialog(false);
            }
            dataBriefReport();
        }

    };

    useEffect(() => {
        dataBowelReport();
        dataUrineReport();
        dataBriefReport();
    }, [params.l]);



    const [filters, setFilters] = useState({
        resident_name: ' ',
        who_name: ''
    });

    useEffect(() => {
        const resident = residents.find((res) => res.id === params.r);
        if (resident) {
            setFilters({
                resident_name: `${resident.first_name} ${resident.last_name}`,
                who_name: ''
            });
        } else {
            setFilters({
                resident_name: '',
                who_name: ''
            });
        }
    }, [params.r]);

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderInputForColumn = (column, index) => {
        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'date':
                if (formData[column.fieldName] != undefined) {
                    const dateString = formData[column.fieldName];
                    const parsedDate = new Date(dateString);
                    const formattedDate = format(parsedDate, "dd/MM/yyyy hh:mm a");
                    return <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"outline"}
                                className={`w-[319px] justify-start text-left font-normal`}>
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {formattedDate}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                                id={column.fieldName}
                                name={column.fieldName}
                                selectedDate={parsedDate}
                                onChange={date => handleDateChange(column.fieldName, date)}
                                MonthAndYearPicker={true}
                                timePicker={true}
                            />
                        </PopoverContent>
                    </Popover>
                };
            case 'textarea':
                return <textarea
                    id={column.fieldName}
                    name={column.fieldName}
                    value={formData[column.fieldName] || ''}
                    onChange={handleChange}
                    style={{ outline: 'none' }}
                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                />;
            case 'select':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)} style={{ outline: 'none' }}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md " style={{ outline: 'none' }}>
                            <SelectValue placeholder={column.label}>
                                {typesBowel.find((option) => option.id === formData.type)?.type_name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesBowel.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.type_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selecturine':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesUrine.find((option) => option.id === formData.type)?.type_name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesUrine.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.type_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectoutput':
                return (
                    <Select value={formData.output} onValueChange={(value) => handleSelectChange('output', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesUrineOutput.find((option) => option.id === formData.output)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesUrineOutput.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectsmell':
                return (
                    <Select value={formData.smell} onValueChange={(value) => handleSelectChange('smell', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesUrineSmell.find((option) => option.id === formData.smell)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesUrineSmell.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectother':
                return (
                    <Select value={formData.other} onValueChange={(value) => handleSelectChange('other', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesUrineOther.find((option) => option.id === formData.other)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesUrineOther.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectbrief':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesBrief.find((option) => option.id === formData.type)?.type_name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesBrief.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.type_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            default:
                return null;
        }
    };

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction: direction });
    };

    const sortedDataBowel = useMemo(() => {
        if (sortConfig.key) {
            return [...dataReportBowel].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });
        }

        if (Array.isArray(typesBowel) && !typesBowel.length) {
            return;
        }

        const dataWithTypeName = dataReportBowel.map(row => ({
            ...row,
            data: {
                ...row.data,
                type_name: typesBowel.find(type => type.id === row.data.type)?.type_name || 'N/A',
                image: typesBowel.find(type => type.id === row.data.type)?.image_url || ''
            }

        }));


        return dataWithTypeName

    }, [dataReportBowel, sortConfig]);


    const sortedDataUrine = useMemo(() => {
        if (sortConfig.key) {
            return [...dataReportUrine].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });

        }

        if (Array.isArray(typesUrine) && !typesUrine.length) {
            return;
        }

        const dataWithTypeUrine = dataReportUrine.map(row => ({
            ...row,
            data: {
                ...row.data,
                type_name: typesUrine.find(type => type.id === row.data.type)?.type_name || 'N/A',
                type_description: typesUrine.find(type => type.id === row.data.type)?.description || ''
            }

        }));


        return dataWithTypeUrine
    }, [dataReportUrine, sortConfig]);

    const sortedDataBrief = useMemo(() => {
        if (sortConfig.key) {
            return [...dataReportBrief].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });
        }

        if (Array.isArray(typesBrief) && !typesBrief.length) {
            return;
        }
        const dataWithTypeName = dataReportBrief.map(row => ({
            ...row,
            data: {
                ...row.data,
                type_name: typesBrief.find(type => type.id === row.data.type)?.type_name || 'N/A'
            }

        }));


        return dataWithTypeName;
    }, [dataReportBrief, sortConfig]);

    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedTypeUrine, setSelectedTypeUrine] = useState<string | null>(null);
    const [selectedTypeBrief, setSelectedTypeBrief] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [detailBowel, setDetailBowel] = useState('');
    const [detailUrine, setDetailUrine] = useState('');
    const [detailBrief, setDetailBrief] = useState('');
    const formattedDate = selectedDate ? selectedDate.toLocaleString() : '';

    useEffect(() => {
        if (Array.isArray(typesBowel) && !typesBowel.length) {
            return;
        }
        const defaultType = typesBowel?.find(type => type.type_name === 'Type 4');
        if (defaultType) {
            setSelectedType(defaultType.id);
        }
    }, [typesBowel]);

    useEffect(() => {
        if (Array.isArray(typesUrine) && !typesUrine.length) {
            return;
        }
        const defaultUrineType = typesUrine?.find(type => type.type_name === 'Yellow');
        if (defaultUrineType) {
            setSelectedTypeUrine(defaultUrineType.id);
        }
    }, [typesUrine]);

    const handleDateChangeBowel = (date) => {
        setSelectedDate(date);
    };
    const handleDateChangeBrief = (date) => {
        setSelectedDate(date);
    };

    const handleDateChangeUrine = (date) => {
        setSelectedDate(date);
    };

    const handleChangeBowel = (event) => {
        setDetailBowel(event.target.value);
    };

    const handleChangeBrief = (event) => {
        setDetailBrief(event.target.value);
    };

    const handleChangeUrine = (event) => {
        setDetailUrine(event.target.value);
    };

    const handleSubmitBowel = async (event) => {
        event.preventDefault();
        if (!params.r) {
            setErrorMessage('Por favor seleccione un residente');
            return;
        } else {
            setErrorMessage('');
        }

        const formData = [{
            type: selectedType,
            resident_id: params.r,
            detail: detailBowel,
            date: selectedDate,
            location_id: params.l
        }];
        try {
            const response = await TrackerService.saveTrackerBowel(formData);
            setDetailBowel(' ');
            dataBowelReport();
            toast({
                title: 'Bowel movement',
                description: 'Bowel movement saved successfully',
                className: 'bg-green-500 text-white'
            });
        } catch (error) {
            console.error('Error al guardar:', error);
            toast({
                description: 'There was an error saving the bowel movement',
                variant: 'destructive'
            });
        }
    };


    const handleSubmitBrief = async (event) => {
        event.preventDefault();
        if (!params.r) {
            setErrorMessage('Por favor seleccione un residente');
            return;
        } else {
            setErrorMessage('');
        }

        const formData = [{
            type: selectedTypeBrief,
            resident_id: params.r,
            detail: detailBrief,
            date: selectedDate,
            location_id: params.l
        }];
        try {
            const response = await TrackerService.saveTrackerBrief(formData);
            setDetailBrief(' ');
            dataBriefReport();
            toast({
                title: 'Brief',
                description: 'brief saved successfully',
                className: 'bg-green-500 text-white'
            });
        } catch (error) {
            console.error('Error al guardar:', error);
            toast({
                description: 'There was an error saving brief',
                variant: 'destructive'
            });
        }
    };


    const [formDataOut, setFormDataOut] = useState({ type: 'Medium' });

    const typesUrineOutput = [
        { id: 'Light', name: t('trackers.Light') },
        { id: 'Medium', name: t('trackers.Medium') },
        { id: 'Heavy', name: t('trackers.Heavy') }
    ];


    const handleSelectChangeOut = (field, value) => {
        setFormDataOut((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };
    const [formDataSmell, setFormDataSmell] = useState({ type: 'Normal' });

    const typesUrineSmell = [
        { id: 'Normal', name: t('trackers.Normal') },
        { id: 'Strong', name: t('trackers.Strong') }
    ];


    const handleSelectChangeSmell = (field, value) => {
        setFormDataSmell((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };


    const [formDataOther, setFormDataOther] = useState({ type: 'None' });

    const typesUrineOther = [
        { id: 'None', name: t('trackers.None') },
        { id: 'Discharge', name: t('trackers.Discharge') },
        { id: 'Painful Urination', name: t('trackers.Painful Urination') },
        { id: 'Effort to urinate', name: t('trackers.Effort to urinate') }
    ];

    const handleSelectChangeOther = (field, value) => {
        setFormDataOther((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };


    const handleSubmitUrine = async (event) => {
        event.preventDefault();
        if (!params.r) {
            setErrorMessage('Por favor seleccione un residente');
            return;
        } else {
            setErrorMessage('');
        }

        const formData = [{
            type: selectedTypeUrine,
            resident_id: params.r,
            date: selectedDate,
            output: formDataOut.type,
            smell: formDataSmell.type,
            other: formDataOther.type,
            detail: detailUrine,
            location_id: params.l
        }];
        try {
            const response = await TrackerService.saveTrackerUrine(formData);
            setDetailUrine(' ');
            dataUrineReport();
            toast({
                title: 'Urine',
                description: 'Urine saved successfully',
                className: 'bg-green-500 text-white'
            });
        } catch (error) {
            console.error('Error al guardar:', error);
            toast({
                description: 'There was an error saving urine',
                variant: 'destructive'
            });
        }
    };

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                params.l && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackerincontinence.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackerincontinence.description')}</div>
                        </div>

                        <Tabs defaultValue={selectedIncosistence} onValueChange={(e) => { onSelectVitals(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('seizure.form.label')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }


            {
                selectedIncosistence === 'form' && (
                    <div>
                        {
                            params.l && (
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <ResidentSelector
                                            onSelect={onResidentSelect}
                                            locationId={location}
                                            residentId={params.r}
                                            showAllResidents={false}
                                            showKeepInEye={true}
                                        />
                                    </div>
                                    <div >
                                        <Card className="border-t-4 border-t-primary/80">
                                            <CardHeader>
                                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Bowel Movement')}</CardTitle>
                                            </CardHeader>
                                            <form onSubmit={handleSubmitBowel}>
                                                <CardContent>
                                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                                    <div className="flex flex-wrap lg:flex-nowrap">
                                                        <RadioGroup className="flex flex-wrap lg:flex-nowrap gap-4 w-full">
                                                            {typesBowel && typesBowel.map((type) => (
                                                                <label key={type.type_name} className="bowel-type flex flex-col items-center p-4 w-full sm:w-1/2 md:w-1/3 lg:w-auto">
                                                                    <img
                                                                        className="img-thumbnail thumbnail-administrator w-24 h-auto"
                                                                        src={`/img/trackers/bowel/${type.image_url}`}
                                                                        alt={type.image_url}
                                                                    />
                                                                    <br />
                                                                    <input
                                                                        type="radio"
                                                                        name="tracker_bowel_type"
                                                                        value={type.id}
                                                                        checked={selectedType === type.id}
                                                                        onChange={() => setSelectedType(type.id)}
                                                                        required
                                                                    />
                                                                    <b className="mt-2">{type.type_name}</b>
                                                                    <p className="mt-1 text-center">{type.description}</p>
                                                                </label>
                                                            ))}
                                                        </RadioGroup>
                                                    </div>

                                                    <div className="flex flex-wrap">
                                                        <div className="flex flex-col w-full md:w-1/2 p-2">
                                                            <Label htmlFor="tracker_bowel_date" className="control-label mb-3">
                                                                {t('trackers.Date/Time')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Popover>
                                                                    <PopoverTrigger asChild>
                                                                        <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                                            {formattedDate}
                                                                        </Button>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent className="w-auto p-0">
                                                                        <CustomCalendar
                                                                            id="tracker_bowel"
                                                                            name="tracker_bowel"
                                                                            selectedDate={selectedDate}
                                                                            onChange={handleDateChangeBowel}
                                                                            MonthAndYearPicker={true}
                                                                            timePicker={true}
                                                                        />
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col w-full md:w-1/2 p-2">
                                                            <Label htmlFor="tracker_bowel_detail" className="control-label mb-3">
                                                                {t('trackers.Detail')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    className="w-full justify-start text-left font-normal"
                                                                    id="tracker_bowel_detail"
                                                                    name="tracker_bowel_detail"
                                                                    type="text"
                                                                    value={detailBowel}
                                                                    onChange={handleChangeBowel}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row justify-end mt-4">
                                                        <Button type="submit" className="bg-primary text-white px-4 py-2 rounded">
                                                            {t('trackers.Save')}
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </form>
                                        </Card>
                                    </div>

                                    <div>
                                        <Card className="border-t-4 border-t-primary/80">
                                            <CardHeader>
                                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Urine')}</CardTitle>
                                            </CardHeader>
                                            <form onSubmit={handleSubmitUrine}>
                                                <CardContent>
                                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                                    <div className="flex flex-wrap lg:flex-nowrap">
                                                        <RadioGroup className="flex flex-wrap lg:flex-nowrap gap-7 w-full">
                                                            {typesUrine && typesUrine && typesUrine.map((type) => (
                                                                <label key={type.type_name} className="bowel-type flex flex-col items-center p-4 w-full sm:w-1/2 md:w-1/3 lg:w-auto">
                                                                    <img
                                                                        className="img-thumbnail thumbnail-administrator w-10 h-auto"
                                                                        src={`/img/trackers/urine/${type.image_url}`}
                                                                        alt={type.image_url}
                                                                    />
                                                                    <br />
                                                                    <input
                                                                        type="radio"
                                                                        name="tracker_urine_type"
                                                                        value={type.id}
                                                                        checked={selectedTypeUrine === type.id}
                                                                        onChange={() => setSelectedTypeUrine(type.id)}
                                                                        required
                                                                    />
                                                                    <b className="mt-2">{type.type_name}</b>
                                                                    <p className="mt-1 text-center">{type.description}</p>
                                                                </label>
                                                            ))}
                                                        </RadioGroup>
                                                    </div>

                                                    <div className="flex flex-wrap">
                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_date" className="control-label mb-3">
                                                                {t('trackers.Date/Time')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Popover>
                                                                    <PopoverTrigger asChild>
                                                                        <Button variant="outline" className="w-full lg:w-[200px] justify-start text-left font-normal">
                                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                                            {formattedDate}
                                                                        </Button>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent className="w-auto p-0">
                                                                        <CustomCalendar
                                                                            id="tracker_urine"
                                                                            name="tracker_urine"
                                                                            selectedDate={selectedDate}
                                                                            onChange={handleDateChangeUrine}
                                                                            MonthAndYearPicker={true}
                                                                            timePicker={true}
                                                                        />
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_output" className="control-label mb-3">
                                                                {t('trackers.Output')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Select value={formDataOut.type} onValueChange={(value) => handleSelectChangeOut('type', value)} >
                                                                    <SelectTrigger className="w-full lg:w-[200px] justify-start text-left font-normal" >
                                                                        <SelectValue placeholder="Select Urine Output">
                                                                            {typesUrineOutput.find((option) => option.id === formDataOut.type)?.name}
                                                                        </SelectValue>
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        {typesUrineOutput.map((option) => (
                                                                            <SelectItem key={option.id} value={option.id}>
                                                                                {option.name}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectContent>
                                                                </Select>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_smell" className="control-label mb-3">
                                                                {t('trackers.Smell')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Select value={formDataSmell.type} onValueChange={(value) => handleSelectChangeSmell('type', value)}>
                                                                    <SelectTrigger className="w-full lg:w-[200px] justify-start text-left font-normal">
                                                                        <SelectValue placeholder="Select Urine Output">
                                                                            {typesUrineSmell.find((option) => option.id === formDataSmell.type)?.name}
                                                                        </SelectValue>
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        {typesUrineSmell.map((option) => (
                                                                            <SelectItem key={option.id} value={option.id}>
                                                                                {option.name}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectContent>
                                                                </Select>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_other" className="control-label mb-3">
                                                                {t('trackers.Other')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Select value={formDataOther.type} onValueChange={(value) => handleSelectChangeOther('type', value)}>
                                                                    <SelectTrigger className="w-full lg:w-[200px] justify-start text-left font-normal">
                                                                        <SelectValue placeholder="Select Urine Other">
                                                                            {typesUrineOther.find((option) => option.id === formDataOther.type)?.name}
                                                                        </SelectValue>
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        {typesUrineOther.map((option) => (
                                                                            <SelectItem key={option.id} value={option.id}>
                                                                                {option.name}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectContent>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_detail" className="control-label mb-3">
                                                                {t('trackers.Detail')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    className="w-full lg:w-[233px] justify-start text-left font-normal"
                                                                    id="tracker_urine_detail"
                                                                    name="tracker_urine_detail"
                                                                    type="text"
                                                                    value={detailUrine}
                                                                    onChange={handleChangeUrine}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row justify-end mt-4">
                                                        <Button type="submit" className="bg-primary text-white px-4 py-2 rounded">
                                                            {t('trackers.Save')}
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </form>
                                        </Card>
                                    </div>

                                    <div>
                                        <Card className="border-t-4 border-t-primary/80">
                                            <CardHeader>
                                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Brief')}</CardTitle>
                                            </CardHeader>
                                            <form onSubmit={handleSubmitBrief}>
                                                <CardContent>
                                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                                    <div className="flex flex-wrap lg:flex-nowrap">
                                                        <RadioGroup className="flex flex-wrap lg:flex-nowrap gap-4 w-full">
                                                            {typesBrief && typesBrief.map((type) => (
                                                                <label key={type.type_name} className="bowel-type flex flex-col items-center p-4 w-full sm:w-1/2 md:w-1/3 lg:w-auto">
                                                                    <img
                                                                        className="img-thumbnail thumbnail-administrator w-25 h-auto"
                                                                        src={`/img/trackers/brief/${type.image_url}`}
                                                                        alt={type.image_url}
                                                                    />
                                                                    <br />
                                                                    <input
                                                                        type="radio"
                                                                        name="tracker_bowel_type"
                                                                        value={type.id}
                                                                        checked={selectedTypeBrief === type.id}
                                                                        onChange={() => setSelectedTypeBrief(type.id)}
                                                                        required
                                                                    />
                                                                    <b className="mt-2">{type.type_name}</b>
                                                                    <p className="mt-1 text-center">{type.description}</p>
                                                                </label>
                                                            ))}
                                                        </RadioGroup>
                                                    </div>
                                                    <div className="flex flex-wrap">
                                                        <div className="flex flex-col w-full md:w-1/2 p-2">
                                                            <Label htmlFor="tracker_brief_date" className="control-label mb-3">
                                                                {t('trackers.Date/Time')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Popover>
                                                                    <PopoverTrigger asChild>
                                                                        <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                                            {formattedDate}
                                                                        </Button>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent className="w-auto p-0">
                                                                        <CustomCalendar
                                                                            id="tracker_brief"
                                                                            name="tracker_brief"
                                                                            selectedDate={selectedDate}
                                                                            onChange={handleDateChangeBrief}
                                                                            MonthAndYearPicker={true}
                                                                            timePicker={true}
                                                                        />
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col w-full md:w-1/2 p-2">
                                                            <Label htmlFor="tracker_brief_detail" className="control-label mb-3">
                                                                {t('trackers.Detail')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    className="w-full justify-start text-left font-normal"
                                                                    id="tracker_brief_detail"
                                                                    name="tracker_brief_detail"
                                                                    type="text"
                                                                    value={detailBrief}
                                                                    onChange={handleChangeBrief}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row justify-end mt-4">
                                                        <Button type="submit" className="bg-primary text-white px-4 py-2 rounded">
                                                            {t('trackers.Save')}
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </form>
                                        </Card>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                selectedIncosistence === 'report' && (
                    <div>
                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <Tabs defaultValue={selectedTracker} onValueChange={(e) => { onSelectTracker(e); }}
                                    className="w-auto">
                                    <TabsList>
                                        <TabsTrigger value="bowel" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t('trackers.Bowel Movement')}
                                        </TabsTrigger>
                                        <TabsTrigger value="urine" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t('trackers.Urine')}
                                        </TabsTrigger>
                                        <TabsTrigger value="brief" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t('trackers.Brief')}
                                        </TabsTrigger>
                                    </TabsList>

                                </Tabs>
                                {selectedTracker === 'bowel' && (
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Tracker Bowel Report')}</CardTitle>
                                )}
                                {selectedTracker === 'urine' && (
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Tracker Urine Report')}</CardTitle>
                                )}
                                {selectedTracker === 'brief' && (
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Tracker Brief Report')}</CardTitle>
                                )}
                                <div className="flex w-full items-center justify-between">
                                    <div>

                                    </div>
                                </div>
                            </CardHeader>
                            <CardContent>
                                {selectedTracker === 'bowel' && (
                                    <div>
                                        <div className="flex space-x-4">
                                            <Input
                                                placeholder="Filter by Resident"
                                                value={filters.resident_name}
                                                onChange={(e) => setFilters({ ...filters, resident_name: e.target.value })}
                                                className="w-[200px]"
                                            />
                                            <Input
                                                placeholder="Filter by Staff"
                                                value={filters.who_name}
                                                onChange={(e) => setFilters({ ...filters, who_name: e.target.value })}
                                                className="w-[200px]"
                                            />
                                        </div>
                                        <br />
                                        <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                            <Table className="shadow-lg overflow-hidden">
                                                <TableHeader>
                                                    <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                        {columnsreport.map((column) => (

                                                            <TableHead
                                                                key={column.value}
                                                                className="text-white cursor-pointer items-center"
                                                                onClick={() => handleSort(column.value)}>
                                                                <div className="flex items-center ">
                                                                    <span>{column.label}</span>
                                                                    <span className="ml-1 flex-col justify-end">
                                                                        <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'ascending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                            ▲
                                                                        </span>
                                                                        <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'descending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                            ▼
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </TableHead>

                                                        ))}
                                                        <TableHead className="text-white">{t('trackers.Actions')}</TableHead>

                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody className="bg-white dark:bg-background">

                                                    {sortedDataBowel && sortedDataBowel
                                                        .filter(row =>
                                                            (!filters.resident_name ||
                                                                (`${row.data.resident.first_name.toLowerCase()} ${row.data.resident.last_name.toLowerCase()}`).includes(filters.resident_name.toLowerCase())
                                                            ) &&
                                                            (!filters.who_name ||
                                                                (`${row.data.who.first_name.toLowerCase()} ${row.data.who.last_name.toLowerCase()}`).includes(filters.who_name.toLowerCase())
                                                            )
                                                        )
                                                        .map((row, index) => (
                                                            <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                                {columnsreport.map((column, columnIndex) => {

                                                                    return (
                                                                        <TableCell key={columnIndex} className="py-2 px-4">
                                                                            {column.value === 'date' ? (
                                                                                <span>
                                                                                    {format(new Date(row.data.date), "dd/MM/yyyy")}
                                                                                    <br/>
                                                                                    {format(new Date(row.data.date), "hh:mm a")}
                                                                                    {column.type_name === 'date' ? ` (${row.value})` : null}
                                                                                </span>
                                                                            ) : column.value === 'resident_name' ? (
                                                                                <span>
                                                                                    {capitalizeWords(String(row.data.resident.first_name))} {capitalizeWords(String(row.data.resident.last_name))}
                                                                                </span>
                                                                            ) : column.value === 'type_name' ? (
                                                                                <span>
                                                                                    {row.data.type_name !== undefined && capitalizeWords(String(row.data.type_name))}
                                                                                    {row.data.image && (
                                                                                        <label key={row[column.value]} className="bowel-type flex flex-col p-1">
                                                                                            <img
                                                                                                className="img-thumbnail thumbnail-administrator w-24 h-auto"
                                                                                                src={`/img/trackers/bowel/${row.data.image}`}
                                                                                                alt={row.data.type_name !== undefined ? row.data.type_name : 'No image'}
                                                                                            />
                                                                                        </label>
                                                                                    )}
                                                                                </span>
                                                                            ) : column.value === 'detail' ? (
                                                                                <span>
                                                                                    {row.data.detail ? capitalizeWords(String(row.data.detail)) : 'N/A'}
                                                                                </span>
                                                                            ) : column.value === 'who_name' ? (
                                                                                <span>
                                                                                    {capitalizeWords(String(row.data.who.first_name))} {capitalizeWords(String(row.data.who.last_name))}
                                                                                </span>
                                                                            ) : (
                                                                                capitalizeWords(String(row[column.value as keyof typeof row]))
                                                                            )}

                                                                        </TableCell>
                                                                    )
                                                                })}
                                                                <TableCell className="py-2 px-4">
                                                                    <DropdownMenu>
                                                                        <DropdownMenuTrigger asChild>
                                                                            <Button variant="outline" size="icon">
                                                                                <Ellipsis className="h-4 w-4" />
                                                                            </Button>
                                                                        </DropdownMenuTrigger>
                                                                        <DropdownMenuContent className="w-56">
                                                                            <DropdownMenuSeparator />
                                                                            <DropdownMenuGroup>
                                                                                <DropdownMenuItem>
                                                                                    <Pencil className="mr-2 h-4 w-4" />
                                                                                    <span onClick={() => handleButtonOpenDialog(Fields['EditBowel'], 'Edit', row.id, row.data, '', 'Bowel')}>{t('trackers.Edit')}</span>
                                                                                </DropdownMenuItem>
                                                                                <DropdownMenuItem>
                                                                                    <Trash className="mr-2 h-4 w-4" />
                                                                                    <span onClick={() => handleButtonOpenDialog([], 'Delete', row.id, row.data, "Are you sure you want to delete this item?", 'Bowel')}>{t('trackers.Delete')}</span>
                                                                                </DropdownMenuItem>

                                                                            </DropdownMenuGroup>
                                                                        </DropdownMenuContent>
                                                                    </DropdownMenu>

                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                                <CustomDialog
                                                    width="30rem"
                                                    newDialog={true}
                                                    isOpen={showEditDialog}
                                                    onClose={handleCloseEditDialog}
                                                    title={dialogTitle}
                                                    onSubmit={handleSubmit}
                                                    description={dialogDescription}
                                                >
                                                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                                        <div className="grid gap-4">
                                                            {fields && fields.map((field, index) => (
                                                                    <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                                                        <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>
                                                                        {renderInputForColumn(field, index)}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </form>

                                                </CustomDialog>
                                            </Table>
                                            <div>
                                                <div className="flex items-center justify-between px-2">
                                                    <div className="flex-1 text-sm text-muted-foreground">
                                                        {dataReportBowel && dataReportBowel.length} of {dataReportBowel && dataReportBowel.length} row(s) selected.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="flex justify-end mb-4">
                                            <button className="p-2 bg-primary text-white rounded">{t('trackers.Download')}</button>
                                        </div>
                                    </div>
                                )}

                                {selectedTracker === 'urine' && (
                                    <div>
                                        <div className="flex space-x-4">
                                            <Input
                                                placeholder="Filter by Resident"
                                                value={filters.resident_name}
                                                onChange={(e) => setFilters({ ...filters, resident_name: e.target.value })}
                                                className="w-[200px]"
                                            />
                                            <Input
                                                placeholder="Filter by Staff"
                                                value={filters.who_name}
                                                onChange={(e) => setFilters({ ...filters, who_name: e.target.value })}
                                                className="w-[200px]"
                                            />
                                        </div>
                                        <br />
                                        <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                            <Table className="shadow-lg overflow-hidden">
                                                <TableHeader>
                                                    <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                        {columnsreportUrine.map((column) => (

                                                            <TableHead
                                                                key={column.value}
                                                                className="text-white cursor-pointer items-center"
                                                                onClick={() => handleSort(column.value)}>
                                                                <div className="flex items-center ">
                                                                    <span>{column.label}</span>
                                                                    <span className="ml-1 flex-col justify-end">
                                                                        <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'ascending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                            ▲
                                                                        </span>
                                                                        <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'descending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                            ▼
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </TableHead>

                                                        ))}
                                                        <TableHead className="text-white">{t('trackers.Actions')}</TableHead>

                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody className="bg-white dark:bg-background">

                                                    {sortedDataUrine && sortedDataUrine
                                                        .filter(row =>
                                                            (!filters.resident_name ||
                                                                (`${row.data.resident.first_name.toLowerCase()} ${row.data.resident.last_name.toLowerCase()}`).includes(filters.resident_name.toLowerCase())
                                                            ) &&
                                                            (!filters.who_name ||
                                                                (`${row.data.who.first_name.toLowerCase()} ${row.data.who.last_name.toLowerCase()}`).includes(filters.who_name.toLowerCase())
                                                            )
                                                        )
                                                        .map((row, index) => (
                                                            <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                                {columnsreportUrine.map((column, columnIndex) => (
                                                                    <TableCell key={columnIndex} className="py-2 px-4">
                                                                        {column.value === 'date' ? (
                                                                            <span>
                                                                                {format(new Date(row.data.date), "dd/MM/yyyy hh:mm a")}
                                                                                {column.type_name === 'date' ? ` (${row.value})` : null}
                                                                            </span>
                                                                        ) : column.value === 'resident_name' ? (
                                                                            <span>
                                                                                {capitalizeWords(String(row.data.resident.first_name))} {capitalizeWords(String(row.data.resident.last_name))}
                                                                            </span>
                                                                        ) : column.value === 'type_name' ? (
                                                                            <span>
                                                                                {row.data.type_name !== undefined && capitalizeWords(String(row.data.type_name))}
                                                                                {row.data.type_name && (
                                                                                    <>
                                                                                        {" - "}
                                                                                        {row.data.type_description}
                                                                                    </>
                                                                                )}

                                                                            </span>
                                                                        ) : column.value === 'output' ? (

                                                                            <span>
                                                                                {row.data.output ? capitalizeWords(String(row.data.output)) : 'N/A'}
                                                                            </span>

                                                                        ) : column.value === 'smell' ? (

                                                                            <span>
                                                                                {row.data.smell ? capitalizeWords(String(row.data.smell)) : 'N/A'}
                                                                            </span>

                                                                        ) : column.value === 'other' ? (

                                                                            <span>
                                                                                {row.data.other ? capitalizeWords(String(row.data.other)) : 'N/A'}
                                                                            </span>

                                                                        ) : column.value === 'detail' ? (

                                                                            <span>
                                                                                {row.data.detail ? capitalizeWords(String(row.data.detail)) : 'N/A'}
                                                                            </span>

                                                                        ) : column.value === 'who_name' ? (
                                                                            <span>
                                                                                {capitalizeWords(String(row.data.who.first_name))} {capitalizeWords(String(row.data.who.last_name))}
                                                                            </span>
                                                                        ) : (
                                                                            capitalizeWords(String(row[column.value as keyof typeof row]))
                                                                        )}

                                                                    </TableCell>
                                                                ))}
                                                                <TableCell className="py-2 px-4">
                                                                    <DropdownMenu>
                                                                        <DropdownMenuTrigger asChild>
                                                                            <Button variant="outline" size="icon">
                                                                                <Ellipsis className="h-4 w-4" />
                                                                            </Button>
                                                                        </DropdownMenuTrigger>
                                                                        <DropdownMenuContent className="w-56">
                                                                            <DropdownMenuSeparator />
                                                                            <DropdownMenuGroup>
                                                                                <DropdownMenuItem>
                                                                                    <Pencil className="mr-2 h-4 w-4" />
                                                                                    <span onClick={() => handleButtonOpenDialog(Fields['EditUrine'], 'Edit', row.id, row.data, '', 'Urine')}>{t('trackers.Edit')}</span>
                                                                                </DropdownMenuItem>
                                                                                <DropdownMenuItem>
                                                                                    <Trash className="mr-2 h-4 w-4" />
                                                                                    <span onClick={() => handleButtonOpenDialog([], 'Delete', row.id, row.data, "Are you sure you want to delete this item?", 'Urine')}>{t('trackers.Delete')}</span>
                                                                                </DropdownMenuItem>

                                                                            </DropdownMenuGroup>
                                                                        </DropdownMenuContent>
                                                                    </DropdownMenu>

                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                                <CustomDialog
                                                    width="30rem"
                                                    newDialog={true}
                                                    isOpen={showEditDialog}
                                                    onClose={handleCloseEditDialog}
                                                    title={dialogTitle}
                                                    onSubmit={handleSubmit}
                                                    description={dialogDescription}
                                                >
                                                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                                        <div className="grid gap-4">
                                                            {fields && fields.map((field, index) => (
                                                                <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                                                    <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>
                                                                    {renderInputForColumn(field, index)}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </form>

                                                </CustomDialog>
                                            </Table>
                                            <div>
                                                <div className="flex items-center justify-between px-2">
                                                    <div className="flex-1 text-sm text-muted-foreground">
                                                        {dataReportUrine && dataReportUrine.length} of {dataReportUrine && dataReportUrine.length} row(s) selected.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="flex justify-end mb-4">
                                            <button className="p-2 bg-primary text-white rounded">{t('trackers.Download')}</button>
                                        </div>
                                    </div>
                                )}

                                {selectedTracker === 'brief' && (
                                    <div>
                                        <div className="flex space-x-4">
                                            <Input
                                                placeholder="Filter by Resident"
                                                value={filters.resident_name}
                                                onChange={(e) => setFilters({ ...filters, resident_name: e.target.value })}
                                                className="w-[200px]"
                                            />
                                            <Input
                                                placeholder="Filter by Staff"
                                                value={filters.who_name}
                                                onChange={(e) => setFilters({ ...filters, who_name: e.target.value })}
                                                className="w-[200px]"
                                            />
                                        </div>
                                        <br />
                                        <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                            <Table className="shadow-lg overflow-hidden">
                                                <TableHeader>
                                                    <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                        {columnsreport.map((column) => (

                                                            <TableHead
                                                                key={column.value}
                                                                className="text-white cursor-pointer items-center"
                                                                onClick={() => handleSort(column.value)}>
                                                                <div className="flex items-center ">
                                                                    <span>{column.label}</span>
                                                                    <span className="ml-1 flex-col justify-end">
                                                                        <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'ascending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                            ▲
                                                                        </span>
                                                                        <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'descending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                            ▼
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </TableHead>

                                                        ))}
                                                        <TableHead className="text-white">{t('trackers.Actions')}</TableHead>

                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody className="bg-white dark:bg-background">

                                                    {sortedDataBrief && sortedDataBrief
                                                        .filter(row =>
                                                            (!filters.resident_name ||
                                                                (`${row.data.resident.first_name.toLowerCase()} ${row.data.resident.last_name.toLowerCase()}`).includes(filters.resident_name.toLowerCase())
                                                            ) &&
                                                            (!filters.who_name ||
                                                                (`${row.data.who.first_name.toLowerCase()} ${row.data.who.last_name.toLowerCase()}`).includes(filters.who_name.toLowerCase())
                                                            )
                                                        )
                                                        .map((row, index) => (
                                                            <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                                {columnsreport.map((column, columnIndex) => (
                                                                    <TableCell key={columnIndex} className="py-2 px-4">
                                                                        {column.value === 'date' ? (
                                                                            <span>
                                                                                {format(new Date(row.data.date), "dd/MM/yyyy hh:mm a")}
                                                                                {column.type_name === 'date' ? ` (${row.value})` : null}
                                                                            </span>
                                                                        ) : column.value === 'resident_name' ? (
                                                                            <span>
                                                                                {capitalizeWords(String(row.data.resident.first_name))} {capitalizeWords(String(row.data.resident.last_name))}
                                                                            </span>
                                                                        ) : column.value === 'detail' ? (

                                                                            <span>
                                                                                {row.data.detail ? capitalizeWords(String(row.data.detail)) : 'N/A'}
                                                                            </span>

                                                                        ) : column.value === 'type_name' ? (
                                                                            <span>
                                                                                {row.data.type_name !== undefined && capitalizeWords(String(row.data.type_name))}
                                                                            </span>
                                                                        ) : column.value === 'who_name' ? (
                                                                            <span>
                                                                                {capitalizeWords(String(row.data.who.first_name))} {capitalizeWords(String(row.data.who.last_name))}
                                                                            </span>
                                                                        ) : (
                                                                            capitalizeWords(String(row[column.value as keyof typeof row]))
                                                                        )}

                                                                    </TableCell>
                                                                ))}
                                                                <TableCell className="py-2 px-4">
                                                                    <DropdownMenu>
                                                                        <DropdownMenuTrigger asChild>
                                                                            <Button variant="outline" size="icon">
                                                                                <Ellipsis className="h-4 w-4" />
                                                                            </Button>
                                                                        </DropdownMenuTrigger>
                                                                        <DropdownMenuContent className="w-56">
                                                                            <DropdownMenuSeparator />
                                                                            <DropdownMenuGroup>
                                                                                <DropdownMenuItem>
                                                                                    <Pencil className="mr-2 h-4 w-4" />
                                                                                    <span onClick={() => handleButtonOpenDialog(Fields['EditBrief'], 'Edit', row.id, row.data, '', 'Brief')}>{t('trackers.Edit')}</span>
                                                                                </DropdownMenuItem>
                                                                                <DropdownMenuItem>
                                                                                    <Trash className="mr-2 h-4 w-4" />
                                                                                    <span onClick={() => handleButtonOpenDialog([], 'Delete', row.id, row.data, "Are you sure you want to delete this item?", 'Brief')}>{t('trackers.Delete')}</span>
                                                                                </DropdownMenuItem>

                                                                            </DropdownMenuGroup>
                                                                        </DropdownMenuContent>
                                                                    </DropdownMenu>

                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                                <CustomDialog
                                                    width="30rem"
                                                    newDialog={true}
                                                    isOpen={showEditDialog}
                                                    onClose={handleCloseEditDialog}
                                                    title={dialogTitle}
                                                    onSubmit={handleSubmit}
                                                    description={dialogDescription}
                                                >
                                                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                                        <div className="grid gap-4">
                                                            {fields && fields.map((field, index) => (
                                                                <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                                                    <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>
                                                                    {renderInputForColumn(field, index)}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </form>

                                                </CustomDialog>
                                            </Table>
                                            <div>
                                                <div className="flex items-center justify-between px-2">
                                                    <div className="flex-1 text-sm text-muted-foreground">
                                                        {dataReportBowel && dataReportBowel.length} of {dataReportBowel && dataReportBowel.length} row(s) selected.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="flex justify-end mb-4">
                                            <button className="p-2 bg-primary text-white rounded">{t('trackers.Download')}</button>
                                        </div>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                )
            }

        </div >
    )
}

export default TrackerInconsistence