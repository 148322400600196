import { CrudState, FormData, NoteI } from '@/modules/notes/domain/note.domain';
import { useState } from 'react';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { SelectNoteTo } from '../notes/elements/SelectNoteTo';
import {
  Select as ShadSelect,
  SelectContent,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { QuillJS } from '../common/quilljs';
import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Loader } from 'lucide-react';
import NoteService from '../../../infrastructure/services/NoteService';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { UrgentNoteCheckbox } from './elements/urgentNoteCeheckbox';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import { SelectNoteCategory } from '../common/SelectNoteCategory';
import { useTranslation } from 'react-i18next';

interface CreateNoteProps {
  crudState: CrudState;
  setCrudState: (crudState: CrudState) => void;
  note: NoteI | null;
}

export function UpdateNote({ crudState, setCrudState, note }: CreateNoteProps) {
  // Init values
  const { t } = useTranslation();
  const defaultFormData: FormData = {
    content: note?.content || '',
    html_content: note?.html_content || '',
    images: note?.images || null,
    user_id: note?.private_user_ids || [],
    location_id: note?.scope_location_ids || [],
    department_id: note?.department_ids || [],
    category_id: note?.category_id || '',
    resident_id: note?.resident_id || null,
    urgent: note?.urgent || false
  };

  // Tools
  const { params, setParam } = useRouteParams();

  // Hooks
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);

  // Behaviors
  const getContent = () => {
    if (note) {
      return note.html_content;
    }

    return '';
  };

  const onResidentSelect = (resident: any) => {
    setFormData({
      ...formData,
      ['resident_id']: resident
    });
  };

  const handleSelectNoteTo = (e: any) => {
    const recipients: string[] = [];

    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        recipients.push(e[key].value);
      }
    }

    const staffItems = recipients.filter((item) => item.endsWith('-staff')).map((item) => item.replace('-staff', ''));

    const departmentsItems = recipients
      .filter((item) => item.endsWith('-department'))
      .map((item) => item.replace('-department', ''));

    const locationItems = recipients
      .filter((item) => item.endsWith('-location') && item !== 'all-houses-location')
      .map((item) => item.replace('-location', ''));

    setFormData({
      ...formData,
      ['user_id']: staffItems,
      ['location_id']: locationItems,
      ['department_id']: departmentsItems
    });
  };

  const handleSelectCategory = (value: any) => {
    setFormData({
      ...formData,
      ['category_id']: value
    });
  };

  const handleSelectQuicknote = (value: any) => {};

  const handleQuillJs = (values: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...values
    }));
  };

  const handleUrgentNoteCheckbox = (value: boolean) => {
    setFormData({
      ...formData,
      ['urgent']: value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    let data = {
      ...formData,
      id: crudState.itemId
    };

    await NoteService.updateNote(data);

    setIsLoading(false);

    setCrudState(NoteHelperService.defineState({}));
  };

  return (
    <div className="flex flex-col h-full p-4 pr-1">
      <div className="flex flex-col  h-[95vh]">
        <ScrollArea className="h-[86vh]">
          <ResidentSelector locationId={params?.l} onSelect={onResidentSelect} setUrlParams={false}  />
          <Card className="rounded-t-none border-t-0">
            <CardContent>
              <form onSubmit={handleSubmit}>
                <div className="pt-3">
                  <SelectNoteTo note={note} handleSelectNoteTo={handleSelectNoteTo} />
                </div>
                <div className="flex justify-between pt-3">
                  <SelectNoteCategory handleSelectCategory={handleSelectCategory} value={formData.category_id} />
                  <ShadSelect name="quickNote" onValueChange={handleSelectQuicknote}>
                    <SelectTrigger className="w-[49%]" name="quickNote">
                      <SelectValue placeholder={t('notes.quickNote')} />
                    </SelectTrigger>
                    <SelectContent></SelectContent>
                  </ShadSelect>
                </div>
                <div className="w-full h-[15vh] pt-8">
                  <ScrollArea className="h-[15vh]">
                    <QuillJS onChange={handleQuillJs} value={getContent()} />
                  </ScrollArea>
                </div>
                <div className="pt-16 flex justify-between">
                  <UrgentNoteCheckbox
                    checked={note ? Boolean(note.urgent) : false}
                    onCheck={handleUrgentNoteCheckbox}
                  />
                  <Button type="submit" disabled={isLoading} size="sm" className="ml-auto">
                    {isLoading ? <Loader className="mr-2 h-4 w-4 animate-spin" /> : t('notes.update')}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </ScrollArea>
      </div>
    </div>
  );
}
