import { Column } from '@tanstack/react-table';

interface RowData {
  id: string;
  key: string;
  label: string;
  type: string;
  editable: string;
  visible: string;
}

export const columns: Column<RowData>[] = [
  {
    id: 'hour',
    key: 'hour',
    label: 'Hour',
    type: 'select',
    visible: true,
    static: true,
    sortable: true
  },
  {
    key: 'name',
    label: 'Name',
    type: 'text',
    visible: true,
    sortable: true
  },
  {
    key: 'likes_to_be_called',
    label: 'Likes To Be Called',
    accessor: 'preferred_name',
    type: 'text',
    visible: true,
    sortable: true
  },
  {
    key: 'date_of_birth',
    label: 'Date Of Birth',
    accessor: 'dob',
    visible: true,
    sortable: true
  },
  {
    key: 'admission_date',
    label: 'Admission Date',
    accessor: 'other_information.admission_date',
    visible: true,
    sortable: true
  },
  {
    key: 'home',
    label: 'Home',
    accessor: 'location.name',
    visible: true,
    sortable: true
  },
  {
    key: 'actions',
    label: 'Actions',
    visible: true,
    static: true,
    sortable: false
  }
];
