import { create } from 'zustand';
import ResidentCarriersFinancialInformationService from '@/modules/residents/infrastructure/services/ResidentCarriersFinancialInformationService';

type CarriersAndFinancialInformationState = {
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  createOrupdateCarrierInformation: (data: any) => Promise<any>;
  removeCarrierInformation: (data: any) => Promise<any>;
  createOrUpdateFinancialInformation: (data: any) => Promise<any>;
  removeFinancialInformation: (data: any) => Promise<any>;
};

export const useCarriersAndFinancialInformationStore = create<CarriersAndFinancialInformationState>((set) => ({
  status: 'idle',
  error: null,

  createOrupdateCarrierInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.createOrUpdateCarrierInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  removeCarrierInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.removeCarrierInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  createOrUpdateFinancialInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.createOrUpdateFinancialInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  removeFinancialInformation: async (data: any) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentCarriersFinancialInformationService.removeFinancialInformation(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
