import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { cn } from '@/lib/utils';
import { IAccount } from '@/modules/wizard/domain/signup.domain';
import { useWizardFormData } from '@/modules/wizard/infrastructure/providers/FormDataProvider';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserNameExist, updateLoginInfo } from '../../slices/SignUpSlice';
import { z } from 'zod';
import { Eye, EyeOff, Loader2 } from 'lucide-react';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useNavigate } from 'react-router-dom';
import { verifySession } from '@/auth/presentation/slices/authSlice';
import { getClientConfigurations } from '@/common/presentation/slices/configurationSlice';
import { useTranslation } from 'react-i18next';

const Step6 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUserNameExist, setIsUserNameExist] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [errors, setErrors] = useState<IAccount>({
    user_name: '',
    password: '',
    confirm_password: ''
  });
  const { formData, updateFormData } = useWizardFormData();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    updateFormData({
      ...formData,
      account: {
        ...formData.account,
        [name]: value
      }
    });

    setErrors({
      ...errors,
      [name]: ''
    });

    if (name === 'confirm_password') {
      validateConfirmPassword(value.toString());
    }

    if (name === 'user_name') {
      userNameExists(value.toString());
    }
  };

  const validateConfirmPassword = (confirmPassword: string) => {
    if (confirmPassword.length === 0) {
      setIsPasswordMatch(true);
      return;
    }
    if (formData.account.password === confirmPassword) {
      setIsPasswordMatch(true);
    } else {
      setIsPasswordMatch(false);
    }
  };

  const validForm = () => {
    setErrors({
      user_name: '',
      password: '',
      confirm_password: ''
    });

    const schema = z
      .object({
        user_name: z.string().min(3, { message: t('signUp.userNameAtLeast', { number: 3 }) }),
        password: z.string().min(8, { message: t('signUp.passwordAtLeast', { number: 8 }) }),
        confirm_password: z.string().min(8, { message: t('signUp.passwordAtLeast', { number: 8 }) })
      })
      .refine(
        (values) => {
          return values.password === values.confirm_password;
        },
        {
          message: t('signUp.passwordsMustMatch'),
          path: ['confirm_password']
        }
      );

    try {
      schema.parse(formData.account);
      return true;
    } catch (error: any) {
      const errors = error.errors.reduce((acc: any, curr: any) => {
        return { ...acc, [curr.path[0]]: curr.message };
      }, {});

      setErrors(errors);
      return false;
    }
  };

  const userNameExists = async (userName: string) => {
    setIsUserNameExist(false);
    if (userName.trim().length === 0) {
      return;
    }
    const response = await dispatch<any>(fetchUserNameExist(userName));
    if (!response.error) {
      const { exists } = response.payload;
      setIsUserNameExist(exists);
    }
  };

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoading(true);
    if (validForm()) {
      try {
        const response = await dispatch<any>(updateLoginInfo(formData.account));

        if (response.error) {
          throw new Error(response.error.message);
        }
      } catch (error: any) {
        toast({
          title: 'User Creation Error',
          description: `Error: ${error.message}`,
          variant: 'destructive'
        });
        setIsLoading(false);
        return;
      }
      await dispatch<any>(getClientConfigurations());
      await dispatch<any>(verifySession());

      setTimeout(() => {
        toast({
          title: 'User Creation',
          description: `Your user was created successfully!`,
          className: 'bg-green-500 text-white'
        });
        navigate('/dashboard', { replace: true });
        return;
      }, 100);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="col-span-3 w-11/12 lg:w-9/12 flex md:block items-center md:items-start">
        <Card className={`border-0 shadow-none flex flex-col md:mt-20`}>
          <CardHeader>
            <CardTitle className="flex flex-row items-center text-xl font-bold">
              {t('signUp.userInformation')}
            </CardTitle>
            <div className="flex w-full items-center justify-between">
              <div className="text-base">{t('signUp.welcomeCaringData')}</div>
            </div>
          </CardHeader>
          <CardContent>
            <form>
              <div className="grid grid-cols-1 gap-2">
                <div className="flex w-full flex-col justify-center items-center">
                  <div className="col-span-1 w-full">
                    <div className="py-1">
                      <Label htmlFor="user_name" className="text-right">
                        {t('signUp.userName')}{' '}
                        <span className={cn(errors.user_name ? 'text-red-600' : 'text-gray-400')}>
                          ({t('signUp.required')})
                        </span>
                      </Label>
                    </div>
                    <div className="py-1">
                      <Input
                        type="text"
                        name="user_name"
                        className={cn(isUserNameExist ? 'border-red-600' : '')}
                        placeholder={t('signUp.userName')}
                        value={formData.account.user_name}
                        onChange={(e) => handleInput(e)}
                      />
                      {isUserNameExist && (
                        <Label className="mt-3 ml-1 text-red-600 font-semibold text-xs">
                          {t('signUp.userNameAlreadyExists')}
                        </Label>
                      )}
                      {errors.user_name && (
                        <Label className="mt-3 ml-1 text-red-600 font-semibold text-xs">{errors.user_name}</Label>
                      )}
                    </div>
                  </div>

                  <div className="col-span-1 w-full">
                    <div className="py-1">
                      <Label htmlFor="password" className="text-right">
                        {t('signUp.password')}{' '}
                        <span className={cn(errors.password ? 'text-red-600' : 'text-gray-400')}>
                          ({t('signUp.required')})
                        </span>
                      </Label>
                    </div>
                    <div className="py-1 w-full">
                      <div className="relative flex items-center">
                        <Input
                          type={passwordVisible ? 'text' : 'password'}
                          name="password"
                          placeholder={t('signUp.password')}
                          value={formData.account.password}
                          onChange={(e) => handleInput(e)}
                        />
                        <Eye
                          className={cn(
                            'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                            passwordVisible && 'hidden'
                          )}
                          onClick={() => setPasswordVisible(true)}
                        />
                        <EyeOff
                          className={cn(
                            'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                            !passwordVisible && 'hidden'
                          )}
                          onClick={() => setPasswordVisible(false)}
                        />
                      </div>
                      {errors.password && (
                        <Label className="mt-3 ml-1 text-red-600 font-semibold text-xs">{errors.password}</Label>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 w-full">
                    <div className="py-1">
                      <Label htmlFor="confirm_password" className="text-right">
                        {t('signUp.confirmPassword')}{' '}
                        <span className={cn(errors.password ? 'text-red-600' : 'text-gray-400')}>
                          ({t('signUp.required')})
                        </span>
                      </Label>
                    </div>
                    <div className="py-1">
                      <div className="relative flex items-center">
                        <Input
                          type={confirmPasswordVisible ? 'text' : 'password'}
                          name="confirm_password"
                          placeholder={t('signUp.confirmPassword')}
                          value={formData.account.confirm_password}
                          className={cn(!isPasswordMatch ? 'border-red-600' : '')}
                          onChange={(e) => handleInput(e)}
                        />
                        <Eye
                          className={cn(
                            'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                            confirmPasswordVisible && 'hidden'
                          )}
                          onClick={() => setConfirmPasswordVisible(true)}
                        />
                        <EyeOff
                          className={cn(
                            'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                            !confirmPasswordVisible && 'hidden'
                          )}
                          onClick={() => setConfirmPasswordVisible(false)}
                        />
                      </div>
                      {!isPasswordMatch && errors.confirm_password.length === 0 && (
                        <Label className="mt-3 ml-1 text-red-600 font-semibold text-xs">
                          {t('signUp.passwordsMustMatch')}
                        </Label>
                      )}
                      {errors.confirm_password && (
                        <Label className="mt-3 ml-1 text-red-600 font-semibold text-xs">
                          {errors.confirm_password}
                        </Label>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-span-1 w-full py-2">
                  <Button className="w-full" onClick={(e) => onSubmit(e)} disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        {t('signUp.creatingUser')}
                      </>
                    ) : (
                      <>{t('signUp.createUser')}</>
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Step6;
