import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useRouteParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(() => {
    const initialParams: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      initialParams[key] = value;
    }
    return initialParams;
  });

  const setParam = (key: string, value: string) => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.set(key, value);
      return newSearchParams;
    });
  };

  const removeParam = (key: string) => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.delete(key);
      return newSearchParams;
    });
  };

  const resetParams = () => {
    // setTimeout(() => {
    setParams({});
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      for (const key of newSearchParams.keys()) {
        newSearchParams.delete(key);
      }
      return newSearchParams;
    });
    // }, 5000);
  };

  useEffect(() => {
    const newParams: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      newParams[key] = value;
    }
    setParams(newParams);
  }, [searchParams.toString()]);

  return { params, setParam, removeParam, resetParams };
};

export default useRouteParams;
