import { Tabs, TabsList, TabsTrigger } from "@/common/presentation/components/ui/tabs";
import { useEffect, useState } from "react";
import { ITrackerCleaningArea, ITrackerCleaningQuickTracker, ITrackerCleaningTask, trackerCleaningAreaTableData, trackerCleaningQuickTrackerData } from "./TrackerCleaningMockup";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { Checkbox } from "@/common/presentation/components/ui/checkbox";
import { format } from "date-fns";
import { Button } from "@/common/presentation/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { getAreas, getDaySummaries, getTasks } from "../../slices/TrackerCleaningSlice";
import { RootState } from "@/store/store";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { Progress } from "@/common/presentation/components/ui/progress";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import TrackerCleaningService from "@/modules/tracker/infrastructure/services/TrackerCleaningService";
import { Search } from "lucide-react";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from "react-i18next";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";

export function TrackerCleaningQuickTracker() {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const configurations = useSelector((state: RootState) => state.configurations);
    const { toast } = useToast();
    const { t } = useTranslation();
    const [selectedArea, setArea] = useState<string>("");
    const [areas, setAreas] = useState<ITrackerCleaningArea[]>([]);
    const [tasks, setTasks] = useState<ITrackerCleaningTask[]>([]);
    const [dataSource, setDataSource] = useState<ITrackerCleaningTask[]>([]);
    const [percentage, setPercentage] = useState<number>(0);
    const [accentColor, setAccentColor] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchDaySummmaries();
        fetchAreas();
        fetchTask();
    }, []);

    useEffect(() => {
        setPercentage((tasks.filter(value => value.complete).length / tasks.length) * 100);
    }, [tasks]);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        const summariesNames = trackerCleaning.daySummaries.map(value => value.data.task.name);

        const verifiedTasks = trackerCleaning.activeTasks.map(value => {
            const newValue = Object.assign({}, value);

            if(summariesNames.includes(newValue.name)) {
                newValue.complete = true;
                newValue.doneToday = true;
            } else {
                newValue.complete = false;
                newValue.doneToday = false;
            }

            return newValue;
        });

        setTasks(verifiedTasks);
        setAreas(trackerCleaning.activeAreas);
        setDataSource(verifiedTasks);
    }, [trackerCleaning]);

    const fetchDaySummmaries = () => {
        dispatch(getDaySummaries(
            {
                location_id: params.l,
                day: format(new Date(), "d")
            }
        ));
    }

    const fetchTask = () => {
        dispatch(getTasks(
            {
                location_id: params.l
            }
        ));
    }

    const fetchAreas = () => {
        dispatch(getAreas(
            {
                location_id: params.l
            }
        ));
    }

    const onSelectedTab = (event: string) => {
        setArea(event);
    }

    const completeTask = (id: string, checked: boolean) => {
        const newTasks = dataSource.map(value => {
            const newValue = Object.assign({}, value);

            if(value.id === id) newValue.complete = checked;

            return newValue;
        });

        setTasks(newTasks);
        setDataSource(newTasks);
    }

    const saveQuickSummary = async () => {
        const toCreate = dataSource.filter(value => value.complete && !value.doneToday);

        if(toCreate.length) {
            if(selectedArea) {
                const summariesPromise = toCreate.map((value) => {
                    return TrackerCleaningService.createSummary({
                        "location_id": params.l,
                        "data": {
                            "date": new Date().toISOString(),
                            "task": value.id,
                            "detail": "",
                            "area": selectedArea
                        }
                    })
                });
                
                await Promise.all(summariesPromise);

                fetchDaySummmaries();
                fetchTask();
            } else {
                toast(
                    {
                        description: t("cleaning.form.quick.toast.areaRequired")
                    }
                );
            }
        } else {
            toast(
                {
                    description: t("cleaning.form.quick.toast.taskSelectRequired")
                }
            );
        }
    }

    const a = () => {
        setOpen(true);
    }

    return (
        <div>
            {
                (
                    dataSource.length && areas.length ? (
                        <Tabs defaultValue={selectedArea} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto mb-3">
                            <TabsList>
                                {
                                    areas.map(value => 
                                        <TabsTrigger value={value.id} className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {value.name}
                                        </TabsTrigger>
                                    )
                                }
                            </TabsList>
                        </Tabs>
                    ) : null
                )
            }

            <div style={{ display: "grid", gridTemplateRows: "1fr", gridTemplateColumns: "1fr 0.5fr", columnGap: "2rem", alignItems: "start" }}>
                <div className="w-100" style={{ display: "grid", gridTemplateRows: "1fr auto", gridTemplateColumns: "1fr", columnGap: "2rem", alignItems: "center" }}>
                    {
                        dataSource.length && areas.length ? (
                            <Table>
                                <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                                    <TableRow className="bg-primary/80 hover:bg-primary/80">
                                        <TableHead className="text-white">
                                            { t("cleaning.form.quick.table.task") }
                                        </TableHead>
                                        <TableHead className="text-white">{format(new Date(), "d")}</TableHead>
                                    </TableRow>
                                </TableHeader>
                                
                                <TableBody>
                                    {
                                        dataSource.length && dataSource.map(
                                            element =>
                                            <TableRow key={element.id}>
                                                <TableCell>{element.name}</TableCell>
                                                <TableCell>
                                                    <Checkbox checked={element.complete} disabled={element.complete && element.doneToday} onCheckedChange={(checked: boolean) => completeTask(element.id, checked)} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        ) : (
                            <div className="flex flex-col w-100 justify-center align-center">
                                <div className="flex justify-center items-center">
                                    <Search className="text-center w-5 mb-2" />
                                </div>

                                <h1 className="text-center">
                                    { t("cleaning.form.quick.table.notFound") }
                                </h1>
                            </div>
                        )
                    }

                    {
                        dataSource.length ? (
                            <div className="flex justify-end">
                                <Button variant="outline" size="sm" className='bg-primary/80 hover:bg-primary/80 hover:text-white text-white align-self-end mt-3' onClick={saveQuickSummary}>
                                    <span>
                                        { t("cleaning.form.quick.submitButton") }
                                    </span>
                                </Button>
                            </div>
                        ) : null
                    }
                </div>

                <div>
                    <Table>
                        <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                            <TableRow className="bg-primary/80 hover:bg-primary/80">
                                <TableHead className="text-white">
                                    Progreso
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        
                        <TableBody className="flex align-center justify-center" style={{ padding: "2rem" }}>
                        {
                            dataSource.length ? (
                                <div style={{ width: "13rem" }}>
                                    <CircularProgressbar 
                                        className="w-1" 
                                        value={percentage} 
                                        text={Math.floor(percentage) + '%'}
                                        styles={buildStyles({
                                            strokeLinecap: 'butt',
                                            textSize: '0.8rem',
                                            textColor: accentColor,
                                            trailColor: '#ecf0f1',
                                            pathColor: accentColor
                                        })}
                                    />
                                </div>
                            ) : null
                        }
                        </TableBody>
                    </Table>
                </div>
            </div>
        </div>
    );
}
