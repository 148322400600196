import { create } from 'zustand';

type HistoryDocumentsState = {
  documents: any[];
  isOpen: boolean;
  onOpen: (documents: any[]) => void;
  onClose: () => void;
};

export const useHistoryDocuments = create<HistoryDocumentsState>((set) => ({
  documents: [],
  isOpen: false,
  onOpen: (documents) => set({ isOpen: true, documents }),
  onClose: () => set({ isOpen: false })
}));
