import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { motion } from 'framer-motion';

const KeepInEye: React.FC<KeepInEyeProps> = React.memo(({ observedRef, text, className, style, whileHover, image }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const localRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [imageError, setImageError] = useState<boolean>(false);

  const handleImageError = useCallback(() => {
    setImageError(true);
  }, []);

  useEffect(() => {
    setImageError(false);
  }, [text]);

  const handleIntersection = useCallback((entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(!entry.isIntersecting);
  }, []);

  const observerOptions = useMemo(() => ({
    root: null,
    threshold: 0.1
  }), []);

  const setupObserver = useCallback(() => {
    if (typeof IntersectionObserver === 'undefined') {
      return;
    }

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(handleIntersection, observerOptions);

    const target = observedRef?.current ?? localRef.current;

    if (target) {
      observerRef.current.observe(target);
    }
  }, [handleIntersection, observedRef, observerOptions]);

  useEffect(() => {
    setupObserver();

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [setupObserver]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setTimeout(setupObserver, 0);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [setupObserver]);

  const contentProps = useMemo(() => ({ text, image }), [text, image]);

  return (
    <div ref={localRef}>
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className={`fixed top-14 right-5 !p-0 !bg-background border-2 border-primary text-white px-4 py-2 rounded-full shadow-lg cursor-pointer ${className}`}
        style={style}
        whileHover={whileHover ?? { scale: 1.05 }}
      >
        {contentProps.image && contentProps.image.length > 0 && !imageError ? (
          <img className="h-10 w-10 object-cover rounded-full" src={contentProps.image} onError={handleImageError} />
        ) : (
          <div className="text-xs text-primary mx-2">{contentProps.text}</div>
        )}
      </motion.div>
    </div>
  );
});

export default KeepInEye;