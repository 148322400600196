import { FormField } from '@/modules/residents/domain/models/FormField';
import { create } from 'zustand';

type FormFieldsManagerState = {
  formFields: FormField[];
  isOpen: boolean;
  onOpen: (formFields: FormField[]) => void;
  onClose: () => void;
  updateFormFields: (formFields: FormField[]) => void;
};

export const useFormFieldsManager = create<FormFieldsManagerState>((set) => ({
  formFields: [],
  isOpen: false,
  onOpen: (formFields: FormField[]) => set({ isOpen: true, formFields }),
  onClose: () => set({ isOpen: false }),
  updateFormFields: (formFields: FormField[]) => set({ formFields })
}));
