import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ContactsCategoriesService = {
  getContactsCategoriesPersonal: async () => {
    let url = '/contacts/listcategoriespersonal';
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  },
  getContactsCategoriesMedical: async () => {
    let url = '/contacts/listcategoriesmedical';
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  },
  createContact: async (data: any) => {
    let url = '/contacts/create';
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  },

  updateContact: async (id, data) => {
    let url = `/contacts/update/${id}`;
    try {
      const response = await axios.put(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  },

  updatePhones: async (data: any) => {
    // Nuevo método para actualizar teléfonos
    let url = '/contacts/updatephones';
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }
};

export default ContactsCategoriesService;
