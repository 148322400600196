import React from 'react';
import { Dialogs } from '../CustomDialog/Dialogs';
import Sidebar from './sidebar/Sidebar';
import Banner from './Banner/Banner';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <div className="h-screen flex bg-muted/50">
        <Sidebar />
        <div className="relative flex-1 overflow-y-auto">
          <main className="mt-[50px] px-2 md:px-6 lg:px-12">
            <Banner />
            {children}
          </main>
        </div>
      </div>

      <Dialogs />
    </>
  );
};

export default Layout;
