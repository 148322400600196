import { es, enUS } from "date-fns/locale";
import { cn } from "@/lib/utils";

import { format } from "date-fns";

import { useEffect, useState } from "react";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar.tsx";
import { CustomInput } from "@/common/presentation/components/CustomInput/CustomInput.tsx";

import { Button } from "@/common/presentation/components/ui/button.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/common/presentation/components/ui/dialog.tsx";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/presentation/components/ui/form.tsx";
import { RadioGroup, RadioGroupItem } from "@/common/presentation/components/ui/radio-group.tsx";
import { Label } from "@radix-ui/react-dropdown-menu";

import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"

import {
  Command,
  CommandEmpty,
  CommandList,
  CommandInput,
  CommandItem,
} from "@/common/presentation/components/ui/command.tsx";

import { DialogCustomRecurrenceProp } from "../../../domain/DialogTaskEvent.ts";

import { useTranslation } from 'react-i18next';


export const DialogCustomRecurrence = ({
    open,
    onOpenChange,
    form,
    repeatMonthlyOn,
    valueRepeatEveryType,
    onChangeRepeatEveryTypes,
    isUpdate
  }: DialogCustomRecurrenceProp) => {
    
    const [isOpenPopoverRepeatEvery, setOpenPopoverRepeatEvery] = useState<boolean>(false);
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<string[]>(['monday']);
    const [isOpenPopoverrepeatMonthlyOn, setOpenPopoverrepeatMonthlyOn] = useState<boolean>(false);
    const [endsRadioType, setEndsRadioType] = useState<string>('');

    const { t } = useTranslation();

    const repeatEveryTypes: Record<string, string> = {
      "day":  t('calendar.day'),
      "week": t('calendar.week'),
      "month": t('calendar.month'),
      "year": t('calendar.year')
    }
  
    const initialsDayOfWeek: Record<string, string> = {
      "sunday":    t('calendar.initialsDayOfWeek.sunday'),
      "monday":    t('calendar.initialsDayOfWeek.monday'),
      "tuesday":   t('calendar.initialsDayOfWeek.tuesday'),
      "wednesday": t('calendar.initialsDayOfWeek.wednesday'),
      "thursday":  t('calendar.initialsDayOfWeek.thursday'),
      "friday":    t('calendar.initialsDayOfWeek.friday'),
      "saturday":  t('calendar.initialsDayOfWeek.saturday')
    };
  
    const handleSelectedDaysOfWeek = (key: string): void => {
  
      const objSelectedDays: string[] = [...selectedDaysOfWeek];
  
      setSelectedDaysOfWeek((prevSelectedDays: string[]): string[] => {
  
        const updateDays = prevSelectedDays.includes(key)
          ? objSelectedDays.filter((k: string) => k !== key)
          : [...objSelectedDays, key];
  
        if (updateDays.length === 0) {
            updateDays.push('monday')
        }
  
        form.setValue("daysOfWeek", updateDays);
  
        return updateDays;
      })
  
    }

    const onSelectMonthlyOn = (object: any)=>{
      /* No cambiar el componente "CommandItem" solo permite string como valor*/
      if(object){
          const valSelected = repeatMonthlyOn.find((obj:any) => obj.value === object.value);
          if(valSelected){
            form.setValue("repeatMonthlyOn", valSelected.value);
            setOpenPopoverrepeatMonthlyOn(false);
          }
      }     
    }
    
    
    useEffect(() => {
      if(open && isUpdate){
        setEndsRadioType(form.getValues('ends'));
      }
  }, [open, form]);
  

  
  
    return (
  
      <Dialog open={open} onOpenChange={onOpenChange} modal >
        <DialogContent className="w-[95%] block md:grid max-w-[425px] rounded-md m-0 md:w-[auto] z-[999999]">
          <DialogHeader>
            <DialogTitle className="items-center">{t('calendar.customRecurrence')}</DialogTitle>
          </DialogHeader>
  
          <div className="flex space-x-4 mt-8  md:flex-row md:items-center w-100">
            <FormField
              control={form.control}
              name="repeatEveryCount"
              render={({ field }) => (
                <FormItem className="pb-4 w-[100px] md:w-[152px] flex flex-col md:flex-row">
                  <FormLabel className="w-60 md:self-center">{t('calendar.repeatEvery')}</FormLabel>
                  <FormControl>
  
                    <CustomInput
                      onChange={field.onChange}
                      value={field.value}
                      type="number"
                      field={field}
                      required={true}
                    />
                  </FormControl>
  
                  <FormMessage />
                </FormItem>
              )}
            />
  
            <FormField
              control={form.control}
              name="repeatEveryType"
              render={({ field }) => (
                <FormItem className="pb-[8px] mt-3 md:mt-0 w-full md:w-[60px] md:pl-2 flex self-center">
                  <Popover open={isOpenPopoverRepeatEvery} onOpenChange={setOpenPopoverRepeatEvery}>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "w-full md:w-[200px] justify-between text-muted-foreground"
                          )}
                        >
  
                          {field.value ? repeatEveryTypes[field.value] : t('calendar.selectOption')}
  
                          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[200px] p-0 z-[9999999]">
  
                      <Command>
                        <CommandInput placeholder="Search option..." />
                        <CommandEmpty>{t('calendar.noOptionFound')}.</CommandEmpty>
  
                        <CommandList>
                          {Object.keys(repeatEveryTypes)
                            .map((key: string) => (
  
                              <CommandItem
                                key={key}
                                value={key}
                                onSelect={(currentValue: any) => {
                                  form.setValue("repeatEveryType", currentValue)
                                  onChangeRepeatEveryTypes(currentValue)
                                  setOpenPopoverRepeatEvery(false)
                                }}
                              >
  
                                {repeatEveryTypes[key]}
                                <CheckIcon
                                  className={cn(
                                    "ml-auto h-4 w-4",
                                    field.value == key ? "opacity-100" : "opacity-0"
                                  )}
                                />
                              </CommandItem>
                            ))}
  
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
  
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
  
  
          {valueRepeatEveryType === "week"
            && (
  
              <FormField
                control={form.control}
                name="daysOfWeek"
                render={() => (
                  <FormItem className="pb-[8px] w-auto pl-2">
                    <FormLabel>{t('calendar.repeatOn')}</FormLabel>
                    <FormControl>
                      <div className="flex gap-2">
  
                        {Object.keys(initialsDayOfWeek).map(key => (
  
                          <button
                            key={key}
                            onClick={() => handleSelectedDaysOfWeek(key)}
                            className={`rounded-full p-2 text-xs w-[22px] h-[22px] flex items-center justify-center 
                            ${selectedDaysOfWeek.find((k: string) => k === key)
                                ? 'bg-primary text-white'
                                : ''}
                            `}
                          >
                            {initialsDayOfWeek[key]}
                          </button>
  
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
  
  
          {valueRepeatEveryType === "month"
            && (
  
              <div className="w-full">
                <FormField
                  control={form.control}
                  name="repeatMonthlyOn"
                  render={({ field }) => (
                    <FormItem className="pb-[8px] w-full md:w-[250px] md:pl-2 flex">
                      <Popover open={isOpenPopoverrepeatMonthlyOn} onOpenChange={setOpenPopoverrepeatMonthlyOn}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "w-full md:w-[350px] justify-between",
                                "text-muted-foreground"
                              )}
                            >
                              { field.value? 
                              (
                                repeatMonthlyOn.find((obj:any) => 
                                  JSON.stringify(obj.value) 
                                    == 
                                  (typeof field.value === 'string'? field.value : JSON.stringify(field.value) ) )
                              )?.label : "Select Option" }
  
                              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-[250px] p-0 z-[9999999]">
  
                          <Command>
                            <CommandInput placeholder="Search option..." />
                            <CommandEmpty>{t('calendar.noOptionFound')}.</CommandEmpty>
  
                            <CommandList>
                              {repeatMonthlyOn
                                .map((object: any, key: number) => (
  
                                  <CommandItem
                                    key={key}
                                    value={JSON.stringify(object.value)}
                                    onSelect={()=> onSelectMonthlyOn(object)}
                                  >
                                    {repeatMonthlyOn[key].label}
                                    <CheckIcon
                                      className={cn(
                                        "ml-auto h-4 w-4",
                                        field.value == object.value ? "opacity-100" : "opacity-0"
                                      )}
                                    />
                                  </CommandItem>
                                ))}
  
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
  
                      <FormMessage />
                    </FormItem>
                  )}
                />
  
              </div>
  
            )}
  
          <div className="flex mb-7 ">
            <FormField
              control={form.control}
              name="ends"
              render={({ field }) => (
                <FormItem className=" pb-[83px] pr-[30px] md:pr-[83px] w-auto pl-2 mt-[20px] h-[145px]">
                  <FormLabel>{t('calendar.ends')}</FormLabel>
                  <FormControl>
                    <div className="flex ">
                      <RadioGroup
                        onValueChange={(val: string = '') => {
                          setEndsRadioType(val);
                          field.onChange(val);
                        }}
                        defaultValue={field.value}
                        className="gap-[20px]"
                      >
                        <div className="flex items-center space-x-2 ">
                          <RadioGroupItem value="never" id="never" />
                          <Label>{t('calendar.never')}</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="on" id="on" />
                          <Label >{t('calendar.on')}</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="after" id="after" />
                          <Label >{t('calendar.after')}</Label>
                        </div>
                      </RadioGroup>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col self-end gap-2">
  
              <FormField
                control={form.control}
                name="onDate"
                render={({ field }) => (
                  <FormItem>
                    <Popover>
                      <PopoverTrigger asChild >
                        <FormControl>
  
                          <Button
                            variant={"outline"}
                            className={cn("w-full justify-start font-normal")}
                            disabled={endsRadioType == 'on' ? false : true}
                          >
                            {field.value
                              ? (
                                format(new Date(field.value), t('dateFormats.dateFormat'))
                                )
                              : (
                                format(new Date(), t('dateFormats.dateFormat'))
                              )
                            }
                          </Button>
  
  
                        </FormControl>
                      </PopoverTrigger>
  
                      <PopoverContent align="start" avoidCollisions className="z-[1000000]">
                        <CustomCalendar
                          onChange={(date:any) => {
                            field.onChange(date);
                          }}
                          selectedDate={field.value}
                          value={field.value}
                          classMonthYearSelect="z-[1000000] h-[300px]"
                          MonthAndYearPicker
                        />
                 
  
                      </PopoverContent>
  
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
  
              <FormField
                control={form.control}
                name="afterOccurrences"
                render={({ field }) => (
                  <FormItem key={field.value}>   
                    <CustomInput
                      key={JSON.stringify([field.value])}
                      onChange={(val:string) => {
                        field.onChange(parseInt(val));
                      }}
                      value={parseInt(field.value)}
                      type="number"
                      field={field}
                      disabled={endsRadioType == 'after' ? false : true}
                    />
                      
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
  
          <DialogFooter>
            <Button type="button" onClick={onOpenChange}>
              {t('calendar.close')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }