import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const paymentsService = {
  getPaymentMethods: async () => {
    let url = `/paymentMethod/getList`;
    try {
      const response = await axios.get(url);
      return response;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  setPaymentAsDefault: async (paymetMethodId: string) => {
    let url = `/paymentMethod/setDefault`;
    try {
      const response = await axios.post(url, { payment_method_id: paymetMethodId });
      return response;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  createPaymentMethod: async (cardEncryptedInfo: string) => {
    let url = `/paymentMethod`;
    try {
      const response = await axios.post(url, { card_information: cardEncryptedInfo });
      return response;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  }
};

export default paymentsService;
