import { Route, Routes } from 'react-router-dom';
import DashboardPage from '../modules/dashboard/presentation/pages/DashboardPage';

const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardPage />} />
    </Routes>
  );
};

export default DashboardRouter;
