import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { SquareActivity } from 'lucide-react';

interface Props {
  className?: string;
}

export const DiagnosisCard = ({ className }: Props) => {
  return (
    <>
      <div className={className}>
        <CustomAccordion
          titleComponent={
            <div className="flex items-center justify-start text-primary">
              <SquareActivity className="size-5 mr-2" />
              <h1 className="font-bold">Diagnosis</h1>
            </div>
          }
          openOption={false}
          defaultOpen
        >
          <div className="grid grid-cols-2 gap-4">
            <div className="flex justify-start">
              <label className="" htmlFor="">
                Primary Diagnosis:
              </label>
            </div>
            <div>
              <span>Dementia</span>
            </div>
            <div className="flex justify-start">
              <label className="" htmlFor="">
                Secondary Diagnosis:
              </label>
            </div>
            <div>
              <span>pollen</span>
            </div>
            <div className="flex justify-start">
              <label className="" htmlFor="">
                Allergies:
              </label>
            </div>
            <div>
              <span>Nonambulatory</span>
            </div>
            <div className="flex justify-start">
              <label className="" htmlFor="">
                Ambulatory:
              </label>
            </div>
            <div>
              <span>Mild cognitive</span>
            </div>
            <div className="flex justify-start">
              <label className="" htmlFor="">
                Mental Status:
              </label>
            </div>
            <div>
              <span>Mild cognitive</span>
            </div>

            <div className="flex justify-start">
              <div className="flex flex-wrap gap-2"></div>
            </div>
          </div>
        </CustomAccordion>
      </div>
    </>
  );
};
