import { useTheme } from '@/common/infrastructure/providers/ThemeProvider';
import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import Chrome from '@uiw/react-color-chrome';
import { GithubPlacement } from '@uiw/react-color-github';
import { Loader, MoonIcon, Palette, RotateCcw, SunIcon } from 'lucide-react';
import { useState } from 'react';

import { Dialog, DialogContent, DialogTrigger } from '@/common/presentation/components/ui/dialog';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { updateAccentColor } from '@/common/presentation/slices/configurationSlice';
import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../../../ui/use-toast';

export const ThemeToggle = () => {
  const { setTheme } = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);

  const { accentColor } = useConfigurationsProvider();

  const [hex, setHex] = useState(accentColor);
  const { isLoading } = useSelector((state: RootState) => state.configurations);

  const handleChange = async () => {
    const hexColor = hex.toUpperCase();
    const response = await dispatch(updateAccentColor({ accent_color: hexColor }));

    if (updateAccentColor.rejected.match(response)) {
      toast({
        title: 'Error',
        description: 'Error updating accent color'
      });
    }

    if (updateAccentColor.fulfilled.match(response)) {
      setOpen(false);
      toast({
        title: 'Success',
        description: 'Accent color updated'
      });
    }
  };

  const resetColor = async () => {
    setHex('#4F81EE');
    const response = await dispatch(updateAccentColor({ accent_color: '#4F81EE' }));

    if (updateAccentColor.rejected.match(response)) {
      toast({
        title: 'Error',
        description: 'Error updating accent color'
      });
    }

    if (updateAccentColor.fulfilled.match(response)) {
      setOpen(false);
      toast({
        title: 'Success',
        description: 'Accent color reset to default'
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="h-8 w-8  text-white hover:bg-primary/50 hover:text-white">
            <SunIcon className="h-4 w-4 dark:hidden" />
            <MoonIcon className="h-4 w-4 hidden dark:block" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="center">
          <DropdownMenuItem className="py-2 hover:bg-primary/5" onClick={() => setTheme('light')}>
            Light
          </DropdownMenuItem>
          <DropdownMenuItem className="py-2 hover:bg-primary/5" onClick={() => setTheme('dark')}>
            Dark
          </DropdownMenuItem>
          <DropdownMenuItem className="py-2 hover:bg-primary/5" onClick={() => setTheme('system')}>
            System
          </DropdownMenuItem>
          <hr className="p-1 mt-2" />
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Button variant="ghost" className="flex flex-row items-center justify-start gap-2 hover:bg-primary/5">
                <Palette className="h-4 w-4" />
                Change Accent Color
              </Button>
            </DialogTrigger>
            <DialogContent className="w-auto z-[99999]">
              <Chrome
                color={hex}
                placement={GithubPlacement.Right}
                onChange={(color) => {
                  setHex(color.hexa);
                }}
              />
              {accentColor !== '#4F81EE' && (
                <Button variant="outline" disabled={isLoading} onClick={resetColor}>
                  {isLoading ? (
                    <Loader className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    <RotateCcw className="mr-2 h-4 w-4" />
                  )}
                  Reset
                </Button>
              )}
              <Button
                variant="outline"
                className=" flex flex-row items-center justify-center gap-2"
                onClick={handleChange}
                disabled={isLoading}
              >
                {isLoading ? <Loader className="mr-2 h-4 w-4 animate-spin" /> : <Palette />}
                Set Accent Color
              </Button>
            </DialogContent>
          </Dialog>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
