import React from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Pencil, Trash2, BookCopy, Ellipsis, Copy, FolderArchive } from 'lucide-react';
import { Row } from '@tanstack/react-table';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  handleEdit: (id: string) => void;
  handleCopy: (id: string) => void;
  handleAudit: (id: string) => void;
}

const RenderActionsColumn: React.FC<RenderActionsColumnProps> = ({ rowData, handleEdit, handleCopy, handleAudit }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
          <Ellipsis className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuItem onClick={() => handleEdit(rowData.id)}>
          <Pencil className="h-4 w-4 mr-2" /> Edit
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleCopy(rowData.id)}>
          <BookCopy className="h-4 w-4 mr-2" /> Copy
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleAudit(rowData.id)}>
          <FolderArchive className="h-4 w-4 mr-2" /> Audit
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RenderActionsColumn;
