import { Button } from '@/common/presentation/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';
import { Label } from '@/common/presentation/components/ui/label';
import { NoteI } from '@/modules/notes/domain/note.domain';
import { setNoteId } from '@/modules/notes/presentation/slices/NoteSlice';
import { RootState } from '@/store/store';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { UserHelper } from '@/utils/helpers/user.helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface NotesModalI {
  isOpen: boolean;
  note: NoteI | null;
  location?: string;
  setIsOpen: (isOpen: boolean) => void;
}

interface ResidentI {
  id: string;
  first_name: string;
  last_name: string;
}

const NotesModal: React.FC<NotesModalI> = ({ isOpen, note, setIsOpen, location = 'all-locations' }) => {
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents) ?? [];
  const staffList = useSelector((state: RootState) => state.staff.allStaff.staff) ?? [];
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const residentNameById = (residentId: string | null): string => {
    if (residentId === null) return t('dashboard.everyone');
    const resident = residents.find((resident: ResidentI) => resident.id === residentId) ?? null;
    return `${resident?.first_name} ${resident?.last_name}`;
  };

  const goToNote = async () => {
    await dispatch(setNoteId(note?.id))
    navigate(`/notes?l=${location}`)
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-11/12 lg:w-1/3">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">{ t('dashboard.quickNoteView') }</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-1 justify-start items-start text-start">
          <div className="flex flex-col">
            <Label className="text-base font-bold">{ t('dashboard.relatedTo') }</Label>
            <Label className="text-base font-normal">{residentNameById(note?.resident_id ?? null)}</Label>
          </div>
          {
            note?.created_at && (
                <div className="flex flex-col">
                <Label className="text-base font-bold">{ t('dashboard.dateCreated') }</Label>
                <Label className="text-base font-normal">{formatDate(note?.created_at ?? '')}</Label>
              </div>
            )
          }
          {note?.created_by && (
            <div className="flex flex-col">
              <Label className="text-base font-bold">{ t('dashboard.createdBy') }</Label>
              <Label className="text-base font-normal"> { UserHelper.getStaffName(note.created_by, staffList) }</Label>
            </div>
          )}
          <div className="flex flex-col">
            <Label className="text-base font-bold">{ t('dashboard.note') }</Label>
            <Label className="text-base font-normal">{note?.content}</Label>
          </div>
        </div>
        <DialogFooter>
          <Button className="dark:text-white" onClick={goToNote}>{ t('dashboard.viewNote') }</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default NotesModal;
