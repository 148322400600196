import { Button } from "@/common/presentation/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { IScreeningQuestion } from "@/modules/visitors/infrastructure/interfaces/ScreeningQuestionInterface";
import { RootState } from "@/store/store";
import { ThumbsDown, ThumbsUp } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function AssessmentQuestion() {
    const [questions, setQuestions] = useState<IScreeningQuestion[]>([]);
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");

    useEffect(() => {
        setQuestions(
            [
                {
                    id: "4aba9fe6-784d-4b41-920e-2ca597260f74",
                    data: {
                        name: "Have you come into close contact (within 6 feet) with someone who has a laboratory confirmed COVID-19 diagnosis in the past 10 days?",
                        status: "active",
                        who: {
                            id: "6c402ebc-dd97-4eaa-89e8-e15e2a5da1c1",
                            first_name: "Laura",
                            last_name: "Mejiaz"
                        }
                    },
                    location: {
                        id: "d3885e33-4953-49e8-8dff-c66b84ac466b",
                        name: "Caring Data Home"
                    },
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                },
                {
                    id: "d51efa9c-2683-4324-9e94-11678a308d26",
                    data: {
                        name: "Are you currently presenting any of the following symptoms? Fever of chills? Cough? Sore throat? Shortness of breath or difficulty breathing? Body aches or headache? New loss of taste or smell?",
                        status: "active",
                        who: {
                            id: "6c402ebc-dd97-4eaa-89e8-e15e2a5da1c1",
                            first_name: "Laura",
                            last_name: "Mejiaz"
                        }
                    },
                    location: {
                        id: "d3885e33-4953-49e8-8dff-c66b84ac466b",
                        name: "Caring Data Home"
                    },
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                },
                {
                    id: "6551e475-7bb7-4cc6-897f-d8918cd8f8d6",
                    data: {
                        name: "Have you had a positive COVID-19 test for active virus in the past 10 days?",
                        status: "active",
                        who: {
                            id: "6c402ebc-dd97-4eaa-89e8-e15e2a5da1c1",
                            first_name: "Laura",
                            last_name: "Mejiaz"
                        }
                    },
                    location: {
                        id: "d3885e33-4953-49e8-8dff-c66b84ac466b",
                        name: "Caring Data Home"
                    },
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                },
                {
                    id: "c28eee40-5ae0-4455-8fdb-f142822a5624",
                    data: {
                        name: "Within the past 10 days, has a public health or medical professional told you to self-monitor, self-isolate, or self-quarantine because of concerns about COVID-10  infection?",
                        status: "active",
                        who: {
                            id: "6c402ebc-dd97-4eaa-89e8-e15e2a5da1c1",
                            first_name: "Laura",
                            last_name: "Mejiaz"
                        }
                    },
                    location: {
                        id: "d3885e33-4953-49e8-8dff-c66b84ac466b",
                        name: "Caring Data Home"
                    },
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                },
                {
                    id: "00973744-61ff-4ac0-9c86-a0008f8f7e97",
                    data: {
                        name: "Within the past 10 days, have you been to a facility or place that has any positive COVID cases in staff or patients/residents?",
                        status: "active",
                        who: {
                            id: "6c402ebc-dd97-4eaa-89e8-e15e2a5da1c1",
                            first_name: "Laura",
                            last_name: "Mejiaz"
                        }
                    },
                    location: {
                        id: "d3885e33-4953-49e8-8dff-c66b84ac466b",
                        name: "Caring Data Home"
                    },
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                },
                {
                    id: "d88bd09e-8520-435b-abe5-22d56cb66880",
                    data: {
                        name: "Anyone in your home has COVID-19 symptoms or tested positive in the last 10 days?",
                        status: "active",
                        who: {
                            id: "6c402ebc-dd97-4eaa-89e8-e15e2a5da1c1",
                            first_name: "Laura",
                            last_name: "Mejiaz"
                        }
                    },
                    location: {
                        id: "d3885e33-4953-49e8-8dff-c66b84ac466b",
                        name: "Caring Data Home"
                    },
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                }
            ],
        );
    }, []);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const yes = (id: string) => {
        next(id);
    }

    const no = (id: string) => {
        next(id);
    }

    const next = (id: string) => {
        if(questions.length) {
            setQuestions(
                questions.filter((item) => item.id != id)
            );
        }
    }

    return (
        <div>
            {
                questions.length ? (
                    <Card className="m-5 border-t-4 border-t-primary/80" style={{ width: "60rem", justifySelf: "center", marginTop: "3rem" }}>
                            <CardHeader className="mb-5">
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                    { 
                                        questions.length && questions[0] && questions[0].data.name 
                                    }
                                </CardTitle>
                            </CardHeader>

                            <CardContent>
                                <div className="flex justify-center w-100 mt-5">
                                    {
                                        questions.length && questions[0] && (
                                            <>
                                                <Button onClick={() => yes(questions[0].id)} className="mr-5" variant="outline" size="icon">
                                                    <ThumbsUp style={{ color: "gray" }}/>
                                                </Button>

                                                <Button onClick={() => no(questions[0].id)} variant="outline" size="icon">
                                                    <ThumbsDown style={{ color: "gray" }} />
                                                </Button>
                                            </>
                                        )
                                    }
                                </div>
                            </CardContent>
                        </Card>
                ) : (
                    <div></div>
                )
            }
        </div>
    );
}
