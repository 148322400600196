//import axios from '@/common/services/axios';
import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ContactsService = {
    getContacts: async () => {
        let url = '/contacts';
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    getContactsByResident: async (residentId: string) => {
        let url = `/contacts/${residentId}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    getResidentById: async (residentId: string) => {
        let url = `/contacts/detailresident/${residentId}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    getContactById: async (id: string) => {
        let url = `/contacts/detail/${id}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    createPhones: async (data: any) => {
        let url = '/contacts/createphone';
        try {
            const response = await axios.post(url, data);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    getListPhonesById: async (id: string) => {
        let url = `/contacts/listphones/${id}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    updatePhones: async (data: any) => {
        let url = '/contacts/updatephones';
        try {
            const response = await axios.post(url, data);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    deleteContact: async (contactId: string) => {
        try {
            const response = await axios.delete(`/contacts/deletecontact/${contactId}`);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    deletePhone: async (phoneId: string) => {
        try {
            const response = await axios.delete(`/contacts/deletephone/${phoneId}`);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    deletePhonesByContactId: async (contactId: string) => {
        try {
            const response = await axios.delete(`/contacts/deletePhonesByContactId/${contactId}`);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export default ContactsService;