export enum DocumentCategory {
  ADMISSION = 'Admission',
  LICENSING_DOCUMENTS = 'Licensing Documents',
  MEDICAL_DOCUMENTS = 'Medical Documents',
  HOME_HEALTH_DOCUMENTS = 'Home Health Documents',
  LIC624_DOCUMENTS = 'Lic624 Documents',
  DR_NOTES = 'Drs Notes',
  LEGAL_DOCUMENTS = 'Legal Documents',
  COVID_19 = 'Covid19',
  FACILITY_FORMS = 'Facility Forms',
  ELECTRONIC_SERVICE_PLAN = 'Electronic Service Plan',
  ADMISSION_AGREEMENT = 'Admission Agreement COLAH',
  WEEKLY_TRAINING_CATEGORY = 'Weekly Training Category'
}

// const documents = {
//   'admission': [
//     {
//       name: 'Admission Letter',
//       value: 'admission_letter',
//       order: 1
//     },
//     {
//       name: 'Admission Agreement',
//       value: 'admission_agreement',
//       order: 2
//     },
//     {
//       name: 'Admission Certificate',
//       value: 'admission_certificate',
//       order: 3
//     },
//   ],
//   'licensing_documents': [
//     {
//       name: 'Licensing Letter',
//       value: 'licensing_letter',
//       order: 1
//     },
//     {
//       name: 'Licensing Agreement',
//       value: 'licensing_agreement',
//       order: 2
//     },
//     {
//       name: 'Licensing Certificate',
//       value: 'licensing_certificate',
//       order: 3
//     },
//   ],
//   'medical_documents': [
//     {
//       name: 'Medical Letter',
//       value: 'medical_letter',
//       order: 1
//     },
//     {
//       name: 'Medical Agreement',
//       value: 'medical_agreement',
//       order: 2
//     },
//     {
//       name: 'Medical Certificate',
//       value: 'medical_certificate',
//       order: 3
//     },
//   ],
// }

export const categories = [
  {
    name: DocumentCategory.ADMISSION,
    value: 'admission',
    order: 1
  },
  {
    name: DocumentCategory.LICENSING_DOCUMENTS,
    value: 'licensing_documents',
    order: 2
  },
  {
    name: DocumentCategory.MEDICAL_DOCUMENTS,
    value: 'medical_documents',
    order: 3
  },
  {
    name: DocumentCategory.HOME_HEALTH_DOCUMENTS,
    value: 'home_health_documents',
    order: 4
  },
  {
    name: DocumentCategory.LIC624_DOCUMENTS,
    value: 'lic624_documents',
    order: 5
  },
  {
    name: DocumentCategory.DR_NOTES,
    value: 'dr_notes',
    order: 6
  },
  {
    name: DocumentCategory.LEGAL_DOCUMENTS,
    value: 'legal_documents',
    order: 7
  },
  {
    name: DocumentCategory.COVID_19,
    value: 'covid_19',
    order: 8
  },
  {
    name: DocumentCategory.FACILITY_FORMS,
    value: 'facility_forms',
    order: 9
  },
  {
    name: DocumentCategory.ELECTRONIC_SERVICE_PLAN,
    value: 'electronic_service_plan',
    order: 10
  },
  {
    name: DocumentCategory.ADMISSION_AGREEMENT,
    value: 'admission_agreement',
    order: 11
  },
  {
    name: DocumentCategory.WEEKLY_TRAINING_CATEGORY,
    value: 'weekly_training_category',
    order: 12
  }
];
