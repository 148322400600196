import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { OverviewTableActions } from './OverviewTableActions';

export const overviewRoutineColumns: ColumnDef<any>[] = [
  {
    id: 'exception_date',
    header: 'Exception Date',
    cell: ({ row }) => {
      return format(new Date(row?.original?.emar_medication_exception?.created_at), 'MM/dd/yyyy hh:mm a');
    }
  },
  {
    header: 'Medication',
    accessorKey: 'medication.name',
    cell: ({ row }) => {
      return row?.original?.medication?.name;
    }
  },
  {
    id: 'resident',
    header: 'Resident',
    cell: ({ row }) => {
      return `${row?.original?.resident?.first_name} ${row?.original?.resident?.last_name}`;
    }
  },
  {
    id: 'staff',
    header: 'Staff',
    cell: ({ row }) => {
      return `${row?.original?.emar_medication_exception?.created_by?.first_name} ${row?.original?.emar_medication_exception?.created_by?.last_name}`;
    }
  },
  {
    id: 'actions',
    cell: ({ row }) => <OverviewTableActions data={row.original} />
  }
];
