import { Calendar } from '@/common/presentation/components/ui/calendar';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { MonthAndYearSelector } from './MonthAndYearSelector';
import { TimePicker } from './TimePicker';

interface CustomCalendarProps {
  onChange?: (date: Date) => void;
  value?: Date | null | undefined;
  MonthAndYearPicker?: boolean;
  timePicker?: boolean;
  baseSelectorYear?: number;
  classMonthYearSelect?: string;
  selectedDate?: Date;
}

export const CustomCalendar = ({
  onChange,
  MonthAndYearPicker = false,
  timePicker = false,
  baseSelectorYear = new Date().getFullYear(),
  selectedDate = undefined,
  classMonthYearSelect = ''
}: CustomCalendarProps) => {
  const [date, setDate] = useState<Date | undefined>(selectedDate || new Date());
  const [time, setTime] = useState<string>(selectedDate ? format(selectedDate, 'HH:mm') : '00:00');

  useEffect(() => {
    if (date) {
      setTime(format(date, 'HH:mm'));
    }
  }, [date]);

  const mergeDateAndTime = (date: Date | undefined, time: string) => {
    if (!date) return;
    const [hours, minutes] = time.split(':').map(Number);
    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds(0);
    onChange?.(newDate);
  };

  const handleDateChange = (selectedDate: Date | undefined) => {
    setDate(!selectedDate ? new Date() : selectedDate);
    mergeDateAndTime(!selectedDate ? new Date() : selectedDate, time);
  };

  const handleTimeChange = (newTime: string) => {
    setTime(newTime);
    mergeDateAndTime(date, newTime);
  };

  return (
    <div className="flex flex-col items-center w-full">
      {MonthAndYearPicker && (
        <MonthAndYearSelector
          date={date}
          onChange={setDate}
          baseSelectorYear={baseSelectorYear}
          className={classMonthYearSelect}
        />
      )}

      <div className="flex flex-col sm:flex-row justify-center items-start w-full space-y-4 sm:space-y-0 sm:space-x-4">
        <Calendar
          mode="single"
          selected={date}
          today={date}
          onSelect={handleDateChange}
          onMonthChange={setDate}
          defaultMonth={date}
          month={date}
          initialFocus
        />

        {timePicker && (
          <div className="w-full sm:w-auto">
            <TimePicker onChange={handleTimeChange} value={time} />
          </div>
        )}
      </div>
    </div>
  );
};