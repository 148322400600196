import { SingleImageDropzone } from '@/common/presentation/components/ImageDropZone/single-image-dropzone';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/common/presentation/components/ui/dialog';
import { useProfileImage } from '../../infrastructure/hooks/use-profile-image';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import * as residentsFormActions from '@/modules/residents/presentation/slices/residentsForm';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';

export const ProfileImageDialog = () => {
  const { isOpen, onClose } = useProfileImage();
  const [file, setFile] = useState<File>();
  const [isSubmitting, setIsSubmitting] = useState(false); // [1
  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const dispatch = useDispatch<AppDispatch>();

  const onChange = async (file?: File) => {
    if (file) {
      setIsSubmitting(true);
      setFile(file);
      const response = await dispatch(
        residentsFormActions.uploadProfileImage({
          resident_id: resident.id,
          file
        })
      );

      if (response.payload) {
        dispatch(residentActions.setResident(response.payload.resident));
        setFile(undefined);
        setIsSubmitting(false);
        onClose();

        toast({
          description: 'Profile picture uploaded successfully'
        });
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <h2 className="text-center text-lg font-semibold">Resident Profile Picture</h2>
        </DialogHeader>
        <SingleImageDropzone className="w-full outline-none" disabled={isSubmitting} value={file} onChange={onChange} />
        <DialogFooter>
          <p className="text-xs">Maximum file size is 5MB. Supported file types are .jpg, .jpeg, .png, .tiff, .webp.</p>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
