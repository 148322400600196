import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { useNavigate } from 'react-router-dom';
import { OverViewTable } from '@/modules/emar/presentation/components/tables/OverviewTable';
import { useEffect } from 'react';
import useRouteParams from '@/common/hooks/RouteParamsHook';

export const EmarDashboardPage = () => {
  const navigate = useNavigate();
  const { params, setParam } = useRouteParams();

  const onLocationSelect = (locationId: string) => {
    const location = locationId !== 'all-locations' ? locationId : 'all-locations';
    const emar = params.e === 'routine' ? 'routine' : 'prn';
    const url = `/emar/${location}?e=${emar}`;
    navigate(url);
  };

  useEffect(() => {
    if (!params.e) {
      setParam('e', 'routine');
    }
  }, [params, setParam]);

  return (
    <div className="pt-4">
      <LocationSelector selections={false} setUrlParams={false} onSelect={onLocationSelect} />
      <OverViewTable />
    </div>
  );
};
