import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { NavigationBar } from '@/common/presentation/components/navigations/NavigationBar';
import { AppDispatch, RootState } from '@/store/store';
import { ArrowLeft } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavigationItems } from '../NavigationItems';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { Button } from '@/common/presentation/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { cn } from '@/lib/utils';
import FormDestruction from './FormDestruction/FormDestruction';
import ReportDestruction from './ReportDestruction/ReportDestruction';
import { useState } from 'react';

const DestructionsPage = () => {
  const navigate = useNavigate();
  const { location, status: locationStatus } = useSelector((state: RootState) => state.locations.location);
  const { params, setParam } = useRouteParams();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTab, setSelectedTab] = useState<string>('form');

  const handleBackClick = () => {
    navigate('/medications');
  };

  const onResidentSelect = (residentId: string) => {
    if (residentId === 'all-residents') {
      dispatch(residentActions.resetResident());
    }
  };

  const onSelectDestruction = (e: string) => {
    setParam('t', e);
    setSelectedTab(e);
  };

  return locationStatus === 'loading' ? (
    <SplashScreen />
  ) : (
    <div className="gap-4 my-4">
      <div>
        <div className="flex flex-col gap-4 my-4">
          <div className="flex items-center justify-between">
            <div className="flex gap-2">
              <div className="font-semibold text-2xl">
                <Button variant={'ghost'} onClick={handleBackClick}>
                  <>
                    <ArrowLeft className="mr-2 h-4 w-4" />
                    Back
                  </>
                </Button>
                {'Destruction Medication'}{' '}
              </div>
            </div>
            <Tabs
              defaultValue={selectedTab}
              onValueChange={(e) => {
                onSelectDestruction(e);
              }}
              className="flex gap-2"
            >
              <TabsList className="flex">
                <TabsTrigger
                  value="form"
                  className={cn(
                    'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all disabled:pointer-events-none disabled:opacity-50 bg-primary text-white',
                    selectedTab === 'form' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-800'
                  )}
                >
                  Form
                </TabsTrigger>
                <TabsTrigger
                  value="report"
                  className={cn(
                    'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all disabled:pointer-events-none disabled:opacity-50 bg-primary text-white',
                    selectedTab === 'report' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-800'
                  )}
                >
                  Report
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </div>
        <div className="flex flex-col gap-4 my-4">
          <ResidentSelector onSelect={onResidentSelect} locationId={undefined} residentId={params.r} />
          {selectedTab === 'form' ? <FormDestruction /> : <ReportDestruction />}
        </div>
      </div>
    </div>
  );
};

export default DestructionsPage;
