import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import React, { useEffect, useState } from 'react';
import columnsReportMedications from './columnsReportMedications';
import { getDestroyedMedications } from '../../../slices/medicationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import RenderActionsColumn from './renderActionsColumn';
import useRouteParams from '@/common/hooks/RouteParamsHook';

const ReportDestruction = () => {
  const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });
  const dispatch = useDispatch();
  const { destroyedMedications } = useSelector((state: RootState) => state.medications);
  const { params, setParam } = useRouteParams();

  const handleRowAction = (rowData) => {
    console.log('Se hizo clic en la fila:', rowData);
  };

  const columnsWithActions = columnsReportMedications.map((column) => {
    if (column.key === 'actions') {
      return {
        ...column,
        render: (rowData) => <RenderActionsColumn rowData={rowData} onRowAction={handleRowAction} />
      };
    }
    return column;
  });

  const [columns, setColumns] = useState(columnsWithActions);

  useEffect(() => {
    if (params.r) {
      dispatch(getDestroyedMedications(params.r));
    }
  }, [dispatch, params.r]);

  const handleSort = (columnKey) => {
    setSorting((prevSorting) => ({
      key: columnKey,
      direction: prevSorting.direction === 'ascending' ? 'descending' : 'ascending'
    }));
  };

  return (
    <div className="flex flex-col gap-4 my-4">
      <TableCustomVirtoso
        data={[]}
        columns={columns}
        renderCellContent={(index, column, data) => data[index][column.key]}
        additionalButtons={[]}
        onSort={handleSort}
      />
    </div>
  );
};

export default ReportDestruction;
