import { useTranslation } from 'react-i18next';
import renderActionsColumn from './renderActionsColumn';

const useColumnsResidentMedications = (statusFilter) => {
  const { t } = useTranslation();

  const columnsResidentMedications = [
    {
      key: 'medication_name',
      id: 'medication_name',
      labelTranslationKey: 'medications.columnsResidentMedications.medication',
      value: 'medication_name',
      visible: true,
      sortable: true,
      static: true,
      width: '20%'
    },
    {
      key: 'medication_form',
      id: 'medication_form',
      labelTranslationKey: 'medications.columnsResidentMedications.form-strength',
      value: 'medication_form',
      visible: true,
      sortable: true,
      width: '5%'
    },
    {
      key: 'resident_full_name',
      id: 'resident_full_name',
      labelTranslationKey: 'medications.columnsResidentMedications.resident',
      value: 'resident_full_name',
      link: true,
      visible: true,
      sortable: true,
      width: '5%'
    },
    {
      key: 'time',
      id: 'time',
      labelTranslationKey: 'medications.columnsResidentMedications.time',
      value: 'time',
      visible: true,
      sortable: true,
      width: '10%'
    },
    {
      key: 'rx',
      id: 'rx',
      labelTranslationKey: 'medications.columnsResidentMedications.rx',
      value: 'rx',
      visible: true,
      sortable: true,
      width: '10%'
    },
    {
      key: 'type_medication_name',
      id: 'type_medication_name',
      labelTranslationKey: 'medications.columnsResidentMedications.type',
      value: 'type_medication_name',
      visible: true,
      static: true,
      sortable: true,
      width: '5%'
    },
    {
      key: 'current_quantity',
      id: 'current_quantity',
      labelTranslationKey: 'medications.columnsResidentMedications.quantity',
      value: 'current_quantity',
      visible: true,
      sortable: true,
      width: '2%'
    },
    {
      key: 'filleDate',
      id: 'filleDate',
      labelTranslationKey: 'medications.columnsResidentMedications.filleDate',
      value: 'filleDate',
      visible: false,
      sortable: true,
      width: '10%'
    },
    {
      key: 'actions',
      labelTranslationKey: 'medications.columnsResidentMedications.actions',
      render: renderActionsColumn,
      visible: true,
      static: true,
      width: '5%'
    }
  ];

  if (statusFilter) {
    const actionsIndex = columnsResidentMedications.findIndex((column) => column.key === 'actions');
    columnsResidentMedications.splice(actionsIndex, 0, {
      key: 'status',
      id: 'status',
      labelTranslationKey: 'medications.columnsResidentMedications.status',
      value: 'status',
      visible: true,
      sortable: true,
      width: '5%',
      render: (rowData) => {
        console.log('Row Data:', rowData.status); // Añade este log para depurar
        return rowData.status === true
          ? t('medications.columnsResidentMedications.active')
          : t('medications.columnsResidentMedications.inactive');
      }
    });
  }

  const columnsWithTranslation = columnsResidentMedications.map((column) => ({
    ...column,
    label: t(column.labelTranslationKey || column.key)
  }));

  return columnsWithTranslation;
};

export default useColumnsResidentMedications;
