import React, { FC, useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomizations, getTemperature, getTemperatureType, saveTemperature } from '../../slices/TrackerSlice';
import { AppDispatch, RootState } from '@/store/store';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Input } from '@/common/presentation/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { cn } from '@/lib/utils';
import { columns } from '../../pages/columns';
import { Button } from '@/common/presentation/components/ui/button';

const FormTemperature = forwardRef((props, ref) => {
  const dispatch = useDispatch<AppDispatch>();
  const { temperatureType, status } = useSelector((state: RootState) => state.tracker);
  const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>({});
  const [data, setData] = useState<any[]>([
    { hour: '', column_1: '', column_2: '', column_3: '', column_4: '' },
    { hour: '', column_1: '', column_2: '', column_3: '', column_4: '' }
  ]);
  const [sorting, setSorting] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);
  const isEditable = true;
  const formRef = useRef<any>();

  useEffect(() => {
    dispatch(getCustomizations());
    dispatch(getTemperatureType());
    dispatch(getTemperature());
  }, [dispatch]);

  useEffect(() => {
    const initColumnVisibility = (parsedColumns) => {
      const initialColumnVisibility = parsedColumns.reduce((acc, column) => {
        acc[column.key] = column.visible ?? true;
        return acc;
      }, {});
      initialColumnVisibility['hour'] = true;
      setColumnVisibility(initialColumnVisibility);
    };

    if (columns && columns.length > 0) {
      const parsedColumns = columns.map((field: any, index: number) => ({
        id: index + 1,
        key: field.key,
        label: field.label,
        type: field.type,
        editable: true,
        visible: field.visible ?? true
      }));

      initColumnVisibility(parsedColumns);
    } else {
      const staticColumn = [
        {
          key: 'hour',
          label: 'Hour',
          type: 'select',
          editable: true,
          visible: true
        }
      ];
      initColumnVisibility(staticColumn);
    }
  }, [status]);

  useImperativeHandle(ref, () => ({
    getData: () => data
  }));

  const TypeTemperature: FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
    return (
      <Select value={value} onValueChange={onChange}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select an hour" />
        </SelectTrigger>
        <SelectContent>
          {temperatureType.map((option) => (
            <SelectItem key={option.id} value={option.id}>
              {option.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  };

  const handleCellChange = (index: number, columnKey: string, value: string | number) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [columnKey]: value
      };
      return newData;
    });
  };

  const renderCellContent = (index: number, column: any, data: any[]) => (
    <td key={column.key} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
      {column.type === 'select' ? (
        <TypeTemperature
          value={data[index][column.key] || ''}
          onChange={(value) => handleCellChange(index, column.key, value)}
        />
      ) : isEditable ? (
        <Input
          type={column.type === 'number' ? 'number' : 'text'}
          value={data[index][column.key] || ''}
          onChange={(e) => handleCellChange(index, column.key, e.target.value)}
          style={{ width: '100px' }}
        />
      ) : (
        data[index][column.key] || ''
      )}
    </td>
  );

  const handleSave = () => {
    if (formRef.current) {
      const formData = formRef.current.getData();
      dispatch(saveTemperature({ formData, locationId: location }));
    }
  };

  const handleSort = (columnKey, direction) => {
    setSorting({ key: columnKey, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setData(sortedData);
  };

  const additionalButtons = (
    <>
      <Button onClick={handleSave}>Save</Button>
    </>
  );

  return (
    <div>
      <TableCustomVirtoso
        data={data}
        columns={columns}
        renderCellContent={renderCellContent}
        additionalButtons={additionalButtons}
        onSort={handleSort}
      />
    </div>
  );
});

export default FormTemperature;
