import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { NotesEditor } from '@/common/presentation/components/Notes/NotesEditor';
import { Label } from '@/common/presentation/components/ui/label';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import NumberInput from '../NumberInput';
import { CalendarIcon, Check, ChevronsUpDown, Plus } from 'lucide-react';
import { createNewPharmacy, fetchPharmacies } from '@/modules/pharmacy/presentation/slices/PharmacySlice';
import { createPhysician, fetchPhysicianTypes, fetchPhysicians } from '@/common/presentation/slices/physicianSlice';
import { fetchRoutes, fetchResponsible } from '../../slices/medicationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import CustomCombobox from './combobox';
import { cn } from '@/lib/utils';

const MedicationsOptional: React.FC<{
  residentId: string;
  locationId: string;
  control: any;
  handleSubmit: any;
  setValue: (name: string, value: any) => void;
  watch: any;
}> = ({ residentId, locationId, control, handleSubmit, setValue, watch }) => {
  const { routes, responsibleAdministrations } = useSelector((state: RootState) => state.medications);
  const { physicians, physicianTypes } = useSelector((state: RootState) => state.physicians);
  const { pharmacies } = useSelector((state: RootState) => state.pharmacy);
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [dialogOpenPharmacy, setDialogOpenPharmacy] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchRoutes());
    dispatch(fetchResponsible());
    dispatch(fetchPhysicians());
    dispatch(fetchPhysicianTypes());

    if (locationId) {
      dispatch(fetchPharmacies(locationId));
    }
  }, [dispatch, locationId]);

  // useEffect(() => {
  //   if (watch('date_prescribed')) {
  //     setSelectedDate(parseISO(watch('date_prescribed')));
  //   }
  // }, [watch('date_prescribed')]);

  const handlePharmacyFormSubmit = (data: any) => {
    data.location_id = locationId;
    const response = dispatch(createNewPharmacy(data));
    setDialogOpenPharmacy(false);
    if (!response.error) {
      if (locationId) {
        dispatch(fetchPharmacies(locationId));
      }
      toast({
        description: 'Pharmacy saved successfully!',
        variant: 'success'
      });
    } else {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
    setValue('pharmacy_id', '');
  };

  const handlePharmacySelect = (selectedPharmacy) => {
    setValue('pharmacy_id', selectedPharmacy.id);
  };

  const handleCreate = (newPharmacyName) => {
    console.log(newPharmacyName);
    setDialogOpenPharmacy(true);
  };

  const handleSelect = (selected) => {
    // Manejar la selección
    console.log(selected);
  };

  const pharmacyFields = [
    { name: 'name', placeholder: 'Pharmacy Name' },
    { name: 'address', placeholder: 'Address' },
    { name: 'phone', placeholder: 'Phone' }
  ];

  const doctorFields = [
    { name: 'name', placeholder: 'Doctor Name' },
    { name: 'type', placeholder: 'Doctor Type' },
    { name: 'npi', placeholder: 'NPI' }
  ];

  const handlePhysicianFormSubmit = (data: any) => {
    data.resident_id = residentId;
    const response = dispatch(createPhysician(data));
    setDialogOpen(false);
    if (!response.error) {
      dispatch(fetchPhysicians());
      toast({
        description: 'Physician saved successfully!',
        variant: 'success'
      });
    } else {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
    setValue('physician_id', '');
  };

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-4">
        <div>
          <Label htmlFor="physician_id" className="text-sm mb-2">
            Prescribing Physician
          </Label>
          <Controller
            name="physician_id"
            control={control}
            render={({ field }) => (
              <CustomCombobox
                options={physicians}
                onSelect={handleSelect}
                onCreate={handlePhysicianFormSubmit}
                placeholder="Select physician"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                entityType="doctor"
                fields={doctorFields}
              />
            )}
          />
        </div>
        <div>
          <Label htmlFor="prescriber_npi" className="text-sm mb-2">
            Prescriber NPI
          </Label>
          <Input name="prescriber_npi" value={watch('prescriber_npi')} className="w-full" readOnly />
        </div>
        <div>
          <Label htmlFor="physician_typeName" className="text-sm mb-2">
            Type Physician
          </Label>
          <Input name="physician_typeName" value={watch('physician_typeName')} className="w-full" readOnly />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 mb-4">
        <div>
          <Label htmlFor="pharmacy_id" className="text-sm mb-2">
            Pharmacy
          </Label>
          <Controller
            name="pharmacy_id"
            control={control}
            render={({ field }) => (
              <CustomCombobox
                options={pharmacies}
                onSelect={handleSelect}
                onCreate={handlePharmacyFormSubmit}
                placeholder="Select Pharmacy"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                entityType="pharmacy"
                fields={pharmacyFields}
              />
            )}
          />
        </div>
        <div>
          <Label htmlFor="date_prescribed" className="text-sm mb-2">
            Date Prescribed
          </Label>
          <Controller
            name="date_prescribed"
            control={control}
            render={({ field }) => (
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={'outline'}
                    className={cn(
                      'w-full justify-start text-left text-sm py-1',
                      !field.value && 'text-muted-foreground'
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {field.value
                        ? format(parse(field.value, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
                        : t('medications.medicationForm.selectDate')}
                    </span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <CustomCalendar
                    selectedDate={field.value ? parse(field.value, 'yyyy-MM-dd', new Date()) : null}
                    onChange={(date) => {
                      const formattedDate = date ? format(date, 'yyyy-MM-dd') : null;
                      setValue('date_prescribed', formattedDate);
                    }}
                    MonthAndYearPicker
                  />
                </PopoverContent>
              </Popover>
            )}
          />
        </div>

        <div>
          <Label htmlFor="frequency" className="text-sm mb-2">
            Frequency
          </Label>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <Input name="frequency" value={field.value} onChange={field.onChange} className="w-full" />
            )}
          />
        </div>
        <div>
          <Label htmlFor="routes_medication_id" className="text-sm mb-2">
            Route of Medication
          </Label>
          <Controller
            name="routes_medication_id"
            control={control}
            render={({ field }) => (
              <Select className="w-full" value={field.value} onValueChange={field.onChange}>
                <SelectTrigger>
                  <SelectValue placeholder="Select Route" />
                </SelectTrigger>
                <SelectContent>
                  {routes.length > 0 ? (
                    routes.map((route) => (
                      <SelectItem key={route.id} value={route.id}>
                        {route.name}
                      </SelectItem>
                    ))
                  ) : (
                    <p>No routes available</p>
                  )}
                </SelectContent>
              </Select>
            )}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
        <div>
          <Label htmlFor="current_quantity" className="text-sm mb-2">
            Current Quantity
          </Label>
          <NumberInput name="current_quantity" control={control} />
        </div>
        <div>
          <Label htmlFor="minimum_quantity" className="text-sm mb-2">
            Minimum Quantity
          </Label>
          <NumberInput name="minimum_quantity" control={control} />
        </div>
        <div>
          <Label htmlFor="units_per_dose" className="text-sm mb-2">
            Units per Dose
          </Label>
          <NumberInput name="units_per_dose" control={control} />
        </div>
      </div>
      <div className="mb-4">
        <Label htmlFor="responsible_administrations_id" className="text-sm mb-2">
          Responsible Administration
        </Label>
        <Controller
          name="responsible_administrations_id"
          control={control}
          render={({ field }) => (
            <Select className="w-full" value={field.value} onValueChange={field.onChange}>
              <SelectTrigger>
                <SelectValue placeholder="Select Responsible Administration" />
              </SelectTrigger>
              <SelectContent>
                {responsibleAdministrations.length > 0 ? (
                  responsibleAdministrations.map((responsible) => (
                    <SelectItem key={responsible.id} value={responsible.id}>
                      {responsible.name}
                    </SelectItem>
                  ))
                ) : (
                  <p>No responsible administrations available</p>
                )}
              </SelectContent>
            </Select>
          )}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div className="mb-4">
          <Label htmlFor="purpose" className="text-sm mb-2">
            Purpose
          </Label>
          <Controller
            name="purpose"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
              />
            )}
          />
        </div>

        <div className="mb-4">
          <Label htmlFor="not_exceed" className="text-sm mb-2">
            Not to Exceed
          </Label>
          <Controller
            name="not_exceed"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
              />
            )}
          />
        </div>
      </div>

      <div className="mb-4">
        <Label htmlFor="content_notes" className="text-sm mb-2">
          Content Notes
        </Label>
        <Controller
          name="content_notes"
          control={control}
          render={({ field }) => (
            <NotesEditor onChange={field.onChange} className="min-h-auto h-[200px] pb-10" value={field.value} />
          )}
        />
      </div>
    </div>
  );
};

export default MedicationsOptional;
