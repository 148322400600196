import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { usePrnDialog } from '@/modules/emar/infrastructure/hooks/UsePrnDialog';
import { PrnDialogForm } from '@/modules/emar/presentation/components/forms/PrnDialogForm';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';

export const NewPrnDialog = () => {
  const { isOpen, onClose, data, locationId } = usePrnDialog();

  const { residents } = useSelector((state: RootState) => state.residents.allResidents);

  const resident = residents.find((resident) => resident.id === data?.resident_id);

  const { dispensePrnMedication, status: medicationCardStatus } = useMedicationStore();

  const onSubmit = async (values: any) => {
    try {
      await dispensePrnMedication(
        values.time_given,
        values.follow_up,
        values.reason,
        values.level_of_pain,
        values.result,
        values.comments,
        resident?.id,
        locationId,
        data?.id,
        data?.dispense_id
      );

      onClose();
      toast({
        description: 'Medication dispensed successfully'
      });
    } catch (error: any) {
      toast({
        description: 'Failed to dispense medication',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[90vw] lg:max-w-[50vw] max-h-[90vh] min-h-[90vh] h-full flex flex-col gap-6 overflow-y-scroll">
          <DialogHeader>
            <DialogTitle className="text-xl">
              PRN medicaton for {resident?.first_name} {resident?.last_name}
            </DialogTitle>
          </DialogHeader>
          <Separator />
          <div>
            <h3 className="text-lg font-bold mb-4">Before giving this medication, please check the following:</h3>
            <div className="w-full flex flex-col gap-2">
              <p>
                <span className="font-bold">Medications: </span>
                {data?.medication?.name || 'Not provided'}
              </p>
              <p>
                <span className="font-bold">Strength Per Tab: </span>
                {data?.strength || 'Not provided'}
              </p>
              <p>
                <span className="font-bold">Frequency (PRN) Hrs: </span>
                {data?.frequency || 'Not provided'}
              </p>
              <p className="text-md">
                <span className="font-bold">Instructions: </span>
                {data?.instruction || 'No instructions provided'}
              </p>
              <p className="text-md">
                <span className="font-bold">Special instructions: </span>
                {data?.special_instructions || 'No instructions provided'}
              </p>
              <p>
                <span className="font-bold">Purpose of Medication: </span>
                {data?.purpose}
              </p>
            </div>
          </div>

          <Separator />
          <PrnDialogForm
            defaultValues={{
              time_given: data?.dispensed_at || new Date(),
              follow_up: data?.follow_up || '',
              reason: data?.reason || '',
              result: data?.result || '',
              comments: data?.comments || '',
              level_of_pain: data?.level_of_pain || 0
            }}
            onSubmit={onSubmit}
            disabled={medicationCardStatus === 'loading'}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
