import { Button } from '@/common/presentation/components/ui/button';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { useNewListMedication } from '@/modules/medications/infrastructure/hooks/use-new-list-medications';
import medicationService from '@/modules/medications/infrastructure/services/MedicationService';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader, NotebookTabs } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ResidentSelector } from './ResidentSelector';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import * as medicationActions from '@/modules/medications/presentation/slices/medicationsSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';

const displayColumns = [
  { id: 'date_prescribed', label: 'Prescription Date' },
  { id: 'rx', label: 'Rx #' },
  { id: 'times', label: 'Times' },
  { id: 'expiration_date', label: 'Expiration Date' },
  { id: 'end_date', label: 'End Date' },
  { id: 'start_date', label: 'Start Date' },
  { id: 'medication/generic_name', label: 'Generic Name' },
  { id: 'frequency', label: 'Frequency' }
] as const;

const FormSchema = z.object({
  resident_id: z
    .string({
      required_error: 'A resident is required.'
    })
    .min(1, 'A resident is required.'),
  items: z
    .array(z.string())
    .max(4)
    .refine((value) => value.some((item) => item), {
      message: 'You have to select at least one item.'
    })
});

type FormValues = z.infer<typeof FormSchema>;

const defaultValues: Partial<FormValues> = {
  resident_id: '',
  items: ['date_prescribed', 'rx', 'times', 'expiration_date']
};

export const ListMedicationDocumentDialog = () => {
  // const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onClose, locationId, residentId } = useNewListMedication();

  const { status } = useSelector((state: RootState) => state.medications);

  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmit = async (values: FormValues) => {
    const response = await dispatch(
      medicationActions.GenerateMedicationPDF({
        residentId: values.resident_id,
        type: 'list-medications',
        data: values
      })
    );

    if (response.meta.requestStatus === 'rejected') {
      toast({
        title: 'Failed to generate PDF',
        description: 'An error occurred while generating the PDF',
        variant: 'destructive'
      });
      return;
    }

    const blob = new Blob([response.payload], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url); // Abre el PDF en una nueva pestaña

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  useEffect(() => {
    if (form.getValues().items.length === 0) {
      form.setValue('items', defaultValues.items ?? []);
    }
  }, [form]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>List Medications Details</DialogTitle>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="resident_id"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Resident</FormLabel>
                    <FormControl>
                      <ResidentSelector
                        locationId={locationId}
                        residentId={residentId}
                        onChange={field.onChange}
                        placeholder="Select a resident"
                        disabled={status === 'loading'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="items"
                render={() => (
                  <FormItem>
                    <div>
                      <FormDescription>Select up to 4 items to display on the list of medications</FormDescription>
                    </div>
                    {displayColumns.map((item) => (
                      <FormField
                        key={item.id}
                        control={form.control}
                        name="items"
                        render={({ field }) => (
                          <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                            <FormControl>
                              <Checkbox
                                checked={field.value?.includes(item.id)}
                                onCheckedChange={(checked) =>
                                  checked
                                    ? field.onChange([...field.value, item.id])
                                    : field.onChange(field.value?.filter((value) => value !== item.id))
                                }
                                disabled={
                                  (field.value?.length === 4 && !field.value?.includes(item.id)) || status === 'loading'
                                }
                              />
                            </FormControl>
                            <FormLabel className="font-normal">{item.label}</FormLabel>
                          </FormItem>
                        )}
                      />
                    ))}
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" variant={'default'} disabled={status === 'loading'} className="w-full">
                {status === 'loading' && <Loader className="mr-2 h-4 w-4 animate-spin" />}
                Generate
              </Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
