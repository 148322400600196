import useRouteParams from '@/common/hooks/RouteParamsHook';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { toast } from '@/components/ui/use-toast';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { useEditDefaultForm } from '@/modules/residents/infrastructure/hooks/forms/useEditDefaultForm';
import { BasicInformationForm } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/Forms/BasicInformationForm';
import { DefaultFormValues } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/DefaultFormValues';
import { FacilityInformation } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/FacilityInformation';
import { FormInformation } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/FormInformation';
import { AppraisalForm } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/Forms/AppraisalForm';
import { ResidentInformation } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/ResidentInformation';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { AppDispatch, RootState } from '@/store/store';
import { Loader2, MonitorDown, Save } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const AppraisalNeedsAndServiceCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { formKey } = useParams();

  const { resident } = useSelector((state: RootState) => state.residents.resident || {});
  const {
    forms,
    status: formsStatus,
    formResource: formResourceFromStore,
    saveForm,
    getFormHistory,
    getFormResources
  } = useFormsStore();
  const { defaultFormValues: defaultFormValuesEdit, changeDefaultFormValues } = useEditDefaultForm();

  const [isReady, setIsReady] = useState(false);
  const [formResource, setFormResource] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const basicInformationFormRef = useRef<HTMLFormElement>();
  const appraisalFormRef = useRef<HTMLFormElement>();

  const lastestForm = useMemo(() => {
    return forms.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0] || {};
  }, [forms]);

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  useEffect(() => {
    const fetchFormResources = async () => {
      if (!formResourceFromStore) {
        const data = await getFormResources(formKey as string);
        setFormResource(data?.form_resource?.other_information);
      }
    };

    const initializeDefaultFormValues = () => {
      if (formResourceFromStore) {
        const transformResourceToDefaultValues = (obj: any): any => {
          const result: any = {};
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
              result[key] = transformResourceToDefaultValues(obj[key]);
            } else if (Array.isArray(obj[key])) {
              result[key] = '';
            }
          });

          if ('needs' in result || 'objectives' in result) {
            result['time_frame'] = '';
            result['person_responsible_for_implementation'] = '';
            result['method_of_evaluating_progress'] = '';
          }
          return result;
        };

        const defaultFormValues = {
          appraisal: transformResourceToDefaultValues(formResourceFromStore.other_information),
          basic_information: {
            resident_description: '',
            resident_likes: '',
            resident_dislikes: ''
          },
          default_form_values: {
            time_frame: '',
            person_responsible_for_implementation: '',
            method_of_evaluating_progress: ''
          }
        };

        setFormData((prevData: any) => ({ ...prevData, ...defaultFormValues }));
        setIsReady(true);
      }
    };

    fetchFormResources();
    initializeDefaultFormValues();
  }, [formResourceFromStore, formKey]);

  const convertNullToEmptyString = (obj: any) => {
    const result: any = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        result[key] = '';
      } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        result[key] = convertNullToEmptyString(obj[key]);
      } else {
        result[key] = obj[key];
      }
    });
    return result;
  };

  const loadLatestForm = () => {
    setFormData({
      basic_information: lastestForm?.data?.basicInformation || {
        resident_description: '',
        resident_likes: '',
        resident_dislikes: ''
      },
      default_form_values: lastestForm?.data?.defaultValues || {
        time_frame: '',
        person_responsible_for_implementation: '',
        method_of_evaluating_progress: ''
      },
      appraisal: convertNullToEmptyString(lastestForm?.data?.appraisal) || formData?.appraisal
    });

    changeDefaultFormValues(lastestForm?.data?.defaultValues);
    toast({ description: 'Latest form loaded', variant: 'default' });
  };

  const handleSubmit = async ({ pdf }: { pdf: boolean }) => {
    try {
      const basicInformationFormData = await basicInformationFormRef.current?.handleSubmit();
      const appraisalFormData = await appraisalFormRef.current?.handleSubmit();

      const combinedData = {
        basicInformation: basicInformationFormData,
        defaultValues: defaultFormValuesEdit,
        appraisal: appraisalFormData
      };

      console.log({ combinedData });

      const payload = {
        name: 'LIC625 Appraisal Needs and Services Plan',
        resident_id: resident?.id,
        key: 'appraisal_needs_and_services',
        data: combinedData,
        generate_pdf: pdf
      };

      const response = await saveForm(payload, pdf);

      toast({ description: 'Form saved successfully!', variant: 'default' });
      await getFormHistory(resident?.id, 'appraisal_needs_and_services');

      if (pdf) {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);
        setTimeout(() => URL.revokeObjectURL(url), 100);
      }
    } catch (error) {
      toast({ description: 'Error saving form', variant: 'destructive' });
    }
  };

  return (
    <CustomAccordion
      title={`Appraisal / Needs and Services Plan for ${resident?.first_name} ${resident?.last_name}`}
      components={
        <Button variant={'outline'} className="w-auto" onClick={loadLatestForm}>
          <MonitorDown className="mr-2 h-4 w-4" />
          Load Latest Form
        </Button>
      }
      openOption={false}
      defaultOpen
      separator
    >
      {!isReady && <Loader2 className="h-4 w-4 animate-spin" />}
      {isReady && (
        <div className="flex flex-col gap-4 py-2">
          <ResidentInformation resident={resident} />
          <Separator />
          <FacilityInformation resident={resident} />
          <Separator />
          <FormInformation resident={resident} />
          <Separator />
          <BasicInformationForm ref={basicInformationFormRef} defaultValues={formData?.basic_information} />
          <Separator />
          <DefaultFormValues defaultValues={formData?.default_form_values} />
          <Separator />
          <AppraisalForm ref={appraisalFormRef} defaultValues={formData?.appraisal} formResource={formResource} />
          <div className="flex justify-center gap-4">
            <Button
              onClick={() => handleSubmit({ pdf: false })}
              variant={'default'}
              disabled={formsStatus === 'loading'}
            >
              {formsStatus === 'loading' ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Save className="size-4 mr-2" />
              )}
              Save Form
            </Button>
            <Button
              onClick={() => handleSubmit({ pdf: true })}
              variant={'default'}
              disabled={formsStatus === 'loading'}
            >
              {formsStatus === 'loading' ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <FaRegFilePdf className="size-4 mr-2" />
              )}
              Save and View PDF
            </Button>
          </div>
        </div>
      )}
    </CustomAccordion>
  );
};
