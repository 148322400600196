import useRouteParams from "@/common/hooks/RouteParamsHook";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { RootState } from "@/store/store";
import { format } from "date-fns";
import { Pencil, Search, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ITrackerSeizure } from "./TrackerSeizureMockup";
import { TrackerSeizureReportUpdate } from "./TrackerSeizureReportUpdate";
import { TrackerSeizureReportDelete } from "./TrackerSeizureReportDelete";
import { deleteSeizure, getSeizures, updateSeizure } from "../../slices/TrackerSeizureSlice";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { Label } from "@/common/presentation/components/ui/label";
import { Input } from "@/common/presentation/components/ui/input";

export function TrackerSeizureReport() {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const configurations = useSelector((state: RootState) => state.configurations);
    const trackerSeizure = useSelector((state: RootState) => state.trackerSeizure);
    const { params } = useRouteParams();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState<ITrackerSeizure[]>([]);
    const [accentColor, setAccentColor] = useState("");
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState<ITrackerSeizure>();
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteData, setDeleteData] = useState("");
    const [values, setValues] = useState({
        duration: "",
        detail: ""
    });

    useEffect(() => {
        fetchData();
    }, [params.l]);

    useEffect(() => {
        setDataSource(trackerSeizure.seizures);
    }, [trackerSeizure]);

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    /* EDIT DIALOG */
    const openEditDialog = (element: ITrackerSeizure) => {
        setOpenEdit(true);
        setEditData(element);

        setValues({
            duration: element.data.duration,
            detail: element.data.detail
        });
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleEdit = (element: ITrackerSeizure) => {
        if (values.detail && values.duration) {
            dispatch(updateSeizure(
                {
                    id: element.id,
                    data: {
                        ...values
                    }
                }
            ));

            fetchData();

            toast(
                {
                    description: t("seizure.report.edit.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("seizure.report.edit.toast.required")
                }
            );
        }

        fetchData();

        return true;
    }

    /* DELETE DIALOG */
    const openDeleteDialog = (id: string) => {
        setOpenDelete(true);
        setDeleteData(id);
    }

    const closeDeleteDialog = () => {
        setOpenDelete(false);
    }

    const handleDelete = (id: string) => {
        dispatch(deleteSeizure(
            {
                id
            }
        ));

        fetchData();

        return true;
    }

    const fetchData = async () => {
        try {
            const result = await dispatch(getSeizures({
                location_id: params.l
            }));
            const res = result.payload.result;
            if (res && Array.isArray(res) && res.length > 0) {
                let allData = [];
                res.forEach(item => {
                    if (item && item.data) {
                        const parsedData = item;
                        if (Array.isArray(parsedData)) {
                            allData = allData.concat(parsedData.map(dataItem => ({
                                ...dataItem,
                                created_at: item.created_at,
                                dataReportid: item.id
                            })));
                        } else {
                            allData.push({
                                ...parsedData,
                                created_at: item.created_at,
                                dataReportid: item.id
                            });
                        }
                    }
                });
                allData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setDataSource(allData);
            } else {
                setDataSource([])
            }
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <div>
            {
                deleteData && <CustomDialog
                    title={t("cleaning.report.summary.delete.title")}
                    description={t("cleaning.report.summary.delete.description")}
                    width="30rem"
                    newDialog={true}
                    isOpen={openDelete}
                    onSubmit={() => handleDelete(deleteData)}
                    onClose={closeDeleteDialog}
                ></CustomDialog>
            }

            {
                editData && <CustomDialog
                    title={t("seizure.report.edit.title")}
                    width="30rem"
                    newDialog={true}
                    isOpen={openEdit}
                    onSubmit={() => handleEdit(editData)}
                    onClose={closeEditDialog}
                >
                    <form className="grid gap-4 py-4">
                        <div>
                            <Label htmlFor="duration" className="text-right">
                                {t("seizure.report.edit.form.duration")}
                            </Label>

                            <Input
                                id="duration"
                                name="duration"
                                className="col-span-3"
                                value={values.duration}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div>
                            <Label htmlFor="detail" className="text-right">
                                {t("seizure.report.edit.form.detail")}
                            </Label>

                            <Input
                                id="detail"
                                name="detail"
                                className="col-span-3"
                                value={values.detail}
                                onChange={handleInputChange}
                            />
                        </div>
                    </form>
                </CustomDialog>
            }

            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("seizure.report.title")}
                    </CardTitle>
                </CardHeader>

                <CardContent>
                    <div className="grid grid-cols-1 grid-row-2 w-100">
                        {
                            dataSource.length ? (
                                <Table>
                                    <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                                        <TableRow className="bg-primary/80 hover:bg-primary/80">
                                            <TableHead className="text-white">{t("seizure.report.table.date")}</TableHead>
                                            <TableHead className="text-white">{t("seizure.report.table.resident")}</TableHead>
                                            <TableHead className="text-white w-5">{t("seizure.report.table.actions")}</TableHead>
                                        </TableRow>
                                    </TableHeader>

                                    <TableBody>
                                        {
                                            dataSource.length && dataSource.map(
                                                element =>
                                                    <TableRow key={element.id}>
                                                        <TableCell>{format(new Date(element.data.date), "dd/MM/yyyy hh:mm a")}</TableCell>
                                                        <TableCell>{element.data.resident.first_name + ' ' + element.data.resident.last_name}</TableCell>
                                                        <TableCell className="flex">
                                                            <Button onClick={() => openEditDialog(element)} variant="outline" size="icon" className='mr-2'>
                                                                <Pencil className="h-4 w-4" />
                                                            </Button>

                                                            <Button onClick={() => openDeleteDialog(element.id as string)} variant="outline" size="icon" className='mr-2'>
                                                                <Trash className="h-4 w-4" />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            ) : (
                                <div className="flex flex-col w-100 justify-center align-center">
                                    <div className="flex justify-center items-center">
                                        <Search className="text-center w-5 mb-2" />
                                    </div>

                                    <h1 className="text-center">
                                        {t("seizure.report.table.notFound")}
                                    </h1>
                                </div>
                            )
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
