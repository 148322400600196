import { TabsContent, TabsTrigger } from '@/common/presentation/components/ui/tabs';

export const EmarTab = () => {
  return (
    <TabsTrigger value="emar" className="w-full flex items-center justify-start gap-2">
      Emar
    </TabsTrigger>
  );
};

export const EmarContent = () => {
  return (
    <TabsContent value="emar" className="w-full">
      hola
    </TabsContent>
  );
};
