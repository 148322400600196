import { useState, useEffect } from 'react';
import { RootState } from '@/store/store';
import {  useSelector } from 'react-redux';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { useTranslation } from 'react-i18next';
import { Select, SelectContent, SelectItem, SelectTrigger } from '@/components/ui/select';
import { FloatButton } from './floatButton';

interface NavigationProps {
  setCrudState: (crudState: CrudState) => void;
  crudState: CrudState;
}

export function Navigation({ setCrudState, crudState }: NavigationProps) {
  // Init values
  const { t } = useTranslation();

  // Hooks
  const [selectedValue, setSelectedValue] = useState('');
  useEffect(() => {
    if (crudState.isCreating || crudState.isUpdating) {
      setSelectedValue(t('notes.notes'));
    } else if(crudState.isCreatingStaffNote || crudState.isUpdatingStaffNote || crudState.showingStaffNotes) {
      setSelectedValue(t('notes.staffNotes'));
    }  else {
      setSelectedValue(t('notes.notes'));
    }
  }, [crudState]);

  // Redux
  const auth = useSelector((state: RootState) => state.auth.user);

  // Behavior

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);

    if (value === t('notes.notes')) {
      setCrudState(NoteHelperService.defineState({}));
    } else if (value === t('notes.staffNotes')) {
      setCrudState(NoteHelperService.defineState({ showingStaffNotes: true }));
    }
  };

  return (
    <>
      <Select onValueChange={handleSelectChange}>
        <SelectTrigger className="w-[160px]  border-primary bg-transparent focus:outline-none focus:ring-0">
          <h1 className="ml-2 text-xl font-bold">{selectedValue}</h1>
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={t('notes.notes')}>
            <div className="flex">
              <h1 className="ml-2 text-xl font-bold">{t('notes.notes')}</h1>
            </div>
          </SelectItem>
          {auth?.roles[0] && ['Super Administrator', 'Executive', 'Client Manager'].includes(auth?.roles[0].name) && (
            <SelectItem value={t('notes.staffNotes')}>
              <div className="flex">
                <h1 className="ml-2 text-xl font-bold">{t('notes.staffNotes')}</h1>
              </div>
            </SelectItem>
          )}
        </SelectContent>
      </Select>
      <FloatButton setCrudState={setCrudState} />
    </>
  );
}
