import { Button } from '@/common/presentation/components/ui/button';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Separator } from '@/common/presentation/components/ui/separator';
import { cn } from '@/lib/utils';
import { FormFieldToRequest } from '@/modules/residents/domain/models/FormField';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface Props {
  className?: string;
  onSubmit: (values: FormFieldToRequest) => void;
  status?: string;
  defaultValues?: Partial<FormValues>;
  isEdit?: boolean;
}

const formSchema = z.object({
  label: z
    .string({
      required_error: 'Label is required'
    })
    .min(3, {
      message: 'Label must be at least 3 characters long'
    })
    .max(255, {
      message: 'Label must be at most 255 characters long'
    }),
  value: z.string(),
  placeholder: z.string().optional(),
  isRequired: z.boolean().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const CustomFieldsForm = ({ className, onSubmit, defaultValues, status, isEdit = false }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const castedValues = {
        ...values,
        placeholder: values.placeholder || null,
        isRequired: values.isRequired || false
      };

      onSubmit(castedValues);
    },
    [onSubmit]
  );

  useEffect(() => {
    if (form.watch('label') && !defaultValues?.value) {
      console.log({ defaultValues });

      form.setValue('value', form.watch('label').replace(/\s+/g, '_').toLowerCase());
    }
  }, [form.watch('label'), defaultValues]);

  return (
    <div className={cn(className)}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 px-1">
          <FormField
            control={form.control}
            name="label"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Label</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    disabled={status === 'loading'}
                    className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="placeholder"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Placeholder</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    disabled={status === 'loading'}
                    className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="isRequired"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                </FormControl>
                <FormLabel className="font-normal">Required</FormLabel>
              </FormItem>
            )}
          />

          <Separator className="col-span-full bg-primary" />

          <div className="col-span-full flex justify-end">
            <Button type="submit" disabled={status === 'loading'}>
              {status === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
              {isEdit ? 'Edit' : 'Add'}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
