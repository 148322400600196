import { NotesStructureI } from "@/modules/notes/domain/note.domain";

export interface InitialStateI {
    urgentNotes: NotesStructureI;
    messagesForYou: NotesStructureI;
    residentAlerts: AlertStateI;
    administrationAlerts: AlertStateI;
};

export interface AlertStateI {
    status:  'idle' | 'loading' | 'fail';
    data: AlertsResponseI[];
};

export interface AlertsResponseI {
    alert_key: string;
    date: string;
    evaluator: number;
    resident_id: string;
    value: number;
};

export enum HealthStatus {
    good = 'good',
    poor = 'poor',
    fair = 'fair'
};

export type HealthStatusI = HealthStatus.good | HealthStatus.poor | HealthStatus.fair;