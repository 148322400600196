import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/common/presentation/components/ui/form';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { SuggestionSheet } from './SuggestionSheet';

interface FormFieldComponentProps {
  name: string;
  label: string;
  control: any;
  defaultValue?: string[];
  addCompletitionToTextarea?: (value: string) => void;
}

export const FormFieldComponent: React.FC<FormFieldComponentProps> = ({
  name,
  label,
  control,
  defaultValue,
  addCompletitionToTextarea
}) => {
  // console.log({
  //   name,
  //   defaultValue,
  //   control,
  //   addCompletitionToTextarea
  // });

  return (
    <>
      <div className="border border-t-4 border-t-primary/80 p-2 rounded-lg">
        <FormField
          control={control}
          name={name}
          render={({ field }) => {
            // console.log({
            //   name,
            //   field: field.value
            // });

            return (
              <FormItem>
                <FormLabel>{label}</FormLabel>
                <FormControl>
                  <div className="relative cursor-pointer">
                    <div className="absolute bottom-0 pb-3 left-0 pl-3 flex items-center">
                      {addCompletitionToTextarea && (
                        <SuggestionSheet
                          label={`${label}`}
                          defaultValue={defaultValue}
                          addCompletitionToTextarea={(value: string) => addCompletitionToTextarea(value)}
                        />
                      )}
                    </div>
                    <Textarea
                      className="h-[200px] border-2 focus-visible:ring-0 focus-visible:ring-offset-0 pb-14"
                      {...field}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      </div>
    </>
  );
};
