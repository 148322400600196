import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader } from '@/common/presentation/components/ui/dialog';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Label } from '@/common/presentation/components/ui/label';
import SubscriptionForm from './SubscriptionForm';
import { useDispatch } from 'react-redux';
import {
  fetchCancelSubscription,
  fetchGetClientSubscriptions,
  fetchGetStripeSubscription
} from '../slices/SubscriptionSlice';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/common/presentation/components/ui/alert-dialog';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { Loader2 } from 'lucide-react';
import { useMarketPlaceFormData } from '../../infraestructure/providers/MarketPlaceProvider';
import { SubscriptionI } from '../../domain/makerPlace.domain';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { getClientConfigurations } from '@/common/presentation/slices/configurationSlice';

interface ServiceModalProps {}

const ServiceModal: React.FC<ServiceModalProps> = () => {
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState<Partial<SubscriptionI>>({});
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const { formData, updateFormData } = useMarketPlaceFormData();
  const { configurations } = useConfigurationsProvider();
  const dispatch = useDispatch();

  const getInitialsName = (name: string) => {
    const names = name.split(' ');
    return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
  };

  useEffect(() => {    
    // If there is a subcription to cancel, we show the confirmation dialog
    if (Object.keys(formData.subscriptionToCancel).length > 0) {
      if (!configurations.free_access && formData.subscriptionToCancel.id && formData.subscriptionToCancel.cancel_at == null) {        
        // If there is a subscription to cancel and the subscription does not has cancel date yet
        // fetch subscription information form stripe to get cancel date
        getSubscriptionPeriodEnd(formData.subscriptionToCancel.id);
      }
      setSubscriptionInfo(formData.subscriptionToCancel);
      setConfirmCancelModalOpen(true);
    } else {
      setConfirmCancelModalOpen(false);
    }
  }, [formData.subscriptionToCancel]);

  useEffect(() => {
    if (!formData.isDialogOpen) {
      setSubscriptionInfo({});
    }
  }, [formData.isDialogOpen]);

  const closeModal = () => {
    updateFormData({ ...formData, isDialogOpen: false });
  };

  const closeCancelModal = () => {
    updateFormData({
      ...formData,
      isDialogOpen: true,
      subscriptionToCancel: {}
    });
  };

  const cancelSubscription = async () => {
    if (!formData.subscriptionToCancel.id) return;
    setIsCancelLoading(true);

    const response = await dispatch<any>(fetchCancelSubscription(formData.subscriptionToCancel.id));

    if (!response.error) {
      await dispatch<any>(fetchGetClientSubscriptions());

      if (configurations.free_access) {
        // Getting client configuration to update the menu inmediatelly
        await dispatch<any>(getClientConfigurations());
      }

      setConfirmCancelModalOpen(false);
      updateFormData({
        ...formData,
        subscriptionToCancel: {}
      });
      toast({
        title: 'Your subscription was canceled successfully',
        description: `You will retain access to this service until the current billing period ends`,
        className: 'bg-green-500 text-white'
      });
      return;
    }
    setIsCancelLoading(false);
  };

  const getSubscriptionPeriodEnd = async (subscriptionId: string) => {
    const response = await dispatch<any>(fetchGetStripeSubscription(subscriptionId));
    if (!response.error) {
      updateFormData({
        ...formData,
        subscriptionToCancel: {
          ...formData.subscriptionToCancel,
          cancel_at: response.payload.current_period_end ?? ''
        }
      });
    }
  };

  return (
    <div>
      {formData.isDialogOpen && formData.serviceSelectedInfo && (
        <Dialog open={formData.isDialogOpen} onOpenChange={closeModal}>
          <DialogContent>
            <DialogHeader>
              <Card className="mt-3 border-none">
                <CardHeader>
                  <CardTitle>
                    <div className="flex flex-row">
                      <div className="w-8 h-8 border border-1 flex rounded-md justify-center items-center bg-primary">
                        <Label className="select-none text-white text-sm font-semibold">
                          {getInitialsName(formData.serviceSelectedInfo.name)}
                        </Label>
                      </div>
                      <div className="pl-2 flex items-center justify-center align-middle">
                        {formData.serviceSelectedInfo.name}
                      </div>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="justify-start">{formData.serviceSelectedInfo.description}</div>
                  <SubscriptionForm serviceInfo={formData.serviceSelectedInfo} />
                </CardContent>
              </Card>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      )}
      {Object.keys(formData.subscriptionToCancel).length > 0 && (
        <AlertDialog open={confirmCancelModalOpen}>
          <AlertDialogTrigger asChild></AlertDialogTrigger>
          <AlertDialogContent className="z-[999999]">
            <AlertDialogHeader>
              <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
              <AlertDialogDescription>
                <Label className="text-base">
                  Are you sure you want to cancel your subscription to
                  <span className="font-bold"> {formData.subscriptionToCancel?.service?.name}</span>? 
                  { !configurations.free_access  ? (
                    <>
                    You will continue
                  to have access to the service until{' '}
                  {!formData.subscriptionToCancel.cancel_at?.length ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    </>
                  ) : (
                    <>{subscriptionInfo.cancel_at}</>
                  )}
                    </>
                  ) : (
                    <>
                      {' '} Your service is going to be cancelled inmediatelly.
                    </>
                  ) } 
                </Label>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                disabled={isCancelLoading || (!formData.subscriptionToCancel.cancel_at?.length && !configurations.free_access)}
                className="text-red-600"
                onClick={cancelSubscription}
              >
                {isCancelLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Canceling Subscription
                  </>
                ) : (
                  'Cancel Subscription'
                )}
              </AlertDialogCancel>
              <AlertDialogAction
                disabled={isCancelLoading || (!formData.subscriptionToCancel.cancel_at?.length && !configurations.free_access)}
                onClick={closeCancelModal}
              >
                Keep Subscription
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </div>
  );
};

export default ServiceModal;
