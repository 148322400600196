import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { Label } from '@/common/presentation/components/ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/common/presentation/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { ServiceI } from '../../domain/makerPlace.domain';
import { useMarketPlaceFormData } from '../../infraestructure/providers/MarketPlaceProvider';

interface ServiceCardProps {
  serviceInfo: ServiceI;
  isSubscribed: boolean;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ serviceInfo, isSubscribed }) => {
  const { updateFormData, formData } = useMarketPlaceFormData();
  
  const getInitialsName = (name: string) => {
    const names = name.split(' ');
    return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
  };

  const clickHandler = () => {
    updateFormData({
      ...formData,
      serviceSelectedInfo: serviceInfo,
      isDialogOpen: true
    });
  };

  return (
    <Card className="hover:bg-primary/5">
      <CardContent className="pl-3 pt-3" onClick={clickHandler}>
        <div className="flex flex-row">
          <div className="w-8 h-8 border border-1 flex rounded-md justify-center items-center bg-primary">
            <Label className="select-none text-white text-sm font-semibold">{getInitialsName(serviceInfo.name)}</Label>
          </div>
          <div className="flex justify-end flex-grow">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className={cn(
                      'h-8 bg-background text-primary border-2 border-gray-200 dark:border-gray-800 hover:text-white',
                      isSubscribed ? 'bg-primary text-white' : ''
                    )}
                  >
                    {isSubscribed ? 'Subscribed' : 'Learn More'}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{isSubscribed ? 'Manage your subscription' : 'See more service details'}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div className="flex flex-col mt-1">
          <div>
            <Label className="font-bold text-sm">{serviceInfo.name}</Label>
          </div>
          <div>
            <Label className="text-sm font-normal text-slate-500 dark:text-slate-300">
              { serviceInfo.description && (
                <>
                { serviceInfo.description.substring(0, 100) } ...
                </>
              ) }
            </Label>{' '}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
