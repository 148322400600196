import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { cn } from '@/lib/utils';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@radix-ui/react-hover-card';
import { Home } from 'lucide-react';
import React from 'react';

interface PropsI {
  children: JSX.Element;
  locationName: string;
  description?: string | null;
  image?: string;
}

export const HoverCardLocation: React.FC<PropsI> = ({ children, locationName, description, image }) => {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>{children}</HoverCardTrigger>
      <HoverCardContent className="w-80 z-[999999] bg-background rounded-md p-3 shadow-lg mt-2 border-2 border-muted">
        <div className="flex gap-3">
          <Avatar>
            <AvatarImage src={image} />
            <AvatarFallback>
                <Home className={cn('size-4')}  />
            </AvatarFallback>
          </Avatar>
          <div className={cn('space-y-1 items-center', !description || description.length == 0 ? 'flex justify-center' : '')}>
            <h4 className="text-sm font-semibold text-primary">{locationName}</h4>
            {description && <p className="text-sm">{description}</p>}
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
