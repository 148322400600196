import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const PharmacyService = {
  getPharmacy: async (locationId: string) => {
    try {
      const response = await axios.get(`pharmacys/listpharmacy?location_id=${locationId}`);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error list pharmacies: No data returned from server');
      }
    } catch (error) {
      throw new Error('Error fetching pharmacies: ' + error);
    }
  },

  createPharmacy: async (formData: any) => {
    try {
      const response = await axios.post(`pharmacys/createpharmacy`, formData);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating pharmacy: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating pharmacy: ' + error.message);
    }
  }
};

export default PharmacyService;
