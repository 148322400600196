export const formatTabLabel = (tab: string) => {
  return tab
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getNestedProperty = (obj: any, path: string): any => {
  const keys = path.split('.');
  return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : undefined), obj);
};
