import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Button } from '@/common/presentation/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/common/presentation/components/ui/form';
import { Label } from '@/common/presentation/components/ui/label';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetClientSubscriptions, fetchSubscribe, fetchUpdateSubscription } from '../../slices/SubscriptionSlice';
import { BadgeCheck, Loader2 } from 'lucide-react';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { getClientConfigurations } from '@/common/presentation/slices/configurationSlice';
import { ModuleI, PlanI, ServiceI, SubscriptionI } from '@/modules/subscription/domain/makerPlace.domain';
import { RootState } from '@/store/store';
import { calculatePrice } from '@/utils/helpers/subscriptions.helper';
import { localization } from '@/utils/helpers/localization.helper';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { useMarketPlaceFormData } from '@/modules/subscription/infraestructure/providers/MarketPlaceProvider';
import { Alert, AlertDescription, AlertTitle } from '@/common/presentation/components/ui/alert';

interface Props {
  serviceInfo: ServiceI;
}

interface IFormData {
  service_module: string;
}

interface LoadingI {
  [key: string]: boolean;
}

const ServiceSelectionForm: React.FC<Props> = ({ serviceInfo }) => {
  const subscriptions = useSelector((state: RootState) => state.subscription.subscriptions);
  const locations = useSelector((state: RootState) => state.locations.allLocations.locations);
  const [price, setPrice] = useState<number>(0);
  const form = useForm<IFormData>({});
  const [isLoading, setIsLoading] = useState<Partial<LoadingI>>({});
  const dispatch = useDispatch();
  const [serviceModuleId, setServiceModuleId] = useState('');
  const [labelButton, setLabelButton] = useState('');
  const [subscriptionInfo, setSubscriptionInfo] = useState<Partial<SubscriptionI>>({});
  const { clientInfo, configurations } = useConfigurationsProvider();
  const { formData, updateFormData } = useMarketPlaceFormData();
  const [serviceModuleSubscribed, setServicemoduleSubscribed] = useState<string>('');

  useEffect(() => {
    if (Object.values(subscriptions).length == 0) {
      return;
    }
    // Get client subscription information by service
    getSubscriptionInfo(serviceInfo.key);
  }, [subscriptions]);

  useEffect(() => {
    if (subscriptionInfo) {
      const activePlan: PlanI | undefined = subscriptionInfo.plans?.find((plan) => plan.status === 'active');
      if (activePlan) {
        // Select service module that the client is subscribed
        setServiceModuleId(activePlan.service_module.id);
        setServicemoduleSubscribed(activePlan.service_module.id);
      }
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    if (serviceModuleId) {
      // Detect service module change and recalculate the price
      form.setValue('service_module', serviceModuleId);
      getPrice(serviceModuleId);
    }
  }, [serviceModuleId]);

  useEffect(() => {
    generateLabelButton();
  }, [subscriptionInfo]);

  const getSubscriptionInfo = async (serviceKey: string) => {
    let subscription = subscriptions[serviceKey] || {};
    setSubscriptionInfo(subscription);
    return subscription;
  };

  const onSubmit = async (formData: IFormData) => {
    setIsLoading({ ...isLoading, [serviceInfo.key]: true });

    const modulesFormatted = getFormatedModules(formData);
    let response;

    if (Object.values(subscriptionInfo).length > 0) {
      // Update subscription if the client is subscribed
      response = await dispatch<any>(
        fetchUpdateSubscription({
          service_key: serviceInfo.key,
          modules: modulesFormatted,
          subscription_id: subscriptionInfo?.id
        })
      );
    } else {
      response = await dispatch<any>(
        fetchSubscribe({
          service_key: serviceInfo.key,
          modules: modulesFormatted
        })
      );
    }

    if (!response.error) {
      await dispatch<any>(fetchGetClientSubscriptions());

      // Getting client configuration to update the menu
      dispatch<any>(getClientConfigurations());
      updateFormData({
        ...formData,
        isDialogOpen: false
      });

      toast({
        title: 'Subscription',
        description: subscriptionInfo
          ? 'Your subscription was updated successfully'
          : 'You have subscribed successfully',
        className: 'bg-green-500 text-white'
      });
    }

    setIsLoading({ ...isLoading, [serviceInfo.key]: false });
  };

  const cancelSubscription = async () => {
    updateFormData({
      ...formData,
      isDialogOpen: false,
      subscriptionToCancel: subscriptionInfo
    });
  };

  const getFormatedModules = (formData: IFormData) => {
    return serviceInfo.modules.reduce((acc: Record<string, any>, module: any) => {
      if (module.id === formData.service_module) acc[module.id] = 1;
      return acc;
    }, {});
  };

  const getPrice = (serviceModuleSelected: string) => {
    // Get price of the service module
    const selectedModule: ModuleI | undefined = serviceInfo.modules.find(
      (module: any) => module.id === serviceModuleSelected
    );
    if (selectedModule) {
      const modulePrice = calculatePrice(selectedModule, getClientBedsNumber(locations), clientInfo.country.code);
      setPrice(modulePrice.price);
    }
  };

  const getClientBedsNumber = (locations: any) => {
    return locations.reduce((sum: number, current: any) => sum + current.max_beds, 0);
  };

  const getLabel = () => {
    switch (serviceInfo.key) {
      case 'training_plan':
        return {
          formLabel: 'Platform',
          selectLabel: 'Select a platform'
        };
      default:
        return {
          formLabel: 'Service Module',
          selectLabel: 'Select a module'
        };
    }
  };

  const generateLabelButton = () => {
    let label = '';

    if (Object.values(subscriptionInfo).length > 0) {
      if (subscriptionInfo.cancel_at) {
        label = 'Reactivate Subscription';
      } else {
        label = 'Update Subscription';
      }
    } else {
      label = 'Subscribe';
    }

    setLabelButton(label);
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="my-3">
            <FormField
              control={form.control}
              name="service_module"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{getLabel().formLabel}</FormLabel>
                  <Select
                    disabled={
                      isLoading[serviceInfo.key] ||
                      (subscriptionInfo.cancel_at != undefined && subscriptionInfo.cancel_at?.length > 0)
                    }
                    key={JSON.stringify(serviceModuleId)}
                    defaultValue={serviceModuleId || field.value}
                    onValueChange={(value) => {
                      field.onChange(value);
                      getPrice(value);
                      setServiceModuleId(value);
                    }}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={getLabel().selectLabel} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="z-[99999]">
                      {serviceInfo.modules.map((module: any) => (
                        <SelectItem key={module.id} value={module.id}>
                          {module.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>
          {configurations.free_access ? (
            <div className="col-span-2 mt-1">
              <Alert className="border-primary bg-primary/5">
                <BadgeCheck className="h-4 w-4 stroke-primary" />
                <AlertTitle>Free Access</AlertTitle>
                <AlertDescription>Your account does not require payments for this service</AlertDescription>
              </Alert>
            </div>
          ) : (
            price > 0 && (
              <div className="mt-3">
                <Label className="font-bold text-3xl">
                  {localization.formatPrice(price, clientInfo.country.currency)}
                </Label>{' '}
                / Monthly
              </div>
            )
          )}
          <div className="mt-9">
            <div className="mb-2">
              <Label className="text-xs font-light line-height-5">
                {subscriptionInfo
                  ? subscriptionInfo?.cancel_at
                    ? `
                    Your canceled this subscriptions, you will retain access to this service until the current billing period ends on ${subscriptionInfo?.cancel_at}
                  `
                    : 'By modifying your subscription, you agree to update your subscription details. Any changes made will be reflected in your next billing cycle.'
                  : 'By subscribing, you agree to immediate billing using your registered payment method. Charges occur upon subscription activation. You can cancel anytime from your account.'}
              </Label>
            </div>
            {(serviceModuleId !== serviceModuleSubscribed || subscriptionInfo?.cancel_at) && (
              <Button type="submit" className="w-full" disabled={isLoading[serviceInfo.key]}>
                {isLoading[serviceInfo.key] && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                {labelButton}
              </Button>
            )}
          </div>
        </form>
        <div className="mt-3">
          {Object.values(subscriptionInfo).length > 0 && !subscriptionInfo.cancel_at && (
            <Button
              onClick={cancelSubscription}
              className="w-full bg-background border border-primary text-primary hover:text-background"
            >
              Cancel Subscription
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default ServiceSelectionForm;
