import { Input } from '@/common/presentation/components/ui/input';
import React, { useEffect, useRef, useState } from 'react';
import Inputmask from 'inputmask';
import { getCardImageType, getCardPatternMask, getCardType } from '@/utils/helpers/paymentMethod.helper';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  placeHolder: string;
  name: string;
  disabled?: boolean;
}

const InputCreditCard: React.FC<IProps> = ({ value, onChange, placeHolder, name, disabled = false }) => {
  const inputCardRef = useRef<HTMLInputElement>();
  const [cardImage, setCardImage] = useState('unknown.svg');
  const [maskPattern, setMaskPattern] = useState('9999 9999 9999 9999');
  useEffect(() => {
    if (inputCardRef.current) {
      const cardNumberMask = new Inputmask({
        mask: '9999 9999 9999 9999',
        showMaskOnFocus: false,
        greedy: false
      });
      cardNumberMask.mask(inputCardRef.current);

      setCardImage(getCardImageType(getCardType(value)));
    }
  }, []);


  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    onChange(value);
    
    const cardType = getCardType(value);
    const cardNumberMaskPattern = getCardPatternMask(cardType)

    setMaskPattern(cardNumberMaskPattern);
    const cardNumberMask = new Inputmask({
      mask: cardNumberMaskPattern,
      showMaskOnFocus: false,
      greedy: false
    });

    if (inputCardRef.current) {
      cardNumberMask.mask(inputCardRef.current);
    }

    setCardImage(getCardImageType(cardType));
  };

  const handleInputBlur = () => {
    if (inputCardRef.current) {
      Inputmask.remove(inputCardRef.current);
    }
  };

  const handleInputFocus = () => {
    if (inputCardRef.current) {
      const cardNumberMask = new Inputmask({
        mask: maskPattern,
        showMaskOnFocus: false,
        greedy: false
      });

      cardNumberMask.mask(inputCardRef.current);
    }
  };

  return (
    <>
      <div className="relative w-full">
        <img
          src={`/img/credit-cards/${cardImage}`}
          alt="card"
          className="absolute left-2 top-1/2 transform -translate-y-1/2 h-5 z-10"
        />
        <Input
          type="text"
          value={value}
          onChange={(e) => inputHandler(e)}
          name={name}
          placeholder={placeHolder}
          ref={inputCardRef}
          disabled={disabled}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          className="pl-14"
        />
      </div>
    </>
  );
};

export default InputCreditCard;
