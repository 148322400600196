import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { usePlanOfCareStore } from '@/modules/residents/domain/stores/plan-of-care/planOfCareStore';
import { RootState } from '@/store/store';
import { Eye, Trash } from 'lucide-react';
import { useSelector } from 'react-redux';

export const PlanOfCareHistoryActions = ({ id }: any) => {
  const { deletePlanOfCare, status: formsStatus, getPlanOfCareHistory, generateHistoryPdf } = usePlanOfCareStore();
  const { resident } = useSelector((state: RootState) => state.residents.resident);

  const handleDelete = async () => {
    try {
      const response = await deletePlanOfCare(id);

      toast({
        description: 'Form deleted successfully',
        variant: 'default'
      });

      if (response) {
        await getPlanOfCareHistory(resident?.id);
      }
    } catch (error) {
      toast({
        description: 'Error deleting form',
        variant: 'destructive'
      });
    }
  };

  const handleView = async () => {
    try {
      const response = await generateHistoryPdf(id);

      const blob = new Blob([response], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url);

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      toast({
        description: 'Error generating PDF',
        variant: 'destructive'
      });
    }
  };

  return (
    <div className="flex gap-2">
      <Button size={'icon'} variant={'outline'} onClick={handleView} disabled={formsStatus === 'loading'}>
        <Eye className="w-4 h-4" />
      </Button>
      <Button size={'icon'} variant={'outline'} onClick={handleDelete} disabled={formsStatus === 'loading'}>
        <Trash className="w-4 h-4" />
      </Button>
    </div>
  );
};
