import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { Label } from '@/common/presentation/components/ui/label';
import { CreditCard } from 'lucide-react';
import PaymentMethodList from '../components/PaymentMethodList';
import PaymentMethodAdding from '../components/PaymentMethodAdding';
import { useTranslation } from 'react-i18next';

const PaymentManage = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div className="col-span-1">
            <div>
              <div className="flex flex-row gap-2 align-bottom mt-4">
                <CreditCard size={30} color="#777" />
                <h1 className="font-bold text-xl">{t('subscription.paymentMethods')}</h1>
              </div>
              <div className="my-3">
                <Label className="text-neutral-600 dark:text-white">{t('subscription.manageYourPaymentMethods')}</Label>
              </div>
              <div>
                <PaymentMethodAdding />
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className="col-span-1">
              <PaymentMethodList />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PaymentManage;
