import React, { useEffect, useState } from 'react';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { format, eachDayOfInterval } from 'date-fns';
import { MonthAndYearSelector } from './MonthAndYearSelector';
import { TimePicker } from './TimePicker';

interface CustomMultiCalendarProps {
  onChange?: (dates: Date[]) => void;
  value?: Date[];
  MonthAndYearPicker?: boolean;
  timePicker?: boolean;
  baseSelectorYear?: number;
  allowMultipleSelection?: boolean;
}

export const CustomMultiCalendar = ({
  onChange,
  value = [],
  MonthAndYearPicker = false,
  timePicker = false,
  baseSelectorYear,
  allowMultipleSelection = false
}: CustomMultiCalendarProps) => {
  const [dates, setDates] = useState<Date[]>(value);
  const [time, setTime] = useState<string>('00:00');

  useEffect(() => {
    if (dates.length > 0) {
      setTime(format(dates[0], 'HH:mm'));
    }
  }, [dates]);

  const handleDateChange = (selectedDate: Date | Date[]) => {
    if (selectedDate !== null && selectedDate !== undefined) {
      if (allowMultipleSelection) {
        // Si se permite la selección múltiple, convertir a arreglo si no lo es
        const newDates = Array.isArray(selectedDate) ? selectedDate : [selectedDate];
        setDates(newDates);
        onChange?.(newDates);
      } else {
        setDates([selectedDate]);
        onChange?.([selectedDate]);
      }
    }
  };

  const handleTimeChange = (newTime: string) => {
    setTime(newTime);
    if (dates.length > 0) {
      const updatedDates = dates.map((d) => {
        const [hours, minutes] = newTime.split(':').map(Number);
        const newDate = new Date(d);
        newDate.setHours(hours);
        newDate.setMinutes(minutes);
        return newDate;
      });
      setDates(updatedDates);
      onChange?.(updatedDates);
    }
  };

  return (
    <>
      <div>
        {MonthAndYearPicker && (
          <MonthAndYearSelector
            date={dates.length > 0 ? dates[0] : new Date()}
            onChange={(date) => handleDateChange(date)}
            baseSelectorYear={baseSelectorYear}
          />
        )}
        <Calendar
          mode={allowMultipleSelection ? 'multiple' : 'single'}
          selected={dates}
          onSelect={(date) => date && handleDateChange(date)}
          onMonthChange={() => {}}
          initialFocus
        />
        {timePicker && <TimePicker onChange={handleTimeChange} value={time} />}
      </div>
    </>
  );
};
