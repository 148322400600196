import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { IVisitorForm } from "@/modules/visitors/infrastructure/interfaces/VisitorsFormInterface";
import { IVisitor } from "@/modules/visitors/infrastructure/interfaces/VisitorsInterface";
import { RootState } from "@/store/store";
import { format } from "date-fns";
import { CalendarIcon, Pencil, Search, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export function VisitorsReport() {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const configurations = useSelector((state: RootState) => state.configurations);
    const { params } = useRouteParams();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState<IVisitor[]>([]);
    const [accentColor, setAccentColor] = useState("");
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState<IVisitor>();
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteData, setDeleteData] = useState("");
    const [values, setValues] = useState<IVisitorForm>({
        date: null,
        visitor: "",
        temperature: ""
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setDataSource([
            {
                id: "12b39e21-1a4a-4d43-b2cf-4427a75a7b3c",
                data: {
                    date: "2024-08-15T09:01:28.885Z",
                    visitor: "Leonard",
                    temperature: "25",
                    resident: {
                        id: "d8c76f59-b9b1-4649-b4c7-3719664216c5",
                        first_name: "Sheldon",
                        last_name: "Cooper",
                    },
                    who: {
                        id: "cd59f549-5a35-4c64-b24f-abf18b545973",
                        first_name: "Nombre Staff",
                        last_name: "Apellido Staff"
                    },
                },
                created_at: "2024-08-15T09:01:28.885Z",
                updated_at: "2024-08-15T09:01:28.885Z"
            }
        ]);
    }, []);
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    /* EDIT DIALOG */
    const openEditDialog = (element: IVisitor) => {
        setOpenEdit(true);
        setEditData(element);

        setValues({
            date: new Date(element.data.date),
            visitor: element.data.visitor,
            temperature: element.data.temperature
        });
    }

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleStartDateChange = (value: any) => {
        setValues({
            ...values,
            date: value
        });
    };

    const handleEdit = (element: IVisitor) => {
        if(values.date && values.visitor && values.temperature) {
            console.log({
                id: element.id,
                data: {
                    ...values,
                    date: values.date.toISOString()
                }
            });

            fetchData();
            
            toast(
                {
                    description: t("mentruation.report.edit.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("mentruation.report.edit.toast.required")
                }
            );
        }

        fetchData();

        return true;
    }

     /* DELETE DIALOG */
     const openDeleteDialog = (id: string) => {
        setOpenDelete(true);
        setDeleteData(id);
    }

    const closeDeleteDialog = () => {
        setOpenDelete(false);
    }

    const handleDelete = (id: string) => {
        console.log({
            id
        });
        fetchData();

        return true;
    }

    const fetchData = () => {
        console.log({
            location_id: params.l
        });
    }
    
    return (
        <div>
        {
            deleteData && <CustomDialog 
                title={t("cleaning.report.summary.delete.title")} 
                description={t("cleaning.report.summary.delete.description")} 
                width="30rem"
                newDialog={true}
                isOpen={openDelete}
                onSubmit={() => handleDelete(deleteData)}
                onClose={closeDeleteDialog}
            ></CustomDialog>
        }

        {
            editData && <CustomDialog 
                title={t("mentruation.report.edit.title")}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onSubmit={() => handleEdit(editData)}
                onClose={closeEditDialog}
            >
            <div className="general-outings-form grid grid-cols-1 gap-4">
                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"visitor"} className="text-right justify-self-start">
                        { t("mentruation.form.form.comments") }
                    </Label>

                    <Input
                        id={"visitor"}
                        name={"visitor"}
                        type="text"
                        value={values.visitor}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"temperature"} className="text-right justify-self-start">
                        { t("mentruation.form.form.comments") }
                    </Label>

                    <Input
                        id={"temperature"}
                        name={"temperature"}
                        type="text"
                        value={values.temperature}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                        <Label htmlFor={"date"} className="text-left">
                            { t("mentruation.form.form.startDate") }
                        </Label>
                        
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                variant={"outline"}
                                className={cn(
                                    "w-[280px] justify-start text-left font-normal w-100",
                                    !values.date && "text-muted-foreground"
                                )}
                                >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {values.date ? format(values.date, "MM/dd/yyyy - hh:mm aa") : <span>{ t("mentruation.form.form.datePlaceholder") }</span>}
                                </Button>
                            </PopoverTrigger>

                            <PopoverContent className="w-auto p-0">
                                <CustomCalendar
                                    value={values.date}
                                    timePicker={true}
                                    onChange={handleStartDateChange}
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
            </div>

            </CustomDialog>
        }

        <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
            <CardHeader>
                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                    {t("mentruation.report.title")}
                </CardTitle>
            </CardHeader>
                
            <CardContent>
                <div className="grid grid-cols-1 grid-row-2 w-100">
                    {
                        dataSource.length ? (
                            <Table>
                                <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                                    <TableRow className="bg-primary/80 hover:bg-primary/80">
                                        <TableHead className="text-white">Post Info</TableHead>
                                        <TableHead className="text-white">Regarding To</TableHead>
                                        <TableHead className="text-white w-5">Visitor</TableHead>
                                        <TableHead className="text-white w-5">Temperature</TableHead>
                                        <TableHead className="text-white w-5">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                
                                <TableBody>
                                    {
                                        dataSource.length && dataSource.map(
                                            element =>
                                            <TableRow key={element.id}>
                                                <TableCell>{format(new Date(element.created_at), "dd/MM/yyyy hh:mm a")} By {element.data.who.first_name}</TableCell>
                                                <TableCell>{element.data.resident.first_name + ' ' + element.data.resident.last_name}</TableCell>
                                                <TableCell>{element.data.visitor}</TableCell>
                                                <TableCell>{element.data.temperature}</TableCell>
                                                <TableCell className="flex">
                                                    <Button onClick={() => openEditDialog(element)} variant="outline" size="icon" className='mr-2'>
                                                        <Pencil className="h-4 w-4" />
                                                    </Button>
                                                    
                                                    <Button onClick={() => openDeleteDialog(element.id as string)} variant="outline" size="icon" className='mr-2'>
                                                        <Trash className="h-4 w-4" />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        ) : (
                            <div className="flex flex-col w-100 justify-center align-center">
                                <div className="flex justify-center items-center">
                                    <Search className="text-center w-5 mb-2" />
                                </div>

                                <h1 className="text-center">
                                    {t("mentruation.report.table.notFound")}
                                </h1>
                            </div>
                        )
                    }
                </div>
            </CardContent>
        </Card>
    </div>
    );
}
