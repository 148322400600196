import useRouteParams from "@/common/hooks/RouteParamsHook";
import { LocationSelector } from "@/common/presentation/components/Selectors/LocationSelector";
import { Tabs, TabsList, TabsTrigger } from "@/common/presentation/components/ui/tabs";
import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { VisitorsForm } from "../components/main/VisitorsForm";
import { VisitorsReport } from "../components/main/VisitorsReport";

export function VisitorsPage() {
    const { params } = useRouteParams();
    const [selectedTab, setTab] = useState("form");
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const { t } = useTranslation();
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);
    
    const onSelectedTab = (event: string) => {
        setTab(event);
    }
    
    return (
        <div>
            { !params.l && <LocationSelector selections={false} />}
            
            {
                params.l && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{color: accentColor}}>
                                Visitors
                            </div>
                        </div>

                        <Tabs defaultValue={selectedTab} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    { t("mentruation.form.label") }
                                </TabsTrigger>

                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    { t("mentruation.report.label") }
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                )
            }

            {
                params.l && selectedTab === "form" && <VisitorsForm />
            }

            {
                params.l && selectedTab === "report" && <VisitorsReport />
            }
        </div>
    );
}
