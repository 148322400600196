import { FinancialInformationForm } from './FinancialInformationForm';
import { CarriersInformationForm } from './CarriersInformation';

export const CarriersFinancial = () => {
  return (
    <>
      <div className="grid grid-cols-4 rounded-lg border dark:bg-[#1d1d1d] mt-4 p-4 w-full gap-4">
        <div className="col-span-4 xl:col-span-4 flex flex-col gap-4">
          <CarriersInformationForm />
        </div>
        <div className="col-span-4 xl:col-span-4 flex flex-col gap-4">
          <FinancialInformationForm />
        </div>
      </div>
    </>
  );
};
