import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';

import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Input } from '@/common/presentation/components/ui/input';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import * as residentFormActions from '@/modules/residents/presentation/slices/residentsForm';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';

interface Props {
  className?: string;
}

const formSchema = z.object({
  discharge_date: z.date().optional(),
  reason_discharge: z.string().optional(),
  name_mortuary: z.string().optional()
});
type FormValues = z.infer<typeof formSchema>;

export const DischargeResidents = ({ className }: Props) => {
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const { status: residentFormStatus } = useSelector((state: RootState) => state.residents.residentForm);

  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues } as FormValues
  });

  const onSubmitForm = async (data: FormValues) => {
    const response = dispatch(
      residentFormActions.updateDischargeForm({
        resident_id: resident?.id,
        ...data
      })
    );

    if ((await response).payload) {
      toast({
        description: 'Discharge resident updated successfully'
      });
    }
  };

  useEffect(() => {
    if (resident) {
      const dischargeResidentData = resident.discharge_resident[0];

      setDefaultValues({
        discharge_date: dischargeResidentData?.discharge_date
          ? new Date(dischargeResidentData?.discharge_date)
          : undefined,
        reason_discharge: dischargeResidentData?.reason_discharge || '',
        name_mortuary: dischargeResidentData?.name_mortuary || ''
      });
    }
  }, [resident]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load resident</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Discharge Residents">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmitForm)}
                className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end"
              >
                <div className="col-span-1 items-end">
                  <FormField
                    control={form.control}
                    name="discharge_date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Discharge Date</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={'outline'}
                                className={cn(
                                  'w-full pl-3 text-left font-normal',
                                  !field.value && 'text-muted-foreground'
                                )}
                                disabled={residentFormStatus === 'loading'}
                              >
                                {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date </span>}
                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <div className="rounded-md border">
                              <CustomCalendar
                                onChange={field.onChange}
                                value={field.value || undefined}
                                MonthAndYearPicker
                              />
                            </div>
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={form.control}
                    name="reason_discharge"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Reason For Discharge</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            value={field.value || ''}
                            disabled={residentFormStatus === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={form.control}
                    name="name_mortuary"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name Of Mortuary</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            value={field.value || ''}
                            disabled={residentFormStatus === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                  <>
                    <Separator className="col-span-full" />
                    <Button type="submit" className="w-64" disabled={residentFormStatus === 'loading'}>
                      {residentFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
