import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { LucideIcon } from 'lucide-react';
import { ReactNode } from 'react';

interface AlertCardProps {
  title: string;
  alerts: any[];
  icon: LucideIcon;
  children?: ReactNode;
  custom?: boolean;
  customScrollHeight?: string;
}

export const AlertCard = ({
  title,
  alerts,
  icon: Icon,
  custom = false,
  children,
  customScrollHeight
}: AlertCardProps) => {
  return (
    <>
      <Card className="border-t-4 border-t-primary/80 h-full flex flex-col">
        <CardHeader>
          <CardTitle className="flex flex-row items-center text-lg mb-2">
            <Icon className="shrink-0 h-6 w-6 mr-2 text-muted-foreground" />
            <span className="flex-1 font-bold text-muted-foreground">{title}</span>
            <span className="font-bold text-muted-foreground">{alerts.length}</span>
          </CardTitle>
          <hr />
        </CardHeader>
        <CardContent className="flex-grow flex flex-col">
          <ScrollArea className={cn('w-full flex-grow overflow-auto', customScrollHeight ? customScrollHeight : 'h-[150px]')}>
            {custom && children}
            {!custom &&
              alerts.map((alert) => (
                <div key={alert.id}>
                  <div className="py-1 px-2 rounded-sm hover:bg-primary/5 cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap">
                    <span className="text-primary font-semibold">{alert.date}</span> -
                    <span className="text-primary font-semibold ml-1">{alert.name}</span> -
                    <span className="ml-1">{alert.alert}</span>
                  </div>
                </div>
              ))}
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </CardContent>
      </Card>
    </>
  );
};
