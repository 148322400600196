import { create } from 'zustand';

type NewListMedicationState = {
  isOpen: boolean;
  residentId?: string;
  locationId?: string;
  onOpen: (locationId?: string, residentId?: string) => void;
  onClose: () => void;
};

export const useNewListMedication = create<NewListMedicationState>((set) => ({
  isOpen: false,
  residentId: undefined,
  locationId: undefined,
  onOpen: (locationId?: string, residentId?: string) => set({ isOpen: true, locationId, residentId }),
  onClose: () => set({ isOpen: false })
}));
