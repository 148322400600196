import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useCarriersAndFinancialInformationStore } from '@/modules/residents/domain/stores/carriers-financial-information/use-carrier-financial-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { CircleFadingPlus } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CarrierCard, DefaultValues } from './components/CarrierCard';
import { Separator } from '@/common/presentation/components/ui/separator';

export const CarriersInformationForm = () => {
  const { resident }: { resident: Resident | null; status: string } = useSelector(
    (state: RootState) => state.residents.resident
  );

  const initialCarriersInformation = useMemo(
    () =>
      (resident?.carriers_and_financial_information?.carriers || []).map(
        ({ id, carrier, carrier_number, type }: any) => ({
          id,
          carrier,
          carrier_number,
          type
        })
      ),
    [resident]
  );

  const [carriersInformation, setCarriersInformation] = useState<DefaultValues[]>(initialCarriersInformation);

  const {
    createOrupdateCarrierInformation,
    removeCarrierInformation,
    status: carriersAndFinancialInformationFormStatus
  } = useCarriersAndFinancialInformationStore();

  const addCarrier = useCallback(() => {
    setCarriersInformation((prevCarriersInformation) => [
      {
        id: null,
        carrier: '',
        carrier_number: '',
        type: ''
      },
      ...prevCarriersInformation
    ]);
  }, []);

  const createOrUpdateCarrier = useCallback(
    async (values: any) => {
      const payload = {
        resident_id: resident?.id,
        ...values
      };

      try {
        const response = await createOrupdateCarrierInformation(payload);

        setCarriersInformation((prevCarriersInformation) =>
          prevCarriersInformation.map((carrierInformation) =>
            carrierInformation.id === null
              ? {
                  ...carrierInformation,
                  id: response.id
                }
              : carrierInformation
          )
        );

        toast({
          description: 'Carrier information has been saved successfully'
        });
      } catch (error) {
        toast({
          description: 'Error saving carrier information',
          variant: 'destructive'
        });
      }
    },
    [createOrupdateCarrierInformation, resident]
  );

  const removeCarrier = useCallback(
    async (id: string | null) => {
      try {
        if (id !== null) {
          const payload = {
            id,
            resident_id: resident?.id
          };

          const response = await removeCarrierInformation(payload);
          const newCarriersInformation =
            carriersInformation.filter((carrierInformation) => carrierInformation.id !== id) || [];
          setCarriersInformation(newCarriersInformation);

          if (response) {
            toast({
              description: 'Carrier information has been removed successfully'
            });
          } else {
            setCarriersInformation(
              carriersInformation.filter((carrierInformation) => carrierInformation.id !== id) || null
            );
          }
        }
      } catch (error) {
        toast({
          description: 'Error removing carrier information',
          variant: 'destructive'
        });
      }
    },
    [removeCarrierInformation, carriersInformation, resident]
  );

  return (
    <>
      <div>
        <CustomAccordion
          titleComponent={<h1 className="text-xl font-bold">Carrier Information</h1>}
          openOption={true}
          defaultOpen={true}
        >
          <div className="w-full flex flex-col gap-4">
            <div className="w-full flex flex-col gap-4">
              <div className="w-full flex items-center justify-between">
                <p>Add any carriers that the resident has.</p>

                <Button className="flex gap-2" variant={'default'} onClick={() => addCarrier()}>
                  <CircleFadingPlus className="h-4 w-4 flex-1" />
                  New Carrier
                </Button>
              </div>
            </div>

            {carriersInformation.map((carrier, index) => (
              <CarrierCard
                key={carrier.id || index}
                defaultValues={carrier}
                onSubmit={createOrUpdateCarrier}
                onRemove={removeCarrier}
                disabled={carriersAndFinancialInformationFormStatus === 'loading'}
              />
            ))}

            <Separator />

            <p>
              Total carriers: <span className="font-bold">{carriersInformation.length}</span>
            </p>
          </div>
        </CustomAccordion>
      </div>
    </>
  );
};
