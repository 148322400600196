import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { useSubscription } from '@/modules/subscription/infraestructure/providers/SubscriptionProvider';
import TrainingPage from '@/modules/training/presentation/pages/TrainingPage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

interface ProtectedRoutesProps {
  serviceKey: string;
  serviceModule: string | null;
}

const ProtectRoutes: React.FC<ProtectedRoutesProps> = ({ serviceKey, serviceModule }) => {
  const { isSubscribed } = useSubscription();
  const hasAccess = isSubscribed(serviceKey, serviceModule);
  return hasAccess ? <Outlet /> : <Navigate to="/dashboard" />;
};

const TrainingRouter = () => {
  const subscriptionsLoaded = useSelector((state: any) => state.subscription.subscriptions_loading);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (subscriptionsLoaded) return;
    setIsReady(true);
  }, [subscriptionsLoaded]);
  return (
    <>
      {!isReady ? (
        <SplashScreen/>
      ) : (
        <Routes>
          <Route element={<ProtectRoutes serviceKey="training_plan" serviceModule={null} />}>
            <Route path="/" element={<TrainingPage />}></Route>
          </Route>
        </Routes>
      )}
    </>
  );
};

export default TrainingRouter;
