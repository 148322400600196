import { AppraisalNeedsAndServicesPlanPage } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/AppraisalNeedsAndServicesPlanPage';
import { FormsSelector } from './FormsSelector';

const forms = [
  {
    name: 'Appraisal Needs and Services Plan',
    key: 'appraisal_needs_and_services'
  }
];

export const LicencingForms = () => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <div>
          <h1 className="text-2xl font-bold">Licencing Forms</h1>
        </div>
        {/* <AppraisalNeedsAndServicesPlanPage /> */}

        <FormsSelector forms={forms} />
      </div>
    </>
  );
};
