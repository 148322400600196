import { createListenerMiddleware } from '@reduxjs/toolkit';

const authMiddleware = createListenerMiddleware();

authMiddleware.startListening({
    predicate: (action: any) => {
        return (
            action.type.endsWith('/rejected') &&
            (action.payload?.message === 'Unauthenticated.' || action.error?.message === 'Unauthenticated.')
        );
    },
    effect: async (action, listenerApi) => {
        const currentPath = window.location.pathname;
        const signUpPathRegex = /^\/sign-up(\/.*)?$/;
        if (!signUpPathRegex.test(currentPath)) {
            console.log('Unauthorized access detected, redirecting to login');  
            location.href = 'login';
        }
    },
});

export default authMiddleware;