import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuAvailable: true
};

const MenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuAvailability(state, action) {
     state.menuAvailable = action.payload;
  }
}
});

export const { setMenuAvailability } = MenuSlice.actions;
export default MenuSlice.reducer;
