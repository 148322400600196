import { Separator } from '@/common/presentation/components/ui/separator';
import { TabsContent, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import * as residentConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { Users } from 'lucide-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomFields } from './CustomFields';
import { FormCards } from './FormCards';

export const ResidentTab = () => {
  return (
    <TabsTrigger value="residents" className="w-full flex items-center justify-start gap-2">
      <Users />
      Residents
    </TabsTrigger>
  );
};

export const ResidentContent = () => {
  const { configurations } = useSelector((state: RootState) => state.residents.configurations);

  const dispatch = useDispatch<AppDispatch>();

  const defaultValues = configurations?.resident_form_cards
    ? configurations.resident_form_cards
    : {
        level_of_care: false,
        xxxx: false,
        religious_preferences: false,
        administrator_can_see: false,
        custom_fields: false,
        notes: false,
        discharge: false
      };

  useEffect(() => {
    if (Object.keys(configurations).length === 0) {
      dispatch(residentConfigurationsActions.getResidentsConfigurations());
    }
  }, [dispatch]);

  return (
    <TabsContent
      value="residents"
      className="w-full mb-4 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
    >
      <FormCards defaultValues={defaultValues} />
      <Separator className="my-4" />
      <CustomFields />
    </TabsContent>
  );
};
