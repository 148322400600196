import { cn } from '@/lib/utils';
import { ChevronDown, ChevronRight, LucideIcon } from 'lucide-react';
import { useSelector } from 'react-redux';

interface ItemProps {
  id?: string;
  itemIcon?: string;
  active?: boolean;
  expanded: boolean;
  level?: number;
  onExpand?: () => void;
  label: string;
  onClick?: () => void;
  icon: LucideIcon;
  hasSubmenu?: boolean;
}

export const Item = ({
  id,
  label,
  onClick,
  icon: Icon,
  active,
  itemIcon,
  level = 0,
  onExpand,
  expanded,
  hasSubmenu = false
}: ItemProps) => {
  const handleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onExpand?.();
  };
  const menuAvailable = useSelector((state: any) => state.menu.menuAvailable);

  const ChevronIcon = expanded ? ChevronDown : ChevronRight;

  return (
    <div
      role="button"
      onClick={onClick}
      style={{
        paddingLeft: level ? `${level * 12 + 12}px` : '12px'
      }}
      className={cn(
        'group min-h-[27px] text-sm py-2 pr-3 w-full hover:bg-primary/5 flex items-center text-muted-foreground font-medium',
        active && 'bg-primary/5 text-primary',
        !menuAvailable && 'opacity-50 pointer-events-none'
      )}
    >
      {itemIcon ? (
        <div className="shrink-0 mr-2 text-[18px]">{itemIcon}</div>
      ) : (
        <Icon className="shrink-0 h-[18px] mr-2 text-muted-foreground" />
      )}
      <span className="truncate flex-1">{label}</span>
      {hasSubmenu && (
        <div role="button" className="h-full rounded-sm hover:bg-primary/20  mr-1" onClick={handleExpand}>
          <ChevronIcon className="shrink-0 text-muted-foreground/50 h-4 w-4" />
        </div>
      )}
    </div>
  );
};
