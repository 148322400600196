import useRouteParams from '@/common/hooks/RouteParamsHook';
import { ImageCard } from '@/common/presentation/components/Selectors/ImageCard';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { cn } from '@/lib/utils';
import * as locationActions from '@/modules/locations/presentation/slices/locationSlice';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { Home } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../ui/use-toast';
import { useTranslation } from 'react-i18next';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { getResidentsByLocation } from '@/modules/residents/presentation/slices/residentsSlice';
import SimpleCard from './SimpleCard';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

interface ListImagesProps {
  onSelect?: (id: string) => void;
  className?: ReactNode;
  locationId?: string;
  selections?: boolean;
  setUrlParams?: boolean;
  quickView?: boolean;
}

export const LocationSelector = ({
  onSelect,
  className,
  locationId = 'all-locations',
  selections = true,
  setUrlParams = true,
  quickView = false
}: ListImagesProps) => {
  const { params, setParam } = useRouteParams();
  const { locations, status } = useSelector((state: RootState) => state.locations.allLocations);
  const { setLocationSelectedId, locationSelectedId} = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();

  const getLocations = async () => {
    const response: any = await dispatch(locationsActions.getLocations());
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
  };

  const getLocation = async (id: string) => {
    const response: any = await dispatch(locationActions.getLocation(id));
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
      setParam('l', 'all-locations');
    }
  };

  const getResidents = async () => {
    const response: any = await dispatch(
      getResidentsByLocation(locationId === 'all-locations' ? undefined : locationId)
    );
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
  };

  const onSelectedLocation = (id: string) => {
    // setSelectedLocation(id); TODO: CHECK
    setLocationSelectedId(id);
    onSelect?.(id);
    if (setUrlParams) setParam('l', id);

    if (id === 'all-locations') {
      dispatch(locationActions.removeLocation());
    } else {
      getLocation(id);
      getResidents();
    }
  };

  useEffect(() => {
    if (params.l && params.l.length > 0) {      
      setLocationSelectedId(params.l);
      // setSelectedLocation(params.l); TODO: CHECK
      if (params.l === 'all-locations') {
        dispatch(locationActions.removeLocation());
      } else {
        getLocation(params.l);
      }
    }
  }, [params.l, dispatch]);

  useEffect(() => {    
    if (!locations.length) getLocations();
  }, [dispatch]);

  return (
    <>
      <div className={cn('overflow-hidden pt-3 pb-2', className)}>
        {status === 'loading' && !quickView ? (
          <div className="flex items-center justify-start">
            <div className="flex flex-col items-center gap-2">
              <Skeleton className="w-[120px] h-[168px] rounded-xl bg-primary/20" />
              <Skeleton className="h-5 w-[120px] rounded-full bg-primary/20" />
            </div>
          </div>
        ) : status === 'loading' ? (
          <div className="flex items-center justify-start mb-4">
            <div className="flex flex-col items-center gap-2">
              <Skeleton className="w-[120px] h-9 rounded-lg bg-primary/20" />
            </div>
          </div>
        ) : (
          <ScrollArea>
            <div className="flex gap-4 mb-4">
              {locations.length > 1 &&
                (quickView ? (
                  <SimpleCard
                    item={{
                      id: 'all',
                      firstName: t('locationsSelector.allLocations')
                    }}
                    isSelected={selections && locationSelectedId === 'all-locations'}
                    onSelect={() => onSelectedLocation('all-locations')}
                  />
                ) : (
                  <ImageCard
                    icon={Home}
                    item={{
                      id: 'all',
                      firstName: t('locationsSelector.allLocations')
                    }}
                    className={cn('hover:cursor-pointer')}
                    onSelect={() => onSelectedLocation('all-locations')}
                    aspectRatio={'portrait'}
                    isSelected={selections && locationSelectedId === 'all-locations'}
                  />
                ))}

              {locations.map((location, index) =>
                quickView ? (
                  <SimpleCard
                    key={index}
                    item={{
                      id: location.id,
                      firstName: location.name,
                      description: location.location_description,
                      image: location.image_path
                    }}
                    isSelected={selections && locationSelectedId === location.id}
                    onSelect={() => onSelectedLocation(location.id)}
                  />
                ) : (
                  <ImageCard
                    key={index}
                    item={{
                      id: location.id,
                      firstName: location.name,
                      description: location.location_description,
                      image: location.image_path
                    }}
                    onSelect={() => onSelectedLocation(location.id)}
                    aspectRatio={'portrait'}
                    isSelected={selections && locationSelectedId === location.id}
                  />
                )
              )}
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        )}
      </div>
    </>
  );
};
