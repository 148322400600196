import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { useNewExceptionDialog } from '@/modules/emar/infrastructure/hooks/UseNewException';
import { format } from 'date-fns';
import { Loader } from 'lucide-react';
import { RefObject, useEffect, useMemo, useRef } from 'react';
import { useMedicationStore } from '../../domain/stores/useMedicationStore';

interface MedicationCardProps {
  className?: string;
  data: any;
  children?: React.ReactNode;
  setRef?: (ref: RefObject<HTMLDivElement>) => void;
  selectedDate: Date;
  selectedTime: string;
  locationId?: string;
  residentId?: string;
}

const responsibleOfAdministration = (name: string) => {
  const styles: any = {
    'Controlled medication': { bg: 'bg-blue-400/50', text: 'text-blue-950' },
    'Hospice Care kit': { bg: 'bg-teal-500/50', text: 'text-teal-950' },
    'Administered by nurse': { bg: 'bg-pink-500/50', text: 'text-pink-950' },
    default: { bg: 'bg-gray-400/50', text: 'text-gray-950' }
  };
  return styles[name] || styles.default;
};

const findMedicationByDateTime = (medications: any[], selectedDate: Date, selectedTime: string) => {
  return medications.find((med: any) => {
    return (
      med?.date_to_be_dispensed?.split('T')[0] === format(selectedDate, 'yyyy-MM-dd') &&
      med?.time_to_be_dispensed === selectedTime
    );
  });
};

export const EmarRoutineCard = ({
  className,
  data,
  selectedDate,
  selectedTime,
  setRef,
  locationId,
  residentId
}: MedicationCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const { onOpen } = useNewExceptionDialog();
  const { dispenseMedication, witnessMedication, status: medicationCardStatus } = useMedicationStore();

  const { isDispensed, isWitnessed, isException } = useMemo(() => {
    const medication = findMedicationByDateTime(data?.emar_routine_medications, selectedDate, selectedTime);
    const exception = findMedicationByDateTime(data?.emar_medication_exceptions, selectedDate, selectedTime);

    return {
      isDispensed: medication?.dispensed === 1,
      isWitnessed: medication?.witnessed === 1,
      isException: exception?.status === 'active'
    };
  }, [data, selectedDate, selectedTime]);

  const onHandleDispense = async (data: any) => {
    try {
      await dispenseMedication(locationId!, residentId!, data?.id, selectedTime, format(selectedDate, 'yyyy-MM-dd'));

      toast({
        description: 'Medication dispensed successfully',
        variant: 'default'
      });
    } catch (error: any) {
      toast({
        description: 'Failed to dispense medication',
        variant: 'destructive'
      });
    }
  };

  const onHandleWitness = async (data: any) => {
    try {
      await witnessMedication(
        locationId!,
        residentId!,
        data?.emar_routine_medications?.find((med: any) => med?.time_to_be_dispensed === selectedTime)?.id,
        selectedTime,
        format(selectedDate, 'yyyy-MM-dd')
      );

      toast({
        description: 'Medication witnessed successfully',
        variant: 'default'
      });
    } catch (error: any) {
      toast({
        description: 'Failed to witness medication',
        variant: 'destructive'
      });
    }
  };

  useEffect(() => {
    if (cardRef.current) {
      setRef?.(cardRef);
    }
  }, [cardRef]);

  return (
    <Card className={cn('flex flex-col', className)} ref={cardRef}>
      <CardContent className="space-y-2">
        <CardHeader className="px-0">
          <div className="flex flex-wrap gap-2">
            {!isWitnessed && !isException && (
              <Badge variant="outline" className="text-md font-bold border-red-500 text-red-500">
                Not Completed
              </Badge>
            )}

            {isDispensed && !isWitnessed && !isException && (
              <Badge variant="outline" className="text-md font-bold border-blue-500 text-blue-500">
                Dispensed
              </Badge>
            )}

            {((isDispensed && isWitnessed) || isException) && (
              <Badge variant="outline" className="text-md font-bold border-green-500 text-green-500">
                Completed
              </Badge>
            )}
            {isException && (
              <Badge variant="outline" className="text-md font-bold border-gray-500 text-gray-500">
                Exception
              </Badge>
            )}
          </div>
          <CardTitle className="text-xl font-bold">{data?.medication?.name}</CardTitle>
        </CardHeader>
        <Separator />
        <div className="text-md">
          <p>
            RX #: <span className="font-bold">{data?.rx || 'Not provided'}</span>
          </p>
          <p>
            Start Date:{' '}
            <span className="font-bold">
              {data?.cycle_start_date ? format(new Date(data?.cycle_start_date), 'MM/dd/yyyy') : 'Not provided'}
            </span>
          </p>
          <p>
            Physician: <span className="font-bold">{data?.physician || 'Not provided'}</span>
          </p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Instructions</h3>
          <p className="text-md">{data?.instruction || 'No instructions provided'}</p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Special Instructions</h3>
          <p className="text-md">{data?.special_instructions || 'No special instructions provided'}</p>
        </div>
      </CardContent>
      <CardFooter className="mt-auto w-full flex flex-col gap-2">
        {data?.responsible_of_administration && (
          <>
            <Separator />
            <p
              className={cn(
                'w-full p-2 text-md font-bold text-center rounded-md',
                responsibleOfAdministration(data?.responsible_of_administration?.name).bg,
                responsibleOfAdministration(data?.responsible_of_administration?.name).text
              )}
            >
              {data?.responsible_of_administration?.name || 'No responsible of administration provided'}
            </p>
          </>
        )}

        <div className="w-full grid grid-cols-2 space-x-2">
          {!isDispensed && (
            <Button
              variant={'outline'}
              size={'sm'}
              className="text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
              onClick={() => onHandleDispense(data)}
              disabled={medicationCardStatus === 'loading' || isException}
            >
              {medicationCardStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
              <span className="font-bold">Dispense</span>
            </Button>
          )}
          {isDispensed && (
            <Button
              variant={'outline'}
              size={'sm'}
              className="text-green-500 border-green-500 hover:bg-green-500 hover:text-white"
              disabled={medicationCardStatus === 'loading' || isWitnessed || isException}
              onClick={() => {
                onHandleWitness(data);
              }}
            >
              {medicationCardStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
              <span className="font-bold">Witnessed</span>
            </Button>
          )}
          <Button
            variant={'outline'}
            size={'sm'}
            className="text-gray-400 border-gray-400 hover:bg-gray-400 hover:text-white"
            disabled={medicationCardStatus === 'loading' || isWitnessed || isException}
            onClick={() => {
              onOpen(data, selectedDate, [selectedTime], locationId, residentId);
            }}
          >
            {medicationCardStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
            <span className="font-bold">Exception</span>
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};
