import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
 } from '@/common/presentation/components/ui/dialog';
 import { Button } from '@/common/presentation/components/ui/button';
 import { Label } from '@/common/presentation/components/ui/label';
 import { Input } from '@/common/presentation/components/ui/input';
import { Plus } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createArea, getAreas } from '../../slices/TrackerCleaningSlice';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';

export function TrackerCleaningAreaCreateDialog() {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { toast } = useToast();
    const { t } = useTranslation();
    const [values, setValues] = useState({
        name: "",
    });

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(values.name) {
            await dispatch(createArea(
                {
                    ...values,
                    location_id: params.l
                }
            ));
    
            fetchData();

            toast(
                {
                    description: t("cleaning.create.area.create.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.create.area.create.toast.required")
                }
            );
        }
    }

    const fetchData = async () => {
        await dispatch(getAreas(
            {
                location_id: params.l
            }
        ));
    }

    const cleanInputs = () => {
        setValues({
            name: ""
        });
    };

    return (
        <div>
            <Dialog>
                <DialogTrigger asChild>
                    <Button variant="outline" size="sm" className='bg-primary/80 hover:bg-primary/80 hover:text-white text-white align-self-end'>
                        <span className='mr-1'>{t("cleaning.create.area.newButton")}</span>
                        <Plus />
                    </Button>
                </DialogTrigger>

                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>{t("cleaning.create.area.create.title")}</DialogTitle>
                    </DialogHeader>

                    <form className="grid gap-4 py-4" onSubmit={handleSubmit}>
                        <div>
                            <Label htmlFor="name" className="text-right">
                                {t("cleaning.create.area.create.form.name")}
                            </Label>
                            
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                className="col-span-3"
                                value={values.name}
                                onChange={handleInputChange}
                            />
                        </div>

                        <DialogClose asChild>
                            <Button type="submit">
                                {t("cleaning.create.area.create.form.submit")}
                            </Button>
                        </DialogClose>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};
