import { FlagIcon } from 'react-flag-kit';
import Select, { components } from 'react-select';
import React, { useEffect, useState } from 'react';
import { countriesAvailable } from './countries';
import clsx from 'clsx';

interface IProps {
  countries?: Array<string>;
  country: string;
  defaultCountry: string;
  onChange: (value: any) => void;
  name: string;
  disabled?: boolean;
}


const CountrySelector: React.FC<IProps> = ({ countries, defaultCountry, country, onChange, name, disabled = false }) => {

  let finalCountries = null;
  if (countries) {
    finalCountries = countriesAvailable.filter((country) => countries.includes(country.code));
  } else {
    finalCountries = countriesAvailable;
  }

  const countryOptions = finalCountries.map((country) => ({
    value: country.code,
    label: country.name
  }));

  const getOptionFromCode = (code: string) => countryOptions.find(option => option.value === code);

  const formatOptionLabel = ({ value, label }: { value: any; label: any }) => (
    <div className="flex items-center">
      <FlagIcon code={value} size={22} className="mr-2 rounded-sm" />
      {label}
    </div>
  );

  const CustomInput = (props: any) => (
    <components.Input {...props} autoComplete="off" className="dark:text-white "/>
  );

  const CustomSelectContainer = (props: any) => (
    <components.SelectContainer {...props} className="text-xs"  />
  );

  const customClassNames = {
    control: () => clsx('!bg-background border-1 !border-gray-300 dark:!border-gray-700 focus:!border-red-400 !rounded h-1 focus:!outline-none'),
    menu: () => clsx('!bg-background !border-2 !border-gray focus:!border-red-400 !z-[9999]'),
    option: ({isSelected}: any) => clsx('dark:text-white dark:hover:!bg-gray-400', 
      isSelected ? '!bg-primary' : ''
    ),
    singleValue: () => clsx('text-dark dark:text-white'),
  }

  return (
    <>
      <Select
        key={defaultCountry}
        options={countryOptions}
        name={name}
        formatOptionLabel={formatOptionLabel}
        placeholder="Select a country"
        isSearchable
        defaultValue={getOptionFromCode(defaultCountry)}
        value={getOptionFromCode(country)}
        onChange={onChange}
        isDisabled={disabled}
        classNames={customClassNames}
        components={{ Input: CustomInput, SelectContainer: CustomSelectContainer }}
        className="h-10 z-[9999] justify-between rounded-md border bg-background text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-0 focus:border-black disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
      />{' '}
    </>
  );
};

export default CountrySelector;
