import PropTypes from 'prop-types';
import { Phone, PhoneForwarded, Plus, Trash, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/common/presentation/components/ui/button';

const ContactsPhonesCreate = ({ category, contactIndex, phones, handlePhoneInputChange, addPhone, removePhone }) => {
  const { t } = useTranslation();
  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneInputChangeWrapper = (category, contactIndex, phoneIndex, field, value) => {
    const formattedPhoneNumber = field === 'number' ? formatPhoneNumber(value) : value;
    handlePhoneInputChange(category, contactIndex, phoneIndex, field, formattedPhoneNumber);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">{t('contacts.phone')}</h3>
        <Button
          type="button"
          onClick={() => addPhone(category, contactIndex)}
          className="flex items-center px-3 py-2 text-white rounded"
        >
          <Plus className="mr-2" />
          {t('contacts.add_phone')}
        </Button>
      </div>
      {phones.map((phone, phoneIndex) => (
        <div key={phoneIndex} className="grid grid-cols-1 md:grid-cols-[2fr_3fr_2fr_auto] gap-6 mb-4 items-end">
          <div className="relative">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`phone-category-${category}-${contactIndex}-${phoneIndex}`}>
              {t('contacts.category')}
            </label>
            <div className="relative">
              <select
                id={`phone-category-${category}-${contactIndex}-${phoneIndex}`}
                value={phone.category}
                onChange={(e) => handlePhoneInputChange(category, contactIndex, phoneIndex, 'category', e.target.value)}
                className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="phone">{t('contacts.phone')}</option>
                <option value="fax">{t('contacts.fax')}</option>
                <option value="mobile">{t('contacts.mobile')}</option>
                <option value="home">{t('contacts.home')}</option>
                <option value="work">{t('contacts.work')}</option>
                <option value="main">{t('contacts.main')}</option>
                <option value="pager">{t('contacts.pager')}</option>
                <option value="other">{t('contacts.other')}</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 10l5 5 5-5z" /></svg>
              </div>
            </div>
          </div>
          <div className="relative">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`phone-${phoneIndex}`}>
              {t('contacts.phone_number')}
            </label>
            <div className="flex items-center">
              <Phone className="absolute left-3 text-gray-400" />
              <input
                type="text"
                name={`phone-${phoneIndex}`}
                id={`phone-${phoneIndex}`}
                value={phone.number}
                onChange={(e) => handlePhoneInputChangeWrapper(category, contactIndex, phoneIndex, 'number', e.target.value)}
                className="pl-10 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div className="relative">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`ext-${phoneIndex}`}>
              {t('contacts.extension')}
            </label>
            <div className="flex items-center">
              <PhoneForwarded className="absolute left-3 text-gray-400" />
              <input
                type="text"
                name={`ext-${phoneIndex}`}
                id={`ext-${phoneIndex}`}
                value={phone.ext}
                onChange={(e) => handlePhoneInputChange(category, contactIndex, phoneIndex, 'ext', e.target.value)}
                className="pl-10 shadow appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div className="flex items-center justify-center" style={{ marginBottom: "0.2rem" }}>
            <button
              type="button"
              onClick={() => removePhone(category, contactIndex, phoneIndex, phone.id)}
              className="px-2 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

ContactsPhonesCreate.propTypes = {
  category: PropTypes.string.isRequired,
  contactIndex: PropTypes.number.isRequired,
  phones: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    number: PropTypes.string,
    ext: PropTypes.string,
  })).isRequired,
  handlePhoneInputChange: PropTypes.func.isRequired,
  addPhone: PropTypes.func.isRequired,
  removePhone: PropTypes.func.isRequired,
};

export default ContactsPhonesCreate;
