import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '@/store/store';
import { getContacts, getContactsByResident, getListPhonesById, deleteContact } from '../slices/contactsSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { ArrowLeft, Trash, CircleFadingPlus, Mail, Phone, Pencil, Eye, CircleUserRound, Building2, StretchHorizontal, UtilityPole, SquareChevronRight, Waypoints, SquareStack, BookA } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/common/presentation/components/ui/table';
import { Button } from '@/common/presentation/components/ui/button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { cn } from '@/lib/utils';
import { NavigationBar } from '@/common/presentation/components/navigations/NavigationBar';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import useContactsColumns from '../components/PersonalContacts/ContactsColumns';
import renderActionsColumn from '../components/PersonalContacts/renderActionsColumn';
import { useTranslation } from 'react-i18next';

const DialogContactInfo = ({ contact, isOpen, onClose, phones }) => {
    const { t } = useTranslation();
    
    return (
    <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-md">
            <DialogHeader>
                <DialogTitle>{t('contacts.contact_information')}</DialogTitle>
            </DialogHeader>
            <div>
                <p className="flex justify-center"><CircleUserRound size={50} className="w-56" /></p>
                <p className="text-center text-xl"><span>{contact.full_name}</span></p>
                <p className="pt-5 pb-5">
                    <DialogDescription>
                        {t('contacts.contact_details')}.
                    </DialogDescription>
                </p>
                <p className="flex mt-3"><Building2 className="mr-2" /><strong className="mr-1">{t('contacts.company')}:</strong> {contact.company}</p>
                <p className="flex mt-3"><Mail className="mr-2" /><strong className="mr-1">{t('contacts.email')}:</strong> {contact.email}</p>
                <p className="flex mt-3"><StretchHorizontal className="mr-2" /><strong className="mr-1">{t('contacts.address')}:</strong> {contact.address}</p>
                <p className="flex mt-3"><UtilityPole className="mr-2" /><strong className="mr-1">{t('contacts.city')}:</strong> {contact.city}</p>
                <p className="flex mt-3"><Waypoints className="mr-2" /><strong className="mr-1">{t('contacts.state')}:</strong> {contact.state}</p>
                <p className="flex mt-3"><SquareChevronRight className="mr-2" /><strong className="mr-1">{t('contacts.zip_code')}:</strong> {contact.zipcode}</p>
                <p className="flex mt-3"><SquareStack className="mr-2" /><strong className="mr-1">{t('contacts.country')}:</strong> {contact.country}</p>
                <p className="pt-5 pb-5">
                    <DialogDescription>
                        {t('contacts.phones')}:
                    </DialogDescription>
                </p>
                <ul className="list-disc pl-5">
                    {phones.map((phone, index) => (
                        <li key={index}>{phone.number} <strong>{t('contacts.ext')}:</strong> {phone.ext}</li>
                    ))}
                </ul>
            </div>
            <DialogFooter>
                <DialogClose asChild>
                    <Button variant="secondary">{t('contacts.close')}</Button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    </Dialog>
    );
};

const ContactsPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const contacts = useSelector((state: RootState) => state.contacts.contacts) || [];
    const phones = useSelector((state: RootState) => state.contacts.phones) || {};
    const [data, setData] = useState<any[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const { params, setParam } = useRouteParams();
    const [filter, setFilter] = useState<string>('');

    const [selectedContact, setSelectedContact] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const residentId = searchParams.get('r');
    const ContactsColumns = useContactsColumns();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [sort, setSort] = useState<{ column: string; direction: string }>({ column: '', direction: '' });

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        params.l === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === params.l);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!params.l && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(params.l === 'all-locations' ? locations : locations.filter((l) => l.id === params.l));
        setLocation(params.l);

        if (!params.r && params.l) {
            setParam('r', 'all-residents');
        }
    }, [params]);

    const onResidentSelect = (residentId: string) => {
        setParam('r', residentId);
    };

    useEffect(() => {
        //console.log("params.r:", params.r);
        if (!params.r || params.r === 'all-residents') {
            dispatch(getContacts());
        } else {
            dispatch(getContactsByResident(params.r));
        }
    }, [dispatch, params.r]);
    
    useEffect(() => {
        // Fetch phone numbers for each contact
        contacts.forEach((contact) => {
            dispatch(getListPhonesById(contact.id));
        });
    }, [contacts, dispatch]);

    useEffect(() => {
        if (contacts.length) {
            const formattedData = contacts.map((contact) => ({
                id: contact.id || 'N/A',
                category: contact.category?.name || 'N/A',
                linkto: contact.resident ? `${contact.resident.first_name} ${contact.resident.last_name}` : 'All Residents',
                company: contact.company || 'N/A',
                name: contact.name || 'N/A',
                last_name: contact.last_name || 'N/A',
                relationship: contact.relationship || 'N/A',
                email: contact.email || 'N/A',
                full_name: `${contact.name} ${contact.last_name}`,
                address: contact.address || 'N/A',
                phone: phones[contact.id] && phones[contact.id].length > 0 ? phones[contact.id][0].number : 'N/A', // Primer número de teléfono
                city: contact.city || 'N/A',
                state: contact.state || 'N/A',
                zipcode: contact.zipcode || 'N/A',
                country: contact.country || 'N/A',
                same_as: contact.same_as || 'N/A'
            }));
            setData(formattedData);
            //console.log("Contacts updated:", formattedData);
        } else {
            setData([]); // Reset data if no contacts
        }
    }, [contacts, phones]);

    const handleEyeClick = (contact) => {
        setSelectedContact(contact);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedContact(null);
    };

    const handleEditClick = (id, linkto) => {
        // Redirecciona a la página de edición de contactos
        navigate(`/contacts/edit/${id}?r=${linkto}`);
    };

    const handleNewContactClick = () => {
        //console.log('Navigating to create new contact page');
        navigate(`/contacts/new/${residentId}`);
    };

    const handleBackClick = () => {
        navigate('/contacts');
    };

    const handleDeleteClick = (contactId: string) => {
        //console.log("id de contacto " + contactId);
        dispatch(deleteContact(contactId)).then(() => {
            dispatch(getContacts()); // Refresca la lista de contactos
            dispatch(getListPhonesById(contactId)); // Refresca la lista de teléfonos
        });
    };
    
    const columnsWithActions = ContactsColumns.map((column) => {
        if (column.key === 'actions') {
          return {
            ...column,
            render: (rowData: any) => renderActionsColumn({ rowData, handleEyeClick, handleEditClick, handleDeleteClick })
          };
        }
        return column;
    });
    const [columns, setColumns] = useState(columnsWithActions);
    const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });

    const handleSort = (columnKey, direction) => {
        setSorting({ key: columnKey, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
            if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setData(sortedData);
    };
    //console.log("Data before rendering table:", data); // Verificar data antes de renderizar

    return (
        <div className=" ">
            <div className="flex flex-col gap-4 my-4">
                <div className="flex items-center justify-between">
                    <div className="flex gap-2">
                        <div className="font-semibold text-2xl">
                            {selectedLocations.length > 0 ? (
                                <>{t('contacts.contacts')} {selectedLocations.length > 1 ? 'All Locations' : `- ${selectedLocations[0]?.name}`}</>
                            ) : (
                                'Contacts'
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {!params.l && (
                <>
                    <LocationSelector selections={false} />
                    <TableCustomVirtuoso
                    data={data}
                    columns={columns}
                    renderCellContent={(index, column, data) => data[index][column.key]}
                    additionalButtons={<></>}
                    onSort={handleSort}
                    />
            </>
            )}
            {params.l && location && (
                <>
                    <NavigationBar>
                        <Button variant="ghost" onClick={handleBackClick}>
                        <>
                            <ArrowLeft className="mr-2 h-4 w-4" />
                            {t('contacts.back')}
                        </>
                        </Button>
                    </NavigationBar>
                    <div className="flex flex-col gap-4 my-4">
                        <ResidentSelector onSelect={onResidentSelect} locationId={location} residentId={params.r} />
                    </div>
                    <div className="Table">
                        <div className="flex justify-end w-full py-4">
                            <Button onClick={handleNewContactClick} className="flex w-auto gap-2 hover:bg-[#B6B6B6]">
                                <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                                {t('contacts.new')}
                            </Button>
                        </div>
                        <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                            <TableCustomVirtuoso
                            data={data}
                            columns={columns}
                            renderCellContent={(index, column, data) => data[index][column.key]}
                            additionalButtons={<></>}
                            onSort={handleSort}
                            />
                            {selectedContact && (
                                <DialogContactInfo
                                    contact={selectedContact}
                                    isOpen={isDialogOpen}
                                    onClose={handleCloseDialog}
                                    phones={phones[selectedContact.id] || []}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ContactsPage;