import React, { useEffect, useState } from 'react';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { Button } from '@/common/presentation/components/ui/button';
import { CustomMultiCalendar } from '@/common/presentation/components/CustomCalendar/CustomMultiCalendar';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/common/presentation/components/ui/tooltip';
import { InfoIcon } from 'lucide-react';

interface TypeOptionsProps {
  type: string;
  onDaysChange: (selectedDays: string[]) => void;
  onCalendarDateChange: (selectedDate: Date[]) => void;
  onTextChange: (text: string) => void;
  onUpdateFormData: (updatedData: { special_day?: string[] }) => void;
  special_days: any;
}

const TypeOptions: React.FC<TypeOptionsProps> = ({ onUpdateFormData, special_days }) => {
  const [showOption, setShowOption] = useState<'every_day' | 'days_of_week' | 'calendar' | ''>('');
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date[]>([]);
  const [text, setText] = useState('');
  const [typeName, setTypeName] = useState('');

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const handleDayChange = (day: string) => {
    const updatedDays = selectedDays.includes(day) ? selectedDays.filter((d) => d !== day) : [...selectedDays, day];
    setSelectedDays(updatedDays);
    const specialDayValue = showOption === 'days_of_week' ? updatedDays : [];
    onUpdateFormData({ special_day: specialDayValue, text, type: typeName });
  };

  const handleCalendarChange = (dates: Date[]) => {
    setSelectedDate(dates);
    const specialDayValue = showOption === 'calendar' ? dates : [];
    onUpdateFormData({ special_day: specialDayValue, text, type: typeName });
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    setText(newText);
    let specialDayValue;
    if (showOption === 'every_day') {
      specialDayValue = 'every_day';
    } else if (showOption === 'days_of_week') {
      specialDayValue = selectedDays;
    } else if (showOption === 'calendar') {
      specialDayValue = selectedDate;
    } else {
      specialDayValue = [];
    }
    onUpdateFormData({ special_day: specialDayValue, text: newText, type: typeName });
  };

  const handleOptionChange = (option: 'every_day' | 'days_of_week' | 'calendar') => {
    let typeNameValue = '';
    if (option === 'every_day') {
      typeNameValue = option;
    } else if (option === 'days_of_week') {
      typeNameValue = option;
    } else if (option === 'calendar') {
      typeNameValue = option;
    }
    setShowOption(option);
    setTypeName(typeNameValue);
    const specialDayValue = option === 'every_day' ? 'every_day' : option === 'calendar' ? selectedDate : selectedDays;
    onUpdateFormData({ special_day: specialDayValue || [], text, type: typeNameValue });
  };

  useEffect(() => {
    if (special_days) {
      const data = special_days;
      setText(data.text || '');
      setShowOption(data.type || '');

      if (data.type === 'days_of_week') {
        setSelectedDays(data.special_day || []);
      } else if (data.type === 'calendar') {
        // setSelectedDate(data.special_day || []);
      }
    }
  }, [special_days]);

  return (
    <div className="p-6 border border-gray-300 rounded-lg shadow-sm bg-gray-50 mb-4">
      <TooltipProvider>
        <div className="mb-6">
          <div className="mt-3 flex flex-wrap items-center gap-4">
            {['every_day', 'days_of_week', 'calendar'].map((option) => (
              <div key={option} className="flex items-center">
                <Input
                  id={option}
                  type="radio"
                  name="days_option"
                  value={option}
                  checked={showOption === option}
                  onChange={() => handleOptionChange(option as 'every_day' | 'days_of_week' | 'calendar')}
                  className="form-radio  h-5 w-5 focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                />
                <Label htmlFor={option} className="ml-2 text-gray-700 cursor-pointer">
                  {option === 'every_day'
                    ? 'Every Day'
                    : option === 'days_of_week'
                    ? 'Days of the Week'
                    : 'According to the calendar'}
                </Label>
              </div>
            ))}
          </div>
        </div>
      </TooltipProvider>

      {showOption === 'days_of_week' && (
        <div className="grid grid-cols-7 gap-2 mb-6">
          {daysOfWeek.map((day) => (
            <Button
              key={day}
              className={`py-2 px-3 rounded-md border ${
                selectedDays.includes(day) ? 'bg-blue-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-100'
              }`}
              onClick={() => handleDayChange(day)}
            >
              {day}
            </Button>
          ))}
        </div>
      )}

      {showOption === 'calendar' && (
        <div className="mb-6">
          <div className="bg-white rounded-lg shadow-sm p-4">
            <CustomMultiCalendar
              MonthAndYearPicker
              value={selectedDate}
              onChange={handleCalendarChange}
              allowMultipleSelection
            />
          </div>
        </div>
      )}

      <div>
        <Label htmlFor="text" className="font-medium mb-2 flex items-center">
          Special Days Instructions
        </Label>
        <textarea
          id="text"
          rows={2}
          value={text}
          name="special_days"
          onChange={handleTextChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none mt-1"
        />
      </div>
    </div>
  );
};

export default TypeOptions;
