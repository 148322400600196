import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Separator } from '@/common/presentation/components/ui/separator';
import { cn } from '@/lib/utils';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import * as residentFormActions from '@/modules/residents/presentation/slices/residentsForm';
import { Textarea } from '@/common/presentation/components/ui/textarea';

interface Props {
  className?: string;
}

export const CustomFields = ({ className }: Props) => {
  const { configurations } = useSelector((state: RootState) => state.residents.configurations);
  const { status: residentFormStatus } = useSelector((state: RootState) => state.residents.residentForm);
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const dispatch = useDispatch<AppDispatch>();

  const fieldTypes = (type: string, optional: boolean) => {
    switch (type) {
      case 'text' || 'textarea':
        return optional ? z.string().default('').optional() : z.string().default('');
      case 'number':
        return optional ? z.number().default(0).optional() : z.number().default(0);
      case 'date':
        return optional ? z.date().default(new Date()).optional() : z.date().default(new Date());
      case 'boolean':
        return optional ? z.boolean().default(false).optional() : z.boolean().default(false);
      default:
        return z.string();
    }
  };

  const { custom_form_fields } = configurations || { custom_form_fields: [] };

  const formSchema = useMemo(() => {
    const fields = custom_form_fields.reduce((acc: any, field: any) => {
      acc[field.name] = fieldTypes(field.type, field.required);
      return acc;
    }, {});
    return z.object(fields);
  }, [custom_form_fields]);

  const defaultValues = useMemo(() => {
    const nameValues = custom_form_fields.map((field: any) => field.name);

    return nameValues.reduce((acc: any, key: any) => {
      if (key in resident?.other_information) {
        acc[key] = resident?.other_information[key];
      }
      return acc;
    }, {} as Record<string, any>);
  }, [custom_form_fields]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmit = async (data: any) => {
    const response = await dispatch(
      residentFormActions.updateCustomFieldsForm({
        resident_id: resident?.id,
        custom_fields: {
          ...data
        }
      })
    );

    if (response.payload) {
      console.log('Custom fields updated successfully');
    }
  };

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load configurations</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Custom Fields">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <>
                  {configurations.custom_form_fields?.map((custom_field: any) => {
                    return (
                      <div key={custom_field?.name}>
                        <FormField
                          control={form.control}
                          name={custom_field?.name as never}
                          render={({ field }) => {
                            return (
                              <FormItem>
                                <FormLabel>{custom_field?.label}</FormLabel>
                                <FormControl>
                                  <>
                                    {custom_field?.type === 'number' && (
                                      <Input
                                        {...field}
                                        type="number"
                                        inputMode="numeric"
                                        onChange={(e) => field.onChange(+e.target.value)}
                                        placeholder={custom_field?.placeholder}
                                        value={field.value || 0}
                                        disabled={residentFormStatus === 'loading'}
                                      />
                                    )}

                                    {custom_field?.type === 'text' && (
                                      <Input
                                        {...field}
                                        value={field.value || ''}
                                        placeholder={custom_field?.placeholder}
                                        disabled={residentFormStatus === 'loading'}
                                      />
                                    )}

                                    {custom_field?.type === 'textarea' && (
                                      <Textarea className="min-h-[40px] h-[40px]" placeholder="" {...field} />
                                    )}
                                  </>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </>

                {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                  <>
                    <Separator className="col-span-full" />
                    <Button type="submit" className="w-64" disabled={residentFormStatus === 'loading'}>
                      {residentFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
