
const SignUpAdapter = {
    signUp: (data: any) => {
        return {
            first_name: data.first_name,
            last_name: data.last_name,
            country: data.client_country.code,
            phone_number: data.phone,
            email: data.email,
            company_name: data.client_name,
            training:  data.training === 'yes',
            facilities: data.facilities_list,
            card_information: data.card_information,
            billing_country: data.country,
            billing_state: data.state,
            billing_city: data.city,
            billing_zip: data.postal_code,
            billing_address_1: data.address_1,
            billing_address_2: data.address_2
        }
    }
}

export default SignUpAdapter;