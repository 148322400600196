import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';
import { useNewResidentTransfer } from '../../infrastructure/hooks/use-new-resident-transfer';
import { Newtransfer } from './new-transfer-form';


export const NewResidentTransferDialog = () => {
  const { isOpen, onClose } = useNewResidentTransfer();

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="  border-t-4 border-t-primary/80">
          <DialogHeader>
            <DialogTitle className="text-xl">New Resident Trasfer Form</DialogTitle>
            <DialogDescription>This is a form to add a new resident transfer to the system.</DialogDescription>
          </DialogHeader>
          <Newtransfer />
        </DialogContent>
      </Dialog>
    </>
  );
};