import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StaffService from '../../infrastructure/services/StaffService';

interface StaffConfigurationState {
  configurations: any;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: StaffConfigurationState = {
  configurations: {},
  status: 'idle',
  error: null
};

export const getStaffConfigurations = createAsyncThunk(
  'staffConfigurations/getStaffConfigurations',
  async () => {
    try {
      const response = await StaffService.getStaffConfigurations();
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error getting residents configurations: ' + error);
    }
  }
);

export const postStaffConfigurations = createAsyncThunk(
  'staffConfigurations/postResidentsConfigurations',
  async (data: any) => {
    try {
      const response = await StaffService.postStaffConfigurations(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error posting Staff configurations: ' + error);
    }
  }
);

const staffConfigurationsSlice = createSlice({
  name: 'staffConfigurations',
  initialState,
  reducers: {
    setConfigurations: (state, action) => {
      state.configurations = action.payload;
    },
    resetConfigurations: (state) => {
      state.configurations = {};
      state.error = null;
      state.status = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffConfigurations.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.configurations = {};
      })
      .addCase(getStaffConfigurations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.configurations = action.payload;
        state.error = null;
      })
      .addCase(getStaffConfigurations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
        state.configurations = {};
      });

    builder
      .addCase(postStaffConfigurations.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postStaffConfigurations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.configurations = action.payload;
        state.error = null;
      })
      .addCase(postStaffConfigurations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  }
});

export const { setConfigurations, resetConfigurations } = staffConfigurationsSlice.actions;
export default staffConfigurationsSlice.reducer;
